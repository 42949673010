export const HANDLE_GOAL_MODAL = "HANDLE_GOAL_MODAL";
export const HANDLE_EDIT_GOAL_MODAL = "HANDLE_EDIT_GOAL_MODAL";

export const ADD_GOAL_REQUEST = "ADD_GOAL_REQUEST";
export const ADD_GOAL_SUCCESS = "ADD_GOAL_SUCCESS";
export const ADD_GOAL_FAILURE = "ADD_GOAL_FAILURE";

export const GET_GOALS_BY_ORGANIZATION_ID_REQUEST =
  "GET_GOALS_BY_ORGANIZATION_ID_REQUEST";
export const GET_GOALS_BY_ORGANIZATION_ID_SUCCESS =
  "GET_GOALS_BY_ORGANIZATION_ID_SUCCESS";
export const GET_GOALS_BY_ORGANIZATION_ID_FAILURE =
  "GET_GOALS_BY_ORGANIZATION_ID_FAILURE";

export const GET_GOALS_BY_USER_ID_REQUEST = "GET_GOALS_BY_USER_ID_REQUEST";
export const GET_GOALS_BY_USER_ID_SUCCESS = "GET_GOALS_BY_USER_ID_SUCCESS";
export const GET_GOALS_BY_USER_ID_FAILURE = "GET_GOALS_BY_USER_ID_FAILURE";

export const SET_EDIT_GOAL_STATE = "SET_EDIT_GOAL_STATE";

export const UPDATE_GOAL_BY_ID_REQUEST = "UPDATE_GOAL_BY_ID_REQUEST";
export const UPDATE_GOAL_BY_ID_SUCCESS = "UPDATE_GOAL_BY_ID_SUCCESS";
export const UPDATE_GOAL_BY_ID_FAILURE = "UPDATE_GOAL_BY_ID_FAILURE";
