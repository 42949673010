import * as types from "./InstagramRulesActionTypes";

const initialState = {
    fetchingTemplate: false,
    fetchingTemplateError: false,
    template: [],

    fetchingNotificationTemplate: false,
    fetchingNotificationTemplateError: false,
    notificationTemplate: [],

    //Invoice
    addingInvoiceForEmail: false,
    addingInvoiceForEmailError: false,

    fetchingaddingInvoice: false,
    fetchingaddingInvoiceError: false,
    addingInvoice: [],

    fetchingSequenceLeads: false,
    fetchingSequenceLeadsError: false,
    sequenceData: [],
};

export const instagramRulesReducer = (state = initialState, action) => {
    switch (action.type) {

        case types.GET_TEMPLATE_REQUEST:
            return { ...state, fetchingTemplate: true };
        case types.GET_TEMPLATE_SUCCESS:
            return {
                ...state,
                fetchingTemplate: false,
                template: action.payload,
            };
        case types.GET_TEMPLATE_FAILURE:
            return {
                ...state,
                fetchingTemplate: false,
                fetchingTemplateError: true,
            };


        case types.GET_NOTIFICATION_TEMPLATE_REQUEST:
            return { ...state, fetchingNotificationTemplate: true };
        case types.GET_NOTIFICATION_TEMPLATE_SUCCESS:
            return {
                ...state,
                fetchingNotificationTemplate: false,
                notificationTemplate: action.payload,
            };
        case types.GET_NOTIFICATION_TEMPLATE_FAILURE:
            return {
                ...state,
                fetchingNotificationTemplate: false,
                fetchingNotificationTemplateError: true,
            };

        
        //Invoice For Mail Add Get
        case types.ADD_INVOICE_FOR_EMAIL_REQUEST:
            return { ...state, addingInvoiceForEmail: true };
        case types.ADD_INVOICE_FOR_EMAIL_SUCCESS:
            return {
                ...state,
                addingInvoiceForEmail: false,
                addingInvoice: action.payload,
            };
        case types.ADD_INVOICE_FOR_EMAIL_FAILURE:
            return {
                ...state,
                addingInvoiceForEmail: false,
                addingInvoiceForEmailError: true,
            };


        case types.GET_INVOICE_FOR_EMAIL_REQUEST:
            return { ...state, fetchingaddingInvoice: true };
        case types.GET_INVOICE_FOR_EMAIL_SUCCESS:
            return {
                ...state,
                fetchingaddingInvoice: false,
                addingInvoice: action.payload,
            };
        case types.GET_INVOICE_FOR_EMAIL_FAILURE:
            return {
                ...state,
                fetchingaddingInvoice: false,
                fetchingaddingInvoiceError: true,
            };


        case types.GET_SEQUENCE_LEADS_REQUEST:
            return {
                ...state,
                fetchingSequenceLeads: true,
            };
        case types.GET_SEQUENCE_LEADS_SUCCESS:
            return {
                ...state,
                fetchingSequenceLeads: false,
                sequenceData: action.payload,
            };
        case types.GET_SEQUENCE_LEADS_FAILURE:
            return {
                ...state,
                fetchingSequenceLeads: false,
                fetchingSequenceLeadsError: true,
            };
        
        default:
            return state;
    }
};