import styled from "styled-components";

const MainWrapper = styled.div`
    border-radius: 0.2rem;
    box-shadow: 0.62em 0.94em 0.94em -0.25em ${(props) => props.theme.boxShadowColor};
    border: 0.06em solid ${(props) => props.theme.borderColor}
    background-color: ${(props) => props.theme.backgroundColor};
   height: ${(props) => props.Height || "auto"};
    color: ${(props) => props.theme.color};
    margin: 0.3rem;
    padding: 0.3rem;
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    overflow: auto;
`;
export default MainWrapper;
