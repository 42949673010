export const HANDLE_ORG_EMAIL_MODAL = "HANDLE_ORG_EMAIL_MODAL";
export const HANDLE_ORG_FACEBOOK_MODAL = "HANDLE_ORG_FACEBOOK_MODAL";
export const HANDLE_ORG_QUICKBOOK_MODAL = "HANDLE_ORG_QUICKBOOK_MODAL";

export const HANDLE_PROCESS_MODAL = "HANDLE_PROCESS_MODAL";
export const HANDLE_TASK_EDIT_PROCESS_MODAL = "HANDLE_TASK_EDIT_PROCESS_MODAL";
export const HANDLE_PROCESS_TASK_MODAL = "HANDLE_PROCESS_TASK_MODAL";
export const HANDLE_RULES_MODAL = "HANDLE_RULES_MODAL";

export const ADD_PROCESS_REQUEST = "ADD_PROCESS_REQUEST";
export const ADD_PROCESS_SUCCESS = "ADD_PROCESS_SUCCESS";
export const ADD_PROCESS_FAILURE = "ADD_PROCESS_FAILURE";

export const UPDATE_PROCESS_TASK_REQUEST = "UPDATE_PROCESS_TASK_REQUEST";
export const UPDATE_PROCESS_TASK_SUCCESS = "UPDATE_PROCESS_TASK_SUCCESS";
export const UPDATE_PROCESS_TASK_FAILURE = "UPDATE_PROCESS_TASK_FAILURE";
export const DATA_CLEAR = "DATA_CLEAR";

export const GET_PROCESS_REQUEST = "GET_PROCESS_REQUEST";
export const GET_PROCESS_SUCCESS = "GET_PROCESS_SUCCESS";
export const GET_PROCESS_FAILURE = "GET_PROCESS_FAILURE";

export const UPDATE_STAGE_REQUEST = "UPDATE_STAGE_REQUEST";
export const UPDATE_STAGE_SUCCESS = "UPDATE_STAGE_SUCCESS";
export const UPDATE_STAGE_FAILURE = "UPDATE_STAGE_FAILURE";

export const GET_DEFAULT_PROCESS_REQUEST = "GET_DEFAULT_PROCESS_REQUEST";
export const GET_DEFAULT_PROCESS_SUCCESS = "GET_DEFAULT_PROCESS_SUCCESS";
export const GET_DEFAULT_PROCESS_FAILURE = "GET_DEFAULT_PROCESS_FAILURE";

export const ADD_PROCESS_TASK_REQUEST = "ADD_PROCESS_TASK_REQUEST";
export const ADD_PROCESS_TASK_SUCCESS = "ADD_PROCESS_TASK_SUCCESS";
export const ADD_PROCESS_TASK_FAILURE = "ADD_PROCESS_TASK_FAILURE";

export const GET_PROCESS_TASK_REQUEST = "GET_PROCESS_TASK_REQUEST";
export const GET_PROCESS_TASK_SUCCESS = "GET_PROCESS_TASK_SUCCESS";
export const GET_PROCESS_TASK_FAILURE = "GET_PROCESS_TASK_FAILURE";

export const GET_PROCESS_STAGES_REQUEST = "GET_PROCESS_STAGES_REQUEST";
export const GET_PROCESS_STAGES_SUCCESS = "GET_PROCESS_STAGES_SUCCESS";
export const GET_PROCESS_STAGES_FAILURE = "GET_PROCESS_STAGES_FAILURE";

export const REMOVE_STAGE_REQUEST = "REMOVE_STAGE_REQUEST";
export const REMOVE_STAGE_SUCCESS = "REMOVE_STAGE_SUCCESS";
export const REMOVE_STAGE_FAILURE = "REMOVE_STAGE_FAILURE";

export const ADD_PROCESS_STAGE_REQUEST = "ADD_PROCESS_STAGE_REQUEST";
export const ADD_PROCESS_STAGE_SUCCESS = "ADD_PROCESS_STAGE_SUCCESS";
export const ADD_PROCESS_STAGE_FAILURE = "ADD_PROCESS_STAGE_FAILURE";

export const UPDATE_PROCESS_NAME_REQUEST = "UPDATE_PROCESS_NAME_REQUEST";
export const UPDATE_PROCESS_NAME_SUCCESS = "UPDATE_PROCESS_NAME_SUCCESS";
export const UPDATE_PROCESS_NAME_FAILURE = "UPDATE_PROCESS_NAME_FAILURE";

export const GET_ALL_PROCESS_STAGES_REQUEST = "GET_ALL_PROCESS_STAGES_REQUEST";
export const GET_ALL_PROCESS_STAGES_SUCCESS = "GET_ALL_PROCESS_STAGES_SUCCESS";
export const GET_ALL_PROCESS_STAGES_FAILURE = "GET_ALL_PROCESS_STAGES_FAILURE";

export const GET_OPPO_STAGES_REQUEST = "GET_OPPO_STAGES_REQUEST";
export const GET_OPPO_STAGES_SUCCESS = "GET_OPPO_STAGES_SUCCESS";
export const GET_OPPO_STAGES_FAILURE = "GET_OPPO_STAGES_FAILURE";

export const GET_DEPARTMENTS_REQUEST = "GET_DEPARTMENTS_REQUEST";
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";
export const GET_DEPARTMENTS_FAILURE = "GET_DEPARTMENTS_FAILURE";

export const GET_LEVELS_REQUEST = "GET_LEVELS_REQUEST";
export const GET_LEVELS_SUCCESS = "GET_LEVELS_SUCCESS";
export const GET_LEVELS_FAILURE = "GET_LEVELS_FAILURE";

export const UPDATE_TASK_BY_ID_REQUEST = "UPDATE_TASK_BY_ID_REQUEST";
export const UPDATE_TASK_BY_ID_SUCCESS = "UPDATE_TASK_BY_ID_SUCCESS";
export const UPDATE_TASK_BY_ID_FAILURE = "UPDATE_TASK_BY_ID_FAILURE";

export const DELETE_TASK_REQUEST = "DELETE_TASK_REQUEST";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_FAILURE = "DELETE_TASK_FAILURE";

export const HANDLE_TASK_MODAL = "HANDLE_TASK_MODAL";
export const SET_TASK_EDIT = "SET_TASK_EDIT";

export const UPDATE_TASK_RESUFFEL_BY_ID_REQUEST =
  "UPDATE_TASK_RESUFFEL_BY_ID_REQUEST";
export const UPDATE_TASK_RESUFFEL_BY_ID_SUCCESS =
  "UPDATE_TASK_RESUFFEL_BY_ID_SUCCESS";
export const UPDATE_TASK_RESUFFEL_BY_ID_FAILURE =
  "UPDATE_TASK_RESUFFEL_BY_ID_FAILURE";

//reQCRUITMENT
export const GET_PROCESS_FOR_RECRUIT_REQUEST =
  "GET_PROCESS_FOR_RECRUIT_REQUEST";
export const GET_PROCESS_FOR_RECRUIT_SUCCESS =
  "GET_PROCESS_FOR_RECRUIT_SUCCESS";
export const GET_PROCESS_FOR_RECRUIT_FAILURE =
  "GET_PROCESS_FOR_RECRUIT_FAILURE";

export const ADD_PROCESS_FOR_RECRUIT_REQUEST =
  "ADD_PROCESS_FOR_RECRUIT_REQUEST";
export const ADD_PROCESS_FOR_RECRUIT_SUCCESS =
  "ADD_PROCESS_FOR_RECRUIT_SUCCESS";
export const ADD_PROCESS_FOR_RECRUIT_FAILURE =
  "ADD_PROCESS_FOR_RECRUIT_FAILURE";

export const GET_PROCESS_STAGES_FOR_RECRUIT_REQUEST =
  "GET_PROCESS_STAGES_FOR_RECRUIT_REQUEST";
export const GET_PROCESS_STAGES_FOR_RECRUIT_SUCCESS =
  "GET_PROCESS_STAGES_FOR_RECRUIT_SUCCESS";
export const GET_PROCESS_STAGES_FOR_RECRUIT_FAILURE =
  "GET_PROCESS_STAGES_FOR_RECRUIT_FAILURE";

export const ADD_PROCESS_STAGE_FOR_RECRUIT_REQUEST =
  "ADD_PROCESS_STAGE_FOR_RECRUIT_REQUEST";
export const ADD_PROCESS_STAGE_FOR_RECRUIT_SUCCESS =
  "ADD_PROCESS_STAGE_FOR_RECRUIT_SUCCESS";
export const ADD_PROCESS_STAGE_FOR_RECRUIT_FAILURE =
  "ADD_PROCESS_STAGE_FOR_RECRUIT_FAILURE";

export const UPDATE_PROCESS_NAME_FOR_RECRUIT_REQUEST =
  "UPDATE_PROCESS_NAME_FOR_RECRUIT_REQUEST";
export const UPDATE_PROCESS_NAME_FOR_RECRUIT_SUCCESS =
  "UPDATE_PROCESS_NAME_FOR_RECRUIT_SUCCESS";
export const UPDATE_PROCESS_NAME_FOR_RECRUIT_FAILURE =
  "UPDATE_PROCESS_NAME_FOR_RECRUIT_FAILURE";

export const UPDATE_STAGE_FOR_RECRUIT_REQUEST =
  "UPDATE_STAGE_FOR_RECRUIT_REQUEST";
export const UPDATE_STAGE_FOR_RECRUIT_SUCCESS =
  "UPDATE_STAGE_FOR_RECRUIT_SUCCESS";
export const UPDATE_STAGE_FOR_RECRUIT_FAILURE =
  "UPDATE_STAGE_FOR_RECRUIT_FAILURE";

export const GET_ALL_PROCESS_STAGES_FOR_RECRUIT_REQUEST =
  "GET_ALL_PROCESS_STAGES_FOR_RECRUIT_REQUEST";
export const GET_ALL_PROCESS_STAGES_FOR_RECRUIT_SUCCESS =
  "GET_ALL_PROCESS_STAGES_FOR_RECRUIT_SUCCESS";
export const GET_ALL_PROCESS_STAGES_FOR_RECRUIT_FAILURE =
  "GET_ALL_PROCESS_STAGES_FOR_RECRUIT_FAILURE";

export const ADD_ORGANIZATION_LEADS_USER_REQUEST =
  "ADD_ORGANIZATION_LEADS_USER_REQUEST";
export const ADD_ORGANIZATION_LEADS_USER_SUCCESS =
  "ADD_ORGANIZATION_LEADS_USER_SUCCESS";
export const ADD_ORGANIZATION_LEADS_USER_FAILURE =
  "ADD_ORGANIZATION_LEADS_USER_FAILURE";

export const GET_ORGANIZATION_LEADS_USER_REQUEST =
  "GET_ORGANIZATION_LEADS_USER_REQUEST";
export const GET_ORGANIZATION_LEADS_USER_SUCCESS =
  "GET_ORGANIZATION_LEADS_USER_SUCCESS";
export const GET_ORGANIZATION_LEADS_USER_FAILURE =
  "GET_ORGANIZATION_LEADS_USER_FAILURE";

export const GET_SIGNATURE_REQUEST = "GET_SIGNATURE_REQUEST";
export const GET_SIGNATURE_SUCCESS = "GET_SIGNATURE_SUCCESS";
export const GET_SIGNATURE_FAILURE = "GET_SIGNATURE_FAILURE";

export const ENABLE_RECRUITMENT_ADVANCE_REQUEST =
  "ENABLE_RECRUITMENT_ADVANCE_REQUEST";
export const ENABLE_RECRUITMENT_ADVANCE_SUCCESS =
  "ENABLE_RECRUITMENT_ADVANCE_SUCCESS";
export const ENABLE_RECRUITMENT_ADVANCE_FAILURE =
  "ENABLE_RECRUITMENT_ADVANCE_FAILURE";

//Apparel data

export const HANDLE_APPAREL_MODAL = "HANDLE_APPAREL_MODAL";
export const ADD_APPAREL_REQUEST = "ADD_APPAREL_REQUEST";
export const ADD_APPAREL_SUCCESS = "ADD_APPAREL_SUCCESS";
export const ADD_APPAREL_FAILURE = "ADD_APPAREL_FAILURE";

export const UPDATE_APPAREL_REQUEST = "UPDATE_APPAREL_REQUEST";
export const UPDATE_APPAREL_SUCCESS = "UPDATE_APPAREL_SUCCESS";
export const UPDATE_APPAREL_FAILURE = "UPDATE_APPAREL_FAILURE";

export const GET_APPAREL_REQUEST = "GET_APPAREL_REQUEST";
export const GET_APPAREL_SUCCESS = "GET_APPAREL_SUCCESS";
export const GET_APPAREL_FAILURE = "GET_APPAREL_FAILURE";

export const ADD_APPAREL_DATA_BY_APPAREL_ID_REQUEST =
  "ADD_APPAREL_DATA_BY_APPAREL_ID_REQUEST";
export const ADD_APPAREL_DATA_BY_APPAREL_ID_SUCCESS =
  "ADD_APPAREL_DATA_BY_APPAREL_ID_SUCCESS";
export const ADD_APPAREL_DATA_BY_APPAREL_ID_FAILURE =
  "ADD_APPAREL_DATA_BY_APPAREL_ID_FAILURE";

export const GET_APPAREL_DATA_BY_APPAREL_ID_REQUEST =
  "GET_APPAREL_DATA_BY_APPAREL_ID_REQUEST";
export const GET_APPAREL_DATA_BY_APPAREL_ID_SUCCESS =
  "GET_APPAREL_DATA_BY_APPAREL_ID_SUCCESS";
export const GET_APPAREL_DATA_BY_APPAREL_ID_FAILURE =
  "GET_APPAREL_DATA_BY_APPAREL_ID_FAILURE";

export const UPDATE_APPAREL_BY_APPAREL_ID_REQUEST =
  "UPDATE_APPAREL_BY_APPAREL_ID_REQUEST";
export const UPDATE_APPAREL_BY_APPAREL_ID_SUCCESS =
  "UPDATE_APPAREL_BY_APPAREL_ID_SUCCESS";
export const UPDATE_APPAREL_BY_APPAREL_ID_FAILURE =
  "GET_APPAREL_BY_APPAREL_ID_FAILURE";

export const GET_ALL_SUBCATEGORY_REQUEST = "GET_ALL_SUBCATEGORY_REQUEST";
export const GET_ALL_SUBCATEGORY_SUCCESS = "GET_ALL_SUBCATEGORY_SUCCESS";
export const GET_ALL_SUBCATEGORY_FAILURE = "GET_ALL_SUBCATEGORY_FAILURE";


