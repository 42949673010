import * as types from "./SurveyDashboardActionTypes";
import moment from "moment";
const initialState = {
  dateRangeList: [
    {
      id: 1,
      type: "year",
      value: "FY",
      starter: true,
      isSelected: true,
      startDate: moment()
        .startOf("year")
        .toISOString(),
      endDate: moment()
        .endOf("year")
        .toISOString(),
    },
    {
      id: 2,
      type: "quarter",
      value: "QTD",
      starter: false,
      isSelected: false,
      startDate: moment()
        .startOf("quarter")
        .toISOString(),
      endDate: moment()
        .endOf("quarter")
        .toISOString(),
    },
    {
      id: 3,
      type: "month",
      value: "MTD",
      starter: false,
      isSelected: false,
      startDate: moment()
        .startOf("month")
        .toISOString(),
      endDate: moment()
        .endOf("month")
        .toISOString(),
    },
    {
      id: 4,
      type: "week",
      value: "1W",
      starter: false,
      isSelected: false,
      startDate: moment()
        .startOf("week")
        .toISOString(),
      endDate: moment()
        .endOf("week")
        .toISOString(),
    },
  ],
  viewType: "dashboard",
  creatingSurvey: false,
  creatingSurveyError: false,

  fetchingSurveyExistingData: false,
  fetchingSurveyExistingDataError: false,
  surveyExistingData: [],
};
const newDateRange = (dateRange, newDate) =>
  dateRange.map((range) => {
    if (range.id === newDate.id) {
      return { ...range, isSelected: true };
    } else {
      return { ...range, isSelected: false };
    }
  });
export const surveyDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SURVEY_VIEW_TYPE:
      return { ...state, viewType: action.payload };
    case types.CHANGE_SELECTED_TIME_INTERVAL_SURVEY:
      return {
        ...state,
        dateRangeList: newDateRange(state.dateRangeList, action.payload),
        isCustomSelected: false,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        timeRangeType: action.payload.type,
      };
    case types.SET_TIME_INTERVAL_SURVEY:
      return {
        ...state,
        isCustomSelected: true,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    case types.CREATE_SURVEY_REQUEST:
      return { ...state, creatingSurvey: true };
    case types.CREATE_SURVEY_SUCCESS:
      return { ...state, creatingSurvey: false };
    case types.CREATE_SURVEY_FAILURE:
      return { ...state, creatingSurvey: false, creatingSurveyError: true };

    case types.GET_EXISTING_SURVEY_REQUEST:
      return { ...state, fetchingSurveyExistingData: true };
    case types.GET_EXISTING_SURVEY_SUCCESS:
      return {
        ...state,
        fetchingSurveyExistingData: false,
        surveyExistingData: action.payload,
      };
    case types.GET_EXISTING_SURVEY_FAILURE:
      return {
        ...state,
        fetchingSurveyExistingData: false,
        fetchingSurveyExistingDataError: true,
      };
    default:
      return state;
  }
};
