export const ADD_TAG_CONTACT_REQUEST = "ADD_TAG_CONTACT_REQUEST";
export const ADD_TAG_CONTACT_SUCCESS = "ADD_TAG_CONTACT_SUCCESS";
export const ADD_TAG_CONTACT_FAILURE = "ADD_TAG_CONTACT_FAILURE";

export const HANDLE_LINK_CONFIGURE_MODAL = "HANDLE_LINK_CONFIGURE_MODAL";

export const HANDLE_COSTING_MODAL = "HANDLE_COSTING_MODAL";

export const GET_OPPORTUNITIES_REQUEST = "GET_OPPORTUNITIES_REQUEST";
export const GET_OPPORTUNITIES_SUCCESS = "GET_OPPORTUNITIES_SUCCESS";
export const GET_OPPORTUNITIES_FAILURE = "GET_OPPORTUNITIES_FAILURE";

export const ADD_DELIVERY_REQUEST = "ADD_DELIVERY_REQUEST";
export const ADD_DELIVERY_SUCCESS = "ADD_DELIVERY_SUCCESS";
export const ADD_DELIVERY_FAILURE = "ADD_DELIVERY_FAILURE";

export const DELETE_DOCUMENT_REQUEST = "DELETE_DOCUMENT_REQUEST";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAILURE = "DELETE_DOCUMENT_FAILURE";

export const PATCH_TASK_BY_ID_REQUEST = "PATCH_TASK_BY_ID_REQUEST";
export const PATCH_TASK_BY_ID_SUCCESS = "PATCH_TASK_BY_ID_SUCCESS";
export const PATCH_TASK_BY_ID_FAILURE = "PATCH_TASK_BY_ID_FAILURE";

export const ADD_APPROVE_REQUEST = "ADD_APPROVE_REQUEST";
export const ADD_APPROVE_SUCCESS = "ADD_APPROVE_SUCCESS";
export const ADD_APPROVE_FAILURE = "ADD_APPROVE_FAILURE";

export const AFTER_APPROVE_REQUEST = "AFTER_APPROVE_REQUEST";
export const AFTER_APPROVE_SUCCESS = "AFTER_APPROVE_SUCCESS";
export const AFTER_APPROVE_FAILURE = "AFTER_APPROVE_FAILURE";

export const GET_DELIVERY_REQUEST = "GET_DELIVERY_REQUEST";
export const GET_DELIVERY_SUCCESS = "GET_DELIVERY_SUCCESS";
export const GET_DELIVERY_FAILURE = "GET_DELIVERY_FAILURE";

export const GET_LATEST_OPPORTUNITIES_REQUEST =
  "GET_LATEST_OPPORTUNITIES_REQUEST";
export const GET_LATEST_OPPORTUNITIES_SUCCESS =
  "GET_LATEST_OPPORTUNITIES_SUCCESS";
export const GET_LATEST_OPPORTUNITIES_FAILURE =
  "GET_LATEST_OPPORTUNITIES_FAILURE";

export const GET_ALL_LATEST_OPPORTUNITIES_REQUEST =
  "GET_ALL_LATEST_OPPORTUNITIES_REQUEST";
export const GET_ALL_LATEST_OPPORTUNITIES_SUCCESS =
  "GET_ALL_LATEST_OPPORTUNITIES_SUCCESS";
export const GET_ALL_LATEST_OPPORTUNITIES_FAILURE =
  "GET_ALL_LATEST_OPPORTUNITIES_FAILURE";

export const GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_REQUEST =
  "GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_REQUEST";
export const GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_SUCCESS =
  "GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_SUCCESS";
export const GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_FAILURE =
  "GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_FAILURE";

export const GET_OPPORTUNITIES_BY_PRICE_REQUEST =
  "GET_OPPORTUNITIES_BY_PRICE_REQUEST";
export const GET_OPPORTUNITIES_BY_PRICE_SUCCESS =
  "GET_OPPORTUNITIES_BY_PRICE_SUCCESS";
export const GET_OPPORTUNITIES_BY_PRICE_FAILURE =
  "GET_OPPORTUNITIES_BY_PRICE_FAILURE";

export const GET_OPPORTUNITIES_BY_WINLOSS_REQUEST =
  "GET_OPPORTUNITIES_BY_WINLOSS_REQUEST";
export const GET_OPPORTUNITIES_BY_WINLOSS_SUCCESS =
  "GET_OPPORTUNITIES_BY_WINLOSS_SUCCESS";
export const GET_OPPORTUNITIES_BY_WINLOSS_FAILURE =
  "GET_OPPORTUNITIES_BY_WINLOSS_FAILURE";

export const GET_ALL_OPPORTUNITIES_BY_PRICE_REQUEST =
  "GET_ALL_OPPORTUNITIES_BY_PRICE_REQUEST";
export const GET_ALL_OPPORTUNITIES_BY_PRICE_SUCCESS =
  "GET_ALL_OPPORTUNITIES_BY_PRICE_SUCCESS";
export const GET_ALL_OPPORTUNITIES_BY_PRICE_FAILURE =
  "GET_ALL_OPPORTUNITIES_BY_PRICE_FAILURE";

export const GET_OPPORTUNITY_BY_ID_REQUEST = "GET_OPPORTUNITY_BY_ID_REQUEST";
export const GET_OPPORTUNITY_BY_ID_SUCCESS = "GET_OPPORTUNITY_BY_ID_SUCCESS";
export const GET_OPPORTUNITY_BY_ID_FAILURE = "GET_OPPORTUNITY_BY_ID_FAILURE";

export const UPDATE_OPPORTUNITY_BY_ID_REQUEST =
  "UPDATE_OPPORTUNITY_BY_ID_REQUEST";
export const UPDATE_OPPORTUNITY_BY_ID_SUCCESS =
  "UPDATE_OPPORTUNITY_BY_ID_SUCCESS";
export const UPDATE_OPPORTUNITY_BY_ID_FAILURE =
  "UPDATE_OPPORTUNITY_BY_ID_FAILURE";

export const GET_ACCOUNT_LIST_BY_OPPORTUNITY_ID_REQUEST =
  "GET_ACCOUNT_LIST_BY_OPPORTUNITY_ID_REQUEST";
export const GET_ACCOUNT_LIST_BY_OPPORTUNITY_ID_SUCCESS =
  "GET_ACCOUNT_LIST_BY_OPPORTUNITY_ID_SUCCESS";
export const GET_ACCOUNT_LIST_BY_OPPORTUNITY_ID_FAILURE =
  "GET_ACCOUNT_LIST_BY_OPPORTUNITY_ID_FAILURE";

export const UPDATE_ACCOUNT_LIST_BY_OPPORTUNITY_ID_REQUEST =
  "UPDATE_ACCOUNT_LIST_BY_OPPORTUNITY_ID_REQUEST";
export const UPDATE_ACCOUNT_LIST_BY_OPPORTUNITY_ID_SUCCESS =
  "UPDATE_ACCOUNT_LIST_BY_OPPORTUNITY_ID_SUCCESS";
export const UPDATE_ACCOUNT_LIST_BY_OPPORTUNITY_ID_FAILURE =
  "UPDATE_ACCOUNT_LIST_BY_OPPORTUNITY_ID_FAILURE";

export const GET_CONTACT_LIST_BY_OPPORTUNITY_ID_REQUEST =
  "GET_CONTACT_LIST_BY_OPPORTUNITY_ID_REQUEST";
export const GET_CONTACT_LIST_BY_OPPORTUNITY_ID_SUCCESS =
  "GET_CONTACT_LIST_BY_OPPORTUNITY_ID_SUCCESS";
export const GET_CONTACT_LIST_BY_OPPORTUNITY_ID_FAILURE =
  "GET_CONTACT_LIST_BY_OPPORTUNITY_ID_FAILURE";

export const UPDATE_CONTACT_LIST_BY_OPPORTUNITY_ID_REQUEST =
  "UPDATE_CONTACT_LIST_BY_OPPORTUNITY_ID_REQUEST";
export const UPDATE_CONTACT_LIST_BY_OPPORTUNITY_ID_SUCCESS =
  "UPDATE_CONTACT_LIST_BY_OPPORTUNITY_ID_SUCCESS";
export const UPDATE_CONTACT_LIST_BY_OPPORTUNITY_ID_FAILURE =
  "UPDATE_CONTACT_LIST_BY_OPPORTUNITY_ID_FAILURE";

export const LINK_CONTACTS_TO_OPPORTUNITY_ID_REQUEST =
  "LINK_CONTACTS_TO_OPPORTUNITY_ID_REQUEST";
export const LINK_CONTACTS_TO_OPPORTUNITY_ID_SUCCESS =
  "LINK_CONTACTS_TO_OPPORTUNITY_ID_SUCCESS";
export const LINK_CONTACTS_TO_OPPORTUNITY_ID_FAILURE =
  "LINK_CONTACTS_TO_OPPORTUNITY_ID_FAILURE";

export const LINK_CONTACTS_TO_CHECK_OPPORTUNITY_ID_REQUEST =
  "LINK_CONTACTS_TO_CHECK_OPPORTUNITY_ID_REQUEST";
export const LINK_CONTACTS_TO_CHECK_OPPORTUNITY_ID_SUCCESS =
  "LINK_CONTACTS_TO_CHECK_OPPORTUNITY_ID_SUCCESS";
export const LINK_CONTACTS_TO_CHECK_OPPORTUNITY_ID_FAILURE =
  "LINK_CONTACTS_TO_CHECK_OPPORTUNITY_ID_FAILURE";

export const UPDATE_CONTACT_ROLE_BY_OPPORTUNITY_ID_REQUEST =
  "UPDATE_CONTACT_ROLE_BY_OPPORTUNITY_ID_REQUEST";
export const UPDATE_CONTACT_ROLE_BY_OPPORTUNITY_ID_SUCCESS =
  "UPDATE_CONTACT_ROLE_BY_OPPORTUNITY_ID_SUCCESS";
export const UPDATE_CONTACT_ROLE_BY_OPPORTUNITY_ID_FAILURE =
  "UPDATE_CONTACT_ROLE_BY_OPPORTUNITY_ID_FAILURE";

export const GET_NOTES_LIST_BY_OPPORTUNITY_ID_REQUEST =
  "GET_NOTES_LIST_BY_OPPORTUNITY_ID_REQUEST";
export const GET_NOTES_LIST_BY_OPPORTUNITY_ID_SUCCESS =
  "GET_NOTES_LIST_BY_OPPORTUNITY_ID_SUCCESS";
export const GET_NOTES_LIST_BY_OPPORTUNITY_ID_FAILURE =
  "GET_NOTES_LIST_BY_OPPORTUNITY_ID_FAILURE";

export const GET_NOTES_LIST_BY_OPPORTUNITY_LINK_TASK_ID_REQUEST =
  "GET_NOTES_LIST_BY_OPPORTUNITY_LINK_TASK_ID_REQUEST";
export const GET_NOTES_LIST_BY_OPPORTUNITY_LINK_TASK_ID_SUCCESS =
  "GET_NOTES_LIST_BY_OPPORTUNITY_LINK_TASK_ID_SUCCESS";
export const GET_NOTES_LIST_BY_OPPORTUNITYLINK_TASK_ID_FAILURE =
  "GET_NOTES_LIST_BY_OPPORTUNITYLINK_TASK_ID_FAILURE";

export const UPDATE_NOTES_LIST_BY_OPPORTUNITY_ID_REQUEST =
  "UPDATE_NOTES_LIST_BY_OPPORTUNITY_ID_REQUEST";
export const UPDATE_NOTES_LIST_BY_OPPORTUNITY_ID_SUCCESS =
  "UPDATE_NOTES_LIST_BY_OPPORTUNITY_ID_SUCCESS";
export const UPDATE_NOTES_LIST_BY_OPPORTUNITY_ID_FAILURE =
  "UPDATE_NOTES_LIST_BY_OPPORTUNITY_ID_FAILURE";

export const GET_CALLS_LIST_BY_OPPORTUNITY_ID_REQUEST =
  "GET_CALLS_LIST_BY_OPPORTUNITY_ID_REQUEST";
export const GET_CALLS_LIST_BY_OPPORTUNITY_ID_SUCCESS =
  "GET_CALLS_LIST_BY_OPPORTUNITY_ID_SUCCESS";
export const GET_CALLS_LIST_BY_OPPORTUNITY_ID_FAILURE =
  "GET_CALLS_LIST_BY_OPPORTUNITY_ID_FAILURE";

export const UPDATE_CALLS_LIST_BY_OPPORTUNITY_ID_REQUEST =
  "UPDATE_CALLS_LIST_BY_OPPORTUNITY_ID_REQUEST";
export const UPDATE_CALLS_LIST_BY_OPPORTUNITY_ID_SUCCESS =
  "UPDATE_CALLS_LIST_BY_OPPORTUNITY_ID_SUCCESS";
export const UPDATE_CALLS_LIST_BY_OPPORTUNITY_ID_FAILURE =
  "UPDATE_CALLS_LIST_BY_OPPORTUNITY_ID_FAILURE";

export const GET_EVENTS_LIST_BY_OPPORTUNITY_ID_REQUEST =
  "GET_EVENTS_LIST_BY_OPPORTUNITY_ID_REQUEST";
export const GET_EVENTS_LIST_BY_OPPORTUNITY_ID_SUCCESS =
  "GET_EVENTS_LIST_BY_OPPORTUNITY_ID_SUCCESS";
export const GET_EVENTS_LIST_BY_OPPORTUNITY_ID_FAILURE =
  "GET_EVENTS_LIST_BY_OPPORTUNITY_ID_FAILURE";

export const UPDATE_EVENTS_LIST_BY_OPPORTUNITY_ID_REQUEST =
  "UPDATE_EVENTS_LIST_BY_OPPORTUNITY_ID_REQUEST";
export const UPDATE_EVENTS_LIST_BY_OPPORTUNITY_ID_SUCCESS =
  "UPDATE_EVENTS_LIST_BY_OPPORTUNITY_ID_SUCCESS";
export const UPDATE_EVENTS_LIST_BY_OPPORTUNITY_ID_FAILURE =
  "UPDATE_EVENTS_LIST_BY_OPPORTUNITY_ID_FAILURE";

export const GET_TASKS_LIST_BY_OPPORTUNITY_ID_REQUEST =
  "GET_TASKS_LIST_BY_OPPORTUNITY_ID_REQUEST";
export const GET_TASKS_LIST_BY_OPPORTUNITY_ID_SUCCESS =
  "GET_TASKS_LIST_BY_OPPORTUNITY_ID_SUCCESS";
export const GET_TASKS_LIST_BY_OPPORTUNITY_ID_FAILURE =
  "GET_TASKS_LIST_BY_OPPORTUNITY_ID_FAILURE";

export const UPDATE_TASKS_LIST_BY_OPPORTUNITY_ID_REQUEST =
  "UPDATE_TASKS_LIST_BY_OPPORTUNITY_ID_REQUEST";
export const UPDATE_TASKS_LIST_BY_OPPORTUNITY_ID_SUCCESS =
  "UPDATE_TASKS_LIST_BY_OPPORTUNITY_ID_SUCCESS";
export const UPDATE_TASKS_LIST_BY_OPPORTUNITY_ID_FAILURE =
  "UPDATE_TASKS_LIST_BY_OPPORTUNITY_ID_FAILURE";

export const ADD_OPPORTUNITY_REQUEST = "ADD_OPPORTUNITY_REQUEST";
export const ADD_OPPORTUNITY_SUCCESS = "ADD_OPPORTUNITY_SUCCESS";
export const ADD_OPPORTUNITY_FAILURE = "ADD_OPPORTUNITY_FAILURE";

export const UPDATE_OPPORTUNITY_STAGE_REQUEST =
  "UPDATE_OPPORTUNITY_STAGE_REQUEST";
export const UPDATE_OPPORTUNITY_STAGE_SUCCESS =
  "UPDATE_OPPORTUNITY_STAGE_SUCCESS";
export const UPDATE_OPPORTUNITY_STAGE_FAILURE =
  "UPDATE_OPPORTUNITY_STAGE_FAILURE";

export const GET_STAGES_REQUEST = "GET_STAGES_REQUEST";
export const GET_STAGES_SUCCESS = "GET_STAGES_SUCCESS";
export const GET_STAGES_FAILURE = "GET_STAGES_FAILURE";

export const ADD_STAGE_REQUEST = "ADD_STAGE_REQUEST";
export const ADD_STAGE_SUCCESS = "ADD_STAGE_SUCCESS";
export const ADD_STAGE_FAILURE = "ADD_STAGE_FAILURE";

export const REMOVE_STAGE_REQUEST = "REMOVE_STAGE_REQUEST";
export const REMOVE_STAGE_SUCCESS = "REMOVE_STAGE_SUCCESS";
export const REMOVE_STAGE_FAILURE = "REMOVE_STAGE_FAILURE";

export const UPDATE_STAGE_REQUEST = "UPDATE_STAGE_REQUEST";
export const UPDATE_STAGE_SUCCESS = "UPDATE_STAGE_SUCCESS";
export const UPDATE_STAGE_FAILURE = "UPDATE_STAGE_FAILURE";

export const GET_SOURCES_REQUEST = "GET_SOURCES_REQUEST";
export const GET_SOURCES_SUCCESS = "GET_SOURCES_SUCCESS";
export const GET_SOURCES_FAILURE = "GET_SOURCES_FAILURE";

export const ADD_SOURCE_REQUEST = "ADD_SOURCE_REQUEST";
export const ADD_SOURCE_SUCCESS = "ADD_SOURCE_SUCCESS";
export const ADD_SOURCE_FAILURE = "ADD_SOURCE_FAILURE";

export const REMOVE_SOURCE_REQUEST = "REMOVE_SOURCE_REQUEST";
export const REMOVE_SOURCE_SUCCESS = "REMOVE_SOURCE_SUCCESS";
export const REMOVE_SOURCE_FAILURE = "REMOVE_SOURCE_FAILURE";

export const UPDATE_SOURCE_REQUEST = "UPDATE_SOURCE_REQUEST";
export const UPDATE_SOURCE_SUCCESS = "UPDATE_SOURCE_SUCCESS";
export const UPDATE_SOURCE_FAILURE = "UPDATE_SOURCE_FAILURE";

export const GET_COUNTRIES_REQUEST = "GET_COUNTRIES_REQUEST";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";

export const GET_CURRENCY_REQUEST = "GET_CURRENCY_REQUEST";
export const GET_CURRENCY_SUCCESS = "GET_CURRENCY_SUCCESS";
export const GET_CURRENCY_FAILURE = "GET_CURRENCY_FAILURE";

export const GET_OPPORTUNITY_DOCUMENTS_REQUEST =
  "GET_OPPORTUNITY_DOCUMENTS_REQUEST";
export const GET_OPPORTUNITY_DOCUMENTS_SUCCESS =
  "GET_OPPORTUNITY_DOCUMENTS_SUCCESS";
export const GET_OPPORTUNITY_DOCUMENTS_FAILURE =
  "GET_OPPORTUNITY_DOCUMENTS_FAILURE";

export const ADD_OPPORTUNITY_DOCUMENT_REQUEST =
  "ADD_OPPORTUNITY_DOCUMENT_REQUEST";
export const ADD_OPPORTUNITY_DOCUMENT_SUCCESS =
  "ADD_OPPORTUNITY_DOCUMENT_SUCCESS";
export const ADD_OPPORTUNITY_DOCUMENT_FAILURE =
  "ADD_OPPORTUNITY_DOCUMENT_FAILURE";

export const GET_OPPORTUNITY_VELOCITY_REQUEST =
  "GET_OPPORTUNITY_VELOCITY_REQUEST";
export const GET_OPPORTUNITY_VELOCITY_SUCCESS =
  "GET_OPPORTUNITY_VELOCITY_SUCCESS";
export const GET_OPPORTUNITY_VELOCITY_FAILURE =
  "GET_OPPORTUNITY_VELOCITY_FAILURE";

export const GET_OPPORTUNITY_VELOCITY_BY_ORGANIZATION_ID_REQUEST =
  "GET_OPPORTUNITY_VELOCITY_BY_ORGANIZATION_ID_REQUEST";
export const GET_OPPORTUNITY_VELOCITY_BY_ORGANIZATION_ID_SUCCESS =
  "GET_OPPORTUNITY_VELOCITY_BY_ORGANIZATION_ID_SUCCESS";
export const GET_OPPORTUNITY_VELOCITY_BY_ORGANIZATION_ID_FAILURE =
  "GET_OPPORTUNITY_VELOCITY_BY_ORGANIZATION_ID_FAILURE";

export const GET_OPPORTUNITY_DEVIATION_REQUEST =
  "GET_OPPORTUNITY_DEVIATION_REQUEST";
export const GET_OPPORTUNITY_DEVIATION_SUCCESS =
  "GET_OPPORTUNITY_DEVIATION_SUCCESS";
export const GET_OPPORTUNITY_DEVIATION_FAILURE =
  "GET_OPPORTUNITY_DEVIATION_FAILURE";

export const HANDLE_OPPORTUNITY_MODAL = "HANDLE_OPPORTUNITY_MODAL";
export const HANDLE_LINK_DELIVERY_MODAL = " HANDLE_LINK_DELIVERY_MODAL";
export const HANDLE_LINK_OPPORTUNITY_MODAL = "HANDLE_LINK_OPPORTUNITY_MODAL";
export const HANDLE_LINK_PROFESSIONALDUCT_MODAL =
  "HANDLE_LINK_PROFESSIONALDUCT_MODAL";
export const HANDLE_LINK_SERVICE_MODAL = "HANDLE_LINK_SERVICE_MODAL";
export const HANDLE_DOCUMENT_UPLOAD_MODAL = "HANDLE_DOCUMENT_UPLOAD_MODAL";
export const HANDLE_OPPORTUNITY_DRAWER = "HANDLE_OPPORTUNITY_DRAWER";

export const SET_CLEARBIT_DATA = "SET_CLEARBIT_DATA";

export const SET_OPPORTUNITY_VIEW_TYPE = "SET_OPPORTUNITY_VIEW_TYPE";
export const SET_OPPORTUNITY_FILTER_TEXT = "SET_OPPORTUNITY_FILTER_TEXT";
export const SET_OPPORTUNITY_FILTER_USER = "SET_OPPORTUNITY_FILTER_USER";

export const GET_HISTORICAL_PROFESSIONALPOSAL_AMOUNT_REQUEST =
  "GET_HISTORICAL_PROFESSIONALPOSAL_AMOUNT_REQUEST";
export const GET_HISTORICAL_PROFESSIONALPOSAL_AMOUNT_SUCCESS =
  "GET_HISTORICAL_PROFESSIONALPOSAL_AMOUNT_SUCCESS";
export const GET_HISTORICAL_PROFESSIONALPOSAL_AMOUNT_FAILURE =
  "GET_HISTORICAL_PROFESSIONALPOSAL_AMOUNT_FAILURE";

export const LINK_PROFESSIONALDUCT_TO_OPPORTUNITY_REQUEST =
  "LINK_PROFESSIONALDUCT_TO_OPPORTUNITY_REQUEST";
export const LINK_PROFESSIONALDUCT_TO_OPPORTUNITY_SUCCESS =
  "LINK_PROFESSIONALDUCT_TO_OPPORTUNITY_SUCCESS";
export const LINK_PROFESSIONALDUCT_TO_OPPORTUNITY_FAILURE =
  "LINK_PROFESSIONALDUCT_TO_OPPORTUNITY_FAILURE";

export const LINK_SERVICE_TO_OPPORTUNITY_REQUEST =
  "LINK_SERVICE_TO_OPPORTUNITY_REQUEST";
export const LINK_SERVICE_TO_OPPORTUNITY_SUCCESS =
  "LINK_SERVICE_TO_OPPORTUNITY_SUCCESS";
export const LINK_SERVICE_TO_OPPORTUNITY_FAILURE =
  "LINK_SERVICE_TO_OPPORTUNITY_FAILURE";

export const FETCHING_PROFESSIONALDUCTS_BY_OPPORTUNITY_ID_REQUEST =
  "FETCHING_PROFESSIONALDUCTS_BY_OPPORTUNITY_ID_REQUEST";
export const FETCHING_PROFESSIONALDUCTS_BY_OPPORTUNITY_ID_SUCCESS =
  "FETCHING_PROFESSIONALDUCTS_BY_OPPORTUNITY_ID_SUCCESS";
export const FETCHING_PROFESSIONALDUCTS_BY_OPPORTUNITY_ID_FAILURE =
  "FETCHING_PROFESSIONALDUCTS_BY_OPPORTUNITY_ID_FAILURE";

export const FETCHING_SERVICE_BY_OPPORTUNITY_ID_REQUEST =
  "FETCHING_SERVICE_BY_OPPORTUNITY_ID_REQUEST";
export const FETCHING_SERVICE_BY_OPPORTUNITY_ID_SUCCESS =
  "FETCHING_SERVICE_BY_OPPORTUNITY_ID_SUCCESS";
export const FETCHING_SERVICE_BY_OPPORTUNITY_ID_FAILURE =
  "FETCHING_SERVICE_BY_OPPORTUNITY_ID_FAILURE";

export const ADD_REASON_REQUEST = "ADD_REASON_REQUEST";
export const ADD_REASON_SUCCESS = "ADD_REASON_SUCCESS";
export const ADD_REASON_FAILURE = "ADD_REASON_FAILURE";

export const FETCH_QUOTATION_REQUEST = "FETCH_QUOTATION_REQUEST";
export const FETCH_QUOTATION_SUCCESS = "FETCH_QUOTATION_SUCCESS";
export const FETCH_QUOTATION_FAILURE = "FETCH_QUOTATION_FAILURE";

export const GENERATE_QUOTATION_REQUEST = "GENERATE_QUOTATION_REQUEST";
export const GENERATE_QUOTATION_SUCCESS = "GENERATE_QUOTATION_SUCCESS";
export const GENERATE_QUOTATION_FAILURE = "GENERATE_QUOTATION_FAILURE";

export const GET_CURRENT_QUOTATION_REQUEST = "GET_CURRENT_QUOTATION_REQUEST";
export const GET_CURRENT_QUOTATION_SUCCESS = "GET_CURRENT_QUOTATION_SUCCESS";
export const GET_CURRENT_QUOTATION_FAILURE = "GET_CURRENT_QUOTATION_FAILURE";

export const APPROVED_QUOTATION_REQUEST = "APPROVED_QUOTATION_REQUEST";
export const APPROVED_QUOTATION_SUCCESS = "APPROVED_QUOTATION_SUCCESS";
export const APPROVED_QUOTATION_FAILURE = "APPROVED_QUOTATION_FAILURE";

//Recruit
export const HANDLE_RECRUIT_MODAL = "HANDLE_RECRUI_MODAL";
export const LINK_RECRUIT_TO_OPPORTUNITY_REQUEST =
  "LINK_RECRUIT_TO_OPPORTUNITY_REQUEST";
export const LINK_RECRUIT_TO_OPPORTUNITY_SUCCESS =
  "LINK_RECRUIT_TO_OPPORTUNITY_SUCCESS";
export const LINK_RECRUIT_TO_OPPORTUNITY_FAILURE =
  "LINK_RECRUIT_TO_OPPORTUNITY_FAILURE";

export const GET_RECRUIT_TO_OPPORTUNITY_REQUEST =
  "GET_RECRUIT_TO_OPPORTUNITY_REQUEST";
export const GET_RECRUIT_TO_OPPORTUNITY_SUCCESS =
  "GET_RECRUIT_TO_OPPORTUNITY_SUCCESS";
export const GET_RECRUIT_TO_OPPORTUNITY_FAILURE =
  "GET_RECRUIT_TO_OPPORTUNITY_FAILURE";

export const LINK_RECRUIT_CATEGORY_TO_OPPORTUNITY_REQUEST =
  "LINK_RECRUIT_CATEGORY_TO_OPPORTUNITY_REQUEST";
export const LINK_RECRUIT_CATEGORY_TO_OPPORTUNITY_SUCCESS =
  "LINK_RECRUIT_CATEGORY_TO_OPPORTUNITY_SUCCESS";
export const LINK_RECRUIT_CATEGORY_TO_OPPORTUNITY_FAILURE =
  "LINK_RECRUIT_CATEGORY_TO_OPPORTUNITY_FAILURE";

export const LINK_RECRUIT_CONTACT_TO_OPPORTUNITY_REQUEST =
  "LINK_RECRUIT_CONTACT_TO_OPPORTUNITY_REQUEST";
export const LINK_RECRUIT_CONTACT_TO_OPPORTUNITY_SUCCESS =
  "LINK_RECRUIT_CONTACT_TO_OPPORTUNITY_SUCCESS";
export const LINK_RECRUIT_CONTACT_TO_OPPORTUNITY_FAILURE =
  "LINK_RECRUIT_CONTACT_TO_OPPORTUNITY_FAILURE";

export const LINK_RECRUIT_STAGE_TO_OPPORTUNITY_REQUEST =
  "LINK_RECRUIT_STAGE_TO_OPPORTUNITY_REQUEST";
export const LINK_RECRUIT_STAGE_TO_OPPORTUNITY_SUCCESS =
  "LINK_RECRUIT_STAGE_TO_OPPORTUNITY_SUCCESS";
export const LINK_RECRUIT_STAGE_TO_OPPORTUNITY_FAILURE =
  "LINK_RECRUIT_STAGE_TO_OPPORTUNITY_FAILURE";

export const LINK_RECRUIT_STATUS_TO_OPPORTUNITY_REQUEST =
  "LINK_RECRUIT_STATUS_TO_OPPORTUNITY_REQUEST";
export const LINK_RECRUIT_STATUS_TO_OPPORTUNITY_SUCCESS =
  "LINK_RECRUIT_STATUS_TO_OPPORTUNITY_SUCCESS";
export const LINK_RECRUIT_STATUS_TO_OPPORTUNITY_FAILURE =
  "LINK_RECRUIT_STATUS_TO_OPPORTUNITY_FAILURE";

export const GET_QUOTE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_REQUEST =
  "GET_QUOTE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_REQUEST";
export const GET_QUOTE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_SUCCESS =
  "GET_QUOTE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_SUCCESS";
export const GET_QUOTE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_FAILURE =
  "GET_QUOTE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_FAILURE";

export const GET_QUOTE_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST =
  "GET_QUOTE_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST";
export const GET_QUOTE_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS =
  "GET_QUOTE_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS";
export const GET_QUOTE_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE =
  "GET_QUOTE_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE";

export const GET_INVOICE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_REQUEST =
  "GET_INVOICE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_REQUEST";
export const GET_INVOICE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_SUCCESS =
  "GET_INVOICE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_SUCCESS";
export const GET_INVOICE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_FAILURE =
  "GET_INVOICE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_FAILURE";

export const GET_INVOICE_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST =
  "GET_INVOICE_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST";
export const GET_INVOICE_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS =
  "GET_INVOICE_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS";
export const GET_INVOICE_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE =
  "GET_INVOICE_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE";

export const UPDATE_OPPORTUNITY_BY_PARTICULAR_OPPORTUNIY_STAGE_REQUEST =
  "UPDATE_OPPORTUNITY_BY_PARTICULAR_OPPORTUNIY_STAGE_REQUEST";
export const UPDATE_OPPORTUNITY_BY_PARTICULAR_OPPORTUNIY_STAGE_SUCCESS =
  "UPDATE_OPPORTUNITY_BY_PARTICULAR_OPPORTUNIY_STAGE_SUCCESS";
export const UPDATE_OPPORTUNITY_BY_PARTICULAR_OPPORTUNIY_STAGE_FAILURE =
  "UPDATE_OPPORTUNITY_BY_PARTICULAR_OPPORTUNIY_STAGE_FAILURE";

export const EMAIL_SEND_FOR_QUOTE_REQUEST = "EMAIL_SEND_FOR_QUOTE_REQUEST";
export const EMAIL_SEND_FOR_QUOTE_SUCCESS = "EMAIL_SEND_FOR_QUOTE_SUCCESS";
export const EMAIL_SEND_FOR_QUOTE_FAILURE = "EMAIL_SEND_FOR_QUOTE_FAILURE";

export const EMAIL_SEND_FOR_INVOICE_REQUEST = "EMAIL_SEND_FOR_INVOICE_REQUEST";
export const EMAIL_SEND_FOR_INVOICE_SUCCESS = "EMAIL_SEND_FOR_INVOICE_SUCCESS";
export const EMAIL_SEND_FOR_INVOICE_FAILURE = "EMAIL_SEND_FOR_INVOICE_FAILURE";

export const EMAIL_SEND_FOR_RECRUITMENT_REQUEST =
  "EMAIL_SEND_FOR_RECRUITMENT_REQUEST";
export const EMAIL_SEND_FOR_RECRUITMENT_SUCCESS =
  "EMAIL_SEND_FOR_RECRUITMENT_SUCCESS";
export const EMAIL_SEND_FOR_RECRUITMENT_FAILURE =
  "EMAIL_SEND_FOR_RECRUITMENT_FAILURE";

export const SET_CURRENT_RECRUITMENT_DATA = "SET_CURRENT_RECRUITMENT_DATA";

export const UPDATE_RECRUITMENT_REQUEST = "UPDATE_RECRUITMENT_REQUEST";
export const UPDATE_RECRUITMENT_SUCCESS = "UPDATE_RECRUITMENT_SUCCESS";
export const UPDATE_RECRUITMENT_FAILURE = "UPDATE_RECRUITMENT_FAILURE";

export const HANDLE_AGEING_MODAL = "HANDLE_AGEING_MODAL";
export const HANDLE_STAGE_MODAL = "HANDLE_STAGE_MODAL";
export const HANDLE_MEETINGS_MODAL = "HANDLE_MEETINGS_MODAL";
export const HANDLE_CALLS_MODAL = "HANDLE_CALLS_MODAL";

export const HANDLE_TAGPROFILE_MODAL = "HANDLE_TAGPROFILE_MODAL";
export const HANDLE_REMARKS_MODAL = "HANDLE_REMARKS_MODAL";

//Profile
export const LINK_PROFILE_TO_OPPORTUNITY_REQUEST =
  "LINK_PROFILE_TO_OPPORTUNITY_REQUEST";
export const LINK_PROFILE_TO_OPPORTUNITY_SUCCESS =
  "LINK_PROFILE_TO_OPPORTUNITY_SUCCESS";
export const LINK_PROFILE_TO_OPPORTUNITY_FAILURE =
  "LINK_PROFILE_TO_OPPORTUNITY_FAILURE";

export const ADD_RECRUITMENT_PROFILE_REQUEST =
  "ADD_RECRUITMENT_PROFILE_REQUEST";
export const ADD_RECRUITMENT_PROFILE_SUCCESS =
  "ADD_RECRUITMENT_PROFILE_SUCCESS";
export const ADD_RECRUITMENT_PROFILE_FAILURE =
  "ADD_RECRUITMENT_PROFILE_FAILURE";

export const ADD_REMARK_REQUEST = "ADD_REMARK_REQUEST";
export const ADD_REMARK_SUCCESS = "ADD_REMARK_SUCCESS";
export const ADD_REMARK_FAILURE = "ADD_REMARK_FAILURE";

export const GET_REMARK_REQUEST = "GET_REMARK_REQUEST";
export const GET_REMARK_SUCCESS = "GET_REMARK_SUCCESS";
export const GET_REMARK_FAILURE = "GET_REMARK_FAILURE";

//RecruimentDashBoard (For Jumpstart)

export const GET_ALL_RECRUITMENT_BY_OPP_ID_REQUEST =
  "GET_ALL_RECRUITMENT_BY_OPP_ID_REQUEST";
export const GET_ALL_RECRUITMENT_BY_OPP_ID_SUCCESS =
  "GET_ALL_RECRUITMENT_BY_OPP_ID_SUCCESS";
export const GET_ALL_RECRUITMENT_BY_OPP_ID_FAILURE =
  "GET_ALL_RECRUITMENT_BY_OPP_ID_FAILURE";

export const GET_RECRUITMENT_POSITION_BY_OPP_ID_REQUEST =
  "GET_RECRUITMENT_POSITION_BY_OPP_ID_REQUEST";
export const GET_RECRUITMENT_POSITION_BY_OPP_ID_SUCCESS =
  "GET_RECRUITMENT_POSITION_BY_OPP_ID_SUCCESS";
export const GET_RECRUITMENT_POSITION_BY_OPP_ID_FAILURE =
  "GET_RECRUITMENT_POSITION_BY_OPP_ID_FAILURE";

export const GET_RECRUITMENT_AVG_TIME_BY_OPP_ID_REQUEST =
  "GET_RECRUITMENT_AVG_TIME_BY_OPP_ID_REQUEST";
export const GET_RECRUITMENT_AVG_TIME_BY_OPP_ID_SUCCESS =
  "GET_RECRUITMENT_AVG_TIME_BY_OPP_ID_SUCCESS";
export const GET_RECRUITMENT_AVG_TIME_BY_OPP_ID_FAILURE =
  "GET_RECRUITMENT_AVG_TIME_BY_OPP_ID_FAILURE";

export const GET_ALL_RECRUITMENT_DETAILS_BY_OPP_ID_REQUEST =
  "GET_ALL_RECRUITMENT_DETAILS_BY_OPP_ID_REQUEST";
export const GET_ALL_RECRUITMENT_DETAILS_BY_OPP_ID_SUCCESS =
  "GET_ALL_RECRUITMENT_DETAILS_BY_OPP_ID_SUCCESS";
export const GET_ALL_RECRUITMENT_DETAILS_BY_OPP_ID_FAILURE =
  "GET_ALL_RECRUITMENT_DETAILS_BY_OPP_ID_FAILURE";

export const GET_RECRUITMENT_POSITION_FILLED_BY_OPP_ID_REQUEST =
  "GET_RECRUITMENT_POSITION_FILLED_BY_OPP_ID_REQUEST";
export const GET_RECRUITMENT_POSITION_FILLED_BY_OPP_ID_SUCCESS =
  "GET_RECRUITMENT_POSITION_FILLED_BY_OPP_ID_SUCCESS";
export const GET_RECRUITMENT_POSITION_FILLED_BY_OPP_ID_FAILURE =
  "GET_RECRUITMENT_POSITION_FILLED_BY_OPP_ID_FAILURE";

// export const GET_OPPORTUNITY_REQUEST = "GET_OPPORTUNITY_REQUEST";
// export const GET_OPPORTUNITY_SUCCESS = "GET_OPPORTUNITY_SUCCESS";
// export const GET_OPPORTUNITY_FAILURE = "GET_OPPORTUNITY_FAILURE";
//candidate switch
export const CANDIDATE_SWITCH_REQUEST = "CANDIDATE_SWITCH_REQUEST";
export const CANDIDATE_SWITCH_SUCCESS = "CANDIDATE_SWITCH_SUCCESS";
export const CANDIDATE_SWITCH_FAILURE = "CANDIDATE_SWITCH_FAILURE";

//Sponsor switch
export const SPONSOR_SWITCH_REQUEST = "SPONSOR_SWITCH_REQUEST";
export const SPONSOR_SWITCH_SUCCESS = "SPONSOR_SWITCH_SUCCESS";
export const SPONSOR_SWITCH_FAILURE = "SPONSOR_SWITCH_FAILURE";

export const HANDLE_SELECT_SPONSOR_MODAL = "HANDLE_SELECT_SPONSOR_MODAL";
export const GET_DRAGGABLE_OPPORTUNITY_REQUEST =
  "GET_DRAGGABLE_OPPORTUNITY_REQUEST";
export const GET_DRAGGABLE_OPPORTUNITY_SUCCESS =
  "GET_DRAGGABLE_OPPORTUNITY_SUCCESS";
export const GET_DRAGGABLE_OPPORTUNITY_FAILURE =
  "GET_DRAGGABLE_OPPORTUNITY_FAILURE";

export const ADD_DRAGGABLE_OPPORTUNITY_REQUEST =
  "ADD_DRAGGABLE_OPPORTUNITY_REQUEST";
export const ADD_DRAGGABLE_OPPORTUNITY_SUCCESS =
  "ADD_DRAGGABLE_OPPORTUNITY_SUCCESS";
export const ADD_DRAGGABLE_OPPORTUNITY_FAILURE =
  "ADD_DRAGGABLE_OPPORTUNITY_FAILURE";

export const DELETE_OPP_PRODUCT_REQUEST = "DELETE_OPP_PRODUCT_REQUEST";
export const DELETE_OPP_PRODUCT_SUCCESS = "DELETE_OPP_PRODUCT_SUCCESS";
export const DELETE_OPP_PRODUCT_FAILURE = "DELETE_OPP_PRODUCT_FAILURE";

export const DELETE_OPP_SERVICE_REQUEST = "DELETE_OPP_SERVICE_REQUEST";
export const DELETE_OPP_SERVICE_SUCCESS = "DELETE_OPP_SERVICE_SUCCESS";
export const DELETE_OPP_SERVICE_FAILURE = "DELETE_OPP_SERVICE_FAILURE";

export const HANDLE_PURCHASE_MODAL = "HANDLE_PURCHASE_MODAL";

export const ADD_PURCHASE_REQUEST = "ADD_PURCHASE_REQUEST";
export const ADD_PURCHASE_SUCCESS = "ADD_PURCHASE_SUCCESS";
export const ADD_PURCHASE_FAILURE = "ADD_PURCHASE_FAILURE";

export const GET_PURCHASE_REQUEST = "GET_PURCHASE_REQUEST";
export const GET_PURCHASE_SUCCESS = "GET_PURCHASE_SUCCESS";
export const GET_PURCHASE_FAILURE = "GET_PURCHASE_FAILURE";

export const HANDLE_PLAYBOOK_MODAL = "HANDLE_PLAYBOOK_MODAL";

export const GET_PLAYBOOK_REQUEST = "GET_PLAYBOOK_REQUEST";
export const GET_PLAYBOOK_SUCCESS = "GET_PLAYBOOK_SUCCESS";
export const GET_PLAYBOOK_FAILURE = "GET_PLAYBOOK_FAILURE";

export const GET_PLAYBOOK_OPPORTUNITY_DOCUMENTS_REQUEST =
  "GET_PLAYBOOK_OPPORTUNITY_DOCUMENTS_REQUEST";
export const GET_PLAYBOOK_OPPORTUNITY_DOCUMENTS_SUCCESS =
  "GET_PLAYBOOK_OPPORTUNITY_DOCUMENTS_SUCCESS";
export const GET_PLAYBOOK_OPPORTUNITY_DOCUMENTS_FAILURE =
  "GET_PLAYBOOK_OPPORTUNITY_DOCUMENTS_FAILURE";

export const GET_PLAYBOOK_OPPORTUNITY_YOUTUBE_REQUEST =
  "GET_PLAYBOOK_OPPORTUNITY_YOUTUBE_REQUEST";
export const GET_PLAYBOOK_OPPORTUNITY_YOUTUBE_SUCCESS =
  "GET_PLAYBOOK_OPPORTUNITY_YOUTUBE_SUCCESS";
export const GET_PLAYBOOK_OPPORTUNITY_YOUTUBE_FAILURE =
  "GET_PLAYBOOK_OPPORTUNITY_YOUTUBE_FAILURE";

export const CLEANUP_QUOTATION_TABLE = "CLEANUP_QUOTATION_TABLE";

//get playBooks by stage Id
export const GET_PLAYBOOK_BY_STAGEID_REQUEST =
  "GET_PLAYBOOK_BY_STAGEID_REQUEST";
export const GET_PLAYBOOK_BY_STAGEID_SUCCESS =
  "GET_PLAYBOOK_BY_STAGEID_SUCCESS";
export const GET_PLAYBOOK_BY_STAGEID_FAILURE =
  "GET_PLAYBOOK_BY_STAGEID_FAILURE";

//send Email on stage
export const SEND_EMAIL_STAGE_UPDATE_REQUEST =
  "SEND_EMAIL_STAGE_UPDATE_REQUEST";
export const SEND_EMAIL_STAGE_UPDATE_SUCCESS =
  "SEND_EMAIL_STAGE_UPDATE_SUCCESS";
export const SEND_EMAIL_STAGE_UPDATE_FAILURE =
  "SEND_EMAIL_STAGE_UPDATE_FAILURE";

//Check Quote approve by quote id
export const CHECK_QUOTE_APPROVED_BY_QUOTE_ID_REQUEST =
  "CHECK_QUOTE_APPROVED_BY_QUOTE_ID_REQUEST";
export const CHECK_QUOTE_APPROVED_BY_QUOTE_ID_SUCCESS =
  "CHECK_QUOTE_APPROVED_BY_QUOTE_ID_SUCCESS";
export const CHECK_QUOTE_APPROVED_BY_QUOTE_ID_FAILURE =
  "CHECK_QUOTE_APPROVED_BY_QUOTE_ID_FAILURE";

//Check Quote approve by Opportunity id
export const CHECK_QUOTE_APPROVED_BY_OPPORTUNITY_ID_REQUEST =
  "CHECK_QUOTE_APPROVED_BY_OPPORTUNITY_ID_REQUEST";
export const CHECK_QUOTE_APPROVED_BY_OPPORTUNITY_ID_SUCCESS =
  "CHECK_QUOTE_APPROVED_BY_OPPORTUNITY_ID_SUCCESS";
export const CHECK_QUOTE_APPROVED_BY_OPPORTUNITY_ID_FAILURE =
  "CHECK_QUOTE_APPROVED_BY_OPPORTUNITY_ID_FAILURE";
export const GET_ALL_LATEST_OPPORTUNITIES_FOR_LAZY_LOADING_REQUEST =
  "GET_ALL_LATEST_OPPORTUNITIES_FOR_LAZY_LOADING_REQUEST";
export const GET_ALL_LATEST_OPPORTUNITIES_FOR_LAZY_LOADING_SUCCESS =
  "GET_ALL_LATEST_OPPORTUNITIES_FOR_LAZY_LOADING_SUCCESS";
export const GET_ALL_LATEST_OPPORTUNITIES_FOR_LAZY_LOADING_FAILURE =
  "GET_ALL_LATEST_OPPORTUNITIES_FOR_LAZY_LOADING_FAILURE";

export const GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_FOR_LAZY_LOADING_REQUEST =
  "GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_FOR_LAZY_LOADING_REQUEST";
export const GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_FOR_LAZY_LOADING_SUCCESS =
  "GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_FOR_LAZY_LOADING_SUCCESS";
export const GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_FOR_LAZY_LOADING_FAILURE =
  "GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_FOR_LAZY_LOADING_FAILURE";

export const HANDLE_FASHION_MODAL = "HANDLE_FASHION_MODAL";
//Add fashion Item
export const ADD_FASHION_ITEM_REQUEST = "ADD_FASHION_ITEM_REQUEST";
export const ADD_FASHION_ITEM_SUCCESS = "ADD_FASHION_ITEM_SUCCESS";
export const ADD_FASHION_ITEM_FAILURE = "ADD_FASHION_ITEM_FAILURE";

//FETCH FASHION ITEM
export const GET_FASHION_ITEM_REQUEST = "GET_FASHION_ITEM_REQUEST";
export const GET_FASHION_ITEM_SUCCESS = "GET_FASHION_ITEM_SUCCESS";
export const GET_FASHION_ITEM_FAILURE = "GET_FASHION_ITEM_FAILURE";

//add image
export const ADD_UPLOAD_ITEM_REQUEST = "ADD_UPLOAD_ITEM_REQUEST";
export const ADD_UPLOAD_ITEM_SUCCESS = "ADD_UPLOAD_ITEM_SUCCESS";
export const ADD_UPLOAD_ITEM_FAILURE = "ADD_UPLOAD_ITEM_FAILURE";

//fetch
export const GET_UPLOAD_ITEM_REQUEST = "GET_UPLOAD_ITEM_REQUEST";
export const GET_UPLOAD_ITEM_SUCCESS = "GET_UPLOAD_ITEM_SUCCESS";
export const GET_UPLOAD_ITEM_FAILURE = "GET_UPLOAD_ITEM_FAILURE";

//upload
export const UPDATE_UPLOAD_ITEM_REQUEST = "UPDATE_UPLOAD_ITEM_REQUEST";
export const UPDATE_UPLOAD_ITEM_SUCCESS = "UPDATE_UPLOAD_ITEM_SUCCESS";
export const UPDATE_UPLOAD_ITEM_FAILURE = "UPDATE_UPLOAD_ITEM_FAILURE";

//get timeline data
export const GET_TIMELINE_DATA_BY_OPPORTUNITY_ID_REQUEST =
  "GET_TIMELINE_DATA_BY_OPPORTUNITY_ID_REQUEST";
export const GET_TIMELINE_DATA_BY_OPPORTUNITY_ID_SUCCESS =
  "GET_TIMELINE_DATA_BY_OPPORTUNITY_ID_SUCCESS";
export const GET_TIMELINE_DATA_BY_OPPORTUNITY_ID_FAILURE =
  "GET_TIMELINE_DATA_BY_OPPORTUNITY_ID_FAILURE";

export const HANDLE_FASHION_EDIT_MODAL = "HANDLE_FASHION_EDIT_MODAL";
export const GET_STAGE_CHECK_BY_STAGEID_REQUEST =
  "GET_STAGE_CHECK_BY_STAGEID_REQUEST";
export const GET_STAGE_CHECK_BY_STAGEID_SUCCESS =
  "GET_STAGE_CHECK_BY_STAGEID_SUCCESS";
export const GET_STAGE_CHECK_BY_STAGEID_FAILURE =
  "GET_STAGE_CHECK_BY_STAGEID_FAILURE";

// suspend

export const GET_STAGE_DELETE_BY_STAGEID_REQUEST =
  "GET_STAGE_DELETE_BY_STAGEID_REQUEST";
export const GET_STAGE_DELETE_BY_STAGEID_SUCCESS =
  "GET_STAGE_DELETE_BY_STAGEID_SUCCESS";
export const GET_STAGE_DELETE_BY_STAGEID_FAILURE =
  "GET_STAGE_DELETE_BY_STAGEID_FAILURE";

export const HANDLE_OPPORTUNITY_ACTIVITY_MODAL =
  "HANDLE_OPPORTUNITY_ACTIVITY_MODAL";

export const GET_OPPORTUNITY_ACTIVITY_DATA_REQUEST =
  "GET_OPPORTUNITY_ACTIVITY_DATA_REQUEST";
export const GET_OPPORTUNITY_ACTIVITY_DATA_SUCCESS =
  "GET_OPPORTUNITY_ACTIVITY_DATA_SUCCESS";
export const GET_OPPORTUNITY_ACTIVITY_DATA_FAILURE =
  "GET_OPPORTUNITY_ACTIVITY_DATA_SUCCESS";

export const GET_OPPORTUNITY_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST =
  "GET_OPPORTUNITY_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST";
export const GET_OPPORTUNITY_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS =
  "GET_OPPORTUNITY_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS";
export const GET_OPPORTUNITY_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE =
  "GET_OPPORTUNITY_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE";

export const GET_QUOTATION_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST =
  "GET_QUOTATION_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST";
export const GET_QUOTATION_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS =
  "GET_QUOTATION_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS";
export const GET_QUOTATION_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE =
  "GET_QUOTATION_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE";

export const GET_BILLING_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST =
  "GET_BILLING_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST";
export const GET_BILLING_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS =
  "GET_BILLING_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS";
export const GET_BILLING_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE =
  "GET_BILLING_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE";

export const GET_OPPORTUNITY_STAGE_DATA_REQUEST =
  "GET_OPPORTUNITY_STAGE_DATA_REQUEST";
export const GET_OPPORTUNITY_STAGE_DATA_SUCCESS =
  "GET_OPPORTUNITY_STAGE_DATA_SUCCESS";
export const GET_OPPORTUNITY_STAGE_DATA_FAILURE =
  "GET_OPPORTUNITY_STAGE_DATA_FAILURE";

export const ADD_OPPORTUNITY_STAGE_DISABLE_REQUEST =
  "ADD_OPPORTUNITY_STAGE_DISABLE_REQUEST";
export const ADD_OPPORTUNITY_STAGE_DISABLE_SUCCESS =
  "ADD_OPPORTUNITY_STAGE_DISABLE_SUCCESS";
export const ADD_OPPORTUNITY_STAGE_DISABLE_FAILURE =
  "ADD_OPPORTUNITY_STAGE_DISABLE_FAILURE";

export const SET_CLEARBIT_COSTING_DATA = "SET_CLEARBIT_COSTING_DATA";

export const UPDATE_FASHION_ITEM_REQUEST = "UPDATE_FASHION_ITEM_REQUEST";
export const UPDATE_FASHION_ITEM_SUCCESS = "UPDATE_FASHION_ITEM_SUCCESS";
export const UPDATE_FASHION_ITEM_FAILURE = "ADD_FASHION_ITEM_FAILURE";

export const HANDLE_GALLERY_MODAL = "HANDLE_GALLERY_MODAL";

export const ADD_GALLERY_ITEM_REQUEST = "ADD_GALLERY_ITEM_REQUEST";
export const ADD_GALLERY_ITEM_SUCCESS = "ADD_GALLERY_ITEM_SUCCESS";
export const ADD_GALLERY_ITEM_FAILURE = "ADD_GALLERY_ITEM_FAILURE";

export const FETCH_GALLERY_REQUEST = "FETCH_GALLERY_REQUEST";
export const FETCH_GALLERY_SUCCESS = "FETCH_GALLERY_SUCCESS";
export const FETCH_GALLERY_FAILURE = "FETCH_GALLERY_FAILURE";

export const FETCHING_COSTING_SERVICE_BY_OPPORTUNITY_ID_REQUEST =
  "FETCHING_COSTING_SERVICE_BY_OPPORTUNITY_ID_REQUEST";
export const FETCHING_COSTING_SERVICE_BY_OPPORTUNITY_ID_SUCCESS =
  "FETCHING_COSTING_SERVICE_BY_OPPORTUNITY_ID_SUCCESS";
export const FETCHING_COSTING_SERVICE_BY_OPPORTUNITY_ID_FAILURE =
  "FETCHING_COSTING_SERVICE_BY_OPPORTUNITY_ID_FAILURE";

export const FETCHING_COSTING_PRODUCT_BY_OPPORTUNITY_ID_REQUEST =
  "FETCHING_COSTING_PRODUCT_BY_OPPORTUNITY_ID_REQUEST";
export const FETCHING_COSTING_PRODUCT_BY_OPPORTUNITY_ID_SUCCESS =
  "FETCHING_COSTING_PRODUCT_BY_OPPORTUNITY_ID_SUCCESS";
export const FETCHING_COSTING_PRODUCT_BY_OPPORTUNITY_ID_FAILURE =
  "FETCHING_COSTING_PRODUCT_BY_OPPORTUNITY_ID_FAILURE";

export const ADD_COSTING_PRODUCT_REQUEST = "ADD_COSTING_PRODUCT_REQUEST";
export const ADD_COSTING_PRODUCT_SUCCESS = "ADD_COSTING_PRODUCT_SUCCESS";
export const ADD_COSTING_PRODUCT_FAILURE = "ADD_COSTING_PRODUCT_FAILURE";

export const ADD_COSTING_SERVICE_REQUEST = "ADD_COSTING_SERVICE_REQUEST";
export const ADD_COSTING_SERVICE_SUCCESS = "ADD_COSTING_SERVICE_SUCCESS";
export const ADD_COSTING_SERVICE_FAILURE = "ADD_COSTING_SERVICE_FAILURE";

export const FETCH_COSTING_PRODUCT_AND_SERVICE_REQUEST =
  "FETCH_COSTING_PRODUCT_AND_SERVICE_REQUEST";
export const FETCH_COSTING_PRODUCT_AND_SERVICE_SUCCESS =
  "FETCH_COSTING_PRODUCT_AND_SERVICE_SUCCESS";
export const FETCH_COSTING_PRODUCT_AND_SERVICE_FAILURE =
  "FETCH_COSTING_PRODUCT_AND_SERVICE_FAILURE";

export const GET_CURRENT_QUOTATION_FOR_COSTING_REQUEST =
  "GET_CURRENT_QUOTATION_FOR_COSTING_REQUEST";
export const GET_CURRENT_QUOTATION_FOR_COSTING_SUCCESS =
  "GET_CURRENT_QUOTATION_FOR_COSTING_SUCCESS";
export const GET_CURRENT_QUOTATION_FOR_COSTING_FAILURE =
  "GET_CURRENT_QUOTATION_FOR_COSTING_FAILURE";

export const FETCHING_PRODUCTS_BY_TASK_ID_REQUEST =
  "FETCHING_PRODUCTS_BY_TASK_ID_REQUEST";
export const FETCHING_PRODUCTS_BY_TASK_ID_SUCCESS =
  "FETCHING_PRODUCTS_BY_TASK_ID_SUCCESS";
export const FETCHING_PRODUCTS_BY_TASK_ID_FAILURE =
  "FETCHING_PRODUCTS_BY_TASK_ID_FAILURE";

export const FETCHING_SERVICE_BY_TASK_ID_REQUEST =
  "FETCHING_SERVICE_BY_TASK_ID_REQUEST";
export const FETCHING_SERVICE_BY_TASK_ID_SUCCESS =
  "FETCHING_SERVICE_BY_TASK_ID_SUCCESS";
export const FETCHING_SERVICE_BY_TASK_ID_FAILURE =
  "FETCHING_SERVICE_BY_TASK_ID_FAILURE";

export const GENERATE_COSTING_QUOTATION_REQUEST =
  "GENERATE_COSTING_QUOTATION_REQUEST";
export const GENERATE_COSTING_QUOTATION_SUCCESS =
  "GENERATE_COSTING_QUOTATION_SUCCESS";
export const GENERATE_COSTING_QUOTATION_FAILURE =
  "GENERATE_COSTING_QUOTATION_FAILURE";

export const FETCHING_COST_SHEET_BY_COST_ID_REQUEST =
  "FETCHING_COST_SHEET_BY_COST_ID_REQUEST";
export const FETCHING_COST_SHEET_BY_COST_ID_SUCCESS =
  "FETCHING_COST_SHEET_BY_COST_ID_SUCCESS";
export const FETCHING_COST_SHEET_BY_COST_ID_FAILURE =
  "FETCHING_COST_SHEET_BY_COST_ID_FAILURE";

export const FETCHING_COST_SHEET_BY_OPPORTUNITY_ID_REQUEST =
  "FETCHING_COST_SHEET_BY_OPPORTUNITY_ID_REQUEST";
export const FETCHING_COST_SHEET_BY_OPPORTUNITY_ID_SUCCESS =
  "FETCHING_COST_SHEET_BY_OPPORTUNITY_ID_SUCCESS";
export const FETCHING_COST_SHEET_BY_OPPORTUNITY_ID_FAILURE =
  "FETCHING_COST_SHEET_BY_OPPORTUNITY_ID_FAILURE";

export const GET_CUSTOMERS_REQUEST = "GET_CUSTOMERS_REQUEST";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAILURE = "GET_CUSTOMERS_FAILURE";

export const ADD_CUSTOMER_REQUEST = "ADD_CUSTOMER_REQUEST";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_FAILURE = "ADD_CUSTOMER_FAILURE";

export const REMOVE_CUSTOMER_REQUEST = "REMOVE_CUSTOMER_REQUEST";
export const REMOVE_CUSTOMER_SUCCESS = "REMOVE_CUSTOMER_SUCCESS";
export const REMOVE_CUSTOMER_FAILURE = "REMOVE_CUSTOMER_FAILURE";

export const UPDATE_CUSTOMER_REQUEST = "UPDATE_CUSTOMER_REQUEST";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAILURE = "UPDATE_CUSTOMER_FAILURE";

export const DROP_OPPORTUNITY_BY_OPPORTUNITY_ID_REQUEST =
  "DROP_OPPORTUNITY_BY_OPPORTUNITY_ID_REQUEST";
export const DROP_OPPORTUNITY_BY_OPPORTUNITY_ID_SUCCESS =
  "DROP_OPPORTUNITY_BY_OPPORTUNITY_ID_SUCCESS";
export const DROP_OPPORTUNITY_BY_OPPORTUNITY_ID_FAILURE =
  "DROP_OPPORTUNITY_BY_OPPORTUNITY_ID_FAILURE";

export const GET_CONTACTS_REQUEST = "GET_CONTACTS_REQUEST";
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS";
export const GET_CONTACTS_FAILURE = "GET_CONTACTS_FAILURE";

export const ADD_CONTACT_REQUEST = "ADD_CONTACT_REQUEST";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const ADD_CONTACT_FAILURE = "ADD_CONTACT_FAILURE";

export const REMOVE_CONTACT_REQUEST = "REMOVE_CONTACT_REQUEST";
export const REMOVE_CONTACT_SUCCESS = "REMOVE_CONTACT_SUCCESS";
export const REMOVE_CONTACT_FAILURE = "REMOVE_CONTACT_FAILURE";

export const UPDATE_CONTACT_REQUEST = "UPDATE_CONTACT_REQUEST";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_FAILURE = "UPDATE_CONTACT_FAILURE";

export const GET_DOCUMENT_REQUEST = "GET_DOCUMENT_REQUEST";
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
export const GET_DOCUMENT_FAILURE = "GET_DOCUMENT_FAILURE";

export const ADD_DOCUMENT_REQUEST = "ADD_DOCUMENT_REQUEST";
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS";
export const ADD_DOCUMENT_FAILURE = "ADD_DOCUMENT_FAILURE";

export const HANDLE_UPDATE_EVENT_MODAL = "HANDLE_UPDATE_EVENT_MODAL";
export const HANDLE_UPDATE_CALL_MODAL = "HANDLE_UPDATE_CALL_MODAL";
export const HANDLE_UPDATE_TASK_MODAL = "HANDLE_UPDATE_TASK_MODAL";

export const INPUT_SEARCH_OPPORTUNITY_DATA_REQUEST = "INPUT_SEARCH_OPPORTUNITY_DATA_REQUEST";
export const INPUT_SEARCH_OPPORTUNITY_DATA_SUCCESS = "INPUT_SEARCH_OPPORTUNITY_DATA_SUCCESS";
export const INPUT_SEARCH_OPPORTUNITY_DATA_FAILURE = "INPUT_SEARCH_OPPORTUNITY_DATA_FAILURE";

export const REMOVE_DOCUMENT_REQUEST = "REMOVE_DOCUMENT_REQUEST";
export const REMOVE_DOCUMENT_SUCCESS = "REMOVE_DOCUMENT_SUCCESS";
export const REMOVE_DOCUMENT_FAILURE = "REMOVE_DOCUMENT_FAILURE";

export const UPDATE_DOCUMENT_REQUEST = "UPDATE_DOCUMENT_REQUEST";
export const UPDATE_DOCUMENT_SUCCESS = "UPDATE_DOCUMENT_SUCCESS";
export const UPDATE_DOCUMENT_FAILURE = "UPDATE_DOCUMENT_FAILURE";

export const ADD_SHARE_OPPORTUNITY_PERMISSION_REQUEST = "ADD_SHARE_OPPORTUNITY_PERMISSION_REQUEST";
export const ADD_SHARE_OPPORTUNITY_PERMISSION_SUCCESS = "ADD_SHARE_OPPORTUNITY_PERMISSION_SUCCESS";
export const ADD_SHARE_OPPORTUNITY_PERMISSION_FAILURE = "ADD_SHARE_OPPORTUNITY_PERMISSION_FAILURE";

export const GET_PERMISSIONS_LIST_OPPORTUNITY_REQUEST = "GET_PERMISSIONS_LIST_OPPORTUNITY_REQUEST";
export const GET_PERMISSIONS_LIST_OPPORTUNITY_SUCCESS = "GET_PERMISSIONS_LIST_OPPORTUNITY_SUCCESS";
export const GET_PERMISSIONS_LIST_OPPORTUNITY_FAILURE = "GET_PERMISSIONS_LIST_OPPORTUNITY_FAILURE";