export const GET_CATAGORY_REQUEST = "GET_CATAGORY_REQUEST";
export const GET_CATAGORY_SUCCESS = "GET_CATAGORY_SUCCESS";
export const GET_CATAGORY_FAILURE = "GET_CATAGORY_FAILURE";

export const GET_CATAGORY_WISE_CONTACT_REQUEST =
  "GET_CATAGORY_WISE_CONTACT_REQUEST";
export const GET_CATAGORY_WISE_CONTACT_SUCCESS =
  "GET_CATAGORY_WISE_CONTACT_SUCCESS";
export const GET_CATAGORY_WISE_CONTACT_FAILURE =
  "GET_CATAGORY_WISE_CONTACT_FAILURE";

export const GET_CATAGORY_WISE_CONTACT_FOR_AND_OPERATION_SUCCESS =
  "GET_CATAGORY_WISE_CONTACT_FOR_AND_OPERATION_SUCCESS";

export const GET_MAP_CONTACTS_REQUEST = "GET_MAP_CONTACTS_REQUEST";
export const GET_MAP_CONTACTS_SUCCESS = "GET_MAP_CONTACTS_SUCCESS";
export const GET_MAP_CONTACTS_FAILURE = "GET_MAP_CONTACTS_FAILURE";

export const SET_NOTE_EDIT_BY_CONTACT_ID = "SET_NOTE_EDIT_BY_CONTACT_ID";

export const GET_CONTACTS_REQUEST = "GET_CONTACTS_REQUEST";
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS";
export const GET_CONTACTS_FAILURE = "GET_CONTACTS_FAILURE";

export const GET_CONTACTS_LAZY_LOADING_REQUEST =
  "GET_CONTACTS_LAZY_LOADING_REQUEST";
export const GET_CONTACTS_LAZY_LOADING_SUCCESS =
  "GET_CONTACTS_LAZY_LOADING_SUCCESS";
export const GET_CONTACTS_LAZY_LOADING_FAILURE =
  "GET_CONTACTS_LAZY_LOADING_FAILURE";

export const GET_LATEST_CONTACTS_REQUEST = "GET_LATEST_CONTACTS_REQUEST";
export const GET_LATEST_CONTACTS_SUCCESS = "GET_LATEST_CONTACTS_SUCCESS";
export const GET_LATEST_CONTACTS_FAILURE = "GET_LATEST_CONTACTS_FAILURE";

export const GET_ALL_LATEST_CONTACTS_REQUEST =
  "GET_ALL_LATEST_CONTACTS_REQUEST";
export const GET_ALL_LATEST_CONTACTS_SUCCESS =
  "GET_ALL_LATEST_CONTACTS_SUCCESS";
export const GET_ALL_LATEST_CONTACTS_FAILURE =
  "GET_ALL_LATEST_CONTACTS_FAILURE";

export const GET_LATEST_CONTACTS_BY_ORGANIZATION_ID_REQUEST =
  "GET_LATEST_CONTACTS_BY_ORGANIZATION_ID_REQUEST";
export const GET_LATEST_CONTACTS_BY_ORGANIZATION_ID_SUCCESS =
  "GET_LATEST_CONTACTS_BY_ORGANIZATION_ID_SUCCESS";
export const GET_LATEST_CONTACTS_BY_ORGANIZATION_ID_FAILURE =
  "GET_LATEST_CONTACTS_FAILURE";

export const GET_CONTACTS_BY_FUNNEL_VALUE_REQUEST =
  "GET_CONTACTS_BY_FUNNEL_VALUE_REQUEST";
export const GET_CONTACTS_BY_FUNNEL_VALUE_SUCCESS =
  "GET_CONTACTS_BY_FUNNEL_VALUE_SUCCESS";
export const GET_CONTACTS_BY_FUNNEL_VALUE_FAILURE =
  "GET_CONTACTS_BY_FUNNEL_VALUE_FAILURE";

export const GET_ALL_CONTACTS_BY_FUNNEL_VALUE_REQUEST =
  "GET_ALL_CONTACTS_BY_FUNNEL_VALUE_REQUEST";
export const GET_ALL_CONTACTS_BY_FUNNEL_VALUE_SUCCESS =
  "GET_ALL_CONTACTS_BY_FUNNEL_VALUE_SUCCESS";
export const GET_ALL_CONTACTS_BY_FUNNEL_VALUE_FAILURE =
  "GET_ALL_CONTACTS_BY_FUNNEL_VALUE_FAILURE";

export const GET_CONTACTS_BY_TIME_TO_CONNECT_REQUEST =
  "GET_CONTACTS_BY_TIME_TO_CONNECT_REQUEST";
export const GET_CONTACTS_BY_TIME_TO_CONNECT_SUCCESS =
  "GET_CONTACTS_BY_TIME_TO_CONNECT_SUCCESS";
export const GET_CONTACTS_BY_TIME_TO_CONNECT_FAILURE =
  "GET_CONTACTS_BY_TIME_TO_CONNECT_FAILURE";

export const GET_ALL_CONTACTS_BY_TIME_TO_CONNECT_REQUEST =
  "GET_ALL_CONTACTS_BY_TIME_TO_CONNECT_REQUEST";
export const GET_ALL_CONTACTS_BY_TIME_TO_CONNECT_SUCCESS =
  "GET_ALL_CONTACTS_BY_TIME_TO_CONNECT_SUCCESS";
export const GET_ALL_CONTACTS_BY_TIME_TO_CONNECT_FAILURE =
  "GET_ALL_CONTACTS_BY_TIME_TO_CONNECT_FAILURE";

export const GET_CONTACT_BY_ID_REQUEST = "GET_CONTACT_BY_ID_REQUEST";
export const GET_CONTACT_BY_ID_SUCCESS = "GET_CONTACT_BY_ID_SUCCESS";
export const GET_CONTACT_BY_ID_FAILURE = "GET_CONTACT_BY_ID_FAILURE";

export const UPDATE_CONTACT_ADDRESS = "UPDATE_CONTACT_ADDRESS";

export const UPDATE_CONTACT_BY_ID_REQUEST = "UPDATE_CONTACT_BY_ID_REQUEST";
export const UPDATE_CONTACT_BY_ID_SUCCESS = "UPDATE_CONTACT_BY_ID_SUCCESS";
export const UPDATE_CONTACT_BY_ID_FAILURE = "UPDATE_CONTACT_BY_ID_FAILURE";

export const DELETE_CONTACT_BY_ID_REQUEST = "DELETE_CONTACT_BY_ID_REQUEST";
export const DELETE_CONTACT_BY_ID_SUCCESS = "DELETE_CONTACT_BY_ID_SUCCESS";
export const DELETE_CONTACT_BY_ID_FAILURE = "DELETE_CONTACT_BY_ID_FAILURE";

export const LINK_ACCOUNT_TO_CONTACT_REQUEST =
  "LINK_ACCOUNT_TO_CONTACT_REQUEST";
export const LINK_ACCOUNT_TO_CONTACT_SUCCESS =
  "LINK_ACCOUNT_TO_CONTACT_SUCCESS";
export const LINK_ACCOUNT_TO_CONTACT_FAILURE =
  "LINK_ACCOUNT_TO_CONTACT_FAILURE";

export const UNLINK_ACCOUNT_FROM_CONTACT_REQUEST =
  "UNLINK_ACCOUNT_FROM_CONTACT_REQUEST";
export const UNLINK_ACCOUNT_FROM_CONTACT_SUCCESS =
  "UNLINK_ACCOUNT_FROM_CONTACT_SUCCESS";
export const UNLINK_ACCOUNT_FROM_CONTACT_FAILURE =
  "UNLINK_ACCOUNT_FROM_CONTACT_FAILURE";

export const GET_CONTACT_LIST_RANGE_BY_USER_ID_REQUEST =
  "GET_CONTACT_LIST_RANGE_BY_USER_ID_REQUEST";
export const GET_CONTACT_LIST_RANGE_BY_USER_ID_SUCCESS =
  "GET_CONTACT_LIST_RANGE_BY_USER_ID_SUCCESS";
export const GET_CONTACT_LIST_RANGE_BY_USER_ID_FAILURE =
  "GET_CONTACT_LIST_RANGE_BY_USER_ID_FAILURE";

export const GET_CONTACT_LIST_RANGE_OF_ALL_USERS_REQUEST =
  "GET_CONTACT_LIST_RANGE_OF_ALL_USERS_REQUEST";
export const GET_CONTACT_LIST_RANGE_OF_ALL_USERS_SUCCESS =
  "GET_CONTACT_LIST_RANGE_OF_ALL_USERS_SUCCESS";
export const GET_CONTACT_LIST_RANGE_OF_ALL_USERS_FAILURE =
  "GET_CONTACT_LIST_RANGE_OF_ALL_USERS_FAILURE";

export const GET_ACCOUNT_LIST_BY_USER_ID_REQUEST =
  "GET_ACCOUNT_LIST_BY_USER_ID_REQUEST";
export const GET_ACCOUNT_LIST_BY_USER_ID_SUCCESS =
  "GET_ACCOUNT_LIST_BY_USER_ID_SUCCESS";
export const GET_ACCOUNT_LIST_BY_USER_ID_FAILURE =
  "GET_ACCOUNT_LIST_BY_USER_ID_FAILURE";

export const GET_ACCOUNT_LIST_BY_CONTACT_ID_REQUEST =
  "GET_ACCOUNT_LIST_BY_CONTACT_ID_REQUEST";
export const GET_ACCOUNT_LIST_BY_CONTACT_ID_SUCCESS =
  "GET_ACCOUNT_LIST_BY_CONTACT_ID_SUCCESS";
export const GET_ACCOUNT_LIST_BY_CONTACT_ID_FAILURE =
  "GET_ACCOUNT_LIST_BY_CONTACT_ID_FAILURE";

export const UPDATE_ACCOUNT_LIST_BY_CONTACT_ID_REQUEST =
  "UPDATE_ACCOUNT_LIST_BY_CONTACT_ID_REQUEST";
export const UPDATE_ACCOUNT_LIST_BY_CONTACT_ID_SUCCESS =
  "UPDATE_ACCOUNT_LIST_BY_CONTACT_ID_SUCCESS";
export const UPDATE_ACCOUNT_LIST_BY_CONTACT_ID_FAILURE =
  "UPDATE_ACCOUNT_LIST_BY_CONTACT_ID_FAILURE";

export const GET_OPPORTUNITY_LIST_BY_CONTACT_ID_REQUEST =
  "GET_OPPORTUNITY_LIST_BY_CONTACT_ID_REQUEST";
export const GET_OPPORTUNITY_LIST_BY_CONTACT_ID_SUCCESS =
  "GET_OPPORTUNITY_LIST_BY_CONTACT_ID_SUCCESS";
export const GET_OPPORTUNITY_LIST_BY_CONTACT_ID_FAILURE =
  "GET_OPPORTUNITY_LIST_BY_CONTACT_ID_FAILURE";

export const UPDATE_OPPORTUNITY_LIST_BY_CONTACT_ID_REQUEST =
  "UPDATE_OPPORTUNITY_LIST_BY_CONTACT_ID_REQUEST";
export const UPDATE_OPPORTUNITY_LIST_BY_CONTACT_ID_SUCCESS =
  "UPDATE_OPPORTUNITY_LIST_BY_CONTACT_ID_SUCCESS";
export const UPDATE_OPPORTUNITY_LIST_BY_CONTACT_ID_FAILURE =
  "UPDATE_OPPORTUNITY_LIST_BY_CONTACT_ID_FAILURE";

export const GET_NOTES_LIST_BY_CONTACT_ID_REQUEST =
  "GET_NOTES_LIST_BY_CONTACT_ID_REQUEST";
export const GET_NOTES_LIST_BY_CONTACT_ID_SUCCESS =
  "GET_NOTES_LIST_BY_CONTACT_ID_SUCCESS";
export const GET_NOTES_LIST_BY_CONTACT_ID_FAILURE =
  "GET_NOTES_LIST_BY_CONTACT_ID_FAILURE";

export const UPDATE_NOTES_LIST_BY_CONTACT_ID_REQUEST =
  "UPDATE_NOTES_LIST_BY_CONTACT_ID_REQUEST";
export const UPDATE_NOTES_LIST_BY_CONTACT_ID_SUCCESS =
  "UPDATE_NOTES_LIST_BY_CONTACT_ID_SUCCESS";
export const UPDATE_NOTES_LIST_BY_CONTACT_ID_FAILURE =
  "UPDATE_NOTES_LIST_BY_CONTACT_ID_FAILURE";

export const GET_CALLS_LIST_BY_CONTACT_ID_REQUEST =
  "GET_CALLS_LIST_BY_CONTACT_ID_REQUEST";
export const GET_CALLS_LIST_BY_CONTACT_ID_SUCCESS =
  "GET_CALLS_LIST_BY_CONTACT_ID_SUCCESS";
export const GET_CALLS_LIST_BY_CONTACT_ID_FAILURE =
  "GET_CALLS_LIST_BY_CONTACT_ID_FAILURE";

export const UPDATE_CALLS_LIST_BY_CONTACT_ID_REQUEST =
  "UPDATE_CALLS_LIST_BY_CONTACT_ID_REQUEST";
export const UPDATE_CALLS_LIST_BY_CONTACT_ID_SUCCESS =
  "UPDATE_CALLS_LIST_BY_CONTACT_ID_SUCCESS";
export const UPDATE_CALLS_LIST_BY_CONTACT_ID_FAILURE =
  "UPDATE_CALLS_LIST_BY_CONTACT_ID_FAILURE";

export const GET_EVENTS_LIST_BY_CONTACT_ID_REQUEST =
  "GET_EVENTS_LIST_BY_CONTACT_ID_REQUEST";
export const GET_EVENTS_LIST_BY_CONTACT_ID_SUCCESS =
  "GET_EVENTS_LIST_BY_CONTACT_ID_SUCCESS";
export const GET_EVENTS_LIST_BY_CONTACT_ID_FAILURE =
  "GET_EVENTS_LIST_BY_CONTACT_ID_FAILURE";

export const UPDATE_EVENTS_LIST_BY_CONTACT_ID_REQUEST =
  "UPDATE_EVENTS_LIST_BY_CONTACT_ID_REQUEST";
export const UPDATE_EVENTS_LIST_BY_CONTACT_ID_SUCCESS =
  "UPDATE_EVENTS_LIST_BY_CONTACT_ID_SUCCESS";
export const UPDATE_EVENTS_LIST_BY_CONTACT_ID_FAILURE =
  "UPDATE_EVENTS_LIST_BY_CONTACT_ID_FAILURE";

export const GET_TASKS_LIST_BY_CONTACT_ID_REQUEST =
  "GET_TASKS_LIST_BY_CONTACT_ID_REQUEST";
export const GET_TASKS_LIST_BY_CONTACT_ID_SUCCESS =
  "GET_TASKS_LIST_BY_CONTACT_ID_SUCCESS";
export const GET_TASKS_LIST_BY_CONTACT_ID_FAILURE =
  "GET_TASKS_LIST_BY_CONTACT_ID_FAILURE";

export const UPDATE_TASKS_LIST_BY_CONTACT_ID_REQUEST =
  "UPDATE_TASKS_LIST_BY_CONTACT_ID_REQUEST";
export const UPDATE_TASKS_LIST_BY_CONTACT_ID_SUCCESS =
  "UPDATE_TASKS_LIST_BY_CONTACT_ID_SUCCESS";
export const UPDATE_TASKS_LIST_BY_CONTACT_ID_FAILURE =
  "UPDATE_TASKS_LIST_BY_CONTACT_ID_FAILURE";

export const ADD_CONTACT_REQUEST = "ADD_CONTACT_REQUEST";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const ADD_CONTACT_FAILURE = "ADD_CONTACT_FAILURE";

export const ADD_LINK_CONTACT_BY_OPPORTUNITY_ID_REQUEST =
  "ADD_LINK_CONTACT_BY_OPPORTUNITY_ID_REQUEST";
export const ADD_LINK_CONTACT_BY_OPPORTUNITY_ID_SUCCESS =
  "ADD_LINK_CONTACT_BY_OPPORTUNITY_ID_SUCCESS";
export const ADD_LINK_CONTACT_BY_OPPORTUNITY_ID_FAILURE =
  "ADD_LINK_CONTACT_BY_OPPORTUNITY_ID_FAILURE";

export const GET_TOPICS_BY_CONTACT_ID_REQUEST =
  "GET_TOPICS_BY_CONTACT_ID_REQUEST";
export const GET_TOPICS_BY_CONTACT_ID_SUCCESS =
  "GET_TOPICS_BY_CONTACT_ID_SUCCESS";
export const GET_TOPICS_BY_CONTACT_ID_FAILURE =
  "GET_TOPICS_BY_CONTACT_ID_FAILURE";

export const ADD_TOPIC_BY_CONTACT_ID_REQUEST =
  "ADD_TOPIC_BY_CONTACT_ID_REQUEST";
export const ADD_TOPIC_BY_CONTACT_ID_SUCCESS =
  "ADD_TOPIC_BY_CONTACT_ID_SUCCESS";
export const ADD_TOPIC_BY_CONTACT_ID_FAILURE =
  "ADD_TOPIC_BY_CONTACT_ID_FAILURE";

export const DELETE_TOPIC_BY_CONTACT_ID_REQUEST =
  "DELETE_TOPIC_BY_CONTACT_ID_REQUEST";
export const DELETE_TOPIC_BY_CONTACT_ID_SUCCESS =
  "DELETE_TOPIC_BY_CONTACT_ID_SUCCESS";
export const DELETE_TOPIC_BY_CONTACT_ID_FAILURE =
  "DELETE_TOPIC_BY_CONTACT_ID_FAILURE";

export const FUNNEL_VALUE_BY_CONTACT_ID_REQUEST =
  "FUNNEL_VALUE_BY_CONTACT_ID_REQUEST";
export const FUNNEL_VALUE_BY_CONTACT_ID_SUCCESS =
  "FUNNEL_VALUE_BY_CONTACT_ID_SUCCESS";
export const FUNNEL_VALUE_BY_CONTACT_ID_FAILURE =
  "FUNNEL_VALUE_BY_CONTACT_ID_FAILURE";

export const HANDLE_CONTACT_MODAL = "HANDLE_CONTACT_MODAL";
export const HANDLE_LINK_CONTACT_MODAL = "HANDLE_LINK_CONTACT_MODAL";
export const HANDLE_CONTACT_DRAWER = "HANDLE_CONTACT_DRAWER";
export const SET_CONTACT_VIEW_TYPE = "SET_CONTACT_VIEW_TYPE";
export const SET_CONTACT_FILTER_TEXT = "SET_CONTACT_FILTER_TEXT";
export const SET_CONTACT_FILTER_USER = "SET_CONTACT_FILTER_USER";

export const CHANGE_SELECTED_TIME_INTERVAL_CONTACT =
  "CHANGE_SELECTED_TIME_INTERVAL_CONTACT";
export const SET_TIME_INTERVAL_CONTACT = "SET_TIME_INTERVAL_CONTACT";

export const GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_DASHBOARD_REQUEST =
  "GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_DASHBOARD_REQUEST";
export const GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_DASHBOARD_SUCCESS =
  "GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_DASHBOARD_SUCCESS";
export const GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_DASHBOARD_FAILURE =
  "GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_DASHBOARD_FAILURE";

export const ADD_CATAGORY_BY_CONTACT_ID_REQUEST =
  "ADD_CATAGORY_BY_CONTACT_ID_REQUEST";
export const ADD_CATAGORY_BY_CONTACT_ID_SUCCESS =
  "ADD_CATAGORY_BY_CONTACT_ID_SUCCESS";
export const ADD_CATAGORY_BY_CONTACT_ID_FAILURE =
  "ADD_CATAGORY_BY_CONTACT_ID_FAILURE";

export const GET_CATAGORY_BY_CONTACT_ID_REQUEST =
  "GET_CATAGORY_BY_CONTACT_ID_REQUEST";
export const GET_CATAGORY_BY_CONTACT_ID_SUCCESS =
  "GET_CATAGORY_BY_CONTACT_ID_SUCCESS";
export const GET_CATAGORY_BY_CONTACT_ID_FAILURE =
  "GET_CATAGORY_BY_CONTACT_ID_FAILURE";

export const DELETE_CATAGORY_BY_CONTACT_ID_REQUEST =
  "DELETE_CATAGORY_BY_CONTACT_ID_REQUEST";
export const DELETE_CATAGORY_BY_CONTACT_ID_SUCCESS =
  "DELETE_CATAGORY_BY_CONTACT_ID_SUCCESS";
export const DELETE_CATAGORY_BY_CONTACT_ID_FAILURE =
  "DELETE_CATAGORY_BY_CONTACT_ID_FAILURE";

export const GET_ROLE_CHART_DATA_BY_CONTACT_ID_REQUEST =
  "GET_ROLE_CHART_DATA_BY_CONTACT_ID_REQUEST";
export const GET_ROLE_CHART_DATA_BY_CONTACT_ID_SUCCESS =
  "GET_ROLE_CHART_DATA_BY_CONTACT_ID_SUCCESS";
export const GET_ROLE_CHART_DATA_BY_CONTACT_ID_FAILURE =
  "GET_ROLE_CHART_DATA_BY_CONTACT_ID_FAILURE";

export const ADD_CONTACT_DOCUMENT_REQUEST = "ADD_CONTACT_DOCUMENT_REQUEST";
export const ADD_CONTACT_DOCUMENT_SUCCESS = "ADD_CONTACT_DOCUMENT_SUCCESS";
export const ADD_CONTACT_DOCUMENT_FAILURE = "ADD_CONTACT_DOCUMENT_FAILURE";

export const GET_CONTACT_DOCUMENTS_REQUEST = "GET_CONTACT_DOCUMENTS_REQUEST";
export const GET_CONTACT_DOCUMENTS_SUCCESS = "GET_CONTACT_DOCUMENTS_SUCCESS";
export const GET_CONTACT_DOCUMENTS_FAILURE = "GET_CONTACT_DOCUMENTS_FAILURE";

export const LINK_STAGE_TO_OPPORTUNITY_BY_CONTACT_ID_REQUEST =
  "LINK_STAGE_TO_OPPORTUNITY_BY_CONTACT_ID_REQUEST";
export const LINK_STAGE_TO_OPPORTUNITY_BY_CONTACT_ID_SUCCESS =
  "LINK_STAGE_TO_OPPORTUNITY_BY_CONTACT_ID_SUCCESS";
export const LINK_STAGE_TO_OPPORTUNITY_BY_CONTACT_ID_FAILURE =
  "LINK_STAGE_TO_OPPORTUNITY_BY_CONTACT_ID_FAILURE";

export const GET_DESIGNATION_REQUEST = "GET_DESIGNATION_REQUEST";
export const GET_DESIGNATION_SUCCESS = "GET_DESIGNATION_SUCCESS";
export const GET_DESIGNATION_FAILURE = "GET_DESIGNATION_FAILURE";

export const GET_CONTACT_QUOTE_REQUEST = "GET_CONTACT_QUOTE_REQUEST";
export const GET_CONTACT_QUOTE_SUCCESS = "GET_CONTACT_QUOTE_SUCCESS";
export const GET_CONTACT_QUOTE_FAILURE = "GET_CONTACT_QUOTE_FAILURE";

export const HANDLE_ABSOLUTE_MODAL = "HANDLE_ABSOLUTE_MODAL";
export const HANDLE_WIN_MODAL = "HANDLE_WIN_MODAL";
export const HANDLE_MEETINGS_MODAL = "HANDLE_MEETINGS_MODAL";
export const HANDLE_CALLS_MODAL = "HANDLE_CALLS_MODAL";
export const HANDLE_PIE_MODAL = "HANDLE_PIE_MODAL";

export const EMPTY_CONTACT_DATA = "EMPTY_CONTACT_DATA";

export const HANDLE_CONTACT_CUSTOME_FIELD_MODAL =
  "HANDLE_CONTACT_CUSTOME_FIELD_MODAL";

export const GET_NO_OF_EMAILS_SENT_REQUEST = "GET_NO_OF_EMAILS_SENT_REQUEST";
export const GET_NO_OF_EMAILS_SENT_SUCCESS = "GET_NO_OF_EMAILS_SENT_SUCCESS";
export const GET_NO_OF_EMAILS_SENT_FAILURE = "GET_NO_OF_EMAILS_SENT_FAILURE";

export const CLEANUP_CONTACT_REQUEST = "CLEANUP_CONTACT_REQUEST";
export const CLEANUP_CONTACT_SUCCESS = "CLEANUP_CONTACT_SUCCESS";
export const CLEANUP_CONTACT_FAILURE = "CLEANUP_CONTACT_FAILURE";

export const GET_RECORDS_REQUEST = "GET_RECORDS_REQUEST";
export const GET_RECORDS_SUCCESS = "GET_RECORDS_SUCCESS";
export const GET_RECORDS_FAILURE = "GET_RECORDS_FAILURE";

export const GET_ALL_LATEST_CONTACTS_FOR_LAZY_LOADING_REQUEST =
  "GET_ALL_LATEST_CONTACTS_FOR_LAZY_LOADING_REQUEST";
export const GET_ALL_LATEST_CONTACTS_FOR_LAZY_LOADING_SUCCESS =
  "GET_ALL_LATEST_CONTACTS_FOR_LAZY_LOADING_SUCCESS";
export const GET_ALL_LATEST_CONTACTS_FOR_LAZY_LOADING_FAILURE =
  "GET_ALL_LATEST_CONTACTS_FOR_LAZY_LOADING_FAILURE";

//add customeField form
export const ADD_CUSTOME_FIELD_FORM_REQUEST = "ADD_CUSTOME_FIELD_FORM_REQUEST";
export const ADD_CUSTOME_FIELD_FORM_SUCCESS = "ADD_CUSTOME_FIELD_FORM_SUCCESS";
export const ADD_CUSTOME_FIELD_FORM_FAILURE = "ADD_CUSTOME_FIELD_FORM_FAILURE";

//GET CUSTOME FIELD DATA
export const GET_CUSTOME_FIELD_REQUEST = "GET_CUSTOME_FIELD_REQUEST";
export const GET_CUSTOME_FIELD_SUCCESS = "GET_CUSTOME_FIELD_SUCCESS";
export const GET_CUSTOME_FIELD_FAILURE = "GET_CUSTOME_FIELD_FAILURE";

//get timeline data
export const GET_TIMELINE_DATA_BY_CONTACT_ID_REQUEST =
  "GET_TIMELINE_DATA_BY_CONTACT_ID_REQUEST";
export const GET_TIMELINE_DATA_BY_CONTACT_ID_SUCCESS =
  "GET_TIMELINE_DATA_BY_CONTACT_ID_SUCCESS";
export const GET_TIMELINE_DATA_BY_CONTACT_ID_FAILURE =
  "GET_TIMELINE_DATA_BY_CONTACT_ID_FAILURE";

export const HANDLE_CONTACT_ACTIVITY_MODAL = "HANDLE_CONTACT_ACTIVITY_MODAL";
export const HANDLE_CONTACT_ORDER_MODAL = "HANDLE_CONTACT_ORDER_MODAL";

export const GET_CONTACT_ACTIVITY_DATA_REQUEST =
  "GET_CONTACT_ACTIVITY_DATA_REQUEST";
export const GET_CONTACT_ACTIVITY_DATA_SUCCESS =
  "GET_CONTACT_ACTIVITY_DATA_SUCCESS";
export const GET_CONTACT_ACTIVITY_DATA_FAILURE =
  "GET_CONTACT_ACTIVITY_DATA_SUCCESS";

export const GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST =
  "GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST";
export const GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS =
  "GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS";
export const GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE =
  "GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE";

export const GET_CONTACT_LIST_BY__RANGE_BY_ORGANISATION_ID_FOR_REPORT_REQUEST =
  "GET_CONTACT_LIST_BY__RANGE_BY_ORGANISATION_ID_FOR_REPORT_REQUEST";
export const GET_CONTACT_LIST_BY__RANGE_BY_ORGANISATION_ID_FOR_REPORT_SUCCESS =
  "GET_CONTACT_LIST_BY__RANGE_BY_ORGANISATION_ID_FOR_REPORT_SUCCESS";
export const GET_CONTACT_LIST_BY__RANGE_BY_ORGANISATION_ID_FOR_REPORT_FAILURE =
  "GET_CONTACT_LIST_BY__RANGE_BY_ORGANISATION_ID_FOR_REPORT_FAILURE";

export const INPUT_SEARCH_DATA_REQUSET = "INPUT_SEARCH_DATA_REQUSET";
export const INPUT_SEARCH_DATA_SUCCESS = "INPUT_SEARCH_DATA_SUCCESS";
export const INPUT_SEARCH_DATA_FAILURE = "INPUT_SEARCH_DATA_FAILURE";

export const SUSPEND_CONTACT_REQUSET = "SUSPEND_CONTACT_REQUSET";
export const SUSPEND_CONTACT_SUCCESS = "SUSPEND_CONTACT_SUCCESS";
export const SUSPEND_CONTACT_FAILURE = "SUSPEND_CONTACT_FAILURE";

export const HANDLE_LINK_CONTACT_ORDER_CONFIGURE_MODAL = "HANDLE_LINK_CONTACT_ORDER_CONFIGURE_MODAL";

// order product

export const LINK_PRODUCT_TO_CONTACT_REQUEST =
  "LINK_PRODUCT_TO_CONTACT_REQUEST";
export const LINK_PRODUCT_TO_CONTACT_SUCCESS =
  "LINK_PRODUCT_TO_CONTACT_SUCCESS";
export const LINK_PRODUCT_TO_CONTACT_FAILURE =
  "LINK_PRODUCT_TO_CONTACT_FAILURE";

export const FETCHING_PROFESSIONALDUCTS_BY_CONTACT_ID_REQUEST =
  "FETCHING_PROFESSIONALDUCTS_BY_CONTACT_ID_REQUEST";
export const FETCHING_PROFESSIONALDUCTS_BY_CONTACT_ID_SUCCESS =
  "FETCHING_PROFESSIONALDUCTS_BY_CONTACT_ID_SUCCESS";
export const FETCHING_PROFESSIONALDUCTS_BY_CONTACT_ID_FAILURE =
  "FETCHING_PROFESSIONALDUCTS_BY_CONTACT_ID_FAILURE";

// get order 

export const GET_CONTACT_ORDER_REQUEST =
  "GET_CONTACT_ORDER_REQUEST";
export const GET_CONTACT_ORDER_SUCCESS =
  "GET_CONTACT_ORDER_SUCCESS";
export const GET_CONTACT_ORDER_FAILURE =
  "GET_CONTACT_ORDER_FAILURE";


export const FETCHING_PROFESSIONAL_SERVICE_BY_CONTACT_ID_REQUEST =
  "FETCHING_PROFESSIONAL_SERVICE_BY_CONTACT_ID_REQUEST";
export const FETCHING_PROFESSIONAL_SERVICE_BY_CONTACT_ID_SUCCESS =
  "FETCHING_PROFESSIONAL_SERVICE_BY_CONTACT_ID_SUCCESS";
export const FETCHING_PROFESSIONAL_SERVICE_BY_CONTACT_ID_FAILURE =
  "FETCHING_PROFESSIONAL_SERVICE_BY_CONTACT_ID_FAILURE";


export const LINK_SERVICE_TO_CONTACT_REQUEST =
  "LINK_SERVICE_TO_CONTACT_REQUEST";
export const LINK_SERVICE_TO_CONTACT_SUCCESS =
  "LINK_SERVICE_TO_CONTACT_SUCCESS";
export const LINK_SERVICE_TO_CONTACT_FAILURE =
  "LINK_SERVICE_TO_CONTACT_FAILURE";

export const FETCHING_NEW_ORDER_LIST_REQUEST = "FETCHING_NEW_ORDER_LIST_REQUEST";
export const FETCHING_NEW_ORDER_LIST_SUCCESS = "FETCHING_NEW_ORDER_LIST_SUCCESS";
export const FETCHING_NEW_ORDER_LIST_FAILURE = "FETCHING_NEW_ORDER_LIST_FAILURE";

export const FETCHING_NEW_ORDER_SERVICE_LIST_REQUEST =
  "FETCHING_NEW_ORDER_SERVICE_LIST_REQUEST";
export const FETCHING_NEW_ORDER_SERVICE_LIST_SUCCESS =
  "FETCHING_NEW_ORDER_SERVICE_LIST_SUCCESS";
export const FETCHING_NEW_ORDER_SERVICE_LIST_FAILURE =
  "FETCHING_NEW_ORDER_SERVICE_LIST_FAILURE";

export const HANDLE_CONTACT_SUBSCRIPTION_CONFIGURE_MODAL = "HANDLE_CONTACT_SUBSCRIPTION_CONFIGURE_MODAL";

export const GENERATE_ORDER_BY_CONTACT_ID_REQUEST =
  "GENERATE_ORDER_BY_CONTACT_ID_REQUEST";
export const GENERATE_ORDER_BY_CONTACT_ID_SUCCESS =
  "GENERATE_ORDER_BY_CONTACT_ID_SUCCESS";
export const GENERATE_ORDER_BY_CONTACT_ID_FAILURE =
  "GENERATE_ORDER_BY_CONTACT_ID_FAILURE";

export const HANDLE_RENEWAL_BUTTON_MODAL = "HANDLE_RENEWAL_BUTTON_MODAL";
export const HANDLE_PAUSE_BUTTON_MODAL = "HANDLE_PAUSE_BUTTON_MODAL";

export const GET_PRODUCT_ORDER_DETAILS_BY_ID_REQUEST = "GET_PRODUCT_ORDER_DETAILS_BY_ID_REQUEST";
export const GET_PRODUCT_ORDER_DETAILS_BY_ID_SUCCESS = "GET_PRODUCT_ORDER_DETAILS_BY_ID_SUCCESS";
export const GET_PRODUCT_ORDER_DETAILS_BY_ID_FAILURE = "GET_PRODUCT_ORDER_DETAILS_BY_ID_FAILURE";

export const LINK_PAUSE_BY_CONTACT_ID_REQUEST =
  "LINK_PAUSE_BY_CONTACT_ID_REQUEST";
export const LINK_PAUSE_BY_CONTACT_ID_SUCCESS =
  "LINK_PAUSE_BY_CONTACT_ID_SUCCESS";
export const LINK_PAUSE_BY_CONTACT_ID_FAILURE =
  "LINK_PAUSE_BY_CONTACT_ID_FAILURE";


export const LINK_RENEWAL_BY_CONTACT_ID_REQUEST =
  "LINK_RENEWAL_BY_CONTACT_ID_REQUEST";
export const LINK_RENEWAL_BY_CONTACT_ID_SUCCESS =
  "LINK_RENEWAL_BY_CONTACT_ID_SUCCESS";
export const LINK_RENEWAL_BY_CONTACT_ID_FAILURE =
  "LINK_RENEWAL_BY_CONTACT_ID_FAILURE";

export const GET_FEEDBACK_BY_ORDER_ID_REQUEST =
  "GET_FEEDBACK_BY_ORDER_ID_REQUEST";
export const GET_FEEDBACK_BY_ORDER_ID_SUCCESS =
  "GET_FEEDBACK_BY_ORDER_ID_SUCCESS";
export const GET_FEEDBACK_BY_ORDER_ID_FAILURE =
  "GET_FEEDBACK_BY_ORDER_ID_FAILURE";


export const GET_FEEDBACK_BY_CONTACT_ID_REQUEST =
  "GET_FEEDBACK_BY_CONTACT_ID_REQUEST";
export const GET_FEEDBACK_BY_CONTACT_ID_SUCCESS =
  "GET_FEEDBACK_BY_CONTACT_ID_SUCCESS";
export const GET_FEEDBACK_BY_CONTACT_ID_FAILURE =
  "GET_FEEDBACK_BY_CONTACT_ID_FAILURE";


export const HANDLE_FEEDBACK_MODAL = "HANDLE_FEEDBACK_MODAL";

export const GET_CONTACT_HISTORY_REQUEST = "GET_CONTACT_HISTORY_REQUEST";
export const GET_CONTACT_HISTORY_SUCCESS = "GET_CONTACT_HISTORY_SUCCESS";
export const GET_CONTACT_HISTORY_FAILURE = "GET_CONTACT_HISTORY_FAILURE";


export const ADD_SHARE_CONTACT_PERMISSION_REQUEST = "ADD_SHARE_CONTACT_PERMISSION_REQUEST";
export const ADD_SHARE_CONTACT_PERMISSION_SUCCESS = "ADD_SHARE_CONTACT_PERMISSION_SUCCESS";
export const ADD_SHARE_CONTACT_PERMISSION_FAILURE = "ADD_SHARE_CONTACT_PERMISSION_FAILURE";

export const GET_PERMISSIONS_LIST_CONTACT_REQUEST = "GET_PERMISSIONS_LIST_CONTACT_REQUEST";
export const GET_PERMISSIONS_LIST_CONTACT_SUCCESS = "GET_PERMISSIONS_LIST_CONTACT_SUCCESS";
export const GET_PERMISSIONS_LIST_CONTACT_FAILURE = "GET_PERMISSIONS_LIST_CONTACT_FAILURE";