//call to local or server

export const TOGGLE_SERVER = "TOGGLE_SERVER";

// register action types
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

// login request types
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// set password action types
export const SET_PASSWORD_REQUEST = "SET_PASSWORD_REQUEST";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_FAILURE = "SET_PASSWORD_FAILURE";

// change password action types
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

// validate email action types
export const VALIDATE_EMAIL_REQUEST = "VALIDATE_EMAIL_REQUEST";
export const VALIDATE_EMAIL_SUCCESS = "VALIDATE_EMAIL_SUCCESS";
export const VALIDATE_EMAIL_FAILURE = "VALIDATE_EMAIL_FAILURE";

// validate email action types
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

// get user details action types
export const GET_USER_DETAILS_REQUEST = "GET_USER_DETAILS_REQUEST";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";

// update user details action types
export const UPDATE_USER_DETAILS_REQUEST = "UPDATE_USER_DETAILS_REQUEST";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

//update address of user.
export const UPDATE_USER_ADDRESS = "UPDATE_USER_ADDRESS";

// get organization details action types
export const GET_ORGANIZATION_DETAILS_REQUEST =
  "GET_ORGANIZATION_DETAILS_REQUEST";
export const GET_ORGANIZATION_DETAILS_SUCCESS =
  "GET_ORGANIZATION_DETAILS_SUCCESS";
export const GET_ORGANIZATION_DETAILS_FAILURE =
  "GET_ORGANIZATION_DETAILS_FAILURE";

// update organization details action types
export const UPDATE_ORGANIZATION_DETAILS_REQUEST =
  "UPDATE_ORGANIZATION_DETAILS_REQUEST";
export const UPDATE_ORGANIZATION_DETAILS_SUCCESS =
  "UPDATE_ORGANIZATION_DETAILS_SUCCESS";
export const UPDATE_ORGANIZATION_DETAILS_FAILURE =
  "UPDATE_ORGANIZATION_DETAILS_FAILURE";

// update subscription type on organization
export const UPDATE_SUBSCRIPTION_TYPE = "UPDATE_SUBSCRIPTION_TYPE";

// update smartBoost enable/disable
export const UPDATE_SMARTBOOST_SUCCESS = "UPDATE_SMARTBOOST_SUCCESS";

// update smartBoost enable/disable
export const UPDATE_PROFESSIONALDUCT_SUCCESS =
  "UPDATE_PROFESSIONALDUCT_SUCCESS";

// update viewport enable/disable
export const UPDATE_VIEWPORT_SUCCESS = "UPDATE_VIEWPORT_SUCCESS";

// update delivery enable/disable
export const UPDATE_DELIVERY_SUCCESS = "UPDATE_DELIVERY_SUCCESS";

// update finance enable/disable
export const UPDATE_FINANCE_SUCCESS = "UPDATE_FINANCE_SUCCESS";

// update legal enable/disable
export const UPDATE_LEGAL_SUCCESS = "UPDATE_LEGAL_SUCCESS";

// update riskManagement enable/disable
export const UPDATE_RISKMANAGEMENT_SUCCESS = "UPDATE_RISKMANAGEMENT_SUCCESS";

// update CUSTOMEmODULE enable/disable
export const UPDATE_CUSTOME_MODULE_SUCCESS = "UPDATE_CUSTOME_MODULE_SUCCESS";

// update Recruitment enable/disable
export const UPDATE_RECRUITMENT_SUCCESS = "UPDATE_RECRUITMENT_SUCCESS";

// update Marketing enable
export const UPDATE_MARKETING_SUCCESS = "UPDATE_MARKETING_SUCCESS";

// update recruitpro Advance enable
export const UPDATE_RECRUITMENT_ADVANCE_SUCCESS =
  "UPDATE_RECRUITMENT_ADVANCE_SUCCESS";
export const UPDATE_INVENTORY_SUCCESS = "UPDATE_INVENTORY_SUCCESS";
export const UPDATE_FASHION_SUCCESS = "UPDATE_FASHION_SUCCESS";
//logout and reset the redux store
// export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
// export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
// export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const LOGOUT = "LOGOUT";

//unauth user on timeout and token expiration
export const UNAUTH_USER = "UNAUTH_USER";
