import * as types from "./PartnerActionType";
import axios from "axios";
import { base_url } from "../../Config/Auth";
import { message } from "antd";
import moment from "moment";

export const updatePartnerAddress = (partnerId, address) => (dispatch) => {
  console.log(partnerId, address);
  dispatch({
    type: types.UPDATE_PARTNER_ADDRESS,
    payload: {
      partnerId,
      address,
    },
  });
};

export const addPartnerAddress = (address) => (dispatch) => {
  //////debugger;
  // console.log(accountId);
  dispatch({
    type: types.ADD_PARTNER_ADDRESS,
    payload: {
      address,
    },
  });
};

export const handleOpenModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_ALLOW_SWITCH_OPEN_PARTNER_MODAL,
    payload: modalProps,
  });
};
export const setAccountViewType = (viewType) => (dispatch) =>
  dispatch({ type: types.SET_ACCOUNT_VIEW_TYPE, payload: viewType });

export const setPartnerViewType = (viewType) => (dispatch) =>
  dispatch({ type: types.SET_PARTNER_VIEW_TYPE, payload: viewType });

export const handlePartnerModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_PARTNER_MODAL,
    payload: modalProps,
  });
};
export const setSelectedTimeIntervalCatalogue = (selectedTime) => (
  dispatch
) => {
  dispatch({
    type: types.CHANGE_SELECTED_TIME_INTERVAL_CATALOGUE,
    payload: selectedTime,
  });
};
export const setTimeRangeCatalogue = (startDate, endDate) => (dispatch) => {
  dispatch({
    type: types.SET_TIME_INTERVAL_CATALOGUE,
    payload: {
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
    },
  });
};
export const handleLinkPartnerModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_LINK_PARTNER_MODAL,
    payload: modalProps,
  });
};

export const handleLinkUpdatePartnerModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_LINK_UPDATE_PARTNER_MODAL,
    payload: modalProps,
  });
};

export const setItem = (props) => (dispatch) => {
  dispatch({
    type: types.SET_CURRENT_PARTNER,
    payload: props,
  });
};

export const addPartner = (partner, cb) => (dispatch, getState) => {
  const { userId } = getState("auth").auth.userDetails;
  dispatch({
    type: types.ADD_PARTNER_REQUEST,
  });

  axios
    .post(`${base_url}/partner`, partner, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(getPartners(userId));
      dispatch({
        type: types.ADD_PARTNER_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_PARTNER_FAILURE,
        payload: err,
      });
      cb && cb("failure");
    });
};

export const addLinkPartner = (partnerIds, cb) => (dispatch, getState) => {
  const { opportunityId } = getState("opportunity").opportunity.opportunity;
  dispatch({
    type: types.ADD_LINK_PARTNER_REQUEST,
  });

  axios
    .post(`${base_url}/link/partner/opportunity`, partnerIds, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(getLinkOpportunityPartners(opportunityId));
      dispatch({
        type: types.ADD_LINK_PARTNER_SUCCESS,
        payload: res.data,
      });
      cb && cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_LINK_PARTNER_FAILURE,
        payload: err,
      });
      cb && cb();
    });
};

export const updatePartner = (data) => (dispatch, getState) => {
  const { opportunityId } = getState("opportunity").opportunity.opportunity;
  dispatch({
    type: types.UPDATE_PARTNER_REQUEST,
  });

  axios
    .put(`${base_url}/update/partner`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      // dispatch(getLinkOpportunityPartners(opportunityId));
      dispatch({
        type: types.UPDATE_PARTNER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.UPDATE_PARTNER_FAILURE,
        payload: err,
      });
    });
};

export const updatePartnerVisibility = (data) => (dispatch, getState) => {
  const { opportunityId } = getState("opportunity").opportunity.opportunity;
  dispatch({
    type: types.UPDATE_PARTNER_VISIBILITY_REQUEST,
  });

  axios
    .put(`${base_url}/partner/update-partner-visibility`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      // dispatch(getLinkOpportunityPartners(opportunityId));
      dispatch({
        type: types.UPDATE_PARTNER_VISIBILITY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.UPDATE_PARTNER_VISIBILITY_FAILURE,
        payload: err,
      });
    });
};

export const getPartners = (userId) => (dispatch) => {
  dispatch({
    type: types.GET_PARTNER_REQUEST,
  });

  axios
    .get(`${base_url}/partner/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PARTNER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_PARTNER_FAILURE,
        payload: err,
      });
    });
};

export const getLinkOpportunityPartners = (opportunityId) => (dispatch) => {
  dispatch({
    type: types.GET_LINK_OPPORTUNITY_PARTNER_REQUEST,
  });

  axios
    .get(`${base_url}/linkedPartner/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_LINK_OPPORTUNITY_PARTNER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_LINK_OPPORTUNITY_PARTNER_FAILURE,
        payload: err,
      });
    });
};

export const getPartnersName = () => (dispatch) => {
  dispatch({
    type: types.GET_PARTNER_NAME_REQUEST,
  });

  axios
    .get(`${base_url}/partners`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PARTNER_NAME_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_PARTNER_NAME_FAILURE,
        payload: err,
      });
    });
};

//partner collaborate
export const addCollaboratePartner = (partner, cb) => (dispatch, getState) => {
  const { opportunityId } = getState("opportunity").opportunity.opportunity;
  dispatch({
    type: types.ADD_COLLABORATE_PARTNER_REQUEST,
  });

  axios
    .post(`${base_url}/collaborate/partner/opportunity`, partner, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      dispatch(getLinkOpportunityPartners(opportunityId));
      console.log(res);
      if (res.data.emailInd === true) {
        message.error("Account cannot be created using this user name");
        dispatch({
          type: types.ADD_COLLABORATE_PARTNER_FAILURE,
        });
      } else {
        // message.success("Login request for Partner user accepted");
        // dispatch(getPartners(userId));
        dispatch({
          type: types.ADD_COLLABORATE_PARTNER_SUCCESS,
          payload: res.data,
        });
        cb && cb();
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_COLLABORATE_PARTNER_FAILURE,
        payload: err,
      });
      cb && cb();
    });
};

export const getPartnerById = (partnerId) => (dispatch) => {
  dispatch({
    type: types.GET_PARTNER_BY_ID_REQUEST,
  });
  axios
    .get(`${base_url}/partner-details/${partnerId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PARTNER_BY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_PARTNER_BY_ID_FAILURE,
        payload: err,
      });
    });
};

export const updatePartnerById = (partnerId, data, cb) => (dispatch) => {
  console.log(data);
  dispatch({ type: types.UPDATE_PARTNER_BY_ID_REQUEST });
  axios
    .put(
      `${base_url}/partner/${partnerId}`,
      { ...data },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_PARTNER_BY_ID_SUCCESS,
        payload: res.data,
      });
      cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_PARTNER_BY_ID_FAILURE,
        payload: err,
      });
    });
};
// for user
export const getPartnerUserOpportunityByUserId = (userId, partnerId) => (
  dispatch
) => {
  dispatch({
    type: types.GET_PARTNER_OPPORTUNITY_BY_USER_ID_REQUEST,
  });
  axios
    .get(`${base_url}/partner/user/opportunities/${userId}/${partnerId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PARTNER_OPPORTUNITY_BY_USER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_PARTNER_OPPORTUNITY_BY_USER_ID_FAILURE,
        payload: err,
      });
    });
};
// for partner
export const getPartnerUserOpportunityByPartnerId = (userId, partnerId) => (
  dispatch
) => {
  dispatch({
    type: types.GET_PARTNER_OPPORTUNITY_BY_PARTNER_ID_REQUEST,
  });
  axios
    .get(`${base_url}/partner/user/opportunities/${userId}/${partnerId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PARTNER_OPPORTUNITY_BY_PARTNER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_PARTNER_OPPORTUNITY_BY_PARTNER_ID_FAILURE,
        payload: err,
      });
    });
};

//For Professional+
export const handlePartnerContactModal = (modalProps) => (dispatch) => {
  dispatch({ type: types.HANDLE_PARTNER_CONTACT_MODAL, payload: modalProps });
};

export const addPartnerContactsByPartnerId = (
  contact,
  partnerId,
  userId,
  cb
) => (dispatch) => {
  dispatch({
    type: types.ADD_PARTNER_CONTACT_BY_PARTNERID_ID_REQUEST,
  });
  axios
    .post(`${base_url}/link/partner/contact/${partnerId}`, contact, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(getPartnerContactsByPartnerId(userId, partnerId));
      dispatch({
        type: types.ADD_PARTNER_CONTACT_BY_PARTNERID_ID_SUCCESS,
        payload: res.data,
      });
      cb && cb();
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.ADD_PARTNER_CONTACT_BY_PARTNERID_ID_FAILURE,
        payload: err,
      });
      cb && cb();
    });
};

export const getPartnerContactsByPartnerId = (userId, partnerId) => (
  dispatch
) => {
  dispatch({
    type: types.GET_PARTNER_CONTACT_BY_PARTNERID_ID_REQUEST,
  });
  axios
    .get(`${base_url}/partner/contacts/${userId}/${partnerId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PARTNER_CONTACT_BY_PARTNERID_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_PARTNER_CONTACT_BY_PARTNERID_ID_FAILURE,
        payload: err,
      });
    });
};

export const getPartnerTasksByPartnerId = (partnerId) => (dispatch) => {
  dispatch({
    type: types.GET_PARTNER_TASK_BY_PARTNERID_ID_REQUEST,
  });
  axios
    .get(`${base_url}/partner/contacts/${partnerId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PARTNER_TASK_BY_PARTNERID_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_PARTNER_TASK_BY_PARTNERID_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get topics of interest by partnerId
 */
export const getTopicsByPartnerId = (partnerId) => (dispatch) => {
  dispatch({
    type: types.GET_TOPICS_BY_PARTNER_ID_REQUEST,
  });
  axios
    .get(`${base_url}/topic/partner/${partnerId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_TOPICS_BY_PARTNER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_TOPICS_BY_PARTNER_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * add topic of a partnerId
 */
export const addTopicByPartnerId = (topic) => (dispatch) => {
  console.log(topic);
  dispatch({
    type: types.ADD_TOPIC_BY_PARTNER_ID_REQUEST,
  });
  axios
    .post(`${base_url}/topic`, topic, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.ADD_TOPIC_BY_PARTNER_ID_SUCCESS,
        payload: { ...topic, topicId: res.data },
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_TOPIC_BY_PARTNER_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * delete topics of interest by partnerId
 */
export const deleteTopicByPartnerId = (topicId, partnerId) => (
  dispatch,
  getState
) => {
  const partnerId = getState().partner.partner.partnerId;
  dispatch({
    type: types.DELETE_TOPIC_BY_PARTNER_ID_REQUEST,
  });
  axios
    .delete(`${base_url}/topic/${topicId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(getTopicsByPartnerId(partnerId));
      dispatch({
        type: types.DELETE_TOPIC_BY_PARTNER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.DELETE_TOPIC_BY_PARTNER_ID_FAILURE,
        payload: err,
      });
    });
};

//get inititative wise partner(filter)

export const getInitiativeWisePartner = (userId, initiative) => (dispatch) => {
  dispatch({
    type: types.GET_INITIATIVE_WISE_PARTNER_REQUEST,
  });
  axios
    .get(`${base_url}/partners/topic/${userId}/${initiative}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_INITIATIVE_WISE_PARTNER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_INITIATIVE_WISE_PARTNER_FAILURE,
        payload: err,
      });
    });
};

export const getPartnerTopicList = () => (dispatch) => {
  dispatch({
    type: types.GET_PARTNER_TOPIC_LIST_REQUEST,
  });
  axios
    .get(`${base_url}/partner/topicList`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PARTNER_TOPIC_LIST_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_PARTNER_TOPIC_LIST_FAILURE,
        payload: err,
      });
    });
};

/**
 * get notes list by opportunityId
 */
export const getNotesListByPartnerId = (partnerId, userId) => (dispatch) => {
  dispatch({
    type: types.GET_NOTES_LIST_BY_PARTNER_ID_REQUEST,
  });
  axios
    .get(`${base_url}/note/partner/${partnerId}/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_NOTES_LIST_BY_PARTNER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_NOTES_LIST_BY_PARTNER_ID_FAILURE,
        payload: err,
      });
    });
};

// for partner user

export const getNotesListByUserId = (partnerId, userId) => (dispatch) => {
  dispatch({
    type: types.GET_NOTES_LIST_BY_USER_ID_REQUEST,
  });
  axios
    .get(`${base_url}/note/partner/${partnerId}/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_NOTES_LIST_BY_USER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_NOTES_LIST_BY_USER_ID_FAILURE,
        payload: err,
      });
    });
};
export const handlePartnerDrawer = (drawerProps, isVisible) => (dispatch) => {
  dispatch({
    type: types.HANDLE_PARTNER_DRAWER,
    payload: { props: drawerProps, isVisible: isVisible },
  });
};

export const handleAverageModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_AVERAGE_MODAL,
    payload: modalProps,
  });
};

export const handleActiveModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_ACTIVE_MODAL,
    payload: modalProps,
  });
};

export const handleRegisteredModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_REGISTERED_MODAL,
    payload: modalProps,
  });
};

export const handleWinModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_WIN_MODAL,
    payload: modalProps,
  });
};

export const handleWonModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_WON_MODAL,
    payload: modalProps,
  });
};

export const handleAccountsAddedModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_ACCOUNTSADDED_MODAL,
    payload: modalProps,
  });
};

export const handlePulseAverageModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_PULSEAVERAGE_MODAL,
    payload: modalProps,
  });
};

export const handlePulseActiveModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_PULSEACTIVE_MODAL,
    payload: modalProps,
  });
};

export const handlePulseWinModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_PULSEWIN_MODAL,
    payload: modalProps,
  });
};

export const handlePulseWonModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_PULSEWON_MODAL,
    payload: modalProps,
  });
};

export const handleBarModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_BAR_MODAL,
    payload: modalProps,
  });
};

export const handlePartnerTableContactModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_PARTNER_TABLE_CONTACT_MODAL,
    payload: modalProps,
  });
};

export const getPartnerListViewContactsByPartnerId = (partnerId) => (
  dispatch
) => {
  dispatch({
    type: types.GET_PARTNER_CONTACT_BY_PARTNER_ID_REQUEST,
  });
  axios
    .get(`${base_url}/partner/contacts/${partnerId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PARTNER_CONTACT_BY_PARTNER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_PARTNER_CONTACT_BY_PARTNER_ID_FAILURE,
        payload: err,
      });
    });
};

//PARTNER SHARE
export const sharePartner = (data, cb) => (dispatch, getState) => {
  const { userId } = getState("auth").auth.userDetails;
  dispatch({
    type: types.ADD_SHAREPARTNER_REQUEST,
  });

  axios
    .put(`${base_url}/partner/sharePartners`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.ADD_SHAREPARTNER_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_SHAREPARTNER_FAILURE,
        payload: err,
      });
      cb && cb("failure");
    });
};

export const getsharePartnerUsers = (cb) => (dispatch, getState) => {
  const { userId } = getState("auth").auth.userDetails;
  dispatch({
    type: types.GET_SHAREPARTNER_USERS_REQUEST,
  });

  axios
    .get(`${base_url}/partner/ShareUsers`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_SHAREPARTNER_USERS_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_SHAREPARTNER_USERS_FAILURE,
        payload: err,
      });
      cb && cb("failure");
    });
};

export const getSharePartner = (cb) => (dispatch, getState) => {
  const { userId } = getState("auth").auth.userDetails;
  dispatch({
    type: types.GET_SHAREPARTNER_REQUEST,
  });

  axios
    .get(`${base_url}/partner/sharePartners`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_SHAREPARTNER_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_SHAREPARTNER_FAILURE,
        payload: err,
      });
      cb && cb("failure");
    });
};

export const getPartnersMapOnDashboard = (userId) => (dispatch) => {
  dispatch({
    type: types.GET_MAP_DASHBOARD_PARTNER_REQUEST,
  });
  axios
    .get(`${base_url}/partner/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_MAP_DASHBOARD_PARTNER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_MAP_DASHBOARD_PARTNER_FAILURE,
        payload: err,
      });
    });
};

/**
 * Input data search
 */

export const inputDataSearch = (partnerName) => (dispatch) => {
  dispatch({
    type: types.INPUT_SEARCH_PARTNER_DATA_REQUEST,
  });
  axios
    .get(`${base_url}/partner/Name/${partnerName}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      if (res.data.partnerId) {
        console.log(res.data);
        dispatch();
      }

      dispatch({
        type: types.INPUT_SEARCH_PARTNER_DATA_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.INPUT_SEARCH_PARTNER_DATA_FAILURE,
        payload: err,
      });
    });
};

/**
 *  getPartnerHistory
 */


export const getPartnerHistory = (partnerId) => (dispatch) => {
  dispatch({
    type: types.GET_PARTNER_HISTORY_REQUEST,
  });
  axios
    .get(`${base_url}/partnerHistory/${partnerId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    }
    ).then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PARTNER_HISTORY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_PARTNER_HISTORY_FAILURE,
        payload: err,
      });
    });
};


/**
 *  Partner Table feedback
 */
export const getFeedbackByPartnerId = (partnerId, userId) => (dispatch) => {
  dispatch({
    type: types.GET_FEEDBACK_BY_PARTNER_ID_REQUEST,
  });
  axios
    .get(`${base_url}/feedback/partner/${partnerId}/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_FEEDBACK_BY_PARTNER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_FEEDBACK_BY_PARTNER_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 *  feedback-card modal
 */
export const handleFeedbackModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_FEEDBACK_MODAL,
    payload: modalProps,
  });
}


