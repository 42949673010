import Tabs from "antd/lib/tabs";
import styled from "styled-components";

const StyledTabs = styled(Tabs)`
    .ant-tabs-nav-container {
        color: ${props => props.theme.color};
    }
    .ant-tabs-bar {
        margin: 0.3rem;
    }
    .ant-tabs-nav .ant-tabs-tab{
        margin: 0 0.31em 0 0;
        padding: 0.25em 0.5em;
    }
    .ant-nav-container {
        color: ${props => props.theme.color};
        box-shadow: 0 0.06em 0.25em 0.06em ${props => props.theme.boxShadowColor};
        border: 0.06em solid ${props => props.theme.borderColor}
        border-radius: 0.2rem;
    }
    .ant-tabs-tab .anticon {
        margin-right: 2px;
    }
    
`;
export default StyledTabs;
