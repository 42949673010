import React from "react";
import UnitedStatesofAmerica from "../../../Assets/Images/flag-of-United-States-of-America.png";
import unitedKingdom from "../../../Assets/Images/flag-of-United-Kingdom.png";
import Australia from "../../../Assets/Images/flag-of-Australia.png";
import Austria from "../../../Assets/Images/flag-of-Austria.png";
import Belgium from "../../../Assets/Images/flag-of-Belgium.png";
import Belarus from "../../../Assets/Images/flag-of-Belarus.png";
// import Botswana from "../../../Assets/Images/flag-of-Botswana.png";
// import Cameroon from "../../../Assets/Images/flag-of-Cameroon.png";
import China from "../../../Assets/Images/flag-of-China.png";
import Colombia from "../../../Assets/Images/flag-of-Colombia.png";
import Canada from "../../../Assets/Images/flag-of-Canada.png";
import Cyprus from "../../../Assets/Images/flag-of-Cyprus.png";
import Egypt from "../../../Assets/Images/flag-of-Egypt.png";
import Estonia from "../../../Assets/Images/flag-of-Estonia.png";
import Ethiopia from "../../../Assets/Images/flag-of-Ethiopia.png";
import Finland from "../../../Assets/Images/flag-of-Finland.png";
import France from "../../../Assets/Images/flag-of-France.png";
// import Gambia from "../../../Assets/Images/flag-of-Gambia.png";
import Germany from "../../../Assets/Images/flag-of-Germany.png";
// import Ghana from "../../../Assets/Images/flag-of-Ghana.png";
import Greece from "../../../Assets/Images/flag-of-Greece.png";
import India from "../../../Assets/Images/flag-of-India.png";
import Ireland from "../../../Assets/Images/flag-of-Ireland.png";
import Italy from "../../../Assets/Images/flag-of-Italy.png";
import Kenya from "../../../Assets/Images/flag-of-Kenya.png";
import Latvia from "../../../Assets/Images/flag-of-Latvia.png";
// import Libya from "../../../Assets/Images/flag-of-Libya.png";
import Lithuania from "../../../Assets/Images/flag-of-Lithuania.png";
import Luxembourg from "../../../Assets/Images/flag-of-Luxembourg.png";
// import Mali from "../../../Assets/Images/flag-of-Mali.png";
import Malta from "../../../Assets/Images/flag-of-Malta.png";
import Mauritius from "../../../Assets/Images/flag-of-Mauritius.png";
import Morocco from "../../../Assets/Images/flag-of-Morocco.png";
// import Namibia from "../../../Assets/Images/flag-of-Namibia.png";
import Netherlands from "../../../Assets/Images/flag-of-Netherlands.png";
// import Niger from "../../../Assets/Images/flag-of-Niger.png";
import Nigeria from "../../../Assets/Images/flag-of-Nigeria.png";
import Portugal from "../../../Assets/Images/flag-of-Portugal.png";
import Singapore from "../../../Assets/Images/flag-of-Singapore.png";
import Slovakia from "../../../Assets/Images/flag-of-South-Sudan.png";
import Slovenia from "../../../Assets/Images/flag-of-Slovenia.png";
// import SouthSudan from "../../../Assets/Images/flag-of-South-Sudan.png";
import Spain from "../../../Assets/Images/flag-of-Spain.png";
// import Sudan from "../../../Assets/Images/flag-of-Sudan.png";
// import Tanzania from "../../../Assets/Images/flag-of-Tanzania.png";
// import Uganda from "../../../Assets/Images/flag-of-Uganda.png";
// import Zambia from "../../../Assets/Images/flag-of-Zambia.png";
// import Zimbabwe from "../../../Assets/Images/flag-of-Zimbabwe.png";
import Bangladesh from "../../../Assets/Images/flag-of-Bangladesh.png";
import Argentina from "../../../Assets/Images/flag-of-Argentina.png";
import Brazil from "../../../Assets/Images/flag-of-Brazil.png";

function Flag(props) {
  const { countryDialCode, countryName } = props;

  if (countryName === "United Kingdom") {
    return (
      <div style={{ display: "flex" }}>
        <img src={unitedKingdom} height="15%" width="25%" />

        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "United States") {
    return (
      <div style={{ display: "flex" }}>
        <img src={UnitedStatesofAmerica} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "Australia") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Australia} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "Austria") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Austria} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "Belgium") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Belgium} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  // if (countryName === "Botswana") {
  //   return (
  //     <div style={{ display: "flex" }}>
  //       <img src={Botswana} height="15%" width="25%" />
  //       &nbsp;&nbsp;
  //       {`${countryDialCode}`}
  //     </div>
  //   );
  // }
  if (countryName === "Belarus") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Belarus} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }

  // if (countryName === "Cameroon") {
  //   return (
  //     <div style={{ display: "flex" }}>
  //       <img src={Cameroon} height="15%" width="25%" />
  //       &nbsp;&nbsp;
  //       {`${countryDialCode}`}
  //     </div>
  //   );
  // }
  if (countryName === "Canada") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Canada} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "China") {
    return (
      <div style={{ display: "flex" }}>
        <img src={China} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "Colombia") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Colombia} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "Cyprus") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Cyprus} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "Egypt") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Egypt} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "Estonia") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Estonia} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "Ethiopia") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Ethiopia} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "Finland") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Finland} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "France") {
    return (
      <div style={{ display: "flex" }}>
        <img src={France} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  // if (countryName === "Gambia") {
  //   return (
  //     <div style={{ display: "flex" }}>
  //       <img src={Gambia} height="15%" width="25%" />
  //       &nbsp;&nbsp;
  //       {`${countryDialCode}`}
  //     </div>
  //   );
  // }
  if (countryName === "Germany") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Germany} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }

  // if (countryName === "Ghana") {
  //   return (
  //     <div style={{ display: "flex" }}>
  //       <img src={Ghana} height="15%" width="25%" />
  //       &nbsp;&nbsp;
  //       {`${countryDialCode}`}
  //     </div>
  //   );
  // }
  if (countryName === "Greece") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Greece} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "India") {
    return (
      <div style={{ display: "flex" }}>
        <img src={India} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "Ireland") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Ireland} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "Italy") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Italy} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "Kenya") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Kenya} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "Latvia") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Latvia} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  // if (countryName === "Libya") {
  //   return (
  //     <div style={{ display: "flex" }}>
  //       <img src={Libya} height="15%" width="25%" />
  //       &nbsp;&nbsp;
  //       {`${countryDialCode}`}
  //     </div>
  //   );
  // }
  if (countryName === "Lithuania") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Lithuania} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "Luxembourg") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Luxembourg} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  // if (countryName === "Mali") {
  //   return (
  //     <div style={{ display: "flex" }}>
  //       <img src={Mali} height="15%" width="25%" />
  //       &nbsp;&nbsp;
  //       {`${countryDialCode}`}
  //     </div>
  //   );
  // }
  if (countryName === "Malta") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Malta} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "Mauritius") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Mauritius} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "Morocco") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Morocco} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  // if (countryName === "Namibia") {
  //   return (
  //     <div style={{ display: "flex" }}>
  //       <img src={Namibia} height="15%" width="25%" />
  //       &nbsp;&nbsp;
  //       {`${countryDialCode}`}
  //     </div>
  //   );
  // }
  if (countryName === "Netherlands") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Netherlands} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  // if (countryName === "Niger") {
  //   return (
  //     <div style={{ display: "flex" }}>
  //       <img src={Niger} height="15%" width="25%" />
  //       &nbsp;&nbsp;
  //       {`${countryDialCode}`}
  //     </div>
  //   );
  // }
  if (countryName === "Nigeria") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Nigeria} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "Portugal") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Portugal} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "Singapore") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Singapore} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "Slovakia") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Slovakia} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "Slovenia") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Slovenia} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  // if (countryName === "South Sudan") {
  //   return (
  //     <div style={{ display: "flex" }}>
  //       <img src={SouthSudan} height="15%" width="25%" />
  //       &nbsp;&nbsp;
  //       {`${countryDialCode}`}
  //     </div>
  //   );
  // }
  if (countryName === "Spain") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Spain} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  // if (countryName === "Sudan") {
  //   return (
  //     <div style={{ display: "flex" }}>
  //       <img src={Sudan} height="15%" width="25%" />
  //       &nbsp;&nbsp;
  //       {`${countryDialCode}`}
  //     </div>
  //   );
  // }
  // if (countryName === "Tanzania") {
  //   return (
  //     <div style={{ display: "flex" }}>
  //       <img src={Tanzania} height="15%" width="25%" />
  //       &nbsp;&nbsp;
  //       {`${countryDialCode}`}
  //     </div>
  //   );
  // }
  // if (countryName === "Uganda") {
  //   return (
  //     <div style={{ display: "flex" }}>
  //       <img src={Uganda} height="15%" width="25%" />
  //       &nbsp;&nbsp;
  //       {`${countryDialCode}`}
  //     </div>
  //   );
  // }
  // if (countryName === "Zambia") {
  //   return (
  //     <div style={{ display: "flex" }}>
  //       <img src={Zambia} height="15%" width="25%" />
  //       &nbsp;&nbsp;
  //       {`${countryDialCode}`}
  //     </div>
  //   );
  // }
  // if (countryName === "Zimbabwe") {
  //   return (
  //     <div style={{ display: "flex" }}>
  //       <img src={Zimbabwe} height="15%" width="25%" />
  //       &nbsp;&nbsp;
  //       {`${countryDialCode}`}
  //     </div>
  //   );
  // }
  if (countryName === "Bangladesh") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Bangladesh} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "Argentina") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Argentina} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  }
  if (countryName === "Brazil") {
    return (
      <div style={{ display: "flex" }}>
        <img src={Brazil} height="15%" width="25%" />
        &nbsp;&nbsp;
        {`${countryDialCode}`}
      </div>
    );
  } else {
    return null;
  }
}
export default Flag;