import * as types from "./TeamActionTypes";
import axios from "axios";
import { base_url } from "../../Config/Auth";
import { message } from "antd";
import moment from "moment";
import {
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_REQUEST,
  UPDATE_USER_DETAILS_FAILURE,
  UPDATE_SMARTBOOST_SUCCESS,
  UPDATE_PROFESSIONALDUCT_SUCCESS,
  UPDATE_VIEWPORT_SUCCESS,
  UPDATE_DELIVERY_SUCCESS,
  UPDATE_FINANCE_SUCCESS,
  UPDATE_LEGAL_SUCCESS,
  UPDATE_RISKMANAGEMENT_SUCCESS,
  UPDATE_CUSTOME_MODULE_SUCCESS,
  UPDATE_RECRUITMENT_SUCCESS,
  UPDATE_MARKETING_SUCCESS,
  UPDATE_INVENTORY_SUCCESS,
  UPDATE_FASHION_SUCCESS,
} from "../Auth/AuthTypes";

const dummyData = [
  {
    title: "Legal",
    employees: [
      {
        firstName: "lipsa",
        lastName: "patra",
        middleName: "",
        email: "lipsapatra23@gmail.com",
      },

      {
        firstName: "ipsita",
        lastName: "patra",
        middleName: "",
        email: "patra.ipsita@gmail.com",
      },
    ],
  },
  {
    title: "Diet Consultance",
    employees: [
      {
        firstName: "Avinash",
        lastName: "yadav",
        middleName: "",
        email: "avinash@gmail.com",
      },

      {
        firstName: "Sauhitya",
        lastName: "Garabadu",
        middleName: "",
        email: "sauhitya@gmail.com",
      },
    ],
  },
];
export const getUserLevelUpdate = (userId, data) => (dispatch) => {
  dispatch({
    type: types.UPDATE_USER_LEVEL_REQUEST,
  });

  axios
    .put(`${base_url}/level/${userId}`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      // dispatch(getUsers());
      // const startDate = moment()
      //   .startOf("month")
      //   .toISOString();
      // const endDate = moment()
      //   .endOf("month")
      //   .toISOString();
      // dispatch(getLatestUsers(startDate, endDate));
      // dispatch(getSalesUser());
      // // dispatch(getDeliveryUser());
      // dispatch(getLegalUser());
      // dispatch(getRiskManagementUser());
      // dispatch(getFinanceUser());
      console.log(res);
      dispatch({
        type: types.UPDATE_USER_LEVEL_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      message.error("Oops! something went wrong. Please retry.");
      console.log(err);
      dispatch({
        type: types.UPDATE_USER_LEVEL_FAILURE,
        payload: err,
      });
    });
};

export const getLatestUsers = (startDate, endDate) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/sort/users/?sort=latest`;
  } else {
    api_url = `/sort/users/?sort=latest&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_LATEST_USERS_REQUEST,
  });
  // dispatch({
  //   type: types.GET_LATEST_USERS_SUCCESS,
  //   payload: mockdata
  // });
  // return;

  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_LATEST_USERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_LATEST_USERS_FAILURE,
        payload: err,
      });
    });
};
/**
 * Get all the users list (who can login )
 */
export const getUsers = () => (dispatch) => {
  dispatch({
    type: types.GET_USERS_REQUEST,
  });
  console.log("inside team action get users");
  axios
    .get(`${base_url}/users`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_USERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      message.error("Oops! something went wrong. Please retry.");
      console.log(err);
      dispatch({
        type: types.GET_USERS_FAILURE,
        payload: err,
      });
    });
};

export const getSalesUser = () => (dispatch) => {
  dispatch({
    type: types.GET_SALES_USERS_REQUEST,
  });
  console.log("inside team action get users");
  axios
    .get(`${base_url}/salesUser`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_SALES_USERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      message.error("Oops! something went wrong. Please retry.");

      console.log(err);
      dispatch({
        type: types.GET_SALES_USERS_FAILURE,
        payload: err,
      });
    });
};

export const getOnlySalesUser = () => (dispatch) => {
  dispatch({
    type: types.GET_ONLY_SALES_USERS_REQUEST,
  });
  console.log("inside team action get users");
  axios
    .get(`${base_url}/dropdown/salesUser`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ONLY_SALES_USERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      message.error("Oops! something went wrong. Please retry.");

      console.log(err);
      dispatch({
        type: types.GET_ONLY_SALES_USERS_FAILURE,
        payload: err,
      });
    });
};
//get department
export const getDepartment = () => (dispatch) => {
  dispatch({
    type: types.GET_DEPARTMENT_REQUEST,
  });
  console.log("inside team action get users");
  axios
    .get(`${base_url}/allDept`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_DEPARTMENT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      message.error("Oops! something went wrong. Please retry.");
      console.log(err);
      dispatch({
        type: types.GET_DEPARTMENT_FAILURE,
        payload: err,
      });
    });
};

// get delivery user

export const getDeliveryUser = () => (dispatch) => {
  dispatch({
    type: types.GET_DELIVERY_USERS_REQUEST,
  });
  console.log("inside team action get users");
  axios
    .get(`${base_url}/deliveryUser`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_DELIVERY_USERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      message.error("Oops! something went wrong. Please retry.");
      console.log(err);
      dispatch({
        type: types.GET_DELIVERY_USERS_FAILURE,
        payload: err,
      });
    });
};

// get delivery user

export const getFinanceUser = () => (dispatch) => {
  dispatch({
    type: types.GET_FINANCE_USERS_REQUEST,
  });
  console.log("inside team action get users");
  axios
    .get(`${base_url}/finance`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_FINANCE_USERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      // message.error("Oops! something went wrong. Please retry.");
      console.log(err);
      dispatch({
        type: types.GET_FINANCE_USERS_FAILURE,
        payload: err,
      });
    });
};

//get legal user

export const getLegalUser = () => (dispatch) => {
  dispatch({
    type: types.GET_LEGAL_USERS_REQUEST,
  });
  console.log("inside team action  get legal users");
  axios
    .get(`${base_url}/legal`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_LEGAL_USERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      message.error("Oops! something went wrong. Please retry.");
      console.log(err);
      dispatch({
        type: types.GET_LEGAL_USERS_FAILURE,
        payload: err,
      });
    });
};

//get RISKMANAGENENT user

export const getRiskManagementUser = () => (dispatch) => {
  dispatch({
    type: types.GET_RISKMANAGEMENT_USERS_REQUEST,
  });
  console.log("inside team action  get legal users");
  axios
    .get(`${base_url}/riskManagement`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_RISKMANAGEMENT_USERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      message.error("Oops! something went wrong. Please retry.");
      console.log(err);
      dispatch({
        type: types.GET_RISKMANAGEMENT_USERS_FAILURE,
        payload: err,
      });
    });
};

//get PARTNER user

export const getPartnerUser = () => (dispatch) => {
  dispatch({
    type: types.GET_PARTNER_USERS_REQUEST,
  });
  console.log("inside team action  get legal users");
  axios
    .get(`${base_url}/partner-user`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PARTNER_USERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      message.error("Oops! something went wrong. Please retry.");
      console.log(err);
      dispatch({
        type: types.GET_PARTNER_USERS_FAILURE,
        payload: err,
      });
    });
};

//get CustomeModule user

export const getCustomeModuleUser = () => (dispatch) => {
  dispatch({
    type: types.GET_CUSTOME_MODULE_USERS_REQUEST,
  });
  axios
    .get(`${base_url}/organization/custom-modules`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CUSTOME_MODULE_USERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_CUSTOME_MODULE_USERS_FAILURE,
        payload: err,
      });
    });
};

/**
 * add user request
 * superuser creates another user
 */
export const addUser = (user, cb) => (dispatch) => {
  console.log(user);
  dispatch({
    type: types.ADD_USER_REQUEST,
  });

  axios
    .post(`${base_url}/user`, user, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      if (res.data.emailInd === true) {
        message.error("User Account cannot be created with these credentials");
        dispatch({
          type: types.ADD_USER_FAILURE,
        });
      } else if (res.data.type === "free" && res.data.userCount === 3) {
        message.error("Subscription user count limit reached");
        dispatch({
          type: types.ADD_USER_FAILURE,
        });
      } else {
        message.success("New user added successfully");

        console.log(res);
        dispatch(getUsers());
        const startDate = moment()
          .startOf("month")
          .toISOString();
        const endDate = moment()
          .endOf("month")
          .toISOString();
        dispatch(getLatestUsers(startDate, endDate));
        dispatch(getSalesUser());
        // dispatch(getDeliveryUser());
        dispatch(getLegalUser());
        dispatch(getRiskManagementUser());
        dispatch(getFinanceUser());
        dispatch({
          type: types.ADD_USER_SUCCESS,
          payload: res.data,
        });
        cb && cb("success");
      }
    })
    .catch((err) => {
      message.error("Oops! something went wrong. Please retry.");
      console.log(err);
      dispatch({
        type: types.ADD_USER_FAILURE,
        payload: err,
      });
      cb && cb("failure");
    });
};

/**
 * update aspecific field using put request
 */
export const updateUserById = (id, data, cb) => (dispatch, getState) => {
  console.log(data);
  const { userId } = getState().auth.userDetails;
  if (id === userId) {
    ////debugger
    dispatch({ type: UPDATE_USER_DETAILS_REQUEST });
  }
  dispatch({ type: types.UPDATE_USER_BY_ID_REQUEST });
  axios
    .put(
      `${base_url}/user/${id}`,
      { ...data },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      if (id === userId) {
        dispatch({ type: UPDATE_USER_DETAILS_SUCCESS, payload: res.data });
        sessionStorage.setItem("userDetails", JSON.stringify(res.data));
      }
      dispatch({
        type: types.UPDATE_USER_BY_ID_SUCCESS,
        payload: res.data,
      });
      cb && cb();
    })
    .catch((err) => {
      console.log(err);
      if (id === userId) {
        dispatch({ type: UPDATE_USER_DETAILS_FAILURE });
      }
      dispatch({
        type: types.UPDATE_USER_BY_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * add user by Id request
 * fetching data for single user
 */
export const getUserById = (userId) => (dispatch) => {
  dispatch({
    type: types.GET_USER_BY_ID_REQUEST,
  });

  axios
    .get(`${base_url}/user/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_USER_BY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      message.error("Oops! something went wrong. Please retry.");
      console.log(err);
      dispatch({
        type: types.GET_USER_BY_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * delete user by Id request
 * @param {userId: string}
 */
export const deleteUserById = (userId) => (dispatch) => {
  dispatch({
    type: types.DELETE_USER_BY_ID_REQUEST,
  });

  axios
    .delete(`${base_url}/user/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.DELETE_USER_BY_ID_SUCCESS,
        payload: userId,
      });
    })
    .catch((err) => {
      message.error("Oops! something went wrong. Please retry.");
      console.log(err);
      dispatch({
        type: types.DELETE_USER_BY_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * get account list by userId
 */
export const getContactListByUserId = (userId) => (dispatch) => {
  console.log(userId);
  dispatch({
    type: types.GET_CONTACT_LIST_BY_USER_ID_REQUEST,
  });
  axios
    .get(`${base_url}/contact/user/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CONTACT_LIST_BY_USER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CONTACT_LIST_BY_USER_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * get account list by userId
 */
export const getAccountListByUserId = (userId) => (dispatch) => {
  console.log(userId);
  dispatch({
    type: types.GET_ACCOUNT_LIST_BY_USER_ID_REQUEST,
  });
  axios
    .get(`${base_url}/account/user/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ACCOUNT_LIST_BY_USER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ACCOUNT_LIST_BY_USER_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * get opportunity list by userId
 */
export const getOpportunityListByUserId = (userId) => (dispatch) => {
  console.log(userId);
  dispatch({
    type: types.GET_OPPORTUNITY_LIST_BY_USER_ID_REQUEST,
  });
  axios
    .get(`${base_url}/opportunity/user/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_OPPORTUNITY_LIST_BY_USER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_OPPORTUNITY_LIST_BY_USER_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * get notes list by userId
 */
export const getNotesListByUserId = (userId) => (dispatch) => {
  console.log(userId);
  dispatch({
    type: types.GET_NOTES_LIST_BY_USER_ID_REQUEST,
  });
  axios
    .get(`${base_url}/note/user/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_NOTES_LIST_BY_USER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_NOTES_LIST_BY_USER_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * get call list by userId
 */
export const getCallsListByUserId = (userId) => (dispatch) => {
  console.log(userId);
  dispatch({
    type: types.GET_CALLS_LIST_BY_USER_ID_REQUEST,
  });
  axios
    .get(`${base_url}/call/user/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CALLS_LIST_BY_USER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CALLS_LIST_BY_USER_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * get task list by userId
 */
export const getTasksListByUserId = (userId) => (dispatch) => {
  console.log(userId);
  dispatch({
    type: types.GET_TASKS_LIST_BY_USER_ID_REQUEST,
  });
  axios
    .get(`${base_url}/task/user/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_TASKS_LIST_BY_USER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_TASKS_LIST_BY_USER_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * get event list by userId
 */
export const getEventsListByUserId = (userId) => (dispatch) => {
  console.log(userId);
  dispatch({
    type: types.GET_EVENTS_LIST_BY_USER_ID_REQUEST,
  });
  axios
    .get(`${base_url}/event/user/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_EVENTS_LIST_BY_USER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_EVENTS_LIST_BY_USER_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get topics of interest by contactId
 */
export const getTopicsByUserId = (userId) => (dispatch) => {
  dispatch({
    type: types.GET_TOPICS_BY_USER_ID_REQUEST,
  });
  axios
    .get(`${base_url}/topic/user/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_TOPICS_BY_USER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_TOPICS_BY_USER_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * add topic of a userId
 */
export const addTopicByUserId = (topic) => (dispatch) => {
  console.log(topic);
  dispatch({
    type: types.ADD_TOPIC_BY_USER_ID_REQUEST,
  });
  axios
    .post(`${base_url}/topic`, topic, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.ADD_TOPIC_BY_USER_ID_SUCCESS,
        payload: { ...topic, topicId: res.data },
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_TOPIC_BY_USER_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * get topics of interest by userId
 */
export const deleteTopicByUserId = (topicId, userId) => (dispatch) => {
  dispatch({
    type: types.DELETE_TOPIC_BY_USER_ID_REQUEST,
  });
  axios
    .delete(`${base_url}/topic/${topicId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.DELETE_TOPIC_BY_USER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.DELETE_TOPIC_BY_USER_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get topics of interest by organizationId
 */
export const getTopicsByOrganizationId = (organizationId) => (dispatch) => {
  dispatch({
    type: types.GET_TOPICS_BY_ORGANIZATION_ID_REQUEST,
  });
  axios
    .get(`${base_url}/topic/organization/${organizationId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_TOPICS_BY_ORGANIZATION_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_TOPICS_BY_ORGANIZATION_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * add topic of an organization
 */
export const addTopicByOrganizationId = (topic) => (dispatch) => {
  console.log(topic);
  dispatch({
    type: types.ADD_TOPIC_BY_ORGANIZATION_ID_REQUEST,
  });
  axios
    .post(`${base_url}/topic`, topic, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.ADD_TOPIC_BY_ORGANIZATION_ID_SUCCESS,
        payload: { ...topic, topicId: res.data },
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_TOPIC_BY_ORGANIZATION_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * delete topics os an organization
 */
export const deleteTopicByOrganizationId = (topicId, organizationId) => (
  dispatch
) => {
  dispatch({
    type: types.DELETE_TOPIC_BY_ORGANIZATION_ID_REQUEST,
  });
  axios
    .delete(`${base_url}/topic/${topicId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.DELETE_TOPIC_BY_ORGANIZATION_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.DELETE_TOPIC_BY_ORGANIZATION_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * enable smart boost of an user
 */
export const enableSmartBoostByUserId = (userId) => (dispatch) => {
  dispatch({
    type: types.ENABLE_SMART_BOOST_REQUEST,
  });
  axios
    .post(
      `${base_url}/smartboost/enable/${userId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      dispatch({
        type: types.ENABLE_SMART_BOOST_SUCCESS,
        payload: { userId },
      });
      dispatch({
        type: UPDATE_SMARTBOOST_SUCCESS,
        payload: { userId, smartBoost: res.data.boostInd },
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ENABLE_SMART_BOOST_FAILURE,
        payload: err,
      });
    });
};
/**
 * enable smart boost for all users
 */
export const enableSmartBoostForAll = (organizationId, cb) => (dispatch) => {
  dispatch({
    type: types.ENABLE_SMART_BOOST_FOR_ALL_REQUEST,
  });
  axios
    .post(
      `${base_url}/smartboost/enableAll/${organizationId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      dispatch({
        type: types.ENABLE_SMART_BOOST_FOR_ALL_SUCCESS,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ENABLE_SMART_BOOST_FOR_ALL_FAILURE,
        payload: err,
      });
      cb && cb("error");
    });
};
/**
 * disable smart boost of an user
 */
export const disableSmartBoostByUserId = (userId, cb) => (dispatch) => {
  dispatch({
    type: types.DISABLE_SMART_BOOST_REQUEST,
  });
  axios
    .post(
      `${base_url}/smartboost/disable/${userId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      if (res.data.days < 30) {
        dispatch({
          type: types.UNABLE_TO_DISABLE_SMART_BOOST_SUCCESS,
          payload: res.data,
        });
        cb && cb(res.data.lastDayFromStartDate);
      } else {
        dispatch({
          type: types.DISABLE_SMART_BOOST_SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: UPDATE_SMARTBOOST_SUCCESS,
          payload: { userId, boostInd: res.data.boostInd },
        });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.DISABLE_SMART_BOOST_FAILURE,
        payload: err,
      });
    });
};
/**
 * enable Viewport of an user
 */
export const enableViewport = (organizationId) => (dispatch) => {
  dispatch({
    type: types.ENABLE_VIEWPORT_REQUEST,
  });
  axios
    .post(
      `${base_url}/viewport/enable/${organizationId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log("inside then");
      dispatch({
        type: types.ENABLE_VIEWPORT_SUCCESS,
        payload: { organizationId },
      });
      dispatch({
        type: UPDATE_VIEWPORT_SUCCESS,
        payload: res.data.viewPortInd,
      });
    })
    .catch((err) => {
      console.log("inside catch");
      console.log(err);
      dispatch({
        type: types.ENABLE_VIEWPORT_FAILURE,
        payload: err,
      });
    });
};
/**
 * disable Viewport of an user
 */
export const disableViewport = (organizationId, cb) => (dispatch) => {
  dispatch({
    type: types.DISABLE_VIEWPORT_REQUEST,
  });
  axios
    .post(
      `${base_url}/viewport/disable/${organizationId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      if (res.data.days < 30) {
        dispatch({
          type: types.UNABLE_TO_DISABLE_VIEWPORT_SUCCESS,
          payload: res.data,
        });
        cb && cb();
      } else {
        dispatch({
          type: types.DISABLE_VIEWPORT_SUCCESS,
          payload: { organizationId },
        });
        dispatch({
          type: UPDATE_VIEWPORT_SUCCESS,
          payload: res.data.viewPortInd,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.DISABLE_VIEWPORT_FAILURE,
        payload: err,
      });
      cb && cb();
    });
};

/**
 * enable smart boost of an user
 */
export const enableProductByUserId = (userId) => (dispatch) => {
  dispatch({
    type: types.ENABLE_PROFESSIONALDUCT_REQUEST,
  });
  axios
    .post(
      `${base_url}/product/enable/${userId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      dispatch({
        type: types.ENABLE_PROFESSIONALDUCT_SUCCESS,
        payload: { userId },
      });
      dispatch({
        type: UPDATE_PROFESSIONALDUCT_SUCCESS,
        payload: { userId, productStatus: res.data.productInd },
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ENABLE_PROFESSIONALDUCT_FAILURE,
        payload: err,
      });
    });
};
/**
 * enable product for all users
 */
export const enableProductForAllUsers = (organizationId, cb) => (dispatch) => {
  dispatch({
    type: types.ENABLE_PROFESSIONALDUCT_FOR_ALL_REQUEST,
  });
  axios
    .post(
      `${base_url}/product/enableAll/${organizationId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      dispatch({
        type: types.ENABLE_PROFESSIONALDUCT_FOR_ALL_SUCCESS,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ENABLE_PROFESSIONALDUCT_FOR_ALL_FAILURE,
        payload: err,
      });
      cb && cb("error");
    });
};
/**
 * disable smart boost of an user
 */
export const disableProductByUserId = (userId, cb) => (dispatch) => {
  dispatch({
    type: types.DISABLE_PROFESSIONALDUCT_REQUEST,
  });
  axios
    .post(
      `${base_url}/product/disable/${userId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      if (res.data.days < 30) {
        dispatch({
          type: types.UNABLE_TO_DISABLE_PROFESSIONALDUCT_SUCCESS,
          payload: res.data,
        });
        cb && cb();
      } else {
        dispatch({
          type: types.DISABLE_PROFESSIONALDUCT_SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: UPDATE_SMARTBOOST_SUCCESS,
          payload: { userId, productStatus: res.data.productInd },
        });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.DISABLE_PROFESSIONALDUCT_FAILURE,
        payload: err,
      });
    });
};

/**
 * get billing details list of all users
 */
export const getBillingById = (organizationId, data, cb) => (dispatch) => {
  dispatch({
    type: types.GET_BILLING_REQUEST,
  });
  axios
    .get(
      `${base_url}/organization/billing/${organizationId}?dateString=${data}`,
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      dispatch({
        type: types.GET_BILLING_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_BILLING_FAILURE,
        payload: err,
      });
      cb && cb("error");
    });
};
/**
 * adding user modal action
 */
export const handleUserModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_USER_MODAL,
    payload: modalProps,
  });
};
/**
 *  goal modal action
 */
export const handleGoalModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_GOAL_MODAL,
    payload: modalProps,
  });
};
/**
 * team modal drawer
 */
export const handleTeamDrawer = (drawerProps, isVisible) => (dispatch) => {
  dispatch({
    type: types.HANDLE_TEAM_DRAWER,
    payload: { props: drawerProps, isVisible: isVisible },
  });
};
export const handleTeamDrawerForAdmin = (drawerProps, isVisible) => (
  dispatch
) => {
  dispatch({
    type: types.HANDLE_TEAM_DRAWER_FOR_ADMIN,
    payload: { props: drawerProps, isVisible: isVisible },
  });
};
/**
 * SET TEAM VIEW TYPE
 * TABLE VIEW/CARD VIEW
 */
export const setTeamViewType = (viewType) => (dispatch) => {
  //debugger;

  dispatch({ type: types.SET_TEAM_VIEW_TYPE, payload: viewType });
};
/**
 * SET TEAM FILTER TEXT
 */
export const setTeamFilterText = (teamFilterText) => (dispatch) =>
  dispatch({ type: types.SET_TEAM_FILTER_TEXT, payload: teamFilterText });
/**
 * SET FILTER BY USER OPTION
 */
export const setFilterByUserOption = (userOption) => (dispatch) =>
  dispatch({ type: types.SET_TEAM_FILTER_TEXT, payload: userOption });

/**
 * unlink a contact from account
 */
export const unlinkAccountFromUser = (userId, accountId) => (dispatch) => {
  console.log(userId, accountId);
  axios
    .delete(`${base_url}/user/${userId}/account/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_ACCOUNT_LIST_BY_USER_ID_SUCCESS,
        payload: accountId,
      });
    })
    .catch((err) => console.log(err));
};
/**
 * unlink a contact from account
 */
export const unlinkContactFromUser = (userId, contactId) => (dispatch) => {
  console.log(userId, contactId);
  axios
    .delete(`${base_url}/user/${userId}/contact/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_CONTACT_LIST_BY_USER_ID_SUCCESS,
        payload: contactId,
      });
    })
    .catch((err) => console.log(err));
};
/**
 * unlink a opportunity from account
 */
export const unlinkOpportunityFromUser = (userId, opportunityId) => (
  dispatch
) => {
  console.log(userId, opportunityId);
  axios
    .delete(`${base_url}/user/${userId}/opportunity/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_OPPORTUNITY_LIST_BY_USER_ID_SUCCESS,
        payload: opportunityId,
      });
    })
    .catch((err) => console.log(err));
};
/**
 * unlink a call from account
 */
export const unlinkCallFromUser = (userId, callId) => (dispatch) => {
  console.log(userId, callId);
  axios
    .delete(`${base_url}/user/${userId}/call/${callId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_CALLS_LIST_BY_USER_ID_SUCCESS,
        payload: callId,
      });
    })
    .catch((err) => console.log(err));
};
/**
 * unlink a task from account
 */
export const unlinkTaskFromUser = (userId, taskId) => (dispatch) => {
  console.log(userId, taskId);
  axios
    .delete(`${base_url}/user/${userId}/task/${taskId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_TASKS_LIST_BY_USER_ID_SUCCESS,
        payload: taskId,
      });
    })
    .catch((err) => console.log(err));
};
/**
 * unlink a event from account
 */
export const unlinkEventFromUser = (userId, eventId) => (dispatch) => {
  console.log(userId, eventId);
  axios
    .delete(`${base_url}/user/${userId}/event/${eventId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_EVENTS_LIST_BY_USER_ID_SUCCESS,
        payload: eventId,
      });
    })
    .catch((err) => console.log(err));
};

/**
 * enable delivery of an user
 */
export const enableDelivery = (organizationId) => (dispatch) => {
  dispatch({
    type: types.ENABLE_DELIVERY_REQUEST,
  });
  axios
    .post(
      `${base_url}/delivery/enable/${organizationId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log("inside then");
      dispatch({
        type: types.ENABLE_DELIVERY_SUCCESS,
        payload: { organizationId },
      });
      dispatch({
        type: UPDATE_DELIVERY_SUCCESS,
        payload: res.data.deliveryInd,
      });
    })
    .catch((err) => {
      console.log("inside catch");
      console.log(err);
      dispatch({
        type: types.ENABLE_DELIVERY_FAILURE,
        payload: err,
      });
    });
};
/**
 * disable DELIVERY of an user
 */
export const disableDelivery = (organizationId, cb) => (dispatch) => {
  dispatch({
    type: types.DISABLE_DELIVERY_REQUEST,
  });
  axios
    .post(
      `${base_url}/delivery/disable/${organizationId}`,

      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      dispatch({
        type: types.DISABLE_DELIVERY_SUCCESS,
        payload: { organizationId },
      });
      dispatch({
        type: UPDATE_DELIVERY_SUCCESS,
        payload: res.data.deliveryInd,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.DISABLE_DELIVERY_FAILURE,
        payload: err,
      });
      cb && cb();
    });
};

/**
 * enable finace of an user
 */
export const enableFinance = (organizationId) => (dispatch) => {
  dispatch({
    type: types.ENABLE_FINANCE_REQUEST,
  });
  axios
    .post(
      `${base_url}/finance/enable/${organizationId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log("inside then");
      dispatch({
        type: types.ENABLE_FINANCE_SUCCESS,
        payload: { organizationId },
      });
      dispatch({
        type: UPDATE_FINANCE_SUCCESS,
        // payload: res.data.financeInd
        payload: { organizationId, financeInd: res.data.financeInd },
      });
    })
    .catch((err) => {
      console.log("inside catch");
      console.log(err);
      dispatch({
        type: types.ENABLE_FINANCE_FAILURE,
        payload: err,
      });
    });
};
/**
 * disable finance of an user
 */
export const disableFinance = (organizationId, cb) => (dispatch) => {
  dispatch({
    type: types.DISABLE_FINANCE_REQUEST,
  });
  axios
    .post(
      `${base_url}/finance/disable/${organizationId}`,

      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      dispatch({
        type: types.DISABLE_FINANCE_SUCCESS,
        payload: { organizationId },
      });
      dispatch({
        type: UPDATE_FINANCE_SUCCESS,
        payload: res.data.financeInd,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.DISABLE_FINANCE_FAILURE,
        payload: err,
      });
      cb && cb();
    });
};

/**
 * enable legal of an user
 */
export const enableLegal = (organizationId) => (dispatch) => {
  dispatch({
    type: types.ENABLE_LEGAL_REQUEST,
  });
  axios
    .post(
      `${base_url}/legal/enable/${organizationId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      //////debugger
      console.log("inside then");
      dispatch({
        type: types.ENABLE_LEGAL_SUCCESS,
        payload: { organizationId },
      });
      dispatch({
        type: UPDATE_LEGAL_SUCCESS,
        // payload: res.data.legalInd
        payload: { organizationId, legalInd: res.data.legalInd },
      });
    })
    .catch((err) => {
      //////debugger;
      console.log("inside catch");
      console.log(err);
      dispatch({
        type: types.ENABLE_LEGAL_FAILURE,
        payload: err,
      });
    });
};
/**
 * disable legal of an user
 */
export const disableLegal = (organizationId, cb) => (dispatch) => {
  dispatch({
    type: types.DISABLE_LEGAL_REQUEST,
  });
  axios
    .post(
      `${base_url}/legal/disable/${organizationId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      dispatch({
        type: types.DISABLE_LEGAL_SUCCESS,
        payload: { organizationId },
      });
      dispatch({
        type: UPDATE_LEGAL_SUCCESS,
        payload: res.data.legalInd,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.DISABLE_LEGAL_FAILURE,
        payload: err,
      });
      cb && cb();
    });
};

/**
 * enable RISKMANAGEMENT of an user
 */
export const enableRiskManagement = (organizationId) => (dispatch) => {
  dispatch({
    type: types.ENABLE_RISKMANAGEMENT_REQUEST,
  });
  axios
    .post(
      `${base_url}/risk/enable/${organizationId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      //////debugger
      console.log("inside then");
      dispatch({
        type: types.ENABLE_RISKMANAGEMENT_SUCCESS,
        payload: { organizationId },
      });
      dispatch({
        type: UPDATE_RISKMANAGEMENT_SUCCESS,
        payload: {
          organizationId,
          riskManagementInd: res.data.riskManagementInd,
        },
        // payload: res.data.riskManagementInd
      });
    })
    .catch((err) => {
      console.log("inside catch");
      console.log(err);
      dispatch({
        type: types.ENABLE_RISKMANAGEMENT_FAILURE,
        payload: err,
      });
    });
};
/**
 * disable legal of an user
 */
export const disableRiskManagement = (organizationId, cb) => (dispatch) => {
  dispatch({
    type: types.DISABLE_RISKMANAGEMENT_REQUEST,
  });
  axios
    .post(
      `${base_url}/risk/disable/${organizationId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      dispatch({
        type: types.DISABLE_RISKMANAGEMENT_SUCCESS,
        payload: { organizationId },
      });
      dispatch({
        type: UPDATE_RISKMANAGEMENT_SUCCESS,
        payload: res.data.riskManagementInd,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.DISABLE_RISKMANAGEMENT_FAILURE,
        payload: err,
      });
      cb && cb();
    });
};

//get Reports To user

export const getReportsToUser = () => (dispatch) => {
  //debugger;
  dispatch({
    type: types.GET_REPORTS_TO_USERS_REQUEST,
  });
  console.log("inside team action  get Reports users");
  axios
    .get(`${base_url}/reportsTo`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      //debugger;
      console.log(res);
      dispatch({
        type: types.GET_REPORTS_TO_USERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      message.error("Oops! something went wrong. Please retry.");
      console.log(err);
      dispatch({
        type: types.GET_REPORTS_TO_USERS_FAILURE,
        payload: err,
      });
    });
};

export const addCustomDepartment = (department) => (dispatch) => {
  dispatch({
    type: types.ADD_CUSTOM_DEPARTMENT_REQUEST,
  });

  axios
    .post(`${base_url}/customizedModule`, department, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      message.success(
        `${res.data} Function created Successfully, add users, define and automate processes to boost your productivity.`
      );
      dispatch(getCustomDepartment());
      console.log(res);

      dispatch({
        type: types.ADD_CUSTOM_DEPARTMENT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      message.error("some error occoured");
      console.log(err);
      dispatch({
        type: types.ADD_CUSTOM_DEPARTMENT_FAILURE,
        payload: err,
      });
    });
};

export const getCustomDepartment = () => (dispatch) => {
  dispatch({
    type: types.GET_CUSTOM_DEPARTMENT_REQUEST,
  });

  axios
    .get(`${base_url}/AllcustomizedModule`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);

      dispatch({
        type: types.GET_CUSTOM_DEPARTMENT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      message.error("some error occoured");
      console.log(err);
      dispatch({
        type: types.GET_CUSTOM_DEPARTMENT_FAILURE,
        payload: err,
      });
    });
};

export const enableCustomeModule = (organizationId, name) => (dispatch) => {
  dispatch({
    type: types.ENABLE_CUSTOME_MODULE_REQUEST,
  });
  axios
    .post(
      `${base_url}/customizedDepartment/enable/${organizationId}/${name}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      //////debugger
      console.log("inside then");
      dispatch({
        type: types.ENABLE_CUSTOME_MODULE_SUCCESS,
        payload: { organizationId },
      });
      dispatch({
        type: UPDATE_CUSTOME_MODULE_SUCCESS,
        // payload: res.data.legalInd
        payload: { organizationId, Ind: res.data },
      });
    })
    .catch((err) => {
      //////debugger;
      console.log("inside catch");
      console.log(err);
      dispatch({
        type: types.ENABLE_CUSTOME_MODULE_FAILURE,
        payload: err,
      });
    });
};

/**
 * enable Recruitment of an user
 */
export const enableRecruitment = (organizationId) => (dispatch) => {
  console.log("print organization Id ...........", organizationId);
  dispatch({
    type: types.ENABLE_RECRUITMENT_REQUEST,
  });
  axios
    .post(
      `${base_url}/recruitment/enable/${organizationId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log("inside then");
      dispatch({
        type: types.ENABLE_RECRUITMENT_SUCCESS,
        payload: { organizationId },
      });
      dispatch({
        type: UPDATE_RECRUITMENT_SUCCESS,
        payload: res.data.recruitmentInd,
      });
    })
    .catch((err) => {
      console.log("inside catch");
      console.log(err);
      dispatch({
        type: types.ENABLE_RECRUITMENT_FAILURE,
        payload: err,
      });
    });
};
/**
 * disable Recruitment of an user
 */
export const disableRecruitment = (organizationId, cb) => (dispatch) => {
  dispatch({
    type: types.DISABLE_RECRUITMENT_REQUEST,
  });
  axios
    .post(
      `${base_url}/recruitment/disable/${organizationId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      if (res.data.days < 30) {
        dispatch({
          type: types.UNABLE_TO_DISABLE_RECRUITMENT_SUCCESS,
          payload: res.data,
        });
        cb && cb();
      } else {
        dispatch({
          type: types.DISABLE_RECRUITMENT_SUCCESS,
          payload: { organizationId },
        });
        dispatch({
          type: UPDATE_RECRUITMENT_SUCCESS,
          payload: res.data.recriutmentInd,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.DISABLE_RECRUITMENT_FAILURE,
        payload: err,
      });
      cb && cb();
    });
};

/**
 * enable Marketing of an user
 */
export const enableMarketing = (organizationId) => (dispatch) => {
  console.log("print organization Id ...........", organizationId);
  dispatch({
    type: types.ENABLE_MARKETING_REQUEST,
  });
  axios
    .post(
      `${base_url}/campaign/enable/${organizationId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log("inside then");
      dispatch({
        type: types.ENABLE_MARKETING_SUCCESS,
        payload: { organizationId },
      });
      dispatch({
        type: UPDATE_MARKETING_SUCCESS,
        payload: res.data.campaignInd,
      });
    })
    .catch((err) => {
      console.log("inside catch");
      console.log(err);
      dispatch({
        type: types.ENABLE_MARKETING_FAILURE,
        payload: err,
      });
    });
};

export const addPersonalSignatureByUserId = (data) => (dispatch, getState) => {
  const { userId } = getState().auth.userDetails;
  dispatch({
    type: types.ADD_PERSONAL_SIGNATUE_BY_USER_ID_REQUEST,
  });
  axios
    .put(`${base_url}/user/signature`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(getPersonalSignatureByUserId(userId));
      dispatch({
        type: types.ADD_PERSONAL_SIGNATUE_BY_USER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_PERSONAL_SIGNATUE_BY_USER_ID_FAILURE,
        payload: err,
      });
    });
};

export const addOrganizationSignatureByOrgId = (data) => (
  dispatch,
  getState
) => {
  const { organizationId } = getState().auth.userDetails;
  dispatch({
    type: types.ADD_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_REQUEST,
  });
  axios
    .put(`${base_url}/org/signature`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(getOrganizationSignatureByOrgId(organizationId));
      dispatch({
        type: types.ADD_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_FAILURE,
        payload: err,
      });
    });
};

export const getPersonalSignatureByUserId = (userId) => (dispatch) => {
  dispatch({
    type: types.GET_PERSONAL_SIGNATUE_BY_USER_ID_REQUEST,
  });
  axios
    .get(`${base_url}/user/signature/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PERSONAL_SIGNATUE_BY_USER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_PERSONAL_SIGNATUE_BY_USER_ID_FAILURE,
        payload: err,
      });
    });
};

export const getOrganizationSignatureByOrgId = (orgId) => (dispatch) => {
  dispatch({
    type: types.GET_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_REQUEST,
  });
  axios
    .get(`${base_url}/admin/signature/${orgId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_FAILURE,
        payload: err,
      });
    });
};

export const handleTemplateModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_TEMPLATE_MODAL,
    payload: modalProps,
  });
};

export const userLoggOff = (userId) => (dispatch) => {
  dispatch({
    type: types.USER_LOGG_OFF_REQUEST,
  });
  axios
    .post(`${base_url}/user/logoff/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.USER_LOGG_OFF_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.USER_LOGG_OFF_FAILURE,
        payload: err,
      });
    });
};

/**
 * enable InnoventoryPro of an user
 */
export const enableInnoventoryPro = (organizationId) => (dispatch) => {
  console.log("print organization Id ...........", organizationId);
  dispatch({
    type: types.ENABLE_INNOVENTORY_PRO_REQUEST,
  });
  axios
    .post(
      `${base_url}/inventory/enable/${organizationId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log("inside then", res.data.inventoryInd);
      dispatch({
        type: types.ENABLE_INNOVENTORY_PRO_SUCCESS,
        payload: { organizationId },
      });
      dispatch({
        type: UPDATE_INVENTORY_SUCCESS,
        payload: res.data.inventoryInd,
      });
    })
    .catch((err) => {
      console.log("inside catch");
      console.log(err);
      dispatch({
        type: types.ENABLE_INNOVENTORY_PRO_FAILURE,
        payload: err,
      });
    });
};

export const handleInnoventoryModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_INNOVENTORY_MODAL,
    payload: modalProps,
  });
};
//

// E-commerce

export const enableEcommercePro = (organizationId) => (dispatch) => {
  console.log("print organization Id ...........", organizationId);
  dispatch({
    type: types.ENABLE_ECOMMERCE_PRO_REQUEST,
  });
  axios
    .post(
      `${base_url}/ecom-enable/${organizationId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log("inside then", res.data.ecommerceInd);
      dispatch({
        type: types.ENABLE_ECOMMERCE_PRO_SUCCESS,
        payload: { organizationId },
      });
      // dispatch({
      //   type: UPDATE_ECOMMERCE_SUCCESS,
      //   payload: res.data.ecommerceInd,
      // });
    })
    .catch((err) => {
      console.log("inside catch");
      console.log(err);
      dispatch({
        type: types.ENABLE_ECOMMERCE_PRO_FAILURE,
        payload: err,
      });
    });
};

export const handleEcommerceModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_ECOMMERCE_MODAL,
    payload: modalProps,
  });
};


export const handleLocationModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_LOCATION_MODAL,
    payload: modalProps,
  });
};

//FashionPRo
/**
 * enable InnoventoryPro of an user
 */
export const enableFashionPro = (organizationId) => (dispatch) => {
  console.log("print organization Id ...........", organizationId);
  dispatch({
    type: types.ENABLE_FASHION_PRO_REQUEST,
  });
  axios
    .post(
      `${base_url}/garment-pro-enable/${organizationId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log("inside then", res.data.garmentProInd);
      dispatch({
        type: types.ENABLE_FASHION_PRO_SUCCESS,
        payload: { organizationId },
      });
      dispatch({
        type: UPDATE_FASHION_SUCCESS,
        payload: res.data.garmentProInd,
      });
    })
    .catch((err) => {
      console.log("inside catch");
      console.log(err);
      dispatch({
        type: types.ENABLE_FASHION_PRO_FAILURE,
        payload: err,
      });
    });
};
