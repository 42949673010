export const EXCEL_IMPORT_REQUEST = "EXCEL_IMPORT_REQUEST";
export const EXCEL_IMPORT_SUCCESS = "EXCEL_IMPORT_SUCCESS";
export const EXCEL_IMPORT_FAILURE = "EXCEL_IMPORT_FAILURE";

export const GET_EXCEL_HEADERS_REQUEST = "GET_EXCEL_HEADERS_REQUEST";
export const GET_EXCEL_HEADERS_SUCCESS = "GET_EXCEL_HEADERS_SUCCESS";
export const GET_EXCEL_HEADERS_FAILURE = "GET_EXCEL_HEADERS_FAILURE";

export const GET_CONTACT_MATCHING_FIELDS_REQUEST =
  "GET_CONTACT_MATCHING_FIELDS_REQUEST";
export const GET_CONTACT_MATCHING_FIELDS_SUCCESS =
  "GET_CONTACT_MATCHING_FIELDS_SUCCESS";
export const GET_CONTACT_MATCHING_FIELDS_FAILURE =
  "GET_CONTACT_MATCHING_FIELDS_FAILURE";

export const GET_ACCOUNT_MATCHING_FIELDS_REQUEST =
  "GET_ACCOUNT_MATCHING_FIELDS_REQUEST";
export const GET_ACCOUNT_MATCHING_FIELDS_SUCCESS =
  "GET_ACCOUNT_MATCHING_FIELDS_SUCCESS";
export const GET_ACCOUNT_MATCHING_FIELDS_FAILURE =
  "GET_ACCOUNT_MATCHING_FIELDS_FAILURE";

export const GET_PRODUCT_MATCHING_FIELDS_REQUEST =
  "GET_PRODUCT_MATCHING_FIELDS_REQUEST";
export const GET_PRODUCT_MATCHING_FIELDS_SUCCESS =
  "GET_PRODUCT_MATCHING_FIELDS_SUCCESS";
export const GET_PRODUCT_MATCHING_FIELDS_FAILURE =
  "GET_PRODUCT_MATCHING_FIELDS_FAILURE";

export const GET_SERVICE_MATCHING_FIELDS_REQUEST =
  "GET_SERVICE_MATCHING_FIELDS_REQUEST";
export const GET_SERVICE_MATCHING_FIELDS_SUCCESS =
  "GET_SERVICE_MATCHING_FIELDS_SUCCESS";
export const GET_SERVICE_MATCHING_FIELDS_FAILURE =
  "GET_SERVICE_MATCHING_FIELDS_FAILURE";

export const GET_LEADS_CONTACT_MATCHING_FIELDS_REQUEST =
  "GET_LEADS_CONTACT_MATCHING_FIELDS_REQUEST";
export const GET_LEADS_CONTACT_MATCHING_FIELDS_SUCCESS =
  "GET_LEADS_CONTACT_MATCHING_FIELDS_SUCCESS";
export const GET_LEADS_CONTACT_MATCHING_FIELDS_FAILURE =
  "GET_LEADS_CONTACT_MATCHING_FIELDS_FAILURE";

export const GET_LEADS_ACCOUNT_MATCHING_FIELDS_REQUEST =
  "GET_LEADS_ACCOUNT_MATCHING_FIELDS_REQUEST";
export const GET_LEADS_ACCOUNT_MATCHING_FIELDS_SUCCESS =
  "GET_LEADS_ACCOUNT_MATCHING_FIELDS_SUCCESS";
export const GET_LEADS_ACCOUNT_MATCHING_FIELDS_FAILURE =
  "GET_LEADS_ACCOUNT_MATCHING_FIELDS_FAILURE";

export const MAP_EXCEL_TO_CONTACT_REQUEST = "MAP_EXCEL_TO_CONTACT_REQUEST";
export const MAP_EXCEL_TO_CONTACT_SUCCESS = "MAP_EXCEL_TO_CONTACT_SUCCESS";
export const MAP_EXCEL_TO_CONTACT_FAILURE = "MAP_EXCEL_TO_CONTACT_FAILURE";

export const MAP_EXCEL_TO_ACCOUNT_REQUEST = "MAP_EXCEL_TO_ACCOUNT_REQUEST";
export const MAP_EXCEL_TO_ACCOUNT_SUCCESS = "MAP_EXCEL_TO_ACCOUNT_SUCCESS";
export const MAP_EXCEL_TO_ACCOUNT_FAILURE = "MAP_EXCEL_TO_ACCOUNT_FAILURE";

export const MAP_EXCEL_TO_PRODUCT_REQUEST = "MAP_EXCEL_TO_PRODUCT_REQUEST";
export const MAP_EXCEL_TO_PRODUCT_SUCCESS = "MAP_EXCEL_TO_PRODUCT_SUCCESS";
export const MAP_EXCEL_TO_PRODUCT_FAILURE = "MAP_EXCEL_TO_PRODUCT_FAILURE";

export const MAP_EXCEL_TO_SERVICE_REQUEST = "MAP_EXCEL_TO_SERVICE_REQUEST";
export const MAP_EXCEL_TO_SERVICE_SUCCESS = "MAP_EXCEL_TO_SERVICE_SUCCESS";
export const MAP_EXCEL_TO_SERVICE_FAILURE = "MAP_EXCEL_TO_SERVICE_FAILURE";

export const MAP_EXCEL_TO_LEADS_CONTACT_REQUEST =
  "MAP_EXCEL_TO_LEADS_CONTACT_REQUEST";
export const MAP_EXCEL_TO_LEADS_CONTACT_SUCCESS =
  "MAP_EXCEL_TO_LEADS_CONTACT_SUCCESS";
export const MAP_EXCEL_TO_LEADS_CONTACT_FAILURE =
  "MAP_EXCEL_TO_LEADS_CONTACT_FAILURE";

export const MAP_EXCEL_TO_LEADS_ACCOUNT_REQUEST =
  "MAP_EXCEL_TO_LEADS_ACCOUNT_REQUEST";
export const MAP_EXCEL_TO_LEADS_ACCOUNT_SUCCESS =
  "MAP_EXCEL_TO_LEADS_ACCOUNT_SUCCESS";
export const MAP_EXCEL_TO_LEADS_ACCOUNT_FAILURE =
  "MAP_EXCEL_TO_LEADS_ACCOUNT_FAILURE";
