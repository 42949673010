import styled from "styled-components";

const StyledLabel = styled.label`
  color: ${(props) => (props.color ? props.color : props.theme.color)};
  font-weight: 600;
  margin-left: 0.2rem;
  white-space: nowrap;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "0.91em")};
`;
export default StyledLabel;
