export const UPDATE_USER_LEVEL_REQUEST = "UPDATE_USER_LEVEL_REQUEST";
export const UPDATE_USER_LEVEL_SUCCESS = "UPDATE_USER_LEVEL_SUCCESS";
export const UPDATE_USER_LEVEL_FAILURE = "UPDATE_USER_LEVEL_FAILURE";

export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const GET_LATEST_USERS_REQUEST = "GET_LATEST_USERS_REQUEST";
export const GET_LATEST_USERS_SUCCESS = " GET_LATEST_USERS_SUCCESS";
export const GET_LATEST_USERS_FAILURE = "GET_LATEST_USERS_FAILURE";

export const GET_ONLY_SALES_USERS_REQUEST = "GET_ONLY_SALES_USERS_REQUEST";
export const GET_ONLY_SALES_USERS_SUCCESS = " GET_ONLY_SALES_USERS_SUCCESS";
export const GET_ONLY_SALES_USERS_FAILURE = "GET_ONLY_SALES_USERS_FAILURE";

export const GET_SALES_USERS_REQUEST = "GET_SALES_USERS_REQUEST";
export const GET_SALES_USERS_SUCCESS = " GET_SALES_USERS_SUCCESS";
export const GET_SALES_USERS_FAILURE = "GET_SALES_USERS_FAILURE";
//get department
export const GET_DEPARTMENT_REQUEST = "GET_DEPARTMENT_REQUEST";
export const GET_DEPARTMENT_SUCCESS = " GET_DEPARTMENT_SUCCESS";
export const GET_DEPARTMENT_FAILURE = "GET_DEPARTMENT_FAILURE";
// GET delivery user
export const GET_DELIVERY_USERS_REQUEST = "GET_DELIVERY_USERS_REQUEST";
export const GET_DELIVERY_USERS_SUCCESS = " GET_DELIVERY_USERS_SUCCESS";
export const GET_DELIVERY_USERS_FAILURE = "GET_DELIVERY_USERS_FAILURE";
// GET finance user
export const GET_FINANCE_USERS_REQUEST = "GET_FINANCE_USERS_REQUEST";
export const GET_FINANCE_USERS_SUCCESS = " GET_FINANCE_USERS_SUCCESS";
export const GET_FINANCE_USERS_FAILURE = "GET_FINANCE_USERS_FAILURE";
//get legal user
export const GET_LEGAL_USERS_REQUEST = "GET_LEGAL_USERS_REQUEST";
export const GET_LEGAL_USERS_SUCCESS = " GET_LEGAL_USERS_SUCCESS";
export const GET_LEGAL_USERS_FAILURE = "GET_LEGAL_USERS_FAILURE";
// get riskmanagement user
export const GET_RISKMANAGEMENT_USERS_REQUEST =
  "GET_RISKMANAGEMENT_USERS_REQUEST";
export const GET_RISKMANAGEMENT_USERS_SUCCESS =
  " GET_RISKMANAGEMENT_USERS_SUCCESS";
export const GET_RISKMANAGEMENT_USERS_FAILURE =
  " GET_RISKMANAGEMENT_USERS_FAILURE";

//get partner user
export const GET_PARTNER_USERS_REQUEST = "GET_PARTNER_USERS_REQUEST";
export const GET_PARTNER_USERS_SUCCESS = " GET_PARTNER_USERS_SUCCESS";
export const GET_PARTNER_USERS_FAILURE = "GET_PARTNER_USERS_FAILURE";

export const GET_USER_BY_ID_REQUEST = "GET_USER_BY_ID_REQUEST";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_FAILURE = "GET_USER_BY_ID_FAILURE";

export const UPDATE_USER_BY_ID_REQUEST = "UPDATE_USER_BY_ID_REQUEST";
export const UPDATE_USER_BY_ID_SUCCESS = "UPDATE_USER_BY_ID_SUCCESS";
export const UPDATE_USER_BY_ID_FAILURE = "UPDATE_USER_BY_ID_FAILURE";

export const DELETE_USER_BY_ID_REQUEST = "DELETE_USER_BY_ID_REQUEST";
export const DELETE_USER_BY_ID_SUCCESS = "DELETE_USER_BY_ID_SUCCESS";
export const DELETE_USER_BY_ID_FAILURE = "DELETE_USER_BY_ID_FAILURE";

export const GET_CONTACT_LIST_BY_USER_ID_REQUEST =
  "GET_CONTACT_LIST_BY_USER_ID_REQUEST";
export const GET_CONTACT_LIST_BY_USER_ID_SUCCESS =
  "GET_CONTACT_LIST_BY_USER_ID_SUCCESS";
export const GET_CONTACT_LIST_BY_USER_ID_FAILURE =
  "GET_CONTACT_LIST_BY_USER_ID_FAILURE";

export const UPDATE_CONTACT_LIST_BY_USER_ID_REQUEST =
  "UPDATE_CONTACT_LIST_BY_USER_ID_REQUEST";
export const UPDATE_CONTACT_LIST_BY_USER_ID_SUCCESS =
  "UPDATE_CONTACT_LIST_BY_USER_ID_SUCCESS";
export const UPDATE_CONTACT_LIST_BY_USER_ID_FAILURE =
  "UPDATE_CONTACT_LIST_BY_USER_ID_FAILURE";

export const GET_ACCOUNT_LIST_BY_USER_ID_REQUEST =
  "GET_ACCOUNT_LIST_BY_USER_ID_REQUEST";
export const GET_ACCOUNT_LIST_BY_USER_ID_SUCCESS =
  "GET_ACCOUNT_LIST_BY_USER_ID_SUCCESS";
export const GET_ACCOUNT_LIST_BY_USER_ID_FAILURE =
  "GET_ACCOUNT_LIST_BY_USER_ID_FAILURE";

export const UPDATE_ACCOUNT_LIST_BY_USER_ID_REQUEST =
  "UPDATE_ACCOUNT_LIST_BY_USER_ID_REQUEST";
export const UPDATE_ACCOUNT_LIST_BY_USER_ID_SUCCESS =
  "UPDATE_ACCOUNT_LIST_BY_USER_ID_SUCCESS";
export const UPDATE_ACCOUNT_LIST_BY_USER_ID_FAILURE =
  "UPDATE_ACCOUNT_LIST_BY_USER_ID_FAILURE";

export const GET_OPPORTUNITY_LIST_BY_USER_ID_REQUEST =
  "GET_OPPORTUNITY_LIST_BY_USER_ID_REQUEST";
export const GET_OPPORTUNITY_LIST_BY_USER_ID_SUCCESS =
  "GET_OPPORTUNITY_LIST_BY_USER_ID_SUCCESS";
export const GET_OPPORTUNITY_LIST_BY_USER_ID_FAILURE =
  "GET_OPPORTUNITY_LIST_BY_USER_ID_FAILURE";

export const UPDATE_OPPORTUNITY_LIST_BY_USER_ID_REQUEST =
  "UPDATE_OPPORTUNITY_LIST_BY_USER_ID_REQUEST";
export const UPDATE_OPPORTUNITY_LIST_BY_USER_ID_SUCCESS =
  "UPDATE_OPPORTUNITY_LIST_BY_USER_ID_SUCCESS";
export const UPDATE_OPPORTUNITY_LIST_BY_USER_ID_FAILURE =
  "UPDATE_OPPORTUNITY_LIST_BY_USER_ID_FAILURE";

export const GET_NOTES_LIST_BY_USER_ID_REQUEST =
  "GET_NOTES_LIST_BY_USER_ID_REQUEST";
export const GET_NOTES_LIST_BY_USER_ID_SUCCESS =
  "GET_NOTES_LIST_BY_USER_ID_SUCCESS";
export const GET_NOTES_LIST_BY_USER_ID_FAILURE =
  "GET_NOTES_LIST_BY_USER_ID_FAILURE";

export const UPDATE_NOTES_LIST_BY_USER_ID_REQUEST =
  "UPDATE_NOTES_LIST_BY_USER_ID_REQUEST";
export const UPDATE_NOTES_LIST_BY_USER_ID_SUCCESS =
  "UPDATE_NOTES_LIST_BY_USER_ID_SUCCESS";
export const UPDATE_NOTES_LIST_BY_USER_ID_FAILURE =
  "UPDATE_NOTES_LIST_BY_USER_ID_FAILURE";

export const GET_CALLS_LIST_BY_USER_ID_REQUEST =
  "GET_CALLS_LIST_BY_USER_ID_REQUEST";
export const GET_CALLS_LIST_BY_USER_ID_SUCCESS =
  "GET_CALLS_LIST_BY_USER_ID_SUCCESS";
export const GET_CALLS_LIST_BY_USER_ID_FAILURE =
  "GET_CALLS_LIST_BY_USER_ID_FAILURE";

export const UPDATE_CALLS_LIST_BY_USER_ID_REQUEST =
  "UPDATE_CALLS_LIST_BY_USER_ID_REQUEST";
export const UPDATE_CALLS_LIST_BY_USER_ID_SUCCESS =
  "UPDATE_CALLS_LIST_BY_USER_ID_SUCCESS";
export const UPDATE_CALLS_LIST_BY_USER_ID_FAILURE =
  "UPDATE_CALLS_LIST_BY_USER_ID_FAILURE";

export const GET_EVENTS_LIST_BY_USER_ID_REQUEST =
  "GET_EVENTS_LIST_BY_USER_ID_REQUEST";
export const GET_EVENTS_LIST_BY_USER_ID_SUCCESS =
  "GET_EVENTS_LIST_BY_USER_ID_SUCCESS";
export const GET_EVENTS_LIST_BY_USER_ID_FAILURE =
  "GET_EVENTS_LIST_BY_USER_ID_FAILURE";

export const UPDATE_EVENTS_LIST_BY_USER_ID_REQUEST =
  "UPDATE_EVENTS_LIST_BY_USER_ID_REQUEST";
export const UPDATE_EVENTS_LIST_BY_USER_ID_SUCCESS =
  "UPDATE_EVENTS_LIST_BY_USER_ID_SUCCESS";
export const UPDATE_EVENTS_LIST_BY_USER_ID_FAILURE =
  "UPDATE_EVENTS_LIST_BY_USER_ID_FAILURE";

export const GET_TASKS_LIST_BY_USER_ID_REQUEST =
  "GET_TASKS_LIST_BY_USER_ID_REQUEST";
export const GET_TASKS_LIST_BY_USER_ID_SUCCESS =
  "GET_TASKS_LIST_BY_USER_ID_SUCCESS";
export const GET_TASKS_LIST_BY_USER_ID_FAILURE =
  "GET_TASKS_LIST_BY_USER_ID_FAILURE";

export const UPDATE_TASKS_LIST_BY_USER_ID_REQUEST =
  "UPDATE_TASKS_LIST_BY_USER_ID_REQUEST";
export const UPDATE_TASKS_LIST_BY_USER_ID_SUCCESS =
  "UPDATE_TASKS_LIST_BY_USER_ID_SUCCESS";
export const UPDATE_TASKS_LIST_BY_USER_ID_FAILURE =
  "UPDATE_TASKS_LIST_BY_USER_ID_FAILURE";

export const GET_TOPICS_BY_USER_ID_REQUEST = "GET_TOPICS_BY_USER_ID_REQUEST";
export const GET_TOPICS_BY_USER_ID_SUCCESS = "GET_TOPICS_BY_USER_ID_SUCCESS";
export const GET_TOPICS_BY_USER_ID_FAILURE = "GET_TOPICS_BY_USER_ID_FAILURE";

export const ADD_TOPIC_BY_USER_ID_REQUEST = "ADD_TOPIC_BY_USER_ID_REQUEST";
export const ADD_TOPIC_BY_USER_ID_SUCCESS = "ADD_TOPIC_BY_USER_ID_SUCCESS";
export const ADD_TOPIC_BY_USER_ID_FAILURE = "ADD_TOPIC_BY_USER_ID_FAILURE";

export const DELETE_TOPIC_BY_USER_ID_REQUEST =
  "DELETE_TOPIC_BY_USER_ID_REQUEST";
export const DELETE_TOPIC_BY_USER_ID_SUCCESS =
  "DELETE_TOPIC_BY_USER_ID_SUCCESS";
export const DELETE_TOPIC_BY_USER_ID_FAILURE =
  "DELETE_TOPIC_BY_USER_ID_FAILURE";

export const GET_TOPICS_BY_ORGANIZATION_ID_REQUEST =
  "GET_TOPICS_BY_ORGANIZATION_ID_REQUEST";
export const GET_TOPICS_BY_ORGANIZATION_ID_SUCCESS =
  "GET_TOPICS_BY_ORGANIZATION_ID_SUCCESS";
export const GET_TOPICS_BY_ORGANIZATION_ID_FAILURE =
  "GET_TOPICS_BY_ORGANIZATION_ID_FAILURE";

export const ADD_TOPIC_BY_ORGANIZATION_ID_REQUEST =
  "ADD_TOPIC_BY_ORGANIZATION_ID_REQUEST";
export const ADD_TOPIC_BY_ORGANIZATION_ID_SUCCESS =
  "ADD_TOPIC_BY_ORGANIZATION_ID_SUCCESS";
export const ADD_TOPIC_BY_ORGANIZATION_ID_FAILURE =
  "ADD_TOPIC_BY_ORGANIZATION_ID_FAILURE";

export const DELETE_TOPIC_BY_ORGANIZATION_ID_REQUEST =
  "DELETE_TOPIC_BY_ORGANIZATION_ID_REQUEST";
export const DELETE_TOPIC_BY_ORGANIZATION_ID_SUCCESS =
  "DELETE_TOPIC_BY_ORGANIZATION_ID_SUCCESS";
export const DELETE_TOPIC_BY_ORGANIZATION_ID_FAILURE =
  "DELETE_TOPIC_BY_ORGANIZATION_ID_FAILURE";

export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";

export const ENABLE_SMART_BOOST_REQUEST = "ENABLE_SMART_BOOST_REQUEST";
export const ENABLE_SMART_BOOST_SUCCESS = "ENABLE_SMART_BOOST_SUCCESS";
export const ENABLE_SMART_BOOST_FAILURE = "ENABLE_SMART_BOOST_FAILURE";

export const ENABLE_SMART_BOOST_FOR_ALL_REQUEST =
  "ENABLE_SMART_BOOST_FOR_ALL_REQUEST";
export const ENABLE_SMART_BOOST_FOR_ALL_SUCCESS =
  "ENABLE_SMART_BOOST_FOR_ALL_SUCCESS";
export const ENABLE_SMART_BOOST_FOR_ALL_FAILURE =
  "ENABLE_SMART_BOOST_FOR_ALL_FAILURE";

export const DISABLE_SMART_BOOST_REQUEST = "DISABLE_SMART_BOOST_REQUEST";
export const DISABLE_SMART_BOOST_SUCCESS = "DISABLE_SMART_BOOST_SUCCESS";
export const UNABLE_TO_DISABLE_SMART_BOOST_SUCCESS =
  "UNABLE_TO_DISABLE_SMART_BOOST_SUCCESS";
export const DISABLE_SMART_BOOST_FAILURE = "DISABLE_SMART_BOOST_FAILURE";

export const ENABLE_PROFESSIONALDUCT_REQUEST =
  "ENABLE_PROFESSIONALDUCT_REQUEST";
export const ENABLE_PROFESSIONALDUCT_SUCCESS =
  "ENABLE_PROFESSIONALDUCT_SUCCESS";
export const ENABLE_PROFESSIONALDUCT_FAILURE =
  "ENABLE_PROFESSIONALDUCT_FAILURE";

export const ENABLE_PROFESSIONALDUCT_FOR_ALL_REQUEST =
  "ENABLE_PROFESSIONALDUCT_FOR_ALL_REQUEST";
export const ENABLE_PROFESSIONALDUCT_FOR_ALL_SUCCESS =
  "ENABLE_PROFESSIONALDUCT_FOR_ALL_SUCCESS";
export const ENABLE_PROFESSIONALDUCT_FOR_ALL_FAILURE =
  "ENABLE_PROFESSIONALDUCT_FOR_ALL_FAILURE";

export const DISABLE_PROFESSIONALDUCT_REQUEST =
  "DISABLE_PROFESSIONALDUCT_REQUEST";
export const DISABLE_PROFESSIONALDUCT_SUCCESS =
  "DISABLE_PROFESSIONALDUCT_SUCCESS";
export const UNABLE_TO_DISABLE_PROFESSIONALDUCT_SUCCESS =
  "UNABLE_TO_DISABLE_PROFESSIONALDUCT_SUCCESS";
export const DISABLE_PROFESSIONALDUCT_FAILURE =
  "DISABLE_PROFESSIONALDUCT_FAILURE";

export const ENABLE_VIEWPORT_REQUEST = "ENABLE_VIEWPORT_REQUEST";
export const ENABLE_VIEWPORT_SUCCESS = "ENABLE_VIEWPORT_SUCCESS";
export const ENABLE_VIEWPORT_FAILURE = "ENABLE_VIEWPORT_FAILURE";

export const GET_BILLING_REQUEST = "GET_BILLING_REQUEST";
export const GET_BILLING_SUCCESS = "GET_BILLING_SUCCESS";
export const GET_BILLING_FAILURE = "GET_BILLING_FAILURE";

export const DISABLE_VIEWPORT_REQUEST = "DISABLE_VIEWPORT_REQUEST";
export const DISABLE_VIEWPORT_SUCCESS = "DISABLE_VIEWPORT_SUCCESS";
export const UNABLE_TO_DISABLE_VIEWPORT_SUCCESS =
  "UNABLE_TO_DISABLE_VIEWPORT_SUCCESS";
export const DISABLE_VIEWPORT_FAILURE = "DISABLE_VIEWPORT_FAILURE";

export const HANDLE_USER_MODAL = "HANDLE_USER_MODAL";
export const HANDLE_GOAL_MODAL = "HANDLE_GOAL_MODAL";
export const HANDLE_TEAM_DRAWER = "HANDLE_TEAM_DRAWER";
export const HANDLE_TEAM_DRAWER_FOR_ADMIN = "HANDLE_TEAM_DRAWER_FOR_ADMIN";
export const SET_TEAM_VIEW_TYPE = "SET_TEAM_VIEW_TYPE";
export const SET_TEAM_FILTER_TEXT = "SET_TEAM_FILTER_TEXT";
export const SET_FILTER_BY_USER_OPTION = "SET_FILTER_BY_USER_OPTION";

// FOR DELIVERY
export const ENABLE_DELIVERY_REQUEST = "ENABLE_DELIVERY_REQUEST";
export const ENABLE_DELIVERY_SUCCESS = "ENABLE_DELIVERY_SUCCESS";
export const ENABLE_DELIVERY_FAILURE = "ENABLE_DELIVERY_FAILURE";

export const DISABLE_DELIVERY_REQUEST = "DISABLE_DELIVERY_REQUEST";
export const DISABLE_DELIVERY_SUCCESS = "DISABLE_DELIVERY_SUCCESS";
export const DISABLE_DELIVERY_FAILURE = "DISABLE_DELIVERY_FAILURE";

// FOR FINANCE
export const ENABLE_FINANCE_REQUEST = "ENABLE_FINANCE_REQUEST";
export const ENABLE_FINANCE_SUCCESS = "ENABLE_FINANCE_SUCCESS";
export const ENABLE_FINANCE_FAILURE = "ENABLE_FINANCE_FAILURE";

export const DISABLE_FINANCE_REQUEST = "DISABLE_FINANCE_REQUEST";
export const DISABLE_FINANCE_SUCCESS = "DISABLE_FINANCE_SUCCESS";
export const DISABLE_FINANCE_FAILURE = "DISABLE_FINANCE_FAILURE";

//FOR LEGAL
export const ENABLE_LEGAL_REQUEST = "ENABLE_LEGAL_REQUEST";
export const ENABLE_LEGAL_SUCCESS = "ENABLE_LEGAL_SUCCESS";
export const ENABLE_LEGAL_FAILURE = "ENABLE_LEGAL_FAILURE";

export const DISABLE_LEGAL_REQUEST = "DISABLE_LEGAL_REQUEST";
export const DISABLE_LEGAL_SUCCESS = "DISABLE_LEGAL_SUCCESS";
export const DISABLE_LEGAL_FAILURE = "DISABLE_LEGAL_FAILURE";

//FOR RISKMANAGEMENT
export const ENABLE_RISKMANAGEMENT_REQUEST = "ENABLE_RISKMANAGEMENT_REQUEST";
export const ENABLE_RISKMANAGEMENT_SUCCESS = "ENABLE_RISKMANAGEMENT_SUCCESS";
export const ENABLE_RISKMANAGEMENT_FAILURE = "ENABLE_RISKMANAGEMENT_FAILURE";

export const DISABLE_RISKMANAGEMENT_REQUEST = "DISABLE_RISKMANAGEMENT_REQUEST";
export const DISABLE_RISKMANAGEMENT_SUCCESS = "DISABLE_RISKMANAGEMENT_SUCCESS";
export const DISABLE_RISKMANAGEMENT_FAILURE = "DISABLE_RISKMANAGEMENT_FAILURE";

// GET Reports to user
export const GET_REPORTS_TO_USERS_REQUEST = "GET_REPORTS_TO_USERS_REQUEST";
export const GET_REPORTS_TO_USERS_SUCCESS = " GET_REPORTS_TO_USERS_SUCCESS";
export const GET_REPORTS_TO_USERS_FAILURE = "GET_REPORTS_TO_USERS_FAILURE";

// Add custom department
export const ADD_CUSTOM_DEPARTMENT_REQUEST = "ADD_CUSTOM_DEPARTMENT_REQUEST";
export const ADD_CUSTOM_DEPARTMENT_SUCCESS = " ADD_CUSTOM_DEPARTMENT_SUCCESS";
export const ADD_CUSTOM_DEPARTMENT_FAILURE = "ADD_CUSTOM_DEPARTMENT_FAILURE";

// get custom department
export const GET_CUSTOM_DEPARTMENT_REQUEST = "GET_CUSTOM_DEPARTMENT_REQUEST";
export const GET_CUSTOM_DEPARTMENT_SUCCESS = " GET_CUSTOM_DEPARTMENT_SUCCESS";
export const GET_CUSTOM_DEPARTMENT_FAILURE = "GET_CUSTOM_DEPARTMENT_FAILURE";

//for custome Module
export const ENABLE_CUSTOME_MODULE_REQUEST = "ENABLE_CUSTOME_MODULE_REQUEST";
export const ENABLE_CUSTOME_MODULE_SUCCESS = "ENABLE_CUSTOME_MODULE_SUCCESS";
export const ENABLE_CUSTOME_MODULE_FAILURE = "ENABLE_CUSTOME_MODULE_FAILURE";

//enable Recruitment

export const ENABLE_RECRUITMENT_REQUEST = "ENABLE_RECRUITMENT_REQUEST";
export const ENABLE_RECRUITMENT_SUCCESS = "ENABLE_RECRUITMENT_SUCCESS";
export const ENABLE_RECRUITMENT_FAILURE = "ENABLE_RECRUITMENT_FAILURE";

export const DISABLE_RECRUITMENT_REQUEST = "DISABLE_RECRUITMENT_REQUEST";
export const DISABLE_RECRUITMENT_SUCCESS = "DISABLE_RECRUITMENT_SUCCESS";
export const UNABLE_TO_DISABLE_RECRUITMENT_SUCCESS =
  "UNABLE_TO_DISABLE_RECRUITMENT_SUCCESS";
export const DISABLE_RECRUITMENT_FAILURE = "DISABLE_RECRUITMENT_FAILURE";

//enable Marketing
export const ENABLE_MARKETING_REQUEST = "ENABLE_MARKETING_REQUEST";
export const ENABLE_MARKETING_SUCCESS = "ENABLE_MARKETING_SUCCESS";
export const ENABLE_MARKETING_FAILURE = "ENABLE_MARKETING_FAILURE";

//signature
export const ADD_PERSONAL_SIGNATUE_BY_USER_ID_REQUEST =
  "ADD_PERSONAL_SIGNATUE_BY_USER_ID_REQUEST";
export const ADD_PERSONAL_SIGNATUE_BY_USER_ID_SUCCESS =
  "ADD_PERSONAL_SIGNATUE_BY_USER_ID_SUCCESS";
export const ADD_PERSONAL_SIGNATUE_BY_USER_ID_FAILURE =
  "ADD_PERSONAL_SIGNATUE_BY_USER_ID_FAILURE";

export const GET_PERSONAL_SIGNATUE_BY_USER_ID_REQUEST =
  "GET_PERSONAL_SIGNATUE_BY_USER_ID_REQUEST";
export const GET_PERSONAL_SIGNATUE_BY_USER_ID_SUCCESS =
  "GET_PERSONAL_SIGNATUE_BY_USER_ID_SUCCESS";
export const GET_PERSONAL_SIGNATUE_BY_USER_ID_FAILURE =
  "GET_PERSONAL_SIGNATUE_BY_USER_ID_FAILURE";

export const ADD_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_REQUEST =
  "ADD_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_REQUEST";
export const ADD_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_SUCCESS =
  "ADD_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_SUCCESS";
export const ADD_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_FAILURE =
  "ADD_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_FAILURE";

export const GET_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_REQUEST =
  "GET_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_REQUEST";
export const GET_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_SUCCESS =
  "GET_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_SUCCESS";
export const GET_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_FAILURE =
  "GET_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_FAILURE";

export const HANDLE_TEMPLATE_MODAL = "HANDLE_TEMPLATE_MODAL";

export const USER_LOGG_OFF_REQUEST = "USER_LOGG_OFF_REQUEST";
export const USER_LOGG_OFF_SUCCESS = "USER_LOGG_OFF_SUCCESS";
export const USER_LOGG_OFF_FAILURE = "USER_LOGG_OFF_FAILURE";

export const GET_CUSTOME_MODULE_USERS_REQUEST =
  "GET_CUSTOME_MODULE_USERS_REQUEST";
export const GET_CUSTOME_MODULE_USERS_SUCCESS =
  "GET_CUSTOME_MODULE_USERS_SUCCESS";
export const GET_CUSTOME_MODULE_USERS_FAILURE =
  "GET_CUSTOME_MODULE_USERS_FAILURE";

//Innoventory
export const ENABLE_INNOVENTORY_PRO_REQUEST = "ENABLE_INNOVENTORY_PRO_REQUEST";
export const ENABLE_INNOVENTORY_PRO_SUCCESS = "ENABLE_INNOVENTORY_PRO_SUCCESS";
export const ENABLE_INNOVENTORY_PRO_FAILURE = "ENABLE_INNOVENTORY_PRO_FAILURE";

export const HANDLE_INNOVENTORY_MODAL = "HANDLE_INNOVENTORY_MODAL";
export const HANDLE_LOCATION_MODAL = "HANDLE_LOCATION_MODAL";

//fASHIION
export const ENABLE_FASHION_PRO_REQUEST = "ENABLE_FASHION_PRO_REQUEST";
export const ENABLE_FASHION_PRO_SUCCESS = "ENABLE_FASHION_PRO_SUCCESS";
export const ENABLE_FASHION_PRO_FAILURE = "ENABLE_FASHION_PRO_FAILURE";

// ECOMMERCE

export const ENABLE_ECOMMERCE_PRO_REQUEST = "ENABLE_ECOMMERCE_PRO_REQUEST";
export const ENABLE_ECOMMERCE_PRO_SUCCESS = "ENABLE_ECOMMERCE_PRO_SUCCESS";
export const ENABLE_ECOMMERCE_PRO_FAILURE = "ENABLE_ECOMMERCE_PRO_FAILURE";

export const HANDLE_ECOMMERCE_MODAL = "HANDLE_ECOMMERCE_MODAL";

