import styled from 'styled-components';

const MainWrapper = styled.div`
    border-radius: 0.2rem;
    box-shadow: 0 0.06em 0.25em 0.06em ${props => props.theme.boxShadowColor};
    border: 0.06em solid ${props => props.theme.borderColor}
    background-color: ${props => props.theme.backgroundColor};
    color: ${props => props.theme.color};
    margin: 0.3rem;
    padding: 0.3rem;
`
export default MainWrapper;