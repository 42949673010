import * as types from "./ProductActionTypes";
import { base_url } from "../../Config/Auth";
import axios from "axios";
import moment from "moment";

/**
 * get all the product of the user
 */

export const getProducts = () => (dispatch) => {
  dispatch({
    type: types.GET_PROFESSIONALDUCTS_REQUEST,
  });
  axios
    .get(`${base_url}/products`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PROFESSIONALDUCTS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_PROFESSIONALDUCTS_FAILURE,
        payload: err,
      });
    });
};
export const getService = () => (dispatch) => {
  dispatch({
    type: types.GET_SERVICE_REQUEST,
  });
  axios
    .get(`${base_url}/services`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_SERVICE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_SERVICE_FAILURE,
        payload: err,
      });
    });
};

export const getProductsById = (productId) => (dispatch) => {
  dispatch({
    type: types.GET_PRODUCT_BY_ID_REQUEST,
  });
  axios
    .get(`${base_url}/fetch/product/${productId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PRODUCT_BY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_PRODUCT_BY_ID_FAILURE,
        payload: err,
      });
    });
};

export const getServiceById = (serviceId) => (dispatch) => {
  dispatch({
    type: types.GET_SERVICE_BY_ID_REQUEST,
  });
  axios
    .get(`${base_url}/service/${serviceId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_SERVICE_BY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_SERVICE_BY_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * request for adding a product
 */
export const addProduct = (product, cb) => (dispatch) => {
  console.log("inside add product");
  dispatch({ type: types.ADD_PROFESSIONALDUCT_REQUEST });
  axios
    .post(`${base_url}/product`, product, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(getProducts());
      dispatch({
        type: types.ADD_PROFESSIONALDUCT_SUCCESS,
        payload: res.data,
      });
      cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_PROFESSIONALDUCT_FAILURE,
        payload: err,
      });
      cb();
    });
};

export const addService = (service, cb) => (dispatch) => {
  console.log("inside add product");
  dispatch({ type: types.ADD_SERVICE_REQUEST });
  axios
    .post(`${base_url}/service`, service, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(getService());
      dispatch({
        type: types.ADD_SERVICE_SUCCESS,
        payload: res.data,
      });
      cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_SERVICE_FAILURE,
        payload: err,
      });
      cb();
    });
};

export const updateService = (id, data, cb) => (dispatch) => {
  console.log(data);
  dispatch({ type: types.UPDATE_SERVICE_BY_ID_REQUEST });
  axios
    .put(
      `${base_url}/update/service`,
      { ...data },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_SERVICE_BY_ID_SUCCESS,
        payload: res.data,
      });
      cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_SERVICE_BY_ID_FAILURE,
        payload: err,
      });
    });
};

export const updateProduct = (id, data, cb) => (dispatch) => {
  console.log(data);
  dispatch({ type: types.UPDATE_PRODUCT_BY_ID_REQUEST });
  axios
    .put(
      `${base_url}/updtae/product`,
      { ...data },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_PRODUCT_BY_ID_SUCCESS,
        payload: res.data,
      });
      cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_PRODUCT_BY_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * get all the latest products of the organization
 */
export const getLatestProductsByOrganizationId = (
  organizationId,
  startDate,
  endDate
) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/sort/product/organization/${organizationId}?`;
  } else {
    api_url = `/sort/product/organization/${organizationId}?&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_LATEST_PRODUCTS_BY_ORGANIZATION_ID_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_LATEST_PRODUCTS_BY_ORGANIZATION_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_LATEST_PRODUCTS_BY_ORGANIZATION_ID_FAILURE,
        payload: err,
      });
    });
};


//Search Category
export const getCategory = (category) => (dispatch) => {
  dispatch({
    type: types.GET_CATEGORY_REQUEST,
  });
  axios
    .get(`${base_url}/catalogue/search/${category}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CATEGORY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_CATEGORY_FAILURE,
        payload: err,
      });
    });
};

/**
 * product modal action
 */
export const handleProductModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_PROFESSIONALDUCT_MODAL,
    payload: modalProps,
  });
};
export const handleServiceModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_SERVICE_MODAL,
    payload: modalProps,
  });
};

export const handleConfigureModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_CONFIGURE_MODAL,
    payload: modalProps,
  });
};

export const handleDetailsProductModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_DETAILSFORM_MODAL,
    payload: modalProps,
  });
};

/**
 * SET PROFESSIONALDUCT VIEW TYPE
 * TABLE VIEW/CARD VIEW
 */
export const setProductViewType = (viewType) => (dispatch) =>
  dispatch({ type: types.SET_PROFESSIONALDUCT_VIEW_TYPE, payload: viewType });

export const setSelectedTimeIntervalCatalogue = (selectedTime) => (
  dispatch
) => {
  dispatch({
    type: types.CHANGE_SELECTED_TIME_INTERVAL_CATALOGUE,
    payload: selectedTime,
  });
};

export const setTimeRangeCatalogue = (startDate, endDate) => (dispatch) => {
  dispatch({
    type: types.SET_TIME_INTERVAL_CATALOGUE,
    payload: {
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
    },
  });
};

export const handleWeightedModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_WEIGHTED_MODAL,
    payload: modalProps,
  });
};

export const handleAbsoluteModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_ABSOLUTE_MODAL,
    payload: modalProps,
  });
};

export const handleWinModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_WIN_MODAL,
    payload: modalProps,
  });
};

export const handleWonModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_WON_MODAL,
    payload: modalProps,
  });
};

export const handleCustomerModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_CUSTOMER_MODAL,
    payload: modalProps,
  });
};

//Product PAreto chart
export const getProductDataForParetoChartByUserId = (
  userId,
  startDate,
  endDate
) => (dispatch) => {
  dispatch({
    type: types.GET_PRODUCT_DATA_FOR_PARETO_CHART_BY_USER_ID_REQUEST,
  });
  axios
    // .get(
    //   `${base_url}/accounts/funnel/user/${userId}?startDate=${startDate}&endDate=${endDate}`,
    //   {
    //     headers: {
    //       Authorization: "Bearer " + sessionStorage.getItem("token") || "",
    //     },
    //   }
    // )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PRODUCT_DATA_FOR_PARETO_CHART_BY_USER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_PRODUCT_DATA_FOR_PARETO_CHART_BY_USER_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get list of products added by an user inside a date range
 */
export const getProductDataForCharts = (userId, startDate, endDate) => (
  dispatch
) => {
  dispatch({
    type: types.GET_PRODUCT_DATA_FOR_CHARTS_REQUEST,
  });
  axios
    .get(
      `${base_url}/orderd/product/${userId}?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PRODUCT_DATA_FOR_CHARTS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_PRODUCT_DATA_FOR_CHARTS_FAILURE,
        payload: err,
      });
    });
};

/**
 * Get Product chart by userId
 */
export const getProductChartByTeamId = (userId, startDate, endDate) => (
  dispatch
) => {
  dispatch({
    type: types.GET_PRODUCT_CHART_BY_USER_ID_REQUEST,
  });
  axios
    .get(
      `${base_url}/orderd/product/${userId}?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PRODUCT_CHART_BY_USER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_PRODUCT_CHART_BY_USER_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * Input data search
 */

export const inputDataSearch = (name) => (dispatch) => {
  dispatch({
    type: types.INPUT_SEARCH_PRODUCT_DATA_REQUEST,
  });
  axios
    .get(`${base_url}/product/Name/${name}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },

    })
    .then((res) => {
      if (res.data.productId) {
        console.log(res.data);
        dispatch();
      }

      dispatch({
        type: types.INPUT_SEARCH_PRODUCT_DATA_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.INPUT_SEARCH_PRODUCT_DATA_FAILURE,
        payload: err,
      });
    });
};
/**
 * Discount Modal
 */
export const handleDiscountModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_DISCOUNT_BUTTON_MODAL,
    payload: modalProps,
  });
};
// discount history
export const getDiscountHistory = (productId) => (dispatch) => {
  dispatch({
    type: types.GET_DISCOUNT_HISTORY_REQUEST,
  });
  axios
    .get(`${base_url}/discount/${productId}`)
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_DISCOUNT_HISTORY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_DISCOUNT_HISTORY_FAILURE,
        payload: err,
      });
    });
};