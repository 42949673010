import React, { Component } from "react";
import { Tabs, Icon, Button } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import LinkProductForm from "./LinkProductForm";
import LinkServiceForm from "./LinkServiceForm";
import { FormattedMessage } from "react-intl";
import { StyledModal } from "../../../../../Components/UI/Antd";
import { StyledTabs } from "../../../../../Components/UI/Antd";

const TabPane = StyledTabs.TabPane;
class LinkConfigureModal extends Component {
  render() {
    const { addLinkConfigureModal, handleLinkConfigureModal } = this.props;
    return (
      <div>
        <StyledModal
          // title="Configure"
          title={<FormattedMessage
            id="app.configure"
            defaultMessage="Configure"
          />}
          width="55vw"
          visible={addLinkConfigureModal}
          destroyOnClose
          maskClosable={false}
          // maskStyle={{transition: '0.5s filter linear', filter: 'blur(1.25em)', width: '100%', height: '100%', padding: '3.12em', backgroundColor: 'rgba(49, 56, 66,0.7)'}}
          maskStyle={{ backgroundColor: "rgba(1, 30, 71,0.7)" }}
          style={{ top: 40 }}
          onCancel={() => handleLinkConfigureModal(false)}
          footer={null}
        >
          <StyledTabs defaultActiveKey="1">
            <TabPane tab={`Product`} key="1">
              <div style={{ marginTop: 20 }}>
                <LinkProductForm />
              </div>
            </TabPane>
            <TabPane tab={`Service`} key="2">
              <div style={{ marginTop: 20 }}>
                <LinkServiceForm />
              </div>
            </TabPane>
          </StyledTabs>
        </StyledModal>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(LinkConfigureModal);
