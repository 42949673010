import * as types from "./StripeActionTypes";

const initialState = {
  addStripeModal: false,

  addingMakePayment: false,
  addingMakePaymentError: false,
  makePayment: []
};

export const stripeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.HANDLE_STRIPE_MODAL:
      return { ...state, addStripeModal: action.payload };

    case types.MAKE_PAYMENT_REQUEST:
      return { ...state, addingMakePayment: true };
    case types.MAKE_PAYMENT_SUCCESS:
      return {
        ...state,
        addingMakePayment: false,
        makePayment: action.payload
      };
    case types.MAKE_PAYMENT_FAILURE:
      return {
        ...state,
        addingMakePayment: false,
        addingMakePaymentError: true
      };
    default:
      return state;
  }
  return state;
};
