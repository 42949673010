import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Button } from "antd";
import { AuthContainer, FormWrapper, Input } from "./styled";
import {
  SubTitle,
  ValidationError,
  HeaderText,
  Spacer,
} from "../../Components/UI/Elements";
import { FlexContainer } from "../../Components/UI/Layout";
import FWLogo from "../../Assets/Images/logo_22.png";
import RandomImageScreen from "./RandomImageScreen";

/**
 * yup validation scheme for set Password
 */

class ApprovedQuoteByAdminMessage extends Component {
  render() {
    return (
      <>
        <FlexContainer>
          <AuthContainer
            style={{ backgroundColor: "#F5F5F5", flexDirection: "column" }}
          >
            <img className="big-logo" src={FWLogo} style={{ width: 200 }} />
            <br />

            <p
              style={{
                color: "black",
                fontSize: "1.37em",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Many thanks for Approving the Quotation!
            </p>
          </AuthContainer>
          <RandomImageScreen />
        </FlexContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApprovedQuoteByAdminMessage)
);
