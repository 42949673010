import * as types from "./ProductActionTypes";
import moment from "moment";
const initialState = {
  addWeightedModal: false,
  addAbsoluteModal: false,
  addWinModal: false,
  addWonModal: false,
  addCustomerModal: false,
  dateRangeList: [
    {
      id: 1,
      type: "year",
      value: "FY",
      starter: true,
      isSelected: true,
      startDate: moment()
        .startOf("year")
        .toISOString(),
      endDate: moment()
        .endOf("year")
        .toISOString(),
    },
    {
      id: 2,
      type: "quarter",
      value: "QTD",
      starter: false,
      isSelected: false,
      startDate: moment()
        .startOf("quarter")
        .toISOString(),
      endDate: moment()
        .endOf("quarter")
        .toISOString(),
    },
    {
      id: 3,
      type: "month",
      value: "MTD",
      starter: false,
      isSelected: false,
      startDate: moment()
        .startOf("month")
        .toISOString(),
      endDate: moment()
        .endOf("month")
        .toISOString(),
    },
    {
      id: 4,
      type: "week",
      value: "1W",
      starter: false,
      isSelected: false,
      startDate: moment()
        .startOf("week")
        .toISOString(),
      endDate: moment()
        .endOf("week")
        .toISOString(),
    },
  ],
  viewType: "grid",
  addProductModal: false,
  addServiceModal: false,
  addConfigureModal: false,
  addDetailsProductModal: false,
  addDiscountModal: false,

  addingProduct: false,
  addingProductError: false,

  addingService: false,
  addingServiceError: false,

  fetchingInputProductData: false,
  fetchingInputProductDataError: false,

  fetchingLatestProductsByOrganizationId: false,
  fetchingLatestProductsByOrganizationIdError: false,
  latestProductsByOrganizationId: [],

  fetchingProducts: false,
  fetchingProductsError: false,
  products: [],

  fetchingProductsById: false,
  fetchingProductsByIdError: false,
  productsById: {},

  fetchingService: false,
  fetchingServiceError: false,
  services: [],

  fetchingServiceById: false,
  fetchingServiceByIdError: false,
  serviceById: {},

  updateServiceById: false,
  updateServiceByIdError: false,

  updateProductById: false,
  updateProductByIdError: false,

  isCustomSelected: false,

  fetchingCategory: false,
  fetchingCategoryError: false,
  category: [],

  fetchingParetoChartByUserId: false,
  fetchingParetoChartByUserIdError: false,

  fetchingProductDataForChart: false,
  productDataForChart: [],
  fetchingProductDataForChartError: false,

  fetchingOpportunityChartByUserId: false,
  fetchingOpportunityChartByUserIdError: false,
  opportunityChartByTeamId: {},

  fetchingDiscountHistory: false,
  fetchingDiscountHistoryError: false,
  discountHistory: []

};
const newDateRange = (dateRange, newDate) =>
  dateRange.map((range) => {
    if (range.id === newDate.id) {
      return { ...range, isSelected: true };
    } else {
      return { ...range, isSelected: false };
    }
  });
export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PRODUCT_BY_ID_REQUEST:
      return {
        ...state,
        fetchingProductsById: true,
        fetchingProductsByIdError: false,
      };
    case types.GET_PRODUCT_BY_ID_SUCCESS:
      return {
        ...state,
        fetchingProductsById: false,
        productsById: action.payload,
      };
    case types.GET_PRODUCT_BY_ID_FAILURE:
      return {
        ...state,
        fetchingProductsById: false,
        fetchingProductsByIdError: true,
      };

    case types.GET_SERVICE_BY_ID_REQUEST:
      return {
        ...state,
        fetchingServiceById: true,
        fetchingServiceByIdError: false,
      };
    case types.GET_SERVICE_BY_ID_SUCCESS:
      return {
        ...state,
        fetchingServiceById: false,
        serviceById: action.payload,
      };
    case types.GET_SERVICE_BY_ID_FAILURE:
      return {
        ...state,
        fetchingServiceById: false,
        fetchingServiceByIdError: true,
      };

    /**
     * update a single contact by its ID
     */
    case types.UPDATE_SERVICE_BY_ID_REQUEST:
      return { ...state, updateServiceById: true };
    case types.UPDATE_SERVICE_BY_ID_SUCCESS:
      return {
        ...state,
        updateServiceById: false,
        serviceById: action.payload,
      };
    case types.UPDATE_SERVICE_BY_ID_FAILURE:
      return {
        ...state,
        updateServiceById: false,
        updateServiceByIdError: true,
      };

    case types.UPDATE_PRODUCT_BY_ID_REQUEST:
      return { ...state, updateProductById: true };
    case types.UPDATE_PRODUCT_BY_ID_SUCCESS:
      return {
        ...state,
        updateProductById: false,
        productsById: action.payload,
      };
    case types.UPDATE_PRODUCT_BY_ID_FAILURE:
      return {
        ...state,
        updateProductById: false,
        updateProductByIdError: true,
      };

    case types.GET_PROFESSIONALDUCTS_REQUEST:
      return { ...state, fetchingProducts: true, fetchingProductsError: false };
    case types.GET_PROFESSIONALDUCTS_SUCCESS:
      return { ...state, fetchingProducts: false, products: action.payload };
    case types.GET_PROFESSIONALDUCTS_FAILURE:
      return { ...state, fetchingProducts: false, fetchingProductsError: true };

    case types.GET_SERVICE_REQUEST:
      return { ...state, fetchingService: true, fetchingServiceError: false };
    case types.GET_SERVICE_SUCCESS:
      return { ...state, fetchingService: false, services: action.payload };
    case types.GET_SERVICE_FAILURE:
      return { ...state, fetchingService: false, fetchingServiceError: true };

    case types.ADD_PROFESSIONALDUCT_REQUEST:
      return { ...state, addingProduct: true, addingProductError: false };
    case types.ADD_PROFESSIONALDUCT_SUCCESS:
      return { ...state, addingProduct: false, addConfigureModal: false };
    case types.ADD_PROFESSIONALDUCT_FAILURE:
      return {
        ...state,
        addingProduct: false,
        addingProductError: true,
        addConfigureModal: false,
      };

    case types.ADD_SERVICE_REQUEST:
      return { ...state, addingService: true, addingServiceError: false };
    case types.ADD_SERVICE_SUCCESS:
      return { ...state, addingService: false, addConfigureModal: false };
    case types.ADD_SERVICE_FAILURE:
      return {
        ...state,
        addingService: false,
        addingServiceError: true,
        addConfigureModal: false,
      };

    case types.GET_LATEST_PRODUCTS_BY_ORGANIZATION_ID_REQUEST:
      return { ...state, fetchingLatestProductsByOrganizationId: true };
    case types.GET_LATEST_PRODUCTS_BY_ORGANIZATION_ID_SUCCESS:
      return {
        ...state,
        fetchingLatestProductsByOrganizationId: false,
        latestProductsByOrganizationId: action.payload,
      };
    case types.GET_LATEST_PRODUCTS_BY_ORGANIZATION_ID_FAILURE:
      return {
        ...state,
        fetchingLatestProductsByOrganizationId: false,
        fetchingLatestProductsByOrganizationIdError: true,
      };

    case types.HANDLE_PROFESSIONALDUCT_MODAL:
      return { ...state, addProductModal: action.payload };
    case types.HANDLE_SERVICE_MODAL:
      return { ...state, addServiceModal: action.payload };
    case types.HANDLE_CONFIGURE_MODAL:
      return { ...state, addConfigureModal: action.payload };
    case types.HANDLE_DETAILSFORM_MODAL:
      return { ...state, addDetailsProductModal: action.payload };
    case types.ADD_PROFESSIONALDUCT_FAILURE:
      return { ...state, viewType: action.payload };
    case types.SET_PROFESSIONALDUCT_VIEW_TYPE:
      return { ...state, viewType: action.payload };
    case types.CHANGE_SELECTED_TIME_INTERVAL_CATALOGUE:
      return {
        ...state,
        dateRangeList: newDateRange(state.dateRangeList, action.payload),
        isCustomSelected: false,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        timeRangeType: action.payload.type,
      };
    case types.SET_TIME_INTERVAL_CATALOGUE:
      return {
        ...state,
        isCustomSelected: true,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };

    case types.HANDLE_WEIGHTED_MODAL:
      return { ...state, addWeightedModal: action.payload };

    case types.HANDLE_WIN_MODAL:
      return { ...state, addWinModal: action.payload };

    case types.HANDLE_WON_MODAL:
      return { ...state, addWonModal: action.payload };

    case types.HANDLE_CUSTOMER_MODAL:
      return { ...state, addCustomerModal: action.payload };

    case types.HANDLE_ABSOLUTE_MODAL:
      return { ...state, addAbsoluteModal: action.payload };

    case types.GET_CATEGORY_REQUEST:
      return {
        ...state,
        fetchingCategory: true,
        fetchingCategoryError: false
      };
    case types.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        fetchingCategory: false,
        category: action.payload
      };
    case types.GET_CATEGORY_FAILURE:
      return {
        ...state,
        fetchingCategory: false,
        fetchingCategoryError: true
      };

    case types.GET_PRODUCT_DATA_FOR_PARETO_CHART_BY_USER_ID_REQUEST:
      return {
        ...state,
        fetchingParetoChartByUserId: true,
        fetchingParetoChartByUserIdError: false,
      };
    case types.GET_PRODUCT_DATA_FOR_PARETO_CHART_BY_USER_ID_SUCCESS:
      return {
        ...state,
        fetchingParetoChartByUserId: false,
        fetchingParetoChartByUserIdError: false,
        paretoChartDataForUser: action.payload,
      };
    case types.GET_PRODUCT_DATA_FOR_PARETO_CHART_BY_USER_ID_FAILURE:
      return {
        ...state,
        fetchingParetoChartByUserId: false,
        fetchingParetoChartByUserIdError: true,
      };
    case types.GET_PRODUCT_DATA_FOR_CHARTS_REQUEST:
      return {
        ...state,
        fetchingProductDataForChart: true,
        fetchingProductDataForChartError: false,
      };
    case types.GET_PRODUCT_DATA_FOR_CHARTS_SUCCESS:
      return {
        ...state,
        fetchingProductDataForChart: false,
        fetchingProductDataForChartError: false,
        productDataForChart: action.payload,
      };
    case types.GET_PRODUCT_DATA_FOR_CHARTS_FAILURE:
      return {
        ...state,
        fetchingProductDataForChart: false,
        fetchingProductDataForChartError: true,
      };

    case types.GET_PRODUCT_CHART_BY_USER_ID_REQUEST:
      return {
        ...state,
        fetchingOpportunityChartByUserId: true,
        fetchingOpportunityChartByUserIdError: false,
      };
    case types.GET_PRODUCT_CHART_BY_USER_ID_SUCCESS:
      return {
        ...state,
        fetchingOpportunityChartByUserId: false,
        fetchingOpportunityChartByUserIdError: false,
        opportunityChartByUserId: action.payload,
      };
    case types.GET_PRODUCT_CHART_BY_USER_ID_FAILURE:
      return {
        ...state,
        fetchingOpportunityChartByUserId: false,
        fetchingOpportunityChartByUserIdError: true,
      };

    case types.INPUT_SEARCH_PRODUCT_DATA_REQUEST:
      return { ...state, fetchingInputProductData: true };
    case types.INPUT_SEARCH_PRODUCT_DATA_SUCCESS:
      return {
        ...state,
        fetchingInputProductData: false,
        products: action.payload,
      };
    case types.INPUT_SEARCH_PRODUCT_DATA_FAILURE:
      return { ...state, fetchingInputProductDataError: true };

    case types.HANDLE_DISCOUNT_BUTTON_MODAL:
      return { ...state, addDiscountModal: action.payload };

    /**
* get the discount history of product
*/
    case types.GET_DISCOUNT_HISTORY_REQUEST:
      return { ...state, fetchingDiscountHistory: true };
    case types.GET_DISCOUNT_HISTORY_SUCCESS:
      return {
        ...state,
        fetchingDiscountHistory: false,
        discountHistory: action.payload,
      };
    case types.GET_DISCOUNT_HISTORY_FAILURE:
      return {
        ...state,
        fetchingDiscountHistory: false,
        fetchingDiscountHistoryError: true,
      };



    default:
      return state;
  }
};
