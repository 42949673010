import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import { Button, Switch } from "antd";
import { Formik, Form, Field } from "formik";
import { Spacer, StyledLabel } from "../../../../../Components/UI/Elements";
import SearchSelect from "../../../../../Components/Forms/Formik/SearchSelect";
import { InputComponent } from "../../../../../Components/Forms/Formik/InputComponent";
import { FlexContainer } from "../../../../../Components/UI/Layout";
import ServiceClearbit from "../../../../../Components/Forms/Autocomplete/ServiceClearbit";
import { setClearbitServiceData } from "../../../../Account/AccountAction";
import { linkServiceToOpportunity } from "../../../OpportunityAction";
import * as Yup from "yup";

/**
 * yup validation scheme for creating a productaddService
 */
const ProductSchema = Yup.object().shape({
  quantity: Yup.number().typeError("Value must be a number"),
  noOfUnit: Yup.string().required("Input needed!"),
  rate: Yup.string().required("Input needed!")
  // .required("Input needed! "),
});

class ServiceForm extends Component {
  callback = (restForm) => {
    console.log("callback");
    restForm();
  };

  render() {
    const {
      opportunity: { opportunityId, currency },
      linkingServiceToOpportunity,
    } = this.props;
    // const { addingService, addService } = this.props;
    return (
      <>
        <Formik
          initialValues={{
            role: "",
            maxRate: "",
            minRate: "",
            region: "",
            serviceCurrency: "",
            opportunityCurrency: this.props.opportunity.currency,
            serviceId: this.props.serviceId,
            opportunityId: opportunityId,
            unit: "",
            rate: "",
            noOfUnit: "",
          }}
          validationSchema={ProductSchema}
          onSubmit={(values, { resetForm }) => {
            ////debugger;
            console.log(values);
            this.props.linkServiceToOpportunity(
              opportunityId,
              { ...values, serviceId: this.props.serviceId },
              () => this.callback(resetForm)
            );
          }}
        >
          {({
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            values,
            ...rest
          }) => (
            <Form>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    height: "100%",
                    width: "45%",
                  }}
                >
                  <div>
                    <Field
                      isRequired
                      name="label"
                      type="text"
                      // label="Search"
                      label={<FormattedMessage
                        id="app.search"
                        defaultMessage="Search"
                      />}
                      placeholder="Search or Createto search..."
                      isColumn
                      setClearbitServiceData={setClearbitServiceData}
                      component={ServiceClearbit}
                      inlineLabel
                      style={{ flexBasis: "80%" }}
                    />
                  </div>
                  <Spacer />
                  <div>
                    <Field
                      disabled="true"
                      name="role"
                      // label="Role"
                      label={<FormattedMessage
                        id="app.role"
                        defaultMessage="Role"
                      />}
                      width={"100%"}
                      component={InputComponent}
                      isColumn
                      inlineLabel
                      style={{ flexBasis: "80%" }}
                    />
                  </div>

                  <Spacer />
                  <div>
                    <Field
                      disabled="true"
                      name="region"
                      // label="Region"
                      label={<FormattedMessage
                        id="app.region"
                        defaultMessage="Region"
                      />}
                      width={"100%"}
                      component={InputComponent}
                      isColumn
                      inlineLabel
                      style={{ flexBasis: "80%" }}
                    />
                  </div>

                  <Spacer />
                </div>
                <div
                  style={{
                    height: "100%",
                    width: "45%",
                  }}
                >
                  <FlexContainer justifyContent="space-between">
                    <div style={{ width: "47%" }}>
                      <Field
                        disabled="true"
                        name="maxRate"
                        // label="Max Rate (Sell)"
                        label={<FormattedMessage
                          id="app.maxrate(sell)"
                          defaultMessage="Max Rate (Sell)"
                        />}
                        component={InputComponent}
                        inlineLabel
                        isColumn
                        width={"100%"}
                        style={{
                          flexBasis: "80%",
                          marginTop: "0em",
                        }}
                      />
                    </div>
                    <div style={{ width: "47%" }}>
                      <Field
                        disabled="true"
                        name="minRate"
                        // label="Min Rate (Sell)"
                        label={<FormattedMessage
                          id="app.minrate(sell)"
                          defaultMessage="Min Rate (Sell)"
                        />}
                        component={InputComponent}
                        inlineLabel
                        isColumn
                        width={"100%"}
                        style={{
                          flexBasis: "80%",
                          marginTop: "0em",
                        }}
                      />
                    </div>
                  </FlexContainer>
                  <Spacer />
                  <FlexContainer justifyContent="space-between">
                    <div style={{ width: "47%" }}>
                      <Field
                        disabled="true"
                        selectType="currency"
                        name="opportunityCurrency"
                        // label="Opportunity Curr."
                        label={<FormattedMessage
                          id="app.opportunitycurrency"
                          defaultMessage="Opportunity Curr."
                        />}
                        isColumn
                        width={"100%"}
                        inlineLabel
                        value={this.props.opportunity.currency}
                        component={InputComponent}
                        style={{
                          flexBasis: "80%",
                          height: "2em",
                          marginTop: "0em",
                        }}
                      />
                    </div>
                    <div style={{ width: "47%" }}>
                      <Field
                        disabled="true"
                        name="serviceCurrency"
                        // label="Curr"
                        label={<FormattedMessage
                          id="app.curr"
                          defaultMessage="Curr"
                        />}
                        width={"100%"}
                        isColumn
                        component={InputComponent}
                        inlineLabel
                        style={{ flexBasis: "80%", marginTop: "0em" }}
                      />
                    </div>
                  </FlexContainer>
                  <Spacer />
                  <FlexContainer justifyContent="space-between">
                    <div style={{ width: "47%" }}>
                      <Field
                        isRequired
                        name="rate"
                        // label="Rate"
                        label={<FormattedMessage
                          id="app.rate"
                          defaultMessage="Rate"
                        />}
                        component={InputComponent}
                        inlineLabel
                        isColumn
                        width={"100%"}
                        style={{
                          flexBasis: "80%",
                          marginTop: "0em",
                        }}
                      />
                    </div>
                    <div style={{ width: "16%" }}>
                      <Field
                        isRequired
                        name="noOfUnit"
                        // label="How many?"
                        label={<FormattedMessage
                          id="app.howmany?"
                          defaultMessage="How many?"
                        />}
                        component={InputComponent}
                        inlineLabel
                        isColumn
                        width={"100%"}
                        style={{
                          flexBasis: "80%",
                          marginTop: "0em",
                        }}
                      />
                    </div>
                    <div style={{ width: "25%" }}>
                      <Field
                        disabled="true"
                        name="unit"
                        // label="Unit"
                        label={<FormattedMessage
                          id="app.unit"
                          defaultMessage="Unit"
                        />}
                        component={InputComponent}
                        inlineLabel
                        isColumn
                        width={"100%"}
                        style={{
                          flexBasis: "80%",
                          marginTop: "0em",
                        }}
                      />
                    </div>
                  </FlexContainer>
                </div>
              </div>
              <Spacer />
              <FlexContainer justifyContent="flex-end">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={linkingServiceToOpportunity}
                >
                  {/* Create */}
                  <FormattedMessage
                    id="app.create"
                    defaultMessage="Create"
                  />
                </Button>
              </FlexContainer>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

const mapStateToProps = ({ auth, product, opportunity, account }) => ({
  addingService: product.addingService,
  addingServiceError: product.addingServiceError,
  addServiceModal: product.addServiceModal,
  user: auth.userDetails,
  opportunity: opportunity.opportunity,
  serviceId: account.clearbitService.serviceId,
  linkingServiceToOpportunity: opportunity.linkingServiceToOpportunity,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setClearbitServiceData,
      linkServiceToOpportunity,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ServiceForm);

// import React, { Component } from "react";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import { Button } from "antd";
// import { Formik, Form, Field, FieldArray } from "formik";
// import * as Yup from "yup";
// import { Spacer } from "../../../../../Components/UI/Elements";
// import SearchSelect from "../../../../../Components/Forms/Formik/SearchSelect";
// import { InputComponent } from "../../../../../Components/Forms/Formik/InputComponent";
// import { } from "../../../OpportunityAction";
// import { DatePicker } from "../../../../../Components/Forms/Formik/DatePicker";

// /**
//  * yup validation scheme for creating a product
//  */
// // const LinkProductSchema = Yup.object().shape({
// //     productName: Yup.string()
// //         .required('Please provide Product name'),
// // });

// class LinkServiceForm extends Component {
//     handleCallback = () => {
//         const { } = this.props;
//     };
//     render() {
//         const { } = this.props;
//         return (
//             <>
//                 <Formik
//                     enableReinitialize
//                     initialValues={{
//                         totalValue: '',
//                         services: [
//                             {
//                                 consultant: '',
//                                 billingRate: '',
//                                 commission: '',
//                                 startDate: '',
//                                 endDate: '',
//                                 activities: [
//                                     {
//                                         activity: '',
//                                         hour: ''
//                                     }
//                                 ]
//                             },
//                         ]
//                     }}
//                     onSubmit={(values, { resetForm }) => {
//                         console.log(values);
//                     }}
//                 >
//                     {({
//                         errors,
//                         touched,
//                         isSubmitting,
//                         setFieldValue,
//                         setFieldTouched,
//                         values,
//                         ...rest
//                     }) => (
//                             <Form>
//                                 <FieldArray
//                                     name="services"
//                                     render={arrayHelpers => (
//                                         <div>
//                                             {values.services && values.services.length &&
//                                                 values.services.map((service, index) => (
//                                                     <div key={index}>
//                                                         <Field
//                                                             name={`service.consultant`}
//                                                             component={InputComponent}
//                                                             placeholder='Consultant'
//                                                         />
//                                                         <Field
//                                                             name={`service.billingRate`}
//                                                             component={InputComponent}
//                                                             placeholder='Billing rate'
//                                                         />
//                                                         <Field
//                                                             name={`service.commission`}
//                                                             component={InputComponent}
//                                                             placeholder='Commission'
//                                                         />
//                                                         <Field
//                                                             name={`service.startDate`}
//                                                             component={DatePicker}
//                                                             placeholder='Start date'
//                                                         />
//                                                         <Field
//                                                             name={`service.endDate`}
//                                                             component={DatePicker}
//                                                             placeholder='End date'
//                                                         />
//                                                         <FieldArray
//                                                             name={`services[${index}].activity`}
//                                                             render={arrayHelpers => (
//                                                                 <div>
//                                                                     {values.services && values.services.length &&
//                                                                         values.services.map((service, i) => (
//                                                                             <>
//                                                                                 <Field
//                                                                                     name={`services[${index}].activity[${i}].activity`}
//                                                                                     placeholder='Activity'
//                                                                                 />
//                                                                                 <Field
//                                                                                     name={`services[${index}].activity[${i}].hour`}
//                                                                                     placeholder='Hour'
//                                                                                 />
//                                                                             </>
//                                                                         ))}
//                                                                 </div>
//                                                             )}
//                                                         />

//                                                         &nbsp;<Button type="danger" onClick={() => arrayHelpers.remove(index)} > - </Button>
//                                                         <Button type="primary" onClick={() => arrayHelpers.push({
//                                                             consultant1: '',
//                                                             billingRate: '',
//                                                             commission: '',
//                                                             startDate: '',
//                                                             endDate: '',
//                                                             activities: [
//                                                                 {
//                                                                     activity: '',
//                                                                     hour: ''
//                                                                 }
//                                                             ]
//                                                         })} > + </Button>
//                                                     </div>
//                                                 ))}
//                                         </div>
//                                     )}
//                                 />
//                                 {/* <FieldArray
//                                     name={`activity`}
//                                     render={arrayHelpers => (
//                                         <div>
//                                             {values.activity && values.activity.length > 0 ? (
//                                                 values.activity.map((activity, index) => (
//                                                     <div key={index}>
//                                                         <Field name={`activity.${index}`} />
//                                                         <Button type="primary" onClick={() => arrayHelpers.remove(index)} > - </Button>
//                                                         <Button type="button" onClick={() => arrayHelpers.insert(index, '')} > + </Button>
//                                                     </div>
//                                                 ))
//                                             ) : (
//                                                     <Button type="button" onClick={() => arrayHelpers.push('')}> Add</Button>
//                                                 )}
//                                         </div>
//                                     )}
//                                 /> */}
//                                 <div style={{ width: 500 }}>
//                                     <Button type="primary" htmlType="submit" loading={false}> Create </Button>
//                                 </div>
//                             </Form>
//                         )}
//                 </Formik>
//             </>
//         );
//     }
// }

// const mapStateToProps = ({ auth, product, opportunity }) => ({
//     user: auth.userDetails,
//     opportunity: opportunity.opportunity
// });

// const mapDispatchToProps = dispatch => bindActionCreators({
// }, dispatch);

// export default connect(mapStateToProps, mapDispatchToProps)(LinkServiceForm);
