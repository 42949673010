import React, { Component } from "react";
import connectionLoss from "../../Assets/Images/connectionLost.png";
import { Button } from "antd";
import { Spacer } from "../../Components/UI/Elements";

class ConnectionLoss extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "70.31em 1.25em",
        }}
      >
        <div
          style={{
            width: "400px",
            height: "400px",
            padding: "0.31em 1.25em",
            /* border-radius: "50%"; */
            boxShadow: " 0 0.81em 1.68em -0.31em rgba(50, 50, 93, 0.25)",
            backgroundColor: " #f5f5f5",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // margin-bottom: 0.62em;
            // margin-top: 0.62em;
          }}
        >
          <img
            src={connectionLoss}
            style={{ height: "9.37em", width: "9.37em", borderRadius: "50%" }}
          ></img>

          <h3 style={{ color: "black" }}>
            You're offline right now. Check your connection.
          </h3>
        </div>
      </div>
    );
  }
}

export default ConnectionLoss;
// 21baba
