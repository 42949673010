export const SET_QUOTE_VIEW_TYPE = "SET_QUOTE_VIEW_TYPE";

export const DATA_CLEAR = "DATA_CLEAR";

export const MOBILE_SEARCH_DATA_REQUSET = "MOBILE_SEARCH_DATA_REQUEST";
export const MOBILE_SEARCH_DATA_SUCCESS = "MOBILE_SEARCH_DATA_SUCCESS";
export const MOBILE_SEARCH_DATA_FAILURE = "MOBILE_SEARCH_DATA_FAILURE";

export const GET_DATA_OR_NOT = "GET_DATA_OR_NOT";

export const ADD_CONTACT_FOR_QUOTE_REQUEST = "ADD_CONTACT_FOR_QUOTE_REQUEST";
export const ADD_CONTACT_FOR_QUOTE_SUCCESS = "ADD_CONTACT_FOR_QUOTE_SUCCESS";
export const ADD_CONTACT_FOR_QUOTE_FAILURE = "ADD_CONTACT_FOR_QUOTE_FAILURE";

export const GET_EXISTING_QUOTE_REQUEST = "GET_EXISTING_QUOTE_REQUEST";
export const GET_EXISTING_QUOTE_SUCCESS = "GET_EXISTING_QUOTE_SUCCESS";
export const GET_EXISTING_QUOTE_FAILURE = "GET_EXISTING_QUOTE_FAILURE";

export const CANCEL_QUOTE_REQUEST = "CANCEL_QUOTE_REQUEST";
export const CANCEL_QUOTE_SUCCESS = "CANCEL_QUOTE_SUCCESS";
export const CANCEL_QUOTE_FAILURE = "CANCEL_QUOTE_FAILURE";

export const GET_QUOTES_LAZY_LOADING_REQUEST =
  "GET_QUOTES_LAZY_LOADING_REQUEST";
export const GET_QUOTES_LAZY_LOADING_SUCCESS =
  "GET_QUOTES_LAZY_LOADING_SUCCESS";
export const GET_QUOTES_LAZY_LOADING_FAILURE =
  "GET_QUOTES_LAZY_LOADING_FAILURE";

export const GET_QUOTES_OPEN_REQUEST = "GET_QUOES_OPEN_REQUEST";
export const GET_QUOTES_OPEN_SUCCESS = "GET_QUOES_OPEN_SUCCESS";
export const GET_QUOTES_OPEN_FAILURE = "GET_QUOES_OPEN_FAILURE";

export const GET_QUOTES_CLOSE_REQUEST = "GET_QUOTES_CLOSE_REQUEST";
export const GET_QUOTES_CLOSE_SUCCESS = "GET_QUOTES_CLOSE_SUCCESS";
export const GET_QUOTES_CLOSE_FAILURE = "GET_QUOTES_CLOSE_FAILURE";

export const GET_QUOTES_AVERAGE_TIME_REQUEST =
  "GET_QUOTES_AVERAGE_TIME_REQUEST";
export const GET_QUOTES_AVERAGE_TIME_SUCCESS =
  "GET_QUOTES_AVERAGE_TIME_SUCCESS";
export const GET_QUOTES_AVERAGE_TIME_FAILURE =
  "GET_QUOTES_AVERAGE_TIME_FAILURE";
