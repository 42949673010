import * as types from "./DashBoardActionTypes";
import moment from "moment";

const initialState = {
  addWeightedModal: false,
  addWinModal: false,
  addWonModal: false,
  addCustomerModal: false,
  addAbsoluteModal: false,
  addChartModal: false,
  addFunnelModal: false,
  addStageModal: false,
  addIndustryModal: false,
  addExistingAccountsModal: false,
  addContactsModal: false,
  dashboardType: "ME",
  dateRangeList: [
    {
      id: 1,
      type: "year",
      value: "FY",
      starter: true,
      isSelected: true,
      startDate: moment()
        .startOf("year")
        .toISOString(),
      endDate: moment()
        .endOf("year")
        .toISOString(),
    },
    {
      id: 2,
      type: "quarter",
      value: "QTD",
      starter: false,
      isSelected: false,
      startDate: moment()
        .startOf("quarter")
        .toISOString(),
      endDate: moment()
        .endOf("quarter")
        .toISOString(),
    },
    {
      id: 3,
      type: "month",
      value: "MTD",
      starter: false,
      isSelected: false,
      startDate: moment()
        .startOf("month")
        .toISOString(),
      endDate: moment()
        .endOf("month")
        .toISOString(),
    },
    {
      id: 4,
      type: "week",
      value: "1W",
      starter: false,
      isSelected: false,
      startDate: moment()
        .startOf("week")
        .toISOString(),
      endDate: moment()
        .endOf("week")
        .toISOString(),
    },
  ],
  todoDrawerVisible: false,
  timeRangeType: "year",
  isCustomSelected: false,
  startDate: moment()
    .startOf("year")
    .toISOString(),
  endDate: moment()
    .endOf("year")
    .toISOString(),

  fetchingOpportunityRelatedData: false,
  opportunityRelatedData: [],
  fetchingOpportunityRelatedDataError: false,

  fetchingOpportunityRelatedDataOfAllUsers: false,
  opportunityRelatedDataOfAllUsers: [],
  fetchingOpportunityRelatedDataOfAllUsersError: false,

  fetchingOpportunityRelatedDataWithProgress: false,
  opportunityRelatedDataWithProgress: [],
  fetchingOpportunityRelatedDataWithProgressError: false,

  fetchingOpportunityRelatedDataWithProgressForDashboard: false,
  opportunityRelatedDataWithProgressForDashboard: [],
  fetchingOpportunityRelatedDataWithProgressForDashboardError: false,

  fetchingOpportunityRelatedDataWithProgressOfAllUsers: false,
  opportunityRelatedDataWithProgressOfAllUsers: [],
  fetchingOpportunityRelatedDataWithProgressOfAllUsersError: false,

  fetchingOpportunityRelatedDataWithProgressOfAllUsersOrg: false,
  opportunityRelatedDataWithProgressOfAllUsersOrg: [],
  fetchingOpportunityRelatedDataWithProgressOfAllUsersOrgError: false,

  fetchingAccountDataForChart: false,
  accountDataForChart: [],
  fetchingAccountDataForChartError: false,

  fetchingAccountDataForChartOfAllUsers: false,
  accountDataForChartOfAllUsers: [],
  fetchingAccountDataForChartOfAllUsersError: false,

  fetchingDashboardFunnel: false,
  dashboardFunnel: {},
  fetchingDashboardFunnelError: false,

  fetchingDashboardFunnelForQuartor: false,
  fetchingDashboardFunnelForQuartorError: false,

  fetchingDashboardFunnelOfAllUsers: false,
  dashboardFunnelOfAllUsers: {},
  fetchingDashboardFunnelOfAllUsersError: false,

  fetchingDashboardFunnelOfAllUsersForQuartor: false,
  fetchingDashboardFunnelOfAllUsersForQuartorError: false,

  fetchingTodos: false,
  fetchingTodosError: false,
  todos: [],
  updatingCall: false,
  updatingCallError: false,
  updatingTodoEvent: false,
  updatingTodoEventError: false,

  fetchingTodosPrevious: false,
  fetchingTodosPreviousError: false,
  todosPrevious: [],

  fetchingTodosUpcoming: false,
  fetchingTodosUpcomingError: false,
  todosUpcoming: [],

  //account data for pareto

  fetchingParetoChartByOrgId: false,
  fetchingParetoChartByOrgIdError: false,
  paretoChartData: {},
  fetchingParetoChartByUserId: false,
  fetchingParetoChartByUserIdError: false,
  paretoChartDataForUser: {},
};
const newDateRange = (dateRange, newDate) =>
  dateRange.map((range) => {
    if (range.id === newDate.id) {
      return { ...range, isSelected: true };
    } else {
      return { ...range, isSelected: false };
    }
  });

const mergeFiscalAndQuarter = (dateRange, newDate) => {
  return dateRange.map((date) => {
    // let q1s = newDate.metaData.fiscalMapper.q1StartDate;
    // let q1e = newDate.metaData.fiscalMapper.q1EndDate;
    // let q2s = newDate.metaData.fiscalMapper.q2StartDate;
    // let q2e = newDate.metaData.fiscalMapper.q2EndDate;
    // let q3s = newDate.metaData.fiscalMapper.q3StartDate;
    // let q3e = newDate.metaData.fiscalMapper.q3EndDate;
    // let q4s = newDate.metaData.fiscalMapper.q4StartDate;
    // let q4e = newDate.metaData.fiscalMapper.q4EndDate;

    if (date.value === "QTD") {
      return {
        ...date,
        startDate:
          newDate.metaData.fiscalMapper.metaData.currentQuarterStartDate +
          "T00:00:00Z",
        endDate:
          newDate.metaData.fiscalMapper.metaData.currentQuarterEndDate +
          "T00:00:00Z",
      };
      // if (moment().isBetween(moment(q1s), moment(q1e))) {
      //   return { ...date, startDate: q1s, endDate: q1e };
      // }
      // if (moment().isBetween(moment(q2s), moment(q2e))) {
      //   return { ...date, startDate: q2s, endDate: q2e };
      // }
      // if (moment().isBetween(moment(q3s), moment(q3e))) {
      //   return { ...date, startDate: q3s, endDate: q3e };
      // }
      // if (moment().isBetween(moment(q4s), moment(q4e))) {
      //   return { ...date, startDate: q4s, endDate: q4e };
      // }
    } else if (date.value === "FY") {
      return {
        ...date,
        startDate: newDate.metaData.fiscalMapper.fiscalStartDate,
        endDate: newDate.metaData.fiscalMapper.fiscalEndDate,
      };
    } else {
      return date;
    }
  });
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_OPPORTUNITY_RELATED_DATA_WITH_PROGRESS_FOR_DASHBOARD_REQUEST:
      return {
        ...state,
        fetchingOpportunityRelatedDataWithProgressForDashboard: true,
        fetchingOpportunityRelatedDataWithProgressForDashboardError: false,
      };
    case types.GET_OPPORTUNITY_RELATED_DATA_WITH_PROGRESS_FOR_DASHBOARD_SUCCESS:
      return {
        ...state,
        fetchingOpportunityRelatedDataWithProgressForDashboard: false,
        fetchingOpportunityRelatedDataWithProgressForDashboardError: false,
        opportunityRelatedDataWithProgressForDashboard: action.payload,
      };
    case types.GET_OPPORTUNITY_RELATED_DATA_WITH_PROGRESS_FOR_DASHBOARD_FAILURE:
      return {
        ...state,
        fetchingOpportunityRelatedDataWithProgressForDashboard: false,
        fetchingOpportunityRelatedDataWithProgressForDashboardError: true,
      };
    case types.SET_DASHBOARD_TYPE:
      return { ...state, dashboardType: action.payload };
    case types.CHANGE_SELECTED_TIME_INTERVAL:
      return {
        ...state,
        dateRangeList: newDateRange(state.dateRangeList, action.payload),
        isCustomSelected: false,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        timeRangeType: action.payload.type,
      };
    case types.SET_TIME_INTERVAL:
      return {
        ...state,
        // dateRangeList: newDateRangeHighlight(
        //   state.dateRangeList,
        //   action.payload
        // ),
        isCustomSelected: true,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    case types.SET_FISCAL_TIME_INTERVAL:
      return {
        ...state,
        dateRangeList: mergeFiscalAndQuarter(
          state.dateRangeList,
          action.payload
        ),
        startDate: action.payload.metaData.fiscalMapper.fiscalStartDate,
        endDate: action.payload.metaData.fiscalMapper.fiscalEndDate,
      };
    case types.GET_OPPORTUNITY_RELATED_DATA_REQUEST:
      return {
        ...state,
        fetchingOpportunityRelatedData: true,
        fetchingOpportunityRelatedDataError: false,
      };
    case types.GET_OPPORTUNITY_RELATED_DATA_SUCCESS:
      return {
        ...state,
        fetchingOpportunityRelatedData: false,
        fetchingOpportunityRelatedDataError: false,
        opportunityRelatedData: action.payload,
      };
    case types.GET_OPPORTUNITY_RELATED_DATA_FAILURE:
      return {
        ...state,
        fetchingOpportunityRelatedData: false,
        fetchingOpportunityRelatedDataError: true,
      };

    case types.GET_OPPORTUNITY_RELATED_DATA_OF_ALL_USERS_REQUEST:
      return {
        ...state,
        fetchingOpportunityRelatedDataOfAllUsers: true,
        fetchingOpportunityRelatedDataOfAllUsersError: false,
      };
    case types.GET_OPPORTUNITY_RELATED_DATA_OF_ALL_USERS_SUCCESS:
      return {
        ...state,
        fetchingOpportunityRelatedDataOfAllUsers: false,
        fetchingOpportunityRelatedDataOfAllUsersError: false,
        opportunityRelatedDataOfAllUsers: action.payload,
      };
    case types.GET_OPPORTUNITY_RELATED_DATA_OF_ALL_USERS_FAILURE:
      return {
        ...state,
        fetchingOpportunityRelatedDataOfAllUsers: false,
        fetchingOpportunityRelatedDataOfAllUsersError: true,
      };

    case types.GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_REQUEST:
      return {
        ...state,
        fetchingOpportunityRelatedDataWithProgress: true,
        fetchingOpportunityRelatedDataWithProgressError: false,
      };
    case types.GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_SUCCESS:
      return {
        ...state,
        fetchingOpportunityRelatedDataWithProgress: false,
        fetchingOpportunityRelatedDataWithProgressError: false,
        opportunityRelatedDataWithProgress: action.payload,
      };
    case types.GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_FAILURE:
      return {
        ...state,
        fetchingOpportunityRelatedDataWithProgress: false,
        fetchingOpportunityRelatedDataWithProgressError: true,
      };

    case types.GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_OF_ALL_USERS_REQUEST:
      return {
        ...state,
        fetchingOpportunityRelatedDataWithProgressOfAllUsers: true,
        fetchingOpportunityRelatedDataWithProgressOfAllUsersError: false,
      };
    case types.GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_OF_ALL_USERS_SUCCESS:
      return {
        ...state,
        fetchingOpportunityRelatedDataWithProgressOfAllUsers: false,
        fetchingOpportunityRelatedDataWithProgressOfAllUsersError: false,
        opportunityRelatedDataWithProgressOfAllUsers: action.payload,
      };
    case types.GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_OF_ALL_USERS_FAILURE:
      return {
        ...state,
        fetchingOpportunityRelatedDataWithProgressOfAllUsers: false,
        fetchingOpportunityRelatedDataWithProgressOfAllUsersError: true,
      };

    case types.GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_OF_ALL_USERS_ORG_REQUEST:
      return {
        ...state,
        fetchingOpportunityRelatedDataWithProgressOfAllUsersOrg: true,
        fetchingOpportunityRelatedDataWithProgressOfAllUsersOrgError: false,
      };
    case types.GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_OF_ALL_USERS_ORG_SUCCESS:
      return {
        ...state,
        fetchingOpportunityRelatedDataWithProgressOfAllUsersOrg: false,
        fetchingOpportunityRelatedDataWithProgressOfAllUsersOrgError: false,
        opportunityRelatedDataWithProgressOfAllUsersOrg: action.payload,
      };
    case types.GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_OF_ALL_USERS_ORG_FAILURE:
      return {
        ...state,
        fetchingOpportunityRelatedDataWithProgressOfAllUsers: false,
        fetchingOpportunityRelatedDataWithProgressOfAllUsersError: true,
      };

    case types.GET_ACCOUNT_DATA_FOR_CHARTS_REQUEST:
      return {
        ...state,
        fetchingAccountDataForChart: true,
        fetchingAccountDataForChartError: false,
      };
    case types.GET_ACCOUNT_DATA_FOR_CHARTS_SUCCESS:
      return {
        ...state,
        fetchingAccountDataForChart: false,
        fetchingAccountDataForChartError: false,
        accountDataForChart: action.payload,
      };
    case types.GET_ACCOUNT_DATA_FOR_CHARTS_FAILURE:
      return {
        ...state,
        fetchingAccountDataForChart: false,
        fetchingAccountDataForChartError: true,
      };

    case types.GET_ACCOUNT_DATA_FOR_CHARTS_OF_ALL_USERS_REQUEST:
      return {
        ...state,
        fetchingAccountDataForChartOfAllUsers: true,
        fetchingAccountDataForChartOfAllUsersError: false,
      };
    case types.GET_ACCOUNT_DATA_FOR_CHARTS_OF_ALL_USERS_SUCCESS:
      return {
        ...state,
        fetchingAccountDataForChartOfAllUsers: false,
        fetchingAccountDataForChartOfAllUsersError: false,
        accountDataForChartOfAllUsers: action.payload,
      };
    case types.GET_ACCOUNT_DATA_FOR_CHARTS_OF_ALL_USERS_FAILURE:
      return {
        ...state,
        fetchingAccountDataForChartOfAllUsers: false,
        fetchingAccountDataForChartOfAllUsersError: true,
      };

    case types.GET_DASHBOARD_FUNNEL_REQUEST:
      return {
        ...state,
        fetchingDashboardFunnel: true,
        fetchingDashboardFunnelError: false,
      };
    case types.GET_DASHBOARD_FUNNEL_SUCCESS:
      return {
        ...state,
        fetchingDashboardFunnel: false,
        fetchingDashboardFunnelError: false,
        dashboardFunnel: action.payload,
      };
    case types.GET_DASHBOARD_FUNNEL_FAILURE:
      return {
        ...state,
        fetchingDashboardFunnel: false,
        fetchingDashboardFunnelError: true,
      };

    case types.GET_DASHBOARD_FOR_QUARTER_FUNNEL_REQUEST:
      return {
        ...state,
        fetchingDashboardFunnelForQuartor: true,
        fetchingDashboardFunnelForQuartorError: false,
      };
    case types.GET_DASHBOARD_FOR_QUARTOR_FUNNEL_SUCCESS:
      return {
        ...state,
        fetchingDashboardFunnelForQuartor: false,
        fetchingDashboardFunnelForQuartorError: false,
        dashboardFunnel: action.payload,
      };
    case types.GET_DASHBOARD_FOR_QUARTOR_FUNNEL_FAILURE:
      return {
        ...state,
        fetchingDashboardFunnelForQuartor: false,
        fetchingDashboardFunnelForQuartorError: true,
      };

    case types.GET_DASHBOARD_FUNNEL_OF_ALL_USERS_REQUEST:
      return {
        ...state,
        fetchingDashboardFunnelOfAllUsers: true,
        fetchingDashboardFunnelOfAllUsersError: false,
      };
    case types.GET_DASHBOARD_FUNNEL_OF_ALL_USERS_SUCCESS:
      return {
        ...state,
        fetchingDashboardFunnelOfAllUsers: false,
        fetchingDashboardFunnelOfAllUsersError: false,
        dashboardFunnelOfAllUsers: action.payload,
      };
    case types.GET_DASHBOARD_FUNNEL_OF_ALL_USERS_FAILURE:
      return {
        ...state,
        fetchingDashboardFunnelOfAllUsers: false,
        fetchingDashboardFunnelOfAllUsersError: true,
      };

    case types.GET_DASHBOARD_FUNNEL_OF_ALL_USERS_FOR_QUARTOR_REQUEST:
      return {
        ...state,
        fetchingDashboardFunnelOfAllUsersForQuartor: true,
        fetchingDashboardFunnelOfAllUsersForQuartorError: false,
      };
    case types.GET_DASHBOARD_FUNNEL_OF_ALL_USERS_FOR_QUARTOR_SUCCESS:
      return {
        ...state,
        fetchingDashboardFunnelOfAllUsersForQuartor: false,
        fetchingDashboardFunnelOfAllUsersForQuartorError: false,
        dashboardFunnelOfAllUsers: action.payload,
      };
    case types.GET_DASHBOARD_FUNNEL_OF_ALL_USERS_FOR_QUARTOR_FAILURE:
      return {
        ...state,
        fetchingDashboardFunnelOfAllUsersForQuartor: false,
        fetchingDashboardFunnelOfAllUsersForQuartorError: true,
      };

    case types.GET_TODOS_REQUEST:
      return { ...state, fetchingTodos: true, fetchingTodosError: false };
    case types.GET_TODOS_SUCCESS:
      return {
        ...state,
        fetchingTodos: false,
        fetchingTodosError: false,
        todos: action.payload,
      };
    case types.GET_TODOS_FAILURE:
      return { ...state, fetchingTodos: false, fetchingTodosError: true };

    case types.GET_TODOS_PREVIOUS_REQUEST:
      return {
        ...state,
        fetchingTodosPrevious: true,
        fetchingTodosPreviousError: false,
      };
    case types.GET_TODOS_PREVIOUS_SUCCESS:
      return {
        ...state,
        fetchingTodosPrevious: false,
        fetchingTodosPreviousError: false,
        todosPrevious: action.payload,
      };
    case types.GET_TODOS_PREVIOUS_FAILURE:
      return {
        ...state,
        fetchingTodosPrevious: false,
        fetchingTodosPreviousError: true,
      };

    case types.GET_TODOS_UPCOMING_REQUEST:
      return {
        ...state,
        fetchingTodosUpcoming: true,
        fetchingTodosUpcomingError: false,
      };
    case types.GET_TODOS_UPCOMING_SUCCESS:
      return {
        ...state,
        fetchingTodosUpcoming: false,
        fetchingTodosUpcomingError: false,
        todosUpcoming: action.payload,
      };
    case types.GET_TODOS_UPCOMING_FAILURE:
      return {
        ...state,
        fetchingTodosUpcoming: false,
        fetchingTodosUpcomingError: true,
      };

    case types.TOGGLE_TODO_DRAWER:
      return { ...state, todoDrawerVisible: action.payload.visible };

    case types.UPDATE_TODOEVENT_SUCCESS:
      console.log(action.payload);
      let events = action.payload;
      // function updateEvent(todos, events) {
      let updateTodos = state.todos.map((item, i) => {
        if (item.id === events.eventId) {
          ////debugger
          return events;
        } else {
          ////debugger

          return item;
        }
      });

      // }
      return {
        ...state,
        todos: updateTodos,
      };

    case types.HANDLE_WEIGHTED_MODAL:
      return { ...state, addWeightedModal: action.payload };

    case types.HANDLE_WIN_MODAL:
      return { ...state, addWinModal: action.payload };

    case types.HANDLE_WON_MODAL:
      return { ...state, addWonModal: action.payload };

    case types.HANDLE_CUSTOMER_MODAL:
      return { ...state, addCustomerModal: action.payload };

    case types.HANDLE_ABSOLUTE_MODAL:
      return { ...state, addAbsoluteModal: action.payload };

    case types.HANDLE_CHART_MODAL:
      return { ...state, addChartModal: action.payload };

    case types.HANDLE_FUNNEL_MODAL:
      return { ...state, addFunnelModal: action.payload };

    case types.HANDLE_STAGE_MODAL:
      return { ...state, addStageModal: action.payload };

    case types.HANDLE_CONTACTS_MODAL:
      return { ...state, addContactsModal: action.payload };

    case types.HANDLE_INDUSTRY_MODAL:
      return { ...state, addIndustryModal: action.payload };

    case types.HANDLE_EXISTINGACCOUNTS_MODAL:
      return { ...state, addExistingAccountsModal: action.payload };

    case types.UPDATE_TODO_CALL_BY_ID_REQUEST:
      return { ...state, updatingTodoCall: true };
    case types.UPDATE_TODO_CALL_BY_ID_SUCCESS:
      return {
        ...state,
        updatingTodoCall: false,
        todos: state.todos.map((item, i) => {
          ////debugger;
          if (item.id === action.payload.id) {
            ////debugger;
            return action.payload;
          } else {
            ////debugger;
            return item;
          }
        }),
      };
    case types.UPDATE_TODO_CALL_BY_ID_FAILURE:
      return { ...state, updatingCall: false, updatingCallError: false };

    case types.UPDATE_TODO_EVENT_BY_ID_REQUEST:
      return { ...state, updatingTodoEvent: true };
    case types.UPDATE_TODO_EVENT_BY_ID_SUCCESS:
      return {
        ...state,
        updatingTodoEvent: false,
        todos: state.todos.map((item, i) => {
          ////debugger;
          if (item.id === action.payload.id) {
            ////debugger;
            return action.payload;
          } else {
            ////debugger;
            return item;
          }
        }),
      };
    case types.UPDATE_TODO_EVENT_BY_ID_FAILURE:
      return {
        ...state,
        updatingTodoEvent: false,
        updatingTodoEventError: false,
      };

    case types.GET_ACCOUNT_DATA_FOR_PARETO_CHART_BY_ORG_ID_REQUEST:
      return {
        ...state,
        fetchingParetoChartByOrgId: true,
        fetchingParetoChartByOrgIdError: false,
      };
    case types.GET_ACCOUNT_DATA_FOR_PARETO_CHART_BY_ORG_ID_SUCCESS:
      return {
        ...state,
        fetchingParetoChartByOrgId: false,
        fetchingParetoChartByOrgIdError: false,
        paretoChartData: action.payload,
      };
    case types.GET_ACCOUNT_DATA_FOR_PARETO_CHART_BY_ORG_ID_FAILURE:
      return {
        ...state,
        fetchingParetoChartByOrgId: false,
        fetchingParetoChartByOrgIdError: true,
      };

    case types.GET_ACCOUNT_DATA_FOR_PARETO_CHART_BY_USER_ID_REQUEST:
      return {
        ...state,
        fetchingParetoChartByUserId: true,
        fetchingParetoChartByUserIdError: false,
      };
    case types.GET_ACCOUNT_DATA_FOR_PARETO_CHART_BY_USER_ID_SUCCESS:
      return {
        ...state,
        fetchingParetoChartByUserId: false,
        fetchingParetoChartByUserIdError: false,
        paretoChartDataForUser: action.payload,
      };
    case types.GET_ACCOUNT_DATA_FOR_PARETO_CHART_BY_USER_ID_FAILURE:
      return {
        ...state,
        fetchingParetoChartByUserId: false,
        fetchingParetoChartByUserIdrror: true,
      };

    default:
      return state;
  }
};
