export const GET_TEMPLATE_REQUEST = "GET_TEMPLATE_REQUEST";
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";
export const GET_TEMPLATE_FAILURE = "GET_TEMPLATE_FAILURE";

export const GET_NOTIFICATION_TEMPLATE_REQUEST =
  "GET_NOTIFICATION_TEMPLATE_REQUEST";
export const GET_NOTIFICATION_TEMPLATE_SUCCESS =
  "GET_NOTIFICATION_TEMPLATE_SUCCESS";
export const GET_NOTIFICATION_TEMPLATE_FAILURE =
  "GET_NOTIFICATION_TEMPLATE_FAILURE";

//INVOICE For Email
export const ADD_INVOICE_FOR_EMAIL_REQUEST = "ADD_INVOICE_FOR_EMAIL_REQUEST";
export const ADD_INVOICE_FOR_EMAIL_SUCCESS = "ADD_INVOICE_FOR_EMAIL_SUCCESS";
export const ADD_INVOICE_FOR_EMAIL_FAILURE = "ADD_INVOICE_FOR_EMAIL_FAILURE";

export const GET_INVOICE_FOR_EMAIL_REQUEST = "GET_INVOICE_FOR_EMAIL_REQUEST";
export const GET_INVOICE_FOR_EMAIL_SUCCESS = "GET_INVOICE_FOR_EMAIL_SUCCESS";
export const GET_INVOICE_FOR_EMAIL_FAILURE = "GET_INVOICE_FOR_EMAIL_FAILURE";

export const GET_SEQUENCE_LEADS_REQUEST = "GET_SEQUENCE_LEADS_REQUEST";
export const GET_SEQUENCE_LEADS_SUCCESS = "GET_SEQUENCE_LEADS_SUCCESS";
export const GET_SEQUENCE_LEADS_FAILURE = "GET_SEQUENCE_LEADS_FAILURE";