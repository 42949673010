





// production
//OLD 
// const base_url = "https://appbackend.tekorero.com/api/v2.0";
// const login_url = "https://appbackend.tekorero.com";
//NEW
const base_url = "https://prod.tekorero.com/korero/api/v2.0";
const login_url = "https://prod.tekorero.com/korero";



//Demo url
// const base_url = "https://develop.tekorero.com/korero/api/v2.0";
// const login_url = "https://develop.tekorero.com/korero";


export { base_url, login_url };

// master:productionc
// alpha-demo
// dev-Test
//develop-my branch
