import React, { Component } from "react";
import AsyncSelect from "react-select/lib/Async";
import axios from "axios";
import { get } from "lodash";
import { base_url, login_url } from "../../../Config/Auth";
import { ValidationError, StyledLabel, StyledAsync } from "../../UI/Elements";
import { FlexContainer } from "../../UI/Layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { message } from "antd";
import { setClearbitServiceData } from "../../../Containers/Account/AccountAction";

class ServiceClearbit extends Component {
  loadOptions = (value) => {
    if (!value) {
      return Promise.resolve([]);
    }
    const url = `${base_url}/serviceName/${value}`;
    return axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      })
      .then((res) => {
        return res.data.map((opt) => ({
          label: opt.name,
          role: opt.role,
          region: opt.region,
          maxRate: opt.maxRate,
          minRate: opt.minRate,
          serviceCurrency: opt.currency,
          unit: opt.unit,
          serviceId: opt.serviceId,
        }));
      })
      .catch((err) => console.log(err));
  };
  // renderOptions = ({ data }) => (<div><p>----{data.website}</p></div>);
  handleInputChange = (e) => e;

  handleOnChange = (option) => {
    this.props.form.setFieldValue("label", option.label);
    this.props.form.setFieldValue("role", option.role);
    this.props.form.setFieldValue("region", option.region);
    this.props.form.setFieldValue("maxRate", option.maxRate);
    this.props.form.setFieldValue("minRate", option.minRate);
    this.props.form.setFieldValue("serviceCurrency", option.serviceCurrency);
    this.props.form.setFieldValue("unit", option.unit);

    this.props.setClearbitServiceData(option);
  };

  render() {
    const {
      label,
      placeholder,
      isRequired,
      inlineLabel,
      isColumn,
      form: { touched, errors, setFieldValue, setFieldTouched },
      field,
      ...rest
    } = this.props;
    if (isColumn) {
      return (
        <>
          <StyledLabel style={{ flexBasis: "20%" }}>{label}</StyledLabel>
          <StyledAsync
            isRequired={isRequired}
            classNamePrefix="sales"
            placeholder={placeholder}
            cacheOptions
            loadOptions={this.loadOptions}
            defaultOptions
            onInputChange={this.handleInputChange}
            onBlur={this.handleBlur}
            onChange={(option) => this.handleOnChange(option)}
            styles={{ width: 600 }}

            // components={this.renderOptions}
          />

          {get(touched, field.name) && get(errors, field.name) && (
            <ValidationError>{get(errors, field.name)}</ValidationError>
          )}
        </>
      );
    }
    return (
      <>
        <FlexContainer>
          <FlexContainer alignItems="center" flexWrap={inlineLabel && "nowrap"}>
            <StyledLabel style={{ flexBasis: "20%" }}>{label}</StyledLabel>
            <StyledAsync
              isRequired={isRequired}
              classNamePrefix="sales"
              placeholder={placeholder}
              cacheOptions
              loadOptions={this.loadOptions}
              defaultOptions
              onInputChange={this.handleInputChange}
              onBlur={this.handleBlur}
              onChange={(option) => this.handleOnChange(option)}
              styles={{ width: 600 }}

              // components={this.renderOptions}
            />
          </FlexContainer>
        </FlexContainer>
        {get(touched, field.name) && get(errors, field.name) && (
          <ValidationError>{get(errors, field.name)}</ValidationError>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth, product, opportunity }) => ({
  user: auth.userDetails,
  opportunity: opportunity.opportunity,
  productsByOpportunityId: opportunity.productsByOpportunityId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setClearbitServiceData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ServiceClearbit);
