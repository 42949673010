export const GET_TWILIO_CREDENTIAL_REQUEST = "GET_TWILIO_CREDENTIAL_REQUEST";
export const GET_TWILIO_CREDENTIAL_SUCCESS = "GET_TWILIO_CREDENTIAL_SUCCESS";
export const GET_TWILIO_CREDENTIAL_FAILURE = "GET_TWILIO_CREDENTIAL_FAILURE";

export const ADD_TWILIO_CREDENTIAL_REQUEST = "ADD_TWILIO_CREDENTIAL_REQUEST";
export const ADD_TWILIO_CREDENTIAL_SUCCESS = "ADD_TWILIO_CREDENTIAL_SUCCESS";
export const ADD_TWILIO_CREDENTIAL_FAILURE = "ADD_TWILIO_CREDENTIAL_FAILURE";

export const UPDATE_TWILIO_CREDENTIAL_REQUEST =
  "UPDATE_TWILIO_CREDENTIAL_REQUEST";
export const UPDATE_TWILIO_CREDENTIAL_SUCCESS =
  "UPDATE_TWILIO_CREDENTIAL_SUCCESS";
export const UPDATE_TWILIO_CREDENTIAL_FAILURE =
  "UPDATE_TWILIO_CREDENTIAL_FAILURE";

export const TWILIO_CALL_REQUEST = "TWILIO_CALL_REQUEST";
export const TWILIO_CALL_SUCCESS = "TWILIO_CALL_SUCCESS";
export const TWILIO_CALL_FAILURE = "TWILIO_CALL_FAILURE";

export const TWILIO_MESSAGE_REQUEST = "TWILIO_MESSAGE_REQUEST";
export const TWILIO_MESSAGE_SUCCESS = "TWILIO_MESSAGE_SUCCESS";
export const TWILIO_MESSAGE_FAILURE = "TWILIO_MESSAGE_FAILURE";

export const HANDLE_INTEGRATION_MODAL = "HANDLE_INTEGRATION_MODAL";
export const HANDLE_VOIP_MODAL = "HANDLE_VOIP_MODAL";
export const HANDLE_EMAIL_MODAL = "HANDLE_EMAIL_MODAL";
export const SET_VOIP_VIEW_TYPE = "SET_VOIP_VIEW_TYPE";

export const ADD_VOIP_REQUEST = "ADD_VOIP_REQUEST";
export const ADD_VOIP_SUCCESS = "ADD_VOIP_SUCCESS";
export const ADD_VOIP_FAILURE = "ADD_VOIP_FAILURE";




export const GET_VOIP_REQUEST = "GET_VOIPS_REQUEST";
export const GET_VOIP_SUCCESS = "GET_VOIPS_SUCCESS";
export const GET_VOIP_FAILURE = "GET_VOIPS_FAILURE";
export const SET_EMAIL_EDIT = "SET_EMAIL_EDIT";
export const SET_VOIP_EDIT = "SET_VOIP_EDIT";