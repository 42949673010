import * as types from "./OrganizationActionTypes";

const initialState = {
    postFacebook: false,
    postFacebookError: false,
    facebookPost: [],

    fetchingFacebookWebhooks: false,
    fetchingFacebookWebhooksError: false,
    facebookWebhooks: [],

    fetchingQuickbookProcessTask: false,
    fetchingQuickbookProcessTaskError: false,
    processQuickbookTask: [],

    facebooklogging: false,
    facebookloginError: false,
    facebookloginSuccess: false,
};

export const organizationReducer = (state = initialState, action) => {
    switch (action.type) {

        case types.ADD_QUICKBOOK_REQUEST:
            return { ...state, addingQuickbook: true, addingQuickbookError: false };
          case types.ADD_QUICKBOOK_SUCCESS:
            return {
              ...state,
              addingQuickbook: false,
              addingQuickbookError: false,
              addQuickbookModal: false,
            };
          case types.ADD_QUICKBOOK_FAILURE:
            return {
              ...state,
              addingQuickbook: false,
              addingQuickbookError: true,
              addQuickbookModal: false,
            };

            case types.POST_FACEBOOK_REQUEST:
                return {
                    ...state,
                    postFacebook: true,
                    postFacebookError: false,
                };
            case types.POST_FACEBOOK_SUCCESS:
                return {
                    ...state,
                    postFacebook: false,
                    postFacebookError: false,
                    facebookPost: action.payload,
                };
            case types.POST_FACEBOOK_FAILURE:
                return {
                    ...state,
                    postFacebook: false,
                    postFacebookError: true,
                };
    
            
            case types.GET_FACEBOOK_WEBHOOKS_REQUEST:
                return {
                    ...state,
                    fetchingFacebookWebhooks: true,
                };
            case types.GET_FACEBOOK_WEBHOOKS_SUCCESS:
                return {
                    ...state,
                    fetchingFacebookWebhooks: false,
                    facebookWebhooks: action.payload,
                };
            case types.GET_FACEBOOK_WEBHOOKS_FAILURE:
                return {
                    ...state,
                    fetchingFacebookWebhooks: false,
                    fetchingFacebookWebhooksError: true,
                };
                
            case types.GET_QUICKBOOK_PROCESS_REQUEST:
                    return {
                      ...state,
                      fetchingQuickbookProcessTask: true,
                      fetchingQuickbookProcessTaskError: false,
                    };
            case types.GET_QUICKBOOK_PROCESS_SUCCESS:
                    return {
                      ...state,
                      fetchingQuickbookProcessTask: false,
                      fetchingQuickbookProcessTaskError: false,
                      processQuickbookTask: action.payload,
                    };
            case types.GET_QUICKBOOK_PROCESS_FAILURE:
                    return {
                      ...state,
                      fetchingQuickbookProcessTask: false,
                      fetchingQuickbookProcessTaskError: true,
                    };

            case types.FACEBOOK_LOGIN_REQUEST:
                  return { ...state, facebooklogging: true };
            case types.FACEBOOK_LOGIN_SUCCESS:
                  return {
                    ...state,
                    facebooklogging: false
                  };
            case types.FACEBOOK_LOGIN_FAILURE:
                  return {
                    ...state,
                    facebooklogging: false,
                    facebookloginError: true
                  };
        default:
      return state;
    }
};