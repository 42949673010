import * as types from "./PartnerLeadsActionTypes";
const initialState = {
  addPartnerOpportunityModal: false,
  addPartnerCustomerModal: false,
  editPartnerCustomerModal: false,
  editPartnerOpportunityModal: false,

  setEditingPartnerLeadsCustomer: {},

  setEditingPartnerLeadsOpportunity: {},

  fetchingCurrency: false,
  fetchingCurrencyError: false,
  currencies: [],

  fetchingProcess: false,
  fetchingProcessError: false,
  Process: [],

  fetchingAllProcessStages: false,
  fetchingAllProcessStagesError: false,
  allProcessStages: [],

  fetchingPartnerCustomer: false,
  fetchingPartnerCustomerError: false,
  partnerCustomer: [],

  fetchingPartnerOpportunity: false,
  fetchingPartnerOpportunityError: false,
  partnerOpportuntiy: [],

  fetchingCompany: false,
  fetchingCompanyError: false,
  companyList: [],

  updatingPartnerCustomer: false,
  updatingPartnerCustomerError: false,
};
export const PartnerLeadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.HANDLE_PARTNER_LEADS_OPPORTUNITY_MODAL:
      return { ...state, addPartnerOpportunityModal: action.payload };

    case types.HANDLE_PARTNER_LEADS_CUSTOMER_MODAL:
      return { ...state, addPartnerCustomerModal: action.payload };

    case types.HANDLE_EDIT_PARTNER_LEADS_CUSTOMER_MODAL:
      return { ...state, editPartnerCustomerModal: action.payload };

    case types.HANDLE_EDIT_PARTNER_LEADS_OPPORTUNITY_MODAL:
      return { ...state, editPartnerOpportunityModal: action.payload };

    case types.SET_CUSTOMER_PARTNER_LEADS_EDIT:
      return { ...state, setEditingPartnerLeadsCustomer: action.payload };

    case types.SET_OPPORTUNITY_PARTNER_LEADS_EDIT:
      return { ...state, setEditingPartnerLeadsOpportunity: action.payload };

    case types.GET_CURRENCY_REQUEST:
      return { ...state, fetchingCurrency: true };
    case types.GET_CURRENCY_SUCCESS:
      return { ...state, fetchingCurrency: false, currencies: action.payload };
    case types.GET_CURRENCY_FAILURE:
      return {
        ...state,
        fetchingCurrency: false,
        fetchingCurrencyError: true,
      };
    case types.GET_PROCESS_REQUEST:
      return { ...state, fetchingProcess: true, fetchingProcessError: false };
    case types.GET_PROCESS_SUCCESS:
      return {
        ...state,
        fetchingProcess: false,
        fetchingProcessError: false,
        Process: action.payload,
      };
    case types.GET_PROCESS_FAILURE:
      return { ...state, fetchingProcess: false, fetchingProcessError: true };
    case types.GET_ALL_PROCESS_STAGES_REQUEST:
      return {
        ...state,
        fetchingAllProcessStages: true,
        fetchingAllProcessStagesError: false,
      };
    case types.GET_ALL_PROCESS_STAGES_SUCCESS:
      return {
        ...state,
        fetchingAllProcessStages: false,
        fetchingAllProcessStagesError: false,
        allProcessStages: action.payload,
      };
    case types.GET_ALL_PROCESS_STAGES_FAILURE:
      return {
        ...state,
        fetchingAllProcessStages: false,
        fetchingAllProcessStagesError: true,
      };

    //PARTNER CUSTOMER
    case types.ADD_PARTNER_CUSTOMER_REQUEST:
      return { ...state, addingPartnerCustomer: true };
    case types.ADD_PARTNER_CUSTOMER_SUCCESS:
      return {
        ...state,
        addingPartnerCustomer: false,
        addPartnerCustomerModal: false,
      };
    case types.ADD_PARTNER_CUSTOMER_FAILURE:
      return {
        ...state,
        addingPartnerCustomer: false,
        addingPartnerCustomerError: true,
      };
    //GET PARTNER CUSTOMER
    case types.GET_PARTNER_CUSTOMER_REQUEST:
      return { ...state, fetchingPartnerCustomer: true };
    case types.GET_PARTNER_CUSTOMER_SUCCESS:
      return {
        ...state,
        fetchingPartnerCustomer: false,
        partnerCustomer: action.payload,
      };
    case types.GET_PARTNER_CUSTOMER_FAILURE:
      return {
        ...state,
        fetchingPartnerCustomer: false,
        fetchingPartnerCustomerError: true,
      };
    //UPDATE PARTNER CUSTOMER
    case types.UPDATE_PARTNER_CUSTOMER_REQUEST:
      return { ...state, updatingPartnerCustomer: true };
    case types.UPDATE_PARTNER_CUSTOMER_SUCCESS:
      return {
        ...state,
        updatingPartnerCustomer: false,
        editPartnerCustomerModal: false,
        partnerCustomer: state.partnerCustomer.map((partnerCustomer, i) => {
          if (partnerCustomer.id === action.payload.id) {
            return action.payload;
          } else {
            return partnerCustomer;
          }
        }),
      };
    case types.UPDATE_PARTNER_CUSTOMER_FAILURE:
      return {
        ...state,
        updatingPartnerCustomer: false,
        updatingPartnerCustomerError: true,
      };

    //PARTNER OPPORTUNITY
    case types.ADD_PARTNER_OPPORTUNITY_REQUEST:
      return { ...state, addingPartnerOpportunity: true };
    case types.ADD_PARTNER_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        addingPartnerOpportunity: false,
        addPartnerOpportunityModal: false,
      };
    case types.ADD_PARTNER_OPPORTUNITY_FAILURE:
      return {
        ...state,
        addingPartnerOpportunity: false,
        addingPartnerOpportunityError: true,
      };

    //GET PARTNER OPPORTUNITY
    case types.GET_PARTNER_OPPORTUNITY_REQUEST:
      return { ...state, fetchingPartnerOpportunity: true };
    case types.GET_PARTNER_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        fetchingPartnerOpportunity: false,
        partnerOpportuntiy: action.payload,
      };
    case types.GET_PARTNER_OPPORTUNITY_FAILURE:
      return {
        ...state,
        fetchingPartnerOpportunity: false,
        fetchingPartnerOpportunityError: true,
      };

    //UPDATE PARTNER OPPORTUNITY
    case types.UPDATE_PARTNER_OPPORTUNITY_REQUEST:
      return { ...state, updatingPartnerCustomer: true };
    case types.UPDATE_PARTNER_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        updatingPartnerCustomer: false,
        editPartnerCustomerModal: false,
        partnerOpportuntiy: state.partnerOpportuntiy.map((partner, i) => {
          if (partner.id === action.payload.id) {
            return action.payload;
          } else {
            return partner;
          }
        }),
      };
    case types.UPDATE_PARTNER_OPPORTUNITY_FAILURE:
      return {
        ...state,
        updatingPartnerCustomer: false,
        updatingPartnerCustomerError: true,
      };

    //REGISTER PARTNER LEADS
    case types.REGISTER_PARTNER_OPPORTUNITY_REQUEST:
      return { ...state, registeringPartnerOpportunity: true };
    case types.REGISTER_PARTNER_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        registeringPartnerOpportunity: false,
        registerPartnerOpportunityModal: false,
      };
    case types.REGISTER_PARTNER_OPPORTUNITY_FAILURE:
      return {
        ...state,
        registeringPartnerOpportunity: false,
        registeringPartnerOpportunityError: true,
      };

    case types.REGISTER_PARTNER_CUSTOMER_REQUEST:
      return { ...state, registeringPartnerCustomer: true };
    case types.REGISTER_PARTNER_CUSTOMER_SUCCESS:
      return {
        ...state,
        registeringPartnerCustomer: false,
        registerPartnerCustomerModal: false,
      };
    case types.REGISTER_PARTNER_CUSTOMER_FAILURE:
      return {
        ...state,
        registeringPartnerCustomer: false,
        registeringPartnerCustomerError: true,
      };

    //GET PARTNER OPPORTUNITY
    case types.GET_COMPANY_REQUEST:
      return { ...state, fetchingCompany: true };
    case types.GET_COMPANY_SUCCESS:
      return {
        ...state,
        fetchingCompany: false,
        companyList: action.payload,
      };
    case types.GET_COMPANY_FAILURE:
      return {
        ...state,
        fetchingCompany: false,
        fetchingCompanyError: true,
      };
    default:
      return state;
  }
};
