import * as types from "./PartnerActionType";
import moment from "moment";
const initialState = {
  addAverageModal: false,
  addActiveModal: false,
  addRegisteredModal: false,
  addWinModal: false,
  addWonModal: false,
  addAccountsAddedModal: false,
  addPulseAverageModal: false,
  addPulseActiveModal: false,
  addPulseWinModal: false,
  addPulseWonModal: false,
  partnerDrawerVisible: false,
  addBarModal: false,
  partnerDrawerProps: {},
  dateRangeList: [
    {
      id: 1,
      type: "year",
      value: "FY",
      starter: true,
      isSelected: true,
      startDate: moment()
        .startOf("year")
        .toISOString(),
      endDate: moment()
        .endOf("year")
        .toISOString(),
    },
    {
      id: 2,
      type: "quarter",
      value: "QTD",
      starter: false,
      isSelected: false,
      startDate: moment()
        .startOf("quarter")
        .toISOString(),
      endDate: moment()
        .endOf("quarter")
        .toISOString(),
    },
    {
      id: 3,
      type: "month",
      value: "MTD",
      starter: false,
      isSelected: false,
      startDate: moment()
        .startOf("month")
        .toISOString(),
      endDate: moment()
        .endOf("month")
        .toISOString(),
    },
    {
      id: 4,
      type: "week",
      value: "1W",
      starter: false,
      isSelected: false,
      startDate: moment()
        .startOf("week")
        .toISOString(),
      endDate: moment()
        .endOf("week")
        .toISOString(),
    },
  ],
  addPartnerModal: false,
  addLinkPartnerModal: false,
  addSwitchOpenPartnerModal: false,
  addUpdateLinkPartnerModal: false,

  viewType: "grid",

  addingPartner: false,
  addingPartnerError: false,

  addingLinkPartner: false,
  addingLinkPartnerError: false,

  fetchingPartners: false,
  fetchingPartnersError: false,
  partners: [],

  currentPartner: {},

  updatePartners: false,
  updatePartnersError: false,

  fetchingLinkOpportunityPartners: false,
  fetchingLinkOpportunityPartnersError: false,
  linkOpportunityPartners: [],

  fetchingPartnersName: false,
  fetchingPartnersNameError: false,
  partnersName: [],

  //collaborate partner
  addingCollaboratePartner: false,
  addingCollaboratePartnerError: false,

  fetchingPartnerById: false,
  fetchingPartnerByIdError: false,
  partner: [],

  updatingPartnereById: false,
  updatingPartnereByIdError: false,
  // for user
  fetchingPartnerOpportunityByUserId: false,
  fetchingPartnerOpportunityByUserIdError: false,
  partnerOpportunityByUserId: [],
  // for partner
  fetchingPartnerOpportunityByPartnerId: false,
  fetchingPartnerOpportunityByPartnerIdError: false,
  partnerOpportunityByPartnerId: [],

  //For Professional +
  partnerContactModal: false,

  fetchingInputPartnerData: false,
  fetchingInputPartnerDataError: false,

  addingPartnerContactsByPartnerId: false,
  addingPartnerContactsByPartnerIdError: false,

  fetchingPartnerContactsByPartnerId: false,
  fetchingPartnerContactsByPartnerIdError: false,
  partnerContactsByPartnerId: [],

  fetchingPartnerTaskByPartnerId: false,
  fetchingPartnerTaskByPartnerIdError: false,
  partnerTaskByPartnerId: [],

  updatingPartnereVisiblilityById: false,
  updatingPartnereVisiblilityByIdError: false,

  //get topics of interest by partnerId
  fetchingTopicsByPartnerId: false,
  fetchingTopicsByPartnerIdError: false,

  topicsByPartnerId: [],

  addingTopicsByPartnerId: false,
  addingTopicsByPartnerIdError: false,

  deletingTopicsByPartnerId: false,
  deletingTopicsByPartnerIdError: false,

  //filter
  fetchingInitiativeWisePartner: false,
  fetchingInitiativeWisePartnerError: false,

  //partner topic Lis
  fetchingTopicList: false,
  fetchingTopicListError: false,
  topicList: [],

  //partner notes
  fetchingNotesListByPartnerId: false,
  fetchingNotesListByPartnerIdError: false,
  notesListByPartnerId: [],

  // partner user notes

  fetchingNotesListByUserId: false,
  fetchingNotesListByUserIdError: false,
  notesListByUserId: [],

  addPartnerTableContact: false,

  //partnerList view
  fetchingPartnerListcontact: false,
  fetchingPartnerListcontactError: false,
  partnersList: [],

  //SHARE PARTNER
  addSharingPartner: false,
  addSharingPartnerError: false,

  fetchingShareUser: false,
  fetchingShareUserError: false,
  shareUsers: [],

  fetchingSharingPartner: false,
  fetchingSharingPartnerError: false,
  sharePartners: [],

  //PARTNER MAP
  fetchingPartnerMapOnDashboardByUserId: false,
  fetchingPartnerMapOnDashboardByUserIdError: false,
  partnerMapOnDashboardByUserId: [],

  // PARTNER HISTORY
  fetchingPartnerHistory: false,
  fetchingPartnerHistoryError: false,
  partnerHistory: [],

  //PARTNER TABLE FEEDBACK
  fetchingFeedbackByPartnerId: false,
  fetchingFeedbackByPartnerIdError: true,
  partnerFeedbacks: [],

  feedbackModal: false,
};
const newDateRange = (dateRange, newDate) =>
  dateRange.map((range) => {
    if (range.id === newDate.id) {
      return { ...range, isSelected: true };
    } else {
      return { ...range, isSelected: false };
    }
  });
export const partnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PARTNER_TOPIC_LIST_REQUEST:
      return { ...state, fetchingTopicList: true };
    case types.GET_PARTNER_TOPIC_LIST_SUCCESS:
      return {
        ...state,
        fetchingTopicList: false,
        topicList: action.payload,
      };
    case types.GET_PARTNER_TOPIC_LIST_FAILURE:
      return {
        ...state,
        fetchingTopicList: false,
        fetchingTopicListError: true,
      };

    // for user

    case types.GET_PARTNER_OPPORTUNITY_BY_USER_ID_REQUEST:
      return { ...state, fetchingPartnerOpportunityByUserId: true };
    case types.GET_PARTNER_OPPORTUNITY_BY_USER_ID_SUCCESS:
      return {
        ...state,
        fetchingPartnerOpportunityByUserId: false,
        partnerOpportunityByUserId: action.payload,
      };
    case types.GET_PARTNER_OPPORTUNITY_BY_USER_ID_FAILURE:
      return {
        ...state,
        fetchingPartnerOpportunityByUserId: false,
        fetchingPartnerOpportunityByUserIdError: true,
      };

    // partner

    case types.GET_PARTNER_OPPORTUNITY_BY_PARTNER_ID_REQUEST:
      return { ...state, fetchingPartnerOpportunityByPartnerId: true };
    case types.GET_PARTNER_OPPORTUNITY_BY_PARTNER_ID_SUCCESS:
      return {
        ...state,
        fetchingPartnerOpportunityByPartnerId: false,
        partnerOpportunityByPartnerId: action.payload,
      };
    case types.GET_PARTNER_OPPORTUNITY_BY_PARTNER_ID_FAILURE:
      return {
        ...state,
        fetchingPartnerOpportunityByPartnerId: false,
        fetchingPartnerOpportunityByPartnerIdError: true,
      };

    /**
     * update a single PARTNER by its ID
     */
    case types.UPDATE_PARTNER_BY_ID_REQUEST:
      return { ...state, updatingPartnereById: true };
    case types.UPDATE_PARTNER_BY_ID_SUCCESS:
      return {
        ...state,
        updatingPartnereById: false,
        partner: action.payload,
      };
    case types.UPDATE_PARTNER_BY_ID_FAILURE:
      return {
        ...state,
        updatingPartnereById: false,
        updatingPartnereByIdError: true,
      };

    case types.UPDATE_PARTNER_ADDRESS:
      return {
        ...state,
        partner: {
          ...state.partner,
          address: state.partner.address.map((item) => {
            if (item.addressId === action.payload.address.addressId) {
              return action.payload.address;
            } else {
              return item;
            }
          }),
        },
      };

    case types.ADD_PARTNER_ADDRESS:
      //////debugger;
      return {
        ...state,
        partner: {
          ...state.partner,
          address: action.payload.address,
        },
      };

    case types.UPDATE_PARTNER_VISIBILITY_REQUEST:
      return { ...state, updatingPartnereVisiblilityById: true };
    case types.UPDATE_PARTNER_VISIBILITY_SUCCESS:
      return {
        ...state,
        updatingPartnereVisiblilityById: false,
        partner: action.payload,
      };
    case types.UPDATE_PARTNER_VISIBILITY_FAILURE:
      return {
        ...state,
        updatingPartnereVisiblilityById: false,
        updatingPartnereVisiblilityByIdError: true,
      };

    case types.SET_PARTNER_VIEW_TYPE:
      return { ...state, viewType: action.payload };
    case types.SET_CURRENT_PARTNER:
      return { ...state, currentPartner: action.payload };

    case types.HANDLE_PARTNER_MODAL:
      return { ...state, addPartnerModal: action.payload };

    case types.HANDLE_LINK_PARTNER_MODAL:
      return { ...state, addLinkPartnerModal: action.payload };

    case types.HANDLE_ALLOW_SWITCH_OPEN_PARTNER_MODAL:
      return { ...state, addSwitchOpenPartnerModal: action.payload };

    case types.HANDLE_LINK_UPDATE_PARTNER_MODAL:
      return { ...state, addUpdateLinkPartnerModal: action.payload };

    case types.ADD_PARTNER_REQUEST:
      return { ...state, addingPartner: true };
    case types.ADD_PARTNER_SUCCESS:
      return {
        ...state,
        addingPartner: false,
        addPartnerModal: false,
        //   clearbit: null
      };
    case types.ADD_PARTNER_FAILURE:
      return {
        ...state,
        addingPartner: false,
        addingPartnerError: true,
        addPartnerModal: false,
      };

    case types.ADD_LINK_PARTNER_REQUEST:
      return { ...state, addingLinkPartner: true };
    case types.ADD_LINK_PARTNER_SUCCESS:
      return {
        ...state,
        addingLinkPartner: false,
        addLinkPartnerModal: false,
        //   clearbit: null
      };
    case types.ADD_LINK_PARTNER_FAILURE:
      return {
        ...state,
        addingLinkPartner: false,
        addingLinkPartnerError: true,
        addLinkPartnerModal: false,
      };

    case types.UPDATE_PARTNER_REQUEST:
      return { ...state, updatePartners: true };
    case types.UPDATE_PARTNER_SUCCESS:
      return {
        ...state,
        updatePartners: false,
        addUpdateLinkPartnerModal: false,
        linkOpportunityPartners: state.linkOpportunityPartners.map((item) => {
          if (item.partnerId === action.payload.partnerId) {
            return {
              ...item,
              value: action.payload.value,
              currency: action.payload.currency,
            };
          } else {
            return item;
          }
        }),
      };
    case types.UPDATE_PARTNER_FAILURE:
      return {
        ...state,
        updatePartners: false,
        updatePartnersError: true,
        addUpdateLinkPartnerModal: false,
      };

    case types.GET_PARTNER_REQUEST:
      return { ...state, fetchingPartners: true };
    case types.GET_PARTNER_SUCCESS:
      return { ...state, fetchingPartners: false, partners: action.payload };
    case types.GET_PARTNER_FAILURE:
      return { ...state, fetchingPartnersError: true };

    case types.GET_LINK_OPPORTUNITY_PARTNER_REQUEST:
      return { ...state, fetchingLinkOpportunityPartners: true };
    case types.GET_LINK_OPPORTUNITY_PARTNER_SUCCESS:
      return {
        ...state,
        fetchingLinkOpportunityPartners: false,
        linkOpportunityPartners: action.payload,
      };
    case types.GET_LINK_OPPORTUNITY_PARTNER_FAILURE:
      return {
        ...state,
        fetchingLinkOpportunityPartners: false,
        fetchingLinkOpportunityPartnersError: true,
      };

    case types.GET_PARTNER_NAME_REQUEST:
      return { ...state, fetchingPartnersName: true };
    case types.GET_PARTNER_NAME_SUCCESS:
      return {
        ...state,
        fetchingPartnersName: false,
        partnersName: action.payload,
      };
    case types.GET_PARTNER_NAME_FAILURE:
      return {
        ...state,
        fetchingPartnersName: false,
        fetchingPartnersNameError: true,
      };

    //partner collbaorate
    case types.ADD_COLLABORATE_PARTNER_REQUEST:
      return { ...state, addingCollaboratePartner: true };
    case types.ADD_COLLABORATE_PARTNER_SUCCESS:
      return {
        ...state,
        addingCollaboratePartner: false,
        addSwitchOpenPartnerModal: false,
        //   clearbit: null
      };
    case types.ADD_COLLABORATE_PARTNER_FAILURE:
      return {
        ...state,
        addingCollaboratePartner: false,
        addingCollaboratePartnerError: true,
        addSwitchOpenPartnerModal: false,
      };

    case types.GET_PARTNER_BY_ID_REQUEST:
      return { ...state, fetchingPartnerById: true };
    case types.GET_PARTNER_BY_ID_SUCCESS:
      return {
        ...state,
        fetchingPartnerById: false,
        partner: action.payload,
      };
    case types.GET_PARTNER_BY_ID_FAILURE:
      return {
        ...state,
        fetchingPartnerById: false,
        fetchingPartnerByIdError: true,
      };

    //For Professional +
    case types.HANDLE_PARTNER_CONTACT_MODAL:
      return { ...state, partnerContactModal: action.payload };

    case types.ADD_PARTNER_CONTACT_BY_PARTNERID_ID_REQUEST:
      return { ...state, addingPartnerContactsByPartnerId: true };
    case types.ADD_PARTNER_CONTACT_BY_PARTNERID_ID_SUCCESS:
      return {
        ...state,
        addingPartnerContactsByPartnerId: false,
        partnerContactModal: false,
      };
    case types.ADD_PARTNER_CONTACT_BY_PARTNERID_ID_FAILURE:
      return {
        ...state,
        addingPartnerContactsByPartnerId: false,
        addingPartnerContactsByPartnerIdError: true,
      };

    case types.GET_PARTNER_CONTACT_BY_PARTNERID_ID_REQUEST:
      return { ...state, fetchingPartnerContactsByPartnerId: true };
    case types.GET_PARTNER_CONTACT_BY_PARTNERID_ID_SUCCESS:
      return {
        ...state,
        fetchingPartnerContactsByPartnerId: false,
        partnerContactsByPartnerId: action.payload,
      };
    case types.GET_PARTNER_CONTACT_BY_PARTNERID_ID_FAILURE:
      return {
        ...state,
        fetchingPartnerContactsByPartnerId: false,
        fetchingPartnerContactsByPartnerIdError: true,
      };

    case types.GET_PARTNER_TASK_BY_PARTNERID_ID_REQUEST:
      return { ...state, fetchingPartnerTaskByPartnerId: true };
    case types.GET_PARTNER_TASK_BY_PARTNERID_ID_SUCCESS:
      return {
        ...state,
        fetchingPartnerTaskByPartnerId: false,
        partnerTaskByPartnerId: action.payload,
      };
    case types.GET_PARTNER_TASK_BY_PARTNERID_ID_FAILURE:
      return {
        ...state,
        fetchingPartnerTaskByPartnerId: false,
        fetchingPartnerTaskByPartnerIdError: true,
      };
    case types.SET_ACCOUNT_VIEW_TYPE:
      return { ...state, viewType: action.payload };
    case types.CHANGE_SELECTED_TIME_INTERVAL_CATALOGUE:
      return {
        ...state,
        dateRangeList: newDateRange(state.dateRangeList, action.payload),
        isCustomSelected: false,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        timeRangeType: action.payload.type,
      };
    case types.SET_TIME_INTERVAL_CATALOGUE:
      return {
        ...state,
        isCustomSelected: true,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };

    //get topics of interest by partnerId

    case types.GET_TOPICS_BY_PARTNER_ID_REQUEST:
      return { ...state, fetchingTopicsByPartnerId: true };
    case types.GET_TOPICS_BY_PARTNER_ID_SUCCESS:
      return {
        ...state,
        fetchingTopicsByPartnerId: false,
        topicsByPartnerId: action.payload,
      };
    case types.GET_TOPICS_BY_PARTNER_ID_FAILURE:
      return {
        ...state,
        fetchingTopicsByPartnerId: false,
        fetchingTopicsByPartnerIdError: true,
      };
    /**
     * add topic of a partnerId
     */
    case types.ADD_TOPIC_BY_PARTNER_ID_REQUEST:
      return { ...state, addingTopicsByPartnerId: true };
    case types.ADD_TOPIC_BY_PARTNER_ID_SUCCESS:
      // console.clear()
      // console.log(action.payload)
      return {
        ...state,
        addingTopicsByPartnerId: false,
        topicsByPartnerId: [...state.topicsByPartnerId, action.payload],
      };
    case types.ADD_TOPIC_BY_PARTNER_ID_FAILURE:
      return {
        ...state,
        addingTopicsByPartnerId: false,
        addingTopicsByPartnerIdError: true,
      };

    case types.DELETE_TOPIC_BY_PARTNER_ID_REQUEST:
      return { ...state, deletingTopicsByPartnerId: true };
    case types.DELETE_TOPIC_BY_PARTNER_ID_SUCCESS:
      return { ...state, deletingTopicsByPartnerId: false };
    case types.DELETE_TOPIC_BY_PARTNER_ID_FAILURE:
      return {
        ...state,
        deletingTopicsByPartnerId: false,
        deletingTopicsByPartnerIdError: true,
      };
    //FILTER partner
    case types.GET_INITIATIVE_WISE_PARTNER_REQUEST:
      return { ...state, fetchingInitiativeWisePartner: true };
    case types.GET_INITIATIVE_WISE_PARTNER_SUCCESS:
      return {
        ...state,
        fetchingInitiativeWisePartner: false,
        partners: action.payload,
      };

    case types.GET_INITIATIVE_WISE_PARTNER_FAILURE:
      return {
        ...state,
        fetchingInitiativeWisePartner: false,
        fetchingInitiativeWisePartnerError: true,
      };

    //partner notes

    case types.GET_NOTES_LIST_BY_PARTNER_ID_REQUEST:
      return { ...state, fetchingNotesListByPartnerId: true };
    case types.GET_NOTES_LIST_BY_PARTNER_ID_SUCCESS:
      return {
        ...state,
        fetchingNotesListByPartnerId: false,
        notesListByPartnerId: action.payload,
      };
    case types.GET_NOTES_LIST_BY_PARTNER_ID_FAILURE:
      return {
        ...state,
        fetchingNotesListByPartnerId: false,
        fetchingNotesListByPartnerIdError: true,
      };
    // partnerUser notes

    case types.GET_NOTES_LIST_BY_USER_ID_REQUEST:
      return { ...state, fetchingNotesListByUserId: true };
    case types.GET_NOTES_LIST_BY_USER_ID_SUCCESS:
      return {
        ...state,
        fetchingNotesListByUserId: false,
        notesListByPartnerId: action.payload,
      };
    case types.GET_NOTES_LIST_BY_USER_ID_FAILURE:
      return {
        ...state,
        fetchingNotesListByUserId: false,
        fetchingNotesListByUserIdError: true,
      };

    case types.HANDLE_PARTNER_DRAWER:
      return {
        ...state,
        partnerDrawerVisible: action.payload.isVisible,
        partnerDrawerProps: action.payload.props,
      };

    case types.HANDLE_AVERAGE_MODAL:
      return { ...state, addAverageModal: action.payload };

    case types.HANDLE_ACTIVE_MODAL:
      return { ...state, addActiveModal: action.payload };

    case types.HANDLE_REGISTERED_MODAL:
      return { ...state, addRegisteredModal: action.payload };

    case types.HANDLE_WIN_MODAL:
      return { ...state, addWinModal: action.payload };

    case types.HANDLE_WON_MODAL:
      return { ...state, addWonModal: action.payload };

    case types.HANDLE_ACCOUNTSADDED_MODAL:
      return { ...state, addAccountsAddedModal: action.payload };

    case types.HANDLE_PULSEAVERAGE_MODAL:
      return { ...state, addPulseAverageModal: action.payload };

    case types.HANDLE_PULSEACTIVE_MODAL:
      return { ...state, addPulseActiveModal: action.payload };

    case types.HANDLE_PULSEWIN_MODAL:
      return { ...state, addPulseWinModal: action.payload };

    case types.HANDLE_PULSEWON_MODAL:
      return { ...state, addPulseWonModal: action.payload };

    case types.HANDLE_BAR_MODAL:
      return { ...state, addBarModal: action.payload };

    case types.HANDLE_PARTNER_TABLE_CONTACT_MODAL:
      return { ...state, addPartnerTableContact: action.payload };

    // Partner Contats by partnerID

    case types.GET_PARTNER_CONTACT_BY_PARTNER_ID_REQUEST:
      return { ...state, fetchingPartnerListcontact: true };
    case types.GET_PARTNER_CONTACT_BY_PARTNER_ID_SUCCESS:
      return {
        ...state,
        fetchingPartnerListcontact: false,
        partnersList: action.payload,
      };

    case types.GET_PARTNER_CONTACT_BY_PARTNER_ID_FAILURE:
      return {
        ...state,
        fetchingPartnerListcontact: false,
        fetchingPartnerListcontactError: true,
      };

    //SHARE PARTNER
    case types.ADD_SHAREPARTNER_REQUEST:
      return { ...state, addSharingPartner: true };

    case types.ADD_SHAREPARTNER_SUCCESS:
      return { ...state, addSharingPartner: false, shareUsers: action.payload };

    case types.ADD_SHAREPARTNER_FAILURE:
      return {
        ...state,
        addSharingPartner: false,
        addSharingPartnerError: true,
      };

    case types.GET_SHAREPARTNER_USERS_REQUEST:
      return { ...state, fetchingShareUser: true };

    case types.GET_SHAREPARTNER_USERS_SUCCESS:
      return { ...state, fetchingShareUser: false, shareUsers: action.payload };

    case types.GET_SHAREPARTNER_USERS_FAILURE:
      return {
        ...state,
        fetchingShareUser: false,
        fetchingShareUserError: true,
      };

    case types.GET_SHAREPARTNER_REQUEST:
      return { ...state, fetchingSharingPartner: true };

    case types.GET_SHAREPARTNER_SUCCESS:
      return {
        ...state,
        fetchingSharingPartner: false,
        sharePartners: action.payload,
      };

    case types.GET_SHAREPARTNER_FAILURE:
      return {
        ...state,
        fetchingSharingPartner: false,
        fetchingSharingPartnerError: true,
      };

    case types.GET_MAP_DASHBOARD_PARTNER_REQUEST:
      return { ...state, fetchingPartnerMapOnDashboardByUserId: true };
    case types.GET_MAP_DASHBOARD_PARTNER_SUCCESS:
      return {
        ...state,
        fetchingPartnerMapOnDashboardByUserId: false,
        partnerMapOnDashboardByUserId: action.payload,
      };
    case types.GET_MAP_DASHBOARD_PARTNER_FAILURE:
      return {
        ...state,
        fetchingPartnerMapOnDashboardByUserId: false,
        fetchingPartnerMapOnDashboardByUserIdError: true,
      };

    case types.INPUT_SEARCH_PARTNER_DATA_REQUEST:
      return { ...state, fetchingInputPartnerData: true };
    case types.INPUT_SEARCH_PARTNER_DATA_SUCCESS:
      return {
        ...state,
        fetchingInputPartnerData: false,
        partners: action.payload,
      };
    case types.INPUT_SEARCH_PARTNER_DATA_FAILURE:
      return { ...state, fetchingInputPartnerDataError: true };

    // * get history of account
    // */
    case types.GET_PARTNER_HISTORY_REQUEST:
      return { ...state, fetchingAccountHistory: true };
    case types.GET_PARTNER_HISTORY_SUCCESS:
      return {
        ...state,
        fetchingPartnerHistory: false,
        partnerHistory: action.payload,
      };
    case types.GET_PARTNER_HISTORY_FAILURE:
      return {
        ...state,
        fetchingPartnerHistory: false,
        fetchingPartnerHistoryError: true,
      };

    // Partner feedback table
    case types.GET_FEEDBACK_BY_PARTNER_ID_REQUEST:
      return { ...state, fetchingFeedbackByPartnerId: true };
    case types.GET_FEEDBACK_BY_PARTNER_ID_SUCCESS:
      return {
        ...state,
        fetchingFeedbackByPartnerId: false,
        partnerFeedbacks: action.payload,
      };
    case types.GET_FEEDBACK_BY_PARTNER_ID_FAILURE:
      return {
        ...state,
        fetchingFeedbackByPartnerId: false,
        fetchingFeedbackByPartnerIdError: true,
      };

    // * feedback-card modal
    // */
    case types.HANDLE_FEEDBACK_MODAL:
      return { ...state, feedbackModal: action.payload };

    default:
      return state;
  }
  return state;
};
