export const SET_LEADS_TYPE = "SET_LEADS_TYPE";
export const HANDLE_LEADS_ACCOUNT_SCHEDULER_MODAL =
  "HANDLE_LEADS_ACCOUNT_SCHEDULER_MODAL";

export const HANDLE_LEADS_NOTES_MODAL = "HANDLE_LEADS_NOTES_MODAL";
export const HANDLE_LEADS_CONTACT_NOTES_MODAL =
  "HANDLE_LEADS_CONTACT_NOTES_MODAL";

export const HANDLE_LEADS_ACCOUNT_MODAL = "HANDLE_LEADS_ACCOUNT_MODAL";
export const SET_LEADS_ACCOUNT_EDIT = "SET_LEADS_ACCOUNT_EDIT";
export const HANDLE_EDIT_LEADS_ACCOUNT_MODAL =
  "HANDLE_EDIT_LEADS_ACCOUNT_MODAL";

export const SET_LEADS_CONTACT_EDIT = "SET_LEADS_CONTACT_EDIT";
export const HANDLE_EDIT_LEADS_CONTACT_MODAL =
  "HANDLE_EDIT_LEADS_CONTACT_MODAL";
export const HANDLE_LEADS_CONTACT_MODAL = "HANDLE_LEADS_CONTACT_MODAL";

export const ADD_LEADS_ACCOUNT_REQUEST = "ADD_LEADS_ACCOUNT_REQUEST";
export const ADD_LEADS_ACCOUNT_SUCCESS = "ADD_LEADS_ACCOUNT_SUCCESS";
export const ADD_LEADS_ACCOUNT_FAILURE = "ADD_LEADS_ACCOUNT_FAILURE";

export const GET_LEADS_ACCOUNT_REQUEST = "GET_LEADS_ACCOUNT_REQUEST";
export const GET_LEADS_ACCOUNT_SUCCESS = "GET_LEADS_ACCOUNT_SUCCESS";
export const GET_LEADS_ACCOUNT_FAILURE = "GET_LEADS_ACCOUNT_FAILURE";

export const CONVERT_LEADS_ACCOUNT_TO_ACTUAL_ACCOUNT_REQUEST =
  "CONVERT_LEADS_ACCOUNT_TO_ACTUAL_ACCOUNT_REQUEST";
export const CONVERT_LEADS_ACCOUNT_TO_ACTUAL_ACCOUNT_SUCCESS =
  "CONVERT_LEADS_ACCOUNT_TO_ACTUAL_ACCOUNT_SUCCESS";
export const CONVERT_LEADS_ACCOUNT_TO_ACTUAL_ACCOUNT_FAILURE =
  "CONVERT_LEADS_ACCOUNT_TO_ACTUAL_ACCOUNT_FAILURE";

export const ADD_LEADS_CONTACT_REQUEST = "ADD_LEADS_CONTACT_REQUEST";
export const ADD_LEADS_CONTACT_SUCCESS = "ADD_LEADS_CONTACT_SUCCESS";
export const ADD_LEADS_CONTACT_FAILURE = "ADD_LEADS_CONTACT_FAILURE";

export const GET_LEADS_CONTACT_REQUEST = "GET_LEADS_CONTACT_REQUEST";
export const GET_LEADS_CONTACT_SUCCESS = "GET_LEADS_CONTACT_SUCCESS";
export const GET_LEADS_CONTACT_FAILURE = "GET_LEADS_CONTACT_FAILURE";

export const GET_LEADS_CONTACT_WITHOUT_PAGE_REQUEST =
  "GET_LEADS_CONTACT_WITHOUT_PAGE_REQUEST";
export const GET_LEADS_CONTACT_WITHOUT_PAGE_SUCCESS =
  "GET_LEADS_CONTACT_WITHOUT_PAGE_SUCCESS";
export const GET_LEADS_CONTACT_WITHOUT_PAGE_FAILURE =
  "GET_LEADS_CONTACT_WITHOUT_PAGE_FAILURE";

export const CONVERT_LEADS_CONTACT_TO_ACTUAL_CONTACT_REQUEST =
  "CONVERT_LEADS_CONTACT_TO_ACTUAL_CONTACT_REQUEST";
export const CONVERT_LEADS_CONTACT_TO_ACTUAL_CONTACT_SUCCESS =
  "CONVERT_LEADS_CONTACT_TO_ACTUAL_CONTACT_SUCCESS";
export const CONVERT_LEADS_CONTACT_TO_ACTUAL_CONTACT_FAILURE =
  "CONVERT_LEADS_CONTACT_TO_ACTUAL_CONTACT_FAILURE";

export const UPDATE_CONTACT_TYPE_FOR_LEAD_REQUEST =
  "UPDATE_CONTACT_TYPE_FOR_LEAD_REQUEST";
export const UPDATE_CONTACT_TYPE_FOR_LEAD_SUCCESS =
  "UPDATE_CONTACT_TYPE_FOR_LEAD_SUCCESS";
export const UPDATE_CONTACT_TYPE_FOR_LEAD_FAILURE =
  "UPDATE_CONTACT_TYPE_FOR_LEAD_FAILURE";

export const UPDATE_ACCOUNT_TYPE_FOR_LEAD_REQUEST =
  "UPDATE_ACCOUNT_TYPE_FOR_LEAD_REQUEST";
export const UPDATE_ACCOUNT_TYPE_FOR_LEAD_SUCCESS =
  "UPDATE_ACCOUNT_TYPE_FOR_LEAD_SUCCESS";
export const UPDATE_ACCOUNT_TYPE_FOR_LEAD_FAILURE =
  "UPDATE_ACCOUNT_TYPE_FOR_LEAD_FAILURE";

export const GET_FILTERS_LEADS_CONTACT_BY_CATEGORY_REQUEST =
  "GET_FILTERS_LEADS_CONTACT_BY_CATEGORY_REQUEST";
export const GET_FILTERS_LEADS_CONTACT_BY_CATEGORY_SUCCESS =
  "GET_FILTERS_LEADS_CONTACT_BY_CATEGORY_SUCCESS";
export const GET_FILTERS_LEADS_CONTACT_BY_CATAOGRY_FAILURE =
  "GET_FILTERS_LEADS_CONTACT_BY_CATAOGRY_FAILURE";

export const GET_FILTERS_LEADS_JUNK_CONTACT_BY_CATEGORY_REQUEST =
  "GET_FILTERS_LEADS_JUNK_CONTACT_BY_CATEGORY_REQUEST";
export const GET_FILTERS_LEADS_JUNK_CONTACT_BY_CATEGORY_SUCCESS =
  "GET_FILTERS_LEADS_JUNK_CONTACT_BY_CATEGORY_SUCCESS";
export const GET_FILTERS_LEADS_JUNK_CONTACT_BY_CATAOGRY_FAILURE =
  "GET_FILTERS_LEADS_JUNK_CONTACT_BY_CATAOGRY_FAILURE";

export const GET_FILTERS_LEADS_ACCOUNT_BY_CATEGORY_REQUEST =
  "GET_FILTERS_LEADS_ACCOUNT_BY_CATEGORY_REQUEST";
export const GET_FILTERS_LEADS_ACCOUNT_BY_CATEGORY_SUCCESS =
  "GET_FILTERS_LEADS_ACCOUNT_BY_CATEGORY_SUCCESS";
export const GET_FILTERS_LEADS_ACCOUNT_BY_CATAOGRY_FAILURE =
  "GET_FILTERS_LEADS_ACCOUNT_BY_CATAOGRY_FAILURE";

export const UPDATE_CONTACT_LEAD_REQUEST = "UPDATE_CONTACT_LEAD_REQUEST";
export const UPDATE_CONTACT_LEAD_SUCCESS = "UPDATE_CONTACT_LEAD_SUCCESS";
export const UPDATE_CONTACT_LEAD_FAILURE = "UPDATE_CONTACT_LEAD_FAILURE";

export const UPDATE_ACCOUNT_LEAD_REQUEST = "UPDATE_ACCOUNT_LEAD_REQUEST";
export const UPDATE_ACCOUNT_LEAD_SUCCESS = "UPDATE_ACCOUNT_LEAD_SUCCESS";
export const UPDATE_ACCOUNT_LEAD_FAILURE = "UPDATE_ACCOUNT_LEAD_FAILURE";

export const GET_DESIGNATION_WISE_CONTACT_REQUEST =
  "GET_DESIGNATION_WISE_CONTACT_REQUEST";
export const GET_DESIGNATION_WISE_CONTACT_SUCCESS =
  "GET_DESIGNATION_WISE_CONTACT_SUCCESS";
export const GET_DESIGNATION_WISE_CONTACT_FAILURE =
  "GET_DESIGNATION_WISE_CONTACT_FAILURE";

export const GET_COUNTRY_WISE_CONTACT_REQUEST =
  "GET_COUNTRY_WISE_CONTACT_REQUEST";
export const GET_COUNTRY_WISE_CONTACT_SUCCESS =
  "GET_COUNTRY_WISE_CONTACT_SUCCESS";
export const GET_COUNTRY_WISE_CONTACT_FAILURE =
  "GET_COUNTRY_WISE_CONTACT_FAILURE";

//approaching Leads
export const GET_APPRAOCHING_LEADS_REQUEST = "GET_APPRAOCHING_LEADS_REQUEST";
export const GET_APPRAOCHING_LEADS_SUCCESS = "GET_APPRAOCHING_LEADS_SUCCESS";
export const GET_APPRAOCHING_LEADS_FAILURE = "GET_APPRAOCHING_LEADS_FAILURE";

//junk leads
export const GET_JUNKED_LEADS_REQUEST = "GET_JUNKED_LEADS_REQUEST";
export const GET_JUNKED_LEADS_SUCCESS = "GET_JUNKED_LEADS_SUCCESS";
export const GET_JUNKED_LEADS_FAILURE = "GET_JUNKED_LEADS_FAILURE";

//filter
export const GET_FILTER_JUNK_CONTACT_REQUEST =
  "GET_FILTER_JUNK_CONTACT_REQUEST";
export const GET_FILTER_JUNK_CONTACT_FAILURE =
  "GET_FILTER_JUNK_CONTACT_FAILURE";
export const GET_FILTER_JUNK_CONTACT_SUCCESS =
  "GET_FILTER_JUNK_CONTACT_SUCCESS";

//Re_Assign
export const REASSIGN_JUNK_CONTACT_REQUEST = "REASSIGN_JUNK_CONTACT_REQUEST";
export const REASSIGN_JUNK_CONTACT_SUCCESS = "REASSIGN_JUNK_CONTACT_SUCCESS";
export const REASSIGN_JUNK_CONTACT_FAILURE = "REASSIGN_JUNK_CONTACT_FAILURE";

//cleanup;
export const CLEAN_LEADS_CONTACT = "CLEAN_LEADS_CONTACT";
export const CLEAN_LEADS_ACCOUNT = "CLEAN_LEADS_ACCOUNT";
export const CLEAN_JUNK_LEADS_CONTACT = "CLEAN_JUNK_LEADS_CONTACT";

export const GET_LEADS_ACCOUNT_WITHOUT_PAGE_NO_REQUEST =
  "GET_LEADS_ACCOUNT_WITHOUT_PAGE_NO_REQUEST";
export const GET_LEADS_ACCOUNT_WITHOUT_PAGE_NO_SUCCESS =
  "GET_LEADS_ACCOUNT_WITHOUT_PAGE_NO_SUCCESS";
export const GET_LEADS_ACCOUNT_WITHOUT_PAGE_NO_FAILURE =
  "GET_LEADS_ACCOUNT_WITHOUT_PAGE_NO_FAILURE";

//cleanup leads contact,account,junk leads
export const CLEANUP_LEADS_ACCOUNT_REQUEST = "CLEANUP_LEADS_ACCOUNT_REQUEST";
export const CLEANUP_LEADS_ACCOUNT_SUCCESS = "CLEANUP_LEADS_ACCOUNT_SUCCESS";
export const CLEANUP_LEADS_ACCOUNT_FAILURE = "CLEANUP_LEADS_ACCOUNT_FAILURE";

export const CLEANUP_LEADS_CONTACT_REQUEST = "CLEANUP_LEADS_CONTACT_REQUEST";
export const CLEANUP_LEADS_CONTACT_SUCCESS = "CLEANUP_LEADS_CONTACT_SUCCESS";
export const CLEANUP_LEADS_CONTACT_FAILURE = "CLEANUP_LEADS_CONTACT_FAILURE";

export const CLEANUP_JUNK_LEADS_CONTACT_REQUEST =
  "CLEANUP_JUNK_LEADS_CONTACT_REQUEST";
export const CLEANUP_JUNK_LEADS_CONTACT_SUCCESS =
  "CLEANUP_JUNK_LEADS_CONTACT_SUCCESS";
export const CLEANUP_JUNK_LEADS_CONTACT_FAILURE =
  "CLEANUP_JUNK_LEADS_CONTACT_FAILURE";

export const HANDLE_EVENT_TASK_MODAL = "HANDLE_EVENT_TASK_MODAL";

export const ADD_LEADS_SCHEDULER_EVENT_REQUEST =
  "ADD_LEADS_SCHEDULER_EVENT_REQUEST";
export const ADD_LEADS_SCHEDULER_EVENT_SUCCESS =
  "ADD_LEADS_SCHEDULER_EVENT_SUCCESS";
export const ADD_LEADS_SCHEDULER_EVENT_FAILURE =
  "ADD_LEADS_SCHEDULER_EVENT_FAILURE";

export const GET_LEADS_SCHEDULER_EVENT_REQUEST =
  "GET_LEADS_SCHEDULER_EVENT_REQUEST";
export const GET_LEADS_SCHEDULER_EVENT_SUCCESS =
  "GET_LEADS_SCHEDULER_EVENT_SUCCESS";
export const GET_LEADS_SCHEDULER_EVENT_FAILURE =
  "GET_LEADS_SCHEDULER_EVENT_FAILURE";

export const ADD_LEADS_SCHEDULER_CALL_REQUEST =
  "ADD_LEADS_SCHEDULER_CALL_REQUEST";
export const ADD_LEADS_SCHEDULER_CALL_SUCCESS =
  "ADD_LEADS_SCHEDULER_CALL_SUCCESS";
export const ADD_LEADS_SCHEDULER_CALL_FAILURE =
  "ADD_LEADS_SCHEDULER_CALL_FAILURE";

export const GET_LEADS_SCHEDULER_CALL_REQUEST =
  "GET_LEADS_SCHEDULER_CALL_REQUEST";
export const GET_LEADS_SCHEDULER_CALL_SUCCESS =
  "GET_LEADS_SCHEDULER_CALL_SUCCESS";
export const GET_LEADS_SCHEDULER_CALL_FAILURE =
  "GET_LEADS_SCHEDULER_CALL_FAILURE";

export const GET_NOTES_LIST_BY_LEADS_ID_REQUEST =
  "GET_NOTES_LIST_BY_LEADS_ID_REQUEST";
export const GET_NOTES_LIST_BY_LEADS_ID_SUCCESS =
  "GET_NOTES_LIST_BY_LEADS_ID_SUCCESS";
export const GET_NOTES_LIST_BY_LEADS_ID_FAILURE =
  "GET_NOTES_LIST_BY_LEADS_ID_FAILURE";

export const SET_NOTE_EDIT = "SET_NOTE_EDIT";

export const GET_CONTACT_NOTES_LIST_BY_LEADS_ID_REQUEST =
  "GET_CONTACT_NOTES_LIST_BY_LEADS_ID_REQUEST";
export const GET_CONTACT_NOTES_LIST_BY_LEADS_ID_SUCCESS =
  "GET_CONTACT_NOTES_LIST_BY_LEADS_ID_SUCCESS";
export const GET_CONTACT_NOTES_LIST_BY_LEADS_ID_FAILURE =
  "GET_CONTACT_NOTES_LIST_BY_LEADS_ID_FAILURE";

export const ADD_LEADS_ACCOUNT_SCHEDULER_CALL_REQUEST =
  "ADD_LEADS_ACCOUNT_SCHEDULER_CALL_REQUEST";
export const ADD_LEADS_ACCOUNT_SCHEDULER_CALL_SUCCESS =
  "ADD_LEADS_ACCOUNT_SCHEDULER_CALL_SUCCESS";
export const ADD_LEADS_ACCOUNT_SCHEDULER_CALL_FAILURE =
  "ADD_LEADS_ACCOUNT_SCHEDULER_CALL_FAILURE";

export const ADD_LEADS_ACCOUNT_SCHEDULER_EVENT_REQUEST =
  "ADD_LEADS_ACCOUNT_SCHEDULER_EVENT_REQUEST";
export const ADD_LEADS_ACCOUNT_SCHEDULER_EVENT_SUCCESS =
  "ADD_LEADS_ACCOUNT_SCHEDULER_EVENT_SUCCESS";
export const ADD_LEADS_ACCOUNT_SCHEDULER_EVENT_FAILURE =
  "ADD_LEADS_ACCOUNT_SCHEDULER_EVENT_FAILURE";

export const GET_TIMELINE_LIST_BY_USER_ID_REQUEST =
  "GET_TIMELINE_LIST_BY_USER_ID_REQUEST";
export const GET_TIMELINE_LIST_BY_USER_ID_SUCCESS =
  "GET_TIMELINE_LIST_BY_USER_ID_SUCCESS";
export const GET_TIMELINE_LIST_BY_USER_ID_FAILURE =
  "GET_TIMELINE_LIST_BY_USER_ID_FAILURE";

export const GET_ACCOUNT_TIMELINE_LIST_BY_USER_ID_REQUEST =
  "GET_ACCOUNT_TIMELINE_LIST_BY_USER_ID_REQUEST";
export const GET_ACCOUNT_TIMELINE_LIST_BY_USER_ID_SUCCESS =
  "GET_ACCOUNT_TIMELINE_LIST_BY_USER_ID_SUCCESS";
export const GET_ACCOUNT_TIMELINE_LIST_BY_USER_ID_FAILURE =
  "GET_ACCOUNT_TIMELINE_LIST_BY_USER_ID_FAILURE";

export const HANDLE_CONTACT_TIMELINE_MODAL = "HANDLE_CONTACT_TIMELINE_MODAL";
export const HANDLE_ACCOUNT_TIMELINE_MODAL = "HANDLE_ACCOUNT_TIMELINE_MODAL";

export const GET_LEADS_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST =
  "GET_LEADS_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST";
export const GET_LEADS_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS =
  "GET_LEADS_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS";
export const GET_LEADS_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE =
  "GET_LEADS_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE";

export const GET_LEADS_ACCOUNT_MEETING_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST =
  "GET_LEADS_ACCOUNT_MEETING_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST";
export const GET_LEADS_ACCOUNT_MEETING_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS =
  "GET_LEADS_ACCOUNT_MEETING_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS";
export const GET_LEADS_ACCOUNT_MEETING_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE =
  "GET_LEADS_ACCOUNT_MEETING_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE";

export const GET_LEADS_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST =
  "GET_LEADS_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST";
export const GET_LEADS_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS =
  "GET_LEADS_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS";
export const GET_LEADS_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE =
  "GET_LEADS_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE";
export const HANDLE_ADD_QUALIFIED_MODAL = "HANDLE_ADD_QUALIFIED_MODAL";

export const ADD_LEADS_QUALIFIER_SALES_ACCOUNT_REQUEST =
  "ADD_LEADS_QUALIFIER_SALES_ACCOUNT_REQUEST";
export const ADD_LEADS_QUALIFIER_SALES_ACCOUNT_SUCCESS =
  "ADD_LEADS_QUALIFIER_SALES_ACCOUNT_SUCCESS";
export const ADD_LEADS_QUALIFIER_SALES_ACCOUNT_FAILURE =
  "ADD_LEADS_QUALIFIER_SALES_ACCOUNT_FAILURE";

export const HANDLE_ADD_LEADS_QUALIFIER_MODAL =
  "HANDLE_ADD_LEADS_QUALIFIER_MODAL";

export const ADD_CONTACT_LEADS_QUALIFIER_SALES_ACCOUNT_REQUEST =
  "ADD_CONTACT_LEADS_QUALIFIER_SALES_ACCOUNT_REQUEST";
export const ADD_CONTACT_LEADS_QUALIFIER_SALES_ACCOUNT_SUCCESS =
  "ADD_CONTACT_LEADS_QUALIFIER_SALES_ACCOUNT_SUCCESS";
export const ADD_CONTACT_LEADS_QUALIFIER_SALES_ACCOUNT_FAILURE =
  "ADD_CONTACT_LEADS_QUALIFIER_SALES_ACCOUNT_FAILURE";

export const HANDLE_LEADS_OPPORTUNITY_MODAL = "HANDLE_LEADS_OPPORTUNITY_MODAL";

export const ADD_SHARE_LEAD_PERMISSION_REQUEST = "ADD_SHARE_LEAD_PERMISSION_REQUEST";
export const ADD_SHARE_LEAD_PERMISSION_SUCCESS = "ADD_SHARE_LEAD_PERMISSION_SUCCESS";
export const ADD_SHARE_LEAD_PERMISSION_FAILURE = "ADD_SHARE_LEAD_PERMISSION_FAILURE";

export const GET_PERMISSIONS_LIST_LEAD_REQUEST = "GET_PERMISSIONS_LIST_LEAD_REQUEST";
export const GET_PERMISSIONS_LIST_LEAD_SUCCESS = "GET_PERMISSIONS_LIST_LEAD_SUCCESS";
export const GET_PERMISSIONS_LIST_LEAD_FAILURE = "GET_PERMISSIONS_LIST_LEAD_FAILURE";
