import styled from "styled-components";

const TabsWrapper = styled.div`
width: '100%'
border-radius: 0.2rem;
box-shadow: 0px 0.25em 0.62em -0.125em ${(props) => props.theme.boxShadowColor};
border: 0.06em solid ${(props) => props.theme.borderColor}
background-color: ${(props) => props.theme.backgroundColor};
color: ${(props) => props.theme.color};
margin: 0.3rem;
padding: 0.3rem;
height:"300px";
/* overflow: auto; */
&:hover{
 
     box-shadow: 0px 0.31em 0.62em -0.125em #444;
  }
`;
export default TabsWrapper;
