import * as types from "./SurveyActionType";

const initialState = {
  addingSurveyTemplate: false,

  fetchingSurveyTemplate: false,
  surveyTemplate: [],

  addingSurvey: false,
  addingSurveyError: false,

  fetchingSurvey: false,
  fetchingSurveyError: false,
  Surveydata: [],

  addSurveyModal: false,

  fetchingSurveyStages: false,
  fetchingSurveyStagesError: false,
  surveyStages: [],

  addingSurveyStages: false,
  addingSurveyStagesError: false,
  Survey: [],
  viewType: "grid",

  //question
  fetchingQuestion:false,
  fetchingQuestionError:false,
  question:[],


  addingQuestion:false,
  addingQuestionError:false,

  currentQuestion:{},
  updateQuestionModal:false,
};

export const surveyReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.SET_CURRENT_QUESTION:
      return {
        ...state,
       currentQuestion:action.payload
      };

      case types.HANDLE_UPDATE_QUESTION_MODAL:
        return {
          ...state,
         updateQuestionModal:action.payload
        };
    case types.ADD_SURVEY_TEMPLATE_REQUEST:
      return {
        ...state,
        addingSurveyTemplate: true,
      };

    case types.GET_SURVEY_TEMPLATE_REQUEST:
      return {
        ...state,
        fetchingSurveyTemplate: true,
      };
    //get survey
    case types.GET_SURVEY_REQUEST:
      return { ...state, fetchingSurvey: true, fetchingProcessError: false };
    case types.GET_SURVEY_SUCCESS:
      return {
        ...state,
        fetchingSurvey: false,
        fetchingSurveyError: false,
        Surveydata: action.payload,
      };
    case types.GET_SURVEY_FAILURE:
      return { ...state, fetchingSurvey: false, fetchingSurveyError: true };
       //UPDATE survey Name

    case types.UPDATE_SURVEY_REQUEST:
      return {
        ...state,
        updatingSurveyStages: true,
        updatingSurveyStagesError: false,
      };
    case types.UPDATE_SURVEY_SUCCESS:
      return {
        ...state,
        updatingSurveyStages: false,
        updatingSurveyStagesError: false,
        Surveydata: state.Surveydata.map((state) =>
          state.surveyId === action.payload.surveyId ? action.payload : state
        ),
      };
    case types.UPDATE_SURVEY_FAILURE:
      return {
        ...state,
        updatingSurveyStages: false,
        updatingSurveyStagesError: true,
      };

    //survey modal for add
    case types.HANDLE_SURVEY_MODAL:
      return { ...state, addSurveyModal: action.payload };
    //Add Survey
    case types.ADD_SURVEY_REQUEST:
      return { ...state, addingSurvey: true };
    case types.ADD_SURVEY_SUCCESS:
      return {
        ...state,
        addingSurvey: false,
        addSurveyModal: false,
      };
    case types.ADD_SURVEY_FAILURE:
      return { ...state, addingSurvey: false, addingSurveyError: true };

    //get survey stage

    case types.GET_SURVEY_STAGES_REQUEST:
      return {
        ...state,
        fetchingSurveyStages: true,
        fetchingSurveyStagesError: false,
      };
    case types.GET_SURVEY_STAGES_SUCCESS:
      return {
        ...state,
        fetchingSurveyStages: false,
        fetchingSurveyStagesError: false,
        surveyStages: action.payload,
      };
    case types.GET_SURVEY_STAGES_FAILURE:
      return {
        ...state,
        fetchingSurveyStages: false,
        fetchingSurveyStagesError: true,
      };

      case types.UPDATE_SURVEY_STAGE_REQUEST:
        return { ...state, updatingSurveyStages: true };
      case types.UPDATE_SURVEY_STAGE_SUCCESS:
        // return { ...state, updatingStages: false, states: [...state.states, action.payload] };
        return {
          ...state,
          updatingSurveyStages: false,
          surveyStages: state.surveyStages.map((state) =>
            state.sectionId === action.payload.sectionId ? action.payload : state
          ),
        };
      case types.UPDATE_SURVEY_STAGE_FAILURE:
        return { ...state, updatingSurveyStages: false, updatingSurveyStagesError: true };

         
    case types.ADD_SURVEY_STAGE_REQUEST:
      return { ...state, addingSurveyStages: true };
    case types.ADD_SURVEY_STAGE_SUCCESS:
      return {
        ...state,
        addingSurveyStages: false,
        Survey: [...state.surveyStages, action.payload],
      };
    case types.ADD_SURVEY_STAGE_FAILURE:
      return {
        ...state,
        addingSurveyStages: false,
        addingSurveyStagesError: true,
      };
    case types.SET_SURVEY_VIEW_TYPE:
      return { ...state, viewType: action.payload };


          //Question

    case types.GET_QUESTION_REQUEST:
      return {
        ...state,
        fetchingQuestion: true,
        fetchingQuestionError: false,
      };
    case types.GET_QUESTION_SUCCESS:
      return {
        ...state,
        fetchingQuestion: false,
        
        question: action.payload,
      };
    case types.GET_QUESTION_FAILURE:
      return {
        ...state,
       
        fetchingQuestionError: true,
      };
      case types.ADD_QUESTION_REQUEST:
        return {
          ...state,
          addingQuestion: true,
          addingQuestionError: false,
        };
      case types.ADD_QUESTION_SUCCESS:
        return {
          ...state,
          addingQuestion: false,
          
       
        };
      case types.ADD_QUESTION_FAILURE:
        return {
          ...state,
         
          addingQuestionnError: true,
        };
        case types.UPDATE_QUESTION_REQUEST:
          return {
            ...state,
            updatingQuestion: true,
           
          };
        case types.UPDATE_QUESTION_SUCCESS:
          return {
            ...state,
            updatingQuestion: false,
           
            question: state.question.map((state) =>
              state.questionId === action.payload.questionId ? action.payload : state
            ),
          };
        case types.UPDATE_QUESTION_FAILURE:
          return {
            ...state,
            updatingQuestion: false,
            updatingQuestionError: true,
          };
       
  

    default:
      return state;
  }
};
