import { combineReducers } from "redux";
import { LOGOUT } from "../Containers/Auth/AuthTypes";
/**
 *  All of application reducers import goes here...
 */
import { authReducer } from "../Containers/Auth/AuthReducer";
import { dashboardReducer } from "../Containers/Dashboard/DashboardReducer";
import { accountReducer } from "../Containers/Account/AccountReducer";
import { contactReducer } from "../Containers/Contact/ContactReducer";
import { teamReducer } from "../Containers/Team/TeamReducer";
import { opportunityReducer } from "../Containers/Opportunity/OpportunityReducer";
import { plannerReducer } from "../Containers/Planner/PlannerReducer";
import { settingsReducer } from "../Containers/Settings/SettingsReducer";
import { callReducer } from "../Containers/Call/CallReducer";
import { eventReducer } from "../Containers/Event/EventReducer";
import { taskReducer } from "../Containers/Task/TaskReducer";
import { themeReducer } from "../Containers/Settings/Theme/ThemeReducer";
import { importReducer } from "../Containers/Import/ImportReducer";
import { notificationReducer } from "../Containers/Notification/NotificationReducer";
import { voipReducer } from "../Containers/Settings/VoIP/VoIPReducer";
import { emailReducer } from "../Containers/Settings/Email/EmailReducer";
import { helpReducer } from "../Containers/Help/HelpReducer";
import { productReducer } from "../Containers/Product/ProductReducer";
import { teamsReducer } from "../Containers/Teams/TeamsReducer";
import { viewportReducer } from "../Containers/Viewport/ViewportReducer";
import { goalReducer } from "../Containers/Goal/GoalReducer";
import { reportReducer } from "../Containers/Report/ReportReducer";
import { stripeReducer } from "../Containers/Stripe/StripeReducer";
import { partnerReducer } from "../Containers/Partner/PartnerReducer";
import { leadReducer } from "../Containers/Leads/LeadsReducer";
import { ruleReducer } from "../Containers/Rules/RulesReducer";
import { quoteReducer } from "../Containers/Quote/QuoteReducer";
import { surveyDashboardReducer } from "../Containers/Survey/SurveyDashboardReducer";
import { campaignReducer } from "../Containers/Campaign/CampaignReducer.js";
import { customizationMenuReducer } from "../Containers/Main/CustomizationMenuReducer";
import { surveyReducer } from "../Containers/Template/SurveyReducer";
import { PartnerLeadsReducer } from "../Containers/PartnerLeads/PartnerLeadsReducer";
import { customeReducer } from "../Containers/Settings/CustomeField/CustomeReducer";
import { customReport } from "../Containers/Report/CustomReport/CustomReportReducer";
import { feedbackReducer } from "../Containers/FeedBack/FeedBackReducer";
import { releaseNoteReducer } from "../Containers/ReleaseNote/ReleaseNoteReducer";
// import { facebookReducer } from "../Containers/Organization/Child/OrganizationTabs/FacebookReducer";
import { usersbillingReducer } from "../Containers/Users/Child/UsersBilling/UsersBillingReducer";
import { idealReducer } from "../Containers/Ideal/IdealReducer";
import { paypalReducer } from "../Containers/PayPal/PaypalReducer";
import { facebookRulesReducer } from "../Containers/Rules/Child/RulesTab/FacebookTab/FacebookRulesReducer";
import { instagramRulesReducer } from "../Containers/Rules/Child/RulesTab/InstagramTab/InstagramRulesReducer";
import { organizationReducer } from "../Containers/Organization/OrganizationReducer";
import { languageReducer } from "../../src/Language/LanguageReducer";
import { storyLineReducer } from "../Containers/Settings/StoryLine/StoryLineReducer";
import { PermissionsReducer } from "../Containers/Permissions/PermissionsReducer";

const appReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  account: accountReducer,
  contact: contactReducer,
  team: teamReducer,
  opportunity: opportunityReducer,
  planner: plannerReducer,
  settings: settingsReducer,
  call: callReducer,
  event: eventReducer,
  task: taskReducer,
  theme: themeReducer,
  importReducer: importReducer,
  notification: notificationReducer,
  voip: voipReducer,
  email: emailReducer,
  help: helpReducer,
  product: productReducer,
  teams: teamsReducer,
  viewport: viewportReducer,
  goal: goalReducer,
  report: reportReducer,
  stripe: stripeReducer,
  partner: partnerReducer,
  lead: leadReducer,
  rule: ruleReducer,
  quote: quoteReducer,
  campaign: campaignReducer,
  custome: customizationMenuReducer,
  survey: surveyReducer,
  surveyDashboard: surveyDashboardReducer,
  partnerLeads: PartnerLeadsReducer,
  customeField: customeReducer,
  CustomReportReducer: customReport,
  feedback: feedbackReducer,
  release: releaseNoteReducer,
  // facebook: facebookReducer,
  mode: usersbillingReducer,
  ideal: idealReducer,
  paypal: paypalReducer,
  facebookrule: facebookRulesReducer,
  instagramrule: instagramRulesReducer,
  organization: organizationReducer,
  language: languageReducer,
  storyLine: storyLineReducer,
  permissions: PermissionsReducer
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    sessionStorage.clear();
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer;
