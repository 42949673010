import * as types from "./HelpActionTypes";
const initialState = {
  fetchingHelpVideos: false,
  fetchingHelpVideosError: false,
  helpVideos: [],
  addCatalogueModal: false,
  addSubscriptionModal: false,
  addAccountModal: false,
  addManageModal: false,
  addRecruitModal: false,
  addExcelModal: false,
  addViewportModal: false,
  addHelpDocumentModal: false,
  addHelpYoutubeModal: false,
  addHelpInfoModal: false,

  fetchingDocumentsByHelpId: false,
  fetchingDocumentsByHelpIdError: false,
  documentsByHelpId: [],

  // Partner

  fetchingPartnerDocumentsByHelpId: false,
  fetchingPartnerDocumentsByHelpIdError: false,
  documentPartnersByHelpId: [],

  gettingPartnerYoutubeByHelpId: false,
  gettingPartnerYoutubeByHelpIdError: false,
  youtubePartnerDocuments: [],

  fetchingPartnerPlaybookProcessStages: false,
  fetchingPartnerPlaybookProcessStagesError: false,
  PlaybookPartnerProcessStages: [],


  fetchingPartnerGeneralData: false,
  fetchingPartnerGeneralDataError: false,
  generalPartnerData: [],

  adddingDocumentsByHelpId: false,
  adddingDocumentsByHelpIdError: false,
  adddingYoutubeByHelpId: false,
  adddingYoutubeByHelpIdError: false,
  deleteDocuments: false,
  deleteDocumentsError: false,
  addYoutubePlayerModal: false,
  editHelpDocumentModal: false,
  editPlaybookYoutubeModal: false,

  gettingYoutubeByHelpId: false,
  gettingYoutubeByHelpIdError: false,
  youtubeDocuments: [],

  fetchingPlaybookProcessStages: false,
  fetchingPlaybookProcessStagesError: false,
  PlaybookProcessStages: [],

  setEditingDocument: {},
  setEditingYoutube: {},

  deletingTable: false,
  deletingTableError: false,

  fetchingGeneralData: false,
  fetchingGeneralDataError: false,
  generalData: [],
};
export const helpReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_HELP_VIDEOS_REQUEST:
      return {
        ...state,
        fetchingHelpVideos: true,
        fetchingHelpVideosError: false,
      };
    case types.GET_HELP_VIDEOS_SUCCESS:
      return {
        ...state,
        fetchingHelpVideos: false,
        fetchingHelpVideosError: false,
        helpVideos: action.payload,
      };
    case types.GET_HELP_VIDEOS_FAILURE:
      return {
        ...state,
        fetchingHelpVideos: false,
        fetchingHelpVideosError: true,
      };
    case types.HANDLE_CATALOGUE_MODAL:
      return { ...state, addCatalogueModal: action.payload };

    case types.HANDLE_EDIT_PLAYBOOK_DOCUMENT_MODAL:
      return { ...state, editHelpDocumentModal: action.payload };

    case types.HANDLE_EDIT_PLAYBOOK_YOUTUBE_MODAL:
      return { ...state, editPlaybookYoutubeModal: action.payload };

    case types.HANDLE_SUBSCRIPTION_MODAL:
      return { ...state, addSubscriptionModal: action.payload };
    case types.HANDLE_ACCOUNT_MODAL:
      return { ...state, addAccountModal: action.payload };
    case types.HANDLE_MANAGE_MODAL:
      return { ...state, addManageModal: action.payload };
    case types.HANDLE_RECRUIT_MODAL:
      return { ...state, addRecruitModal: action.payload };
    case types.HANDLE_EXCEL_MODAL:
      return { ...state, addExcelModal: action.payload };
    case types.HANDLE_VIEWPORT_MODAL:
      return { ...state, addViewportModal: action.payload };
    case types.HANDLE_HELPDOCUMENT_MODAL:
      return { ...state, addHelpDocumentModal: action.payload };
    case types.HANDLE_HELPYOUTUBE_MODAL:
      return { ...state, addHelpYoutubeModal: action.payload };
    case types.HANDLE_HELPINFO_MODAL:
      return { ...state, addHelpInfoModal: action.payload };
    case types.HANDLE_YOUTUBE_PLAYER_MODAL:
      return { ...state, addYoutubePlayerModal: action.payload };

    case types.GET_HELP_DOCUMENTS_REQUEST:
      return {
        ...state,
        fetchingDocumentsByHelpId: true,
        fetchingDocumentsByHelpIdError: false,
      };
    case types.GET_HELP_DOCUMENTS_SUCCESS:
      return {
        ...state,
        fetchingDocumentsByHelpId: false,
        fetchingDocumentsByHelpIdError: false,
        documentsByHelpId: action.payload,
      };
    case types.GET_HELP_DOCUMENTS_FAILURE:
      return {
        ...state,
        fetchingDocumentsByHelpId: false,
        fetchingDocumentsByHelpIdError: true,
      };

    // get partner DOCUMENT

    case types.GET_HELP_PARTNER_DOCUMENTS_REQUEST:
      return {
        ...state,
        fetchingPartnerDocumentsByHelpId: true,
        fetchingPartnerDocumentsByHelpIdError: false,
      };
    case types.GET_HELP_PARTNER_DOCUMENTS_SUCCESS:
      return {
        ...state,
        fetchingPartnerDocumentsByHelpId: false,
        fetchingPartnerDocumentsByHelpIdError: false,
        documentPartnersByHelpId: action.payload,
      };
    case types.GET_HELP_PARTNER_DOCUMENTS_FAILURE:
      return {
        ...state,
        fetchingPartnerDocumentsByHelpId: false,
        fetchingPartnerDocumentsByHelpIdError: true,
      };
    // Get Partner YOUTUBE

    case types.GET_YOUTUBE_PARTNER_DOCUMENTS_REQUEST:
      return {
        ...state,
        gettingPartnerYoutubeByHelpId: true,
        gettingPartnerYoutubeByHelpIdError: false,
      };
    case types.GET_YOUTUBE_PARTNER_DOCUMENTS_SUCCESS:
      return {
        ...state,
        gettingPartnerYoutubeByHelpId: false,
        gettingPartnerYoutubeByHelpIdError: false,
        youtubePartnerDocuments: action.payload,
      };
    case types.GET_YOUTUBE_PARTNER_DOCUMENTS_FAILURE:
      return {
        ...state,
        gettingPartnerYoutubeByHelpId: false,
        gettingPartnerYoutubeByHelpIdError: true,
      };

    // Get Partner PROCESS

    case types.GET_PLAYBOOK_PARTNER_PROCESS_STAGES_REQUEST:
      return {
        ...state,
        fetchingPartnerPlaybookProcessStages: true,
        fetchingPartnerPlaybookProcessStagesError: false,
      };
    case types.GET_PLAYBOOK_PARTNER_PROCESS_STAGES_SUCCESS:
      return {
        ...state,
        fetchingPartnerPlaybookProcessStages: false,
        fetchingPartnerPlaybookProcessStagesError: false,
        PlaybookPartnerProcessStages: action.payload,
      };
    case types.GET_PLAYBOOK_PARTNER_PROCESS_STAGES_FAILURE:
      return {
        ...state,
        fetchingPartnerPlaybookProcessStages: false,
        fetchingPartnerPlaybookProcessStagesError: true,
      };

    // GET Partner GENERAL

    case types.GET_PARTNER_GENERAL_DATA_REQUEST:
      return {
        ...state,
        fetchingPartnerGeneralData: true,
        fetchingPartnerGeneralDataError: false,
      };
    case types.GET_PARTNER_GENERAL_DATA_SUCCESS:
      return {
        ...state,
        fetchingPartnerGeneralData: false,
        fetchingPartnerGeneralDataError: false,
        generalPartnerData: action.payload,
      };
    case types.GET_PARTNER_GENERAL_DATA_FAILURE:
      return {
        ...state,
        fetchingPartnerGeneralData: false,
        fetchingPartnerGeneralDataError: true,
      };


    case types.ADD_HELP_DOCUMENTS_REQUEST:
      return {
        ...state,
        adddingDocumentsByHelpId: true,
        adddingDocumentsByHelpIdError: false,
      };
    case types.ADD_HELP_DOCUMENTS_SUCCESS:
      return {
        ...state,
        adddingDocumentsByHelpId: false,
        adddingDocumentsByHelpIdError: false,
        addHelpDocumentModal: false,
      };
    case types.ADD_HELP_DOCUMENTS_FAILURE:
      return {
        ...state,
        adddingDocumentsByHelpId: false,
        adddingDocumentsByHelpIdError: true,
      };
    case types.DELETE_HELP_DOCUMENTS_REQUEST:
      return { ...state, deleteDocuments: true };
    case types.DELETE_HELP_DOCUMENTS_SUCCESS:
      return {
        ...state,
        deleteDocuments: false,
        documentsByHelpId: state.documentsByHelpId.filter(
          (item) => item.playbookId !== action.payload
        ),
      };
    case types.DELETE_HELP_DOCUMENTS_FAILURE:
      return { ...state, deleteDocuments: false, deleteDocumentsError: false };

    //YOUTUBE
    case types.ADD_YOUTUBE_DOCUMENTS_REQUEST:
      return {
        ...state,
        adddingYoutubeByHelpId: true,
        adddingYoutubeByHelpIdError: false,
      };
    case types.ADD_YOUTUBE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        adddingYoutubeByHelpId: false,
        adddingYoutubeByHelpIdError: false,
        addHelpYoutubeModal: false,
      };
    case types.ADD_YOUTUBE_DOCUMENTS_FAILURE:
      return {
        ...state,
        adddingYoutubeByHelpId: false,
        adddingYoutubeByHelpIdError: true,
      };
    //GET YOUTUBE DOCUMENTS
    case types.GET_YOUTUBE_DOCUMENTS_REQUEST:
      return {
        ...state,
        gettingYoutubeByHelpId: true,
        gettingYoutubeByHelpIdError: false,
      };
    case types.GET_YOUTUBE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        gettingYoutubeByHelpId: false,
        gettingYoutubeByHelpIdError: false,
        youtubeDocuments: action.payload,
      };
    case types.GET_YOUTUBE_DOCUMENTS_FAILURE:
      return {
        ...state,
        gettingYoutubeByHelpId: false,
        gettingYoutubeByHelpIdError: true,
      };

    case types.GET_PLAYBOOK_PROCESS_STAGES_REQUEST:
      return {
        ...state,
        fetchingPlaybookProcessStages: true,
        fetchingPlaybookProcessStagesError: false,
      };
    case types.GET_PLAYBOOK_PROCESS_STAGES_SUCCESS:
      return {
        ...state,
        fetchingPlaybookProcessStages: false,
        fetchingPlaybookProcessStagesError: false,
        PlaybookProcessStages: action.payload,
      };
    case types.GET_PLAYBOOK_PROCESS_STAGES_FAILURE:
      return {
        ...state,
        fetchingPlaybookProcessStages: false,
        fetchingPlaybookProcessStagesError: true,
      };

    case types.DELETE_DOCUMENT_REQUEST:
      return { ...state, deletingTable: true };
    case types.DELETE_DOCUMENT_SUCCESS:
      return { ...state, deletingTable: false };
    case types.DELETE_DOCUMENT_FAILURE:
      return {
        ...state,
        deletingTable: false,
        deletingTableError: true,
      };
    //GENERAL

    case types.GET_GENERAL_DATA_REQUEST:
      return {
        ...state,
        fetchingGeneralData: true,
        fetchingGeneralDataError: false,
      };
    case types.GET_GENERAL_DATA_SUCCESS:
      return {
        ...state,
        fetchingGeneralData: false,
        fetchingGeneralDataError: false,
        generalData: action.payload,
      };
    case types.GET_GENERAL_DATA_FAILURE:
      return {
        ...state,
        fetchingGeneralData: false,
        fetchingGeneralDataError: true,
      };

    case types.SET_DOCUMENT_EDIT:
      return { ...state, setEditingDocument: action.payload };
    case types.SET_YOUTUBE_EDIT:
      return { ...state, setEditingYoutube: action.payload };

    default:
      return state;
  }
};
