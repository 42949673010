import styled from "styled-components";

const FormWrapper = styled.div`
    padding: 1rem;
width: ${props => props.width}
    border-radius: 0.2rem;
    box-shadow: 0px 0.25em 0.62em -0.125em #444;
    border: 0.06em solid #ddd;
    background: #fff;
    @media only screen and (max-width: 600px) {
    
       width:89%
      
           
         }
         @media only screen 
and (min-device-width : 48em) 
and (max-device-width : 64em)
and (-webkit-min-device-pixel-ratio: 2){
 
}
         
`
export default FormWrapper;