export const GET_PROFESSIONALDUCTS_REQUEST = "GET_PROFESSIONALDUCTS_REQUEST";
export const GET_PROFESSIONALDUCTS_SUCCESS = "GET_PROFESSIONALDUCTS_SUCCESS";
export const GET_PROFESSIONALDUCTS_FAILURE = "GET_PROFESSIONALDUCTS_FAILURE";

export const GET_SERVICE_REQUEST = "GET_SERVICE_REQUEST";
export const GET_SERVICE_SUCCESS = "GET_SERVICE_SUCCESS";
export const GET_SERVICE_FAILURE = "GET_SERVICE_FAILURE";

export const GET_PRODUCT_BY_ID_REQUEST = "GET_PRODUCT_BY_ID_REQUEST";
export const GET_PRODUCT_BY_ID_SUCCESS = "GET_PRODUCT_BY_ID_SUCCESS";
export const GET_PRODUCT_BY_ID_FAILURE = "GET_PRODUCT_BY_ID_FAILURE";

export const GET_SERVICE_BY_ID_REQUEST = "GET_SERVICE_BY_ID_REQUEST";
export const GET_SERVICE_BY_ID_SUCCESS = "GET_SERVICE_BY_ID_SUCCESS";
export const GET_SERVICE_BY_ID_FAILURE = "GET_SERVICE_BY_ID_FAILURE";

export const GET_LATEST_PRODUCTS_BY_ORGANIZATION_ID_REQUEST =
  "GET_LATEST_PRODUCTS_BY_ORGANIZATION_ID_REQUEST";
export const GET_LATEST_PRODUCTS_BY_ORGANIZATION_ID_SUCCESS =
  "GET_LATEST_PRODUCTS_BY_ORGANIZATION_ID_SUCCESS";
export const GET_LATEST_PRODUCTS_BY_ORGANIZATION_ID_FAILURE =
  "GET_LATEST_PRODUCTS_BY_ORGANIZATION_ID_FAILURE";

export const ADD_PROFESSIONALDUCT_REQUEST = "ADD_PROFESSIONALDUCT_REQUEST";
export const ADD_PROFESSIONALDUCT_SUCCESS = "ADD_PROFESSIONALDUCT_SUCCESS";
export const ADD_PROFESSIONALDUCT_FAILURE = "ADD_PROFESSIONALDUCT_FAILURE";

export const ADD_SERVICE_REQUEST = "ADD_SERVICE_REQUEST";
export const ADD_SERVICE_SUCCESS = "ADD_SERVICE_SUCCESS";
export const ADD_SERVICE_FAILURE = "ADD_SERVICE_FAILURE";

export const HANDLE_PROFESSIONALDUCT_MODAL = "HANDLE_PROFESSIONALDUCT_MODAL";
export const HANDLE_SERVICE_MODAL = "HANDLE_SERVICE_MODAL";
export const HANDLE_CONFIGURE_MODAL = "HANDLE_CONFIGURE_MODAL";
export const HANDLE_DETAILSFORM_MODAL = "HANDLE_DETAILSFORM_MODAL";
export const SET_PROFESSIONALDUCT_VIEW_TYPE = "SET_PROFESSIONALDUCT_VIEW_TYPE";

export const UPDATE_SERVICE_BY_ID_REQUEST = "UPDATE_SERVICE_BY_ID_REQUEST";
export const UPDATE_SERVICE_BY_ID_SUCCESS = "UPDATE_SERVICE_BY_ID_SUCCESS";
export const UPDATE_SERVICE_BY_ID_FAILURE = "UPDATE_SERVICE_BY_ID_FAILURE";

export const UPDATE_PRODUCT_BY_ID_REQUEST = "UPDATE_PRODUCT_BY_ID_REQUEST";
export const UPDATE_PRODUCT_BY_ID_SUCCESS = "UPDATE_PRODUCT_BY_ID_SUCCESS";
export const UPDATE_PRODUCT_BY_ID_FAILURE = "UPDATE_PRODUCT_BY_ID_FAILURE";

export const CHANGE_SELECTED_TIME_INTERVAL_CATALOGUE =
  "CHANGE_SELECTED_TIME_INTERVAL_CATALOGUE";
export const SET_TIME_INTERVAL_CATALOGUE = "SET_TIME_INTERVAL_CATALOGUE";

export const HANDLE_WEIGHTED_MODAL = "HANDLE_WEIGHTED_MODAL";
export const HANDLE_ABSOLUTE_MODAL = "HANDLE_ABSOLUTE_MODAL";
export const HANDLE_WIN_MODAL = "HANDLE_WIN_MODAL";
export const HANDLE_WON_MODAL = "HANDLE_WON_MODAL";
export const HANDLE_CUSTOMER_MODAL = "HANDLE_CUSTOMER_MODAL";

export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAILURE = "GET_CATEGORY_FAILURE";

export const GET_PRODUCT_DATA_FOR_PARETO_CHART_BY_USER_ID_REQUEST =
  "GET_PRODUCT_DATA_FOR_PARETO_CHART_BY_USER_ID_REQUEST";
export const GET_PRODUCT_DATA_FOR_PARETO_CHART_BY_USER_ID_SUCCESS =
  "GET_PRODUCT_DATA_FOR_PARETO_CHART_BY_USER_ID_SUCCESS";
export const GET_PRODUCT_DATA_FOR_PARETO_CHART_BY_USER_ID_FAILURE =
  "GET_PRODUCT_DATA_FOR_PARETO_CHART_BY_ORG_ID_FAILURE";

export const GET_PRODUCT_DATA_FOR_CHARTS_REQUEST =
  "GET_PRODUCT_DATA_FOR_CHARTS_REQUEST";
export const GET_PRODUCT_DATA_FOR_CHARTS_SUCCESS =
  "GET_PRODUCT_DATA_FOR_CHARTS_SUCCESS";
export const GET_PRODUCT_DATA_FOR_CHARTS_FAILURE =
  "GET_PRODUCT_DATA_FOR_CHARTS_FAILURE";

export const GET_PRODUCT_CHART_BY_USER_ID_REQUEST =
  "GET_PRODUCT_CHART_BY_USER_ID_REQUEST";
export const GET_PRODUCT_CHART_BY_USER_ID_SUCCESS =
  "GET_PRODUCT_CHART_BY_USER_ID_SUCCESS";
export const GET_PRODUCT_CHART_BY_USER_ID_FAILURE =
  "GET_PRODUCT_CHART_BY_USER_ID_FAILURE";

export const INPUT_SEARCH_PRODUCT_DATA_REQUEST = "INPUT_SEARCH_PRODUCT_DATA_REQUEST";
export const INPUT_SEARCH_PRODUCT_DATA_SUCCESS = "INPUT_SEARCH_PRODUCT_DATA_SUCCESS";
export const INPUT_SEARCH_PRODUCT_DATA_FAILURE = "INPUT_SEARCH_PRODUCT_DATA_FAILURE";

export const HANDLE_DISCOUNT_BUTTON_MODAL = "HANDLE_DISCOUNT_BUTTON_MODAL";

export const GET_DISCOUNT_HISTORY_REQUEST = "GET_DISCOUNT_HISTORY_REQUEST";
export const GET_DISCOUNT_HISTORY_SUCCESS = "GET_DISCOUNT_HISTORY_SUCCESS";
export const GET_DISCOUNT_HISTORY_FAILURE = "GET_DISCOUNT_HISTORY_FAILURE";