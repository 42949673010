export const ADD_FEED_BACK_REQUEST = "ADD_FEED_BACK_REQUEST";
export const ADD_FEED_BACK_SUCCESS = "ADD_FEED_BACK_SUCCESS";
export const ADD_FEED_BACK_FAILURE = "ADD_FEED_BACK_FAILURE";

export const GET_FEED_BACK_REQUEST = "GET_FEED_BACK_REQUEST";
export const GET_FEED_BACK_SUCCESS = "GET_FEED_BACK_SUCCESS";
export const GET_FEED_BACK_FAILURE = "GET_FEED_BACK_FAILURE";

export const HANDLE_FEED_BACK_MODAL = "HANDLE_FEED_BACK_MODAL";
export const HANDLE_PARTICULAR_DATA = "HANDLE_PARTICULAR_DATA";
