import * as types from "./CustomReportActionTypes";

const initialState = {
  fetchingTableCustomReports: false,
  fetchingTableCustomReportsError: false,
  customReportsForTable: [],

  fetchingCustomReportsColumn: false,
  fetchingCustomReportsColumnError: false,
  customReportsForColumn: [],
};
export const customReport = (state = initialState, action) => {
  switch (action.type) {
    //Custom Reports for Table
    case types.GET_CUSTOM_TABLE_NAMES_REQUEST:
      return { ...state, fetchingTableCustomReports: true };
    case types.GET_CUSTOM_TABLE_NAMES_SUCCESS:
      return {
        ...state,
        fetchingTableCustomReports: false,
        customReportsForTable: action.payload,
      };
    case types.GET_CUSTOM_TABLE_NAMES_FAILURE:
      return {
        ...state,
        fetchingTableCustomReports: false,
        fetchingTableCustomReportsError: true,
      };
    //Custom Reports for columns
    case types.GET_CUSTOM_COLUMN_NAMES_REQUEST:
      return { ...state, fetchingCustomReportsColumn: true };
    case types.GET_CUSTOM_COLUMN_NAMES_SUCCESS:
      return {
        ...state,
        fetchingCustomReportsColumn: false,
        customReportsForColumn: action.payload,
      };
    case types.GET_CUSTOM_COLUMN_NAMES_FAILURE:
      return {
        ...state,
        fetchingCustomReportsColumn: false,
        fetchingCustomReportsColumnError: true,
      };

    default:
      return state;
  }
};
