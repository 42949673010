import * as types from "./ViewportActionTypes";
import moment from "moment";

const initialState = {
  viewportModal: false,
  addingViewportUser: false,
  addingViewportUserError: false,

  viewportDrawerVisible: false,
  viewportDrawerProps: {},

  sendingViewportNotification: false,
  sendingViewportNotificationError: false,

  fetchingAddedViewportUsers: false,
  fetchingAddedViewportUsersError: false,
  addedViewportUsers: [],

  deletingAddedViewportUser: false,
  deletingAddedViewportUserError: false,

  fetchingViewportUsers: false,
  fetchingViewportUsersError: false,
  viewportUsers: [],

  fetchingViewportUsersDeatails: false,
  fetchingViewportUsersDetailsError: false,
  viewportUsersDetails: {},

  fetchingViewPortFunnel: false,
  viewPortFunnel: {},
  fetchingViewPortFunnelError: false,

  fetchingViewPortFunnelForQuartor: false,
  fetchingViewPortFunnelForQuartorError: false,

  fetchingViewPortVelocity: false,
  fetchingViewPortVelocityError: false,
  viewPortVelocity: 0,

  fetchingViewPortWon: false,
  fetchingViewPortWonError: false,
  viewPortWon: {},

  fetchingViewPortAccount: false,
  fetchingViewPortAccountError: false,
  viewPortAccount: [],

  fetchingViewPortAccountRatio: false,
  fetchingViewPortAccountRatioError: false,
  viewportAccountRatio: [],

  fetchingViewPortStage: false,
  fetchingViewPortStageError: false,
  viewportStage: [],

  fetchingWinLossChartData: false,
  fetchingWinLossChartDataError: false,
  winLossChartData: [],

  selectedViewportUser: {},
  dateRangeList: [
    {
      id: 1,
      type: "year",
      value: "FY",
      starter: true,
      isSelected: true,
      startDate: moment()
        .startOf("year")
        .toISOString(),
      endDate: moment()
        .endOf("year")
        .toISOString(),
    },
    {
      id: 2,
      type: "quarter",
      value: "QTD",
      starter: false,
      isSelected: false,
      startDate: moment()
        .startOf("quarter")
        .toISOString(),
      endDate: moment()
        .endOf("quarter")
        .toISOString(),
    },
    {
      id: 3,
      type: "month",
      value: "MTD",
      starter: false,
      isSelected: false,
      startDate: moment()
        .startOf("month")
        .toISOString(),
      endDate: moment()
        .endOf("month")
        .toISOString(),
    },
    {
      id: 4,
      type: "week",
      value: "1W",
      starter: false,
      isSelected: false,
      startDate: moment()
        .startOf("week")
        .toISOString(),
      endDate: moment()
        .endOf("week")
        .toISOString(),
    },
  ],
  isCustomSelected: false,
  timeRangeType: "year",
  startDate: moment()
    .startOf("year")
    .toISOString(),
  endDate: moment()
    .endOf("year")
    .toISOString(),
};
const newDateRange = (dateRange, newDate) =>
  dateRange.map((range) => {
    if (range.id === newDate.id) {
      return { ...range, isSelected: true };
    } else {
      return { ...range, isSelected: false };
    }
  });
const mergeFiscalAndQuarter = (dateRange, newDate) =>
  dateRange.map((date) => {
    // let q1s = newDate.metaData.fiscalMapper.q1StartDate;
    // let q1e = newDate.metaData.fiscalMapper.q1EndDate;
    // let q2s = newDate.metaData.fiscalMapper.q2StartDate;
    // let q2e = newDate.metaData.fiscalMapper.q2EndDate;
    // let q3s = newDate.metaData.fiscalMapper.q3StartDate;
    // let q3e = newDate.metaData.fiscalMapper.q3EndDate;
    // let q4s = newDate.metaData.fiscalMapper.q4StartDate;
    // let q4e = newDate.metaData.fiscalMapper.q4EndDate;

    if (date.value === "QTD") {
      return {
        ...date,
        startDate:
          newDate.metaData.fiscalMapper.metaData.currentQuarterStartDate +
          "T00:00:00Z",
        endDate:
          newDate.metaData.fiscalMapper.metaData.currentQuarterEndDate +
          "T00:00:00Z",
      };
      // if (moment().isBetween(moment(q1s), moment(q1e))) {
      //   return { ...date, startDate: q1s, endDate: q1e };
      // }
      // if (moment().isBetween(moment(q2s), moment(q2e))) {
      //   return { ...date, startDate: q2s, endDate: q2e };
      // }
      // if (moment().isBetween(moment(q3s), moment(q3e))) {
      //   return { ...date, startDate: q3s, endDate: q3e };
      // }
      // if (moment().isBetween(moment(q4s), moment(q4e))) {
      //   return { ...date, startDate: q4s, endDate: q4e };
      // }
    } else if (date.value === "FY") {
      return {
        ...date,
        startDate: newDate.metaData.fiscalMapper.fiscalStartDate,
        endDate: newDate.metaData.fiscalMapper.fiscalEndDate,
      };
    } else {
      return date;
    }
  });
export const viewportReducer = (state = initialState, action) => {
  switch (action.type) {
    //FUNNEL CASE START
    case types.GET_VIEWPORT_FUNNEL_REQUEST:
      return {
        ...state,
        fetchingViewPortFunnel: true,
        fetchingDashboardFunnelError: false,
      };
    case types.GET_VIEWPORT_FUNNEL_SUCCESS:
      return {
        ...state,
        fetchingViewPortFunnel: false,
        fetchingViewPortFunnelError: false,
        viewPortFunnel: action.payload,
      };
    case types.GET_VIEWPORT_FUNNEL_FAILURE:
      return {
        ...state,
        fetchingViewPortFunnel: false,
        fetchingViewPortFunnelError: true,
      };

    case types.GET_VIEWPORT_FUNNEL_FOR_QUARTER_REQUEST:
      return {
        ...state,
        fetchingViewPortFunnelForQuartor: true,
        fetchingViewPortFunnelForQuartorError: false,
      };
    case types.GET_VIEWPORT_FUNNEL_FOR_QUARTER_SUCCESS:
      return {
        ...state,
        fetchingViewPortFunnelForQuartor: false,
        fetchingViewPortFunnelForQuartorError: false,
        viewPortFunnel: action.payload,
      };
    case types.GET_VIEWPORT_FUNNEL_FOR_QUARTER_FAILURE:
      return {
        ...state,
        fetchingViewPortFunnelForQuartor: false,
        fetchingViewPortFunnelForQuartorError: true,
      };
    //FUNNEL CASE END

    //SALES VELOCITY
    case types.GET_VIEWPORT_VELOCITY_REQUEST:
      return { ...state, fetchingViewPortVelocity: true };
    case types.GET_VIEWPORT_VELOCITY_SUCCESS:
      return {
        ...state,
        fetchingViewPortVelocity: false,
        viewPortVelocity: action.payload,
      };
    case types.GET_VIEWPORT_VELOCITY_FAILURE:
      return {
        ...state,
        fetchingViewPortVelocity: false,
        fetchingViewPortVelocityError: true,
      };
    //Won
    case types.GET_VIEWPORT_WON_REQUEST:
      return { ...state, fetchingViewPortWon: true };
    case types.GET_VIEWPORT_WON_SUCCESS:
      return {
        ...state,
        fetchingViewPortWon: false,
        viewPortWon: action.payload,
      };
    case types.GET_VIEWPORT_WON_FAILURE:
      return {
        ...state,
        fetchingViewPortWon: false,
        fetchingViewPortWonError: true,
      };

    //cusomer Added
    case types.GET_VIEWPORT_ACCOUNT_REQUEST:
      return { ...state, fetchingViewPortAccount: true };
    case types.GET_VIEWPORT_ACCOUNT_SUCCESS:
      return {
        ...state,
        fetchingViewPortAccount: false,
        viewPortAccount: action.payload,
      };
    case types.GET_VIEWPORT_ACCOUNT_FAILURE:
      return {
        ...state,
        fetchingViewPortAccount: false,
        fetchingViewPortAccountError: true,
      };

    //viewportAccountRatio
    case types.GET_VIEWPORT_ACCOUNT_RATIO_CHART_REQUEST:
      return { ...state, fetchingViewPortAccountRatio: true };
    case types.GET_VIEWPORT_ACCOUNT_RATIO_CHART_SUCCESS:
      return {
        ...state,
        fetchingViewPortAccountRatio: false,
        viewportAccountRatio: action.payload,
      };
    case types.GET_VIEWPORT_ACCOUNT_RATIO_CHART_FAILURE:
      return {
        ...state,
        fetchingViewPortAccountRatio: false,
        fetchingViewPortAccountRatioError: true,
      };

    //stage chart
    case types.GET_VIEWPORT_STAGE_CHART_REQUEST:
      return { ...state, fetchingViewPortStage: true };
    case types.GET_VIEWPORT_STAGE_CHART_SUCCESS:
      return {
        ...state,
        fetchingViewPortStage: false,
        viewPortStage: action.payload,
      };
    case types.GET_VIEWPORT_STAGE_CHART_FAILURE:
      return {
        ...state,
        fetchingViewPortStage: false,
        fetchingViewPortStageError: true,
      };
    //win loss chart
    case types.GET_VIEWPORT_WIN_LOSS_CHART_REQUEST:
      return { ...state, fetchingWinLossChartData: true };
    case types.GET_VIEWPORT_WIN_LOSS_CHART_SUCCESS:
      return {
        ...state,
        fetchingWinLossChartData: false,
        winLossChartData: action.payload,
      };
    case types.GET_VIEWPORT_WIN_LOSS_CHART_FAILURE:
      return {
        ...state,
        fetchingWinLossChartData: false,
        fetchingWinLossChartDataError: true,
      };

    case types.CHANGE_SELECTED_TIME_INTERVAL_VIEWPORT:
      return {
        ...state,
        dateRangeList: newDateRange(state.dateRangeList, action.payload),
        isCustomSelected: false,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        timeRangeType: action.payload.type,
      };
    case types.SET_TIME_INTERVAL_VIEWPORT:
      return {
        ...state,
        isCustomSelected: true,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    case types.SET_FISCAL_TIME_INTERVAL_VIEWPORT:
      //debugger;
      return {
        ...state,
        dateRangeList: mergeFiscalAndQuarter(
          state.dateRangeList,
          action.payload
        ),
        startDate: action.payload.metaData.fiscalMapper.fiscalStartDate,
        endDate: action.payload.metaData.fiscalMapper.fiscalEndDate,
      };
    case types.ADD_VIEWPORT_USER_REQUEST:
      return {
        ...state,
        sendingViewportNotification: true,
        sendingViewportNotificationError: false,
      };
    case types.ADD_VIEWPORT_USER_SUCCESS:
      return {
        ...state,
        sendingViewportNotification: false,
        sendingViewportNotificationError: false,
      };
    case types.ADD_VIEWPORT_USER_FAILURE:
      return {
        ...state,
        sendingViewportNotification: false,
        sendingViewportNotificationError: true,
      };

    case types.SEND_VIEWPORT_NOTIFICATION_REQUEST:
      return {
        ...state,
        addingViewportUser: true,
        addingViewportUserError: false,
      };
    case types.SEND_VIEWPORT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        addingViewportUser: false,
        addingViewportUserError: false,
      };
    case types.SEND_VIEWPORT_NOTIFICATION_FAILURE:
      return {
        ...state,
        addingViewportUser: false,
        addingViewportUserError: true,
      };

    case types.GET_ADDED_VIEWPORT_USERS_REQUEST:
      return {
        ...state,
        fetchingAddedViewportUsers: true,
        fetchingAddedViewportUsersError: false,
      };
    case types.GET_ADDED_VIEWPORT_USERS_SUCCESS:
      return {
        ...state,
        fetchingAddedViewportUsers: false,
        fetchingAddedViewportUsersError: false,
        addedViewportUsers: action.payload,
      };
    case types.GET_ADDED_VIEWPORT_USERS_FAILURE:
      return {
        ...state,
        fetchingAddedViewportUsers: false,
        fetchingAddedViewportUsersError: true,
      };

    case types.DELETE_ADDED_VIEWPORT_USER_REQUEST:
      return {
        ...state,
        deletingAddedViewportUser: true,
        deletingAddedViewportUserError: false,
      };
    case types.DELETE_ADDED_VIEWPORT_USER_SUCCESS:
      console.clear();
      console.log(action.payload);
      console.log(state);
      return {
        ...state,
        deletingAddedViewportUser: false,
        deletingAddedViewportUserError: false,
        addedViewportUsers: state.addedViewportUsers.filter(
          (user) => user.parent_organization_id === action.payload
        ),
      };
    case types.DELETE_ADDED_VIEWPORT_USER_FAILURE:
      return {
        ...state,
        deletingAddedViewportUser: false,
        deletingAddedViewportUserError: true,
      };

    case types.GET_VIEWPORT_USERS_REQUEST:
      return {
        ...state,
        fetchingViewportUsers: true,
        fetchingViewportUsersError: false,
      };
    case types.GET_VIEWPORT_USERS_SUCCESS:
      return {
        ...state,
        fetchingViewportUsers: false,
        fetchingViewportUsersError: false,
        viewportUsers: action.payload,
      };
    case types.GET_VIEWPORT_USERS_FAILURE:
      return {
        ...state,
        fetchingViewportUsers: false,
        fetchingViewportUsersError: true,
      };

    case types.GET_VIEWPORT_USERS_DETAILS_REQUEST:
      return {
        ...state,
        fetchingViewportUsersDetails: true,
        fetchingViewportUsersDetailsError: false,
      };
    case types.GET_VIEWPORT_USERS_DETAILS_SUCCESS:
      return {
        ...state,
        fetchingViewportUsersDetails: false,
        fetchingViewportUsersDetailsError: false,
        viewportUsersDetails: action.payload,
      };
    case types.GET_VIEWPORT_USERS_DETAILS_FAILURE:
      return {
        ...state,
        fetchingViewportUsersDetails: false,
        fetchingViewportUsersDetailsError: true,
      };

    case types.SET_SELECTED_VIEWPORT_USER:
      return { ...state, selectedViewportUser: action.payload };

    case types.HANDLE_VIEWPORT_MODAL:
      return { ...state, viewportModal: action.payload };

    case types.HANDLE_VIEWPORT_DRAWER:
      return {
        ...state,
        viewportDrawerVisible: action.payload,
        // viewportDrawerProps: action.payload.props,
      };
    default:
      return state;
  }
};
