import { stubTrue } from "lodash";
import * as types from "./AccountActionTypes";
const initialState = {
  accountFilterText: "",
  accountFilterUser: "all_user",
  viewType: "grid",
  addAccountModal: false,
  linkAccountModal: false,
  addingAccount: false,
  addingAccountError: false,
  fetchingAccountById: false,
  fetchingAccountByIdError: false,
  updateAccountById: false,
  updateAccountByIdError: false,
  addAbsoluteModal: false,
  addWinModal: false,
  addOppModal: false,
  addAccountOpportunityModal: false,

  linkProductModal: false,
  addLifetimeModal: false,
  addBarModal: false,
  addAccountTimelineModal: false,
  addLinkOrderConfigureModal: false,
  account: {},

  fetchingEnrichAccountByAccountId: false,
  fetchingEnrichAccountByAccountIdError: false,

  fetchingAccountListRangeByUserId: false,
  fetchingAccountListRangeByUserIdError: false,
  accountListRangeByUserId: [],

  addAccountSubscriptionConfigureModal: false,

  linkingPauseByAccountId: false,
  linkingPauseByAccountIdError: false,

  fetchingAccountMapOnDashboardByUserId: false,
  fetchingAccountMapOnDashboardByUserIdError: false,
  accountMapOnDashboardByUserId: [],

  fetchingAccountListRangeOfAllUsers: false,
  fetchingAccountListRangeOfAllUsersError: false,
  accountListRangeOfAllUsers: [],

  fetchingProductsByAccountId: false,
  fetchingProductsByAccountIdError: false,
  productsByAccountId: [],

  fetchingServicesByAccountId: false,
  fetchingServicesByAccountIdError: false,
  servicesByAccountId: [],

  fetchingContactListByAccountId: false,
  fetchingContactListByAccountIdError: false,
  contactListByAccountId: [],

  linkingContactsToAccountId: false,
  linkingContactsToAccountIdError: false,

  linkingRenewalByAccountId: false,
  linkingRenewalByAccountIdError: false,

  linkingProductToAccount: false,
  linkingProductToAccountError: false,

  fetchingAccountQuote: false,
  fetchingAccountQuoteError: false,
  accountQuote: [],


  fetchingAccountOrder: false,
  fetchingAccountOrderError: false,
  accountOrder: [],

  fetchingOpportunityListByAccountId: false,
  fetchingOpportunityListByAccountIdError: false,
  opportunityListByAccountId: [],

  linkingOpportunitiesToAccountId: false,
  linkingOpportunitiesToAccountIdError: false,

  fetchingFunnelValueByAccountId: false,
  fetchingFunnelValueByAccountIdError: false,
  funnelValue: {},

  fetchingNotesListByAccountId: false,
  fetchingNotesListByAccountIdError: false,
  notesListByAccountId: [],

  fetchingCallsListByAccountId: false,
  fetchingCallsListByAccountIdError: false,
  callListByAccountId: [],

  fetchingEventsListByAccountId: false,
  fetchingEventsListByAccountIdError: false,
  eventsListByAccountId: [],

  fetchingTasksListByAccountId: false,
  fetchingTasksListByAccountIdError: false,
  tasksListByAccountId: [],

  addingCompetitorsByAccountId: false,
  addingCompetitorsByAccountIdError: false,
  fetchingCompetitorsByAccountId: false,
  fetchingCompetitorsByAccountIdError: false,
  updatingCompetitorsByAccountId: false,
  updatingCompetitorsByAccountIdError: false,
  competitorsByAccountId: [],

  addingRenewalsAccountId: false,
  addingRenewalsAccountIdError: false,
  fetchingRenewalsAccountId: false,
  fetchingRenewalsAccountIdError: false,
  updatingRenewalsAccountId: false,
  updatingRenewalsAccountIdError: false,
  renewalsByAccountId: [],

  fetchingTopicsByAccountId: false,
  fetchingTopicsByAccountIdError: false,
  addingTopicsByAccountId: false,
  addingTopicsByAccountIdError: false,
  deletingTopicsByAccountId: false,
  deletingTopicsByAccountIdError: false,
  topicsByAccountId: [],

  fetchingAccounts: false,
  fetchingAccountsError: false,
  accounts: [],

  fetchingInitiativeWiseAccount: false,
  fetchingInitiativeWiseAccountError: false,

  fetchingCustomeAccounts: false,
  fetchingCustomeAccountsError: false,
  customeAccounts: [],


  fetchingLatestAccounts: false,
  fetchingLatestAccountsError: false,
  latestAccounts: [],

  fetchingAllLatestAccounts: false,
  fetchingAllLatestAccountsError: false,

  fetchingLatestAccountsByOrganizationId: false,
  fetchingLatestAccountsByOrganizationIdError: false,
  latestAccountsByOrganizationId: [],

  fetchingAccountsByFunnelValue: false,
  fetchingAccountsByFunnelValueError: false,
  accountsByFunnelValue: [],

  fetchingAllAccountsByFunnelValue: false,
  fetchingAllAccountsByFunnelValueError: false,

  linkingServiceToAccount: false,
  linkingServiceToAccountError: false,

  fetchingExistingAccounts: false,
  fetchingExistingAccountsError: false,
  existingAccounts: [],

  fetchingAllExistingAccounts: false,
  fetchingAllExistingAccountsError: false,

  fetchingTopTenAccounts: false,
  fetchingTopTenAccountsError: false,
  topTenAccounts: [],

  fetchingNewExistingAccounts: false,
  fetchingNewExistingAccountsError: false,
  newExistingAccounts: [],

  fetchingNewExistingAccountsOfAllUsers: false,
  fetchingNewExistingAccountsOfAllUsersError: false,
  newExistingAccountsOfAllUsers: [],

  fetchingNewExistingAccountsOfAllTeams: false,
  fetchingNewExistingAccountsOfAllTeamsError: false,
  newExistingAccountsOfAllTeams: [],

  fetchingNewExistingAccountsByTeamId: false,
  fetchingNewExistingAccountsByTeamIdError: false,
  newExistingAccountsByTeamId: [],

  clearbit: {},
  clearbitProduct: {},
  clearbitService: {},
  accountDrawerVisible: false,
  accountDrawerProps: {},

  linkingCheckContactsToAccountId: false,
  linkingCheckContactsToAccountIdError: false,

  fetchingTopicList: false,
  fetchingTopicListError: false,
  topicList: [],

  fetchingAllLatestAccountsForLazyLoading: false,
  fetchingAllLatestAccountsForLazyLoadingError: false,
  accountsForLazyLoading: [],

  fetchingTimeLine: false,
  fetchingTimeLineError: false,
  timeLineData: [],

  fetchingOpportunity: false,
  fetchingOpportunityError: false,
  opportunityData: [],

  fetchingAccountListRangeByUserIdForReport: false,
  fetchingAccountListRangeByUserIdForReportError: false,
  accountListRangeByUserIdForReport: [],

  fetchingAccountListRangeByOrgIdForReport: false,
  fetchingAccountListRangeByOrgIdForReportError: false,
  accountListRangeByOrgIdForReport: [],

  documentUploadModal: false,
  addRenewalButtonModal: false,
  addPauseButtonModal: false,

  generatingOrderByCustromerId: false,
  generatingOrderByCustromerIdError: false,

  updateEventModal: false,
  updateCallModal: false,
  updateTaskModal: false,

  fetchingOrder: false,
  fetchingOrderError: false,
  orderForGenerating: [],

  fetchingProductOrderDetailsById: false,
  fetchingProductOrderDetailsByIdError: false,
  productOrderByOrderId: [],

  fetchingServiceOrder: false,
  fetchingServiceOrderError: false,
  serviceOrderForGenerating: [],

  addingAccountOpportunity: false,
  addingAccountOpportunityError: false,


  fetchingFeedbackByOrderId: false,
  fetchingFeedbackByOrderIdError: true,
  orderFeedbacks: [],


  fetchingInputAccountData: false,
  fetchingInputAccountDataError: false,

  fetchingFeedbackByAccountId: false,
  fetchingFeedbackByAccountIdError: true,
  accountFeedbacks: [],

  fetchingFeedbackViewByAccountId: false,
  fetchingFeedbackViewByAccountIdError: true,
  accountViewFeedbacks: [],

  feedbackModal: false,


  fetchingAccountHistory: false,
  fetchingAccountHistoryError: false,
  AccountHistory: [],

  accountDashboardType: "ALL",

  //SHARE Account Permission of customer
  addSharingAccount: false,
  addSharingAccountError: false,

  fetchingPermissionsListAccount: false,
  fetchingPermissionsListAccountError: false,
  permissionsDataListAccount: [],
};
export const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ACCOUNT_TOPIC_LIST_REQUEST:
      return { ...state, fetchingTopicList: true };
    case types.GET_ACCOUNT_TOPIC_LIST_SUCCESS:
      return {
        ...state,
        fetchingTopicList: false,
        topicList: action.payload,
      };
    case types.GET_ACCOUNT_TOPIC_LIST_FAILURE:
      return {
        ...state,
        fetchingTopicList: false,
        fetchingTopicListError: true,
      };

    case types.GET_MAP_DASHBOARD_ACCOUNTS_REQUEST:
      return { ...state, fetchingAccountMapOnDashboardByUserId: true };
    case types.GET_MAP_DASHBOARD_ACCOUNTS_SUCCESS:
      return {
        ...state,
        fetchingAccountMapOnDashboardByUserId: false,
        accountMapOnDashboardByUserId: action.payload,
      };
    case types.GET_MAP_DASHBOARD_ACCOUNTS_FAILURE:
      return {
        ...state,
        fetchingAccountMapOnDashboardByUserId: false,
        fetchingAccountMapOnDashboardByUserIdError: true,
      };
    /**
     * get the list of all accounts
     */
    case types.GET_ACCOUNTS_REQUEST:
      return { ...state, fetchingAccounts: true };
    case types.GET_ACCOUNTS_SUCCESS:
      return { ...state, fetchingAccounts: false, accounts: action.payload };
    case types.GET_ACCOUNTS_FAILURE:
      return { ...state, fetchingAccounts: false, fetchingAccountsError: true };
    /**
     * get the list of all latest contacts
     */
    case types.GET_LATEST_ACCOUNTS_REQUEST:
      return { ...state, fetchingLatestAccounts: true };
    case types.GET_LATEST_ACCOUNTS_SUCCESS:
      return {
        ...state,
        fetchingLatestAccounts: false,
        latestAccounts: action.payload,
      };
    case types.GET_LATEST_ACCOUNTS_FAILURE:
      return {
        ...state,
        fetchingLatestAccounts: false,
        fetchingLatestAccountsError: true,
      };

    case types.GET_ALL_LATEST_ACCOUNTS_REQUEST:
      return { ...state, fetchingAllLatestAccounts: true };
    case types.GET_ALL_LATEST_ACCOUNTS_SUCCESS:
      return {
        ...state,
        fetchingAllLatestAccounts: false,
        latestAccounts: [...action.payload],
      };
    case types.GET_ALL_LATEST_ACCOUNTS_FAILURE:
      return {
        ...state,
        fetchingAllLatestAccounts: false,
        fetchingAllLatestAccountsError: true,
      };
    /**
     * get the list of all latest contacts
     */
    case types.GET_LATEST_ACCOUNTS_BY_ORGANIZATION_ID_REQUEST:
      return { ...state, fetchingLatestAccountsByOrganizationId: true };
    case types.GET_LATEST_ACCOUNTS_BY_ORGANIZATION_ID_SUCCESS:
      return {
        ...state,
        fetchingLatestAccountsByOrganizationId: false,
        latestAccountsByOrganizationId: action.payload,
      };
    case types.GET_LATEST_ACCOUNTS_BY_ORGANIZATION_ID_FAILURE:
      return {
        ...state,
        fetchingLatestAccountsByOrganizationId: false,
        fetchingLatestAccountsByOrganizationIdError: true,
      };

    /**
     * get the list of all contacts sort by funnel value
     */
    case types.GET_ACCOUNTS_BY_FUNNEL_VALUE_REQUEST:
      return { ...state, fetchingAccountsByFunnelValue: true };
    case types.GET_ACCOUNTS_BY_FUNNEL_VALUE_SUCCESS:
      return {
        ...state,
        fetchingAccountsByFunnelValue: false,
        accountsByFunnelValue: action.payload,
      };
    case types.GET_ACCOUNTS_BY_FUNNEL_VALUE_FAILURE:
      return {
        ...state,
        fetchingAccountsByFunnelValue: false,
        fetchingAccountsByFunnelValueError: true,
      };

    case types.GET_ALL_ACCOUNTS_BY_FUNNEL_VALUE_REQUEST:
      return { ...state, fetchingAllAccountsByFunnelValue: true };
    case types.GET_ALL_ACCOUNTS_BY_FUNNEL_VALUE_SUCCESS:
      return {
        ...state,
        fetchingAllAccountsByFunnelValue: false,
        accountsByFunnelValue: [...action.payload],
      };
    case types.GET_ALL_ACCOUNTS_BY_FUNNEL_VALUE_FAILURE:
      return {
        ...state,
        fetchingAllAccountsByFunnelValue: false,
        fetchingAllAccountsByFunnelValueError: true,
      };

    /**
     * get the list of all contacts sort by funnel value
     */
    case types.GET_EXISTING_ACCOUNTS_REQUEST:
      return { ...state, fetchingExistingAccounts: true };
    case types.GET_EXISTING_ACCOUNTS_SUCCESS:
      return {
        ...state,
        fetchingExistingAccounts: false,
        existingAccounts: action.payload,
      };
    case types.GET_EXISTING_ACCOUNTS_FAILURE:
      return {
        ...state,
        fetchingExistingAccounts: false,
        fetchingExistingAccountsError: true,
      };

    case types.GET_ALL_EXISTING_ACCOUNTS_REQUEST:
      return { ...state, fetchingAllExistingAccounts: true };
    case types.GET_ALL_EXISTING_ACCOUNTS_SUCCESS:
      return {
        ...state,
        fetchingAllExistingAccounts: false,
        existingAccounts: [...action.payload],
      };
    case types.GET_ALL_EXISTING_ACCOUNTS_FAILURE:
      return {
        ...state,
        fetchingAllExistingAccounts: false,
        fetchingAllExistingAccountsError: true,
      };

    /**
     * get the list top ten customers
     */
    case types.GET_TOP_TEN_ACCOUNTS_REQUEST:
      return { ...state, fetchingTopTenAccounts: true };
    case types.GET_TOP_TEN_ACCOUNTS_SUCCESS:
      return {
        ...state,
        fetchingTopTenAccounts: false,
        topTenAccounts: action.payload,
      };
    case types.GET_TOP_TEN_ACCOUNTS_FAILURE:
      return {
        ...state,
        fetchingTopTenAccounts: false,
        fetchingTopTenAccountsError: true,
      };

    /**
     * get new Existing customers
     */
    case types.GET_NEW_EXISTING_ACCOUNTS_REQUEST:
      return { ...state, fetchingNewExistingAccounts: true };
    case types.GET_NEW_EXISTING_ACCOUNTS_SUCCESS:
      return {
        ...state,
        fetchingNewExistingAccounts: false,
        newExistingAccounts: action.payload,
      };
    case types.GET_NEW_EXISTING_ACCOUNTS_FAILURE:
      return {
        ...state,
        fetchingNewExistingAccounts: false,
        fetchingNewExistingAccountsError: true,
      };

    /**
     * get new Existing customers of all teams
     */
    case types.GET_NEW_EXISTING_ACCOUNTS_OF_ALL_USERS_REQUEST:
      return { ...state, fetchingNewExistingAccountsOfAllUsers: true };
    case types.GET_NEW_EXISTING_ACCOUNTS_OF_ALL_USERS_SUCCESS:
      return {
        ...state,
        fetchingNewExistingAccountsOfAllUsers: false,
        newExistingAccountsOfAllUsers: action.payload,
      };
    case types.GET_NEW_EXISTING_ACCOUNTS_OF_ALL_USERS_FAILURE:
      return {
        ...state,
        fetchingNewExistingAccountsOfAllUsers: false,
        fetchingNewExistingAccountsOfAllUsersError: true,
      };

    /**
     * get new Existing customers of all teams
     */
    case types.GET_NEW_EXISTING_ACCOUNTS_OF_ALL_TEAMS_REQUEST:
      return { ...state, fetchingNewExistingAccountsOfAllTeams: true };
    case types.GET_NEW_EXISTING_ACCOUNTS_OF_ALL_TEAMS_SUCCESS:
      return {
        ...state,
        fetchingNewExistingAccountsOfAllTeams: false,
        newExistingAccountsOfAllTeams: action.payload,
      };
    case types.GET_NEW_EXISTING_ACCOUNTS_OF_ALL_TEAMS_FAILURE:
      return {
        ...state,
        fetchingNewExistingAccountsOfAllTeams: false,
        fetchingNewExistingAccountsOfAllTeamsError: true,
      };

    /**
     * get new Existing customers of a team
     */
    case types.GET_NEW_EXISTING_ACCOUNTS_BY_TEAM_ID_REQUEST:
      return { ...state, fetchingNewExistingAccountsByTeamId: true };
    case types.GET_NEW_EXISTING_ACCOUNTS_BY_TEAM_ID_SUCCESS:
      return {
        ...state,
        fetchingNewExistingAccountsByTeamId: false,
        newExistingAccountsByTeamId: action.payload,
      };
    case types.GET_NEW_EXISTING_ACCOUNTS_BY_TEAM_ID_FAILURE:
      return {
        ...state,
        fetchingNewExistingAccountsByTeamId: false,
        fetchingNewExistingAccountsByTeamIdError: true,
      };

    /**
     * get a single account by its ID
     */
    case types.GET_ACCOUNT_BY_ID_REQUEST:
      return { ...state, fetchingAccountById: true };
    case types.GET_ACCOUNT_BY_ID_SUCCESS:
      return { ...state, fetchingAccountById: false, account: action.payload };
    case types.GET_ACCOUNT_BY_ID_FAILURE:
      return {
        ...state,
        fetchingAccountById: false,
        fetchingAccountByIdError: true,
      };
    /**
     * update a single account by its ID
     */
    case types.UPDATE_ACCOUNT_BY_ID_REQUEST:
      return { ...state, updateAccountById: true };
    case types.UPDATE_ACCOUNT_BY_ID_SUCCESS:
      return { ...state, updateAccountById: false, account: action.payload };
    case types.UPDATE_ACCOUNT_BY_ID_FAILURE:
      return {
        ...state,
        updateAccountById: false,
        updateAccountByIdError: true,
      };

    /**
     * get list of accounts added by a user
     */
    case types.GET_ACCOUNT_LIST_RANGE_BY_USER_ID_REQUEST:
      return { ...state, fetchingAccountListRangeByUserId: true };
    case types.GET_ACCOUNT_LIST_RANGE_BY_USER_ID_SUCCESS:
      return {
        ...state,
        fetchingAccountListRangeByUserId: false,
        accountListRangeByUserId: action.payload,
      };
    case types.GET_ACCOUNT_LIST_RANGE_BY_USER_ID_FAILURE:
      return {
        ...state,
        fetchingAccountListRangeByUserId: false,
        fetchingAccountListRangeByUserIdError: true,
      };
    /**
     * get list of accounts added by all users of an organization
     */
    case types.GET_ACCOUNT_LIST_RANGE_OF_ALL_USERS_REQUEST:
      return { ...state, fetchingAccountListRangeOfAllUsers: true };
    case types.GET_ACCOUNT_LIST_RANGE_OF_ALL_USERS_SUCCESS:
      return {
        ...state,
        fetchingAccountListRangeOfAllUsers: false,
        accountListRangeOfAllUsers: action.payload,
      };
    case types.GET_ACCOUNT_LIST_RANGE_OF_ALL_USERS_FAILURE:
      return {
        ...state,
        fetchingAccountListRangeOfAllUsers: false,
        fetchingAccountListRangeOfAllUsersError: true,
      };

    /**
     * getcontact list by accountId
     */
    case types.GET_CONTACT_LIST_BY_ACCOUNT_ID_REQUEST:
      return { ...state, fetchingContactListByAccountId: true };
    case types.GET_CONTACT_LIST_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        fetchingContactListByAccountId: false,
        contactListByAccountId: action.payload,
      };
    case types.GET_CONTACT_LIST_BY_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        fetchingContactListByAccountId: false,
        fetchingContactListByAccountIdError: true,
      };
    /**
     * update contact list by accountId after unlinking an account
     */
    case types.UPDATE_CONTACT_LIST_BY_ACCOUNT_ID_REQUEST:
      return { ...state };
    case types.UPDATE_CONTACT_LIST_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        contactListByAccountId: state.contactListByAccountId.filter(
          (item) => item.contactId !== action.payload
        ),
      };
    case types.UPDATE_CONTACT_LIST_BY_ACCOUNT_ID_FAILURE:
      return { ...state };
    /**
     * link existing contacts to a account
     */
    case types.LINK_CONTACTS_TO_ACCOUNT_ID_REQUEST:
      return { ...state, linkingContactsToAccountId: true };
    case types.LINK_CONTACTS_TO_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        linkingContactsToAccountId: false,
        contactListByAccountId: [
          ...state.contactListByAccountId,
          ...action.payload,
        ],
      };
    case types.LINK_CONTACTS_TO_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        linkingContactsToAccountId: false,
        linkingContactsToAccountIdError: true,
      };
    /**
     * get opportunity list by accountId
     */
    case types.GET_OPPORTUNITY_LIST_BY_ACCOUNT_ID_REQUEST:
      return { ...state, fetchingOpportunityListByAccountId: true };
    case types.GET_OPPORTUNITY_LIST_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        fetchingOpportunityListByAccountId: false,
        opportunityListByAccountId: action.payload,
      };
    case types.GET_OPPORTUNITY_LIST_BY_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        fetchingOpportunityListByAccountId: false,
        fetchingOpportunityListByAccountIdError: true,
      };
    /**
     * update contact list by accountId after unlinking an account
     */
    case types.UPDATE_OPPORTUNITY_LIST_BY_ACCOUNT_ID_REQUEST:
      return { ...state };
    case types.UPDATE_OPPORTUNITY_LIST_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        opportunityListByAccountId: state.opportunityListByAccountId.filter(
          (item) => item.opportunityId !== action.payload
        ),
      };
    case types.UPDATE_OPPORTUNITY_LIST_BY_ACCOUNT_ID_FAILURE:
      return { ...state };

    /**
     * link existing contacts to a account
     */
    case types.LINK_OPPORTUNITIES_TO_ACCOUNT_ID_REQUEST:
      return { ...state, linkingOpportunitiesToAccountId: true };
    case types.LINK_OPPORTUNITIES_TO_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        linkingOpportunitiesToAccountId: false,
        opportunityListByAccountId: [
          ...state.opportunityListByAccountId,
          ...action.payload,
        ],
      };
    case types.LINK_OPPORTUNITIES_TO_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        linkingOpportunitiesToAccountId: false,
        linkingOpportunitiesToAccountIdError: true,
      };
    /**
     * get notes list by accountId
     */
    case types.GET_NOTES_LIST_BY_ACCOUNT_ID_REQUEST:
      return { ...state, fetchingNotesListByAccountId: true };
    case types.GET_NOTES_LIST_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        fetchingNotesListByAccountId: false,
        notesListByAccountId: action.payload,
      };
    case types.GET_NOTES_LIST_BY_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        fetchingNotesListByAccountId: false,
        fetchingNotesListByAccountIdError: true,
      };
    /**
     * update contact list by accountId after unlinking an account
     */
    case types.UPDATE_NOTES_LIST_BY_ACCOUNT_ID_REQUEST:
      return { ...state };
    case types.UPDATE_NOTES_LIST_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        notesListByAccountId: state.notesListByAccountId.filter(
          (item) => item.noteId !== action.payload
        ),
      };
    case types.UPDATE_NOTES_LIST_BY_ACCOUNT_ID_FAILURE:
      return { ...state };
    /**
     * get calls list by accountId
     */
    case types.GET_CALLS_LIST_BY_ACCOUNT_ID_REQUEST:
      return { ...state, fetchingCallsListByAccountId: true };
    case types.GET_CALLS_LIST_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        fetchingCallsListByAccountId: false,
        callsListByAccountId: action.payload,
      };
    case types.GET_CALLS_LIST_BY_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        fetchingCallsListByAccountId: false,
        fetchingCallsListByAccountIdError: true,
      };
    /**
     * update contact list by accountId after unlinking an account
     */
    case types.UPDATE_CALLS_LIST_BY_ACCOUNT_ID_REQUEST:
      return { ...state };
    case types.UPDATE_CALLS_LIST_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        callsListByAccountId: state.callsListByAccountId.filter(
          (item) => item.callId !== action.payload
        ),
      };
    case types.UPDATE_CALLS_LIST_BY_ACCOUNT_ID_FAILURE:
      return { ...state };
    /**
     * get events list by accountId
     */
    case types.GET_EVENTS_LIST_BY_ACCOUNT_ID_REQUEST:
      return { ...state, fetchingEventsListByAccountId: true };
    case types.GET_EVENTS_LIST_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        fetchingEventsListByAccountId: false,
        eventsListByAccountId: action.payload,
      };
    case types.GET_EVENTS_LIST_BY_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        fetchingEventsListByAccountId: false,
        fetchingEventsListByAccountIdError: true,
      };
    /**
     * update contact list by accountId after unlinking an account
     */
    case types.UPDATE_EVENTS_LIST_BY_ACCOUNT_ID_REQUEST:
      return { ...state };
    case types.UPDATE_EVENTS_LIST_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        eventsListByAccountId: state.eventsListByAccountId.filter(
          (item) => item.eventId !== action.payload
        ),
      };
    case types.UPDATE_EVENTS_LIST_BY_ACCOUNT_ID_FAILURE:
      return { ...state };
    /**
     * get tasks list by accountId
     */
    case types.GET_TASKS_LIST_BY_ACCOUNT_ID_REQUEST:
      return { ...state, fetchingTasksListByAccountId: true };
    case types.GET_TASKS_LIST_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        fetchingTasksListByAccountId: false,
        tasksListByAccountId: action.payload,
      };
    case types.GET_TASKS_LIST_BY_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        fetchingTasksListByAccountId: false,
        fetchingTasksListByAccountIdError: true,
      };
    /**
     * update contact list by accountId after unlinking an account
     */
    case types.UPDATE_TASKS_LIST_BY_ACCOUNT_ID_REQUEST:
      return { ...state };
    case types.UPDATE_TASKS_LIST_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        tasksListByAccountId: state.tasksListByAccountId.filter(
          (item) => item.taskId !== action.payload
        ),
      };
    case types.UPDATE_TASKS_LIST_BY_ACCOUNT_ID_FAILURE:
      return { ...state };
    /**
     * add competitor to an account
     */
    case types.ADD_COMPETITOR_BY_ACCOUNT_ID_REQUEST:
      return { ...state, addingCompetitorByAccountId: true };
    case types.ADD_COMPETITOR_BY_ACCOUNT_ID_SUCCESS:
      return { ...state, addingCompetitorByAccountId: false };
    case types.ADD_COMPETITOR_BY_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        addingCompetitorByAccountId: false,
        addingCompetitorByAccountIdError: true,
      };
    /**
     * get competitors list by accountId
     */
    case types.GET_COMPETITORS_BY_ACCOUNT_ID_REQUEST:
      return { ...state, fetchingCompetitorsByAccountId: true };
    case types.GET_COMPETITORS_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        fetchingCompetitorsByAccountId: false,
        competitorsByAccountId: action.payload,
      };
    case types.GET_COMPETITORS_BY_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        fetchingCompetitorsByAccountId: false,
        fetchingCompetitorsByAccountIdError: true,
      };
    /**
     * update competitors by accountId
     */
    case types.UPDATE_COMPETITORS_BY_ACCOUNT_ID_REQUEST:
      return { ...state };
    case types.UPDATE_COMPETITORS_BY_ACCOUNT_ID_SUCCESS:
      return { ...state };
    case types.UPDATE_COMPETITORS_BY_ACCOUNT_ID_FAILURE:
      return { ...state };
    /**
     * add competitor to an account
     */
    case types.ADD_RENEWAL_BY_ACCOUNT_ID_REQUEST:
      return { ...state, addingRenewalByAccountId: true };
    case types.ADD_RENEWAL_BY_ACCOUNT_ID_SUCCESS:
      return { ...state, addingRenewalByAccountId: false };
    case types.ADD_RENEWAL_BY_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        addingRenewalByAccountId: false,
        addingRenewalByAccountIdError: true,
      };
    /**
     * get renewals list by accountId
     */
    case types.GET_RENEWALS_BY_ACCOUNT_ID_REQUEST:
      return { ...state, fetchingRenewalsByAccountId: true };
    case types.GET_RENEWALS_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        fetchingRenewalsByAccountId: false,
        renewalsByAccountId: action.payload,
      };
    case types.GET_RENEWALS_BY_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        fetchingRenewalsByAccountId: false,
        fetchingRenewalsByAccountIdError: true,
      };
    /**
     * update renewals list by accountId
     */
    case types.UPDATE_RENEWALS_BY_ACCOUNT_ID_REQUEST:
      return { ...state };
    case types.UPDATE_RENEWALS_BY_ACCOUNT_ID_SUCCESS:
      return { ...state };
    case types.UPDATE_RENEWALS_BY_ACCOUNT_ID_FAILURE:
      return { ...state };

    /**
     * Add a account
     */
    case types.ADD_ACCOUNT_REQUEST:
      return { ...state, addingAccount: true };
    case types.ADD_ACCOUNT_SUCCESS:
      return {
        ...state,
        addingAccount: false,
        addAccountModal: false,
        clearbit: null,
      };
    case types.ADD_ACCOUNT_FAILURE:
      return {
        ...state,
        addingAccount: false,
        addingAccountError: true,
        addAccountModal: false,
      };

    case types.GET_TOPICS_BY_ACCOUNT_ID_REQUEST:
      return { ...state, fetchingTopicsByAccountId: true };
    case types.GET_TOPICS_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        fetchingTopicsByAccountId: false,
        topicsByAccountId: action.payload,
      };
    case types.GET_TOPICS_BY_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        fetchingTopicsByAccountId: false,
        fetchingTopicsByAccountIdError: true,
      };

    case types.ADD_TOPIC_BY_ACCOUNT_ID_REQUEST:
      return { ...state, addingTopicByAccountId: true };
    case types.ADD_TOPIC_BY_ACCOUNT_ID_SUCCESS:
      // console.clear()
      // console.log(action.payload)
      return {
        ...state,
        addingTopicByAccountId: false,
        topicsByAccountId: [...state.topicsByAccountId, action.payload],
      };
    case types.ADD_TOPIC_BY_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        addingTopicByAccountId: false,
        addingTopicByAccountIdError: true,
      };

    case types.DELETE_TOPIC_BY_ACCOUNT_ID_REQUEST:
      return { ...state, deletingTopicByAccountId: true };
    case types.DELETE_TOPIC_BY_ACCOUNT_ID_SUCCESS:
      return { ...state, deletingTopicByAccountId: false };
    case types.DELETE_TOPIC_BY_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        deletingTopicByAccountId: false,
        deletingTopicByAccountIdError: true,
      };

    /**
     * funnel amount by account Id
     */
    case types.FUNNEL_VALUE_BY_ACCOUNT_ID_REQUEST:
      return { ...state, fetchingFunnelValueByAccountId: true };
    case types.FUNNEL_VALUE_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        fetchingFunnelValueByAccountId: false,
        funnelValue: action.payload,
      };
    case types.FUNNEL_VALUE_BY_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        fetchingFunnelValueByAccountId: false,
        fetchingFunnelValueByAccountIdError: true,
      };

    /**
     * enrich contact by account Id
     */
    case types.ENRICH_ACCOUNT_REQUEST:
      return { ...state, fetchingEnrichAccountByAccountId: true };
    case types.ENRICH_ACCOUNT_SUCCESS:
      return {
        ...state,
        fetchingEnrichAccountByAccountId: false,
        account: { ...state.account, ...action.payload },
      };
    case types.ENRICH_ACCOUNT_FAILURE:
      return {
        ...state,
        fetchingEnrichAccountByAccountId: false,
        fetchingEnrichAccountByAccountIdError: true,
      };
    /**
     * handle Account form modal
     */
    case types.HANDLE_ACCOUNT_MODAL:
      return { ...state, addAccountModal: action.payload };
    /**
     * handle Account form modal
     */
    case types.HANDLE_LINK_ACCOUNT_MODAL:
      return { ...state, linkAccountModal: action.payload };
    /**
     * handle Account Drawer
     */
    case types.HANDLE_ACCOUNT_DRAWER:
      return {
        ...state,
        accountDrawerVisible: action.payload.isVisible,
        accountDrawerProps: action.payload.props,
      };
    /**
     * set company name , website and logo url from clearbit
     */
    case types.SET_CLEARBIT_DATA:
      return { ...state, clearbit: action.payload };

    case types.SET_CLEARBIT_PRODUCT_DATA:
      return { ...state, clearbitProduct: action.payload };

    case types.SET_CLEARBIT_SERVICE_DATA:
      return { ...state, clearbitService: action.payload };
    /**
     * set viewtype to grid or table
     */
    case types.SET_ACCOUNT_VIEW_TYPE:
      return { ...state, viewType: action.payload };

    /**
     * set account dashboard to ALl or individual account
     */
    case types.SET_ACCOUNT_DASHBOARD_TYPE:
      console.log(action.payload);
      return { ...state, accountDashboardType: action.payload };

    /**
     * set accountFilterText search
     */
    case types.SET_ACCOUNT_FILTER_TEXT:
      return { ...state, accountFilterText: action.payload };
    /**
     * set accountFilterUser search
     */
    case types.SET_ACCOUNT_FILTER_USER:
      return { ...state, accountFilterUser: action.payload };

    case types.UPDATE_ACCOUNT_ADDRESS:
      return {
        ...state,
        account: {
          ...state.account,
          address: state.account.address.map((item) => {
            if (item.addressId === action.payload.address.addressId) {
              return action.payload.address;
            } else {
              return item;
            }
          }),
        },
      };
    case types.ADD_ACCOUNT_ADDRESS:
      //////debugger;
      return {
        ...state,
        account: {
          ...state.account,
          address: action.payload.address,
        },
      };

    case types.LINK_CONTACTS_TO_CHECK_ACCOUNT_ID_REQUEST:
      return { ...state, linkingCheckContactsToAccountId: true };
    case types.LINK_CONTACTS_TO_CHECK_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        linkingCheckContactsToAccountId: false,
      };
    case types.LINK_CONTACTS_TO_CHECK_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        linkingCheckContactsToAccountId: false,
        linkingCheckContactsToAccountIdError: true,
      };

    case types.HANDLE_ABSOLUTE_MODAL:
      return { ...state, addAbsoluteModal: action.payload };

    case types.HANDLE_WIN_MODAL:
      return { ...state, addWinModal: action.payload };

    case types.HANDLE_OPP_MODAL:
      return { ...state, addOppModal: action.payload };

    case types.HANDLE_LIFETIME_MODAL:
      return { ...state, addLifetimeModal: action.payload };

    //FILTER ACCOUNT
    case types.GET_INITIATIVE_WISE_ACCOUNT_REQUEST:
      return { ...state, fetchingInitiativeWiseAccount: true };
    case types.GET_INITIATIVE_WISE_ACCOUNT_SUCCESS:
      return {
        ...state,
        fetchingInitiativeWiseAccount: false,
        accounts: action.payload,
      };

    case types.GET_INITIATIVE_WISE_ACCOUNT_FAILURE:
      return {
        ...state,
        fetchingInitiativeWiseAccount: false,
        fetchingInitiativeWiseAccountError: true,
      };

    case types.HANDLE_BAR_MODAL:
      return { ...state, addBarModal: action.payload };

    case types.HANDLE_ACCOUNT_TIMELINE_MODAL:
      return { ...state, addAccountTimelineModal: action.payload };

    //ACCOUNT REPORTS LAZY LOADING
    case types.GET_ALL_LATEST_ACCOUNTS_FOR_LAZY_LOADING_REQUEST:
      return { ...state, fetchingAllLatestAccountsForLazyLoading: true };
    case types.GET_ALL_LATEST_ACCOUNTS_FOR_LAZY_LOADING_SUCCESS:
      return {
        ...state,
        fetchingAllLatestAccountsForLazyLoading: false,
        accountsForLazyLoading: [...action.payload],
      };
    case types.GET_ALL_LATEST_ACCOUNTS_FOR_LAZY_LOADING_FAILURE:
      return {
        ...state,
        fetchingAllLatestAccountsForLazyLoading: false,
        fetchingAllLatestAccountsForLazyLoadingError: true,
      };

    //Time line data
    case types.GET_TIMELINE_DATA_BY_ACCOUNT_ID_REQUEST:
      return { ...state, fetchingTimeLine: true };
    case types.GET_TIMELINE_DATA_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        fetchingTimeLine: false,
        timeLineData: action.payload,
      };

    case types.GET_TIMELINE_DATA_BY_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        fetchingTimeLine: false,
        fetchingTimeLineError: true,
      };

    case types.GET_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST:
      return { ...state, fetchingAccountListRangeByUserIdForReport: true };
    case types.GET_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS:
      return {
        ...state,
        fetchingAccountListRangeByUserIdForReport: false,
        accountListRangeByUserIdForReport: action.payload,
      };
    case types.GET_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE:
      return {
        ...state,
        fetchingAccountListRangeByUserIdForReport: false,
        fetchingAccountListRangeByUserIdForReportError: true,
      };

    case types.GET_ACCOUNT_LIST_RANGE_BY_ORG_ID_FOR_REPORT_REQUEST:
      return { ...state, fetchingAccountListRangeByOrgIdForReport: true };
    case types.GET_ACCOUNT_LIST_RANGE_BY_ORG_ID_FOR_REPORT_SUCCESS:
      return {
        ...state,
        fetchingAccountListRangeByOrgIdForReport: false,
        accountListRangeByOrgIdForReport: action.payload,
      };
    case types.GET_ACCOUNT_LIST_RANGE_BY_ORG_ID_FOR_REPORT_FAILURE:
      return {
        ...state,
        fetchingAccountListRangeByOrgIdForReport: false,
        fetchingAccountListRangeByOrgIdForReportError: true,
      };

    case types.GET_ACCOUNT_QUOTE_REQUEST:
      return { ...state, fetchingAccountQuote: true };
    case types.GET_ACCOUNT_QUOTE_SUCCESS:
      return {
        ...state,
        fetchingAccountQuote: false,
        accountQuote: action.payload,
      };
    case types.GET_ACCOUNT_QUOTE_FAILURE:
      return {
        ...state,
        fetchingAccountQuote: false,
        fetchingAccountQuoteError: true,
      };

    // Order

    case types.GET_ACCOUNT_ORDER_REQUEST:
      return { ...state, fetchingAccountOrder: true };
    case types.GET_ACCOUNT_ORDER_SUCCESS:
      return {
        ...state,
        fetchingAccountOrder: false,
        accountOrder: action.payload,
      };
    case types.GET_ACCOUNT_ORDER_FAILURE:
      return {
        ...state,
        fetchingAccountOrder: false,
        fetchingAccountOrderError: true,
      };

    case types.SUSPEND_ACCOUNT_REQUSET:
      return { ...state, suspendAccount: true };
    case types.SUSPEND_ACCOUNT_SUCCESS:
      return {
        ...state,
        suspendAccount: false,
        suspend: action.payload,
      };
    case types.SUSPEND_ACCOUNT_FAILURE:
      return {
        ...state,
        suspendAccount: false,
        suspendAccountError: true,
      };
    // order

    case types.HANDLE_LINK_ORDER_CONFIGURE_MODAL:
      return { ...state, addLinkOrderConfigureModal: action.payload };

    case types.HANDLE_DOCUMENT_UPLOAD_MODAL:
      return { ...state, documentUploadModal: action.payload };

    case types.HANDLE_ACCOUNT_SUBSCRIPTION_CONFIGURE_MODAL:
      return { ...state, addAccountSubscriptionConfigureModal: action.payload };

    case types.FETCHING_PROFESSIONALDUCTS_BY_ACCOUNT_ID_REQUEST:
      return {
        ...state,
        fetchingProductsByAccountId: true,
        fetchingProductsByAccountIdError: false,
      };
    case types.FETCHING_PROFESSIONALDUCTS_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        fetchingProductsByAccountId: false,
        fetchingProductsByAccountIdError: false,
        productsByAccountId: action.payload,
      };
    case types.FETCHING_PROFESSIONALDUCTS_BY_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        fetchingProductsByAccountId: false,
        fetchingProductsByAccountIdError: true,
      };

    case types.FETCHING_PROFESSIONAL_SERVICE_BY_ACCOUNT_ID_REQUEST:
      return {
        ...state,
        fetchingServicesByAccountId: true,
        fetchingServicesByAccountIdError: false,
      };
    case types.FETCHING_PROFESSIONAL_SERVICE_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        fetchingServicesByAccountId: false,
        fetchingServicesByAccountIdError: false,
        servicesByAccountId: action.payload,
      };
    case types.FETCHING_PROFESSIONAL_SERVICE_BY_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        fetchingServicesByAccountId: false,
        fetchingServicesByAccountIdError: true,
      };

    case types.LINK_PROFESSIONALDUCT_TO_ACCOUNT_REQUEST:
      return {
        ...state,
        linkingProductToAccount: true,
      };
    case types.LINK_PROFESSIONALDUCT_TO_ACCOUNT_SUCCESS:
      return {
        ...state,
        linkingProductToAccount: false,
        addLinkOrderConfigureModal: false,
      };
    case types.LINK_PROFESSIONALDUCT_TO_ACCOUNT_FAILURE:
      return {
        ...state,
        linkingProductToAccount: false,
        linkingProductToAccountError: true,
        addLinkOrderConfigureModal: false,
      };


    case types.LINK_SERVICE_TO_ACCOUNT_REQUEST:
      return {
        ...state,
        linkingServiceToAccount: true,
      };
    case types.LINK_SERVICE_TO_ACCOUNT_SUCCESS:
      return {
        ...state,
        linkingServiceToAccount: false,
        addLinkOrderConfigureModal: false,
      };
    case types.LINK_SERVICE_TO_ACCOUNT_FAILURE:
      return {
        ...state,
        linkingServiceToAccount: false,
        linkingServiceToAccountError: true,
        addLinkOrderConfigureModal: false,
      };

    case types.GET_CUSTOME_MODULE_ACCOUNTS_REQUEST:
      return { ...state, fetchingCustomeAccounts: true };
    case types.GET_CUSTOME_MODULE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        fetchingCustomeAccounts: false,
        customeAccounts: action.payload,
      };
    case types.GET_CUSTOME_MODULE_ACCOUNTS_FAILURE:
      return {
        ...state,
        fetchingCustomeAccounts: false,
        fetchingCustomeAccountsError: true,
      };

    /**
          * update event modal
          */
    case types.HANDLE_UPDATE_EVENT_MODAL:
      return { ...state, updateEventModal: action.payload };

    /**
     * update call modal
     */
    case types.HANDLE_UPDATE_CALL_MODAL:
      return { ...state, updateCallModal: action.payload };

    /**
     * update task modal
     */
    case types.HANDLE_UPDATE_TASK_MODAL:
      return { ...state, updateTaskModal: action.payload };

    case types.FETCHING_NEW_ORDER_LIST_REQUEST:
      return {
        ...state,
        fetchingOrder: true,
      };
    case types.FETCHING_NEW_ORDER_LIST_SUCCESS:
      return {
        ...state,
        fetchingOrder: false,
        orderForGenerating: action.payload,
      };
    case types.FETCHING_NEW_ORDER_LIST_FAILURE:
      return {
        ...state,
        fetchingOrder: false,
        fetchingOrderError: true,
      };

    /**
   * generate order with subscription
   */

    case types.GENERATE_ORDER_BY_CUSTOMER_ID_REQUEST:
      return {
        ...state,
        generatingOrderByCustromerId: true,
      };
    case types.GENERATE_ORDER_BY_CUSTOMER_ID_SUCCESS:
      return {
        ...state,
        generatingOrderByCustromerId: false,
        addAccountSubscriptionConfigureModal: false,
      };
    case types.GENERATE_ORDER_BY_CUSTOMER_ID_FAILURE:
      return {
        ...state,
        generatingOrderByCustromerId: false,
        generatingOrderByCustromerIdError: true,
        addAccountSubscriptionConfigureModal: false,
      };

    case types.FETCHING_NEW_ORDER_SERVICE_LIST_REQUEST:
      return {
        ...state,
        fetchingServiceOrder: true,
      };
    case types.FETCHING_NEW_ORDER_SERVICE_LIST_SUCCESS:
      return {
        ...state,
        fetchingServiceOrder: false,
        serviceOrderForGenerating: action.payload,
      };
    case types.FETCHING_NEW_ORDER_SERVICE_LIST_FAILURE:
      return {
        ...state,
        fetchingServiceOrder: false,
        fetchingServiceOrderError: true,
      };
    /**
      * renewal modal
      */
    case types.HANDLE_RENEWAL_BUTTON_MODAL:
      return { ...state, addRenewalButtonModal: action.payload };

    /**
     * Pause modal
     */
    case types.HANDLE_PAUSE_BUTTON_MODAL:
      return { ...state, addPauseButtonModal: action.payload }

    case types.HANDLE_ACCOUNT_OPPORTUNITY_MODAL:
      return { ...state, addAccountOpportunityModal: action.payload };

    /**
     * Add a opportunity
     */
    case types.ADD_ACCOUNT_OPPORTUNITY_REQUEST:
      return { ...state, addingAccountOpportunity: true };
    case types.ADD_ACCOUNT_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        addingAccountOpportunity: false,
        addAccountOpportunityModal: false,
        clearbit: null,
      };
    case types.ADD_ACCOUNT_OPPORTUNITY_FAILURE:
      return {
        ...state,
        addingAccountOpportunity: false,
        addingAccountOpportunityError: true,
        addAccountOpportunityModal: false,
      };

    case types.FETCHING_OPPORTUNITY_REQUEST:
      return {
        ...state,
        fetchingOpportunity: true,
      };
    case types.FETCHING_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        fetchingOpportunity: false,
        opportunityData: action.payload,
      };
    case types.FETCHING_OPPORTUNITY_FAILURE:
      return {
        ...state,
        fetchingOpportunity: false,
        fetchingOpportunityError: true,
      };

    case types.GET_PRODUCT_ORDER_DETAILS_BY_ID_REQUEST:
      return { ...state, fetchingProductOrderDetailsById: true };
    case types.GET_PRODUCT_ORDER_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        fetchingProductOrderDetailsById: false,
        productOrderByOrderId: action.payload,
      };
    case types.GET_PRODUCT_ORDER_DETAILS_BY_ID_FAILURE:
      return {
        ...state,
        fetchingProductOrderDetailsById: false,
        fetchingProductOrderDetailsByIdError: true,
      };

    /**
     * fetching customer name
     */

    case types.INPUT_SEARCH_ACCOUNT_DATA_REQUEST:
      return { ...state, fetchingInputAccountData: true };
    case types.INPUT_SEARCH_ACCOUNT_DATA_SUCCESS:
      return {
        ...state,
        fetchingInputAccountData: false,
        accounts: action.payload,
      };
    case types.INPUT_SEARCH_ACCOUNT_DATA_FAILURE:
      return { ...state, fetchingInputAccountDataError: true };

    /**
    * post pause form
    */

    case types.LINK_PAUSE_BY_ACCOUNT_ID_REQUEST:
      return {
        ...state,
        linkingPauseByAccountId: true,
      };
    case types.LINK_PAUSE_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        linkingPauseByAccountId: false,
        addPauseButtonModal: false,
        accountOrder: state.accountOrder.map((item) => {
          if (
            item.customerId ==
            action.payload.customerId
          ) {
            return action.payload;
          } else {
            return item;
          }
        }),
      };
    case types.LINK_PAUSE_BY_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        linkingPauseByAccountId: false,
        linkingPauseByAccountIdError: true,
        addPauseButtonModal: false,
      };

    /**
    * post renwal form
    */

    case types.LINK_RENEWAL_BY_ACCOUNT_ID_REQUEST:
      return {
        ...state,
        linkingRenewalByAccountId: true,
      };
    case types.LINK_RENEWAL_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        linkingRenewalByAccountId: false,
        addRenewalButtonModal: false,
        accountOrder: state.accountOrder.map((item) => {
          if (
            item.customerId ==
            action.payload.customerId
          ) {
            return action.payload;
          } else {
            return item;
          }
        }),
      };
    case types.LINK_RENEWAL_BY_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        linkingRenewalByAccountId: false,
        linkingRenewalByAccountIdError: true,
        addRenewalButtonModal: false,
      };

    /**
* Customer order Feedback
*/

    case types.GET_FEEDBACK_BY_ORDER_ID_REQUEST:
      return { ...state, fetchingFeedbackByOrderId: true };
    case types.GET_FEEDBACK_BY_ORDER_ID_SUCCESS:
      return {
        ...state,
        fetchingFeedbackByOrderId: false,
        orderFeedbacks: action.payload,
      };
    case types.GET_FEEDBACK_BY_ORDER_ID_FAILURE:
      return {
        ...state,
        fetchingFeedbackByOrderId: false,
        fetchingFeedbackByOrderIdError: true,
      };

    /**
     *  Customer Table Feedback
     */

    case types.GET_FEEDBACK_BY_ACCOUNT_ID_REQUEST:
      return { ...state, fetchingFeedbackByAccountId: true };
    case types.GET_FEEDBACK_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        fetchingFeedbackByAccountId: false,
        accountFeedbacks: action.payload,
      };
    case types.GET_FEEDBACK_BY_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        fetchingFeedbackByAccountId: false,
        fetchingFeedbackByAccountIdError: true,
      };

    /**
*  Customer View Feedback
*/

    case types.GET_FEEDBACK_BY_ACCOUNT_VIEW_ID_REQUEST:
      return { ...state, fetchingFeedbackViewByAccountId: true };
    case types.GET_FEEDBACK_BY_ACCOUNT_VIEW_ID_SUCCESS:
      return {
        ...state,
        fetchingFeedbackViewByAccountId: false,
        accountViewFeedbacks: action.payload,
      };
    case types.GET_FEEDBACK_BY_ACCOUNT_VIEW_ID_FAILURE:
      return {
        ...state,
        fetchingFeedbackViewByAccountId: false,
        fetchingFeedbackViewByAccountIdError: true,
      };

    // * feedback-card modal
    // */
    case types.HANDLE_FEEDBACK_MODAL:
      return { ...state, feedbackModal: action.payload };

    /**
* get history of account
*/
    case types.GET_ACCOUNT_HISTORY_REQUEST:
      return { ...state, fetchingAccountHistory: true };
    case types.GET_ACCOUNT_HISTORY_SUCCESS:
      return {
        ...state,
        fetchingAccountHistory: false,
        accountHistory: action.payload,
      };
    case types.GET_ACCOUNT_HISTORY_FAILURE:
      return {
        ...state,
        fetchingAccountHistory: false,
        fetchingAccountHistoryError: true,
      };

    //SHARE Contact Customer Permissiom
    case types.ADD_SHARE_ACCOUNT_PERMISSION_REQUEST:
      return { ...state, addSharingAccount: true };

    case types.ADD_SHARE_ACCOUNT_PERMISSION_SUCCESS:
      return { ...state, addSharingAccount: false, accountByUserId: action.payload };

    case types.ADD_SHARE_ACCOUNT_PERMISSION_FAILURE:
      return {
        ...state,
        addSharingAccount: false,
        addSharingAccountError: true,
      };

    case types.GET_PERMISSIONS_LIST_ACCOUNT_REQUEST:
      return { ...state, fetchingPermissionsListAccount: true };
    case types.GET_PERMISSIONS_LIST_ACCOUNT_SUCCESS:
      return {
        ...state,
        fetchingPermissionsListAccount: false,
        permissionsDataListAccount: action.payload,
      };
    case types.GET_PERMISSIONS_LIST_ACCOUNT_FAILURE:
      return {
        ...state,
        fetchingPermissionsListAccount: false,
        fetchingPermissionsListAccountError: false,
      };
    default:
      return state;
  }
};

