export const ADD_CALL_REQUEST = "ADD_CALL_REQUEST";
export const ADD_CALL_SUCCESS = "ADD_CALL_SUCCESS";
export const ADD_CALL_FAILURE = "ADD_CALL_FAILURE";

export const DELETE_CALL_REQUEST = "DELETE_CALL_REQUEST";
export const DELETE_CALL_SUCCESS = "DELETE_CALL_SUCCESS";
export const DELETE_CALL_FAILURE = "DELETE_CALL_FAILURE";

export const UPDATE_CALL_BY_ID_REQUEST = "UPDATE_CALL_BY_ID_REQUEST";
export const UPDATE_CALL_BY_ID_SUCCESS = "UPDATE_CALL_BY_ID_SUCCESS";
export const UPDATE_CALL_BY_ID_FAILURE = "UPDATE_CALL_BY_ID_FAILURE";

export const GET_CALL_LIST_RANGE_BY_USER_ID_REQUEST =
  "GET_CALL_LIST_RANGE_BY_USER_ID_REQUEST";
export const GET_CALL_LIST_RANGE_BY_USER_ID_SUCCESS =
  "GET_CALL_LIST_RANGE_BY_USER_ID_SUCCESS";
export const GET_CALL_LIST_RANGE_BY_USER_ID_FAILURE =
  "GET_CALL_LIST_RANGE_BY_USER_ID_FAILURE";

export const GET_CALL_LIST_RANGE_OF_ALL_USERS_REQUEST =
  "GET_CALL_LIST_RANGE_OF_ALL_USERS_REQUEST";
export const GET_CALL_LIST_RANGE_OF_ALL_USERS_SUCCESS =
  "GET_CALL_LIST_RANGE_OF_ALL_USERS_SUCCESS";
export const GET_CALL_LIST_RANGE_OF_ALL_USERS_FAILURE =
  "GET_CALL_LIST_RANGE_OF_ALL_USERS_FAILURE";

export const HANDLE_CALL_MODAL = "HANDLE_CALL_MODAL";
export const HANDLE_TIME_ZONE_MODAL = "HANDLE_TIME_ZONE_MODAL";
export const HANDLE_NOTE_MODAL = "HANDLE_NOTE_MODAL";

export const GET_NOTES_LIST_BY_CALL_ID_REQUEST =
  "GET_NOTES_LIST_BY_CALL_ID_REQUEST";
export const GET_NOTES_LIST_BY_CALL_ID_SUCCESS =
  "GET_NOTES_LIST_BY_CALL_ID_SUCCESS";
export const GET_NOTES_LIST_BY_CALL_ID_FAILURE =
  "GET_NOTES_LIST_BY_CALL_ID_FAILURE";

export const SET_NOTE_EDIT = "SET_NOTE_EDIT";

export const GET_CALL_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST =
  "GET_CALL_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST";
export const GET_CALL_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS =
  "GET_CALL_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS";
export const GET_CALL_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE =
  "GET_CALL_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE";

export const GET_CALLS_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST =
  "GET_CALLS_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST";
export const GET_CALLS_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS =
  "GET_CALLS_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS";
export const GET_CALLS_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE =
  "GET_CALLS_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE";
