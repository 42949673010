import * as types from "./TeamActionTypes";

const initialState = {
  teamFilterText: "",
  filterByUserOption: "",
  viewType: "grid",
  addUserModal: false,
  setGoalModal: false,

  addLocationModal: false,
  addingUser: false,
  addingUserError: false,
  fetchingUser: false,
  fetchingUserError: false,
  updatingUserById: false,
  updatingUserByIdError: false,
  deletingUserById: false,
  deletingUserByIdError: false,

  user: {},

  fetchingLatestUsers: false,
  fetchingLatestUsersError: false,
  latestUsers: [],

  fetchingUsers: false,
  fetchingUsersError: false,
  users: [],

  fetchingOnlySalesUsers: false,
  fetchingOnlySalesUsersError: false,
  onlySalesUsers: [],

  fetchingSalesUsers: false,
  fetchingSalestUsersError: false,
  salesUsers: [],

  //get department
  fetchingDepartment: false,
  fetchingDepartmentError: false,
  department: [],

  // fething delivey user

  fetchingDeliveryUsers: false,
  fetchingDeliveryUsersError: false,
  deliveryUsers: [],

  // fething finance user

  fetchingFinanceUsers: false,
  fetchingFinanceUsersError: false,
  financeUsers: [],

  //fetching legal user
  fetchingLegalUsers: false,
  fetchingLegalUsersError: false,
  legalUsers: [],

  //fetching riskManagement user
  fetchingRiskManagementUsers: false,
  fetchingRiskManagementUsersError: false,
  riskManagementUsers: [],

  //fetching partner user
  fetchingPartnerUsers: false,
  fetchingPartnerUsersError: false,
  partnerUsers: [],

  //fetching partner user
  fetchingCustomeUsers: false,
  fetchingCustomeUsersError: false,
  customeUsers: [],

  //fetching reports to user
  fetchingReportsToUsers: false,
  fetchingReportsToUsersError: false,
  reportsToUsers: [],

  fetchingAccountListByUserId: false,
  fetchingAccountListByUserIdError: false,
  accountListByUserId: [],

  fetchingContactListByUserId: false,
  fetchingContactListByUserIdError: false,
  contactListByUserId: [],

  fetchingOpportunityListByUserId: false,
  fetchingOpportunityListByUserIdError: false,
  opportunityListByUserId: [],

  fetchingNotesListByUserId: false,
  fetchingNotesListByUserIdError: false,
  notesListByUserId: [],

  fetchingCallsListByUserId: false,
  fetchingCallsListByUserIdError: false,
  callsListByUserId: [],

  fetchingEventsListByUserId: false,
  fetchingEventsListByUserIdError: false,
  eventsListByUserId: [],

  fetchingTasksListByUserId: false,
  fetchingTasksListByUserIdError: false,
  tasksListByUserId: [],

  fetchingBilling: false,
  fetchingBillingError: false,
  billings: [],

  fetchingTopicsByUserId: false,
  fetchingTopicsByUserIdError: false,
  addingTopicsByUserId: false,
  addingTopicsByUserIdError: false,
  deletingTopicsByUserId: false,
  deletingTopicsByUserIdError: false,
  topicsByUserId: [],

  fetchingTopicsByOrganizationId: false,
  fetchingTopicsByOrganizationIdError: false,
  addingTopicsByOrganizationId: false,
  addingTopicsByOrganizationIdError: false,
  deletingTopicsByOrganizationId: false,
  deletingTopicsByOrganizationIdError: false,
  topicsByOrganizationId: [],

  enableSmartBoostRequest: false,
  enableSmartBoostRequestError: false,

  enableSmartBoostForAllRequest: false,
  enableSmartBoostForAllRequestError: false,

  disableSmartBoostRequest: false,
  disableSmartBoostRequestError: false,

  enableProductRequest: false,
  enableProductRequestError: false,

  enableProductForAllRequest: false,
  enableProductForAllRequestError: false,

  disableProductRequest: false,
  disableProductRequestError: false,

  enableViewportRequest: false,
  enableViewportRequestError: false,

  disableViewportRequest: false,
  disableViewportRequestError: false,

  //enable/disble delivery

  enableDeliveryRequest: false,
  enableDeliveryRequestError: false,

  disableDeliveryRequest: false,
  disableDeliveryRequestError: false,

  //enable/disble finance

  enableFinanceRequest: false,
  enableFinanceRequestError: false,

  disableFinanceRequest: false,
  disableFinanceRequestError: false,

  //enable/disble legal

  enableLegalRequest: false,
  enableLegalRequestError: false,

  disableLegalRequest: false,
  disableLegalRequestError: false,

  //enable/disble riskmanagement

  enableRiskManagementRequest: false,
  enableRiskManagementRequestError: false,

  disableRiskManagementRequest: false,
  disableRiskManagementRequestError: false,

  //enable/disble CustomeModule

  enableCustomeModuleRequest: false,
  enableCustomeModuleRequestError: false,

  teamDrawerVisible: false,
  teamDrawerVisibleForAdmin: false,
  teamDrawerProps: {},

  updatingLevel: false,
  updatingLevelError: false,

  fetchingCustomDepartment: false,
  fetchingCustomDepartmentError: false,
  customerDepartment: [],

  //enable/disable Recruitment
  enableRecruitmentRequest: false,
  enableRecruitmentRequestError: false,

  disableRecruitmentRequest: false,
  disableRecruitmentRequestError: false,

  //enable Marketing
  enableMarketingRequest: false,
  enableMarketingRequestError: false,

  //signature
  // addingOrganizationLeads: false,
  // addingOrganizationLeadsError: false,

  addingPersonalSignature: false,
  addingPersonalSignatureError: false,

  fetchingPersonalSignature: false,
  fetchingPersonalSignatureError: false,
  personalSignatureData: [],

  addingOrgSignature: false,
  addingOrgSignatureError: false,

  fetchingOrgSignature: false,
  fetchingOrgSignatureError: false,
  orgSignatureData: [],

  addTemplateModal: false,

  userLoggOffRequest: false,
  userLoggOffRequestError: false,
  loggOffInd: false,

  //enable InnoventoryPro
  enableInnoventoryProRequest: false,
  enableInnoventoryProRequestError: false,
  addInnoventoryModal: false,

  // ecommerce
  enableEcommerceProRequest: false,
  enableEcommerceProRequestError: false,
  addEcommerceModal: false,

  //enable FashionPro
  enableFashionProRequest: false,
  enableFashionProRequestError: false,
};

export const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.HANDLE_INNOVENTORY_MODAL:
      return { ...state, addInnoventoryModal: action.payload };

    case types.HANDLE_ECOMMERCE_MODAL:
      return { ...state, addEcommerceModal: action.payload };

    case types.HANDLE_TEMPLATE_MODAL:
      return { ...state, addTemplateModal: action.payload };
    case types.UPDATE_USER_LEVEL_REQUEST:
      return { ...state, updatingLevel: true };
    case types.UPDATE_USER_LEVEL_SUCCESS:
      return {
        ...state,
        updatingLevel: false,

        users: state.users.map((user) => {
          //debugger;
          //////debugger;
          if (user.userId === action.payload.userId) {
            //////debugger;
            return {
              ...user,
              level: action.payload.level,
            };
          } else {
            //////debugger;
            return user;
          }
        }),
        latestUsers: state.latestUsers.map((user) => {
          //debugger;
          //////debugger;
          if (user.userId === action.payload.userId) {
            //////debugger;
            return {
              ...user,
              level: action.payload.level,
            };
          } else {
            //////debugger;
            return user;
          }
        }),
        salesUsers: state.salesUsers.map((user) => {
          //debugger;
          if (user.userId === action.payload.userId) {
            //////debugger;
            return {
              ...user,
              level: action.payload.level,
            };
          } else {
            //////debugger;
            return user;
          }
        }),
        financeUsers: state.financeUsers.map((user) => {
          //////debugger;
          if (user.userId === action.payload.userId) {
            //////debugger;
            return {
              ...user,
              level: action.payload.level,
            };
          } else {
            //////debugger;
            return user;
          }
        }),
        riskManagementUsers: state.riskManagementUsers.map((user) => {
          //////debugger;
          if (user.userId === action.payload.userId) {
            //////debugger;
            return {
              ...user,
              level: action.payload.level,
            };
          } else {
            //////debugger;
            return user;
          }
        }),
      };
    case types.UPDATE_USER_LEVEL_FAILURE:
      return { ...state, updatingLevel: false, updatingLevelError: false };
    case types.GET_LATEST_USERS_REQUEST:
      return { ...state, fetchingLatestUsers: true };
    case types.GET_LATEST_USERS_SUCCESS:
      return {
        ...state,
        fetchingLatestUsers: false,
        latestUsers: action.payload,
      };

    case types.GET_SALES_USERS_FAILURE:
      return {
        ...state,
        fetchingSalesUsers: false,
        fetchingSalesUsersError: true,
      };

    case types.GET_SALES_USERS_REQUEST:
      return { ...state, fetchingSalesUsers: true };
    case types.GET_SALES_USERS_SUCCESS:
      return {
        ...state,
        fetchingSalesUsers: false,
        salesUsers: action.payload,
      };
    case types.GET_SALES_USERS_FAILURE:
      return {
        ...state,
        fetchingSalesUsers: false,
        fetchingSalesUsersError: true,
      };

    case types.GET_SALES_USERS_FAILURE:
      return {
        ...state,
        fetchingSalesUsers: false,
        fetchingSalesUsersError: true,
      };

    case types.GET_ONLY_SALES_USERS_REQUEST:
      return { ...state, fetchingOnlySalesUsers: true };
    case types.GET_ONLY_SALES_USERS_SUCCESS:
      return {
        ...state,
        fetchingOnlySalesUsers: false,
        onlySalesUsers: action.payload,
      };
    case types.GET_ONLY_SALES_USERS_FAILURE:
      return {
        ...state,
        fetchingOnlySalesUsers: false,
        fetchingOnlySalesUsersError: true,
      };
    //get department
    case types.GET_DEPARTMENT_REQUEST:
      return { ...state, fetchingDepartment: true };
    case types.GET_DEPARTMENT_SUCCESS:
      return {
        ...state,
        fetchingDepartment: false,
        department: action.payload,
      };
    case types.GET_DEPARTMENT_FAILURE:
      return {
        ...state,
        fetchingDepartment: false,
        fetchingDepartmentError: true,
      };
    //get delivery User

    case types.GET_DELIVERY_USERS_REQUEST:
      return { ...state, fetchingDeliveryUsers: true };
    case types.GET_DELIVERY_USERS_SUCCESS:
      return {
        ...state,
        fetchingDeliveryUsers: false,
        deliveryUsers: action.payload,
      };
    case types.GET_DELIVERY_USERS_FAILURE:
      return {
        ...state,
        fetchingDeliveryUsers: false,
        fetchingDeliveryUsersError: true,
      };

    //get finance User

    case types.GET_FINANCE_USERS_REQUEST:
      return { ...state, fetchingFinanceUsers: true };
    case types.GET_FINANCE_USERS_SUCCESS:
      return {
        ...state,
        fetchingFinanceUsers: false,
        financeUsers: action.payload,
      };
    case types.GET_FINANCE_USERS_FAILURE:
      return {
        ...state,
        fetchingFinanceUsers: false,
        fetchingFinanceUsersError: true,
      };

    //get legal user
    case types.GET_LEGAL_USERS_REQUEST:
      return { ...state, fetchingLegalUsers: true };
    case types.GET_LEGAL_USERS_SUCCESS:
      return {
        ...state,
        fetchingLegalUsers: false,
        legalUsers: action.payload,
      };
    case types.GET_LEGAL_USERS_FAILURE:
      return {
        ...state,
        fetchingLegalUsers: false,
        fetchingLegalUsersError: true,
      };

    // get riskmanagement user

    case types.GET_RISKMANAGEMENT_USERS_REQUEST:
      return { ...state, fetchingRiskManagementUsers: true };
    case types.GET_RISKMANAGEMENT_USERS_SUCCESS:
      return {
        ...state,
        fetchingRiskManagementUsers: false,
        riskManagementUsers: action.payload,
      };
    case types.GET_RISKMANAGEMENT_USERS_FAILURE:
      return {
        ...state,
        fetchingRiskManagementUsers: false,
        fetchingRiskManagementError: true,
      };

    // get partner user

    case types.GET_PARTNER_USERS_REQUEST:
      return { ...state, fetchingPartnerUsers: true };
    case types.GET_PARTNER_USERS_SUCCESS:
      return {
        ...state,
        fetchingPartnerUsers: false,
        partnerUsers: action.payload,
      };
    case types.GET_PARTNER_USERS_FAILURE:
      return {
        ...state,
        fetchingPartnerUsers: false,
        fetchingPartnerUsersError: true,
      };

    // get CustomeModule user

    case types.GET_CUSTOME_MODULE_USERS_REQUEST:
      return { ...state, fetchingCustomeUsers: true };
    case types.GET_CUSTOME_MODULE_USERS_SUCCESS:
      return {
        ...state,
        fetchingCustomeUsers: false,
        customeUsers: action.payload,
      };
    case types.GET_CUSTOME_MODULE_USERS_FAILURE:
      return {
        ...state,
        fetchingCustomeUsers: false,
        fetchingCustomeUsersError: true,
      };

    // get Reports To user

    case types.GET_REPORTS_TO_USERS_REQUEST:
      return { ...state, fetchingReportsToUsers: true };
    case types.GET_REPORTS_TO_USERS_SUCCESS:
      return {
        ...state,
        fetchingReportsToUsers: false,
        reportsToUsers: action.payload,
      };
    case types.GET_REPORTS_TO_USERS_FAILURE:
      return {
        ...state,
        fetchingReportsToUsers: false,
        fetchingReportsToUsersError: true,
      };

    case types.GET_USERS_REQUEST:
      return { ...state, fetchingUsers: true };
    case types.GET_USERS_SUCCESS:
      return { ...state, fetchingUsers: false, users: action.payload };
    case types.GET_USERS_FAILURE:
      return { ...state, fetchingUsers: false, fetchingUsersError: true };

    case types.GET_USER_BY_ID_REQUEST:
      return { ...state, fetchingUser: true };
    case types.GET_USER_BY_ID_SUCCESS:
      return { ...state, fetchingUser: false, user: action.payload };
    case types.GET_USER_BY_ID_FAILURE:
      return { ...state, fetchingUser: false, fetchingUserError: true };
    /**
     * update a single account by its ID
     */
    case types.UPDATE_USER_BY_ID_REQUEST:
      return { ...state, updatingUserById: true };
    case types.UPDATE_USER_BY_ID_SUCCESS:
      return { ...state, updatingUserById: false, user: action.payload };
    case types.UPDATE_USER_BY_ID_FAILURE:
      return { ...state, updatingUserById: false, updatingUserByIdError: true };

    /**
     * update a single account by its ID
     */
    case types.DELETE_USER_BY_ID_REQUEST:
      return { ...state, deletingUserById: true };
    case types.DELETE_USER_BY_ID_SUCCESS:
      return {
        ...state,
        deletingUserById: false,
        users: state.users.filter((user) => user.userId !== action.payload),
        latestUsers: state.latestUsers.filter(
          (latestUser) => latestUser.userId !== action.payload
        ),
      };
    case types.DELETE_USER_BY_ID_FAILURE:
      return { ...state, deletingUserById: false, deletingUserByIdError: true };

    /**
     * getcontact list by userId
     */
    case types.GET_CONTACT_LIST_BY_USER_ID_REQUEST:
      return { ...state, fetchingContactListByUserId: true };
    case types.GET_CONTACT_LIST_BY_USER_ID_SUCCESS:
      return {
        ...state,
        fetchingContactListByUserId: false,
        contactListByUserId: action.payload,
      };
    case types.GET_CONTACT_LIST_BY_USER_ID_FAILURE:
      return {
        ...state,
        fetchingContactListByUserId: false,
        fetchingContactListByUserIdError: true,
      };
    /**
     * getcontact list by userId
     */
    case types.UPDATE_CONTACT_LIST_BY_USER_ID_REQUEST:
      return { ...state };
    case types.UPDATE_CONTACT_LIST_BY_USER_ID_SUCCESS:
      return {
        ...state,
        contactListByUserId: state.contactListByUserId.filter(
          (item) => item.contactId !== action.payload
        ),
      };
    case types.UPDATE_CONTACT_LIST_BY_USER_ID_FAILURE:
      return { ...state };
    /**
     * get account list by userId
     */
    case types.GET_ACCOUNT_LIST_BY_USER_ID_REQUEST:
      return { ...state, fetchingAccountListByUserId: true };
    case types.GET_ACCOUNT_LIST_BY_USER_ID_SUCCESS:
      return {
        ...state,
        fetchingAccountListByUserId: false,
        accountListByUserId: action.payload,
      };
    case types.GET_ACCOUNT_LIST_BY_USER_ID_FAILURE:
      return {
        ...state,
        fetchingAccountListByUserId: false,
        fetchingAccountListByUserIdError: true,
      };
    /**
     * get account list by userId
     */
    case types.UPDATE_ACCOUNT_LIST_BY_USER_ID_REQUEST:
      return { ...state };
    case types.UPDATE_ACCOUNT_LIST_BY_USER_ID_SUCCESS:
      return {
        ...state,
        accountListByUserId: state.accountListByUserId.filter(
          (item) => item.accountId !== action.payload
        ),
      };
    case types.UPDATE_ACCOUNT_LIST_BY_USER_ID_FAILURE:
      return { ...state };
    /**
     * get opportunity list by userId
     */
    case types.GET_OPPORTUNITY_LIST_BY_USER_ID_REQUEST:
      return { ...state, fetchingOpportunityListByUserId: true };
    case types.GET_OPPORTUNITY_LIST_BY_USER_ID_SUCCESS:
      return {
        ...state,
        fetchingOpportunityListByUserId: false,
        opportunityListByUserId: action.payload,
      };
    case types.GET_OPPORTUNITY_LIST_BY_USER_ID_FAILURE:
      return {
        ...state,
        fetchingOpportunityListByUserId: false,
        fetchingOpportunityListByUserIdError: true,
      };
    /**
     * get opportunity list by userId
     */
    case types.UPDATE_OPPORTUNITY_LIST_BY_USER_ID_REQUEST:
      return { ...state };
    case types.UPDATE_OPPORTUNITY_LIST_BY_USER_ID_SUCCESS:
      return {
        ...state,
        opportunityListByUserId: state.opportunityListByUserId.filter(
          (item) => item.opportunityId !== action.payload
        ),
      };
    case types.UPDATE_OPPORTUNITY_LIST_BY_USER_ID_FAILURE:
      return { ...state };
    /**
     * get notes list by userId
     */
    case types.GET_NOTES_LIST_BY_USER_ID_REQUEST:
      return { ...state, fetchingNotesListByUserId: true };
    case types.GET_NOTES_LIST_BY_USER_ID_SUCCESS:
      return {
        ...state,
        fetchingNotesListByUserId: false,
        notesListByUserId: action.payload,
      };
    case types.GET_NOTES_LIST_BY_USER_ID_FAILURE:
      return {
        ...state,
        fetchingNotesListByUserId: false,
        fetchingNotesListByUserIdError: true,
      };
    /**
     * get notes list by userId
     */
    case types.UPDATE_NOTES_LIST_BY_USER_ID_REQUEST:
      return { ...state };
    case types.UPDATE_NOTES_LIST_BY_USER_ID_SUCCESS:
      return {
        ...state,
        notesListByUserId: state.notesListByUserId.filter(
          (item) => item.noteId !== action.payload
        ),
      };
    case types.UPDATE_NOTES_LIST_BY_USER_ID_FAILURE:
      return { ...state };
    /**
     * get calls list by userId
     */
    case types.GET_CALLS_LIST_BY_USER_ID_REQUEST:
      return { ...state, fetchingCallsListByUserId: true };
    case types.GET_CALLS_LIST_BY_USER_ID_SUCCESS:
      return {
        ...state,
        fetchingCallsListByUserId: false,
        callsListByUserId: action.payload,
      };
    case types.GET_CALLS_LIST_BY_USER_ID_FAILURE:
      return {
        ...state,
        fetchingCallsListByUserId: false,
        fetchingCallsListByUserIdError: true,
      };
    /**
     * get calls list by userId
     */
    case types.UPDATE_CALLS_LIST_BY_USER_ID_REQUEST:
      return { ...state };
    case types.UPDATE_CALLS_LIST_BY_USER_ID_SUCCESS:
      return {
        ...state,
        callsListByUserId: state.callsListByUserId.filter(
          (item) => item.callId !== action.payload
        ),
      };
    case types.UPDATE_CALLS_LIST_BY_USER_ID_FAILURE:
      return { ...state };
    /**
     * get events list by userId
     */
    case types.GET_EVENTS_LIST_BY_USER_ID_REQUEST:
      return { ...state, fetchingEventsListByUserId: true };
    case types.GET_EVENTS_LIST_BY_USER_ID_SUCCESS:
      return {
        ...state,
        fetchingEventsListByUserId: false,
        eventsListByUserId: action.payload,
      };
    case types.GET_EVENTS_LIST_BY_USER_ID_FAILURE:
      return {
        ...state,
        fetchingEventsListByUserId: false,
        fetchingEventsListByUserIdError: true,
      };
    /**
     * get events list by userId
     */
    case types.UPDATE_EVENTS_LIST_BY_USER_ID_REQUEST:
      return { ...state };
    case types.UPDATE_EVENTS_LIST_BY_USER_ID_SUCCESS:
      return {
        ...state,
        eventsListByUserId: state.eventsListByUserId.filter(
          (item) => item.eventId !== action.payload
        ),
      };
    case types.UPDATE_EVENTS_LIST_BY_USER_ID_FAILURE:
      return { ...state };
    /**
     * get tasks list by userId
     */
    case types.GET_TASKS_LIST_BY_USER_ID_REQUEST:
      return { ...state, fetchingTasksListByUserId: true };
    case types.GET_TASKS_LIST_BY_USER_ID_SUCCESS:
      return {
        ...state,
        fetchingTasksListByUserId: false,
        tasksListByUserId: action.payload,
      };
    case types.GET_TASKS_LIST_BY_USER_ID_FAILURE:
      return {
        ...state,
        fetchingTasksListByUserId: false,
        fetchingTasksListByUserIdError: true,
      };
    /**
     * get tasks list by userId
     */
    case types.UPDATE_TASKS_LIST_BY_USER_ID_REQUEST:
      return { ...state };
    case types.UPDATE_TASKS_LIST_BY_USER_ID_SUCCESS:
      return {
        ...state,
        tasksListByUserId: state.tasksListByUserId.filter(
          (item) => item.taskId !== action.payload
        ),
      };
    case types.UPDATE_TASKS_LIST_BY_USER_ID_FAILURE:
      return { ...state };

    /**
     * topic of intrest of an user
     */
    case types.GET_TOPICS_BY_USER_ID_REQUEST:
      return { ...state, fetchingTopicsByUserId: true };
    case types.GET_TOPICS_BY_USER_ID_SUCCESS:
      return {
        ...state,
        fetchingTopicsByUserId: false,
        topicsByUserId: action.payload,
      };
    case types.GET_TOPICS_BY_USER_ID_FAILURE:
      return {
        ...state,
        fetchingTopicsByUserId: false,
        fetchingTopicsByUserIdError: true,
      };

    case types.ADD_TOPIC_BY_USER_ID_REQUEST:
      return { ...state, addingTopicByUserId: true };
    case types.ADD_TOPIC_BY_USER_ID_SUCCESS:
      return {
        ...state,
        addingTopicByUserId: false,
        topicsByUserId: [...state.topicsByUserId, action.payload],
      };
    case types.ADD_TOPIC_BY_USER_ID_FAILURE:
      return {
        ...state,
        addingTopicByUserId: false,
        addingTopicByUserIdError: true,
      };

    case types.DELETE_TOPIC_BY_USER_ID_REQUEST:
      return { ...state, deletingTopicByUserId: true };
    case types.DELETE_TOPIC_BY_USER_ID_SUCCESS:
      return { ...state, deletingTopicByUserId: false };
    case types.DELETE_TOPIC_BY_USER_ID_FAILURE:
      return {
        ...state,
        deletingTopicByUserId: false,
        deletingTopicByUserIdError: true,
      };

    /**
     * topic of intrest of an organization
     */
    case types.GET_TOPICS_BY_ORGANIZATION_ID_REQUEST:
      return { ...state, fetchingTopicsByOrganizationId: true };
    case types.GET_TOPICS_BY_ORGANIZATION_ID_SUCCESS:
      return {
        ...state,
        fetchingTopicsByOrganizationId: false,
        topicsByOrganizationId: action.payload,
      };
    case types.GET_TOPICS_BY_ORGANIZATION_ID_FAILURE:
      return {
        ...state,
        fetchingTopicsByOrganizationId: false,
        fetchingTopicsByOrganizationIdError: true,
      };

    case types.ADD_TOPIC_BY_ORGANIZATION_ID_REQUEST:
      return { ...state, addingTopicByOrganizationId: true };
    case types.ADD_TOPIC_BY_ORGANIZATION_ID_SUCCESS:
      return {
        ...state,
        addingTopicByOrganizationId: false,
        topicsByOrganizationId: [
          ...state.topicsByOrganizationId,
          action.payload,
        ],
      };
    case types.ADD_TOPIC_BY_ORGANIZATION_ID_FAILURE:
      return {
        ...state,
        addingTopicByOrganizationId: false,
        addingTopicByOrganizationIdError: true,
      };

    case types.DELETE_TOPIC_BY_ORGANIZATION_ID_REQUEST:
      return { ...state, deletingTopicByOrganizationId: true };
    case types.DELETE_TOPIC_BY_ORGANIZATION_ID_SUCCESS:
      return { ...state, deletingTopicByOrganizationId: false };
    case types.DELETE_TOPIC_BY_ORGANIZATION_ID_FAILURE:
      return {
        ...state,
        deletingTopicByOrganizationId: false,
        deletingTopicByOrganizationIdError: true,
      };

    case types.ADD_USER_REQUEST:
      return { ...state, addingUser: true };
    case types.ADD_USER_SUCCESS:
      return { ...state, addingUser: false, addUserModal: false };
    case types.ADD_USER_FAILURE:
      return { ...state, addingUser: false, addingUserError: true };

    case types.GET_BILLING_REQUEST:
      return { ...state, fetchingBilling: true };
    case types.GET_BILLING_SUCCESS:
      return { ...state, fetchingBilling: false, billings: action.payload };
    case types.GET_BILLING_FAILURE:
      return { ...state, fetchingBilling: false, fetchingBillingError: true };
    /**
     * enable smart boost for any user
     * only superuser can do this
     */

    case types.ENABLE_SMART_BOOST_REQUEST:
      return { ...state, enableSmartBoostRequest: true };
    case types.ENABLE_SMART_BOOST_SUCCESS:
      return {
        ...state,
        enableSmartBoostRequest: false,
        teamDrawerProps: {
          ...state.teamDrawerProps,
          metaData: { ...state.teamDrawerProps.metaData, smartBoost: true },
        },
        users: state.users.map((user) => {
          //////debugger;
          if (user.userId === action.payload.userId) {
            //////debugger;
            return {
              ...user,
              metaData: { ...user.metaData, smartBoost: true },
            };
          } else {
            //////debugger;
            return user;
          }
        }),
        latestUsers: state.latestUsers.map((user) => {
          //////debugger;
          if (user.userId === action.payload.userId) {
            //////debugger;
            return {
              ...user,
              metaData: { ...user.metaData, smartBoost: true },
            };
          } else {
            //////debugger;
            return user;
          }
        }),
        salesUsers: state.salesUsers.map((user) => {
          //////debugger;
          if (user.userId === action.payload.userId) {
            //////debugger;
            return {
              ...user,
              metaData: { ...user.metaData, smartBoost: true },
            };
          } else {
            //////debugger;
            return user;
          }
        }),
        financeUsers: state.financeUsers.map((user) => {
          //////debugger;
          if (user.userId === action.payload.userId) {
            //////debugger;
            return {
              ...user,
              metaData: { ...user.metaData, smartBoost: true },
            };
          } else {
            //////debugger;
            return user;
          }
        }),
        riskManagementUsers: state.riskManagementUsers.map((user) => {
          //////debugger;
          if (user.userId === action.payload.userId) {
            //////debugger;
            return {
              ...user,
              metaData: { ...user.metaData, smartBoost: true },
            };
          } else {
            //////debugger;
            return user;
          }
        }),
      };
    case types.ENABLE_SMART_BOOST_FAILURE:
      return {
        ...state,
        enableSmartBoostRequest: false,
        enableSmartBoostRequestError: true,
      };

    case types.ENABLE_SMART_BOOST_FOR_ALL_REQUEST:
      return {
        ...state,
        enableSmartBoostForAllRequest: true,
        enableSmartBoostForAllRequestError: false,
      };
    case types.ENABLE_SMART_BOOST_FOR_ALL_SUCCESS:
      return { ...state };
    case types.ENABLE_SMART_BOOST_FOR_ALL_FAILURE:
      return {
        ...state,
        enableSmartBoostForAllRequest: false,
        enableSmartBoostForAllRequestError: true,
      };

    case types.DISABLE_SMART_BOOST_REQUEST:
      return { ...state, disableSmartBoostRequest: true };
    case types.UNABLE_TO_DISABLE_SMART_BOOST_SUCCESS:
      return {
        ...state,
        disableSmartBoostRequest: false,
        teamDrawerProps: {
          ...state.teamDrawerProps,
          metaData: { ...state.teamDrawerProps.metaData, smartBoost: true },
        },
        users: state.users.map((user) => {
          if (user.userId === action.payload.userId) {
            return {
              ...user,
              metaData: { ...user.metaData, smartBoost: true },
            };
          } else {
            return user;
          }
        }),
      };
    case types.DISABLE_SMART_BOOST_SUCCESS:
      return {
        ...state,
        disableSmartBoostRequest: false,
        teamDrawerProps: {
          ...state.teamDrawerProps,
          metaData: { ...state.teamDrawerProps.metaData, smartBoost: false },
        },
        users: state.users.map((user) => {
          if (user.userId === action.payload.userId) {
            return {
              ...user,
              metaData: { ...user.metaData, smartBoost: false },
            };
          } else {
            return user;
          }
        }),
      };
    case types.DISABLE_SMART_BOOST_FAILURE:
      return {
        ...state,
        disableSmartBoostRequest: false,
        disableSmartBoostRequestError: true,
      };

    /**
     * enable product for any user
     * only superuser can do this
     */

    case types.ENABLE_PROFESSIONALDUCT_REQUEST:
      return { ...state, enableProductRequest: true };
    case types.ENABLE_PROFESSIONALDUCT_SUCCESS:
      return {
        ...state,
        enableProductRequest: false,
        teamDrawerProps: {
          ...state.teamDrawerProps,
          metaData: { ...state.teamDrawerProps.metaData, productStatus: true },
        },
        users: state.users.map((user) => {
          if (user.userId === action.payload.userId) {
            return {
              ...user,
              metaData: { ...user.metaData, productStatus: true },
            };
          } else {
            return user;
          }
        }),
        latestUsers: state.latestUsers.map((user) => {
          if (user.userId === action.payload.userId) {
            return {
              ...user,
              metaData: { ...user.metaData, productStatus: true },
            };
          } else {
            return user;
          }
        }),
        salesUsers: state.salesUsers.map((user) => {
          if (user.userId === action.payload.userId) {
            return {
              ...user,
              metaData: { ...user.metaData, productStatus: true },
            };
          } else {
            return user;
          }
        }),
        financeUsers: state.financeUsers.map((user) => {
          if (user.userId === action.payload.userId) {
            return {
              ...user,
              metaData: { ...user.metaData, productStatus: true },
            };
          } else {
            return user;
          }
        }),
        riskMananagementUser: state.riskMananagementUser.map((user) => {
          if (user.userId === action.payload.userId) {
            return {
              ...user,
              metaData: { ...user.metaData, productStatus: true },
            };
          } else {
            return user;
          }
        }),
      };
    case types.ENABLE_PROFESSIONALDUCT_FAILURE:
      return {
        ...state,
        enableProductRequest: false,
        enableProductRequestError: true,
      };

    case types.ENABLE_PROFESSIONALDUCT_FOR_ALL_REQUEST:
      return {
        ...state,
        enableProductForAllRequest: true,
        enableProductForAllRequestError: false,
      };
    case types.ENABLE_PROFESSIONALDUCT_FOR_ALL_SUCCESS:
      return { ...state };
    case types.ENABLE_PROFESSIONALDUCT_FOR_ALL_FAILURE:
      return {
        ...state,
        enableProductForAllRequest: false,
        enableProductForAllRequestError: true,
      };

    case types.DISABLE_PROFESSIONALDUCT_REQUEST:
      return { ...state, disableProductRequestError: true };
    case types.DISABLE_PROFESSIONALDUCT_SUCCESS:
      return {
        ...state,
        disableProductRequestError: false,
        teamDrawerProps: {
          ...state.teamDrawerProps,
          metaData: { ...state.teamDrawerProps.metaData, productStatus: false },
        },
        users: state.users.map((user) => {
          if (user.userId === action.payload.userId) {
            return {
              ...user,
              metaData: { ...user.metaData, productStatus: false },
            };
          } else {
            return user;
          }
        }),
      };
    case types.DISABLE_PROFESSIONALDUCT_FAILURE:
      return {
        ...state,
        disableProductRequestError: false,
        disableProductRequestErrorError: true,
      };

    /**
     * enable  viewport for any user
     * only superuser can do this
     */

    case types.ENABLE_VIEWPORT_REQUEST:
      return { ...state, enableViewportRequest: true };
    case types.ENABLE_VIEWPORT_SUCCESS:
      return {
        ...state,
        enableViewportRequest: false,
        teamDrawerProps: {
          ...state.teamDrawerProps,
          metaData: { ...state.teamDrawerProps.metaData, viewportStatus: true },
        },
        users: state.users.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, viewportStatus: true },
            };
          } else {
            return user;
          }
        }),
        salesUsers: state.salesUsers.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, viewportStatus: true },
            };
          } else {
            return user;
          }
        }),
      };
    case types.ENABLE_VIEWPORT_FAILURE:
      return {
        ...state,
        enableViewportRequest: false,
        enableViewportRequestError: true,
      };

    case types.DISABLE_VIEWPORT_REQUEST:
      return { ...state, disableViewportRequest: true };
    case types.DISABLE_VIEWPORT_SUCCESS:
      return {
        ...state,
        disableViewportRequest: false,
        teamDrawerProps: {
          ...state.teamDrawerProps,
          metaData: {
            ...state.teamDrawerProps.metaData,
            viewportStatus: false,
          },
        },
        users: state.users.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, viewportStatus: false },
            };
          } else {
            return user;
          }
        }),
      };
    case types.DISABLE_VIEWPORT_FAILURE:
      return {
        ...state,
        disableViewportRequest: false,
        disableViewportRequestError: true,
      };

    /**
     * enable  delivery for any user
     * only superuser can do this
     */

    case types.ENABLE_DELIVERY_REQUEST:
      return { ...state, enableDeliveryRequest: true };
    case types.ENABLE_DELIVERY_SUCCESS:
      return {
        ...state,
        enableDeliveryRequest: false,
        teamDrawerProps: {
          ...state.teamDrawerProps,
          metaData: { ...state.teamDrawerProps.metaData, deliveryInd: true },
        },
        users: state.users.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, deliveryInd: true },
            };
          } else {
            return user;
          }
        }),
      };
    case types.ENABLE_DELIVERY_FAILURE:
      return {
        ...state,
        enableDeliveryRequest: false,
        enableDeliveryRequestError: true,
      };
    /**
     * disable delivery for any user
     * only superuser can do this
     */
    case types.DISABLE_DELIVERY_REQUEST:
      return { ...state, disableDeliveryRequestError: true };
    case types.DISABLE_DELIVERY_SUCCESS:
      return {
        ...state,
        disableDeliveryRequestError: false,
        teamDrawerProps: {
          ...state.teamDrawerProps,
          metaData: { ...state.teamDrawerProps.metaData, deliveryInd: false },
        },
        users: state.users.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, deliveryInd: false },
            };
          } else {
            return user;
          }
        }),
      };
    case types.DISABLE_DELIVERY_FAILURE:
      return {
        ...state,
        disableDeliveryRequestError: false,
        disableDeliveryRequestErrorError: true,
      };

    /**
     * enable  finance for any user
     * only superuser can do this
     */

    case types.ENABLE_FINANCE_REQUEST:
      return { ...state, enableFinanceRequest: true };
    case types.ENABLE_FINANCE_SUCCESS:
      return {
        ...state,
        enableFinanceRequest: false,
        teamDrawerProps: {
          ...state.teamDrawerProps,
          metaData: { ...state.teamDrawerProps.metaData, financeInd: true },
        },
        users: state.users.map((user) => {
          //////debugger;
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, financeInd: true },
            };
          } else {
            return user;
          }
        }),
        latestUsers: state.latestUsers.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, financeInd: true },
            };
          } else {
            return user;
          }
        }),
        salesUsers: state.salesUsers.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, financeInd: true },
            };
          } else {
            return user;
          }
        }),
        financeUsers: state.financeUsers.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, financeInd: true },
            };
          } else {
            return user;
          }
        }),
        riskManagementUsers: state.riskManagementUsers.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, financeInd: true },
            };
          } else {
            return user;
          }
        }),
      };
    case types.ENABLE_FINANCE_FAILURE:
      return {
        ...state,
        enableFinanceRequest: false,
        enableFinanceRequestError: true,
      };
    /**
     * disable delivery for any user
     * only superuser can do this
     */
    case types.DISABLE_FINANCE_REQUEST:
      return { ...state, disableFinanceRequestError: true };
    case types.DISABLE_FINANCE_SUCCESS:
      return {
        ...state,
        disableFinanceRequestError: false,
        teamDrawerProps: {
          ...state.teamDrawerProps,
          metaData: { ...state.teamDrawerProps.metaData, financeInd: false },
        },
        users: state.users.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, financeInd: false },
            };
          } else {
            return user;
          }
        }),
      };
    case types.DISABLE_FINANCE_FAILURE:
      return {
        ...state,
        disableFinanceRequestError: false,
        disableFinanceRequestErrorError: true,
      };

    /**
     * enable  legal for any user
     * only superuser can do this
     */

    case types.ENABLE_LEGAL_REQUEST:
      return { ...state, enableLegalRequest: true };
    case types.ENABLE_LEGAL_SUCCESS:
      return {
        ...state,
        enableLegalRequest: false,
        teamDrawerProps: {
          ...state.teamDrawerProps,
          metaData: { ...state.teamDrawerProps.metaData, legalInd: true },
        },
        users: state.users.map((user) => {
          //////debugger;
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, legalInd: true },
            };
          } else {
            //////debugger;
            return user;
          }
        }),
        latestUsers: state.latestUsers.map((user) => {
          //////debugger
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, legalInd: true },
            };
          } else {
            return user;
          }
        }),
        salesUsers: state.salesUsers.map((user) => {
          //////debugger
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, legalInd: true },
            };
          } else {
            return user;
          }
        }),
        financeUsers: state.financeUsers.map((user) => {
          //////debugger;
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, legalInd: true },
            };
          } else {
            return user;
          }
        }),
        riskManagementUsers: state.riskManagementUsers.map((user) => {
          //////debugger
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, legalInd: true },
            };
          } else {
            return user;
          }
        }),
      };
    case types.ENABLE_LEGAL_FAILURE:
      return {
        ...state,
        enableLegalRequest: false,
        enableLegalRequestError: true,
      };
    /**
     * disable  legal for any user
     * only superuser can do this
     */
    case types.DISABLE_LEGAL_REQUEST:
      return { ...state, disableLegalRequestError: true };
    case types.DISABLE_LEGAL_SUCCESS:
      return {
        ...state,
        disableLegalRequestError: false,
        teamDrawerProps: {
          ...state.teamDrawerProps,
          metaData: { ...state.teamDrawerProps.metaData, legalInd: false },
        },
        users: state.users.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, legalInd: false },
            };
          } else {
            return user;
          }
        }),
      };
    case types.DISABLE_LEGAL_FAILURE:
      return {
        ...state,
        disableLegalRequestError: false,
        disableLegalRequestErrorError: true,
      };

    /**
     * enable  RISKMANAGEMENT for any user
     * only superuser can do this
     */

    case types.ENABLE_RISKMANAGEMENT_REQUEST:
      return { ...state, enableRiskManagementRequest: true };
    case types.ENABLE_RISKMANAGEMENT_SUCCESS:
      return {
        ...state,
        enableRiskManagementRequest: false,
        teamDrawerProps: {
          ...state.teamDrawerProps,
          metaData: {
            ...state.teamDrawerProps.metaData,
            riskManagementInd: true,
          },
        },
        users: state.users.map((user) => {
          //////debugger;
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, riskManagementInd: true },
            };
          } else {
            //////debugger;
            return user;
          }
        }),
        latestUsers: state.latestUsers.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, riskManagementInd: true },
            };
          } else {
            return user;
          }
        }),
        salesUsers: state.salesUsers.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, riskManagementInd: true },
            };
          } else {
            return user;
          }
        }),
        financeUsers: state.financeUsers.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, riskManagementInd: true },
            };
          } else {
            return user;
          }
        }),
        riskManagementUsers: state.riskManagementUsers.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, riskManagementInd: true },
            };
          } else {
            return user;
          }
        }),
      };
    case types.ENABLE_RISKMANAGEMENT_FAILURE:
      return {
        ...state,
        enableRiskManagementRequest: false,
        enableRiskManagementRequestError: true,
      };
    /**
     * disable  RISKMANAGEMENT for any user
     * only superuser can do this
     */
    case types.DISABLE_RISKMANAGEMENT_REQUEST:
      return { ...state, disableRiskManagementRequestError: true };
    case types.DISABLE_RISKMANAGEMENT_SUCCESS:
      return {
        ...state,
        disableRiskManagementRequestError: false,
        teamDrawerProps: {
          ...state.teamDrawerProps,
          metaData: {
            ...state.teamDrawerProps.metaData,
            riskManagementInd: false,
          },
        },
        users: state.users.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, riskManagementInd: false },
            };
          } else {
            return user;
          }
        }),
      };
    case types.DISABLE_RISKMANAGEMENT_FAILURE:
      return {
        ...state,
        disableRiskManagementRequestError: false,
        disableRiskManagementRequestErrorError: true,
      };

    case types.HANDLE_USER_MODAL:
      return { ...state, addUserModal: action.payload };
    case types.HANDLE_GOAL_MODAL:
      return { ...state, setGoalModal: action.payload };
    case types.HANDLE_TEAM_DRAWER:
      return {
        ...state,
        teamDrawerVisible: action.payload.isVisible,
        teamDrawerProps: action.payload.props,
      };
    case types.HANDLE_TEAM_DRAWER_FOR_ADMIN:
      return {
        ...state,
        teamDrawerVisibleForAdmin: action.payload.isVisible,
        teamDrawerProps: action.payload.props,
      };

    case types.SET_TEAM_VIEW_TYPE:
      return { ...state, viewType: action.payload };
    case types.SET_TEAM_FILTER_TEXT:
      return { ...state, teamFilterText: action.payload };
    case types.SET_FILTER_BY_USER_OPTION:
      return { ...state, filterByUserOption: action.payload };

    case types.ADD_CUSTOM_DEPARTMENT_REQUEST:
      return { ...state, addingCustomDepartment: true };
    case types.ADD_CUSTOM_DEPARTMENT_SUCCESS:
      return { ...state, addingCustomDepartment: false };
    case types.ADD_CUSTOM_DEPARTMENT_FAILURE:
      return {
        ...state,
        addingCustomDepartment: false,
        addingCustomDepartmentError: true,
      };

    case types.GET_CUSTOM_DEPARTMENT_REQUEST:
      return { ...state, fetchingCustomDepartment: true };
    case types.GET_CUSTOM_DEPARTMENT_SUCCESS:
      return {
        ...state,
        fetchingCustomDepartment: false,
        customerDepartment: action.payload,
      };
    case types.GET_CUSTOM_DEPARTMENT_FAILURE:
      return {
        ...state,
        fetchingCustomDepartment: false,
        fetchingCustomDepartmentError: true,
      };

    /**
     * enable  legal for any user
     * only superuser can do this
     */

    case types.ENABLE_CUSTOME_MODULE_REQUEST:
      return { ...state, enableCustomeModuleRequest: true };
    case types.ENABLE_CUSTOME_MODULE_SUCCESS:
      return {
        ...state,
        enableCustomeModuleRequest: false,
        teamDrawerProps: {
          ...state.teamDrawerProps,
          metaData: { ...state.teamDrawerProps.metaData, Ind: true },
        },
        users: state.users.map((user) => {
          //////debugger;
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, Ind: true },
            };
          } else {
            //////debugger;
            return user;
          }
        }),
        latestUsers: state.latestUsers.map((user) => {
          //////debugger
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, Ind: true },
            };
          } else {
            return user;
          }
        }),
        salesUsers: state.salesUsers.map((user) => {
          //////debugger
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, Ind: true },
            };
          } else {
            return user;
          }
        }),
        financeUsers: state.financeUsers.map((user) => {
          //////debugger;
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, Ind: true },
            };
          } else {
            return user;
          }
        }),
        riskManagementUsers: state.riskManagementUsers.map((user) => {
          //////debugger
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, Ind: true },
            };
          } else {
            return user;
          }
        }),
      };
    case types.ENABLE_CUSTOME_MODULE_FAILURE:
      return {
        ...state,
        enableCustomeModuleRequest: false,
        enableCustomeModuleRequestError: true,
      };

    /**
     * enable  Recruitment for any user
     * only superuser can do this
     */

    case types.ENABLE_RECRUITMENT_REQUEST:
      return { ...state, enableRecruitmentRequest: true };
    case types.ENABLE_RECRUITMENT_SUCCESS:
      return {
        ...state,
        enableRecruitmentRequest: false,
        teamDrawerProps: {
          ...state.teamDrawerProps,
          metaData: { ...state.teamDrawerProps.metaData, recriutmentInd: true },
        },
        users: state.users.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, recriutmentInd: true },
            };
          } else {
            return user;
          }
        }),
        salesUsers: state.salesUsers.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, recriutmentInd: true },
            };
          } else {
            return user;
          }
        }),
      };

    case types.ENABLE_RECRUITMENT_FAILURE:
      return {
        ...state,
        enableRecruitmentRequest: false,
        enableRecruitmentRequestError: true,
      };

    case types.DISABLE_RECRUITMENT_REQUEST:
      return { ...state, disableRecruitmentRequest: true };
    case types.DISABLE_RECRUITMENT_SUCCESS:
      return {
        ...state,
        disableRecruitmentRequest: false,
        teamDrawerProps: {
          ...state.teamDrawerProps,
          metaData: {
            ...state.teamDrawerProps.metaData,
            viewportStatus: false,
          },
        },
        users: state.users.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, viewportStatus: false },
            };
          } else {
            return user;
          }
        }),
      };
    case types.DISABLE_RECRUITMENT_FAILURE:
      return {
        ...state,
        disableRecruitmentRequest: false,
        disableRecruitmentRequestError: true,
      };

    /**
     * enable  Marketing for any user
     * only superuser can do this
     */

    case types.ENABLE_MARKETING_REQUEST:
      return { ...state, enableMarketingRequest: true };
    case types.ENABLE_MARKETING_SUCCESS:
      return {
        ...state,
        enableMarketingRequest: false,
        teamDrawerProps: {
          ...state.teamDrawerProps,
          metaData: { ...state.teamDrawerProps.metaData, campaignInd: true },
        },
        users: state.users.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, campaignInd: true },
            };
          } else {
            return user;
          }
        }),
        salesUsers: state.salesUsers.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, campaignInd: true },
            };
          } else {
            return user;
          }
        }),
      };

    case types.ENABLE_MARKETING_FAILURE:
      return {
        ...state,
        enableMarketingRequest: false,
        enableMarketingRequestError: true,
      };
    //signature

    case types.ADD_PERSONAL_SIGNATUE_BY_USER_ID_REQUEST:
      return {
        ...state,
        addingPersonalSignature: true,
      };
    case types.ADD_PERSONAL_SIGNATUE_BY_USER_ID_SUCCESS:
      return {
        ...state,
        addingPersonalSignature: false,

        personalSignatureData: action.payload,
      };
    case types.ADD_PERSONAL_SIGNATUE_BY_USER_ID_FAILURE:
      return {
        ...state,
        addingPersonalSignature: false,
        addingPersonalSignatureError: true,
      };

    case types.GET_PERSONAL_SIGNATUE_BY_USER_ID_REQUEST:
      return {
        ...state,
        fetchingPersonalSignature: true,
        fetchingPersonalSignatureError: false,
      };
    case types.GET_PERSONAL_SIGNATUE_BY_USER_ID_SUCCESS:
      return {
        ...state,
        fetchingPersonalSignature: false,
        fetchingPersonalSignatureError: false,
        personalSignatureData: action.payload,
      };
    case types.GET_PERSONAL_SIGNATUE_BY_USER_ID_FAILURE:
      return {
        ...state,
        fetchingPersonalSignature: false,
        fetchingPersonalSignatureError: true,
      };

    //signature organization
    case types.ADD_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_REQUEST:
      return {
        ...state,
        addingOrgSignature: true,
      };
    case types.ADD_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_SUCCESS:
      return {
        ...state,
        addingOrgSignature: false,

        orgSignatureData: action.payload,
      };
    case types.ADD_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_FAILURE:
      return {
        ...state,
        addingOrgSignature: false,
        addingOrgSignatureError: true,
      };

    case types.GET_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_REQUEST:
      return {
        ...state,
        fetchingOrgSignature: true,
        fetchingOrgSignatureError: false,
      };
    case types.GET_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_SUCCESS:
      return {
        ...state,
        fetchingOrgSignature: false,
        fetchingOrgSignatureError: false,
        orgSignatureData: action.payload,
      };
    case types.GET_ORGANIZATION_SIGNATUE_BY_ORGANIZATION_ID_FAILURE:
      return {
        ...state,
        fetchingOrgSignature: false,
        fetchingOrgSignatureError: true,
      };

    case types.USER_LOGG_OFF_REQUEST:
      return { ...state, userLoggOffRequest: true };
    case types.USER_LOGG_OFF_SUCCESS:
      return {
        ...state,
        userLoggOffRequest: false,
        loggOffInd: action.payload,
        // teamDrawerProps: {
        //   ...state.teamDrawerProps,
        //   metaData: { ...state.teamDrawerProps.metaData },
        // },
        // users: state.users.map((user) => {
        //   if (user.userId === action.payload.userId) {
        //     return {
        //       ...user,
        //       metaData: { ...user.metaData },
        //     };
        //   } else {
        //     return user;
        //   }
        // }),
        // salesUsers: state.salesUsers.map((user) => {
        //   if (user.userId === action.payload.userId) {
        //     return {
        //       ...user,
        //       metaData: { ...user.metaData },
        //     };
        //   } else {
        //     return user;
        //   }
        // }),
        // partnerUsers: state.partnerUsers.map((user) => {
        //   if (user.userId === action.payload.userId) {
        //     return {
        //       ...user,
        //       metaData: { ...user.metaData },
        //     };
        //   } else {
        //     return user;
        //   }
        // }),
      };

    case types.USER_LOGG_OFF_FAILURE:
      return {
        ...state,
        userLoggOffRequest: false,
        userLoggOffRequestError: true,
      };

    /**
     * enable  Innoventory PRo for any user
     * only superuser can do this
     */

    case types.ENABLE_INNOVENTORY_PRO_REQUEST:
      return { ...state, enableInnoventoryProRequest: true };
    case types.ENABLE_INNOVENTORY_PRO_SUCCESS:
      return {
        ...state,
        enableInnoventoryProRequest: false,
        teamDrawerProps: {
          ...state.teamDrawerProps,
          metaData: {
            ...state.teamDrawerProps.metaData,
            inventoryInd: true,
          },
        },
        users: state.users.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, inventoryInd: true },
            };
          } else {
            return user;
          }
        }),
        salesUsers: state.salesUsers.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, inventoryInd: true },
            };
          } else {
            return user;
          }
        }),
      };

    case types.ENABLE_INNOVENTORY_PRO_FAILURE:
      return {
        ...state,
        enableInnoventoryProRequest: false,
        enableInnoventoryProRequestError: true,
      };

    //Ecommerce

    case types.ENABLE_ECOMMERCE_PRO_REQUEST:
      return { ...state, enableEcommerceProRequest: true };
    case types.ENABLE_ECOMMERCE_PRO_SUCCESS:
      return {
        ...state,
        enableEcommerceProRequest: false,
        teamDrawerProps: {
          ...state.teamDrawerProps,
          metaData: {
            ...state.teamDrawerProps.metaData,
            ecommerceInd: true,
          },
        },
        users: state.users.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, ecommerceInd: true },
            };
          } else {
            return user;
          }
        }),
        salesUsers: state.salesUsers.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, ecommerceInd: true },
            };
          } else {
            return user;
          }
        }),
      };

    case types.ENABLE_ECOMMERCE_PRO_FAILURE:
      return {
        ...state,
        enableEcommerceProRequest: false,
        enableEcommerceProRequestError: true,
      };


    /**
     * enable  Innoventory PRo for any user
     * only superuser can do this
     */

    case types.ENABLE_FASHION_PRO_REQUEST:
      return { ...state, enableFashionProRequest: true };
    case types.ENABLE_FASHION_PRO_SUCCESS:
      return {
        ...state,
        enableFashionProRequest: false,
        teamDrawerProps: {
          ...state.teamDrawerProps,
          metaData: {
            ...state.teamDrawerProps.metaData,
            garmentProInd: true,
          },
        },
        users: state.users.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, garmentProInd: true },
            };
          } else {
            return user;
          }
        }),
        salesUsers: state.salesUsers.map((user) => {
          if (user.organizationId === action.payload.organizationId) {
            return {
              ...user,
              metaData: { ...user.metaData, garmentProInd: true },
            };
          } else {
            return user;
          }
        }),
      };

    case types.ENABLE_FASHION_PRO_FAILURE:
      return {
        ...state,
        enableFashionProRequest: false,
        enableFashionProRequestError: true,
      };

    case types.HANDLE_LOCATION_MODAL:
      return { ...state, addLocationModal: action.payload };
    default:
      return state;
  }

  return state;
};
//  function allUsersTypesIndicatorChanger(params) {
//    return {

//     users: state.users.map(user => {
//     //////debugger;
//     if (user.organizationId === action.payload.organizationId) {
//       return {
//         ...user,
//         metaData: { ...user.metaData, riskManagementInd: true }
//       };
//     } else {
//       //////debugger;
//       return user;
//     }
//   }),
// }
//  }
