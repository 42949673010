import * as types from "./CampaignActionTypes";
const initialState = {
  viewType: "Existing",

  addingCampaign: false,
  addingCampaignError: false,

  fetchingCampaignData: false,
  fetchingCampaignDataError: false,
  campaignData: [],

  fetchingCampaignContactList: false,
  fetchingCampaignContactListError: false,
  campaignContactData: [],

  campaignDrawerVisible: false,
  campaignDrawerProps: {},

  campaignContactModal: false,

  campaignplannerStartDate: '',
  campaignplannerEndDate: '',
  campaignplannerStartTime: '',
  campaignplannerEndTime: '',
  chooserModal: false,
  viewEditModal: false,
  selectedEvent: {},
  openedFormModal: 'event',
};
export const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.HANDLE_CAMPAIGN_CONTACT_MODAL:
      return { ...state, campaignContactModal: action.payload };

    case types.SET_CAMPAIGN_VIEW_TYPE:
      return { ...state, viewType: action.payload };

    case types.ADD_CAMPAIGN_REQUEST:
      return { ...state, addingCampaign: true };
    case types.ADD_CAMPAIGN_SUCCESS:
      return { ...state, addingCampaign: false };
    case types.ADD_CAMPAIGN_FAILURE:
      return { ...state, addingCampaign: false, addingCampaignError: true };

    case types.GET_CAMPAIGN_REQUEST:
      return { ...state, fetchingCampaignData: true };
    case types.GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        fetchingCampaignData: false,
        campaignData: action.payload,
      };
    case types.GET_CAMPAIGN_FAILURE:
      return {
        ...state,
        fetchingCampaignData: false,
        fetchingCampaignDataError: true,
      };

    case types.HANDLE_CAMPAIGN_DRAWER:
      return {
        ...state,
        campaignDrawerVisible: action.payload.isVisible,
        campaignDrawerProps: action.payload.props,
      };

    case types.GET_CAMPAIGN_CONTACT_LIST_REQUEST:
      return { ...state, fetchingCampaignContactList: true };
    case types.GET_CAMPAIGN_CONTACT_LIST_SUCCESS:
      return {
        ...state,
        fetchingCampaignContactList: false,
        campaignContactData: action.payload,
      };
    case types.GET_CAMPAIGN_CONTACT_LIST_FAILURE:
      return {
        ...state,
        fetchingCampaignContactList: false,
        fetchingCampaignContactListError: true,
      };


      case types.SET_CAMPAIGNPLANNER_DATE:
        const { startDate, endDate, startTime, endTime } = action.payload;
        return { ...state, campaignplannerStartDate: startDate, campaignplannerEndDate: endDate, campaignplannerStartTime: startTime, campaignplannerEndTime: endTime };
    case types.HANDLE_EVENT_MODAL:
        return { ...state, addEventModal: action.payload };
    case types.HANDLE_VIEW_EDIT_MODAL:
        return { ...state, viewEditModal: action.payload.visible, selectedEvent: action.payload.event };
    case types.SET_DATE_AND_TIME:
        return { ...state };
    case types.SET_FORM_MODAL_TYPE:
        return { ...state, chooserModal: false };
    case types.HANDLE_CHOOSER_MODAL:
        return { ...state, chooserModal: action.payload };

    default:
      return state;
  }
};
