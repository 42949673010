export const UPDATE_PARTNER_ADDRESS = "UPDATE_PARTNER_ADDRESS";
export const ADD_PARTNER_ADDRESS = "ADD_PARTNER_ADDRESS";

export const HANDLE_PARTNER_MODAL = "HANDLE_PARTNER_MODAL";
export const HANDLE_LINK_PARTNER_MODAL = "HANDLE_LINK_PARTNER_MODAL";
export const HANDLE_ALLOW_SWITCH_OPEN_PARTNER_MODAL =
  "HANDLE_ALLOW_SWITCH_OPEN_PARTNER_MODAL";

export const HANDLE_LINK_UPDATE_PARTNER_MODAL =
  "HANDLE_LINK_UPDATE_PARTNER_MODAL";
export const SET_CURRENT_PARTNER = "SET_CURRENT_PARTNER";

export const ADD_PARTNER_REQUEST = "ADD_PARTNER_REQUEST";
export const ADD_PARTNER_SUCCESS = "ADD_PARTNER_SUCCESS";
export const ADD_PARTNER_FAILURE = "ADD_PARTNER_FAILURE";

export const ADD_LINK_PARTNER_REQUEST = "ADD_LINK_PARTNER_REQUEST";
export const ADD_LINK_PARTNER_SUCCESS = "ADD_LINK_PARTNER_SUCCESS";
export const ADD_LINK_PARTNER_FAILURE = "ADD_LINK_PARTNER_FAILURE";

export const UPDATE_PARTNER_REQUEST = "UPDATE_PARTNER_REQUEST";
export const UPDATE_PARTNER_SUCCESS = "UPDATE_PARTNER_SUCCESS";
export const UPDATE_PARTNER_FAILURE = "UPDATE_PARTNER_FAILURE";

export const UPDATE_PARTNER_VISIBILITY_REQUEST =
  "UPDATE_PARTNER_VISIBILITY_REQUEST";
export const UPDATE_PARTNER_VISIBILITY_SUCCESS =
  "UPDATE_PARTNER_VISIBILITY_SUCCESS";
export const UPDATE_PARTNER_VISIBILITY_FAILURE =
  "UPDATE_PARTNER_VISIBILITY_FAILURE";

export const GET_PARTNER_REQUEST = "GET_PARTNER_REQUEST";
export const GET_PARTNER_SUCCESS = "GET_PARTNER_SUCCESS";
export const GET_PARTNER_FAILURE = "GET_PARTNER_FAILURE";

export const GET_LINK_OPPORTUNITY_PARTNER_REQUEST =
  "GET_LINK_OPPORTUNITY_PARTNER_REQUEST";
export const GET_LINK_OPPORTUNITY_PARTNER_SUCCESS =
  "GET_LINK_OPPORTUNITY_PARTNER_SUCCESS";
export const GET_LINK_OPPORTUNITY_PARTNER_FAILURE =
  "GET_LINK_OPPORTUNITY_PARTNER_FAILURE";

export const GET_PARTNER_NAME_REQUEST = "GET_PARTNER_NAME_REQUEST";
export const GET_PARTNER_NAME_SUCCESS = "GET_PARTNER_NAME_SUCCESS";
export const GET_PARTNER_NAME_FAILURE = "GET_PARTNER_NAME_FAILURE";

export const SET_PARTNER_VIEW_TYPE = "SET_PARTNER_VIEW_TYPE";

//partner colaboratE

export const ADD_COLLABORATE_PARTNER_REQUEST =
  "ADD_COLLABORATE_PARTNER_REQUEST";
export const ADD_COLLABORATE_PARTNER_SUCCESS =
  "ADD_COLLABORATE_PARTNER_SUCCESS";
export const ADD_COLLABORATE_PARTNER_FAILURE =
  "ADD_COLLABORATE_PARTNER_FAILURE";

export const GET_PARTNER_BY_ID_REQUEST = "GET_PARTNER_BY_ID_REQUEST";
export const GET_PARTNER_BY_ID_SUCCESS = "GET_PARTNER_BY_ID_SUCCESS";
export const GET_PARTNER_BY_ID_FAILURE = "GET_PARTNER_BY_ID_FAILURE";

export const UPDATE_PARTNER_BY_ID_REQUEST = "UPDATE_PARTNER_BY_ID_REQUEST";
export const UPDATE_PARTNER_BY_ID_SUCCESS = "UPDATE_PARTNER_BY_ID_SUCCESS";
export const UPDATE_PARTNER_BY_ID_FAILURE = "UPDATE_PARTNER_BY_ID_FAILURE";
// for user
export const GET_PARTNER_OPPORTUNITY_BY_USER_ID_REQUEST =
  "GET_PARTNER_OPPORTUNITY_BY_USER_ID_REQUEST";
export const GET_PARTNER_OPPORTUNITY_BY_USER_ID_SUCCESS =
  "GET_PARTNER_OPPORTUNITY_BY_USER_ID_SUCCESS";
export const GET_PARTNER_OPPORTUNITY_BY_USER_ID_FAILURE =
  "GET_PARTNER_OPPORTUNITY_BY_USER_ID_FAILURE";

// for partner

export const GET_PARTNER_OPPORTUNITY_BY_PARTNER_ID_REQUEST =
  "GET_PARTNER_OPPORTUNITY_BY_PARTNER_ID_REQUEST";
export const GET_PARTNER_OPPORTUNITY_BY_PARTNER_ID_SUCCESS =
  "GET_PARTNER_OPPORTUNITY_BY_PARTNER_ID_SUCCESS";
export const GET_PARTNER_OPPORTUNITY_BY_PARTNER_ID_FAILURE =
  "GET_PARTNER_OPPORTUNITY_BY_PARTNER_ID_FAILURE";


//For Professional +

export const HANDLE_PARTNER_CONTACT_MODAL = "HANDLE_PARTNER_CONTACT_MODAL";
export const ADD_PARTNER_CONTACT_BY_PARTNERID_ID_REQUEST =
  "ADD_PARTNER_CONTACT_BY_PARTNERID_ID_REQUEST";
export const ADD_PARTNER_CONTACT_BY_PARTNERID_ID_SUCCESS =
  "ADD_PARTNER_CONTACT_BY_PARTNERID_ID_SUCCESS";
export const ADD_PARTNER_CONTACT_BY_PARTNERID_ID_FAILURE =
  "ADD_PARTNER_CONTACT_BY_PARTNERID_ID_FAILURE";

export const GET_PARTNER_CONTACT_BY_PARTNERID_ID_REQUEST =
  "GET_PARTNER_CONTACT_BY_PARTNERID_ID_REQUEST";
export const GET_PARTNER_CONTACT_BY_PARTNERID_ID_SUCCESS =
  "GET_PARTNER_CONTACT_BY_PARTNERID_ID_SUCCESS";
export const GET_PARTNER_CONTACT_BY_PARTNERID_ID_FAILURE =
  "GET_PARTNER_CONTACT_BY_PARTNERID_ID_FAILURE";

export const GET_PARTNER_TASK_BY_PARTNERID_ID_REQUEST =
  "GET_PARTNER_TASK_BY_PARTNERID_ID_SUCCESS";
export const GET_PARTNER_TASK_BY_PARTNERID_ID_SUCCESS =
  "GET_PARTNER_TASK_BY_PARTNERID_ID_SUCCESS";
export const GET_PARTNER_TASK_BY_PARTNERID_ID_FAILURE =
  "GET_PARTNER_TASK_BY_PARTNERID_ID_FAILURE";

export const SET_ACCOUNT_VIEW_TYPE = "SET_ACCOUNT_VIEW_TYPE";

export const CHANGE_SELECTED_TIME_INTERVAL_CATALOGUE =
  "CHANGE_SELECTED_TIME_INTERVAL_CATALOGUE";

export const SET_TIME_INTERVAL_CATALOGUE = "SET_TIME_INTERVAL_CATALOGUE";

//get topics of interest by partnerId
export const GET_TOPICS_BY_PARTNER_ID_REQUEST =
  "GET_TOPICS_BY_PARTNER_ID_REQUEST";
export const GET_TOPICS_BY_PARTNER_ID_SUCCESS =
  "GET_TOPICS_BY_PARTNER_ID_SUCCESS";
export const GET_TOPICS_BY_PARTNER_ID_FAILURE =
  "GET_TOPICS_BY_PARTNER_ID_FAILURE";

/**
 * add topic of a partnerId
 */
export const ADD_TOPIC_BY_PARTNER_ID_REQUEST =
  "ADD_TOPIC_BY_PARTNER_ID_REQUEST";
export const ADD_TOPIC_BY_PARTNER_ID_SUCCESS =
  "ADD_TOPIC_BY_PARTNER_ID_SUCCESS";
export const ADD_TOPIC_BY_PARTNER_ID_FAILURE =
  "ADD_TOPIC_BY_PARTNER_ID_FAILURE";

/**
 * delete topics of interest by partnerId
 */

export const DELETE_TOPIC_BY_PARTNER_ID_REQUEST =
  "DELETE_TOPIC_BY_PARTNER_ID_REQUEST";
export const DELETE_TOPIC_BY_PARTNER_ID_SUCCESS =
  "DELETE_TOPIC_BY_PARTNER_ID_SUCCESS";
export const DELETE_TOPIC_BY_PARTNER_ID_FAILURE =
  "DELETE_TOPIC_BY_PARTNER_ID_FAILURE";

//filter partner by Initiative

export const GET_INITIATIVE_WISE_PARTNER_REQUEST =
  "GET_INITIATIVE_WISE_PARTNER_REQUEST";
export const GET_INITIATIVE_WISE_PARTNER_SUCCESS =
  "GET_INITIATIVE_WISE_PARTNER_SUCCESS";
export const GET_INITIATIVE_WISE_PARTNER_FAILURE =
  "GET_INITIATIVE_WISE_PARTNER_FAILURE";

//PARTNER Topic List

export const GET_PARTNER_TOPIC_LIST_REQUEST = "GET_PARTNER_TOPIC_LIST_REQUEST";
export const GET_PARTNER_TOPIC_LIST_SUCCESS = "GET_PARTNER_TOPIC_LIST_SUCCESS";
export const GET_PARTNER_TOPIC_LIST_FAILURE = "GET_PARTNER_TOPIC_LIST_FAILURE";

//USER notes
export const GET_NOTES_LIST_BY_PARTNER_ID_REQUEST =
  "GET_NOTES_LIST_BY_PARTNER_ID_REQUEST";
export const GET_NOTES_LIST_BY_PARTNER_ID_SUCCESS =
  "GET_NOTES_LIST_BY_PARTNER_ID_SUCCESS";
export const GET_NOTES_LIST_BY_PARTNER_ID_FAILURE =
  "GET_NOTES_LIST_BY_PARTNER_ID_FAILURE";

// partnerUser notes

export const GET_NOTES_LIST_BY_USER_ID_REQUEST =
  "GET_NOTES_LIST_BY_USER_ID_REQUEST";
export const GET_NOTES_LIST_BY_USER_ID_SUCCESS =
  "GET_NOTES_LIST_BY_USER_ID_SUCCESS";
export const GET_NOTES_LIST_BY_USER_ID_FAILURE =
  "GET_NOTES_LIST_BY_USER_ID_FAILURE";

export const HANDLE_PARTNER_DRAWER = "HANDLE_PARTNER_DRAWER";
export const HANDLE_AVERAGE_MODAL = "HANDLE_AVERAGE_MODAL";
export const HANDLE_ACTIVE_MODAL = "HANDLE_ACTIVE_MODAL";
export const HANDLE_REGISTERED_MODAL = "HANDLE_REGISTERED_MODAL";
export const HANDLE_WIN_MODAL = "HANDLE_WIN_MODAL";
export const HANDLE_WON_MODAL = "HANDLE_WON_MODAL";
export const HANDLE_ACCOUNTSADDED_MODAL = "HANDLE_ACCOUNTSADDED_MODAL";

export const HANDLE_PULSEAVERAGE_MODAL = "HANDLE_PULSEAVERAGE_MODAL";
export const HANDLE_PULSEACTIVE_MODAL = "HANDLE_PULSEACTIVE_MODAL";
export const HANDLE_PULSEWIN_MODAL = "HANDLE_PULSEWIN_MODAL";
export const HANDLE_PULSEWON_MODAL = "HANDLE_PULSEWON_MODAL";

export const HANDLE_BAR_MODAL = "HANDLE_BAR_MODAL";
export const HANDLE_PARTNER_TABLE_CONTACT_MODAL =
  "HANDLE_PARTNER_TABLE_CONTACT_MODAL";

export const GET_PARTNER_CONTACT_BY_PARTNER_ID_REQUEST =
  "GET_PARTNER_CONTACT_BY_PARTNER_ID_REQUEST";
export const GET_PARTNER_CONTACT_BY_PARTNER_ID_SUCCESS =
  "GET_PARTNER_CONTACT_BY_PARTNER_ID_SUCCESS";
export const GET_PARTNER_CONTACT_BY_PARTNER_ID_FAILURE =
  "GET_PARTNER_CONTACT_BY_PARTNER_ID_FAILURE";

//partner share
export const ADD_SHAREPARTNER_REQUEST = "ADD_SHAREPARTNER_REQUEST";
export const ADD_SHAREPARTNER_SUCCESS = "ADD_SHAREPARTNER_SUCCESS";
export const ADD_SHAREPARTNER_FAILURE = "ADD_PARTNER_FAILURE";

export const GET_SHAREPARTNER_USERS_REQUEST = "GET_SHAREPARTNER_USERS_REQUEST";
export const GET_SHAREPARTNER_USERS_SUCCESS = "GET_SHAREPARTNER_USERS_SUCCESS";
export const GET_SHAREPARTNER_USERS_FAILURE = "GET_SHAREPARTNER_USERS_FAILURE";

export const GET_SHAREPARTNER_REQUEST = "GET_SHAREPARTNER_REQUEST";
export const GET_SHAREPARTNER_SUCCESS = "GET_SHAREPARTNER_SUCCESS";
export const GET_SHAREPARTNER_FAILURE = "GET_SHAREPARTNER_FAILURE";

export const GET_MAP_DASHBOARD_PARTNER_REQUEST =
  "GET_MAP_DASHBOARD_PARTNER_REQUEST";
export const GET_MAP_DASHBOARD_PARTNER_SUCCESS =
  "GET_MAP_DASHBOARD_PARTNER_SUCCESS";
export const GET_MAP_DASHBOARD_PARTNER_FAILURE =
  "GET_MAP_DASHBOARD_PARTNER_FAILURE";

export const INPUT_SEARCH_PARTNER_DATA_REQUEST = "INPUT_SEARCH_PARTNER_DATA_REQUEST";
export const INPUT_SEARCH_PARTNER_DATA_SUCCESS = "INPUT_SEARCH_PARTNER_DATA_SUCCESS";
export const INPUT_SEARCH_PARTNER_DATA_FAILURE = "INPUT_SEARCH_PARTNER_DATA_FAILURE";

export const GET_PARTNER_HISTORY_REQUEST = "GET_PARTNER_HISTORY_REQUEST";
export const GET_PARTNER_HISTORY_SUCCESS = "GET_PARTNER_HISTORY_SUCCESS";
export const GET_PARTNER_HISTORY_FAILURE = "GET_PARTNER_HISTORY_FAILURE";

export const GET_FEEDBACK_BY_PARTNER_ID_REQUEST =
"GET_FEEDBACK_BY_PARTNER_ID_REQUEST";
export const GET_FEEDBACK_BY_PARTNER_ID_SUCCESS =
"GET_FEEDBACK_BY_PARTNER_ID_SUCCESS";
export const GET_FEEDBACK_BY_PARTNER_ID_FAILURE =
"GET_FEEDBACK_BY_PARTNER_ID_FAILURE";

export const HANDLE_FEEDBACK_MODAL = "HANDLE_FEEDBACK_MODAL";