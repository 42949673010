import * as types from "./ReleaseNoteActionTypes";

const initialState = {
  fetchingReleaseNote: false,
  fetchingReleaseNoteError: false,
  releaseNote: [],

  addReleaseNoteData: false,
  addReleaseNoteDataError: false,

  fetchingNewReleaseNote: false,
  fetchingNewReleaseNoteError:false,
  newReleaseNote: {},

  addReleaseModal: false,
};

export const releaseNoteReducer = (state = initialState, action) => {
    switch (action.type) {

    case types.GET_RELEASENOTE_REQUEST:
      return { ...state, fetchingReleaseNote: true };
    case types.GET_RELEASENOTE_SUCCESS:
      return {
        ...state,
        fetchingReleaseNote: false,
        releaseNote: action.payload,
      };
    case types.GET_RELEASENOTE_FAILURE:
      return {
        ...state,
        fetchingReleaseNote: false,
        fetchingReleaseNoteError: true,
      };

    
    case types.ADD_RELEASENOTE_REQUEST:
      return { ...state, addReleaseNoteData: true };
    case types.ADD_RELEASENOTE_SUCCESS:
      return {
        ...state,
        addReleaseNoteData: false,
        addReleaseModal: false,
      };
    case types.ADD_RELEASENOTE_FAILURE:
      return {
        ...state,
        addReleaseNoteData: false,
        addReleaseNoteDataError: true,
      };


    case types.GET_NEW_RELEASENOTE_REQUEST:
      return { ...state, fetchingNewReleaseNote: true};
    case types.GET_NEW_RELEASENOTE_SUCCESS:
      return {
        ...state,
        fetchingNewReleaseNote: false,
        newReleaseNote: action.payload,
      };
    case types.GET_NEW_RELEASENOTE_FAILURE:
      return {
        ...state,
        fetchingNewReleaseNote: false,
        fetchingNewReleaseNoteError: true,
      };

    default:
      return state;
  }
  return state;
};