import * as types from "./FeedBackActionType";
const initialState = {
  addingFeedBack: false,
  addingFeedBackError: false,
  fetchingFeedBack: false,
  fetchingFeedBackError: false,
  feedBackData: [],
  feedBackModal: false,
  feedbackData: {},
};
export const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.HANDLE_FEED_BACK_MODAL:
      return { ...state, feedBackModal: action.payload };

    case types.HANDLE_PARTICULAR_DATA:
      return { ...state, feedbackData: action.payload };

    case types.ADD_FEED_BACK_REQUEST:
      return { ...state, addingFeedBack: true };
    case types.ADD_FEED_BACK_SUCCESS:
      return { ...state, addingFeedBack: false, feedBackModal: false };
    case types.ADD_FEED_BACK_FAILURE:
      return {
        ...state,
        addingFeedBack: false,
        addingFeedBackError: false,
      };

    case types.GET_FEED_BACK_REQUEST:
      return { ...state, fetchingFeedBack: true };
    case types.GET_FEED_BACK_SUCCESS:
      return {
        ...state,
        fetchingFeedBack: false,
        feedBackData: action.payload,
      };
    case types.GET_FEED_BACK_FAILURE:
      return {
        ...state,
        fetchingFeedBack: false,
        fetchingFeedBackError: false,
      };
    default:
      return state;
  }
};
