export const ADD_EVENT_REQUEST = "ADD_EVENT_REQUEST";
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const ADD_EVENT_FAILURE = "ADD_EVENT_FAILURE";

export const DELETE_EVENT_REQUEST = "DELETE_EVENT_REQUEST";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAILURE = "DELETE_EVENT_FAILURE";

export const UPDATE_EVENT_BY_ID_REQUEST = "UPDATE_EVENT_BY_ID_REQUEST";
export const UPDATE_EVENT_BY_ID_SUCCESS = "UPDATE_EVENT_BY_ID_SUCCESS";
export const UPDATE_EVENT_BY_ID_FAILURE = "UPDATE_EVENT_BY_ID_FAILURE";

export const GET_EVENT_LIST_RANGE_BY_USER_ID_REQUEST =
  "GET_EVENT_LIST_RANGE_BY_USER_ID_REQUEST";
export const GET_EVENT_LIST_RANGE_BY_USER_ID_SUCCESS =
  "GET_EVENT_LIST_RANGE_BY_USER_ID_SUCCESS";
export const GET_EVENT_LIST_RANGE_BY_USER_ID_FAILURE =
  "GET_EVENT_LIST_RANGE_BY_USER_ID_FAILURE";

export const GET_EVENT_LIST_RANGE_OF_ALL_USERS_REQUEST =
  "GET_EVENT_LIST_RANGE_OF_ALL_USERS_REQUEST";
export const GET_EVENT_LIST_RANGE_OF_ALL_USERS_SUCCESS =
  "GET_EVENT_LIST_RANGE_OF_ALL_USERS_SUCCESS";
export const GET_EVENT_LIST_RANGE_OF_ALL_USERS_FAILURE =
  "GET_EVENT_LIST_RANGE_OF_ALL_USERS_FAILURE";

export const HANDLE_EVENT_MODAL = "HANDLE_EVENT_MODAL";

export const SET_RATING_VALUE = "SET_RATING_VALUE";

export const SET_ID_VALUE = "SET_ID_VALUE";

export const GET_NOTES_LIST_BY_EVENT_ID_REQUEST =
  "GET_NOTES_LIST_BY_EVENT_ID_REQUEST";
export const GET_NOTES_LIST_BY_EVENT_ID_SUCCESS =
  "GET_NOTES_LIST_BY_EVENT_ID_SUCCESS";
export const GET_NOTES_LIST_BY_EVENT_ID_FAILURE =
  "GET_NOTES_LIST_BY_EVENT_ID_FAILURE";

export const SET_NOTE_EVENT_EDIT = "SET_NOTE_EVENT_EDIT";

export const GET_EVENT_LIST_RANGE_BY_TYPE_REQUEST =
  "GET_EVENT_LIST_RANGE_BY_TYPE_REQUEST";
export const GET_EVENT_LIST_RANGE_BY_TYPE_SUCCESS =
  "GET_EVENT_LIST_RANGE_BY_TYPE_SUCCESS";
export const GET_EVENT_LIST_RANGE_BY_TYPE_FAILURE =
  "GET_EVENT_LIST_RANGE_BY_TYPE_FAILURE";

export const GET_EVENT_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST =
  "GET_EVENT_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST";
export const GET_EVENT_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS =
  "GET_EVENT_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS";
export const GET_EVENT_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE =
  "GET_EVENT_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE";
