export const SET_SURVEY_VIEW_TYPE = "SET_SURVEY_VIEW_TYPE";
export const CHANGE_SELECTED_TIME_INTERVAL_SURVEY =
  "CHANGE_SELECTED_TIME_INTERVAL_SURVEY";
export const SET_TIME_INTERVAL_SURVEY = "SET_TIME_INTERVAL_SURVEY";

export const CREATE_SURVEY_REQUEST = "CREATE_SURVEY_REQUEST";
export const CREATE_SURVEY_SUCCESS = "CREATE_SURVEY_SUCCESS";
export const CREATE_SURVEY_FAILURE = "CREATE_SURVEY_FAILURE";

export const GET_EXISTING_SURVEY_REQUEST = "GET_EXISTING_SURVEY_REQUEST";
export const GET_EXISTING_SURVEY_SUCCESS = "GET_EXISTING_SURVEY_SUCCESS";
export const GET_EXISTING_SURVEY_FAILURE = "GET_EXISTING_SURVEY_FAILURE";
