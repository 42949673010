import * as types from "./AccountActionTypes";
import axios from "axios";
import moment from "moment";
import { base_url } from "../../Config/Auth";
import { message } from "antd";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

/**
 * get all the account of the user
 */
export const getAccounts = (userId) => (dispatch) => {
  let api_url = "";
  if (userId) {
    api_url = `/sort/all/accounts/user/${userId}`;
  } else {
    api_url = `/accounts`;
  }
  console.log(base_url);
  dispatch({
    type: types.GET_ACCOUNTS_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ACCOUNTS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ACCOUNTS_FAILURE,
        payload: err,
      });
    });
};

export const getAccountsMapOnDashboard = (userId) => (dispatch) => {
  dispatch({
    type: types.GET_MAP_DASHBOARD_ACCOUNTS_REQUEST,
  });
  axios
    .get(`${base_url}/map/accounts/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_MAP_DASHBOARD_ACCOUNTS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_MAP_DASHBOARD_ACCOUNTS_FAILURE,
        payload: err,
      });
    });
};
/**
 * get all the latest accounts of the user
 */
export const getLatestAccounts = (userId, startDate, endDate) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/sort/accounts/user/${userId}?sort=latest`;
  } else {
    api_url = `/sort/accounts/user/${userId}?sort=latest&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_LATEST_ACCOUNTS_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_LATEST_ACCOUNTS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_LATEST_ACCOUNTS_FAILURE,
        payload: err,
      });
    });
};
export const getAllLatestAccounts = (userId, startDate, endDate) => (
  dispatch
) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/sort/all/accounts/user/${userId}?sort=latest`;
  } else {
    api_url = `/sort/all/accounts/user/${userId}?sort=latest&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_ALL_LATEST_ACCOUNTS_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ALL_LATEST_ACCOUNTS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ALL_LATEST_ACCOUNTS_FAILURE,
        payload: err,
      });
    });
};

//Account report fo lazy loading
export const getAllLatestAccountsForLazyLoading = (
  pageNo,
  userId,
  startDate,
  endDate
) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/sort/all/accounts/user/${userId}?sort=latest`;
  } else {
    api_url = `/myView/report/account/${pageNo}?userId=${userId}&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_ALL_LATEST_ACCOUNTS_FOR_LAZY_LOADING_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ALL_LATEST_ACCOUNTS_FOR_LAZY_LOADING_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ALL_LATEST_ACCOUNTS_FOR_LAZY_LOADING_FAILURE,
        payload: err,
      });
    });
};
/**
 * get all the latest accounts of the user
 */
export const getLatestAccountsByOrganizationIdForLazyLoading = (
  pageNo,
  organizationId,
  startDate,
  endDate
) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/sort/accounts/organization/${organizationId}?`;
  } else {
    api_url = `/orgView/report/account/${pageNo}?orgId=${organizationId}&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_LATEST_ACCOUNTS_BY_ORGANIZATION_ID_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_LATEST_ACCOUNTS_BY_ORGANIZATION_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_LATEST_ACCOUNTS_BY_ORGANIZATION_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * get all the accounts of the user
 */
export const getAccountsByFunnelValue = (userId, startDate, endDate) => (
  dispatch
) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/sort/accounts/user/${userId}?sort=funnel`;
  } else {
    api_url = `/sort/accounts/user/${userId}?sort=funnel&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_ACCOUNTS_BY_FUNNEL_VALUE_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ACCOUNTS_BY_FUNNEL_VALUE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ACCOUNTS_BY_FUNNEL_VALUE_FAILURE,
        payload: err,
      });
    });
};
export const getAllAccountsByFunnelValue = (userId, startDate, endDate) => (
  dispatch
) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/sort/all/accounts/user/${userId}?sort=funnel`;
  } else {
    api_url = `/sort/all/accounts/user/${userId}?sort=funnel&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_ALL_ACCOUNTS_BY_FUNNEL_VALUE_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ALL_ACCOUNTS_BY_FUNNEL_VALUE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ALL_ACCOUNTS_BY_FUNNEL_VALUE_FAILURE,
        payload: err,
      });
    });
};
/**
 * get all the existing accounts of the user
 */
export const getExistingAccounts = (userId) => (dispatch) => {
  dispatch({ type: types.GET_EXISTING_ACCOUNTS_REQUEST });
  axios
    .get(`${base_url}/existing/accounts/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_EXISTING_ACCOUNTS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_EXISTING_ACCOUNTS_FAILURE,
        payload: err,
      });
    });
};

export const getAllExistingAccounts = (userId) => (dispatch) => {
  dispatch({ type: types.GET_ALL_EXISTING_ACCOUNTS_REQUEST });
  axios
    .get(`${base_url}/existing/all/accounts/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ALL_EXISTING_ACCOUNTS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ALL_EXISTING_ACCOUNTS_FAILURE,
        payload: err,
      });
    });
};

/**
 * get all the top ten accounts of the user
 */
export const getTopTenAccount = (userId, startDate, endDate) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/accounts/topTen/user/${userId}`;
  } else {
    api_url = `/accounts/topTen/user/${userId}?startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_TOP_TEN_ACCOUNTS_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_TOP_TEN_ACCOUNTS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_TOP_TEN_ACCOUNTS_FAILURE,
        payload: err,
      });
    });
};

/**
 * get all the existing accounts of the user
 */
export const getNewExistingAccounts = (userId) => (dispatch) => {
  dispatch({ type: types.GET_NEW_EXISTING_ACCOUNTS_REQUEST });
  axios
    .get(`${base_url}/ratio/accounts/myView/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      //debugger;
      console.log(res);
      dispatch({
        type: types.GET_NEW_EXISTING_ACCOUNTS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_NEW_EXISTING_ACCOUNTS_FAILURE,
        payload: err,
      });
    });
};

/**
 * get all the existing accounts of all user
 */
export const getNewExistingAccountsOfAllUsers = (organizationId) => (
  dispatch
) => {
  dispatch({ type: types.GET_NEW_EXISTING_ACCOUNTS_OF_ALL_USERS_REQUEST });
  axios
    .get(`${base_url}/ratio/accounts/organizationView/${organizationId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_NEW_EXISTING_ACCOUNTS_OF_ALL_USERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_NEW_EXISTING_ACCOUNTS_OF_ALL_USERS_FAILURE,
        payload: err,
      });
    });
};

/*
 * get all the existing accounts of all user
 */
export const getNewExistingAccountsOfAllTeams = (organizationId) => (
  dispatch
) => {
  dispatch({ type: types.GET_NEW_EXISTING_ACCOUNTS_OF_ALL_TEAMS_REQUEST });
  axios
    .get(`${base_url}/team/ratio/accounts/organization/${organizationId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_NEW_EXISTING_ACCOUNTS_OF_ALL_TEAMS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_NEW_EXISTING_ACCOUNTS_OF_ALL_TEAMS_FAILURE,
        payload: err,
      });
    });
};

/*
 * get all the existing accounts of a team
 */
export const getNewExistingAccountsByTeamId = (teamId) => (dispatch) => {
  dispatch({ type: types.GET_NEW_EXISTING_ACCOUNTS_BY_TEAM_ID_REQUEST });
  axios
    .get(`${base_url}/team/ratio/accounts/${teamId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_NEW_EXISTING_ACCOUNTS_BY_TEAM_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_NEW_EXISTING_ACCOUNTS_BY_TEAM_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get a specific account of the user with the accountId
 */
export const getAccountById = (accountId) => (dispatch) => {
  console.log("inside getAccountById");
  dispatch({
    type: types.GET_ACCOUNT_BY_ID_REQUEST,
  });
  axios
    .get(`${base_url}/account/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ACCOUNT_BY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ACCOUNT_BY_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get list of accounts added by an user inside a date range
 */
export const getAccountListRangeByUserId = (userId, startDate, endDate) => (
  dispatch
) => {
  dispatch({
    type: types.GET_ACCOUNT_LIST_RANGE_BY_USER_ID_REQUEST,
  });
  axios
    .get(
      `${base_url}/account/user/${userId}?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ACCOUNT_LIST_RANGE_BY_USER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ACCOUNT_LIST_RANGE_BY_USER_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * get list of accounts added by all users of an organization
 */
export const getAccountListRangeOfAllUsers = (startDate, endDate) => (
  dispatch
) => {
  dispatch({
    type: types.GET_ACCOUNT_LIST_RANGE_OF_ALL_USERS_REQUEST,
  });
  axios
    .get(`${base_url}/accounts?startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ACCOUNT_LIST_RANGE_OF_ALL_USERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ACCOUNT_LIST_RANGE_OF_ALL_USERS_FAILURE,
        payload: err,
      });
    });
};
/**
 * get contact list by accountId
 */
export const getContactListByAccountId = (accountId) => (dispatch) => {
  console.log(accountId);
  dispatch({
    type: types.GET_CONTACT_LIST_BY_ACCOUNT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/contact/account/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CONTACT_LIST_BY_ACCOUNT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CONTACT_LIST_BY_ACCOUNT_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get opportunity list by accountId
 */
export const getOpportunityListByAccountId = (accountId) => (dispatch) => {
  console.log(accountId);
  dispatch({
    type: types.GET_OPPORTUNITY_LIST_BY_ACCOUNT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/opportunity/account/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_OPPORTUNITY_LIST_BY_ACCOUNT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_OPPORTUNITY_LIST_BY_ACCOUNT_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get funnel value by accountId
 */
export const getFunnelValueByAccountId = (accountId) => (dispatch) => {
  dispatch({
    type: types.FUNNEL_VALUE_BY_ACCOUNT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/funnel/account/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.FUNNEL_VALUE_BY_ACCOUNT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.FUNNEL_VALUE_BY_ACCOUNT_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * get notes list by accountId
 */
export const getNotesListByAccountId = (accountId) => (dispatch) => {
  console.log(accountId);
  dispatch({
    type: types.GET_NOTES_LIST_BY_ACCOUNT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/note/account/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_NOTES_LIST_BY_ACCOUNT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_NOTES_LIST_BY_ACCOUNT_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get call list by accountId
 */
export const getCallsListByAccountId = (accountId) => (dispatch) => {
  console.log(accountId);
  dispatch({
    type: types.GET_CALLS_LIST_BY_ACCOUNT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/call/account/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CALLS_LIST_BY_ACCOUNT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CALLS_LIST_BY_ACCOUNT_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get task list by accountId
 */
export const getTasksListByAccountId = (accountId) => (dispatch) => {
  console.log(accountId);
  dispatch({
    type: types.GET_TASKS_LIST_BY_ACCOUNT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/task/account/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_TASKS_LIST_BY_ACCOUNT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_TASKS_LIST_BY_ACCOUNT_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get event list by accountId
 */
export const getEventsListByAccountId = (accountId) => (dispatch) => {
  console.log(accountId);
  dispatch({
    type: types.GET_EVENTS_LIST_BY_ACCOUNT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/event/account/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_EVENTS_LIST_BY_ACCOUNT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_EVENTS_LIST_BY_ACCOUNT_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * add competitor of an accountId
 */
export const addCompetitorByAccountId = (competitor) => (dispatch) => {
  console.log(competitor);
  dispatch({
    type: types.ADD_COMPETITOR_BY_ACCOUNT_ID_REQUEST,
  });
  axios
    .post(`${base_url}/competitor`, competitor, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.ADD_COMPETITOR_BY_ACCOUNT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_COMPETITOR_BY_ACCOUNT_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * get event list by accountId
 */
export const getCompetitorsByAccountId = (accountId) => (dispatch) => {
  console.log(accountId);
  dispatch({
    type: types.GET_COMPETITORS_BY_ACCOUNT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/competitor/account/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_COMPETITORS_BY_ACCOUNT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_COMPETITORS_BY_ACCOUNT_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * get event list by accountId
 */
export const getRenewalsByAccountId = (accountId) => (dispatch) => {
  console.log(accountId);
  dispatch({
    type: types.GET_RENEWALS_BY_ACCOUNT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/renewal/account/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_RENEWALS_BY_ACCOUNT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_RENEWALS_BY_ACCOUNT_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * request for adding a account
 */
export const addAccount = (account, cb) => (dispatch, getState) => {
  const userId = getState().auth.userDetails.userId;
  dispatch({
    type: types.ADD_ACCOUNT_REQUEST,
  });

  axios
    .post(`${base_url}/account`, account, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      const startDate = moment()
        .startOf("month")
        .toISOString();
      const endDate = moment()
        .endOf("month")
        .toISOString();
      dispatch(getAccounts(userId));
      dispatch(getLatestAccounts(userId, startDate, endDate));
      dispatch(getAccountsByFunnelValue(userId));
      dispatch({
        type: types.ADD_ACCOUNT_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_ACCOUNT_FAILURE,
        payload: err,
      });
      cb && cb("unable to create");
    });
};

/**
 * update aspecific field using put request
 */
export const updateAccount = (id, data, cb) => (dispatch) => {
  console.log(data);
  dispatch({ type: types.UPDATE_ACCOUNT_BY_ID_REQUEST });
  axios
    .put(
      `${base_url}/account/${id}`,
      { ...data },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_ACCOUNT_BY_ID_SUCCESS,
        payload: res.data,
      });
      cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_ACCOUNT_BY_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * account modal action
 */
export const handleAccountModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_ACCOUNT_MODAL,
    payload: modalProps,
  });
};

// Timeline modal
export const handleAccountTimelineModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_ACCOUNT_TIMELINE_MODAL,
    payload: modalProps,
  });
};
/**
 * account link modal action
 */
export const handleLinkAccountModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_LINK_ACCOUNT_MODAL,
    payload: modalProps,
  });
};

/**
 * account modal drawer
 */
export const handleAccountDrawer = (drawerProps, isVisible) => (dispatch) => {
  dispatch({
    type: types.HANDLE_ACCOUNT_DRAWER,
    payload: { props: drawerProps, isVisible: isVisible },
  });
};

/**
 * set company name, domain and logo from clearbit
 */
export const setClearbitData = (data) => (dispatch) => {
  dispatch({
    type: types.SET_CLEARBIT_DATA,
    payload: data,
  });
};

export const setClearbitProductData = (data) => (dispatch) => {
  dispatch({
    type: types.SET_CLEARBIT_PRODUCT_DATA,
    payload: data,
  });
};

export const setClearbitServiceData = (data) => (dispatch) => {
  dispatch({
    type: types.SET_CLEARBIT_SERVICE_DATA,
    payload: data,
  });
};
/**
 * SET ACCCOUNT VIEW TYPE
 * TABLE VIEW/CARD VIEW
 */
export const setAccountViewType = (viewType) => (dispatch) =>
  dispatch({ type: types.SET_ACCOUNT_VIEW_TYPE, payload: viewType });

/**
 * set account dashboard type to individual or all
 */
export const setAccountDashboardType = (type) => (dispatch) =>
  dispatch({
    type: types.SET_ACCOUNT_DASHBOARD_TYPE,
    payload: type,
  });

/**
 * SET ACCCOUNT FILTER ETXT
 */
export const setAccountFilterText = (accountFilterText) => (dispatch) =>
  dispatch({ type: types.SET_ACCOUNT_FILTER_TEXT, payload: accountFilterText });
/**
 * set account filter by User
 */
export const setAccountFilterUser = (user) => (dispatch) =>
  dispatch({ type: types.SET_ACCOUNT_FILTER_USER, payload: user });
//Before Link check

export const linkContactsCheckToAccount = (accountId, associations, cb) => (
  dispatch
) => {
  console.log(accountId, associations);
  dispatch({
    type: types.LINK_CONTACTS_TO_CHECK_ACCOUNT_ID_REQUEST,
  });
  axios
    .post(`${base_url}/check/contact/${accountId}`, associations, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      if (res.data === true) {
        message.error("This contact is already taggged with this Account");
      } else {
        dispatch(linkContactsToAccount(accountId, associations, cb));
      }
      console.log(res);
      dispatch({
        type: types.LINK_CONTACTS_TO_CHECK_ACCOUNT_ID_SUCCESS,
        payload: res.data,
      });
      cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.LINK_CONTACTS_TO_CHECK_ACCOUNT_ID_FAILURE,
      });
      cb();
    });
};
/**
 * link contacts to an account
 */
export const linkContactsToAccount = (accountId, associations, cb) => (
  dispatch
) => {
  console.log(accountId, associations);
  dispatch({
    type: types.LINK_CONTACTS_TO_ACCOUNT_ID_REQUEST,
  });
  axios
    .post(`${base_url}/linkContacts/account/${accountId}`, associations, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.LINK_CONTACTS_TO_ACCOUNT_ID_SUCCESS,
        payload: res.data,
      });
      cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.LINK_CONTACTS_TO_ACCOUNT_ID_FAILURE,
      });
      cb();
    });
};
/**
 * link a opportunities to an account
 */
export const linkOpportunitiesToAccount = (accountId, associations, cb) => (
  dispatch
) => {
  console.log(accountId, associations);
  dispatch({
    type: types.LINK_OPPORTUNITIES_TO_ACCOUNT_ID_REQUEST,
  });
  axios
    .post(`${base_url}/linkOpportunities/account/${accountId}`, associations, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.LINK_OPPORTUNITIES_TO_ACCOUNT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.LINK_OPPORTUNITIES_TO_ACCOUNT_ID_FAILURE,
      });
    });
};
/**
 * unlink a contact from account
 */
export const unlinkContactFromAccount = (accountId, contactId) => (
  dispatch
) => {
  console.log(accountId, contactId);
  axios
    .delete(`${base_url}/account/${accountId}/contact/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_CONTACT_LIST_BY_ACCOUNT_ID_SUCCESS,
        payload: contactId,
      });
    })
    .catch((err) => console.log(err));
};

/**
 * unlink a opportunity from account
 */
export const unlinkOpportunityFromAccount = (accountId, opportunityId) => (
  dispatch
) => {
  console.log(accountId, opportunityId);
  axios
    .delete(`${base_url}/account/${accountId}/opportunity/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_OPPORTUNITY_LIST_BY_ACCOUNT_ID_SUCCESS,
        payload: opportunityId,
      });
    })
    .catch((err) => console.log(err));
};

/**
 * unlink a call from account
 */
export const unlinkCallFromAccount = (accountId, callId) => (dispatch) => {
  console.log(accountId, callId);
  axios
    .delete(`${base_url}/account/${accountId}/call/${callId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_CALLS_LIST_BY_ACCOUNT_ID_SUCCESS,
        payload: callId,
      });
    })
    .catch((err) => console.log(err));
};

/**
 * unlink a task from account
 */
export const unlinkTaskFromAccount = (accountId, taskId) => (dispatch) => {
  console.log(accountId, taskId);
  axios
    .delete(`${base_url}/account/${accountId}/task/${taskId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_TASKS_LIST_BY_ACCOUNT_ID_SUCCESS,
        payload: taskId,
      });
    })
    .catch((err) => console.log(err));
};

/**
 * unlink a event from account
 */
export const unlinkEventFromAccount = (accountId, eventId) => (dispatch) => {
  console.log(accountId, eventId);
  axios
    .delete(`${base_url}/account/${accountId}/event/${eventId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_EVENTS_LIST_BY_ACCOUNT_ID_SUCCESS,
        payload: eventId,
      });
    })
    .catch((err) => console.log(err));
};

/**
 * get topics of interest by contactId
 */
export const getTopicsByAccountId = (accountId) => (dispatch) => {
  dispatch({
    type: types.GET_TOPICS_BY_ACCOUNT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/topic/account/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_TOPICS_BY_ACCOUNT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_TOPICS_BY_ACCOUNT_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * add topic of a accountId
 */
export const addTopicByAccountId = (topic) => (dispatch) => {
  console.log(topic);
  dispatch({
    type: types.ADD_TOPIC_BY_ACCOUNT_ID_REQUEST,
  });
  axios
    .post(`${base_url}/topic`, topic, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.ADD_TOPIC_BY_ACCOUNT_ID_SUCCESS,
        payload: { ...topic, topicId: res.data },
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_TOPIC_BY_ACCOUNT_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * delete topics of interest by accountId
 */
export const deleteTopicByAccountId = (topicId, accountId) => (
  dispatch,
  getState
) => {
  const accountId = getState().account.account.accountId;
  dispatch({
    type: types.DELETE_TOPIC_BY_ACCOUNT_ID_REQUEST,
  });
  axios
    .delete(`${base_url}/topic/${topicId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(getTopicsByAccountId(accountId));
      dispatch({
        type: types.DELETE_TOPIC_BY_ACCOUNT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.DELETE_TOPIC_BY_ACCOUNT_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * get ENRICH ACCOUNT by accountId
 */
export const enrichByAccountId = (accountId, domainName) => (dispatch) => {
  console.log(accountId);
  dispatch({
    type: types.ENRICH_ACCOUNT_REQUEST,
  });
  axios
    .get(
      `${base_url}/account/accountEnrich/?accountId=${accountId}&domainName=${domainName}`,
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
          // domainName: domainName
        },
      }
    )
    .then((res) => {
      dispatch(getAccountById(accountId));
      console.log(res);
      dispatch({
        type: types.ENRICH_ACCOUNT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ENRICH_ACCOUNT_FAILURE,
        payload: err,
      });
    });
};
/**
 * update contact address
 * @param {string} accountId
 * @param {address object} address
 */
export const updateAccountAddress = (accountId, address) => (dispatch) => {
  console.log(accountId, address);
  dispatch({
    type: types.UPDATE_ACCOUNT_ADDRESS,
    payload: {
      accountId,
      address,
    },
  });
};

export const addAccountAddress = (address) => (dispatch) => {
  //////debugger;
  // console.log(accountId);
  dispatch({
    type: types.ADD_ACCOUNT_ADDRESS,
    payload: {
      address,
    },
  });
};

export const handleAbsoluteModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_ABSOLUTE_MODAL,
    payload: modalProps,
  });
};

export const handleWinModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_WIN_MODAL,
    payload: modalProps,
  });
};

export const handleOppModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_OPP_MODAL,
    payload: modalProps,
  });
};

export const handleLifetimeModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_LIFETIME_MODAL,
    payload: modalProps,
  });
};

//get inititative wise partner(filter)

export const getInitiativeWiseAccount = (userId, initiative) => (dispatch) => {
  dispatch({
    type: types.GET_INITIATIVE_WISE_ACCOUNT_REQUEST,
  });
  axios
    .get(`${base_url}/accounts/topic/${userId}/${initiative}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_INITIATIVE_WISE_ACCOUNT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_INITIATIVE_WISE_ACCOUNT_FAILURE,
        payload: err,
      });
    });
};
// account Quote

export const getAccountQuote = (accountId) => (dispatch, getState) => {
  dispatch({
    type: types.GET_ACCOUNT_QUOTE_REQUEST,
  });

  axios
    .get(`${base_url}/account/quotes/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      dispatch({
        type: types.GET_ACCOUNT_QUOTE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ACCOUNT_QUOTE_FAILURE,
        payload: err,
      });
    });
};

// get account order

export const getAccountOrder = (customerId) => (dispatch, getState) => {
  dispatch({
    type: types.GET_ACCOUNT_ORDER_REQUEST,
  });

  axios
    .get(`${base_url}/customer/order/${customerId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      dispatch({
        type: types.GET_ACCOUNT_ORDER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ACCOUNT_ORDER_FAILURE,
        payload: err,
      });
    });
};

export const getAccountTopicList = () => (dispatch) => {
  dispatch({
    type: types.GET_ACCOUNT_TOPIC_LIST_REQUEST,
  });
  axios
    .get(`${base_url}/account/topicList`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ACCOUNT_TOPIC_LIST_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_ACCOUNT_TOPIC_LIST_FAILURE,
        payload: err,
      });
    });
};

export const handleBarModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_BAR_MODAL,
    payload: modalProps,
  });
};

//get timeline Data by accountID;
export const getTimeLineDataByAccountId = (accountId) => (dispatch) => {
  dispatch({
    type: types.GET_TIMELINE_DATA_BY_ACCOUNT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/timeline/account/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_TIMELINE_DATA_BY_ACCOUNT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_TIMELINE_DATA_BY_ACCOUNT_ID_FAILURE,
        payload: err,
      });
    });
};

export const getAccountListOpportunityRangeByUserIdForReport = (
  userId,
  type,
  startDate,
  endDate
) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/report/call/${userId}`;
  } else {
    api_url = `/myView/report/account?userId=${userId}&type=${type}&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE,
        payload: err,
      });
    });
};

export const getAccountListRangeByOrgIdForReport = (
  orgId,
  type,
  startDate,
  endDate
) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/report/call/${orgId}`;
  } else {
    api_url = `/orgView/report/account?orgId=${orgId}&type=${type}&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_ACCOUNT_LIST_RANGE_BY_ORG_ID_FOR_REPORT_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ACCOUNT_LIST_RANGE_BY_ORG_ID_FOR_REPORT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ACCOUNT_LIST_RANGE_BY_ORG_ID_FOR_REPORT_FAILURE,
        payload: err,
      });
    });
};

export const DeleteSuspendAccount = (accountId, cb) => (dispatch, getState) => {
  const userId = getState().auth.userDetails.userId;
  dispatch({
    type: types.SUSPEND_ACCOUNT_REQUSET,
  });
  axios
    .delete(`${base_url}/suspend/account/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      history.push("/account");
      const startDate = moment()
        .startOf("month")
        .toISOString();
      const endDate = moment()
        .endOf("month")
        .toISOString();

      console.log(res.data);
      dispatch(getLatestAccounts(userId, startDate, endDate));
      dispatch(getAccountsByFunnelValue(userId));
      dispatch(getExistingAccounts(userId));

      dispatch({
        type: types.SUSPEND_ACCOUNT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.SUSPEND_ACCOUNT_FAILURE,
        payload: err,
      });
    });
};

// Order create Modal

export const handleLinkOrderConfigureModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_LINK_ORDER_CONFIGURE_MODAL,
    payload: modalProps,
  });
};

export const handleDocumentUploadModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_DOCUMENT_UPLOAD_MODAL,
    payload: modalProps,
  });
};

export const handleAccountSubscriptionConfigureModal = (modalProps) => (
  dispatch
) => {
  dispatch({
    type: types.HANDLE_ACCOUNT_SUBSCRIPTION_CONFIGURE_MODAL,
    payload: modalProps,
  });
};

// order product form

export const getProductsByAccountId = (accountId) => (dispatch) => {
  dispatch({
    type: types.FETCHING_PROFESSIONALDUCTS_BY_ACCOUNT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/account/productDetails/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.FETCHING_PROFESSIONALDUCTS_BY_ACCOUNT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.FETCHING_PROFESSIONALDUCTS_BY_ACCOUNT_ID_FAILURE,
        payload: err,
      });
    });
};

// service

export const getServicesByAccountId = (accountId) => (dispatch) => {
  dispatch({
    type: types.FETCHING_PROFESSIONAL_SERVICE_BY_ACCOUNT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/account/productDetails/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.FETCHING_PROFESSIONAL_SERVICE_BY_ACCOUNT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.FETCHING_PROFESSIONAL_SERVICE_BY_ACCOUNT_ID_FAILURE,
        payload: err,
      });
    });
};

export const linkProductToAccount = (data, accountId) => (dispatch) => {
  console.log(accountId);
  dispatch({ type: types.LINK_PROFESSIONALDUCT_TO_ACCOUNT_REQUEST });
  axios
    .post(`${base_url}/link/product/customer`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(fetchingNewOrder(accountId));
      dispatch({
        type: types.LINK_PROFESSIONALDUCT_TO_ACCOUNT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.LINK_PROFESSIONALDUCT_TO_ACCOUNT_FAILURE,
      });
    });
};

export const linkServiceToAccount = (data, accountId) => (dispatch) => {
  console.log(accountId);
  dispatch({ type: types.LINK_SERVICE_TO_ACCOUNT_REQUEST });
  axios
    .post(`${base_url}/customer/service`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(fetchingNewServiceOrder(accountId));
      dispatch({
        type: types.LINK_SERVICE_TO_ACCOUNT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.LINK_SERVICE_TO_ACCOUNT_FAILURE,
      });
    });
};

// get type

export const getCustomeModulAccount = () => (dispatch) => {
  dispatch({
    type: types.GET_CUSTOME_MODULE_ACCOUNTS_REQUEST,
  });
  axios
    .get(`${base_url}/type/customer`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CUSTOME_MODULE_ACCOUNTS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_CUSTOME_MODULE_ACCOUNTS_FAILURE,
        payload: err,
      });
    });
};

/**
 * update event modal
 */
export const handleUpdateEventModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_UPDATE_EVENT_MODAL,
    payload: modalProps,
  });
};
/**
 * update call modal
 */
export const handleUpdateCallModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_UPDATE_CALL_MODAL,
    payload: modalProps,
  });
};

/**
 * update task modal
 */
export const handleUpdateTaskModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_UPDATE_TASK_MODAL,
    payload: modalProps,
  });
};

// get product

export const fetchingNewOrder = (accountId) => (dispatch) => {
  dispatch({
    type: types.FETCHING_NEW_ORDER_LIST_REQUEST,
  });
  axios
    .get(`${base_url}/customer/product/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.FETCHING_NEW_ORDER_LIST_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.FETCHING_NEW_ORDER_LIST_FAILURE,
        payload: err,
      });
    });
};

/**
 *  generate order with subscription
 */

export const generateOrderByCustomerId = (data, cb) => (dispatch) => {
  // //debugger;
  dispatch({ type: types.GENERATE_ORDER_BY_CUSTOMER_ID_REQUEST });
  axios
    .post(`${base_url}/customer/order`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      // dispatch(getAccountOrder(accountId))
      dispatch({
        type: types.GENERATE_ORDER_BY_CUSTOMER_ID_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GENERATE_ORDER_BY_CUSTOMER_ID_FAILURE,
      });
      cb && cb("failure");
    });
};

// get product

export const fetchingNewServiceOrder = (accountId) => (dispatch) => {
  dispatch({
    type: types.FETCHING_NEW_ORDER_SERVICE_LIST_REQUEST,
  });
  axios
    .get(`${base_url}/customer/service/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.FETCHING_NEW_ORDER_SERVICE_LIST_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.FETCHING_NEW_ORDER_SERVICE_LIST_FAILURE,
        payload: err,
      });
    });
};

/**
 * renewal button
 */
export const handleRenewalButtonModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_RENEWAL_BUTTON_MODAL,
    payload: modalProps,
  });
};

/**
 * pause button
 */
export const handlePauseButtonModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_PAUSE_BUTTON_MODAL,
    payload: modalProps,
  });
};
export const handleAccountOpportunityModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_ACCOUNT_OPPORTUNITY_MODAL,
    payload: modalProps,
  });
};

export const addAccountOpportunity = (opportunity, cb) => (
  dispatch,
  getState
) => {
  // const userId = getState().auth.userDetails.userId;
  dispatch({
    type: types.ADD_ACCOUNT_OPPORTUNITY_REQUEST,
  });
  axios
    .post(`${base_url}/opportunity`, opportunity, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      const startDate = moment()
        .startOf("month")
        .toISOString();
      const endDate = moment()
        .endOf("month")
        .toISOString();
      dispatch({
        type: types.ADD_ACCOUNT_OPPORTUNITY_SUCCESS,
        payload: res.data,
      });
      cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_ACCOUNT_OPPORTUNITY_FAILURE,
        payload: err,
      });
    });
};

export const fetchingOpportunity = (accountId) => (dispatch) => {
  dispatch({
    type: types.FETCHING_OPPORTUNITY_REQUEST,
  });
  axios
    .get(`${base_url}/hhkh/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.FETCHING_OPPORTUNITY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.FETCHING_OPPORTUNITY_FAILURE,
        payload: err,
      });
    });
};

/**
 * Get Product Order Details
 */
export const getProductOrderDetailsById = (orderId) => (dispatch) => {
  dispatch({
    type: types.GET_PRODUCT_ORDER_DETAILS_BY_ID_REQUEST,
  });
  axios
    .get(`${base_url}/customer-order/${orderId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PRODUCT_ORDER_DETAILS_BY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_PRODUCT_ORDER_DETAILS_BY_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * Input data search
 */

export const inputDataSearch = (name) => (dispatch) => {
  dispatch({
    type: types.INPUT_SEARCH_ACCOUNT_DATA_REQUEST,
  });
  axios
    .get(`${base_url}/account/Name/${name}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      if (res.data.accountId) {
        console.log(res.data);
        dispatch();
      }

      dispatch({
        type: types.INPUT_SEARCH_ACCOUNT_DATA_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.INPUT_SEARCH_ACCOUNT_DATA_FAILURE,
        payload: err,
      });
    });
};

/**
 * Link pause in distributor
 */
export const linkPauseOrder = (data, cb) => (dispatch) => {
  //debugger;
  dispatch({ type: types.LINK_PAUSE_BY_ACCOUNT_ID_REQUEST });
  axios
    .post(`${base_url}/order/pause-order`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);

      dispatch({
        type: types.LINK_PAUSE_BY_ACCOUNT_ID_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.LINK_PAUSE_BY_ACCOUNT_ID_FAILURE,
      });
      cb && cb("failure");
    });
};

/**
 * Link Renewal in distributor
 */
export const linkRenewalOrder = (data, cb) => (dispatch) => {
  dispatch({ type: types.LINK_RENEWAL_BY_ACCOUNT_ID_REQUEST });
  axios
    .post(`${base_url}/order/renew-order`, data, {})
    .then((res) => {
      console.log(res);

      dispatch({
        type: types.LINK_RENEWAL_BY_ACCOUNT_ID_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.LINK_RENEWAL_BY_ACCOUNT_ID_FAILURE,
      });
      cb && cb("failure");
    });
};
/**
 * get customer order feedback
 */
export const getFeedbackByOrderId = (orderId) => (dispatch) => {
  dispatch({
    type: types.GET_FEEDBACK_BY_ORDER_ID_REQUEST,
  });
  axios
    .get(`${base_url}/order/feedback/${orderId}`)
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_FEEDBACK_BY_ORDER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_FEEDBACK_BY_ORDER_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 *  Account Table feedback
 */
export const getFeedbackByAccountId = (accountId) => (dispatch) => {
  dispatch({
    type: types.GET_FEEDBACK_BY_ACCOUNT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/feedback/account/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_FEEDBACK_BY_ACCOUNT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_FEEDBACK_BY_ACCOUNT_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 *  Account View feedback
 */
//  export const getFeedbackByAccountViewId = (accountId) => (dispatch) => {
//   dispatch({
//     type: types.GET_FEEDBACK_BY_ACCOUNT_VIEW_ID_REQUEST,
//   });
//   axios
//     // .get(`${base_url}/order/feedback/${accountId}`)
//     .then((res) => {
//       console.log(res);
//       dispatch({
//         type: types.GET_FEEDBACK_BY_ACCOUNT_VIEW_ID_SUCCESS,
//         payload: res.data,
//       });
//     })
//     .catch((err) => {
//       console.log(err);
//       dispatch({
//         type: types.GET_FEEDBACK_BY_ACCOUNT_VIEW_ID_FAILURE,
//         payload: err,
//       });
//     });
// };

/**
 *  feedback-card modal
 */
export const handleFeedbackModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_FEEDBACK_MODAL,
    payload: modalProps,
  });
};

/**
 *  getCustomerHistory
 */

export const getAccountHistory = (accountId) => (dispatch) => {
  dispatch({
    type: types.GET_ACCOUNT_HISTORY_REQUEST,
  });
  axios
    .get(`${base_url}/accountHistory/${accountId}`)
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ACCOUNT_HISTORY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ACCOUNT_HISTORY_FAILURE,
        payload: err,
      });
    });
};

//CONTACT PERMISSION SHARE Of Partner
export const shareAccountPermission = (data) => (dispatch, getState) => {
  // const { userId } = getState("auth").auth.userDetails;
  dispatch({
    type: types.ADD_SHARE_ACCOUNT_PERMISSION_REQUEST,
  });

  axios
    // .post(`${base_url}/permission/details`, data, {
    //   headers: {
    //     Authorization: "Bearer " + sessionStorage.getItem("token") || "",
    //   },
    // })
    .then((res) => {
      console.log(res);
      // dispatch(getCustomerListByUserId(userId));
      dispatch({
        type: types.ADD_SHARE_ACCOUNT_PERMISSION_SUCCESS,
        payload: res.data,
      });
      // cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_SHARE_ACCOUNT_PERMISSION_FAILURE,
        payload: err,
      });
      // cb && cb("failure");
    });
};

export const getPermissionsListAccount = () => (dispath) => {
  dispath({ type: types.GET_PERMISSIONS_LIST_ACCOUNT_REQUEST });
  axios
    // .get(`${base_url}/permission/type?type=${"customer"}`, {
    //   headers: {
    //     Authorization: "Bearer " + sessionStorage.getItem("token") || "",
    //   },
    // })
    .then((res) => {
      dispath({
        type: types.GET_PERMISSIONS_LIST_ACCOUNT_SUCCESS,
        payload: res.data,
      });

    })
    .catch((err) => {
      dispath({
        type: types.GET_PERMISSIONS_LIST_ACCOUNT_FAILURE,
        payload: err,
      });
    });
};