import * as types from "./PaypalActionType";

const initialState = {
    addPaypalModal: false,
};

export const paypalReducer = (state = initialState, action) => {
    switch (action.type) {
/**
     * handle Address form modal
     */
 case types.HANDLE_PAYPAL_MODAL:
    return { ...state, addPaypalModal: action.payload };

    default:
      return state;

    }
};
