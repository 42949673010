import React from "react";
import styled from "styled-components";
import moment from "moment";
import { SubTitle, Spacer } from "../UI/Elements";
import Link from "./Link";
const NotesWrapper = styled.div``;
export default function SingleNote(props) {
  console.log(props);
  const {
    description,
    creationDate,
    userId,
    creatorId,
    metaData: {
      creatorDetails: { firstName, lastName }
    }
  } = props;
  return (
    <NotesWrapper>
      {/* <SubTitle fontSize='1.12em' whiteSpace='normal' fontFamily='Abel' style={{ color: '#393a3a' }}>
                {description}
            </SubTitle> */}
      <div dangerouslySetInnerHTML={{ __html: description }} />
      <SubTitle
        fontSize="0.87em"
        // fontFamily="Karla"
        fontFamily="Roboto"
        style={{ color: "#a7b2bc", marginTop: "-0.75em" }}
      >
        <Spacer />
        {`${moment(creationDate).fromNow()}`}
        <b>
          {userId !== creatorId
            ? ` by ${firstName || ""} ${lastName || ""}`
            : ""}
        </b>
      </SubTitle>
    </NotesWrapper>
  );
}
