import styled from "styled-components";

const StyledTextarea = styled.textarea.attrs({
  type: "text",
  size: (props) => (props.small ? 4 : undefined),
})`
  width: 100%;
  min-height: ${(props) => props.height || "6.25em"};
  border-radius: 2px;
  border: 0.06em solid gainsboro;
  background-color: ${(props) => props.theme.backgroundColor};
  color: ${(props) => props.theme.color};
  display: block;
  margin: 0.3rem 0;
  border-radius: 0.2rem;
  outline: none;
  box-shadow: 0px 0.25em 0.62em -0.25em ${(props) => props.theme.boxShadowColor};
  padding: 0.3rem 1rem;
  &:hover {
    box-shadow: 0px 0.25em 0.62em -0.125em ${(props) => props.theme.boxShadowColor};
    border: 0.06em solid #1890ff;
  }
  ::placeholder {
    color: #888;
  }
`;
export default StyledTextarea;
