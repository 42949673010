import * as types from "./OpportunityActionTypes";
import moment from "moment";
import { handleOpportunityModal } from "./OpportunityAction";

const initialState = {
  addAgeingModal: false,
  addStageModal: false,
  addMeetingsModal: false,
  addCallsModal: false,
  addRemarksModal: false,
  opportunityFilterText: "",
  opportunityFilterUser: "all_user",
  viewType: "grid",
  documentUploadModal: false,
  addOpportunityModal: false,
  linkOpportunityModal: false,
  linkProductModal: false,
  linkDeliveryModal: false,
  linkServiceModal: false,
  addingOpportunity: false,
  addingOpportunityError: false,
  addLinkConfigureModal: false,

  addingReason: false,
  addingReasonError: false,

  addingDocuments: false,
  addingDocumentsError: false,
  removingDocuments: false,
  removingDocumentsError: false,

  updatingDocuments: false,
  updatingDocumentsError: false,

  buttonChange: false,

  fetchingOpportunityById: false,
  fetchingOpportunityByIdError: false,
  updateOpportunityById: false,
  updateOpportunityByIdError: false,
  opportunity: {},

  fetchingOpportunities: false,
  fetchingOpportunitiesError: false,
  opportunities: [],

  fetchingOpportunityCardById: false,
  fetchingOpportunityCardByIdError: false,
  fetchingOpportunityCard: [],

  fetchingLatestOpportunities: false,
  fetchingLatestOpportunitiesError: false,
  latestOpportunities: [],

  fetchingAllLatestOpportunities: false,
  fetchingAllLatestOpportunitiesError: false,

  fetchingLatestOpportunitiesByOrganizationId: false,
  fetchingLatestOpportunitiesByOrganizationIdError: false,
  latestOpportunitiesByOrganizationId: [],

  fetchingOpportunitiesByPrice: false,
  fetchingOpportunitiesByPriceError: false,
  opportunitiesByPrice: [],

  fetchingOpportunityActivityData: false,
  fetchingOpportunityActivityDataError: false,
  opportunityActivity: [],

  fetchingOpportunitiesByWinloss: false,
  fetchingOpportunitiesByWinlossError: false,
  opportunitiesByWinloss: [],

  fetchingAllOpportunitiesByPrice: false,
  fetchingALLOpportunitiesByPriceError: false,

  fetchingAccountListByOpportunityId: false,
  fetchingAccountListByOpportunityIdError: false,
  accountListByOpportunityId: [],

  fetchingContactListByOpportunityId: false,
  fetchingContactListByOpportunityIdError: false,
  contactListByOpportunityId: [],

  linkingContactsToOpportunityId: false,
  linkingContactsToOpportunityIdError: false,

  fetchingNotesListByOpportunityId: false,
  fetchingNotesListByOpportunityIdError: false,
  notesListByOpportunityId: [],

  fetchingNotesListByOpportunityLinkTaskId: false,
  fetchingNotesListByOpportunityIdLinkTaskError: false,
  notesListByOpportunityLinkTaskId: [],

  fetchingCallsListByOpportunityId: false,
  fetchingCallsListByOpportunityIdError: false,
  callListByOpportunityId: [],

  fetchingEventsListByOpportunityId: false,
  fetchingEventsListByOpportunityIdError: false,
  eventsListByOpportunityId: [],

  fetchingTasksListByOpportunityId: false,
  fetchingTasksListByOpportunityIdError: false,
  tasksListByOpportunityId: [],

  fetchingHistoricalProposalAmount: false,
  fetchingHistoricalProposalAmountError: false,
  historicalProposalAmount: [],

  fetchingDocumentsByOpportunityId: false,
  fetchingDocumentsByOpportunityIdError: false,
  documentsByOpportunityId: [],

  adddingDocumentByOpportunityId: false,
  adddingDocumentByOpportunityIdError: false,

  fetchingOpportunityVelocity: false,
  fetchingOpportunityVelocityError: false,
  opportunityVelocity: 0,

  fetchingOpportunityVelocityByOrganizationId: false,
  fetchingOpportunityVelocityByOrganizationIdError: false,
  opportunityVelocityByOrganizationId: false,

  fetchingOpportunityDeviation: false,
  fetchingOpportunityDeviationError: false,
  opportunityDeviation: {},

  addingSources: false,
  addingSourcesError: false,
  removingSources: false,
  removingSourcesError: false,

  updateEventModal: false,

  updatingSources: false,
  updatingSourcesError: false,

  fetchingSources: false,
  fetchingSourcesError: false,
  sources: [],

  // customer opportunity

  addingCustomers: false,
  addingCustomersError: false,
  removingCustomers: false,
  removingCustomersError: false,

  updatingCustomers: false,
  updatingCustomersError: false,

  fetchingCustomers: false,
  fetchingCustomersError: false,
  customers: [],

  fetchingDocuments: false,
  fetchingDocumentsError: false,
  documents: [],

  // contacts opportunity

  addingContacts: false,
  addingContactsError: false,
  removingContacts: false,
  removingContactsError: false,
  updatingContacts: false,
  updatingContactsError: false,
  fetchingContacts: false,
  fetchingContactsError: false,
  contacts: [],

  patchingTask: false,
  patchingTaskError: false,

  fetchingInputOpportunityData: false,
  fetchingInputOpportunityDataError: false,

  addingStages: false,
  addingStagesError: false,

  deleteDocument: false,
  deleteDocumentError: false,

  addingDelivery: false,
  addingDeliveryError: false,

  addingApprove: false,
  addingApproveError: false,
  afterApprove: false,
  afterApproveError: false,

  addingTagContact: false,
  addingTagContactError: false,

  fetchingDeliveries: false,
  fetchingDeliveriesError: false,
  deliveries: [],

  removingStages: false,
  removingStagesError: false,
  updatingStages: false,
  updatingStagesError: false,
  fetchingStages: false,
  fetchingStagesError: false,
  stages: [],

  fetchingCountries: false,
  fetchingCountriesError: false,
  countries: [],

  fetchingCurrency: false,
  fetchingCurrencyError: false,
  currencies: [],
  clearbit: {},
  opportunityDrawerVisible: false,
  opportunityDrawerProps: {},

  fetchingProductsByOpportunityId: false,
  fetchingProductsByOpportunityIdError: false,
  productsByOpportunityId: [],

  fetchingServiceByOpportunityId: false,
  fetchingServiceByOpportunityIdError: false,
  servicesByOpportunityId: [],

  linkingProductToOpportunity: false,
  linkingProductToOpportunityError: false,

  linkingServiceToOpportunity: false,
  linkingServiceToOpportunityError: false,

  addOpportunityActivityModal: false,

  updateTaskModal: false,

  fetchingQutation: false,
  fetchingQutationError: false,
  quotation: [],

  fetchingStageCheckByStageId: false,
  fetchingStageCheckByStageIdError: false,

  fetchingStageDeleteByStageId: false,
  fetchingStageDeleteByStageIdError: false,

  generate: false,
  generateError: false,

  linkingCheckContactsToOpportunityId: false,
  linkingCheckContactsToOpportunityIdError: false,

  getCurrentQutation: false,
  getCurrentQutationError: false,
  quotationByQuoteId: [],

  approvingQutation: false,
  approvingQutationError: false,

  //Recruiter
  addRecruitModal: false,

  //Profile Modal
  addTagProfileModal: false,

  linkingRecruitToOpportunity: false,
  linkingRecruitToOpportunityError: false,

  linkingProfileToOpportunity: false,
  linkingProfileToOpportunityError: false,

  fetchingRecruitToOpportunity: false,
  fetchingRecruitToOpportunityError: false,
  recruitByOpportunityId: [],

  linkingCategoryRecruitToOpportunity: false,
  linkingCategoryRecruitToOpportunityError: false,

  linkingContactRecruitToOpportunity: false,
  linkingContactRecruitToOpportunityError: false,

  linkingSatgeRecruitToOpportunity: false,
  linkingSatgeRecruitToOpportunityError: false,

  linkingStatusRecruitToOpportunity: false,
  linkingStatusRecruitToOpportunityError: false,

  fetchingQuoteListRangeByOrgIdForReport: false,
  fetchingQuoteListRangeByOrgIdForReportError: false,
  quoteListRangeByOrgIdForReport: [],

  fetchingQuoteListRangeByUserIdForReport: false,
  fetchingQuoteListRangeByUserIdForReportError: false,
  quoteListRangeByUserIdForReport: [],

  fetchingInvoiceListRangeByOrgIdForReport: false,
  fetchingInvoiceListRangeByOrgIdForReportError: false,
  invoiceListRangeByOrgIdForReport: [],

  fetchingInvoiceListRangeByUserIdForReport: false,
  fetchingInvoiceListRangeByUserIdForReportError: false,
  invoiceListRangeByUserIdForReport: [],

  updatingStageByparticlarOpportunity: false,
  updatingStageByparticlarOpportunityError: false,

  updateCallModal: false,

  emailingQuote: false,
  emailingQuoteError: false,

  emailingInvoice: false,
  emailingInvoiceError: false,

  currentRecruitmentData: {},

  updatingRecruitment: false,
  updatingRecruitmentError: false,

  udatingOpp: false,

  emailingRecruitment: false,
  emailingRecruitmentError: false,

  profileRecruit: [],

  addingRecruitmentProfile: false,
  addingRecruitmentProfileError: false,

  addingRemark: false,
  addingRemarkError: false,

  fetchingRemark: false,
  fetchingRemarkError: false,
  remark: [],
  //RecruimentDashBoard (For Jumpstart)
  fetchingAllRecruitmentByOppId: false,
  fetchingAllRecruitmentByOppIdError: false,
  allRecruitmentByOppId: "",

  fetchingAllRecruitmentPositionByOppId: false,
  fetchingAllRecruitmentPositionByOppIdError: false,
  allRecruitmentPositionByOppId: "",

  fetchingAllRecruitmentAvgTimeByOppId: false,
  fetchingAllRecruitmentAvgTimeByOppIdError: false,
  allRecruitmentAvgTimeByOppId: "",

  fetchingAllRecruitmentDetailsByOppId: false,
  fetchingAllRecruitmentDetailsByOppIdError: false,
  allRecruitmentDetailsByOppId: [],

  fetchingAllRecruitmentPositionFilledByOppId: false,
  fetchingAllRecruitmentPositionFilledByOppIdError: false,
  allRecruitmentPositionFilledByOppId: "",

  //candidtae switch
  updatingCandidateSwitch: false,
  updatingCandidateSwitchError: false,

  updatingSponsorSwitch: false,
  updatingSponsorSwitchError: false,

  addSponsorModal: false,
  addPurchaseModal: false,
  fetchingDraggableOpportunityById: false,
  fetchingDraggableOpportunityByIdError: false,
  fetchingOpportunityCard: [],

  fetchingCostSheetByOpportunityId: false,
  fetchingCostSheetByOpportunityIdError: false,
  costSheetByOpportunityId: [],

  adddingOpportunity: false,
  adddingOpportunityError: false,

  //delete product an service
  deletingOppProduct: false,
  deletingOppProductError: false,

  deletingOppService: false,
  deletingOppServiceError: false,
  //purchase Order
  addingPurchase: false,
  addingPurchaseError: false,

  fetchingPurchase: false,
  fetchingPurchaseError: false,
  purchase: {},
  addPlaybookModal: false,

  fetchingPlaybooks: false,
  fetchingPlaybooksError: false,
  playbook: [],

  fetchingCostSheetByCostId: false,
  fetchingCostSheetByCostIdError: false,
  costSheetByCostId: [],

  fetchingPlaybookOpportunities: false,
  fetchingPlaybookOpportunitiesError: false,
  playbookOpportunities: [],

  fetchingPlaybookYoutube: false,
  fetchingPlaybookYoutubeError: false,
  playbookYoutube: [],

  //get Playbook By stageId
  fetchingPlaybookByStageId: false,
  fetchingPlaybookByStageIdError: false,
  playbookByStageId: [],

  fetchingAllLatestOpportunitiesForLazyLoading: false,
  fetchingAllLatestOpportunitiesForLazyLoadingError: false,
  opportunitiesForLazyLoading: [],

  fetchingBillingListRangeByUserIdForReport: false,
  fetchingBillingListRangeByUserIdForReportError: false,
  billingListRangeByUserIdForReport: [],

  fetchingLatestOpportunitiesByOrganizationIdForLazyLoading: false,
  fetchingLatestOpportunitiesByOrganizationIdForLazyLoadingError: false,
  latestOpportunitiesByOrganizationIdForLazyLoading: [],
  //email stage
  emailingStage: false,
  emailingStageError: false,

  adddingOpportunityStageDisable: false,
  adddingOpportunityStageDisableError: false,

  checkingQuoteApprovedByQuoteId: false,
  checkingQuoteApprovedByQuoteIdError: false,

  checkingQuoteApprovedByOpportunityId: false,
  checkingQuoteApprovedByOpportunityIdError: false,
  refreshedData: false,

  addFashionModal: false,
  addCostingModal: false,
  addGalleryModal: false,
  clearbitCosting: {},

  addingFashionItem: false,
  addingFashionItemError: false,

  updatingFashionItem: false,
  updatingFashionItemError: false,

  fetchingFashionItem: false,
  fetchingFashionItemError: false,
  fashionItem: [],

  fetchingTimeLineByOpportunityId: false,
  fetchingTimeLineByOpportunityIdError: false,
  timeLineDataByOpportunityId: [],

  fetchingOpportunityListRangeByUserIdForReport: false,
  fetchingOpportunityListRangeByUserIdForReportError: false,
  opportunityListRangeByUserIdForReport: [],

  fetchingQuotationListRangeByUserIdForReport: false,
  fetchingQuotationListRangeByUserIdForReportError: false,
  quotationListRangeByUserIdForReport: [],

  fetchingGalleryImages: false,
  fetchingGalleryImagesError: false,
  galleryImages: [],

  fetchingCostingServiceByOpportunityId: false,
  fetchingCostingServiceByOpportunityIdError: false,
  costingServicesByOpportunityId: [],

  fetchingCostingQuotation: false,
  fetchingCostingQuotationError: false,
  costingQuotation: [],

  fetchingQuoteDetailsForCosting: false,
  fetchingQuoteDetailsForCostingError: false,
  costingQuotationByQuoteId: [],

  fetchingProductsByTaskId: false,
  fetchingProductsByTaskIdError: false,
  productsByTaskId: [],

  fetchingServiceByTaskId: false,
  fetchingServiceByTaskIdError: false,
  serviceByTaskId: [],

  fetchingOpportunityStageData: false,
  fetchingOpportunityStageDataError: false,
  opportunitiesStage: false,

  addCostingProduct: false,
  addCostingProductError: false,

  addCostingService: false,
  addCostingServiceError: false,

  generateCostingQuotation: false,
  generateCostingQuotationError: false,

  addEditModal: false,

  //SHARE Contact Permission of customer
  addSharingOpportunity: false,
  addSharingOpportunityError: false,

  fetchingPermissionsListOpportunity: false,
  fetchingPermissionsListOpportunityError: false,
  permissionsDataListOpportunity: [],


};
const updatedOpportunity = (opp, newProps) => {
  return opp.map((item, index) => {
    if (item.opportunityId === newProps.opportunityId) {
      console.log("inside opp");
      item.stageId = newProps.destinationStageId;
    }
    return item;
  });
};
export const opportunityReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.HANDLE_FASHION_EDIT_MODAL:
      return { ...state, addEditModal: action.payload };
    case types.UPDATE_RECRUITMENT_REQUEST:
      return {
        ...state,
        updatingRecruitment: true,
      };
    case types.UPDATE_RECRUITMENT_SUCCESS:
      return {
        ...state,
        updatingRecruitment: false,

        recruitByOpportunityId: state.recruitByOpportunityId.map((item) => {
          if (item.recruitmentId === action.payload.recruitmentId) {
            return action.payload;
          } else {
            return item;
          }
        }),
      };
    case types.UPDATE_RECRUITMENT_FAILURE:
      return {
        ...state,
        updatingRecruitment: false,
        updatingRecruitmentError: true,
      };

    case types.SET_CURRENT_RECRUITMENT_DATA:
      return { ...state, currentRecruitmentData: action.payload };

    case types.EMAIL_SEND_FOR_QUOTE_REQUEST:
      return { ...state, emailingQuote: true };
    case types.EMAIL_SEND_FOR_QUOTE_SUCCESS:
      return {
        ...state,
        emailingQuote: false,
      };
    case types.EMAIL_SEND_FOR_QUOTE_FAILURE:
      return { ...state, emailingQuote: false, emailingQuoteError: true };

    case types.EMAIL_SEND_FOR_RECRUITMENT_REQUEST:
      return { ...state, emailingRecruitment: true };
    case types.EMAIL_SEND_FOR_RECRUITMENT_SUCCESS:
      return {
        ...state,
        emailingRecruitment: false,
      };
    case types.EMAIL_SEND_FOR_RECRUITMENT_FAILURE:
      return {
        ...state,
        emailingRecruitment: false,
        emailingRecruitmentError: true,
      };

    case types.EMAIL_SEND_FOR_INVOICE_REQUEST:
      return { ...state, emailingInvoice: true };
    case types.EMAIL_SEND_FOR_INVOICE_SUCCESS:
      return {
        ...state,
        emailingInvoice: false,
      };
    case types.EMAIL_SEND_FOR_INVOICE_FAILURE:
      return { ...state, emailingInvoice: false, emailingInvoiceError: true };

    case types.ADD_DELIVERY_REQUEST:
      return { ...state, addingDelivery: true };
    case types.ADD_DELIVERY_SUCCESS:
      return {
        ...state,
        addingDelivery: false,
        linkDeliveryModal: false,
      };
    case types.ADD_DELIVERY_FAILURE:
      return { ...state, addingDelivery: false, addingDeliveryError: true };

    case types.PATCH_TASK_BY_ID_REQUEST:
      return { ...state, patchingTask: true };
    case types.PATCH_TASK_BY_ID_SUCCESS:
      return {
        ...state,
        patchingTask: false,
        tasksListByOpportunityId: state.tasksListByOpportunityId.map(
          (task, i) => {
            if (task.taskId === action.payload.taskId) {
              return action.payload;
            } else {
              return task;
            }
          }
        ),
      };
    case types.PATCH_TASK_BY_ID_FAILURE:
      return { ...state, patchingTask: false, patchingTaskError: true };

    case types.ADD_APPROVE_REQUEST:
      return { ...state, addingApprove: true };
    case types.ADD_APPROVE_SUCCESS:
      return {
        ...state,
        addingApprove: false,
        tasksListByOpportunityId: state.tasksListByOpportunityId.map(
          (task, i) => {
            if (task.taskId === action.payload.taskId) {
              return action.payload;
            } else {
              return task;
            }
          }
        ),

        // linkDeliveryModal: false
      };
    case types.ADD_APPROVE_FAILURE:
      return { ...state, addingApprove: false, addingApproveError: true };

    case types.AFTER_APPROVE_REQUEST:
      return { ...state, afterApprove: true };
    case types.AFTER_APPROVE_SUCCESS:
      return {
        ...state,
        afterApprove: false,
        // buttonChange:true,
        // linkDeliveryModal: false
        tasksListByOpportunityId: state.tasksListByOpportunityId.map(
          (task, i) => {
            if (task.taskId === action.payload.taskId) {
              return action.payload;
            } else {
              return task;
            }
          }
        ),
      };
    case types.AFTER_APPROVE_FAILURE:
      return { ...state, afterApprove: false, afterApproveError: true };

    case types.ADD_TAG_CONTACT_REQUEST:
      return { ...state, addingTagContact: true };
    case types.ADD_TAG_CONTACT_SUCCESS:
      return {
        ...state,
        addingTagContact: false,
        tasksListByOpportunityId: state.tasksListByOpportunityId.map(
          (task, i) => {
            if (task.taskId === action.payload.taskId) {
              return action.payload;
            } else {
              return task;
            }
          }
        ),

        // linkDeliveryModal: false
      };
    case types.ADD_TAG_CONTACT_FAILURE:
      return { ...state, addingTagContact: false, addingTagContactError: true };

    case types.GET_DELIVERY_REQUEST:
      return { ...state, fetchingDeliveries: true };
    case types.GET_DELIVERY_SUCCESS:
      return {
        ...state,
        fetchingDeliveries: false,
        deliveries: action.payload,
      };
    case types.GET_DELIVERY_FAILURE:
      return {
        ...state,
        fetchingDeliveries: false,
        fetchingDeliveriesError: true,
      };
    /**
     * get the list of all opportunities
     */
    case types.GET_OPPORTUNITIES_REQUEST:
      return { ...state, fetchingOpportunities: true };
    case types.GET_OPPORTUNITIES_SUCCESS:
      return {
        ...state,
        fetchingOpportunities: false,
        opportunities: action.payload,
      };
    case types.GET_OPPORTUNITIES_FAILURE:
      return {
        ...state,
        fetchingOpportunities: false,
        fetchingOpportunitiesError: true,
      };

    case types.DELETE_DOCUMENT_REQUEST:
      return { ...state, deleteDocument: true };
    case types.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        deleteTask: false,
        documentsByOpportunityId: state.documentsByOpportunityId.filter(
          (item) => item.documentId !== action.payload
        ),
      };
    case types.DELETE_DOCUMENT_FAILURE:
      return { ...state, deleteDocument: false, deleteDocumentError: false };

    /**
     * get the list of all latest opportunities
     */
    case types.GET_LATEST_OPPORTUNITIES_REQUEST:
      return { ...state, fetchingLatestOpportunities: true };
    case types.GET_LATEST_OPPORTUNITIES_SUCCESS:
      return {
        ...state,
        fetchingLatestOpportunities: false,
        latestOpportunities: action.payload,
      };
    case types.GET_LATEST_OPPORTUNITIES_FAILURE:
      return {
        ...state,
        fetchingLatestOpportunities: false,
        fetchingLatestOpportunitiesError: true,
      };
    case types.GET_ALL_LATEST_OPPORTUNITIES_REQUEST:
      return { ...state, fetchingAllLatestOpportunities: true };
    case types.GET_ALL_LATEST_OPPORTUNITIES_SUCCESS:
      return {
        ...state,
        fetchingAllLatestOpportunities: false,
        latestOpportunities: [...action.payload],
      };
    case types.GET_ALL_LATEST_OPPORTUNITIES_FAILURE:
      return {
        ...state,
        fetchingAllLatestOpportunities: false,
        fetchingAllLatestOpportunities: true,
      };
    /**
     * get the list of all latest opportunities
     */
    case types.GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_REQUEST:
      return { ...state, fetchingLatestOpportunitiesByOrganizationId: true };
    case types.GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_SUCCESS:
      return {
        ...state,
        fetchingLatestOpportunitiesByOrganizationId: false,
        latestOpportunitiesByOrganizationId: action.payload,
      };
    case types.GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_FAILURE:
      return {
        ...state,
        fetchingLatestOpportunitiesByOrganizationId: false,
        fetchingLatestOpportunitiesByOrganizationIdError: true,
      };
    //opportunityReport lazy loading organisationId
    case types.GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_FOR_LAZY_LOADING_REQUEST:
      return {
        ...state,
        fetchingLatestOpportunitiesByOrganizationIdForLazyLoading: true,
      };
    case types.GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_FOR_LAZY_LOADING_SUCCESS:
      return {
        ...state,
        fetchingLatestOpportunitiesByOrganizationIdForLazyLoading: false,
        latestOpportunitiesByOrganizationIdForLazyLoading: action.payload,
      };
    case types.GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_FOR_LAZY_LOADING_FAILURE:
      return {
        ...state,
        fetchingLatestOpportunitiesByOrganizationIdForLazyLoading: false,
        fetchingLatestOpportunitiesByOrganizationIdForLazyLoadingError: true,
      };
    /**
     * get the list of all opportunities sort by price
     */
    case types.GET_OPPORTUNITIES_BY_PRICE_REQUEST:
      return { ...state, fetchingOpportunitiesByPrice: true };
    case types.GET_OPPORTUNITIES_BY_PRICE_SUCCESS:
      return {
        ...state,
        fetchingOpportunitiesByPrice: false,
        opportunitiesByPrice: action.payload,
      };
    case types.GET_OPPORTUNITIES_BY_PRICE_FAILURE:
      return {
        ...state,
        fetchingOpportunitiesByPrice: false,
        fetchingOpportunitiesByPriceError: true,
      };

    case types.GET_ALL_OPPORTUNITIES_BY_PRICE_REQUEST:
      return { ...state, fetchingAllOpportunitiesByPrice: true };
    case types.GET_ALL_OPPORTUNITIES_BY_PRICE_SUCCESS:
      return {
        ...state,
        fetchingAllOpportunitiesByPrice: false,
        opportunitiesByPrice: [...action.payload],
      };
    case types.GET_ALL_OPPORTUNITIES_BY_PRICE_FAILURE:
      return {
        ...state,
        fetchingAllOpportunitiesByPrice: false,
        fetchingAllOpportunitiesByPriceError: true,
      };

    case types.GET_OPPORTUNITIES_BY_WINLOSS_REQUEST:
      return { ...state, fetchingOpportunitiesByWinloss: true };
    case types.GET_OPPORTUNITIES_BY_WINLOSS_SUCCESS:
      return {
        ...state,
        fetchingOpportunitiesByWinloss: false,
        opportunitiesByWinloss: action.payload,
      };
    case types.GET_OPPORTUNITIES_BY_WINLOSS_FAILURE:
      return {
        ...state,
        fetchingOpportunitiesByWinloss: false,
        fetchingOpportunitiesByWinlossError: true,
      };
    /**
     * get the list of all stages
     */
    case types.GET_STAGES_REQUEST:
      return { ...state, fetchingStages: true };
    case types.GET_STAGES_SUCCESS:
      return { ...state, fetchingStages: false, stages: action.payload };
    case types.GET_STAGES_FAILURE:
      return { ...state, fetchingStages: false, fetchingStagesError: true };
    /**
     * add a new source for opportunity
     */
    case types.ADD_STAGE_REQUEST:
      return { ...state, adddingStages: true };
    case types.ADD_STAGE_SUCCESS:
      return {
        ...state,
        adddingStages: false,
        stages: [...state.stages, action.payload],
      };
    case types.ADD_STAGE_FAILURE:
      return { ...state, adddingStages: false, adddingStagesError: true };
    /**
     * remove an existing stage  from opportunity
     */
    case types.REMOVE_STAGE_REQUEST:
      return { ...state, removingStages: true };
    case types.REMOVE_STAGE_SUCCESS:
      return {
        ...state,
        removingStages: false,
        stages: state.stages.filter(
          (stage) => stage.stageId !== action.payload
        ),
      };
    case types.REMOVE_STAGE_FAILURE:
      return { ...state, removingStages: false, removingStagesError: true };
    /**
     * update an existing state from opportunity
     */
    case types.UPDATE_STAGE_REQUEST:
      return { ...state, updatingStages: true };
    case types.UPDATE_STAGE_SUCCESS:
      // return { ...state, updatingStages: false, states: [...state.states, action.payload] };
      return {
        ...state,
        updatingStages: false,
        stages: state.stages.map((state) =>
          state.stageId === action.payload.stageId ? action.payload : state
        ),
      };
    case types.UPDATE_STAGE_FAILURE:
      return { ...state, updatingStages: false, updatingStagesError: true };
    /**
     * get the list of all sources
     */
    case types.GET_SOURCES_REQUEST:
      return { ...state, fetchingSources: true };
    case types.GET_SOURCES_SUCCESS:
      return { ...state, fetchingSources: false, sources: action.payload };
    case types.GET_SOURCES_FAILURE:
      return { ...state, fetchingSources: false, fetchingSourcesError: true };
    /**
     * add a new source for opportunity
     */
    case types.ADD_SOURCE_REQUEST:
      return { ...state, adddingSources: true };
    case types.ADD_SOURCE_SUCCESS:
      return {
        ...state,
        adddingSources: false,
        sources: [...state.sources, action.payload],
      };
    case types.ADD_SOURCE_FAILURE:
      return { ...state, adddingSources: false, adddingSourcesError: true };
    /**
     * remove an existing source from opportunity
     */
    case types.REMOVE_SOURCE_REQUEST:
      return { ...state, removingSources: true };
    case types.REMOVE_SOURCE_SUCCESS:
      return {
        ...state,
        removingSources: false,
        sources: state.sources.filter(
          (source) => source.leadSourceId !== action.payload
        ),
      };
    case types.REMOVE_SOURCE_FAILURE:
      return { ...state, removingSources: false, removingSourcesError: true };
    /**
     * update an existing source from opportunity
     */
    case types.UPDATE_SOURCE_REQUEST:
      return { ...state, updatingSources: true };
    case types.UPDATE_SOURCE_SUCCESS:
      // return { ...state, updatingSources: false, sources: [...state.sources, action.payload] };
      return {
        ...state,
        updatingSources: false,
        sources: state.sources.map((source) =>
          source.leadSourceId === action.payload.leadSourceId
            ? action.payload
            : source
        ),
      };
    case types.UPDATE_SOURCE_FAILURE:
      return { ...state, updatingSources: false, updatingSourcesError: true };
    /**
     * get the list of all countries
     */
    case types.GET_COUNTRIES_REQUEST:
      return { ...state, fetchingCountries: true };
    case types.GET_COUNTRIES_SUCCESS:
      return { ...state, fetchingCountries: false, countries: action.payload };
    case types.GET_COUNTRIES_FAILURE:
      return {
        ...state,
        fetchingCountries: false,
        fetchingCountriesError: true,
      };

    case types.GET_CURRENCY_REQUEST:
      return { ...state, fetchingCurrency: true };
    case types.GET_CURRENCY_SUCCESS:
      return { ...state, fetchingCurrency: false, currencies: action.payload };
    case types.GET_CURRENCY_FAILURE:
      return {
        ...state,
        fetchingCurrency: false,
        fetchingCurrencyError: true,
      };
    /**
     * get a single opportunity by its ID
     */
    case types.GET_OPPORTUNITY_BY_ID_REQUEST:
      return { ...state, fetchingOpportunityById: true };
    case types.GET_OPPORTUNITY_BY_ID_SUCCESS:
      return {
        ...state,
        fetchingOpportunityById: false,
        opportunity: action.payload,
      };
    case types.GET_OPPORTUNITY_BY_ID_FAILURE:
      return {
        ...state,
        fetchingOpportunityById: false,
        fetchingOpportunityByIdError: true,
      };
    /**
     * update a single contact by its ID
     */
    case types.UPDATE_OPPORTUNITY_BY_ID_REQUEST:
      return { ...state, updateOpportunityById: true };
    case types.UPDATE_OPPORTUNITY_BY_ID_SUCCESS:
      return {
        ...state,
        updateOpportunityById: false,
        opportunity: action.payload,
      };
    case types.UPDATE_OPPORTUNITY_BY_ID_FAILURE:
      return {
        ...state,
        updateOpportunityById: false,
        updateOpportunityByIdError: true,
      };
    /**
     * getcontact list by accountId
     */
    case types.GET_ACCOUNT_LIST_BY_OPPORTUNITY_ID_REQUEST:
      return { ...state, fetchingContactListByOpportunityId: true };
    case types.GET_ACCOUNT_LIST_BY_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        fetchingContactListByOpportunityId: false,
        contactListByOpportunityId: action.payload,
      };
    case types.GET_ACCOUNT_LIST_BY_OPPORTUNITY_ID_FAILURE:
      return {
        ...state,
        fetchingContactListByOpportunityId: false,
        fetchingContactListByOpportunityIdError: true,
      };
    /**
     * getcontact list by accountId
     */
    case types.UPDATE_ACCOUNT_LIST_BY_OPPORTUNITY_ID_REQUEST:
      return { ...state };
    case types.UPDATE_ACCOUNT_LIST_BY_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        contactListByOpportunityId: state.contactListByOpportunityId.filter(
          (item) => item.accountId !== action.payload
        ),
      };
    case types.UPDATE_ACCOUNT_LIST_BY_OPPORTUNITY_ID_FAILURE:
      return { ...state };
    /**
     * get opportunity list by accountId
     */
    case types.GET_CONTACT_LIST_BY_OPPORTUNITY_ID_REQUEST:
      return { ...state, fetchingContactListByOpportunityId: true };
    case types.GET_CONTACT_LIST_BY_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        fetchingContactListByOpportunityId: false,
        contactListByOpportunityId: action.payload,
      };
    case types.GET_CONTACT_LIST_BY_OPPORTUNITY_ID_FAILURE:
      return {
        ...state,
        fetchingContactListByOpportunityId: false,
        fetchingContactListByOpportunityIdError: true,
      };
    /**
     * get opportunity list by accountId
     */
    case types.UPDATE_CONTACT_LIST_BY_OPPORTUNITY_ID_REQUEST:
      return { ...state };
    case types.UPDATE_CONTACT_LIST_BY_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        contactListByOpportunityId: state.contactListByOpportunityId.filter(
          (item) => item.contactId !== action.payload
        ),
      };
    case types.UPDATE_CONTACT_LIST_BY_OPPORTUNITY_ID_FAILURE:
      return { ...state };
    /**
     * link existing contacts to a account
     */
    case types.LINK_CONTACTS_TO_OPPORTUNITY_ID_REQUEST:
      return { ...state, linkingContactsToOpportunityId: true };
    case types.LINK_CONTACTS_TO_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        linkingContactsToOpportunityId: false,
        contactListByOpportunityId: [
          ...state.contactListByOpportunityId,
          ...action.payload,
        ],
      };
    case types.LINK_CONTACTS_TO_OPPORTUNITY_ID_FAILURE:
      return {
        ...state,
        linkingContactsToOpportunityId: false,
        linkingContactsToOpportunityIdError: true,
      };

    case types.LINK_CONTACTS_TO_CHECK_OPPORTUNITY_ID_REQUEST:
      return { ...state, linkingCheckContactsToOpportunityId: true };
    case types.LINK_CONTACTS_TO_CHECK_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        linkingCheckContactsToOpportunityId: false,
      };
    case types.LINK_CONTACTS_TO_CHECK_OPPORTUNITY_ID_FAILURE:
      return {
        ...state,
        linkingCheckContactsToOpportunityId: false,
        linkingCheckContactsToOpportunityIdError: true,
      };
    /**
     * update contact role inside an opportunity opportunity list by accountId
     */
    case types.UPDATE_CONTACT_ROLE_BY_OPPORTUNITY_ID_REQUEST:
      return { ...state };
    case types.UPDATE_CONTACT_ROLE_BY_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        contactListByOpportunityId: state.contactListByOpportunityId.map(
          (item) =>
            item.contactId === action.payload.contactId
              ? { ...item, role: { type: action.payload.role } }
              : item
        ),
      };
    case types.UPDATE_CONTACT_ROLE_BY_OPPORTUNITY_ID_FAILURE:
      return { ...state };
    /**
     * get notes list by accountId
     */
    case types.GET_NOTES_LIST_BY_OPPORTUNITY_ID_REQUEST:
      return { ...state, fetchingNotesListByOpportunityId: true };
    case types.GET_NOTES_LIST_BY_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        fetchingNotesListByOpportunityId: false,
        notesListByOpportunityId: action.payload,
      };
    case types.GET_NOTES_LIST_BY_OPPORTUNITY_ID_FAILURE:
      return {
        ...state,
        fetchingNotesListByOpportunityId: false,
        fetchingNotesListByOpportunityIdError: true,
      };

    case types.GET_NOTES_LIST_BY_OPPORTUNITY_LINK_TASK_ID_REQUEST:
      return { ...state, fetchingNotesListByOpportunityLinkTaskId: true };
    case types.GET_NOTES_LIST_BY_OPPORTUNITY_LINK_TASK_ID_SUCCESS:
      return {
        ...state,
        fetchingNotesListByOpportunityLinkTaskId: false,
        notesListByOpportunityLinkTaskId: action.payload,
      };
    case types.GET_NOTES_LIST_BY_OPPORTUNITYLINK_TASK_ID_FAILURE:
      return {
        ...state,
        fetchingNotesListByOpportunityLinkTaskId: false,
        fetchingNotesListByOpportunityLinkTaskIdError: true,
      };
    /**
     * get notes list by accountId
     */
    case types.UPDATE_NOTES_LIST_BY_OPPORTUNITY_ID_REQUEST:
      return { ...state };
    case types.UPDATE_NOTES_LIST_BY_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        notesListByOpportunityId: state.notesListByOpportunityId.filter(
          (item) => item.noteId !== action.payload
        ),
      };
    case types.UPDATE_NOTES_LIST_BY_OPPORTUNITY_ID_FAILURE:
      return { ...state };
    /**
     * get calls list by accountId
     */
    case types.GET_CALLS_LIST_BY_OPPORTUNITY_ID_REQUEST:
      return { ...state, fetchingCallsListByOpportunityId: true };
    case types.GET_CALLS_LIST_BY_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        fetchingCallsListByOpportunityId: false,
        callsListByOpportunityId: action.payload,
      };
    case types.GET_CALLS_LIST_BY_OPPORTUNITY_ID_FAILURE:
      return {
        ...state,
        fetchingCallsListByOpportunityId: false,
        fetchingCallsListByOpportunityIdError: true,
      };
    /**
     * get calls list by accountId
     */
    case types.UPDATE_CALLS_LIST_BY_OPPORTUNITY_ID_REQUEST:
      return { ...state };
    case types.UPDATE_CALLS_LIST_BY_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        callsListByOpportunityId: state.callsListByOpportunityId.filter(
          (item) => item.callId !== action.payload
        ),
      };
    case types.UPDATE_CALLS_LIST_BY_OPPORTUNITY_ID_FAILURE:
      return { ...state };
    /**
     * get events list by accountId
     */
    case types.GET_EVENTS_LIST_BY_OPPORTUNITY_ID_REQUEST:
      return { ...state, fetchingEventsListByOpportunityId: true };
    case types.GET_EVENTS_LIST_BY_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        fetchingEventsListByOpportunityId: false,
        eventsListByOpportunityId: action.payload,
      };
    case types.GET_EVENTS_LIST_BY_OPPORTUNITY_ID_FAILURE:
      return {
        ...state,
        fetchingEventsListByOpportunityId: false,
        fetchingEventsListByOpportunityIdError: true,
      };
    /**
     * get events list by accountId
     */
    case types.UPDATE_EVENTS_LIST_BY_OPPORTUNITY_ID_REQUEST:
      return { ...state };
    case types.UPDATE_EVENTS_LIST_BY_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        eventsListByOpportunityId: state.eventsListByOpportunityId.filter(
          (item) => item.eventId !== action.payload
        ),
      };
    case types.UPDATE_EVENTS_LIST_BY_OPPORTUNITY_ID_FAILURE:
      return { ...state };
    /**
     * get tasks list by accountId
     */
    case types.GET_TASKS_LIST_BY_OPPORTUNITY_ID_REQUEST:
      return { ...state, fetchingTasksListByOpportunityId: true };
    case types.GET_TASKS_LIST_BY_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        fetchingTasksListByOpportunityId: false,
        tasksListByOpportunityId: action.payload,
      };
    case types.GET_TASKS_LIST_BY_OPPORTUNITY_ID_FAILURE:
      return {
        ...state,
        fetchingTasksListByOpportunityId: false,
        fetchingTasksListByOpportunityIdError: true,
      };
    /**
     * get tasks list by accountId
     */
    case types.UPDATE_TASKS_LIST_BY_OPPORTUNITY_ID_REQUEST:
      return { ...state };
    case types.UPDATE_TASKS_LIST_BY_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        tasksListByOpportunityId: state.tasksListByOpportunityId.filter(
          (item) => item.taskId !== action.payload
        ),
      };
    case types.UPDATE_TASKS_LIST_BY_OPPORTUNITY_ID_FAILURE:
      return { ...state };

    /**
     * Add a opportunity
     */
    case types.ADD_OPPORTUNITY_REQUEST:
      return { ...state, addingOpportunity: true };
    case types.ADD_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        addingOpportunity: false,
        addOpportunityModal: false,
        clearbit: null,
      };
    case types.ADD_OPPORTUNITY_FAILURE:
      return {
        ...state,
        addingOpportunity: false,
        addingOpportunityError: true,
        addOpportunityModal: false,
      };
    /**
     * update stage of an opportunity
     */
    case types.UPDATE_OPPORTUNITY_STAGE_REQUEST:
      return {
        ...state,
        udatingOpp: true,
        opportunities: updatedOpportunity(state.opportunities, action.payload),
      };
    case types.UPDATE_OPPORTUNITY_STAGE_SUCCESS:
      return { ...state, udatingOpp: false };
    case types.UPDATE_OPPORTUNITY_STAGE_FAILURE:
      return { ...state };
    /**
     * get list of documents of an opportunity
     */
    case types.GET_OPPORTUNITY_DOCUMENTS_REQUEST:
      return {
        ...state,
        fetchingDocumentsByOpportunityId: true,
        fetchingDocumentsByOpportunityIdError: false,
      };
    case types.GET_OPPORTUNITY_DOCUMENTS_SUCCESS:
      return {
        ...state,
        fetchingDocumentsByOpportunityId: false,
        fetchingDocumentsByOpportunityIdError: false,
        documentsByOpportunityId: action.payload,
      };
    case types.GET_OPPORTUNITY_DOCUMENTS_FAILURE:
      return {
        ...state,
        fetchingDocumentsByOpportunityId: false,
        fetchingDocumentsByOpportunityIdError: true,
      };

    /**
     * add/link opportunity
     */
    case types.ADD_OPPORTUNITY_DOCUMENT_REQUEST:
      return {
        ...state,
        adddingDocumentByOpportunityId: true,
        adddingDocumentByOpportunityIdError: false,
      };
    case types.ADD_OPPORTUNITY_DOCUMENT_SUCCESS:
      return {
        ...state,
        adddingDocumentByOpportunityId: false,
        adddingDocumentByOpportunityIdError: false,
      };
    case types.ADD_OPPORTUNITY_DOCUMENT_FAILURE:
      return {
        ...state,
        adddingDocumentByOpportunityId: false,
        adddingDocumentByOpportunityIdError: true,
      };
    /**
     * get historical proposal amount
     */
    case types.GET_HISTORICAL_PROFESSIONALPOSAL_AMOUNT_REQUEST:
      return { ...state, fetchingHistoricalProposalAmount: true };
    case types.GET_HISTORICAL_PROFESSIONALPOSAL_AMOUNT_SUCCESS:
      return {
        ...state,
        fetchingHistoricalProposalAmount: false,
        historicalProposalAmount: action.payload,
      };
    case types.GET_HISTORICAL_PROFESSIONALPOSAL_AMOUNT_FAILURE:
      return {
        ...state,
        fetchingHistoricalProposalAmount: false,
        fetchingHistoricalProposalAmountError: true,
      };

    /**
     * get opportunity velocity
     */
    case types.GET_OPPORTUNITY_VELOCITY_REQUEST:
      return { ...state, fetchingOpportunityVelocity: true };
    case types.GET_OPPORTUNITY_VELOCITY_SUCCESS:
      const data =
        typeof action.payload === "object" ? "Not Computed" : action.payload;
      return {
        ...state,
        fetchingOpportunityVelocity: false,
        opportunityVelocity: data,
      };
    case types.GET_OPPORTUNITY_VELOCITY_FAILURE:
      return {
        ...state,
        fetchingOpportunityVelocity: false,
        fetchingOpportunityVelocityError: true,
      };

    /**
     * get opportunity velocity by organization id
     */
    case types.GET_OPPORTUNITY_VELOCITY_BY_ORGANIZATION_ID_REQUEST:
      return { ...state, fetchingOpportunityVelocityByOrganizationId: true };
    case types.GET_OPPORTUNITY_VELOCITY_BY_ORGANIZATION_ID_SUCCESS:
      const dataByorgId =
        typeof action.payload === "object" ? "Not Computed" : action.payload;
      return {
        ...state,
        fetchingOpportunityVelocityByOrganizationId: false,
        opportunityVelocityByOrganizationId: dataByorgId,
      };
    case types.GET_OPPORTUNITY_VELOCITY_BY_ORGANIZATION_ID_FAILURE:
      return {
        ...state,
        fetchingOpportunityVelocityByOrganizationId: false,
        fetchingOpportunityVelocityByOrganizationIdError: true,
      };

    /**
     * get opportunity velocity
     */
    case types.GET_OPPORTUNITY_DEVIATION_REQUEST:
      return { ...state, fetchingOpportunityDeviation: true };
    case types.GET_OPPORTUNITY_DEVIATION_SUCCESS:
      return {
        ...state,
        fetchingOpportunityDeviation: false,
        opportunityDeviation: action.payload,
      };
    case types.GET_OPPORTUNITY_DEVIATION_FAILURE:
      return {
        ...state,
        fetchingOpportunityDeviation: false,
        fetchingOpportunityDeviationError: true,
      };

    /**
     * LINK product with opportunity
     */
    case types.LINK_PROFESSIONALDUCT_TO_OPPORTUNITY_REQUEST:
      return {
        ...state,
        linkingProductToOpportunity: true,
        fetchingOpportunityDeviationError: false,
      };
    case types.LINK_PROFESSIONALDUCT_TO_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        linkingProductToOpportunity: false,
        fetchingOpportunityDeviationError: false,
        linkProductModal: false,
        addLinkConfigureModal: false,
      };
    case types.LINK_PROFESSIONALDUCT_TO_OPPORTUNITY_FAILURE:
      return {
        ...state,
        linkingProductToOpportunity: false,
        fetchingOpportunityDeviationError: true,
        linkProductModal: false,
        addLinkConfigureModal: false,
      };

    case types.LINK_SERVICE_TO_OPPORTUNITY_REQUEST:
      return {
        ...state,
        linkingServiceToOpportunity: true,
        fetchingOpportunityDeviationError: false,
      };
    case types.LINK_SERVICE_TO_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        linkingServiceToOpportunity: false,
        fetchingOpportunityDeviationError: false,
        linkProductModal: false,
        addLinkConfigureModal: false,
      };
    case types.LINK_SERVICE_TO_OPPORTUNITY_FAILURE:
      return {
        ...state,
        linkingServiceToOpportunity: false,
        fetchingOpportunityDeviationError: true,
        linkProductModal: false,
        addLinkConfigureModal: false,
      };

    /**
     * get linked products of an opportunity
     */
    case types.FETCHING_PROFESSIONALDUCTS_BY_OPPORTUNITY_ID_REQUEST:
      return {
        ...state,
        fetchingProductsByOpportunityId: true,
        fetchingProductsByOpportunityIdError: false,
      };
    case types.FETCHING_PROFESSIONALDUCTS_BY_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        fetchingProductsByOpportunityId: false,
        fetchingProductsByOpportunityIdError: false,
        productsByOpportunityId: action.payload,
      };
    case types.FETCHING_PROFESSIONALDUCTS_BY_OPPORTUNITY_ID_FAILURE:
      return {
        ...state,
        fetchingProductsByOpportunityId: false,
        fetchingProductsByOpportunityIdError: true,
      };

    case types.FETCHING_SERVICE_BY_OPPORTUNITY_ID_REQUEST:
      return {
        ...state,
        fetchingServiceByOpportunityId: true,
        fetchingServiceByOpportunityIdError: false,
      };
    case types.FETCHING_SERVICE_BY_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        fetchingServiceByOpportunityId: false,
        fetchingServiceByOpportunityIdError: false,
        servicesByOpportunityId: action.payload,
      };
    case types.FETCHING_SERVICE_BY_OPPORTUNITY_ID_FAILURE:
      return {
        ...state,
        fetchingServiceByOpportunityId: false,
        fetchingServiceByOpportunityIdError: true,
      };

    /**
     * handle Opportunity form modal
     */
    case types.HANDLE_OPPORTUNITY_MODAL:
      return { ...state, addOpportunityModal: action.payload };
    /**
     * handle link Opportunity  modal
     */
    case types.HANDLE_LINK_OPPORTUNITY_MODAL:
      return { ...state, linkOpportunityModal: action.payload };
    /**
     * handle link Product  modal
     */
    case types.HANDLE_LINK_PROFESSIONALDUCT_MODAL:
      return { ...state, linkProductModal: action.payload };

    /**
     * handle link delivery  modal
     */
    case types.HANDLE_LINK_DELIVERY_MODAL:
      return { ...state, linkDeliveryModal: action.payload };
    /**
     * handle link service  modal
     */
    case types.HANDLE_LINK_SERVICE_MODAL:
      return { ...state, linkServiceModal: action.payload };
    /**
     * handle upload document  modal
     */
    case types.HANDLE_DOCUMENT_UPLOAD_MODAL:
      return { ...state, documentUploadModal: action.payload };
    /**
     * handle Opportunity Drawer
     */
    case types.HANDLE_OPPORTUNITY_DRAWER:
      return {
        ...state,
        opportunityDrawerVisible: action.payload.isVisible,
        opportunityDrawerProps: action.payload.props,
      };
    /**
     * set company name , website and logo url from clearbit
     */
    case types.SET_CLEARBIT_DATA:
      return { ...state, clearbit: action.payload };
    /**
     * set viewtype to grid or table
     */
    case types.SET_OPPORTUNITY_VIEW_TYPE:
      return { ...state, viewType: action.payload };
    /**
     * set opportunityFilterText search
     */
    case types.SET_OPPORTUNITY_FILTER_TEXT:
      return { ...state, opportunityFilterText: action.payload };
    /**
     * set opportunityFilterUser search
     */
    case types.SET_OPPORTUNITY_FILTER_USER:
      console.log(action.payload);
      return { ...state, opportunityFilterUser: action.payload };

    case types.HANDLE_LINK_CONFIGURE_MODAL:
      return { ...state, addLinkConfigureModal: action.payload };

    case types.ADD_REASON_REQUEST:
      return {
        ...state,
        addingReason: true,
      };
    case types.ADD_REASON_SUCCESS:
      return {
        ...state,
        addingReason: false,
      };
    case types.ADD_REASON_FAILURE:
      return {
        ...state,
        addingReason: false,
        addingReasonError: true,
      };

    case types.FETCH_QUOTATION_REQUEST:
      return {
        ...state,
        fetchingQutation: true,
      };
    case types.FETCH_QUOTATION_SUCCESS:
      return {
        ...state,
        fetchingQutation: false,
        quotation: action.payload,
      };
    case types.FETCH_QUOTATION_FAILURE:
      return {
        ...state,
        fetchingQutation: false,
        fetchingQutationError: true,
      };

    case types.CLEANUP_QUOTATION_TABLE:
      return { ...state, quotation: [] };

    case types.GENERATE_QUOTATION_REQUEST:
      return {
        ...state,
        generateQutation: true,
      };
    case types.GENERATE_QUOTATION_SUCCESS:
      return {
        ...state,
        generateQutation: false,
      };
    case types.GENERATE_QUOTATION_FAILURE:
      return {
        ...state,
        generateQutation: false,
        generateQutationError: true,
      };

    case types.GET_CURRENT_QUOTATION_REQUEST:
      return {
        ...state,
        getCurrentQutation: true,
      };
    case types.GET_CURRENT_QUOTATION_SUCCESS:
      return {
        ...state,
        getCurrentQutation: false,
        quotationByQuoteId: action.payload,
      };
    case types.GET_CURRENT_QUOTATION_FAILURE:
      return {
        ...state,
        getCurrentQutation: false,
        getCurrentQutationError: true,
      };

    case types.APPROVED_QUOTATION_REQUEST:
      return {
        ...state,
        approvingQutation: true,
      };
    case types.APPROVED_QUOTATION_SUCCESS:
      return {
        ...state,
        approvingQutation: false,
        quotationByQuoteId: state.quotationByQuoteId.map((item) => {
          if (item.quoteId === action.payload.quoteId) {
            return action.payload;
          } else {
            return item;
          }
        }),
      };
    case types.APPROVED_QUOTATION_FAILURE:
      return {
        ...state,
        approvingQutation: false,
        approvingQutationError: true,
      };

    //Recruit
    case types.HANDLE_RECRUIT_MODAL:
      return { ...state, addRecruitModal: action.payload };

    case types.HANDLE_TAGPROFILE_MODAL:
      return { ...state, addTagProfileModal: action.payload };

    case types.LINK_RECRUIT_TO_OPPORTUNITY_REQUEST:
      return {
        ...state,
        linkingRecruitToOpportunity: true,
      };
    case types.LINK_RECRUIT_TO_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        linkingRecruitToOpportunity: false,

        addRecruitModal: false,
      };
    case types.LINK_RECRUIT_TO_OPPORTUNITY_FAILURE:
      return {
        ...state,
        linkingRecruitToOpportunity: false,
        linkingRecruitToOpportunityError: true,
      };

    case types.GET_RECRUIT_TO_OPPORTUNITY_REQUEST:
      return {
        ...state,
        fetchingRecruitToOpportunity: true,
      };
    case types.GET_RECRUIT_TO_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        fetchingRecruitToOpportunity: false,

        recruitByOpportunityId: action.payload,
      };
    case types.GET_RECRUIT_TO_OPPORTUNITY_FAILURE:
      return {
        ...state,
        fetchingRecruitToOpportunity: false,
        fetchingRecruitToOpportunityError: true,
      };

    case types.LINK_RECRUIT_CATEGORY_TO_OPPORTUNITY_REQUEST:
      return {
        ...state,
        linkingCategoryRecruitToOpportunity: true,
      };
    case types.LINK_RECRUIT_CATEGORY_TO_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        linkingCategoryRecruitToOpportunity: false,
        recruitByOpportunityId: state.recruitByOpportunityId.map(
          (recruit, i) => {
            if (recruit.profileId === action.payload.profileId) {
              return action.payload;
            } else {
              return recruit;
            }
          }
        ),
      };
    case types.LINK_RECRUIT_CATEGORY_TO_OPPORTUNITY_FAILURE:
      return {
        ...state,
        linkingCategoryRecruitToOpportunity: false,
        linkingCategoryRecruitToOpportunityError: true,
      };

    case types.LINK_RECRUIT_CONTACT_TO_OPPORTUNITY_REQUEST:
      return {
        ...state,
        linkingContactRecruitToOpportunity: true,
      };
    case types.LINK_RECRUIT_CONTACT_TO_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        linkingContactRecruitToOpportunity: false,
        recruitByOpportunityId: state.recruitByOpportunityId.map(
          (recruit, i) => {
            if (recruit.profileId === action.payload.profileId) {
              return action.payload;
            } else {
              return recruit;
            }
          }
        ),
      };
    case types.LINK_RECRUIT_CONTACT_TO_OPPORTUNITY_FAILURE:
      return {
        ...state,
        linkingContactRecruitToOpportunity: false,
        linkingContactRecruitToOpportunityError: true,
      };

    case types.LINK_RECRUIT_STAGE_TO_OPPORTUNITY_REQUEST:
      return {
        ...state,
        linkingSatgeRecruitToOpportunity: true,
      };
    case types.LINK_RECRUIT_STAGE_TO_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        linkingSatgeRecruitToOpportunity: false,
        addTagProfileModal: false,
        recruitByOpportunityId: state.recruitByOpportunityId.map(
          (recruit, i) => {
            if (recruit.profileId === action.payload.profileId) {
              return action.payload;
            } else {
              return recruit;
            }
          }
        ),
      };
    case types.LINK_RECRUIT_STAGE_TO_OPPORTUNITY_FAILURE:
      return {
        ...state,
        linkingSatgeRecruitToOpportunity: false,
        linkingSatgeRecruitToOpportunityError: true,
      };

    case types.ADD_RECRUITMENT_PROFILE_REQUEST:
      return {
        ...state,
        addingRecruitmentProfile: true,
      };
    case types.ADD_RECRUITMENT_PROFILE_SUCCESS:
      return {
        ...state,
        addingRecruitmentProfile: false,
        addTagProfileModal: false,
      };
    case types.ADD_RECRUITMENT_PROFILE_FAILURE:
      return {
        ...state,
        addingRecruitmentProfile: false,
        addingRecruitmentProfileError: true,
      };

    case types.LINK_RECRUIT_STATUS_TO_OPPORTUNITY_REQUEST:
      return {
        ...state,
        linkingStatusRecruitToOpportunity: true,
      };
    case types.LINK_RECRUIT_STATUS_TO_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        linkingStatusRecruitToOpportunity: false,
        recruitByOpportunityId: state.recruitByOpportunityId.map(
          (recruit, i) => {
            if (recruit.profileId === action.payload.profileId) {
              return action.payload;
            } else {
              return recruit;
            }
          }
        ),
      };
    case types.LINK_RECRUIT_STATUS_TO_OPPORTUNITY_FAILURE:
      return {
        ...state,
        linkingStatusRecruitToOpportunity: false,
        linkingStatusRecruitToOpportunityError: true,
      };

    case types.GET_QUOTE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_REQUEST:
      return { ...state, fetchingQuoteListRangeByOrgIdForReport: true };
    case types.GET_QUOTE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_SUCCESS:
      return {
        ...state,
        fetchingQuoteListRangeByOrgIdForReport: false,
        quoteListRangeByOrgIdForReport: action.payload,
      };
    case types.GET_QUOTE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_FAILURE:
      return {
        ...state,
        fetchingQuoteListRangeByOrgIdForReport: false,
        fetchingQuoteListRangeByOrgIdForReportError: true,
      };

    case types.GET_QUOTE_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST:
      return { ...state, fetchingQuoteListRangeByUserIdForReport: true };
    case types.GET_QUOTE_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS:
      return {
        ...state,
        fetchingQuoteListRangeByUserIdForReport: false,
        quoteListRangeByUserIdForReport: action.payload,
      };
    case types.GET_QUOTE_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE:
      return {
        ...state,
        fetchingQuoteListRangeByUserIdForReport: false,
        fetchingQuoteListRangeByUserIdForReportError: true,
      };

    case types.GET_INVOICE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_REQUEST:
      return { ...state, fetchingInvoiceListRangeByOrgIdForReport: true };
    case types.GET_INVOICE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_SUCCESS:
      return {
        ...state,
        fetchingInvoiceListRangeByOrgIdForReport: false,
        invoiceListRangeByOrgIdForReport: action.payload,
      };
    case types.GET_INVOICE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_FAILURE:
      return {
        ...state,
        fetchingInvoiceListRangeByOrgIdForReport: false,
        fetchingInvoiceListRangeByOrgIdForReportError: true,
      };

    case types.GET_INVOICE_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST:
      return { ...state, fetchingInvoiceListRangeByUserIdForReport: true };
    case types.GET_INVOICE_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS:
      return {
        ...state,
        fetchingInvoiceListRangeByUserIdForReport: false,
        invoiceListRangeByUserIdForReport: action.payload,
      };
    case types.GET_INVOICE_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE:
      return {
        ...state,
        fetchingInvoiceListRangeByUserIdForReport: false,
        fetchingInvoiceListRangeByUserIdForReportError: true,
      };

    case types.UPDATE_OPPORTUNITY_BY_PARTICULAR_OPPORTUNIY_STAGE_REQUEST:
      return { ...state, updatingStageByparticlarOpportunity: true };
    case types.UPDATE_OPPORTUNITY_BY_PARTICULAR_OPPORTUNIY_STAGE_SUCCESS:
      return {
        ...state,
        updatingStageByparticlarOpportunity: false,
        opportunity: state.opportunity.map((item) => {
          if (item.opportunityId === action.payload.opportunityId) {
            return action.payload;
          } else {
            return item;
          }
        }),
      };

    case types.UPDATE_OPPORTUNITY_BY_PARTICULAR_OPPORTUNIY_STAGE_FAILURE:
      return { ...state, updatingStageByparticlarOpportunityError: true };

    case types.HANDLE_AGEING_MODAL:
      return { ...state, addAgeingModal: action.payload };

    case types.HANDLE_STAGE_MODAL:
      return { ...state, addStageModal: action.payload };

    case types.HANDLE_MEETINGS_MODAL:
      return { ...state, addMeetingsModal: action.payload };

    case types.HANDLE_CALLS_MODAL:
      return { ...state, addCallsModal: action.payload };

    case types.HANDLE_REMARKS_MODAL:
      return { ...state, addRemarksModal: action.payload };

    case types.LINK_PROFILE_TO_OPPORTUNITY_REQUEST:
      return {
        ...state,
        linkingProfileToOpportunity: true,
      };

    case types.LINK_PROFILE_TO_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        linkingProfileToOpportunity: false,
        profileRecruit: action.payload,
      };

    case types.LINK_PROFILE_TO_OPPORTUNITY_FAILURE:
      return {
        ...state,
        linkingProfileToOpportunity: false,
        linkingProfileToOpportunityError: true,
      };

    //Remark
    case types.ADD_REMARK_REQUEST:
      return {
        ...state,
        addingRemark: true,
      };

    case types.ADD_REMARK_SUCCESS:
      return {
        ...state,
        addingRemark: false,
        addRemarksModal: false,
      };

    case types.ADD_REMARK_FAILURE:
      return {
        ...state,
        addingRemarkError: true,
        addRemarksModal: false,
      };

    case types.GET_REMARK_REQUEST:
      return {
        ...state,
        fetchingRemark: true,
      };

    case types.GET_REMARK_SUCCESS:
      return {
        ...state,
        fetchingRemark: false,
        remark: action.payload,
      };

    case types.GET_REMARK_FAILURE:
      return {
        ...state,
        fetchingRemarkkError: true,
      };

    //RecruimentDashBoard (For All)
    case types.GET_ALL_RECRUITMENT_BY_OPP_ID_REQUEST:
      return {
        ...state,
        fetchingAllRecruitmentByOppId: true,
      };

    case types.GET_ALL_RECRUITMENT_BY_OPP_ID_SUCCESS:
      return {
        ...state,
        fetchingAllRecruitmentByOppId: false,
        allRecruitmentByOppId: action.payload,
      };

    case types.GET_ALL_RECRUITMENT_BY_OPP_ID_FAILURE:
      return {
        ...state,
        fetchingAllRecruitmentByOppIdError: true,
      };
    //RecruimentDashBoard (For Position)
    case types.GET_RECRUITMENT_POSITION_BY_OPP_ID_REQUEST:
      return {
        ...state,
        fetchingAllRecruitmentPositionByOppId: true,
      };

    case types.GET_RECRUITMENT_POSITION_BY_OPP_ID_SUCCESS:
      return {
        ...state,
        fetchingAllRecruitmentPositionByOppId: false,
        allRecruitmentPositionByOppId: action.payload,
      };

    case types.GET_RECRUITMENT_POSITION_BY_OPP_ID_FAILURE:
      return {
        ...state,
        fetchingAllRecruitmentPositionByOppIdError: true,
      };
    //RecruimentDashBoard (For AvgTime)
    case types.GET_RECRUITMENT_AVG_TIME_BY_OPP_ID_REQUEST:
      return {
        ...state,
        fetchingAllRecruitmentAvgTimeByOppId: true,
      };

    case types.GET_RECRUITMENT_AVG_TIME_BY_OPP_ID_SUCCESS:
      return {
        ...state,
        fetchingAllRecruitmentAvgTimeByOppId: false,
        allRecruitmentAvgTimeByOppId: action.payload,
      };

    case types.GET_RECRUITMENT_AVG_TIME_BY_OPP_ID_FAILURE:
      return {
        ...state,
        fetchingAllRecruitmentAvgTimeByOppIdError: true,
      };

    //RecruimentDashBoard (For Table)
    case types.GET_ALL_RECRUITMENT_DETAILS_BY_OPP_ID_REQUEST:
      return {
        ...state,
        fetchingAllRecruitmentDetailsByOppId: true,
      };

    case types.GET_ALL_RECRUITMENT_DETAILS_BY_OPP_ID_SUCCESS:
      return {
        ...state,
        fetchingAllRecruitmentDetailsByOppId: false,
        allRecruitmentDetailsByOppId: action.payload,
      };

    case types.GET_ALL_RECRUITMENT_DETAILS_BY_OPP_ID_FAILURE:
      return {
        ...state,
        fetchingAllRecruitmentDetailsByOppIdError: true,
      };

    //RecruimentDashBoard (For Position Filled)
    case types.GET_RECRUITMENT_POSITION_FILLED_BY_OPP_ID_REQUEST:
      return {
        ...state,
        fetchingAllRecruitmentPositionFilledByOppId: true,
      };

    case types.GET_RECRUITMENT_POSITION_FILLED_BY_OPP_ID_SUCCESS:
      return {
        ...state,
        fetchingAllRecruitmentPositionFilledByOppId: false,
        allRecruitmentPositionFilledByOppId: action.payload,
      };

    case types.GET_RECRUITMENT_POSITION_FILLED_BY_OPP_ID_FAILURE:
      return {
        ...state,
        fetchingAllRecruitmentPositionFilledByOppIdError: true,
      };
    //DRAGGABLE OPPORTUNITY
    case types.GET_DRAGGABLE_OPPORTUNITY_REQUEST:
      return {
        ...state,
        fetchingDraggableOpportunityById: true,
      };

    case types.GET_DRAGGABLE_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        fetchingDraggableOpportunityById: false,
        fetchingOpportunityCard: action.payload,
      };

    //CAndidta

    case types.CANDIDATE_SWITCH_REQUEST:
      return {
        ...state,
        updatingCandidateSwitch: true,
      };

    case types.CANDIDATE_SWITCH_SUCCESS:
      return {
        ...state,
        updatingCandidateSwitch: false,
        recruitByOpportunityId: state.recruitByOpportunityId.map((item) => {
          if (item.profileId === action.payload.profileId) {
            return action.payload;
          } else {
            return item;
          }
        }),
      };

    case types.CANDIDATE_SWITCH_FAILURE:
      return {
        ...state,
        updatingCandidateSwitchError: true,
      };

    //sposor
    case types.SPONSOR_SWITCH_REQUEST:
      return {
        ...state,
        updatingSponsorSwitch: true,
      };

    case types.SPONSOR_SWITCH_SUCCESS:
      return {
        ...state,
        updatingSponsorSwitch: false,
        recruitByOpportunityId: state.recruitByOpportunityId.map((item) => {
          if (item.profileId === action.payload.profileId) {
            return action.payload;
          } else {
            return item;
          }
        }),
      };

    case types.SPONSOR_SWITCH_FAILURE:
      return {
        ...state,
        updatingSponsorSwitchError: true,
      };

    case types.HANDLE_SELECT_SPONSOR_MODAL:
      return { ...state, addSponsorModal: action.payload };

    case types.GET_DRAGGABLE_OPPORTUNITY_FAILURE:
      return {
        ...state,
        fetchingDraggableOpportunityByIdError: true,
      };

    case types.ADD_DRAGGABLE_OPPORTUNITY_REQUEST:
      return { ...state, adddingOpportunity: true };
    case types.ADD_DRAGGABLE_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        adddingOpportunity: false,
        draggableOpportunityTabs: [...state.stages, action.payload],
      };
    case types.ADD_DRAGGABLE_OPPORTUNITY_FAILURE:
      return {
        ...state,
        adddingOpportunity: false,
        adddingOpportunityError: true,
      };

    case types.DELETE_OPP_PRODUCT_REQUEST:
      return { ...state, deletingOppProduct: true };
    case types.DELETE_OPP_PRODUCT_SUCCESS:
      return { ...state, deletingOppProduct: false };
    case types.DELETE_OPP_PRODUCT_FAILURE:
      return {
        ...state,
        deletingOppProduct: false,
        deletingOppProductError: true,
      };

    case types.DELETE_OPP_SERVICE_REQUEST:
      return { ...state, deletingOppService: true };
    case types.DELETE_OPP_SERVICE_SUCCESS:
      return { ...state, deletingOppService: false };
    case types.DELETE_OPP_SERVICE_FAILURE:
      return {
        ...state,
        deletingOppService: false,
        deletingOppServiceError: true,
      };
    case types.HANDLE_PURCHASE_MODAL:
      return { ...state, addPurchaseModal: action.payload };

    case types.ADD_PURCHASE_REQUEST:
      return { ...state, addingPurchase: true };
    case types.ADD_PURCHASE_SUCCESS:
      return { ...state, addingPurchase: false, addPurchaseModal: false };
    case types.ADD_PURCHASE_FAILURE:
      return {
        ...state,
        addingPurchase: false,
        addingPurchaseError: true,
      };

    case types.GET_PURCHASE_REQUEST:
      return { ...state, fetchingPurchase: true };
    case types.GET_PURCHASE_SUCCESS:
      return { ...state, fetchingPurchase: false, purchase: action.payload };
    case types.GET_PURCHASE_FAILURE:
      return {
        ...state,
        fetchingPurchase: false,
        fetchingPurchaseError: true,
      };
    case types.HANDLE_PLAYBOOK_MODAL:
      return { ...state, addPlaybookModal: action.payload };

    case types.GET_PLAYBOOK_REQUEST:
      return { ...state, fetchingPlaybooks: true };
    case types.GET_PLAYBOOK_SUCCESS:
      return {
        ...state,
        fetchingPlaybooks: false,
        playbook: action.payload,
      };
    case types.GET_PLAYBOOK_FAILURE:
      return {
        ...state,
        fetchingPlaybooks: false,
        fetchingPlaybooksError: true,
      };
    //PLAYBOOK DOCUMENT
    case types.GET_PLAYBOOK_OPPORTUNITY_DOCUMENTS_REQUEST:
      return { ...state, fetchingPlaybookOpportunities: true };
    case types.GET_PLAYBOOK_OPPORTUNITY_DOCUMENTS_SUCCESS:
      return {
        ...state,
        fetchingPlaybookOpportunities: false,
        playbookOpportunities: action.payload,
      };
    case types.GET_PLAYBOOK_OPPORTUNITY_DOCUMENTS_FAILURE:
      return {
        ...state,
        fetchingPlaybookOpportunities: false,
        fetchingPlaybookOpportunitiesError: true,
      };
    //PLAYBOOK YOUTUBE
    case types.GET_PLAYBOOK_OPPORTUNITY_YOUTUBE_REQUEST:
      return { ...state, fetchingPlaybookYoutube: true };
    case types.GET_PLAYBOOK_OPPORTUNITY_YOUTUBE_SUCCESS:
      return {
        ...state,
        fetchingPlaybookYoutube: false,
        playbookYoutube: action.payload,
      };
    case types.GET_PLAYBOOK_OPPORTUNITY_YOUTUBE_FAILURE:
      return {
        ...state,
        fetchingPlaybookYoutube: false,
        fetchingPlaybookYoutubeError: true,
      };

    //get play books by StageID
    case types.GET_PLAYBOOK_BY_STAGEID_REQUEST:
      return { ...state, fetchingPlaybookByStageId: true };
    case types.GET_PLAYBOOK_BY_STAGEID_SUCCESS:
      return {
        ...state,
        fetchingPlaybookByStageId: false,
        playbookByStageId: action.payload,
      };
    case types.GET_PLAYBOOK_BY_STAGEID_FAILURE:
      return {
        ...state,
        fetchingPlaybookByStageId: false,
        fetchingPlaybookByStageIdError: true,
      };

    //email sendfor stage

    case types.SEND_EMAIL_STAGE_UPDATE_REQUEST:
      return { ...state, emailingStage: true };
    case types.SEND_EMAIL_STAGE_UPDATE_SUCCESS:
      return {
        ...state,
        emailingStage: false,
      };
    case types.SEND_EMAIL_STAGE_UPDATE_FAILURE:
      return { ...state, emailingStage: false, emailingStageError: true };

    //Check Quote approve by quote id
    case types.CHECK_QUOTE_APPROVED_BY_QUOTE_ID_REQUEST:
      return { ...state, checkingQuoteApprovedByQuoteId: true };
    case types.CHECK_QUOTE_APPROVED_BY_QUOTE_ID_SUCCESS:
      return {
        ...state,
        checkingQuoteApprovedByQuoteId: false,
      };
    case types.CHECK_QUOTE_APPROVED_BY_QUOTE_ID_FAILURE:
      return {
        ...state,
        checkingQuoteApprovedByQuoteId: false,
        checkingQuoteApprovedByQuoteIdError: true,
      };

    //Check Quote approve by opportunity id
    case types.CHECK_QUOTE_APPROVED_BY_OPPORTUNITY_ID_REQUEST:
      return { ...state, checkingQuoteApprovedByOpportunityId: true };
    case types.CHECK_QUOTE_APPROVED_BY_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        checkingQuoteApprovedByOpportunityId: false,
        refreshedData: action.payload,
      };
    case types.CHECK_QUOTE_APPROVED_BY_OPPORTUNITY_ID_FAILURE:
      return {
        ...state,
        checkingQuoteApprovedByOpportunityId: false,
        checkingQuoteApprovedByOpportunityIdError: true,
      };

    //OPPORTUNITY REPORTS LAZY LOADING
    case types.GET_ALL_LATEST_OPPORTUNITIES_FOR_LAZY_LOADING_REQUEST:
      return { ...state, fetchingAllLatestOpportunitiesForLazyLoading: true };
    case types.GET_ALL_LATEST_OPPORTUNITIES_FOR_LAZY_LOADING_SUCCESS:
      return {
        ...state,
        fetchingAllLatestOpportunitiesForLazyLoading: false,
        opportunitiesForLazyLoading: [...action.payload],
      };
    case types.GET_ALL_LATEST_OPPORTUNITIES_FOR_LAZY_LOADING_FAILURE:
      return {
        ...state,
        fetchingAllLatestOpportunitiesForLazyLoading: false,
        fetchingAllLatestOpportunitiesForLazyLoadingError: true,
      };
    //ADD GALLERY
    case types.ADD_GALLERY_ITEM_REQUEST:
      return { ...state, addingGalleryItem: true };
    case types.ADD_GALLERY_ITEM_SUCCESS:
      return {
        ...state,
        addingGalleryItem: false,
        addGalleryModal: false,
      };
    case types.ADD_GALLERY_ITEM_FAILURE:
      return {
        ...state,
        addingGalleryItem: false,
        addingGalleryItemError: true,
      };

    //add Fashion
    case types.ADD_FASHION_ITEM_REQUEST:
      return { ...state, addingFashionItem: true };
    case types.ADD_FASHION_ITEM_SUCCESS:
      return {
        ...state,
        addingFashionItem: false,
        addFashionModal: false,
      };
    case types.ADD_FASHION_ITEM_FAILURE:
      return {
        ...state,
        addingFashionItem: false,
        addingFashionItemError: true,
      };
    //update fashion
    case types.UPDATE_FASHION_ITEM_REQUEST:
      return { ...state, updatingFashionItem: true };
    case types.UPDATE_FASHION_ITEM_SUCCESS:
      return {
        ...state,
        updatingFashionItem: false,
        // addFashionModal: false,
      };
    case types.UPDATE_FASHION_ITEM_FAILURE:
      return {
        ...state,
        updatingFashionItem: false,
        updatingFashionItemError: true,
      };

    //GET Fashion
    case types.GET_FASHION_ITEM_REQUEST:
      return { ...state, fetchingFashionItem: true };
    case types.GET_FASHION_ITEM_SUCCESS:
      return {
        ...state,
        fetchingFashionItem: false,
        fashionItem: [...action.payload],
      };
    case types.GET_FASHION_ITEM_FAILURE:
      return {
        ...state,
        fetchingFashionItem: false,
        fetchingFashionItemError: true,
      };

    //add Upload
    case types.ADD_UPLOAD_ITEM_REQUEST:
      return { ...state, addingUploadItem: true };
    case types.ADD_UPLOAD_ITEM_SUCCESS:
      return {
        ...state,
        addingUploadItem: false,
      };
    case types.ADD_UPLOAD_ITEM_FAILURE:
      return {
        ...state,
        addingUploadItem: false,
        addingUploadItemError: true,
      };

    //add Upload
    case types.GET_UPLOAD_ITEM_REQUEST:
      return { ...state, fetchingUploadItem: true };
    case types.GET_UPLOAD_ITEM_SUCCESS:
      return {
        ...state,
        fetchingUploadItem: false,
        uploadItem: action.payload,
      };
    case types.GET_UPLOAD_ITEM_FAILURE:
      return {
        ...state,
        fetchingUploadItem: false,
        fetchingUploadItemError: true,
      };

    case types.HANDLE_FASHION_MODAL:
      return { ...state, addFashionModal: action.payload };

    //Time line data
    case types.GET_TIMELINE_DATA_BY_OPPORTUNITY_ID_REQUEST:
      return { ...state, fetchingTimeLineByOpportunityId: true };
    case types.GET_TIMELINE_DATA_BY_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        fetchingTimeLineByOpportunityId: false,
        timeLineDataByOpportunityId: action.payload,
      };

    case types.GET_TIMELINE_DATA_BY_OPPORTUNITY_ID_FAILURE:
      return {
        ...state,
        fetchingTimeLineByOpportunityId: false,
        fetchingTimeLineByOpportunityIdError: true,
      };

    case types.GET_STAGE_CHECK_BY_STAGEID_REQUEST:
      return { ...state, fetchingStageCheckByStageId: true };
    case types.GET_STAGE_CHECK_BY_STAGEID_SUCCESS:
      return { ...state, fetchingStageCheckByStageId: false };
    case types.GET_STAGE_CHECK_BY_STAGEID_FAILURE:
      return {
        ...state,
        fetchingStageCheckByStageId: false,
        fetchingStageCheckByStageIdError: true,
      };

    //suspend

    case types.GET_STAGE_DELETE_BY_STAGEID_REQUEST:
      return { ...state, fetchingStageDeleteByStageId: true };
    case types.GET_STAGE_DELETE_BY_STAGEID_SUCCESS:
      return { ...state, fetchingStageDeleteByStageId: false };
    case types.GET_STAGE_DELETE_BY_STAGEID_FAILURE:
      return {
        ...state,
        fetchingStageDeleteByStageId: false,
        fetchingStageDeleteByStageIdError: true,
      };

    case types.HANDLE_OPPORTUNITY_ACTIVITY_MODAL:
      return { ...state, addOpportunityActivityModal: action.payload };

    case types.GET_OPPORTUNITY_ACTIVITY_DATA_REQUEST:
      return { ...state, fetchingOpportunityActivityData: true };
    case types.GET_OPPORTUNITY_ACTIVITY_DATA_SUCCESS:
      return {
        ...state,
        fetchingOpportunityActivityData: false,
        opportunityActivity: action.payload,
      };
    case types.GET_OPPORTUNITY_ACTIVITY_DATA_FAILURE:
      return {
        ...state,
        fetchingOpportunityActivityData: false,
        fetchingOpportunityActivityDataError: true,
      };

    case types.GET_OPPORTUNITY_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST:
      return { ...state, fetchingOpportunityListRangeByUserIdForReport: true };
    case types.GET_OPPORTUNITY_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS:
      return {
        ...state,
        fetchingOpportunityListRangeByUserIdForReport: false,
        opportunityListRangeByUserIdForReport: action.payload,
      };
    case types.GET_OPPORTUNITY_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE:
      return {
        ...state,
        fetchingOpportunityListRangeByUserIdForReport: false,
        fetchingOpportunityListRangeByUserIdForReportError: true,
      };

    case types.GET_QUOTATION_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST:
      return { ...state, fetchingQuotationListRangeByUserIdForReport: true };
    case types.GET_QUOTATION_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS:
      return {
        ...state,
        fetchingQuotationListRangeByUserIdForReport: false,
        quotationListRangeByUserIdForReport: action.payload,
      };
    case types.GET_QUOTATION_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE:
      return {
        ...state,
        fetchingQuotationListRangeByUserIdForReport: false,
        fetchingQuotationListRangeByUserIdForReportError: true,
      };

    case types.GET_BILLING_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST:
      return { ...state, fetchingBillingListRangeByUserIdForReport: true };
    case types.GET_BILLING_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS:
      return {
        ...state,
        fetchingBillingListRangeByUserIdForReport: false,
        billingListRangeByUserIdForReport: action.payload,
      };
    case types.GET_BILLING_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE:
      return {
        ...state,
        fetchingBillingListRangeByUserIdForReport: false,
        fetchingBillingListRangeByUserIdForReportError: true,
      };

    case types.GET_OPPORTUNITY_STAGE_DATA_REQUEST:
      return { ...state, fetchingOpportunityStageData: true };
    case types.GET_OPPORTUNITY_STAGE_DATA_SUCCESS:
      return {
        ...state,
        fetchingOpportunityStageData: false,
        opportunitiesStage: action.payload,
      };
    case types.GET_OPPORTUNITY_STAGE_DATA_FAILURE:
      return {
        ...state,
        fetchingOpportunityStageData: false,
        fetchingOpportunityStageDataError: true,
      };

    case types.ADD_OPPORTUNITY_STAGE_DISABLE_REQUEST:
      return { ...state, adddingOpportunityStageDisable: true };
    case types.ADD_OPPORTUNITY_STAGE_DISABLE_REQUEST:
      return {
        ...state,
        adddingOpportunityStageDisable: false,
        opportunity:
          state.opportunity.opportunityId === action.payload.opportunityId
            ? action.payload
            : state.opportunity,

        // linkDeliveryModal: false,
      };
    case types.ADD_OPPORTUNITY_STAGE_DISABLE_SUCCESS:
      return {
        ...state,
        adddingOpportunityStageDisable: false,
        adddingOpportunityStageDisableError: true,
      };
    case types.HANDLE_COSTING_MODAL:
      return { ...state, addCostingModal: action.payload };

    case types.SET_CLEARBIT_COSTING_DATA:
      return { ...state, clearbitCosting: action.payload };

    case types.HANDLE_GALLERY_MODAL:
      return { ...state, addGalleryModal: action.payload };

    case types.FETCH_GALLERY_REQUEST:
      return { ...state, fetchingGalleryImages: true };
    case types.FETCH_GALLERY_SUCCESS:
      return {
        ...state,
        fetchingGalleryImages: false,
        galleryImages: action.payload,
      };
    case types.FETCH_GALLERY_FAILURE:
      return {
        ...state,
        fetchingGalleryImages: false,
        fetchingGalleryImagesError: true,
      };

    //COSTING SERVICE
    case types.FETCHING_COSTING_SERVICE_BY_OPPORTUNITY_ID_REQUEST:
      return {
        ...state,
        fetchingCostingServiceByOpportunityId: true,
        fetchingCostingServiceByOpportunityId: false,
      };
    case types.FETCHING_COSTING_SERVICE_BY_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        fetchingCostingServiceByOpportunityId: false,
        fetchingCostingServiceByOpportunityId: false,
        costingServicesByOpportunityId: action.payload,
      };
    case types.FETCHING_COSTING_SERVICE_BY_OPPORTUNITY_ID_FAILURE:
      return {
        ...state,
        fetchingCostingServiceByOpportunityId: false,
        fetchingCostingServiceByOpportunityIdError: true,
      };

    //COSTING PRODUCT
    case types.FETCHING_COSTING_PRODUCT_BY_OPPORTUNITY_ID_REQUEST:
      return {
        ...state,
        fetchingCostingProductByOpportunityId: true,
        fetchingCostingProductByOpportunityId: false,
      };
    case types.FETCHING_COSTING_PRODUCT_BY_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        fetchingCostingProductByOpportunityId: false,
        fetchingCostingProductByOpportunityId: false,
        costingProductsByOpportunityId: action.payload,
      };
    case types.FETCHING_COSTING_PRODUCT_BY_OPPORTUNITY_ID_FAILURE:
      return {
        ...state,
        fetchingCostingProductByOpportunityId: false,
        fetchingCostingProductByOpportunityIdError: true,
      };
    //ADD PRODUCT IN COSTING
    case types.ADD_COSTING_PRODUCT_REQUEST:
      return { ...state, addCostingProduct: true };
    case types.ADD_COSTING_PRODUCT_SUCCESS:
      return {
        ...state,
        addCostingProduct: false,
        linkDeliveryModal: false,
      };
    case types.ADD_COSTING_PRODUCT_FAILURE:
      return {
        ...state,
        addCostingProduct: false,
        addCostingProductError: true,
      };

    //ADD SERVICE IN COSTING
    case types.ADD_COSTING_SERVICE_REQUEST:
      return { ...state, addCostingService: true };
    case types.ADD_COSTING_SERVICE_SUCCESS:
      return {
        ...state,
        addCostingService: false,
        linkDeliveryModal: false,
      };
    case types.ADD_COSTING_SERVICE_FAILURE:
      return {
        ...state,
        addCostingService: false,
        addCostingServiceError: true,
      };

    //GENERATE QUOTE IN COSTING

    case types.FETCH_COSTING_PRODUCT_AND_SERVICE_REQUEST:
      return {
        ...state,
        fetchingCostingQuotation: true,
      };
    case types.FETCH_COSTING_PRODUCT_AND_SERVICE_SUCCESS:
      return {
        ...state,
        fetchingCostingQuotation: false,
        costingQuotation: action.payload,
      };
    case types.FETCH_COSTING_PRODUCT_AND_SERVICE_FAILURE:
      return {
        ...state,
        fetchingCostingQuotation: false,
        fetchingCostingQuotationError: true,
      };

    //quote details for costing
    case types.GET_CURRENT_QUOTATION_FOR_COSTING_REQUEST:
      return {
        ...state,
        fetchingQuoteDetailsForCosting: true,
      };
    case types.GET_CURRENT_QUOTATION_FOR_COSTING_SUCCESS:
      return {
        ...state,
        fetchingQuoteDetailsForCosting: false,
        costingQuotationByQuoteId: action.payload,
      };
    case types.GET_CURRENT_QUOTATION_FOR_COSTING_FAILURE:
      return {
        ...state,
        fetchingQuoteDetailsForCosting: false,
        fetchingQuoteDetailsForCostingError: true,
      };
    //COSTING PRODUCTS BY TASK
    case types.FETCHING_PRODUCTS_BY_TASK_ID_REQUEST:
      return {
        ...state,
        fetchingProductsByTaskId: true,
        fetchingProductsByTaskIdError: false,
      };
    case types.FETCHING_PRODUCTS_BY_TASK_ID_SUCCESS:
      return {
        ...state,
        fetchingProductsByTaskId: false,
        fetchingProductsByTaskIdError: false,
        productsByTaskId: action.payload,
      };
    case types.FETCHING_PRODUCTS_BY_TASK_ID_FAILURE:
      return {
        ...state,
        fetchingProductsByTaskId: false,
        fetchingProductsByTaskIdError: true,
      };

    //COSTING SERVICE BY TASK
    case types.FETCHING_SERVICE_BY_TASK_ID_REQUEST:
      return {
        ...state,
        fetchingServiceByTaskId: true,
        fetchingServiceByTaskIdError: false,
      };
    case types.FETCHING_SERVICE_BY_TASK_ID_SUCCESS:
      return {
        ...state,
        fetchingServiceByTaskId: false,
        fetchingServiceByTaskIdError: false,
        serviceByTaskId: action.payload,
      };
    case types.FETCHING_SERVICE_BY_TASK_ID_FAILURE:
      return {
        ...state,
        fetchingServiceByTaskId: false,
        fetchingServiceByTaskIdError: true,
      };

    case types.GENERATE_COSTING_QUOTATION_REQUEST:
      return {
        ...state,
        generateCostingQuotation: true,
      };
    case types.GENERATE_COSTING_QUOTATION_SUCCESS:
      return {
        ...state,
        generateCostingQuotation: false,
      };
    case types.GENERATE_COSTING_QUOTATION_FAILURE:
      return {
        ...state,
        generateCostingQuotation: false,
        generateCostingQuotationError: true,
      };

    case types.FETCHING_COST_SHEET_BY_COST_ID_REQUEST:
      return {
        ...state,
        fetchingCostSheetByCostId: true,
      };
    case types.FETCHING_COST_SHEET_BY_COST_ID_SUCCESS:
      return {
        ...state,
        fetchingCostSheetByCostId: false,
        costSheetByCostId: action.payload,
      };
    case types.FETCHING_COST_SHEET_BY_COST_ID_FAILURE:
      return {
        ...state,
        fetchingCostSheetByCostId: false,
        fetchingCostSheetByCostIdError: true,
      };

    case types.FETCHING_COST_SHEET_BY_OPPORTUNITY_ID_REQUEST:
      return {
        ...state,
        fetchingCostSheetByOpportunityId: true,
      };
    case types.FETCHING_COST_SHEET_BY_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        fetchingCostSheetByOpportunityId: false,
        costSheetByOpportunityId: action.payload,
      };
    case types.FETCHING_COST_SHEET_BY_OPPORTUNITY_ID_FAILURE:
      return {
        ...state,
        fetchingCostSheetByOpportunityId: false,
        fetchingCostSheetByOpportunityIdError: true,
      };

    //get opportunity customer

    case types.GET_CUSTOMERS_REQUEST:
      return { ...state, fetchingCustomers: true };
    case types.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        fetchingCustomers: false,
        customers: action.payload,
      };
    case types.GET_CUSTOMERS_FAILURE:
      return {
        ...state,
        fetchingCustomers: false,
        fetchingCustomersError: true,
      };

    // add customer

    case types.ADD_CUSTOMER_REQUEST:
      return { ...state, addingCustomers: true };
    case types.ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        addingCustomers: false,
        customers: action.payload,
      };
    case types.ADD_CUSTOMER_FAILURE:
      return {
        ...state,
        addingCustomers: false,
        addingCustomersError: true,
      };

    // remove customer

    case types.REMOVE_CUSTOMER_REQUEST:
      return { ...state, removingCustomers: true };
    case types.REMOVE_CUSTOMER_SUCCESS:
      return {
        ...state,
        removingCustomers: false,
        customers: state.customers.filter(
          (customer) => customer.typeId !== action.payload
        ),
      };
    case types.REMOVE_CUSTOMER_FAILURE:
      return {
        ...state,
        removingCustomers: false,
        removingCustomersError: true,
      };

    //   update an existing customer from opportunity

    case types.UPDATE_CUSTOMER_REQUEST:
      return { ...state, updatingCustomers: true };
    case types.UPDATE_CUSTOMER_SUCCESS:
      // return { ...state, updatingCustomers: false, sources: [...state.sources, action.payload] };
      return {
        ...state,
        updatingCustomers: false,
        customers: state.customers.map((customer) =>
          customer.typeId === action.payload.typeId ? action.payload : customer
        ),
      };
    case types.UPDATE_CUSTOMER_FAILURE:
      return {
        ...state,
        updatingCustomers: false,
        updatingCustomersError: true,
      };

    case types.DROP_OPPORTUNITY_BY_OPPORTUNITY_ID_REQUEST:
      return { ...state, deleteOpportunity: true };
    case types.DROP_OPPORTUNITY_BY_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        deleteTask: false,
        documentsByOpportunityId: state.documentsByOpportunityId.filter(
          (item) => item.documentId !== action.payload
        ),
      };
    case types.DROP_OPPORTUNITY_BY_OPPORTUNITY_ID_FAILURE:
      return {
        ...state,
        deleteOpportunity: false,
        deleteOpportunityError: false,
      };

    //get opportunity contact

    case types.GET_CONTACTS_REQUEST:
      return { ...state, fetchingContacts: true };
    case types.GET_CONTACTS_SUCCESS:
      return {
        ...state,
        fetchingContacts: false,
        contacts: action.payload,
      };
    case types.GET_CONTACTS_FAILURE:
      return {
        ...state,
        fetchingContacts: false,
        fetchingContactsError: true,
      };

    // add contact

    case types.ADD_CONTACT_REQUEST:
      return { ...state, addingContacts: true };
    case types.ADD_CONTACT_SUCCESS:
      return {
        ...state,
        addingContacts: false,
        contacts: action.payload,
      };
    case types.ADD_CONTACT_FAILURE:
      return {
        ...state,
        addingContacts: false,
        addingContactsError: true,
      };

    // remove contact

    case types.REMOVE_CONTACT_REQUEST:
      return { ...state, removingContacts: true };
    case types.REMOVE_CONTACT_SUCCESS:
      return {
        ...state,
        removingContacts: false,
        contacts: state.contacts.filter(
          (contact) => contact.leadContactId !== action.payload
        ),
      };
    case types.REMOVE_CONTACT_FAILURE:
      return {
        ...state,
        removingContacts: false,
        removingContactsError: true,
      };

    //   update an existing contact from opportunity

    case types.UPDATE_CONTACT_REQUEST:
      return { ...state, updatingContacts: true };
    case types.UPDATE_CONTACT_SUCCESS:
      // return { ...state, updatingCustomers: false, sources: [...state.sources, action.payload] };
      return {
        ...state,
        updatingContacts: false,
        contacts: state.contacts.map((contact) =>
          contact.leadContactId === action.payload.leadContactId
            ? action.payload
            : contact
        ),
      };
    case types.UPDATE_CONTACT_FAILURE:
      return {
        ...state,
        updatingContacts: false,
        updatingContactsError: true,
      };

    // get document

    case types.GET_DOCUMENT_REQUEST:
      return { ...state, fetchingDocuments: true };
    case types.GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        fetchingDocuments: false,
        documents: action.payload,
      };
    case types.GET_DOCUMENT_FAILURE:
      return {
        ...state,
        fetchingDocuments: false,
        fetchingDocumentsError: true,
      };

    //add document

    case types.ADD_DOCUMENT_REQUEST:
      return { ...state, addingDocuments: true };
    case types.ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
        addingDocuments: false,
        documents: action.payload,
      };
    case types.ADD_DOCUMENT_FAILURE:
      return {
        ...state,
        addingDocuments: false,
        addingDocumentsError: true,
      };

    /**
     * update event modal
     */
    case types.HANDLE_UPDATE_EVENT_MODAL:
      return { ...state, updateEventModal: action.payload };

    /**
     * update call modal
     */
    case types.HANDLE_UPDATE_CALL_MODAL:
      return { ...state, updateCallModal: action.payload };

    /**
     * update task modal
     */
    case types.HANDLE_UPDATE_TASK_MODAL:
      return { ...state, updateTaskModal: action.payload };

    /**
     * fetching customer name
     */

    case types.INPUT_SEARCH_OPPORTUNITY_DATA_REQUEST:
      return { ...state, fetchingInputOpportunityData: true };
    case types.INPUT_SEARCH_OPPORTUNITY_DATA_SUCCESS:
      return {
        ...state,
        fetchingInputOpportunityData: false,
        OPPORTUNITYs: action.payload,
      };
    case types.INPUT_SEARCH_OPPORTUNITY_DATA_FAILURE:
      return { ...state, fetchingInputOpportunityDataError: true };

    /**
     * remove an existing DOCUMENT from opportunity
     */
    case types.REMOVE_DOCUMENT_REQUEST:
      return { ...state, removingDocuments: true };
    case types.REMOVE_DOCUMENT_SUCCESS:
      return {
        ...state,
        removingDocuments: false,
        documents: state.documents.filter(
          (source) => source.leadDocumentId !== action.payload
        ),
      };
    case types.REMOVE_DOCUMENT_FAILURE:
      return {
        ...state,
        removingDocuments: false,
        removingDocumentsError: true,
      };

    /**
     * update an existing document from opportunity
     */
    case types.UPDATE_DOCUMENT_REQUEST:
      return { ...state, updatingDocuments: true };
    case types.UPDATE_DOCUMENT_SUCCESS:
      // return { ...state, updatingSources: false, sources: [...state.sources, action.payload] };
      return {
        ...state,
        updatingDocuments: false,
        documents: state.documents.map((document) =>
          document.leadDocumentId === action.payload.leadDocumentId
            ? action.payload
            : document
        ),
      };
    case types.UPDATE_DOCUMENT_FAILURE:
      return { ...state, updatingDocuments: false, updatingDocumentsError: true };

    //SHARE Contact Customer Permissiom
    case types.ADD_SHARE_OPPORTUNITY_PERMISSION_REQUEST:
      return { ...state, addSharingOpportunity: true };

    case types.ADD_SHARE_OPPORTUNITY_PERMISSION_SUCCESS:
      return { ...state, addSharingOpportunity: false, opportunityByUserId: action.payload };

    case types.ADD_SHARE_OPPORTUNITY_PERMISSION_FAILURE:
      return {
        ...state,
        addSharingOpportunity: false,
        addSharingOpportunityError: true,
      };

    case types.GET_PERMISSIONS_LIST_OPPORTUNITY_REQUEST:
      return { ...state, fetchingPermissionsListOpportunity: true };
    case types.GET_PERMISSIONS_LIST_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        fetchingPermissionsListOpportunity: false,
        permissionsDataListOpportunity: action.payload,
      };
    case types.GET_PERMISSIONS_LIST_OPPORTUNITY_FAILURE:
      return {
        ...state,
        fetchingPermissionsListOpportunity: false,
        fetchingPermissionsListOpportunityError: false,
      };
    default:
      return state;
  }
};
