export const GET_MAP_DASHBOARD_ACCOUNTS_REQUEST =
  "GET_MAP_DASHBOARD_ACCOUNTS_REQUEST";
export const GET_MAP_DASHBOARD_ACCOUNTS_SUCCESS =
  "GET_MAP_DASHBOARD_ACCOUNTS_SUCCESS";
export const GET_MAP_DASHBOARD_ACCOUNTS_FAILURE =
  "GET_MAP_DASHBOARD_ACCOUNTS_FAILURE";

export const GET_ACCOUNTS_REQUEST = "GET_ACCOUNTS_REQUEST";
export const GET_ACCOUNTS_SUCCESS = "GET_ACCOUNTS_SUCCESS";
export const GET_ACCOUNTS_FAILURE = "GET_ACCOUNTS_FAILURE";

export const GET_ACCOUNT_QUOTE_REQUEST = "GET_ACCOUNT_QUOTE_REQUEST";
export const GET_ACCOUNT_QUOTE_SUCCESS = "GET_ACCOUNT_QUOTE_SUCCESS";
export const GET_ACCOUNT_QUOTE_FAILURE = "GET_ACCOUNT_QUOTE_FAILURE";

export const GET_ACCOUNT_ORDER_REQUEST = "GET_ACCOUNT_ORDER_REQUEST";
export const GET_ACCOUNT_ORDER_SUCCESS = "GET_ACCOUNT_ORDER_SUCCESS";
export const GET_ACCOUNT_ORDER_FAILURE = "GET_ACCOUNT_ORDER_FAILURE";

export const GET_LATEST_ACCOUNTS_REQUEST = "GET_LATEST_ACCOUNTS_REQUEST";
export const GET_LATEST_ACCOUNTS_SUCCESS = "GET_LATEST_ACCOUNTS_SUCCESS";
export const GET_LATEST_ACCOUNTS_FAILURE = "GET_LATEST_ACCOUNTS_FAILURE";

export const GET_ALL_LATEST_ACCOUNTS_REQUEST =
  "GET_ALL_LATEST_ACCOUNTS_REQUEST";
export const GET_ALL_LATEST_ACCOUNTS_SUCCESS =
  "GET_ALL_LATEST_ACCOUNTS_SUCCESS";
export const GET_ALL_LATEST_ACCOUNTS_FAILURE =
  "GET_ALL_LATEST_ACCOUNTS_FAILURE";

export const GET_LATEST_ACCOUNTS_BY_ORGANIZATION_ID_REQUEST =
  "GET_LATEST_ACCOUNTS_BY_ORGANIZATION_ID_REQUEST";
export const GET_LATEST_ACCOUNTS_BY_ORGANIZATION_ID_SUCCESS =
  "GET_LATEST_ACCOUNTS_BY_ORGANIZATION_ID_SUCCESS";
export const GET_LATEST_ACCOUNTS_BY_ORGANIZATION_ID_FAILURE =
  "GET_LATEST_ACCOUNTS_BY_ORGANIZATION_ID_FAILURE";

export const GET_ACCOUNTS_BY_FUNNEL_VALUE_REQUEST =
  "GET_ACCOUNTS_BY_FUNNEL_VALUE_REQUEST";
export const GET_ACCOUNTS_BY_FUNNEL_VALUE_SUCCESS =
  "GET_ACCOUNTS_BY_FUNNEL_VALUE_SUCCESS";
export const GET_ACCOUNTS_BY_FUNNEL_VALUE_FAILURE =
  "GET_ACCOUNTS_BY_FUNNEL_VALUE_FAILURE";

export const GET_ALL_ACCOUNTS_BY_FUNNEL_VALUE_REQUEST =
  "GET_ALL_ACCOUNTS_BY_FUNNEL_VALUE_REQUEST";
export const GET_ALL_ACCOUNTS_BY_FUNNEL_VALUE_SUCCESS =
  "GET_ALL_ACCOUNTS_BY_FUNNEL_VALUE_SUCCESS";
export const GET_ALL_ACCOUNTS_BY_FUNNEL_VALUE_FAILURE =
  "GET_ALL_ACCOUNTS_BY_FUNNEL_VALUE_FAILURE";

export const GET_EXISTING_ACCOUNTS_REQUEST = "GET_EXISTING_ACCOUNTS_REQUEST";
export const GET_EXISTING_ACCOUNTS_SUCCESS = "GET_EXISTING_ACCOUNTS_SUCCESS";
export const GET_EXISTING_ACCOUNTS_FAILURE = "GET_EXISTING_ACCOUNTS_FAILURE";

export const GET_ALL_EXISTING_ACCOUNTS_REQUEST =
  "GET_ALL_EXISTING_ACCOUNTS_REQUEST";
export const GET_ALL_EXISTING_ACCOUNTS_SUCCESS =
  "GET_ALL_EXISTING_ACCOUNTS_SUCCESS";
export const GET_ALL_EXISTING_ACCOUNTS_FAILURE =
  "GET_ALL_EXISTING_ACCOUNTS_FAILURE";

export const GET_TOP_TEN_ACCOUNTS_REQUEST = "GET_TOP_TEN_ACCOUNTS_REQUEST";
export const GET_TOP_TEN_ACCOUNTS_SUCCESS = "GET_TOP_TEN_ACCOUNTS_SUCCESS";
export const GET_TOP_TEN_ACCOUNTS_FAILURE = "GET_TOP_TEN_ACCOUNTS_FAILURE";

export const GET_NEW_EXISTING_ACCOUNTS_REQUEST =
  "GET_NEW_EXISTING_ACCOUNTS_REQUEST";
export const GET_NEW_EXISTING_ACCOUNTS_SUCCESS =
  "GET_NEW_EXISTING_ACCOUNTS_SUCCESS";
export const GET_NEW_EXISTING_ACCOUNTS_FAILURE =
  "GET_NEW_EXISTING_ACCOUNTS_FAILURE";

export const GET_NEW_EXISTING_ACCOUNTS_OF_ALL_USERS_REQUEST =
  "GET_NEW_EXISTING_ACCOUNTS_OF_ALL_USERS_REQUEST";
export const GET_NEW_EXISTING_ACCOUNTS_OF_ALL_USERS_SUCCESS =
  "GET_NEW_EXISTING_ACCOUNTS_OF_ALL_USERS_SUCCESS";
export const GET_NEW_EXISTING_ACCOUNTS_OF_ALL_USERS_FAILURE =
  "GET_NEW_EXISTING_ACCOUNTS_OF_ALL_USERS_FAILURE";

export const GET_NEW_EXISTING_ACCOUNTS_OF_ALL_TEAMS_REQUEST =
  "GET_NEW_EXISTING_ACCOUNTS_OF_ALL_TEAMS_REQUEST";
export const GET_NEW_EXISTING_ACCOUNTS_OF_ALL_TEAMS_SUCCESS =
  "GET_NEW_EXISTING_ACCOUNTS_OF_ALL_TEAMS_SUCCESS";
export const GET_NEW_EXISTING_ACCOUNTS_OF_ALL_TEAMS_FAILURE =
  "GET_NEW_EXISTING_ACCOUNTS_OF_ALL_TEAMS_FAILURE";

export const GET_NEW_EXISTING_ACCOUNTS_BY_TEAM_ID_REQUEST =
  "GET_NEW_EXISTING_ACCOUNTS_BY_TEAM_ID_REQUEST";
export const GET_NEW_EXISTING_ACCOUNTS_BY_TEAM_ID_SUCCESS =
  "GET_NEW_EXISTING_ACCOUNTS_BY_TEAM_ID_SUCCESS";
export const GET_NEW_EXISTING_ACCOUNTS_BY_TEAM_ID_FAILURE =
  "GET_NEW_EXISTING_ACCOUNTS_BY_TEAM_ID_FAILURE";

export const GET_ACCOUNT_BY_ID_REQUEST = "GET_ACCOUNT_BY_ID_REQUEST";
export const GET_ACCOUNT_BY_ID_SUCCESS = "GET_ACCOUNT_BY_ID_SUCCESS";
export const GET_ACCOUNT_BY_ID_FAILURE = "GET_ACCOUNT_BY_ID_FAILURE";

export const UPDATE_ACCOUNT_BY_ID_REQUEST = "UPDATE_ACCOUNT_BY_ID_REQUEST";
export const UPDATE_ACCOUNT_BY_ID_SUCCESS = "UPDATE_ACCOUNT_BY_ID_SUCCESS";
export const UPDATE_ACCOUNT_BY_ID_FAILURE = "UPDATE_ACCOUNT_BY_ID_FAILURE";

export const GET_ACCOUNT_LIST_RANGE_BY_USER_ID_REQUEST =
  "GET_ACCOUNT_LIST_RANGE_BY_USER_ID_REQUEST";
export const GET_ACCOUNT_LIST_RANGE_BY_USER_ID_SUCCESS =
  "GET_ACCOUNT_LIST_RANGE_BY_USER_ID_SUCCESS";
export const GET_ACCOUNT_LIST_RANGE_BY_USER_ID_FAILURE =
  "GET_ACCOUNT_LIST_RANGE_BY_USER_ID_FAILURE";

export const GET_ACCOUNT_LIST_RANGE_OF_ALL_USERS_REQUEST =
  "GET_ACCOUNT_LIST_RANGE_OF_ALL_USERS_REQUEST";
export const GET_ACCOUNT_LIST_RANGE_OF_ALL_USERS_SUCCESS =
  "GET_ACCOUNT_LIST_RANGE_OF_ALL_USERS_SUCCESS";
export const GET_ACCOUNT_LIST_RANGE_OF_ALL_USERS_FAILURE =
  "GET_ACCOUNT_LIST_RANGE_OF_ALL_USERS_FAILURE";

export const GET_CONTACT_LIST_BY_ACCOUNT_ID_REQUEST =
  "GET_CONTACT_LIST_BY_ACCOUNT_ID_REQUEST";
export const GET_CONTACT_LIST_BY_ACCOUNT_ID_SUCCESS =
  "GET_CONTACT_LIST_BY_ACCOUNT_ID_SUCCESS";
export const GET_CONTACT_LIST_BY_ACCOUNT_ID_FAILURE =
  "GET_CONTACT_LIST_BY_ACCOUNT_ID_FAILURE";

export const UPDATE_CONTACT_LIST_BY_ACCOUNT_ID_REQUEST =
  "UPDATE_CONTACT_LIST_BY_ACCOUNT_ID_REQUEST";
export const UPDATE_CONTACT_LIST_BY_ACCOUNT_ID_SUCCESS =
  "UPDATE_CONTACT_LIST_BY_ACCOUNT_ID_SUCCESS";
export const UPDATE_CONTACT_LIST_BY_ACCOUNT_ID_FAILURE =
  "UPDATE_CONTACT_LIST_BY_ACCOUNT_ID_FAILURE";

export const LINK_CONTACTS_TO_ACCOUNT_ID_REQUEST =
  "LINK_CONTACTS_TO_ACCOUNT_ID_REQUEST";
export const LINK_CONTACTS_TO_ACCOUNT_ID_SUCCESS =
  "LINK_CONTACTS_TO_ACCOUNT_ID_SUCCESS";
export const LINK_CONTACTS_TO_ACCOUNT_ID_FAILURE =
  "LINK_CONTACTS_TO_ACCOUNT_ID_FAILURE";

export const ADD_COMPETITOR_BY_ACCOUNT_ID_REQUEST =
  "ADD_COMPETITOR_BY_ACCOUNT_ID_REQUEST";
export const ADD_COMPETITOR_BY_ACCOUNT_ID_SUCCESS =
  "ADD_COMPETITOR_BY_ACCOUNT_ID_SUCCESS";
export const ADD_COMPETITOR_BY_ACCOUNT_ID_FAILURE =
  "ADD_COMPETITOR_BY_ACCOUNT_ID_FAILURE";

export const GET_COMPETITORS_BY_ACCOUNT_ID_REQUEST =
  "GET_COMPETITORS_BY_ACCOUNT_ID_REQUEST";
export const GET_COMPETITORS_BY_ACCOUNT_ID_SUCCESS =
  "GET_COMPETITORS_BY_ACCOUNT_ID_SUCCESS";
export const GET_COMPETITORS_BY_ACCOUNT_ID_FAILURE =
  "GET_COMPETITORS_BY_ACCOUNT_ID_FAILURE";

export const UPDATE_COMPETITORS_BY_ACCOUNT_ID_REQUEST =
  "UPDATE_COMPETITORS_BY_ACCOUNT_ID_REQUEST";
export const UPDATE_COMPETITORS_BY_ACCOUNT_ID_SUCCESS =
  "UPDATE_COMPETITORS_BY_ACCOUNT_ID_SUCCESS";
export const UPDATE_COMPETITORS_BY_ACCOUNT_ID_FAILURE =
  "UPDATE_COMPETITORS_BY_ACCOUNT_ID_FAILURE";

export const ADD_RENEWAL_BY_ACCOUNT_ID_REQUEST =
  "ADD_RENEWAL_BY_ACCOUNT_ID_REQUEST";
export const ADD_RENEWAL_BY_ACCOUNT_ID_SUCCESS =
  "ADD_RENEWAL_BY_ACCOUNT_ID_SUCCESS";
export const ADD_RENEWAL_BY_ACCOUNT_ID_FAILURE =
  "ADD_RENEWAL_BY_ACCOUNT_ID_FAILURE";

export const GET_RENEWALS_BY_ACCOUNT_ID_REQUEST =
  "GET_RENEWALS_BY_ACCOUNT_ID_REQUEST";
export const GET_RENEWALS_BY_ACCOUNT_ID_SUCCESS =
  "GET_RENEWALS_BY_ACCOUNT_ID_SUCCESS";
export const GET_RENEWALS_BY_ACCOUNT_ID_FAILURE =
  "GET_RENEWALS_BY_ACCOUNT_ID_FAILURE";

export const UPDATE_RENEWALS_BY_ACCOUNT_ID_REQUEST =
  "UPDATE_RENEWALS_BY_ACCOUNT_ID_REQUEST";
export const UPDATE_RENEWALS_BY_ACCOUNT_ID_SUCCESS =
  "UPDATE_RENEWALS_BY_ACCOUNT_ID_SUCCESS";
export const UPDATE_RENEWALS_BY_ACCOUNT_ID_FAILURE =
  "UPDATE_RENEWALS_BY_ACCOUNT_ID_FAILURE";

export const GET_OPPORTUNITY_LIST_BY_ACCOUNT_ID_REQUEST =
  "GET_OPPORTUNITY_LIST_BY_ACCOUNT_ID_REQUEST";
export const GET_OPPORTUNITY_LIST_BY_ACCOUNT_ID_SUCCESS =
  "GET_OPPORTUNITY_LIST_BY_ACCOUNT_ID_SUCCESS";
export const GET_OPPORTUNITY_LIST_BY_ACCOUNT_ID_FAILURE =
  "GET_OPPORTUNITY_LIST_BY_ACCOUNT_ID_FAILURE";

export const UPDATE_OPPORTUNITY_LIST_BY_ACCOUNT_ID_REQUEST =
  "UPDATE_OPPORTUNITY_LIST_BY_ACCOUNT_ID_REQUEST";
export const UPDATE_OPPORTUNITY_LIST_BY_ACCOUNT_ID_SUCCESS =
  "UPDATE_OPPORTUNITY_LIST_BY_ACCOUNT_ID_SUCCESS";
export const UPDATE_OPPORTUNITY_LIST_BY_ACCOUNT_ID_FAILURE =
  "UPDATE_OPPORTUNITY_LIST_BY_ACCOUNT_ID_FAILURE";

export const LINK_OPPORTUNITIES_TO_ACCOUNT_ID_REQUEST =
  "LINK_OPPORTUNITIES_TO_ACCOUNT_ID_REQUEST";
export const LINK_OPPORTUNITIES_TO_ACCOUNT_ID_SUCCESS =
  "LINK_OPPORTUNITIES_TO_ACCOUNT_ID_SUCCESS";
export const LINK_OPPORTUNITIES_TO_ACCOUNT_ID_FAILURE =
  "LINK_OPPORTUNITIES_TO_ACCOUNT_ID_FAILURE";

export const GET_NOTES_LIST_BY_ACCOUNT_ID_REQUEST =
  "GET_NOTES_LIST_BY_ACCOUNT_ID_REQUEST";
export const GET_NOTES_LIST_BY_ACCOUNT_ID_SUCCESS =
  "GET_NOTES_LIST_BY_ACCOUNT_ID_SUCCESS";
export const GET_NOTES_LIST_BY_ACCOUNT_ID_FAILURE =
  "GET_NOTES_LIST_BY_ACCOUNT_ID_FAILURE";

export const UPDATE_NOTES_LIST_BY_ACCOUNT_ID_REQUEST =
  "UPDATE_NOTES_LIST_BY_ACCOUNT_ID_REQUEST";
export const UPDATE_NOTES_LIST_BY_ACCOUNT_ID_SUCCESS =
  "UPDATE_NOTES_LIST_BY_ACCOUNT_ID_SUCCESS";
export const UPDATE_NOTES_LIST_BY_ACCOUNT_ID_FAILURE =
  "UPDATE_NOTES_LIST_BY_ACCOUNT_ID_FAILURE";

export const GET_CALLS_LIST_BY_ACCOUNT_ID_REQUEST =
  "GET_CALLS_LIST_BY_ACCOUNT_ID_REQUEST";
export const GET_CALLS_LIST_BY_ACCOUNT_ID_SUCCESS =
  "GET_CALLS_LIST_BY_ACCOUNT_ID_SUCCESS";
export const GET_CALLS_LIST_BY_ACCOUNT_ID_FAILURE =
  "GET_CALLS_LIST_BY_ACCOUNT_ID_FAILURE";

export const UPDATE_CALLS_LIST_BY_ACCOUNT_ID_REQUEST =
  "UPDATE_CALLS_LIST_BY_ACCOUNT_ID_REQUEST";
export const UPDATE_CALLS_LIST_BY_ACCOUNT_ID_SUCCESS =
  "UPDATE_CALLS_LIST_BY_ACCOUNT_ID_SUCCESS";
export const UPDATE_CALLS_LIST_BY_ACCOUNT_ID_FAILURE =
  "UPDATE_CALLS_LIST_BY_ACCOUNT_ID_FAILURE";

export const GET_EVENTS_LIST_BY_ACCOUNT_ID_REQUEST =
  "GET_EVENTS_LIST_BY_ACCOUNT_ID_REQUEST";
export const GET_EVENTS_LIST_BY_ACCOUNT_ID_SUCCESS =
  "GET_EVENTS_LIST_BY_ACCOUNT_ID_SUCCESS";
export const GET_EVENTS_LIST_BY_ACCOUNT_ID_FAILURE =
  "GET_EVENTS_LIST_BY_ACCOUNT_ID_FAILURE";

export const UPDATE_EVENTS_LIST_BY_ACCOUNT_ID_REQUEST =
  "UPDATE_EVENTS_LIST_BY_ACCOUNT_ID_REQUEST";
export const UPDATE_EVENTS_LIST_BY_ACCOUNT_ID_SUCCESS =
  "UPDATE_EVENTS_LIST_BY_ACCOUNT_ID_SUCCESS";
export const UPDATE_EVENTS_LIST_BY_ACCOUNT_ID_FAILURE =
  "UPDATE_EVENTS_LIST_BY_ACCOUNT_ID_FAILURE";

export const GET_TASKS_LIST_BY_ACCOUNT_ID_REQUEST =
  "GET_TASKS_LIST_BY_ACCOUNT_ID_REQUEST";
export const GET_TASKS_LIST_BY_ACCOUNT_ID_SUCCESS =
  "GET_TASKS_LIST_BY_ACCOUNT_ID_SUCCESS";
export const GET_TASKS_LIST_BY_ACCOUNT_ID_FAILURE =
  "GET_TASKS_LIST_BY_ACCOUNT_ID_FAILURE";

export const UPDATE_TASKS_LIST_BY_ACCOUNT_ID_REQUEST =
  "UPDATE_TASKS_LIST_BY_ACCOUNT_ID_REQUEST";
export const UPDATE_TASKS_LIST_BY_ACCOUNT_ID_SUCCESS =
  "UPDATE_TASKS_LIST_BY_ACCOUNT_ID_SUCCESS";
export const UPDATE_TASKS_LIST_BY_ACCOUNT_ID_FAILURE =
  "UPDATE_TASKS_LIST_BY_ACCOUNT_ID_FAILURE";

export const ADD_ACCOUNT_REQUEST = "ADD_ACCOUNT_REQUEST";
export const ADD_ACCOUNT_SUCCESS = "ADD_ACCOUNT_SUCCESS";
export const ADD_ACCOUNT_FAILURE = "ADD_ACCOUNT_FAILURE";

export const GET_TOPICS_BY_ACCOUNT_ID_REQUEST =
  "GET_TOPICS_BY_ACCOUNT_ID_REQUEST";
export const GET_TOPICS_BY_ACCOUNT_ID_SUCCESS =
  "GET_TOPICS_BY_ACCOUNT_ID_SUCCESS";
export const GET_TOPICS_BY_ACCOUNT_ID_FAILURE =
  "GET_TOPICS_BY_ACCOUNT_ID_FAILURE";

export const ADD_TOPIC_BY_ACCOUNT_ID_REQUEST =
  "ADD_TOPIC_BY_ACCOUNT_ID_REQUEST";
export const ADD_TOPIC_BY_ACCOUNT_ID_SUCCESS =
  "ADD_TOPIC_BY_ACCOUNT_ID_SUCCESS";
export const ADD_TOPIC_BY_ACCOUNT_ID_FAILURE =
  "ADD_TOPIC_BY_ACCOUNT_ID_FAILURE";

export const DELETE_TOPIC_BY_ACCOUNT_ID_REQUEST =
  "DELETE_TOPIC_BY_ACCOUNT_ID_REQUEST";
export const DELETE_TOPIC_BY_ACCOUNT_ID_SUCCESS =
  "DELETE_TOPIC_BY_ACCOUNT_ID_SUCCESS";
export const DELETE_TOPIC_BY_ACCOUNT_ID_FAILURE =
  "DELETE_TOPIC_BY_ACCOUNT_ID_FAILURE";

export const FUNNEL_VALUE_BY_ACCOUNT_ID_REQUEST =
  "FUNNEL_VALUE_BY_ACCOUNT_ID_REQUEST";
export const FUNNEL_VALUE_BY_ACCOUNT_ID_SUCCESS =
  "FUNNEL_VALUE_BY_ACCOUNT_ID_SUCCESS";
export const FUNNEL_VALUE_BY_ACCOUNT_ID_FAILURE =
  "FUNNEL_VALUE_BY_ACCOUNT_ID_FAILURE";

export const ENRICH_ACCOUNT_REQUEST = "ENRICH_ACCOUNT_REQUEST";
export const ENRICH_ACCOUNT_SUCCESS = " ENRICH_ACCOUNT_SUCCESS";
export const ENRICH_ACCOUNT_FAILURE = "ENRICH_ACCOUNT_FAILURE";

export const UPDATE_ACCOUNT_ADDRESS = "UPDATE_ACCOUNT_ADDRESS";
export const ADD_ACCOUNT_ADDRESS = "ADD_ACCOUNT_ADDRESS";

export const HANDLE_ACCOUNT_MODAL = "HANDLE_ACCOUNT_MODAL";
export const HANDLE_LINK_ACCOUNT_MODAL = "HANDLE_LINK_ACCOUNT_MODAL";
export const HANDLE_ACCOUNT_DRAWER = "HANDLE_ACCOUNT_DRAWER";
export const SET_CLEARBIT_DATA = "SET_CLEARBIT_DATA";
export const SET_ACCOUNT_VIEW_TYPE = "SET_ACCOUNT_VIEW_TYPE";
export const SET_ACCOUNT_FILTER_TEXT = "SET_ACCOUNT_FILTER_TEXT";
export const SET_ACCOUNT_FILTER_USER = "SET_ACCOUNT_FILTER_USER";
export const SET_ACCOUNT_DASHBOARD_TYPE = "SET_ACCOUNT_DASHBOARD_TYPE";

export const SET_CLEARBIT_PRODUCT_DATA = "SET_CLEARBIT_PRODUCT_DATA";
export const SET_CLEARBIT_SERVICE_DATA = "SET_CLEARBIT_SERVICE_DATA";

export const LINK_CONTACTS_TO_CHECK_ACCOUNT_ID_REQUEST =
  "LINK_CONTACTS_TO_CHECK_ACCOUNT_ID_REQUEST";
export const LINK_CONTACTS_TO_CHECK_ACCOUNT_ID_SUCCESS =
  "LINK_CONTACTS_TO_CHECK_ACCOUNT_ID_SUCCESS";
export const LINK_CONTACTS_TO_CHECK_ACCOUNT_ID_FAILURE =
  "LINK_CONTACTS_TO_CHECK_ACCOUNT_ID_FAILURE";

export const HANDLE_ABSOLUTE_MODAL = "HANDLE_ABSOLUTE_MODAL";
export const HANDLE_WIN_MODAL = "HANDLE_WIN_MODAL";
export const HANDLE_OPP_MODAL = "HANDLE_OPP_MODAL";
export const HANDLE_LIFETIME_MODAL = "HANDLE_LIFETIME_MODAL";
export const HANDLE_ACCOUNT_TIMELINE_MODAL = "HANDLE_ACCOUNT_TIMELINE_MODAL";

//filter ACCOUNT by Initiative

export const GET_INITIATIVE_WISE_ACCOUNT_REQUEST =
  "GET_INITIATIVE_WISE_ACCOUNT_REQUEST";
export const GET_INITIATIVE_WISE_ACCOUNT_SUCCESS =
  "GET_INITIATIVE_WISE_ACCOUNT_SUCCESS";
export const GET_INITIATIVE_WISE_ACCOUNT_FAILURE =
  "GET_INITIATIVE_WISE_ACCOUNT_FAILURE";

export const GET_ACCOUNT_TOPIC_LIST_REQUEST = "GET_ACCOUNT_TOPIC_LIST_REQUEST";
export const GET_ACCOUNT_TOPIC_LIST_SUCCESS = "GET_ACCOUNT_TOPIC_LIST_SUCCESS";
export const GET_ACCOUNT_TOPIC_LIST_FAILURE = "GET_ACCOUNT_TOPIC_LIST_FAILURE";

export const HANDLE_BAR_MODAL = "HANDLE_BAR_MODAL";

export const GET_ALL_LATEST_ACCOUNTS_FOR_LAZY_LOADING_REQUEST =
  "GET_ALL_LATEST_ACCOUNTS_FOR_LAZY_LOADING_REQUEST";
export const GET_ALL_LATEST_ACCOUNTS_FOR_LAZY_LOADING_SUCCESS =
  "GET_ALL_LATEST_ACCOUNTS_FOR_LAZY_LOADING_SUCCESS";
export const GET_ALL_LATEST_ACCOUNTS_FOR_LAZY_LOADING_FAILURE =
  "GET_ALL_LATEST_ACCOUNTS_FOR_LAZY_LOADING_FAILURE";

//get timeline data
export const GET_TIMELINE_DATA_BY_ACCOUNT_ID_REQUEST =
  "GET_TIMELINE_DATA_BY_ACCOUNT_ID_REQUEST";
export const GET_TIMELINE_DATA_BY_ACCOUNT_ID_SUCCESS =
  "GET_TIMELINE_DATA_BY_ACCOUNT_ID_SUCCESS";
export const GET_TIMELINE_DATA_BY_ACCOUNT_ID_FAILURE =
  "GET_TIMELINE_DATA_BY_ACCOUNT_ID_FAILURE";

export const GET_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST =
  "GET_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST";
export const GET_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS =
  "GET_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS";
export const GET_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE =
  "GET_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE";

export const GET_ACCOUNT_LIST_RANGE_BY_ORG_ID_FOR_REPORT_REQUEST =
  "GET_ACCOUNT_LIST_RANGE_BY_ORG_ID_FOR_REPORT_REQUEST";
export const GET_ACCOUNT_LIST_RANGE_BY_ORG_ID_FOR_REPORT_SUCCESS =
  "GET_ACCOUNT_LIST_RANGE_BY_ORG_ID_FOR_REPORT_SUCCESS";
export const GET_ACCOUNT_LIST_RANGE_BY_ORG_ID_FOR_REPORT_FAILURE =
  "GET_ACCOUNT_LIST_RANGE_BY_ORG_ID_FOR_REPORT_FAILURE";

export const SUSPEND_ACCOUNT_REQUSET = "SUSPEND_ACCOUNT_REQUEST";
export const SUSPEND_ACCOUNT_SUCCESS = "SUSPEND_ACCOUNT_SUCCESS";
export const SUSPEND_ACCOUNT_FAILURE = "SUSPEND_ACCOUNT_FAILURE";

// Order create modal

export const HANDLE_LINK_ORDER_CONFIGURE_MODAL = "HANDLE_LINK_ORDER_CONFIGURE_MODAL";

export const HANDLE_DOCUMENT_UPLOAD_MODAL = "HANDLE_DOCUMENT_UPLOAD_MODAL";

export const HANDLE_ACCOUNT_SUBSCRIPTION_CONFIGURE_MODAL = "HANDLE_ACCOUNT_SUBSCRIPTION_CONFIGURE_MODAL";

// product

export const FETCHING_PROFESSIONALDUCTS_BY_ACCOUNT_ID_REQUEST =
  "FETCHING_PROFESSIONALDUCTS_BY_ACCOUNT_ID_REQUEST";
export const FETCHING_PROFESSIONALDUCTS_BY_ACCOUNT_ID_SUCCESS =
  "FETCHING_PROFESSIONALDUCTS_BY_ACCOUNT_ID_SUCCESS";
export const FETCHING_PROFESSIONALDUCTS_BY_ACCOUNT_ID_FAILURE =
  "FETCHING_PROFESSIONALDUCTS_BY_ACCOUNT_ID_FAILURE";

// service

export const FETCHING_PROFESSIONAL_SERVICE_BY_ACCOUNT_ID_REQUEST =
  "FETCHING_PROFESSIONAL_SERVICE_BY_ACCOUNT_ID_REQUEST";
export const FETCHING_PROFESSIONAL_SERVICE_BY_ACCOUNT_ID_SUCCESS =
  "FETCHING_PROFESSIONAL_SERVICE_BY_ACCOUNT_ID_SUCCESS";
export const FETCHING_PROFESSIONAL_SERVICE_BY_ACCOUNT_ID_FAILURE =
  "FETCHING_PROFESSIONAL_SERVICE_BY_ACCOUNT_ID_FAILURE";

export const LINK_PROFESSIONALDUCT_TO_ACCOUNT_REQUEST =
  "LINK_PROFESSIONALDUCT_TO_ACCOUNT_REQUEST";
export const LINK_PROFESSIONALDUCT_TO_ACCOUNT_SUCCESS =
  "LINK_PROFESSIONALDUCT_TO_ACCOUNT_SUCCESS";
export const LINK_PROFESSIONALDUCT_TO_ACCOUNT_FAILURE =
  "LINK_PROFESSIONALDUCT_TO_ACCOUNT_FAILURE";

export const LINK_SERVICE_TO_ACCOUNT_REQUEST =
  "LINK_SERVICE_TO_ACCOUNT_REQUEST";
export const LINK_SERVICE_TO_ACCOUNT_SUCCESS =
  "LINK_SERVICE_TO_ACCOUNT_SUCCESS";
export const LINK_SERVICE_TO_ACCOUNT_FAILURE =
  "LINK_SERVICE_TO_ACCOUNT_FAILURE";

export const GET_CUSTOME_MODULE_ACCOUNTS_REQUEST =
  "GET_CUSTOME_MODULE_ACCOUNTS_REQUEST";
export const GET_CUSTOME_MODULE_ACCOUNTS_SUCCESS =
  "GET_CUSTOME_MODULE_ACCOUNTS_SUCCESS";
export const GET_CUSTOME_MODULE_ACCOUNTS_FAILURE =
  "GET_CUSTOME_MODULE_ACCOUNTS_FAILURE";

export const HANDLE_UPDATE_EVENT_MODAL = "HANDLE_UPDATE_EVENT_MODAL";
export const HANDLE_UPDATE_CALL_MODAL = "HANDLE_UPDATE_CALL_MODAL";
export const HANDLE_UPDATE_TASK_MODAL = "HANDLE_UPDATE_TASK_MODAL";

export const FETCHING_NEW_ORDER_LIST_REQUEST = "FETCHING_NEW_ORDER_LIST_REQUEST";
export const FETCHING_NEW_ORDER_LIST_SUCCESS = "FETCHING_NEW_ORDER_LIST_SUCCESS";
export const FETCHING_NEW_ORDER_LIST_FAILURE = "FETCHING_NEW_ORDER_LIST_FAILURE";

export const GENERATE_ORDER_BY_CUSTOMER_ID_REQUEST =
  "GENERATE_ORDER_BY_CUSTOMER_ID_REQUEST";
export const GENERATE_ORDER_BY_CUSTOMER_ID_SUCCESS =
  "GENERATE_ORDER_BY_CUSTOMER_ID_SUCCESS";
export const GENERATE_ORDER_BY_CUSTOMER_ID_FAILURE =
  "GENERATE_ORDER_BY_CUSTOMER_ID_FAILURE";

export const FETCHING_NEW_ORDER_SERVICE_LIST_REQUEST =
  "FETCHING_NEW_ORDER_SERVICE_LIST_REQUEST";
export const FETCHING_NEW_ORDER_SERVICE_LIST_SUCCESS =
  "FETCHING_NEW_ORDER_SERVICE_LIST_SUCCESS";
export const FETCHING_NEW_ORDER_SERVICE_LIST_FAILURE =
  "FETCHING_NEW_ORDER_SERVICE_LIST_FAILURE";

export const HANDLE_RENEWAL_BUTTON_MODAL = "HANDLE_RENEWAL_BUTTON_MODAL";
export const HANDLE_PAUSE_BUTTON_MODAL = "HANDLE_PAUSE_BUTTON_MODAL";

export const HANDLE_ACCOUNT_OPPORTUNITY_MODAL = "HANDLE_ACCOUNT_OPPORTUNITY_MODAL";

export const ADD_ACCOUNT_OPPORTUNITY_REQUEST = "ADD_ACCOUNT_OPPORTUNITY_REQUEST";
export const ADD_ACCOUNT_OPPORTUNITY_SUCCESS = "ADD_ACCOUNT_OPPORTUNITY_SUCCESS";
export const ADD_ACCOUNT_OPPORTUNITY_FAILURE = "ADD_ACCOUNT_OPPORTUNITY_FAILURE";

export const FETCHING_OPPORTUNITY_REQUEST = "FETCHING_OPPORTUNITY_REQUEST";
export const FETCHING_OPPORTUNITY_SUCCESS = "FETCHING_OPPORTUNITY_SUCCESS";
export const FETCHING_OPPORTUNITY_FAILURE = "FETCHING_OPPORTUNITY_FAILURE";

export const GET_PRODUCT_ORDER_DETAILS_BY_ID_REQUEST = "GET_PRODUCT_ORDER_DETAILS_BY_ID_REQUEST";
export const GET_PRODUCT_ORDER_DETAILS_BY_ID_SUCCESS = "GET_PRODUCT_ORDER_DETAILS_BY_ID_SUCCESS";
export const GET_PRODUCT_ORDER_DETAILS_BY_ID_FAILURE = "GET_PRODUCT_ORDER_DETAILS_BY_ID_FAILURE";

export const INPUT_SEARCH_ACCOUNT_DATA_REQUEST = "INPUT_SEARCH_ACCOUNT_DATA_REQUEST";
export const INPUT_SEARCH_ACCOUNT_DATA_SUCCESS = "INPUT_SEARCH_ACCOUNT_DATA_SUCCESS";
export const INPUT_SEARCH_ACCOUNT_DATA_FAILURE = "INPUT_SEARCH_ACCOUNT_DATA_FAILURE";


export const LINK_PAUSE_BY_ACCOUNT_ID_REQUEST =
  "LINK_PAUSE_BY_ACCOUNT_ID_REQUEST";
export const LINK_PAUSE_BY_ACCOUNT_ID_SUCCESS =
  "LINK_PAUSE_BY_ACCOUNT_ID_SUCCESS";
export const LINK_PAUSE_BY_ACCOUNT_ID_FAILURE =
  "LINK_PAUSE_BY_ACCOUNT_ID_FAILURE";


export const LINK_RENEWAL_BY_ACCOUNT_ID_REQUEST =
  "LINK_RENEWAL_BY_ACCOUNT_ID_REQUEST";
export const LINK_RENEWAL_BY_ACCOUNT_ID_SUCCESS =
  "LINK_RENEWAL_BY_ACCOUNT_ID_SUCCESS";
export const LINK_RENEWAL_BY_ACCOUNT_ID_FAILURE =
  "LINK_RENEWAL_BY_ACCOUNT_ID_FAILURE";

export const GET_FEEDBACK_BY_ORDER_ID_REQUEST =
  "GET_FEEDBACK_BY_ORDER_ID_REQUEST";
export const GET_FEEDBACK_BY_ORDER_ID_SUCCESS =
  "GET_FEEDBACK_BY_ORDER_ID_SUCCESS";
export const GET_FEEDBACK_BY_ORDER_ID_FAILURE =
  "GET_FEEDBACK_BY_ORDER_ID_FAILURE";

export const GET_FEEDBACK_BY_ACCOUNT_ID_REQUEST =
  "GET_FEEDBACK_BY_ACCOUNT_ID_REQUEST";
export const GET_FEEDBACK_BY_ACCOUNT_ID_SUCCESS =
  "GET_FEEDBACK_BY_ACCOUNT_ID_SUCCESS";
export const GET_FEEDBACK_BY_ACCOUNT_ID_FAILURE =
  "GET_FEEDBACK_BY_ACCOUNT_ID_FAILURE";

export const GET_FEEDBACK_BY_ACCOUNT_VIEW_ID_REQUEST =
  "GET_FEEDBACK_BY_ACCOUNT_VIEW_ID_REQUEST";
export const GET_FEEDBACK_BY_ACCOUNT_VIEW_ID_SUCCESS =
  "GET_FEEDBACK_BY_ACCOUNT_VIEW_ID_SUCCESS";
export const GET_FEEDBACK_BY_ACCOUNT_VIEW_ID_FAILURE =
  "GET_FEEDBACK_BY_ACCOUNT_VIEW_ID_FAILURE";

export const HANDLE_FEEDBACK_MODAL = "HANDLE_FEEDBACK_MODAL";


export const GET_ACCOUNT_HISTORY_REQUEST = "GET_ACCOUNT_HISTORY_REQUEST";
export const GET_ACCOUNT_HISTORY_SUCCESS = "GET_ACCOUNT_HISTORY_SUCCESS";
export const GET_ACCOUNT_HISTORY_FAILURE = "GET_ACCOUNT_HISTORY_FAILURE";


export const ADD_SHARE_ACCOUNT_PERMISSION_REQUEST = "ADD_SHARE_ACCOUNT_PERMISSION_REQUEST";
export const ADD_SHARE_ACCOUNT_PERMISSION_SUCCESS = "ADD_SHARE_ACCOUNT_PERMISSION_SUCCESS";
export const ADD_SHARE_ACCOUNT_PERMISSION_FAILURE = "ADD_SHARE_ACCOUNT_PERMISSION_FAILURE";

export const GET_PERMISSIONS_LIST_ACCOUNT_REQUEST = "GET_PERMISSIONS_LIST_ACCOUNT_REQUEST";
export const GET_PERMISSIONS_LIST_ACCOUNT_SUCCESS = "GET_PERMISSIONS_LIST_ACCOUNT_SUCCESS";
export const GET_PERMISSIONS_LIST_ACCOUNT_FAILURE = "GET_PERMISSIONS_LIST_ACCOUNT_FAILURE";