import * as types from "./ReportActionType";
import moment from "moment";

const initialState = {
  reportViewType: "ME",
  dateRangeList: [
    {
      id: 1,
      type: "year",
      value: "FY",
      starter: false,
      isSelected: false,
      startDate: moment()
        .startOf("year")
        .toISOString(),
      endDate: moment()
        .endOf("year")
        .toISOString(),
    },
    {
      id: 2,
      type: "quarter",
      value: "QTD",
      starter: false,
      isSelected: false,
      startDate: moment()
        .startOf("quarter")
        .toISOString(),
      endDate: moment()
        .endOf("quarter")
        .toISOString(),
    },
    {
      id: 3,
      type: "month",
      value: "MTD",
      starter: false,
      isSelected: false,
      startDate: moment()
        .startOf("month")
        .toISOString(),
      endDate: moment()
        .endOf("month")
        .toISOString(),
    },
    {
      id: 4,
      type: "week",
      value: "1W",
      starter: false,
      isSelected: false,
      startDate: moment()
        .startOf("week")
        .toISOString(),
      endDate: moment()
        .endOf("week")
        .toISOString(),
    },

    {
      id: 5,
      type: "Yesterday",
      value: "YESTERDAY",
      starter: false,
      isSelected: false,
      startDate: moment()
        .subtract(1, "days")

        .toISOString(),
      endDate: moment()
        .subtract(1, "days")

        .toISOString(),
    },
    {
      id: 6,
      type: "Today",
      value: "TODAY",
      starter: true,
      isSelected: true,
      startDate: moment()
        // .subtract(1, "days")
        .toISOString(),
      endDate: moment().toISOString(),
    },
  ],
  isCustomSelected: false,
  startDate: moment().toISOString(),
  endDate: moment().toISOString(),
  selectedReportType: "Choose report type",
  reportTypes: [
    "Contacts",
    "Customer",
    "Opportunities",
    "Events",
    "Leads-Customer",
    "Leads-Contact",
    "Tasks",
    "Calls",
    "Event",
    "Opportunity",
    "Meetings",
    "Webinar",
    "Conference",
    "Quotation",
    "Invoice",
  ],
  reportContactSubTypes: ["Opportunity-Won", "Contact-All"],
  reportCustomerSubTypes: ["Won", "Customer-All"],
  reportCallsSubTypes: ["Complete", "All-Calls"],
  reportEventsSubTypes: ["Events-All", "Events-Complete"],
  reportOpportunitySubTypes: ["Opportunity-All", "Opportunity-High"],
  reportLeadsAccountSubTypes: [
    "Leads-All",
    "Leads-Meetings",
    "Leads-Call",
    "Leads-Qualifier",
  ],
  reportLeadsContactSubTypes: [
    "Leads-Contact-All",
    "Leads-Contact-Meetings",
    "Leads-Contact-Call",
    "Leads-Contact-Qualifier",
  ],
  reportTaskSubTypes: ["Task-All"],
  reportQuotationSubTypes: ["Quotation-All"],

  reportInvoiceSubTypes: ["Invoice-All"],

  selectedSubReportType: "Please select",

  //For All/Organisation View
  reportType: ["Contacts", "Customer", "Opportunities"],
  contactOrganisationReportSubtype: ["Opportunity-Won", "Contact-All"],
  customerOrganisationReportSubtype: ["Customer-All", "Won"],
  opportunityOrganisationSubType: ["Opportunity-All", "Opportunity-High"],
  // invoiceOrganisationSubType: ["7"],
  // quotationOrganisationSubType: ["8"],
};

export const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_REPORT_VIEW_TYPE:
      return {
        ...state,
        reportViewType: action.payload,
        selectedSubReportType: "Please Select",
        selectedReportType: "Choose report type",
      };
    case types.CHANGE_SELECTED_TIME_INTERVAL_REPORT:
      return {
        ...state,
        dateRangeList: newDateRange(state.dateRangeList, action.payload),
        isCustomSelected: false,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    case types.SET_TIME_INTERVAL_REPORT:
      return {
        ...state,
        isCustomSelected: true,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    case types.SET_FISCAL_TIME_INTERVAL_REPORT:
      return {
        ...state,
        dateRangeList: mergeFiscalAndQuarter(
          state.dateRangeList,
          action.payload
        ),
        // startDate: "2021-06-14T00:00:00Z",
        // endDate: "2021-06-14T00:00:00Z",
        startDate: action.payload.metaData.fiscalMapper.todayStartDate,
        endDate: action.payload.metaData.fiscalMapper.todayEndDate,
      };
    case types.SET_SELECTED_REPORT_TYPE:
      return {
        ...state,
        selectedReportType: action.payload,
        selectedSubReportType: "Please select",
      };
    case types.SET_SUB_SELECTED_REPORT_TYPE:
      return {
        ...state,
        selectedSubReportType: action.payload,
      };
    default:
      return state;
  }
};

const newDateRange = (dateRange, newDate) =>
  dateRange.map((range) => {
    if (range.id === newDate.id) {
      return { ...range, isSelected: true };
    } else {
      return { ...range, isSelected: false };
    }
  });
const mergeFiscalAndQuarter = (dateRange, newDate) =>
  dateRange.map((date) => {
    // let q1s = newDate.metaData.fiscalMapper.q1StartDate;
    // let q1e = newDate.metaData.fiscalMapper.q1EndDate;
    // let q2s = newDate.metaData.fiscalMapper.q2StartDate;
    // let q2e = newDate.metaData.fiscalMapper.q2EndDate;
    // let q3s = newDate.metaData.fiscalMapper.q3StartDate;
    // let q3e = newDate.metaData.fiscalMapper.q3EndDate;
    // let q4s = newDate.metaData.fiscalMapper.q4StartDate;
    // let q4e = newDate.metaData.fiscalMapper.q4EndDate;

    if (date.value === "QTD") {
      return {
        ...date,
        startDate:
          newDate.metaData.fiscalMapper.metaData.currentQuarterStartDate +
          "T00:00:00Z",
        endDate:
          newDate.metaData.fiscalMapper.metaData.currentQuarterEndDate +
          "T00:00:00Z",
      };
      // if (moment().isBetween(moment(q1s), moment(q1e))) {
      //   return { ...date, startDate: q1s, endDate: q1e };
      // }
      // if (moment().isBetween(moment(q2s), moment(q2e))) {
      //   return { ...date, startDate: q2s, endDate: q2e };
      // }
      // if (moment().isBetween(moment(q3s), moment(q3e))) {
      //   return { ...date, startDate: q3s, endDate: q3e };
      // }
      // if (moment().isBetween(moment(q4s), moment(q4e))) {
      //   return { ...date, startDate: q4s, endDate: q4e };
      // }
    } else if (date.value === "FY") {
      return {
        ...date,
        // startDate: "2021-06-14T00:00:00Z",
        // endDate: "2021-06-14T00:00:00Z",
        startDate: newDate.metaData.fiscalMapper.fiscalStartDate,
        endDate: newDate.metaData.fiscalMapper.fiscalEndDate,
      };
    } else {
      return date;
    }
  });
