import * as types from "./EmailActionTypes";
import moment from "moment";

const initialState = {
  addingEmailCredential: false,
  addingEmailCredentialError: false,
  addingEmail: false,
  updatingEmailCredential: false,
  updatingEmailCredentialError: false,
  fetchingEmailCredential: false,
  fetchingEmailCredentialError: false,
  emailCredential: {},

  //admin
  addingEmailCredentialForAdmin: false,
  addingEmailCredentialForAdminError: false,
  addingEmailForAdmin: false,
  updatingEmailCredentialForAdmin: false,
  updatingEmailCredentialForAdminError: false,
  fetchingEmailCredentialForAdmin: false,
  fetchingEmailCredentialForAdminError: false,
  emailCredentialForAdmin: {},

  sendingEmail: false,
  sendingEmailError: false,
};
export const emailReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_EMAIL_CREDENTIAL_REQUEST:
      return { ...state, fetchingEmailCredential: true };
    case types.GET_EMAIL_CREDENTIAL_SUCCESS:
      return {
        ...state,
        fetchingEmailCredential: false,
        emailCredential: action.payload,
      };
    case types.GET_EMAIL_CREDENTIAL_FAILURE:
      return {
        ...state,
        fetchingEmailCredential: false,
        fetchingEmailCredentialError: true,
      };

    case types.ADD_EMAIL_CREDENTIAL_REQUEST:
      return { ...state, addingEmailCredential: true };
    case types.ADD_EMAIL_CREDENTIAL_SUCCESS:
      return {
        ...state,
        addingEmailCredential: false,
        addingEmail: true,
        addIntegrationModal: false,
        addEmailModal: false,
      };
    case types.ADD_EMAIL_CREDENTIAL_FAILURE:
      return {
        ...state,
        addingEmailCredential: false,
        addingEmailCredentialError: true,
        addIntegrationModal: false,
        addEmailModal: false,
      };

    case types.UPDATE_EMAIL_CREDENTIAL_REQUEST:
      return { ...state, updatingEmailCredential: true };
    case types.UPDATE_EMAIL_CREDENTIAL_SUCCESS:
      return {
        ...state,
        updatingEmailCredential: false,
        emailCredential: action.payload,
      };
    case types.UPDATE_EMAIL_CREDENTIAL_FAILURE:
      return {
        ...state,
        updatingEmailCredential: false,
        updatingEmailCredentialError: true,
      };

    case types.SEND_EMAIL_REQUEST:
      return { ...state, sendingEmail: true, sendingEmailError: false };
    case types.SEND_EMAIL_SUCCESS:
      return { ...state, sendingEmail: false, sendingEmailError: false };
    case types.SEND_EMAIL_FAILURE:
      return { ...state, sendingEmail: false, sendingEmailError: true };

    //fOR aDMIN
    case types.GET_EMAIL_CREDENTIAL_FOR_ADMIN_REQUEST:
      return { ...state, fetchingEmailCredentialForAdmin: true };
    case types.GET_EMAIL_CREDENTIAL_FOR_ADMIN_SUCCESS:
      return {
        ...state,
        fetchingEmailCredentialForAdmin: false,
        emailCredentialForAdmin: action.payload,
      };
    case types.GET_EMAIL_CREDENTIAL_FOR_ADMIN_FAILURE:
      return {
        ...state,
        fetchingEmailCredentialForAdmin: false,
        fetchingEmailCredentialForAdminError: true,
      };

    case types.ADD_EMAIL_CREDENTIAL_FOR_ADMIN_REQUEST:
      return { ...state, addingEmailCredentialForAdmin: true };
    case types.ADD_EMAIL_CREDENTIAL_FOR_ADMIN_SUCCESS:
      return {
        ...state,
        addingEmailCredentialForAdmin: false,
        addingEmailForAdmin: true,

        addOrgEmailModal: false,
      };
    case types.ADD_EMAIL_CREDENTIAL_FOR_ADMIN_FAILURE:
      return {
        ...state,
        addingEmailCredentialForAdmin: false,
        addingEmailCredentialForAdminError: true,

        addOrgEmailModal: false,
      };

    case types.UPDATE_EMAIL_CREDENTIAL_FOR_ADMIN_REQUEST:
      return { ...state, updatingEmailCredentialForAdmin: true };
    case types.UPDATE_EMAIL_CREDENTIAL_FOR_ADMIN_SUCCESS:
      return {
        ...state,
        updatingEmailCredentialForAdmin: false,
        emailCredentialForAdmin: action.payload,
      };
    case types.UPDATE_EMAIL_CREDENTIAL_FOR_ADMIN_FAILURE:
      return {
        ...state,
        updatingEmailCredentialForAdmin: false,
        updatingEmailCredentialForAdminError: true,
      };

    default:
      return { ...state };
  }
};
