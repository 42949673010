import * as types from "./SettingsActionTypes";

const initialState = {
  addProcessModal: false,
  addEditProcessModal: false,
  addRulesModal: false,
  addProcessTaskModal: false,

  addingProcess: false,
  addingProcessError: false,

  addingProcessTask: false,
  addingProcessTaskError: false,

  fetchingProcess: false,
  fetchingProcessError: false,
  Process: [],

  updatingTask: false,
  updatingTaskError: false,
  deleteTask: false,
  deleteTaskError: false,

  updatingTaskResuffel: false,
  updatingTaskResuffelError: false,


  addTaskModal: false,
  setEditingTask: {},

  updatingProcessTask: false,
  updatingProcessTaskError: false,

  fetchingDefaultProcess: false,
  fetchingDefaultProcessError: false,
  defaultProcess: [],

  removingStages: false,
  removingStagesError: false,
  updatingStages: false,
  updatingStagesError: false,
  fetchingProcessStages: false,
  fetchingProcessStagesError: false,
  ProcessStages: [],

  updateProcessName: false,
  updateProcessNameError: false,
  fetchingProcessTask: false,
  fetchingProcessTaskError: false,
  processTask: [],

  addingProcessStages: false,
  addingProcessStagesError: false,

  fetchingAllProcessStages: false,
  fetchingAllProcessStagesError: false,
  allProcessStages: [],

  fetchingOppoStages: false,
  fetchingOppoStagesError: false,
  oppoStages: [],

  fetchingDepartments: false,
  fetchingDepartmentsError: false,
  departments: [],

  fetchingLevels: false,
  fetchingLevelsError: false,
  levels: [],

  //recruiter
  fetchingProcessForRecruit: false,
  fetchingProcessForRecruitError: false,
  recruitProcess: [],

  addingProcessForRecruit: false,
  addingProcessForRecruitError: false,

  fetchingProcessStagesForRecruit: false,
  fetchingProcessStagesForRecruitError: false,
  recruitProcessStages: [],

  updateProcessNameForRecruit: false,
  updateProcessNameForRecruitError: false,

  fetchingAllProcessStagesForRecruit: false,
  fetchingAllProcessStagesForRecruitError: false,
  allProcessStagesForRecruit: [],

  addingOrganizationLeads: false,
  addingOrganizationLeadsError: false,

  fetchingOrganizationLeads: false,
  fetchingOrganizationLeadsError: false,
  leadsData: [],

  fetchingSignatureInd: false,
  fetchingSignatureIndError: false,
  signatureInd: {},

  addOrgEmailModal: false,

  addOrgFacebookModal: false,

  addOrgQuickbookModal: false,

  enabalingRecruitProAdvance: false,
  enabalingRecruitProAdvanceError: false,

  addingSequenceLeads: false,
  addingSequenceLeadsError: false,

  fetchingSequenceLeads: false,
  fetchingSequenceLeadsError: false,
  sequenceLeads: [],
  //Apparel
  apparelModal: false,

  addingApparel: false,
  addingApparelError: false,

  fetchingApparel: false,
  fetchingApparelError: false,
  apparel: [],

  updateApparel: false,
  updateApparelError: false,

  addingApparelById: false,
  addingApparelByIdError: false,

  fetchingApparelById: false,
  fetchingApparelByIdError: false,
  apparelById: [],

  updateApparelById: false,
  updateApparelByIdError: false,

  fetchingAllSubcategory: false,
  fetchingAllSubcategoryError: false,
  allsubCategory: [],
};
export const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ENABLE_RECRUITMENT_ADVANCE_REQUEST:
      return { ...state, enabalingRecruitProAdvance: true };
    case types.ENABLE_RECRUITMENT_ADVANCE_SUCCESS:
      return {
        ...state,
        enabalingRecruitProAdvance: false,
      };
    case types.ENABLE_RECRUITMENT_ADVANCE_FAILURE:
      return {
        ...state,

        enabalingRecruitProAdvanceError: true,
      };
    case types.HANDLE_ORG_EMAIL_MODAL:
      return { ...state, addOrgEmailModal: action.payload };

    case types.HANDLE_ORG_FACEBOOK_MODAL:
      return { ...state, addOrgFacebookModal: action.payload };
    case types.HANDLE_ORG_QUICKBOOK_MODAL:
      return { ...state, addOrgQuickbookModal: action.payload };

    case types.HANDLE_TASK_EDIT_PROCESS_MODAL:
      return { ...state, addEditProcessModal: action.payload };

    case types.HANDLE_TASK_MODAL:
      return { ...state, addTaskModal: action.payload };

    case types.HANDLE_PROCESS_MODAL:
      return { ...state, addProcessModal: action.payload };

    case types.HANDLE_RULES_MODAL:
      return { ...state, addRulesModal: action.payload };

    case types.HANDLE_PROCESS_TASK_MODAL:
      return { ...state, addProcessTaskModal: action.payload };

    case types.ADD_PROCESS_REQUEST:
      return { ...state, addingProcess: true, addingProcessError: false };
    case types.ADD_PROCESS_SUCCESS:
      return {
        ...state,
        addingProcess: false,
        addingProcessError: false,
        addProcessModal: false,
      };
    case types.ADD_PROCESS_FAILURE:
      return {
        ...state,
        addingProcess: false,
        addingProcessError: true,
        addProcessModal: false,
      };

    case types.GET_PROCESS_REQUEST:
      return { ...state, fetchingProcess: true, fetchingProcessError: false };
    case types.GET_PROCESS_SUCCESS:
      return {
        ...state,
        fetchingProcess: false,
        fetchingProcessError: false,
        Process: action.payload,
      };
    case types.GET_PROCESS_FAILURE:
      return { ...state, fetchingProcess: false, fetchingProcessError: true };

    case types.GET_ALL_PROCESS_STAGES_REQUEST:
      return {
        ...state,
        fetchingAllProcessStages: true,
        fetchingAllProcessStagesError: false,
      };
    case types.GET_ALL_PROCESS_STAGES_SUCCESS:
      return {
        ...state,
        fetchingAllProcessStages: false,
        fetchingAllProcessStagesError: false,
        allProcessStages: action.payload,
      };
    case types.GET_ALL_PROCESS_STAGES_FAILURE:
      return {
        ...state,
        fetchingAllProcessStages: false,
        fetchingAllProcessStagesError: true,
      };

    case types.GET_OPPO_STAGES_REQUEST:
      return {
        ...state,
        fetchingOppoStages: true,
        fetchingOppoStagesError: false,
      };
    case types.GET_OPPO_STAGES_SUCCESS:
      return {
        ...state,
        fetchingOppoStages: false,
        fetchingOppoStagesError: false,
        oppoStages: action.payload,
      };
    case types.GET_OPPO_STAGES_FAILURE:
      return {
        ...state,
        fetchingOppoStages: false,
        fetchingOppoStagesError: true,
      };

    case types.GET_DEFAULT_PROCESS_REQUEST:
      return {
        ...state,
        fetchingDefaultProcess: true,
        fetchingDefaultProcessError: false,
      };
    case types.GET_DEFAULT_PROCESS_SUCCESS:
      return {
        ...state,
        fetchingDefaultProcess: false,
        fetchingDefaultProcessError: false,
        defaultProcess: action.payload,
      };
    case types.GET_DEFAULT_PROCESS_FAILURE:
      return {
        ...state,
        fetchingDefaultProcess: false,
        fetchingDefaultProcessError: true,
      };

    case types.GET_PROCESS_STAGES_REQUEST:
      return {
        ...state,
        fetchingProcessStages: true,
        fetchingProcessStagesError: false,
      };
    case types.GET_PROCESS_STAGES_SUCCESS:
      return {
        ...state,
        fetchingProcessStages: false,
        fetchingProcessStagesError: false,
        ProcessStages: action.payload,
      };
    case types.GET_PROCESS_STAGES_FAILURE:
      return {
        ...state,
        fetchingProcessStages: false,
        fetchingProcessStagesError: true,
      };
    case types.REMOVE_STAGE_REQUEST:
      return { ...state, removingStages: true };
    case types.REMOVE_STAGE_SUCCESS:
      return {
        ...state,
        removingStages: false,
        ProcessStages: state.ProcessStages.filter(
          (stage) => stage.stageId !== action.payload
        ),
      };
    case types.REMOVE_STAGE_FAILURE:
      return { ...state, removingStages: false, removingStagesError: true };

    case types.UPDATE_STAGE_REQUEST:
      return { ...state, updatingStages: true };
    case types.UPDATE_STAGE_SUCCESS:
      // return { ...state, updatingStages: false, states: [...state.states, action.payload] };
      return {
        ...state,
        updatingStages: false,
        ProcessStages: state.ProcessStages.map((state) =>
          state.stageId === action.payload.stageId ? action.payload : state
        ),
      };
    case types.UPDATE_STAGE_FAILURE:
      return { ...state, updatingStages: false, updatingStagesError: true };

    case types.UPDATE_PROCESS_NAME_REQUEST:
      return { ...state, updateProcessName: true };
    case types.UPDATE_PROCESS_NAME_SUCCESS:
      // return { ...state, updatingStages: false, states: [...state.states, action.payload] };
      return {
        ...state,
        updateProcessName: false,
        Process: state.Process.map((state) =>
          state.processId === action.payload.processId ? action.payload : state
        ),
      };
    case types.UPDATE_PROCESS_NAME_FAILURE:
      return {
        ...state,
        updateProcessName: false,
        updateProcessNameError: true,
      };

    case types.ADD_PROCESS_STAGE_REQUEST:
      return { ...state, addingProcessStages: true };
    case types.ADD_PROCESS_STAGE_SUCCESS:
      return {
        ...state,
        addingProcessStages: false,
        ProcessStages: [...state.ProcessStages, action.payload],
      };
    case types.ADD_PROCESS_STAGE_FAILURE:
      return {
        ...state,
        addingProcessStages: false,
        addingProcessStagesError: true,
      };

    case types.ADD_PROCESS_TASK_REQUEST:
      return {
        ...state,
        addingProcessTask: true,
        addingProcessTaskError: false,
      };
    case types.ADD_PROCESS_TASK_SUCCESS:
      return {
        ...state,
        addingProcessTask: false,
        addingProcessTaskError: false,
        addProcessTaskModal: false,
      };
    case types.ADD_PROCESS_TASK_FAILURE:
      return {
        ...state,
        addingProcessTask: false,
        addingProcessTaskError: true,
        addProcessTaskModal: false,
      };

    case types.GET_PROCESS_TASK_REQUEST:
      return {
        ...state,
        fetchingProcessTask: true,
        fetchingProcessTaskError: false,
      };
    case types.GET_PROCESS_TASK_SUCCESS:
      return {
        ...state,
        fetchingProcessTask: false,
        fetchingProcessTaskError: false,
        processTask: action.payload,
      };
    case types.GET_PROCESS_TASK_FAILURE:
      return {
        ...state,
        fetchingProcessTask: false,
        fetchingProcessTaskError: true,
      };

    /**
     * get the list of all departments
     */
    case types.GET_DEPARTMENTS_REQUEST:
      return { ...state, fetchingDepartments: true };
    case types.GET_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        fetchingDepartments: false,
        departments: action.payload,
      };
    case types.GET_DEPARTMENTS_FAILURE:
      return {
        ...state,
        fetchingDepartments: false,
        fetchingDepartmentsError: true,
      };

    case types.GET_LEVELS_REQUEST:
      return { ...state, fetchingLevels: true };
    case types.GET_LEVELS_SUCCESS:
      return { ...state, fetchingLevels: false, levels: action.payload };
    case types.GET_LEVELS_FAILURE:
      return {
        ...state,
        fetchingLevels: false,
        fetchingLevelsError: true,
      };
    case types.UPDATE_TASK_BY_ID_REQUEST:
      return { ...state, updatingTask: true };
    case types.UPDATE_TASK_BY_ID_SUCCESS:
      return { ...state, updatingTask: false };
    case types.UPDATE_TASK_BY_ID_FAILURE:
      return { ...state, updatingTask: false, updatingTaskError: false };

    case types.UPDATE_TASK_RESUFFEL_BY_ID_REQUEST:
      return { ...state, updatingTaskResuffel: true };
    case types.UPDATE_TASK_RESUFFEL_BY_ID_SUCCESS:
      return { ...state, processTask: action.payload };
    case types.UPDATE_TASK_RESUFFEL_BY_ID_FAILURE:
      return {
        ...state,
        updatingTaskResuffel: false,
        updatingTaskResuffelError: false,
      };

    case types.DELETE_TASK_REQUEST:
      return { ...state, deleteTask: true };
    case types.DELETE_TASK_SUCCESS:
      return {
        ...state,
        deleteTask: false,
        processTask: state.processTask.filter(
          (item) => item.taskId !== action.payload
        ),
      };
    case types.DELETE_TASK_FAILURE:
      return { ...state, deleteTask: false, deleteTaskError: false };

    case types.UPDATE_PROCESS_TASK_REQUEST:
      return { ...state, updatingProcessTask: true };
    case types.UPDATE_PROCESS_TASK_SUCCESS:
      ////debugger
      return {
        ...state,
        updatingProcessTask: false,
        addEditProcessModal: false,
      };
    case types.UPDATE_PROCESS_TASK_FAILURE:
      return {
        ...state,
        updatingProcessTask: false,
        updatingProcessTaskError: false,
        addEditProcessModal: false,
      };

    case types.SET_TASK_EDIT:
      return { ...state, setEditingTask: action.payload };

    //recruitment

    case types.GET_PROCESS_FOR_RECRUIT_REQUEST:
      return {
        ...state,
        fetchingProcessForRecruit: true,
        fetchingProcessForRecruitError: false,
      };
    case types.GET_PROCESS_FOR_RECRUIT_SUCCESS:
      return {
        ...state,
        fetchingProcessForRecruit: false,
        fetchingProcessForRecruitError: false,
        recruitProcess: action.payload,
      };
    case types.GET_PROCESS_FOR_RECRUIT_FAILURE:
      return {
        ...state,
        fetchingProcessForRecruit: false,
        fetchingProcessForRecruitError: true,
      };

    case types.ADD_PROCESS_FOR_RECRUIT_REQUEST:
      return {
        ...state,
        addingProcessForRecruit: true,
        addingProcessForRecruitError: false,
      };
    case types.ADD_PROCESS_FOR_RECRUIT_SUCCESS:
      return {
        ...state,
        addingProcessForRecruit: false,
        addingProcessForRecruitError: false,
        addProcessModal: false,
      };
    case types.ADD_PROCESS_FOR_RECRUIT_FAILURE:
      return {
        ...state,
        addingProcessForRecruit: false,
        addingProcessForRecruitError: true,
        addProcessModal: false,
      };

    case types.GET_PROCESS_STAGES_FOR_RECRUIT_REQUEST:
      return {
        ...state,
        fetchingProcessStagesForRecruit: true,
        fetchingProcessStagesForRecruitError: false,
      };
    case types.GET_PROCESS_STAGES_FOR_RECRUIT_SUCCESS:
      return {
        ...state,
        fetchingProcessStagesForRecruit: false,
        fetchingProcessStagesForRecruitError: false,
        recruitProcessStages: action.payload,
      };
    case types.GET_PROCESS_STAGES_FOR_RECRUIT_FAILURE:
      return {
        ...state,
        fetchingProcessStagesForRecruit: false,
        fetchingProcessStagesForRecruitError: true,
      };

    case types.DATA_CLEAR:
      return { ...state, recruitProcessStages: [] };

    case types.ADD_PROCESS_STAGE_FOR_RECRUIT_REQUEST:
      return { ...state, addingProcessStagesForRecruit: true };
    case types.ADD_PROCESS_STAGE_FOR_RECRUIT_SUCCESS:
      return {
        ...state,
        addingProcessStagesForRecruit: false,
        recruitProcessStages: [...state.recruitProcessStages, action.payload],
      };
    case types.ADD_PROCESS_STAGE_FOR_RECRUIT_FAILURE:
      return {
        ...state,
        addingProcessStagesForRecruit: false,
        addingProcessStagesForRecruitError: true,
      };

    case types.UPDATE_PROCESS_NAME_FOR_RECRUIT_REQUEST:
      return { ...state, updateProcessNameForRecruit: true };
    case types.UPDATE_PROCESS_NAME_FOR_RECRUIT_SUCCESS:
      // return { ...state, updatingStages: false, states: [...state.states, action.payload] };
      return {
        ...state,
        updateProcessNameForRecruit: false,
        recruitProcess: state.recruitProcess.map((state) =>
          state.recruitmentProcessId === action.payload.recruitmentProcessId
            ? action.payload
            : state
        ),
      };
    case types.UPDATE_PROCESS_NAME_FOR_RECRUIT_FAILURE:
      return {
        ...state,
        updateProcessNameForRecruit: false,
        updateProcessNameForRecruitError: true,
      };

    case types.UPDATE_STAGE_FOR_RECRUIT_REQUEST:
      return { ...state, updatingStagesForRecruit: true };
    case types.UPDATE_STAGE_FOR_RECRUIT_SUCCESS:
      // return { ...state, updatingStages: false, states: [...state.states, action.payload] };
      return {
        ...state,
        updatingStagesForRecruit: false,
        recruitProcessStages: state.recruitProcessStages.map((state) =>
          state.stageId === action.payload.stageId ? action.payload : state
        ),
      };
    case types.UPDATE_STAGE_FOR_RECRUIT_FAILURE:
      return {
        ...state,
        updatingStagesForRecruit: false,
        updatingStagesForRecruitError: true,
      };

    case types.GET_ALL_PROCESS_STAGES_FOR_RECRUIT_REQUEST:
      return {
        ...state,
        fetchingAllProcessStagesForRecruit: true,
        fetchingAllProcessStagesForRecruitError: false,
      };
    case types.GET_ALL_PROCESS_STAGES_FOR_RECRUIT_SUCCESS:
      return {
        ...state,
        fetchingAllProcessStagesForRecruit: false,
        fetchingAllProcessStagesForRecruitError: false,
        allProcessStagesForRecruit: action.payload,
      };
    case types.GET_ALL_PROCESS_STAGES_FOR_RECRUIT_FAILURE:
      return {
        ...state,
        fetchingAllProcessStagesForRecruit: false,
        fetchingAllProcessStagesForRecruitError: true,
      };

    case types.ADD_ORGANIZATION_LEADS_USER_REQUEST:
      return {
        ...state,
        addingOrganizationLeads: true,
      };
    case types.ADD_ORGANIZATION_LEADS_USER_SUCCESS:
      return {
        ...state,
        addingOrganizationLeads: false,
      };
    case types.ADD_ORGANIZATION_LEADS_USER_FAILURE:
      return {
        ...state,
        addingOrganizationLeads: false,
        addingOrganizationLeadsError: true,
      };

    case types.GET_ORGANIZATION_LEADS_USER_REQUEST:
      return {
        ...state,
        fetchingOrganizationLeads: true,
      };
    case types.GET_ORGANIZATION_LEADS_USER_SUCCESS:
      return {
        ...state,
        fetchingOrganizationLeads: false,

        leadsData: action.payload,
      };
    case types.GET_ORGANIZATION_LEADS_USER_FAILURE:
      return {
        ...state,
        fetchingOrganizationLeads: false,
        fetchingOrganizationLeadsError: true,
      };

    case types.GET_SIGNATURE_REQUEST:
      return {
        ...state,
        fetchingSignatureInd: true,
        fetchingSignatureIndError: false,
      };
    case types.GET_SIGNATURE_SUCCESS:
      return {
        ...state,
        fetchingSignatureInd: false,
        fetchingSignatureIndError: false,
        signatureInd: action.payload,
      };
    case types.GET_SIGNATURE_FAILURE:
      return {
        ...state,
        fetchingSignatureInd: false,
        fetchingSignatureIndError: true,
      };

    //Apparel

    case types.HANDLE_APPAREL_MODAL:
      return { ...state, apparelModal: action.payload };
    case types.ADD_APPAREL_REQUEST:
      return { ...state, addingApparel: true };
    case types.ADD_APPAREL_SUCCESS:
      return {
        ...state,
        addingApparel: false,
        apparelModal: false,
      };
    case types.ADD_APPAREL_FAILURE:
      return {
        ...state,
        addingApparel: false,
        addingApparelError: true,
        apparelModal: false,
      };

    case types.GET_APPAREL_REQUEST:
      return { ...state, fetchingApparel: true };
    case types.GET_APPAREL_SUCCESS:
      return {
        ...state,
        fetchingApparel: false,
        apparel: action.payload,
      };
    case types.GET_APPAREL_FAILURE:
      return {
        ...state,
        fetchingApparel: false,
        fetchingApparelError: true,
      };

    case types.UPDATE_APPAREL_REQUEST:
      return { ...state, updateApparel: true };
    case types.UPDATE_APPAREL_SUCCESS:
      // return { ...state, updatingStages: false, states: [...state.states, action.payload] };
      return {
        ...state,
        updateApparel: false,
        apparel: state.apparel.map((state) =>
          state.categoryId === action.payload.categoryId
            ? action.payload
            : state
        ),
      };
    case types.UPDATE_APPAREL_FAILURE:
      return {
        ...state,
        updateApparel: false,
        updateApparelError: true,
      };

    case types.GET_APPAREL_DATA_BY_APPAREL_ID_REQUEST:
      return { ...state, fetchingApparelById: true };
    case types.GET_APPAREL_DATA_BY_APPAREL_ID_SUCCESS:
      return {
        ...state,
        fetchingApparelById: false,
        apparelById: action.payload,
      };
    case types.GET_APPAREL_DATA_BY_APPAREL_ID_FAILURE:
      return {
        ...state,
        fetchingApparelById: false,
        fetchingApparelByIdError: true,
      };

    case types.UPDATE_APPAREL_BY_APPAREL_ID_REQUEST:
      return { ...state, updateApparelById: true };
    case types.UPDATE_APPAREL_BY_APPAREL_ID_SUCCESS:
      // return { ...state, updatingStages: false, states: [...state.states, action.payload] };
      return {
        ...state,
        updateApparelById: false,
        apparelById: state.apparelById.map((state) =>
          state.subcategoryId === action.payload.subcategoryId
            ? action.payload
            : state
        ),
      };
    case types.UPDATE_APPAREL_BY_APPAREL_ID_FAILURE:
      return {
        ...state,
        updateApparelById: false,
        updateApparelByIdError: true,
      };

    case types.ADD_APPAREL_DATA_BY_APPAREL_ID_REQUEST:
      return { ...state, addingApparelById: true };
    case types.ADD_APPAREL_DATA_BY_APPAREL_ID_SUCCESS:
      return {
        ...state,
        addingApparelById: false,
      };
    case types.ADD_APPAREL_DATA_BY_APPAREL_ID_FAILURE:
      return {
        ...state,
        addingApparelById: false,
        addingApparelByIdError: true,
      };

    case types.GET_ALL_SUBCATEGORY_REQUEST:
      return { ...state, fetchingAllSubcategory: true };
    case types.GET_ALL_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        fetchingAllSubcategory: false,
        allsubCategory: action.payload,
      };
    case types.GET_ALL_SUBCATEGORY_FAILURE:
      return {
        ...state,
        fetchingAllSubcategory: false,
        fetchingAllSubcategoryError: true,
      };

    default:
      return state;
  }
};
