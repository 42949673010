import * as types from "./PartnerLeadsActionTypes";
import axios from "axios";
import { base_url } from "../../Config/Auth";

export const handlePartnerOpportunityModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_PARTNER_LEADS_OPPORTUNITY_MODAL,
    payload: modalProps,
  });
};

export const handlePartnerCustomerModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_PARTNER_LEADS_CUSTOMER_MODAL,
    payload: modalProps,
  });
};

export const handleEditPartnerCustomerModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_EDIT_PARTNER_LEADS_CUSTOMER_MODAL,
    payload: modalProps,
  });
};

export const handlEditPartnerOpportunityModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_EDIT_PARTNER_LEADS_OPPORTUNITY_MODAL,
    payload: modalProps,
  });
};

export const setCustomerPartnerLeadsEdit = (name) => (dispatch) => {
  ////debugger
  dispatch({
    type: types.SET_CUSTOMER_PARTNER_LEADS_EDIT,
    payload: name,
  });
};

export const setOpportunityPartnerLeadsEdit = (name) => (dispatch) => {
  ////debugger
  dispatch({
    type: types.SET_OPPORTUNITY_PARTNER_LEADS_EDIT,
    payload: name,
  });
};

export const getCurrency = () => (dispatch) => {
  dispatch({
    type: types.GET_CURRENCY_REQUEST,
  });
  axios
    .get(`${base_url}/currency`)
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CURRENCY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CURRENCY_FAILURE,
        payload: err,
      });
    });
};

export const getProcess = () => (dispatch) => {
  dispatch({
    type: types.GET_PROCESS_REQUEST,
  });
  axios
    .get(`${base_url}/processes`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PROCESS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_PROCESS_FAILURE,
        payload: err,
      });
    });
};

export const getAllProcessStages = () => (dispatch) => {
  dispatch({
    type: types.GET_ALL_PROCESS_STAGES_REQUEST,
  });
  axios
    .get(`${base_url}/allProcessStages`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ALL_PROCESS_STAGES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ALL_PROCESS_STAGES_FAILURE,
        payload: err,
      });
    });
};

//Add Partner Customer
export const addPartnerCustomer = (data, userId) => (dispatch) => {
  dispatch({ type: types.ADD_PARTNER_CUSTOMER_REQUEST });

  axios
    .post(`${base_url}/partner-account-leads`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      dispatch(getPartnerCustomer(userId));
      console.log(res);
      dispatch({
        type: types.ADD_PARTNER_CUSTOMER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_PARTNER_CUSTOMER_FAILURE,
      });
    });
};

//GET PARTNER CUSTOMER
export const getPartnerCustomer = (userId) => (dispatch) => {
  dispatch({ type: types.GET_PARTNER_CUSTOMER_REQUEST });
  axios
    .get(`${base_url}/partner-account-leads/user/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PARTNER_CUSTOMER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_PARTNER_CUSTOMER_FAILURE,
        payload: err,
      });
    });
};

//UPDATE Partner Customer
export const updatePartnerCustomer = (data, userId, cb) => (dispatch) => {
  dispatch({ type: types.UPDATE_PARTNER_CUSTOMER_REQUEST });

  axios
    .patch(`${base_url}/partner-account-leads`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      // dispatch(getPartnerCustomer(userId));
      console.log(res);
      dispatch({
        type: types.UPDATE_PARTNER_CUSTOMER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_PARTNER_CUSTOMER_FAILURE,
      });
    });
};

//Add Partner Opportunity
export const addPartnerOpportunity = (data, userId, leadsId) => (dispatch) => {
  dispatch({ type: types.ADD_PARTNER_OPPORTUNITY_REQUEST });

  axios
    .post(`${base_url}/partner-opportunity-leads`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      dispatch(getPartnerOpportunity(userId));
      console.log(res);
      dispatch({
        type: types.ADD_PARTNER_OPPORTUNITY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_PARTNER_OPPORTUNITY_FAILURE,
      });
    });
};

//GET PARTNER OPPORTUNITY
export const getPartnerOpportunity = (userId) => (dispatch) => {
  dispatch({ type: types.GET_PARTNER_OPPORTUNITY_REQUEST });
  axios
    .get(`${base_url}/partner-opportunity-leads/user/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PARTNER_OPPORTUNITY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_PARTNER_OPPORTUNITY_FAILURE,
        payload: err,
      });
    });
};

//UPDATE Partner Opportunity
export const updatePartnerOpportunity = (data) => (dispatch) => {
  dispatch({ type: types.UPDATE_PARTNER_OPPORTUNITY_REQUEST });

  axios
    .patch(`${base_url}/partner-opportunity-leads`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      // dispatch(getPartnerOpportunity());
      console.log(res);
      dispatch({
        type: types.UPDATE_PARTNER_OPPORTUNITY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_PARTNER_OPPORTUNITY_FAILURE,
      });
    });
};

//REGISTER opportunity
export const registerPartnerOpportunity = (data, userId) => (dispatch) => {
  dispatch({ type: types.REGISTER_PARTNER_OPPORTUNITY_REQUEST });

  axios
    .post(`${base_url}/register-partner-opportunity-leads`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      dispatch(getPartnerOpportunity(userId));
      console.log(res);
      dispatch({
        type: types.REGISTER_PARTNER_OPPORTUNITY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.REGISTER_PARTNER_OPPORTUNITY_FAILURE,
      });
    });
};

//REGISTER account
export const registerPartnerCustomer = (data, userId) => (dispatch) => {
  dispatch({ type: types.REGISTER_PARTNER_CUSTOMER_REQUEST });

  axios
    .post(`${base_url}/register-partner-account-leads`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      dispatch(getPartnerCustomer(userId));
      console.log(res);
      dispatch({
        type: types.REGISTER_PARTNER_CUSTOMER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.REGISTER_PARTNER_CUSTOMER_FAILURE,
      });
    });
};

//GET COMPANY
export const getCompany = (userId) => (dispatch) => {
  dispatch({ type: types.GET_COMPANY_REQUEST });
  axios
    .get(`${base_url}/partner-account-leads/user/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_COMPANY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_COMPANY_FAILURE,
        payload: err,
      });
    });
};
