import React, { lazy, Suspense } from "react";

import { BundleLoader } from "../../../Components/Placeholder";
import { StyledModal } from "../../../Components/UI/Antd";
const TeamForm = lazy(() => import("./TeamForm"));

const AddUserModal = props => {
  const { addUserModal, handleUserModal, ...formProps } = props;
  return (
    <>
      <StyledModal
        title="User"
        width="40vw"
        visible={addUserModal}
        destroyOnClose
        // destroyOnClose
        maskClosable={false}
        maskStyle={{ backgroundColor: "rgba(1, 30, 71,0.7)" }}
        style={{ top: 10 }}
        onCancel={() => props.handleUserModal(false)}
        footer={null}
      >
        <Suspense fallback={<BundleLoader />}>
          <TeamForm {...formProps} />
        </Suspense>
      </StyledModal>
    </>
  );
};

export default AddUserModal;
