import * as types from "./LeadsActionTypes";
import axios from "axios";
import { base_url } from "../../Config/Auth";
import { message } from "antd";
import { getRecords } from "../Contact/ContactAction";

export const setLeadsType = (type) => (dispatch) =>
  dispatch({
    type: types.SET_LEADS_TYPE,
    payload: type,
  });

export const setLeadsContact = (name) => (dispatch) => {
  ////debugger
  dispatch({
    type: types.SET_LEADS_CONTACT_EDIT,
    payload: name,
  });
};

export const handleLeadsAccountModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_LEADS_ACCOUNT_MODAL,
    payload: modalProps,
  });
};

export const handlLeadsNotesModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_LEADS_NOTES_MODAL,
    payload: modalProps,
  });
};

export const handlLeadsContactNotesModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_LEADS_CONTACT_NOTES_MODAL,
    payload: modalProps,
  });
};

export const setLeadsAccount = (name) => (dispatch) => {
  ////debugger
  dispatch({
    type: types.SET_LEADS_ACCOUNT_EDIT,
    payload: name,
  });
};

export const handlEditLeadsAccountModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_EDIT_LEADS_ACCOUNT_MODAL,
    payload: modalProps,
  });
};

export const handleLeadsContactModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_LEADS_CONTACT_MODAL,
    payload: modalProps,
  });
};

export const handleLeadsOpportunityModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_LEADS_OPPORTUNITY_MODAL,
    payload: modalProps,
  });
};

export const handlEditLeadsContactModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_EDIT_LEADS_CONTACT_MODAL,
    payload: modalProps,
  });
};

export const addLeadsAccount = (account, cb) => (dispatch, getState) => {
  const userId = getState().auth.userDetails.userId;
  dispatch({
    type: types.ADD_LEADS_ACCOUNT_REQUEST,
  });

  axios
    .post(`${base_url}/account-leads`, account, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(emptyLeadsAccount());
      dispatch(getLeadsAccounts(userId, 0));

      dispatch({
        type: types.ADD_LEADS_ACCOUNT_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_LEADS_ACCOUNT_FAILURE,
        payload: err,
      });
      cb && cb("unable to create");
    });
};

export const getLeadsAccounts = (userId, page) => (dispatch) => {
  dispatch({
    type: types.GET_LEADS_ACCOUNT_REQUEST,
  });
  axios
    .get(`${base_url}/account-leads/user/${userId}/${page}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_LEADS_ACCOUNT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_LEADS_ACCOUNT_FAILURE,
        payload: err,
      });
    });
};

export const convertLeadsAccountToActualAccount = (leadsId, cb) => (
  dispatch,
  getState
) => {
  const userId = getState().auth.userDetails.userId;
  dispatch({
    type: types.CONVERT_LEADS_ACCOUNT_TO_ACTUAL_ACCOUNT_REQUEST,
  });

  axios
    .get(`${base_url}/convert/leads/account/${leadsId}/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      dispatch(emptyLeadsAccount());
      dispatch(getLeadsAccounts(userId, 0));
      dispatch({
        type: types.CONVERT_LEADS_ACCOUNT_TO_ACTUAL_ACCOUNT_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.CONVERT_LEADS_ACCOUNT_TO_ACTUAL_ACCOUNT_FAILURE,
        payload: err,
      });
      cb && cb("failure");
    });
};

// export const convertLeadsAccountToActualAccount = (leadsId) => (dispatch) => {
//   dispatch({
//     type: types.CONVERT_LEADS_ACCOUNT_TO_ACTUAL_ACCOUNT_REQUEST,
//   });
//   axios
//     .get(`${base_url}/convert/leads/account/${leadsId}`, {
//       headers: {
//         Authorization: "Bearer " + sessionStorage.getItem("token") || "",
//       },
//     })
//     .then((res) => {
//       console.log(res);
//       dispatch({
//         type: types.CONVERT_LEADS_ACCOUNT_TO_ACTUAL_ACCOUNT_SUCCESS,
//         payload: res.data,
//       });
//     })
//     .catch((err) => {
//       console.log(err);
//       dispatch({
//         type: types.CONVERT_LEADS_ACCOUNT_TO_ACTUAL_ACCOUNT_FAILURE,
//         payload: err,
//       });
//     });
// };

export const addLeadsContact = (contact, cb) => (dispatch, getState) => {
  const userId = getState().auth.userDetails.userId;
  dispatch({
    type: types.ADD_LEADS_CONTACT_REQUEST,
  });

  axios
    .post(`${base_url}/contact-leads`, contact, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(emptyLeadsContact());
      dispatch(getLeadsContacts(userId, 0));
      dispatch(getApproachingLeads());

      dispatch({
        type: types.ADD_LEADS_CONTACT_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_LEADS_CONTACT_FAILURE,
        payload: err,
      });
      cb && cb("unable to create");
    });
};

export const getLeadsContacts = (userId, page) => (dispatch) => {
  dispatch({
    type: types.GET_LEADS_CONTACT_REQUEST,
  });
  axios
    .get(`${base_url}/contact-leads/user/${userId}/${page}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_LEADS_CONTACT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_LEADS_CONTACT_FAILURE,
        payload: err,
      });
    });
};

export const getLeadsContactsWithoutPage = (userId) => (dispatch) => {
  dispatch({
    type: types.GET_LEADS_CONTACT_WITHOUT_PAGE_REQUEST,
  });
  axios
    .get(`${base_url}/contact-leads/user/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_LEADS_CONTACT_WITHOUT_PAGE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_LEADS_CONTACT_WITHOUT_PAGE_FAILURE,
        payload: err,
      });
    });
};

export const convertLeadsContactToActualContact = (leadsId, cb) => (
  dispatch,
  getState
) => {
  const userId = getState().auth.userDetails.userId;
  dispatch({
    type: types.CONVERT_LEADS_CONTACT_TO_ACTUAL_CONTACT_REQUEST,
  });

  axios
    .get(`${base_url}/convert/leads/contact/${leadsId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      dispatch(emptyLeadsContact());
      dispatch(getLeadsContacts(userId, 0));
      // dispatch(getApproachingLeads());
      dispatch(getFilterJunkContact());
      dispatch({
        type: types.CONVERT_LEADS_CONTACT_TO_ACTUAL_CONTACT_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.CONVERT_LEADS_CONTACT_TO_ACTUAL_CONTACT_FAILURE,
        payload: err,
      });
      cb && cb("failure");
    });
};

//contact typoe
export const setContactTypeForLead = (leadsId, leadCategory) => (dispatch) => {
  console.log(leadsId, leadCategory);

  dispatch({
    type: types.UPDATE_CONTACT_TYPE_FOR_LEAD_REQUEST,
  });
  axios
    .patch(`${base_url}/update/leads/contact`, leadCategory, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(getApproachingLeads());
      dispatch({
        type: types.UPDATE_CONTACT_TYPE_FOR_LEAD_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.UPDATE_CONTACT_TYPE_FOR_LEAD_FAILURE,
      });
    });
};

//account typoe
export const setAccountTypeForLead = (leadsId, leadCategory) => (dispatch) => {
  console.log(leadsId, leadCategory);

  dispatch({
    type: types.UPDATE_ACCOUNT_TYPE_FOR_LEAD_REQUEST,
  });
  axios
    .patch(`${base_url}/update/leads/account`, leadCategory, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_ACCOUNT_TYPE_FOR_LEAD_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.UPDATE_ACCOUNT_TYPE_FOR_LEAD_FAILURE,
      });
    });
};

export const getFilterdLeadsContactsByCategory = (userId, category) => (
  dispatch
) => {
  dispatch({
    type: types.GET_FILTERS_LEADS_CONTACT_BY_CATEGORY_REQUEST,
  });
  axios
    .get(`${base_url}/filterLeads/contact/${userId}/${category}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_FILTERS_LEADS_CONTACT_BY_CATEGORY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_FILTERS_LEADS_CONTACT_BY_CATAOGRY_FAILURE,
        payload: err,
      });
    });
};

export const getFilterdLeadsAccountsByCategory = (userId, category) => (
  dispatch
) => {
  dispatch({
    type: types.GET_FILTERS_LEADS_ACCOUNT_BY_CATEGORY_REQUEST,
  });
  axios
    .get(`${base_url}/filter/leads/account/${userId}/${category}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_FILTERS_LEADS_ACCOUNT_BY_CATEGORY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_FILTERS_LEADS_ACCOUNT_BY_CATAOGRY_FAILURE,
        payload: err,
      });
    });
};

export const getFilterdLeadsJunkContactsByCategory = (category) => (
  dispatch
) => {
  dispatch({
    type: types.GET_FILTERS_LEADS_JUNK_CONTACT_BY_CATEGORY_REQUEST,
  });
  axios
    .get(`${base_url}/filter/junk-contact-leads/${category}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_FILTERS_LEADS_JUNK_CONTACT_BY_CATEGORY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_FILTERS_LEADS_JUNK_CONTACT_BY_CATAOGRY_FAILURE,
        payload: err,
      });
    });
};

//Contact /Account Edit
export const UpdateContactLead = (data, cb) => (dispatch) => {
  console.log(data);

  dispatch({
    type: types.UPDATE_CONTACT_LEAD_REQUEST,
  });
  axios
    .patch(`${base_url}/update/leads/contact`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_CONTACT_LEAD_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      dispatch({
        type: types.UPDATE_CONTACT_LEAD_FAILURE,
      });
      cb && cb("failure");
    });
};

export const UpdateAccountLead = (data, cb) => (dispatch) => {
  console.log(data);

  dispatch({
    type: types.UPDATE_ACCOUNT_LEAD_REQUEST,
  });
  axios
    .patch(`${base_url}/update/leads/account`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_ACCOUNT_LEAD_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      dispatch({
        type: types.UPDATE_ACCOUNT_LEAD_FAILURE,
      });
      cb && cb("failure");
    });
};

export const getDesignationWiseContact = (designation) => (dispatch) => {
  dispatch({
    type: types.GET_DESIGNATION_WISE_CONTACT_REQUEST,
  });
  axios
    .get(`${base_url}/filter/leadsContact/${designation}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_DESIGNATION_WISE_CONTACT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_DESIGNATION_WISE_CONTACT_FAILURE,
        payload: err,
      });
    });
};

export const getCountryWiseContact = (country) => (dispatch) => {
  dispatch({
    type: types.GET_COUNTRY_WISE_CONTACT_REQUEST,
  });
  axios
    .get(`${base_url}/filter/leadsContact/country/${country}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_COUNTRY_WISE_CONTACT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_COUNTRY_WISE_CONTACT_FAILURE,
        payload: err,
      });
    });
};
//approaching leads

export const getApproachingLeads = () => (dispatch) => {
  dispatch({
    type: types.GET_APPRAOCHING_LEADS_REQUEST,
  });
  axios
    .get(`${base_url}/leads/junk-leads`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_APPRAOCHING_LEADS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_APPRAOCHING_LEADS_FAILURE,
        payload: err,
      });
    });
};

//junk Leads
export const getJunkedLeads = (page) => (dispatch) => {
  dispatch({
    type: types.GET_JUNKED_LEADS_REQUEST,
  });
  axios
    .get(`${base_url}/junk-contact-leads/${page}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_JUNKED_LEADS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_JUNKED_LEADS_FAILURE,
        payload: err,
      });
    });
};

export const getFilterJunkContact = () => (dispatch) => {
  dispatch({
    type: types.GET_FILTER_JUNK_CONTACT_REQUEST,
  });
  axios
    .get(`${base_url}/leads/junk-leads`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_FILTER_JUNK_CONTACT_SUCCESS,
        payload: res.data.junkContactList,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_FILTER_JUNK_CONTACT_FAILURE,
        payload: err,
      });
    });
};

export const reAssignJunkContact = (data, cb) => (dispatch) => {
  dispatch({
    type: types.REASSIGN_JUNK_CONTACT_REQUEST,
  });
  axios
    .post(`${base_url}/leads/distribute-junk-leads`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.REASSIGN_JUNK_CONTACT_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.REASSIGN_JUNK_CONTACT_SUCCESS,
        payload: err,
      });
      cb && cb("failure");
    });
};

export const emptyLeadsContact = () => (dispatch) => {
  dispatch({
    type: types.CLEAN_LEADS_CONTACT,
  });
};

export const emptyLeadsAccount = () => (dispatch) => {
  dispatch({
    type: types.CLEAN_LEADS_ACCOUNT,
  });
};

export const emptyJunkLeadsAccount = () => (dispatch) => {
  dispatch({
    type: types.CLEAN_JUNK_LEADS_CONTACT,
  });
};

export const getLeadsAccountsWithOutPageNo = (userId) => (dispatch) => {
  dispatch({
    type: types.GET_LEADS_ACCOUNT_WITHOUT_PAGE_NO_REQUEST,
  });
  axios
    .get(`${base_url}/account-leads/user/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_LEADS_ACCOUNT_WITHOUT_PAGE_NO_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_LEADS_ACCOUNT_WITHOUT_PAGE_NO_FAILURE,
        payload: err,
      });
    });
};

//cleanup account,contact,junk leads

export const cleanUpLeadsContacts = (data, userId) => (dispatch) => {
  dispatch({
    type: types.CLEANUP_LEADS_CONTACT_REQUEST,
  });
  axios
    .post(
      `${base_url}/delete/contact-leads`,
      { ids: data },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch(emptyLeadsContact());
      dispatch(getLeadsContacts(userId, 0));
      dispatch(getRecords(userId));
      message.success(`Successfully Deleted!`);

      dispatch({
        type: types.CLEANUP_LEADS_CONTACT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.CLEANUP_LEADS_CONTACT_FAILURE,
        payload: err,
      });
    });
};

export const cleanUpLeadsAccounts = (data, userId) => (dispatch) => {
  dispatch({
    type: types.CLEANUP_LEADS_ACCOUNT_REQUEST,
  });
  axios
    .post(
      `${base_url}/delete/account-leads`,
      { ids: data },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      dispatch(emptyLeadsAccount());
      dispatch(getLeadsAccounts(userId, 0));
      dispatch(getRecords(userId));
      message.success(`Successfully Deleted!`);
      console.log(res);
      dispatch({
        type: types.CLEANUP_LEADS_ACCOUNT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.CLEANUP_LEADS_ACCOUNT_FAILURE,
        payload: err,
      });
    });
};

export const cleanUpJunkedLeadsContacts = (data) => (dispatch) => {
  dispatch({
    type: types.CLEANUP_JUNK_LEADS_CONTACT_REQUEST,
  });
  axios
    .post(
      `${base_url}/delete/junk-leads`,
      { ids: data },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      dispatch(emptyJunkLeadsAccount());
      dispatch(getJunkedLeads(0));
      // dispatch(getRecords(userId));
      console.log(res);
      message.success(`Successfully Deleted!`);
      dispatch({
        type: types.CLEANUP_JUNK_LEADS_CONTACT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.CLEANUP_JUNK_LEADS_CONTACT_FAILURE,
        payload: err,
      });
    });
};

export const handleEventTaskModal = (modalForm) => (dispatch) => {
  dispatch({
    type: types.HANDLE_EVENT_TASK_MODAL,
    payload: modalForm,
  });
};

export const handleLeadsAccountSchedulerModal = (modalForm) => (dispatch) => {
  dispatch({
    type: types.HANDLE_LEADS_ACCOUNT_SCHEDULER_MODAL,
    payload: modalForm,
  });
};

export const addLeadSchedulerEvent = (event, cb) => (dispatch, getState) => {
  // const userId = getState().auth.userDetails.userId;
  dispatch({
    type: types.ADD_LEADS_SCHEDULER_EVENT_REQUEST,
  });

  axios
    .post(`${base_url}/event/contact-leads`, event, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      // dispatch(getLeadSchedulerEvent(leadsId));

      dispatch({
        type: types.ADD_LEADS_SCHEDULER_EVENT_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_LEADS_SCHEDULER_EVENT_FAILURE,
        payload: err,
      });
      cb && cb("unable to create");
    });
};

//customer call
export const addLeadAccountSchedulerCall = (call, cb) => (
  dispatch,
  getState
) => {
  // const userId = getState().auth.userDetails.userId;
  dispatch({
    type: types.ADD_LEADS_ACCOUNT_SCHEDULER_CALL_REQUEST,
  });
  axios
    .post(`${base_url}/call/account-leads`, call, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      // dispatch(getLeadSchedulerEvent(leadsId));

      dispatch({
        type: types.ADD_LEADS_ACCOUNT_SCHEDULER_CALL_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_LEADS_ACCOUNT_SCHEDULER_CALL_FAILURE,
        payload: err,
      });
      cb && cb("unable to create");
    });
};

//customer Event
export const addLeadAccountSchedulerEvent = (event, cb) => (
  dispatch,
  getState
) => {
  // const userId = getState().auth.userDetails.userId;
  dispatch({
    type: types.ADD_LEADS_ACCOUNT_SCHEDULER_EVENT_REQUEST,
  });
  axios
    .post(`${base_url}/event/account-leads`, event, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      // dispatch(getLeadSchedulerEvent(leadsId));

      dispatch({
        type: types.ADD_LEADS_ACCOUNT_SCHEDULER_EVENT_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_LEADS_ACCOUNT_SCHEDULER_EVENT_FAILURE,
        payload: err,
      });
      cb && cb("unable to create");
    });
};

export const getLeadSchedulerEvent = (leadsId) => (dispatch) => {
  dispatch({
    type: types.GET_LEADS_SCHEDULER_EVENT_REQUEST,
  });
  axios
    .get(`${base_url}/event/contact-leads/${leadsId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_LEADS_SCHEDULER_EVENT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_LEADS_SCHEDULER_EVENT_FAILURE,
        payload: err,
      });
    });
};

//CALL

export const addLeadSchedulerCall = (call, leadsId, cb) => (
  dispatch,
  getState
) => {
  // const userId = getState().auth.userDetails.userId;
  dispatch({
    type: types.ADD_LEADS_SCHEDULER_CALL_REQUEST,
  });

  axios
    .post(`${base_url}/call/contact-leads`, call, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      // dispatch(getLeadSchedulerCall(leadsId));

      dispatch({
        type: types.ADD_LEADS_SCHEDULER_CALL_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_LEADS_SCHEDULER_CALL_FAILURE,
        payload: err,
      });
      cb && cb("unable to create");
    });
};

export const getLeadSchedulerCall = (leadsId) => (dispatch) => {
  dispatch({
    type: types.GET_LEADS_SCHEDULER_CALL_REQUEST,
  });
  axios
    .get(`${base_url}/call/contact-leads/${leadsId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_LEADS_SCHEDULER_CALL_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_LEADS_SCHEDULER_CALL_FAILURE,
        payload: err,
      });
    });
};
export const setEditNote = (name) => (dispatch) => {
  dispatch({
    type: types.SET_NOTE_EDIT,
    payload: name,
  });
};

export const getNotesListByLeadsId = (leadsId) => (dispatch) => {
  // console.log(callId);
  dispatch({
    type: types.GET_NOTES_LIST_BY_LEADS_ID_REQUEST,
  });
  axios
    .get(`${base_url}/note/account-leads/${leadsId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_NOTES_LIST_BY_LEADS_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_NOTES_LIST_BY_LEADS_ID_FAILURE,
        payload: err,
      });
    });
};

export const getTimelineListByUserId = (leadsContactId) => (dispatch) => {
  // console.log(callId);
  dispatch({
    type: types.GET_TIMELINE_LIST_BY_USER_ID_REQUEST,
  });
  axios
    .get(`${base_url}/timeline/leads/${leadsContactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_TIMELINE_LIST_BY_USER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_TIMELINE_LIST_BY_USER_ID_FAILURE,
        payload: err,
      });
    });
};

//account Timeline
export const getAccountTimelineListByUserId = (leadsAccountId) => (
  dispatch
) => {
  // console.log(callId);
  dispatch({
    type: types.GET_ACCOUNT_TIMELINE_LIST_BY_USER_ID_REQUEST,
  });
  axios
    .get(`${base_url}/timeline/leads/Account/${leadsAccountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ACCOUNT_TIMELINE_LIST_BY_USER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ACCOUNT_TIMELINE_LIST_BY_USER_ID_FAILURE,
        payload: err,
      });
    });
};

export const getContactNotesListByLeadsId = (leadsId) => (dispatch) => {
  // console.log(callId);
  dispatch({
    type: types.GET_CONTACT_NOTES_LIST_BY_LEADS_ID_REQUEST,
  });
  axios
    .get(`${base_url}/note/contact-leads/${leadsId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CONTACT_NOTES_LIST_BY_LEADS_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CONTACT_NOTES_LIST_BY_LEADS_ID_FAILURE,
        payload: err,
      });
    });
};

export const handleContactTimelineModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_CONTACT_TIMELINE_MODAL,
    payload: modalProps,
  });
};

export const handleAccountTimelineModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_ACCOUNT_TIMELINE_MODAL,
    payload: modalProps,
  });
};

export const getLeadsAccountListRangeByUserIdForReport = (
  userId,
  type,
  startDate,
  endDate
) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/report/call/${userId}`;
  } else {
    api_url = `/report/leadsAccount?userId=${userId}&type=${type}&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_LEADS_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_LEADS_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_LEADS_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE,
        payload: err,
      });
    });
};

export const getLeadsContactListRangeByUserIdForReport = (
  userId,
  type,
  startDate,
  endDate
) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/report/call/${userId}`;
  } else {
    api_url = `/report/leadsContacts?userId=${userId}&type=${type}&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_LEADS_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_LEADS_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_LEADS_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE,
        payload: err,
      });
    });
};
export const handleAddQualifiedModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_ADD_QUALIFIED_MODAL,
    payload: modalProps,
  });
};

export const handleAddLeadsQualifierModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_ADD_LEADS_QUALIFIER_MODAL,
    payload: modalProps,
  });
};

export const addLeadsQualifierSalesAccount = (data, leadsId, userId, cb) => (
  dispatch,
  getState
) => {
  const userIdS = getState().auth.userDetails.userId;
  dispatch({
    type: types.ADD_LEADS_QUALIFIER_SALES_ACCOUNT_REQUEST,
  });

  axios
    .post(
      `${base_url}/convert/leads/account/${leadsId}/${data.userId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch(emptyLeadsAccount());
      dispatch(getLeadsAccounts(userIdS, 0));

      dispatch({
        type: types.ADD_LEADS_QUALIFIER_SALES_ACCOUNT_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_LEADS_QUALIFIER_SALES_ACCOUNT_FAILURE,
        payload: err,
      });
      cb && cb("unable to create");
    });
};

export const addContactLeadsQualifierSalesAccount = (
  data,
  leadsId,
  userId,
  cb
) => (dispatch, getState) => {
  const userIdS = getState().auth.userDetails.userId;
  dispatch({
    type: types.ADD_CONTACT_LEADS_QUALIFIER_SALES_ACCOUNT_REQUEST,
  });

  axios
    .post(
      `${base_url}/convert/leads/contact/${leadsId}/${data.userId}`,
      {},

      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch(emptyLeadsContact());
      dispatch(getLeadsContacts(userIdS, 0));
      // dispatch(getApproachingLeads());
      dispatch(getFilterJunkContact());

      dispatch({
        type: types.ADD_CONTACT_LEADS_QUALIFIER_SALES_ACCOUNT_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_CONTACT_LEADS_QUALIFIER_SALES_ACCOUNT_FAILURE,
        payload: err,
      });
      cb && cb("unable to create");
    });
};

//CONTACT PERMISSION SHARE Of Partner
export const shareLeadPermission = (data) => (dispatch, getState) => {
  // const { userId } = getState("auth").auth.userDetails;
  dispatch({
    type: types.ADD_SHARE_LEAD_PERMISSION_REQUEST,
  });

  axios
    // .post(`${base_url}/permission/details`, data, {
    //   headers: {
    //     Authorization: "Bearer " + sessionStorage.getItem("token") || "",
    //   },
    // })
    .then((res) => {
      console.log(res);
      // dispatch(getCustomerListByUserId(userId));
      dispatch({
        type: types.ADD_SHARE_LEAD_PERMISSION_SUCCESS,
        payload: res.data,
      });
      // cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_SHARE_LEAD_PERMISSION_FAILURE,
        payload: err,
      });
      // cb && cb("failure");
    });
};

export const getPermissionsListLead = () => (dispath) => {
  dispath({ type: types.GET_PERMISSIONS_LIST_LEAD_REQUEST });
  axios
    // .get(`${base_url}/permission/type?type=${"customer"}`, {
    //   headers: {
    //     Authorization: "Bearer " + sessionStorage.getItem("token") || "",
    //   },
    // })
    .then((res) => {
      dispath({
        type: types.GET_PERMISSIONS_LIST_LEAD_SUCCESS,
        payload: res.data,
      });

    })
    .catch((err) => {
      dispath({
        type: types.GET_PERMISSIONS_LIST_LEAD_FAILURE,
        payload: err,
      });
    });
};
