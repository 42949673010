export const HANDLE_PARTNER_LEADS_OPPORTUNITY_MODAL =
  "HANDLE_PARTNER_LEADS_OPPORTUNITY_MODAL";

export const HANDLE_PARTNER_LEADS_CUSTOMER_MODAL =
  "HANDLE_PARTNER_LEADS_CUSTOMER_MODAL";

export const HANDLE_EDIT_PARTNER_LEADS_CUSTOMER_MODAL =
  "HANDLE_EDIT_PARTNER_LEADS_CUSTOMER_MODAL";

export const SET_CUSTOMER_PARTNER_LEADS_EDIT =
  "SET_CUSTOMER_PARTNER_LEADS_EDIT";

export const HANDLE_EDIT_PARTNER_LEADS_OPPORTUNITY_MODAL =
  "HANDLE_EDIT_PARTNER_LEADS_OPPORTUNITY_MODAL";

export const SET_OPPORTUNITY_PARTNER_LEADS_EDIT =
  "SET_OPPORTUNITY_PARTNER_LEADS_EDIT";

export const GET_CURRENCY_REQUEST = "GET_CURRENCY_REQUEST";
export const GET_CURRENCY_SUCCESS = "GET_CURRENCY_SUCCESS";
export const GET_CURRENCY_FAILURE = "GET_CURRENCY_FAILURE";

export const GET_PROCESS_REQUEST = "GET_PROCESS_REQUEST";
export const GET_PROCESS_SUCCESS = "GET_PROCESS_SUCCESS";
export const GET_PROCESS_FAILURE = "GET_PROCESS_FAILURE";

export const GET_ALL_PROCESS_STAGES_REQUEST = "GET_ALL_PROCESS_STAGES_REQUEST";
export const GET_ALL_PROCESS_STAGES_SUCCESS = "GET_ALL_PROCESS_STAGES_SUCCESS";
export const GET_ALL_PROCESS_STAGES_FAILURE = "GET_ALL_PROCESS_STAGES_FAILURE";

export const ADD_PARTNER_CUSTOMER_REQUEST = "ADD_PARTNER_CUSTOMER_REQUEST";
export const ADD_PARTNER_CUSTOMER_SUCCESS = "ADD_PARTNER_CUSTOMER_SUCCESS";
export const ADD_PARTNER_CUSTOMER_FAILURE = "ADD_PARTNER_CUSTOMER_FAILURE";

export const GET_PARTNER_CUSTOMER_REQUEST = "GET_PARTNER_CUSTOMER_REQUEST";
export const GET_PARTNER_CUSTOMER_SUCCESS = "GET_PARTNER_CUSTOMER_SUCCESS";
export const GET_PARTNER_CUSTOMER_FAILURE = "GET_PARTNER_CUSTOMER_FAILURE";

export const ADD_PARTNER_OPPORTUNITY_REQUEST =
  "ADD_PARTNER_OPPORTUNITY_REQUEST";
export const ADD_PARTNER_OPPORTUNITY_SUCCESS =
  "ADD_PARTNER_OPPORTUNITY_SUCCESS";
export const ADD_PARTNER_OPPORTUNITY_FAILURE =
  "ADD_PARTNER_OPPORTUNITY_FAILURE";

export const GET_PARTNER_OPPORTUNITY_REQUEST =
  "GET_PARTNER_OPPORTUNITY_REQUEST";
export const GET_PARTNER_OPPORTUNITY_SUCCESS =
  "GET_PARTNER_OPPORTUNITY_SUCCESS";
export const GET_PARTNER_OPPORTUNITY_FAILURE =
  "GET_PARTNER_OPPORTUNITY_FAILURE";

export const UPDATE_PARTNER_OPPORTUNITY_REQUEST =
  "UPDATE_PARTNER_OPPORTUNITY_REQUEST";
export const UPDATE_PARTNER_OPPORTUNITY_SUCCESS =
  "UPDATE_PARTNER_OPPORTUNITY_SUCCESS";
export const UPDATE_PARTNER_OPPORTUNITY_FAILURE =
  "UPDATE_PARTNER_OPPORTUNITY_FAILURE";

export const UPDATE_PARTNER_CUSTOMER_REQUEST =
  "UPDATE_PARTNER_CUSTOMER_REQUEST";
export const UPDATE_PARTNER_CUSTOMER_SUCCESS =
  "UPDATE_PARTNER_CUSTOMER_SUCCESS";
export const UPDATE_PARTNER_CUSTOMER_FAILURE =
  "UPDATE_PARTNER_CUSTOMER_FAILURE";

export const REGISTER_PARTNER_OPPORTUNITY_REQUEST =
  "REGISTER_PARTNER_OPPORTUNITY_REQUEST";
export const REGISTER_PARTNER_OPPORTUNITY_SUCCESS =
  "REGISTER_PARTNER_OPPORTUNITY_SUCCESS";
export const REGISTER_PARTNER_OPPORTUNITY_FAILURE =
  "REGISTER_PARTNER_OPPORTUNITY_FAILURE";

export const REGISTER_PARTNER_CUSTOMER_REQUEST =
  "REGISTER_PARTNER_CUSTOMER_REQUEST";
export const REGISTER_PARTNER_CUSTOMER_SUCCESS =
  "REGISTER_PARTNER_CUSTOMER_SUCCESS";
export const REGISTER_PARTNER_CUSTOMER_FAILURE =
  "REGISTER_PARTNER_CUSTOMER_FAILURE";

export const GET_COMPANY_REQUEST = "GET_COMPANY_REQUEST";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_FAILURE = "GET_COMPANY_FAILURE";
