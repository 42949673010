export const GET_OPPORTUNITY_RELATED_DATA_WITH_PROGRESS_FOR_DASHBOARD_REQUEST =
  "GET_OPPORTUNITY_RELATED_DATA_WITH_PROGRESS_FOR_DASHBOARD_REQUEST";
export const GET_OPPORTUNITY_RELATED_DATA_WITH_PROGRESS_FOR_DASHBOARD_SUCCESS =
  "GET_OPPORTUNITY_RELATED_DATA_WITH_PROGRESS_FOR_DASHBOARD_SUCCESS";
export const GET_OPPORTUNITY_RELATED_DATA_WITH_PROGRESS_FOR_DASHBOARD_FAILURE =
  "GET_OPPORTUNITY_RELATED_DATA_WITH_PROGRESS_FOR_DASHBOARD_FAILURE";

export const SET_DASHBOARD_TYPE = "SET_DASHBOARD_TYPE";
export const CHANGE_SELECTED_TIME_INTERVAL = "CHANGE_SELECTED_TIME_INTERVAL";
export const SET_TIME_INTERVAL = "SET_TIME_INTERVAL";
export const SET_FISCAL_TIME_INTERVAL = "SET_FISCAL_TIME_INTERVAL";

export const GET_OPPORTUNITY_RELATED_DATA_REQUEST =
  "GET_OPPORTUNITY_RELATED_DATA_REQUEST";
export const GET_OPPORTUNITY_RELATED_DATA_SUCCESS =
  "GET_OPPORTUNITY_RELATED_DATA_SUCCESS";
export const GET_OPPORTUNITY_RELATED_DATA_FAILURE =
  "GET_OPPORTUNITY_RELATED_DATA_FAILURE";

export const GET_OPPORTUNITY_RELATED_DATA_OF_ALL_USERS_REQUEST =
  "GET_OPPORTUNITY_RELATED_DATA_OF_ALL_USERS_REQUEST";
export const GET_OPPORTUNITY_RELATED_DATA_OF_ALL_USERS_SUCCESS =
  "GET_OPPORTUNITY_RELATED_DATA_OF_ALL_USERS_SUCCESS";
export const GET_OPPORTUNITY_RELATED_DATA_OF_ALL_USERS_FAILURE =
  "GET_OPPORTUNITY_RELATED_DATA_OF_ALL_USERS_FAILURE";

export const GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_REQUEST =
  "GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_REQUEST";
export const GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_SUCCESS =
  "GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_SUCCESS";
export const GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_FAILURE =
  "GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_FAILURE";

export const GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_FOR_QUARTOR_REQUEST =
  "GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_FOR_QUARTOR_REQUEST";
export const GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_FOR_QUARTOR_SUCCESS =
  "GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_FOR_QUARTOR_SUCCESS";
export const GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_FOR_QUARTOR_FAILURE =
  "GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_FOR_QUARTOR_FAILURE";

export const GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_OF_ALL_USERS_REQUEST =
  "GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_OF_ALL_USERS_REQUEST";
export const GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_OF_ALL_USERS_SUCCESS =
  "GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_OF_ALL_USERS_SUCCESS";
export const GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_OF_ALL_USERS_FAILURE =
  "GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_OF_ALL_USERS_FAILURE";

export const GET_ACCOUNT_DATA_FOR_CHARTS_REQUEST =
  "GET_ACCOUNT_DATA_FOR_CHARTS_REQUEST";
export const GET_ACCOUNT_DATA_FOR_CHARTS_SUCCESS =
  "GET_ACCOUNT_DATA_FOR_CHARTS_SUCCESS";
export const GET_ACCOUNT_DATA_FOR_CHARTS_FAILURE =
  "GET_ACCOUNT_DATA_FOR_CHARTS_FAILURE";

export const GET_ACCOUNT_DATA_FOR_CHARTS_OF_ALL_USERS_REQUEST =
  "GET_ACCOUNT_DATA_FOR_CHARTS_OF_ALL_USERS_REQUEST";
export const GET_ACCOUNT_DATA_FOR_CHARTS_OF_ALL_USERS_SUCCESS =
  "GET_ACCOUNT_DATA_FOR_CHARTS_OF_ALL_USERS_SUCCESS";
export const GET_ACCOUNT_DATA_FOR_CHARTS_OF_ALL_USERS_FAILURE =
  "GET_ACCOUNT_DATA_FOR_CHARTS_OF_ALL_USERS_FAILURE";

export const GET_DASHBOARD_FUNNEL_REQUEST = "GET_DASHBOARD_FUNNEL_REQUEST";
export const GET_DASHBOARD_FUNNEL_SUCCESS = "GET_DASHBOARD_FUNNEL_SUCCESS";
export const GET_DASHBOARD_FUNNEL_FAILURE = "GET_DASHBOARD_FUNNEL_FAILURE";

export const GET_DASHBOARD_FOR_QUARTER_FUNNEL_REQUEST =
  "GET_DASHBOARD_FOR_QUARTER_FUNNEL_REQUEST";
export const GET_DASHBOARD_FOR_QUARTOR_FUNNEL_SUCCESS =
  "GET_DASHBOARD_FOR_QUARTOR_FUNNEL_SUCCESS";
export const GET_DASHBOARD_FOR_QUARTOR_FUNNEL_FAILURE =
  "GET_DASHBOARD_FOR_QUARTOR_FUNNEL_FAILURE";

export const GET_DASHBOARD_FUNNEL_OF_ALL_USERS_REQUEST =
  "GET_DASHBOARD_FUNNEL_OF_ALL_USERS_REQUEST";
export const GET_DASHBOARD_FUNNEL_OF_ALL_USERS_SUCCESS =
  "GET_DASHBOARD_FUNNEL_OF_ALL_USERS_SUCCESS";
export const GET_DASHBOARD_FUNNEL_OF_ALL_USERS_FAILURE =
  "GET_DASHBOARD_FUNNEL_OF_ALL_USERS_FAILURE";

export const GET_DASHBOARD_FUNNEL_OF_ALL_USERS_FOR_QUARTOR_REQUEST =
  "GET_DASHBOARD_FUNNEL_OF_ALL_USERS_FOR_QUARTOR_REQUEST";
export const GET_DASHBOARD_FUNNEL_OF_ALL_USERS_FOR_QUARTOR_SUCCESS =
  "GET_DASHBOARD_FUNNEL_OF_ALL_USERS_FOR_QUARTOR_SUCCESS";
export const GET_DASHBOARD_FUNNEL_OF_ALL_USERS_FOR_QUARTOR_FAILURE =
  "GET_DASHBOARD_FUNNEL_OF_ALL_USERS_FOR_QUARTOR_FAILURE";

export const GET_TODOS_REQUEST = "GET_TODOS_REQUEST";
export const GET_TODOS_SUCCESS = "GET_TODOS_SUCCESS";
export const GET_TODOS_FAILURE = "GET_TODOS_FAILURE";

export const UPDATE_TODO_CALL_BY_ID_REQUEST = "UPDATE_TODO_CALL_BY_ID_REQUEST";
export const UPDATE_TODO_CALL_BY_ID_SUCCESS = "UPDATE_TODO_CALL_BY_ID_SUCCESS";
export const UPDATE_TODO_CALL_BY_ID_FAILURE = "UPDATE_TODO_CALL_BY_ID_FAILURE";

export const UPDATE_TODO_EVENT_BY_ID_REQUEST =
  "UPDATE_TODO_EVENT_BY_ID_REQUEST";
export const UPDATE_TODO_EVENT_BY_ID_SUCCESS =
  "UPDATE_TODO_EVENT_BY_ID_SUCCESS";
export const UPDATE_TODO_EVENT_BY_ID_FAILURE =
  "UPDATE_TODO_EVENT_BY_ID_FAILURE";

export const GET_TODOS_PREVIOUS_REQUEST = "GET_TODOS_PREVIOUS_REQUEST";
export const GET_TODOS_PREVIOUS_SUCCESS = "GET_TODOS_PREVIOUS_SUCCESS";
export const GET_TODOS_PREVIOUS_FAILURE = "GET_TODOS_PREVIOUS_FAILURE";

export const GET_TODOS_UPCOMING_REQUEST = "GET_TODOS_UPCOMING_REQUEST";
export const GET_TODOS_UPCOMING_SUCCESS = "GET_TODOS_UPCOMING_SUCCESS";
export const GET_TODOS_UPCOMING_FAILURE = "GET_TODOS_UPCOMING`_FAILURE";

export const TOGGLE_TODO_DRAWER = "TOGGLE_TODO_DRAWER";
export const UPDATE_TODOEVENT_SUCCESS = "UPDATE_TODOEVENT_SUCCESS";

export const GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_OF_ALL_USERS_ORG_REQUEST =
  "GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_OF_ALL_USERS_ORG_REQUEST";
export const GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_OF_ALL_USERS_ORG_SUCCESS =
  "GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_OF_ALL_USERS_ORG_SUCCESS";
export const GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_OF_ALL_USERS_ORG_FAILURE =
  "GET_OPPORTUNITY_RELATED_DATA_WITH_PROFESSIONALGRESS_OF_ALL_USERS_ORG_FAILURE";

export const HANDLE_WEIGHTED_MODAL = "HANDLE_WEIGHTED_MODAL";
export const HANDLE_WIN_MODAL = "HANDLE_WIN_MODAL";
export const HANDLE_WON_MODAL = "HANDLE_WON_MODAL";
export const HANDLE_CUSTOMER_MODAL = "HANDLE_CUSTOMER_MODAL";
export const HANDLE_ABSOLUTE_MODAL = "HANDLE_ABSOLUTE_MODAL";

export const HANDLE_CHART_MODAL = "HANDLE_CHART_MODAL";
export const HANDLE_FUNNEL_MODAL = "HANDLE_FUNNEL_MODAL";
export const HANDLE_STAGE_MODAL = "HANDLE_STAGE_MODAL";
export const HANDLE_CONTACTS_MODAL = "HANDLE_CONTACTS_MODAL";
export const HANDLE_INDUSTRY_MODAL = "HANDLE_INDUSTRY_MODAL";
export const HANDLE_EXISTINGACCOUNTS_MODAL = "HANDLE_EXISTINGACCOUNTS_MODAL";

//Account data for Pareto chart by org id
export const GET_ACCOUNT_DATA_FOR_PARETO_CHART_BY_ORG_ID_REQUEST =
  "GET_ACCOUNT_DATA_FOR_PARETO_CHART_BY_ORG_ID_REQUEST";
export const GET_ACCOUNT_DATA_FOR_PARETO_CHART_BY_ORG_ID_SUCCESS =
  "GET_ACCOUNT_DATA_FOR_PARETO_CHART_BY_ORG_ID_SUCCESS";
export const GET_ACCOUNT_DATA_FOR_PARETO_CHART_BY_ORG_ID_FAILURE =
  "GET_ACCOUNT_DATA_FOR_PARETO_CHART_BY_ORG_ID_FAILURE";

export const GET_ACCOUNT_DATA_FOR_PARETO_CHART_BY_USER_ID_REQUEST =
  "GET_ACCOUNT_DATA_FOR_PARETO_CHART_BY_USER_ID_REQUEST";
export const GET_ACCOUNT_DATA_FOR_PARETO_CHART_BY_USER_ID_SUCCESS =
  "GET_ACCOUNT_DATA_FOR_PARETO_CHART_BY_USER_ID_SUCCESS";
export const GET_ACCOUNT_DATA_FOR_PARETO_CHART_BY_USER_ID_FAILURE =
  "GET_ACCOUNT_DATA_FOR_PARETO_CHART_BY_ORG_ID_FAILURE";
