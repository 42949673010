import * as types from "./CustomizationMenuActionTypes";

const initialState = {
    dashDrawerVisible: false,
    // accountDrawerProps: {},
}; 

export const customizationMenuReducer = ( state = initialState, dash) => {
    switch (dash.type) {
        case types.HANDLE_DASH_DRAWER:
            return {
            ...state,
            dashDrawerVisible: dash.payload.isVisible,
            // accountDrawerProps: account.payload.props,
        };
        default:
            return state;
    }
};