export const GET_HELP_VIDEOS_REQUEST = "GET_HELP_VIDEOS_REQUEST";
export const GET_HELP_VIDEOS_SUCCESS = "GET_HELP_VIDEOS_SUCCESS";
export const GET_HELP_VIDEOS_FAILURE = "GET_HELP_VIDEOS_FAILURE";
export const HANDLE_CATALOGUE_MODAL = "HANDLE_CATALOGUE_MODAL";
export const HANDLE_SUBSCRIPTION_MODAL = "HANDLE_SUBSCRIPTION_MODAL";
export const HANDLE_ACCOUNT_MODAL = "HANDLE_ACCOUNT_MODAL";
export const HANDLE_MANAGE_MODAL = "HANDLE_MANAGE_MODAL";
export const HANDLE_RECRUIT_MODAL = "HANDLE_RECRUIT_MODAL";
export const HANDLE_EXCEL_MODAL = "HANDLE_EXCEL_MODAL";
export const HANDLE_VIEWPORT_MODAL = "HANDLE_VIEWPORT_MODAL";
export const HANDLE_HELPDOCUMENT_MODAL = "HANDLE_HELPDOCUMENT_MODAL";
export const HANDLE_HELPYOUTUBE_MODAL = "HANDLE_HELPYOUTUBE_MODAL";
export const HANDLE_HELPINFO_MODAL = "HANDLE_HELPINFO_MODAL";
export const GET_HELP_DOCUMENTS_REQUEST = "GET_HELP_DOCUMENTS_REQUEST";
export const GET_HELP_DOCUMENTS_SUCCESS = "GET_HELP_DOCUMENTS_SUCCESS";
export const GET_HELP_DOCUMENTS_FAILURE = "GET_HELP_DOCUMENTS_FAILURE";
export const ADD_HELP_DOCUMENTS_REQUEST = "ADD_HELP_DOCUMENTS_REQUEST";
export const ADD_HELP_DOCUMENTS_SUCCESS = "ADD_HELP_DOCUMENTS_SUCCESS";
export const ADD_HELP_DOCUMENTS_FAILURE = "ADD_HELP_DOCUMENTS_FAILURE";
export const DELETE_HELP_DOCUMENTS_REQUEST = "DELETE_HELP_DOCUMENTS_REQUEST";
export const DELETE_HELP_DOCUMENTS_SUCCESS = "DELETE_HELP_DOCUMENTS_SUCCESS";
export const DELETE_HELP_DOCUMENTS_FAILURE = "DELETE_HELP_DOCUMENTS_FAILURE";
export const HANDLE_YOUTUBE_PLAYER_MODAL = "HANDLE_YOUTUBE_PLAYER_MODAL";
export const SET_DOCUMENT_EDIT = "SET_DOCUMENT_EDIT";
export const SET_YOUTUBE_EDIT = "SET_YOUTUBE_EDIT";
export const HANDLE_EDIT_PLAYBOOK_DOCUMENT_MODAL =
  "HANDLE_EDIT_PLAYBOOK_DOCUMENT_MODAL";

export const HANDLE_EDIT_PLAYBOOK_YOUTUBE_MODAL =
  "HANDLE_EDIT_PLAYBOOK_YOUTUBE_MODAL";

export const ADD_YOUTUBE_DOCUMENTS_REQUEST = "ADD_YOUTUBE_DOCUMENTS_REQUEST";
export const ADD_YOUTUBE_DOCUMENTS_SUCCESS = "ADD_YOUTUBE_DOCUMENTS_SUCCESS";
export const ADD_YOUTUBE_DOCUMENTS_FAILURE = "ADD_YOUTUBE_DOCUMENTS_FAILURE";

export const GET_YOUTUBE_DOCUMENTS_REQUEST = "GET_YOUTUBE_DOCUMENTS_REQUEST";
export const GET_YOUTUBE_DOCUMENTS_SUCCESS = "GET_YOUTUBE_DOCUMENTS_SUCCESS";
export const GET_YOUTUBE_DOCUMENTS_FAILURE = "GET_YOUTUBE_DOCUMENTS_FAILURE";

export const GET_PLAYBOOK_PROCESS_STAGES_REQUEST =
  "GET_PLAYBOOK_PROCESS_STAGES_REQUEST";
export const GET_PLAYBOOK_PROCESS_STAGES_SUCCESS =
  "GET_PLAYBOOK_PROCESS_STAGES_SUCCESS";
export const GET_PLAYBOOK_PROCESS_STAGES_FAILURE =
  "GET_PLAYBOOK_PROCESS_STAGES_FAILURE";

export const DELETE_DOCUMENT_REQUEST = "DELETE_DOCUMENT_REQUEST";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAILURE = "DELETE_DOCUMENT_FAILURE";

export const GET_GENERAL_DATA_REQUEST = "GET_GENERAL_DATA_REQUEST";
export const GET_GENERAL_DATA_SUCCESS = "GET_GENERAL_DATA_SUCCESS";
export const GET_GENERAL_DATA_FAILURE = "GET_GENERAL_DATA_FAILURE";


export const GET_HELP_PARTNER_DOCUMENTS_REQUEST = "GET_HELP_PARTNER_DOCUMENTS_REQUEST";
export const GET_HELP_PARTNER_DOCUMENTS_SUCCESS = "GET_HELP_PARTNER_DOCUMENTS_SUCCESS";
export const GET_HELP_PARTNER_DOCUMENTS_FAILURE = "GET_HELP_PARTNER_DOCUMENTS_FAILURE";

export const GET_YOUTUBE_PARTNER_DOCUMENTS_REQUEST = "GET_YOUTUBE_PARTNER_DOCUMENTS_REQUEST";
export const GET_YOUTUBE_PARTNER_DOCUMENTS_SUCCESS = "GET_YOUTUBE_PARTNER_DOCUMENTS_SUCCESS";
export const GET_YOUTUBE_PARTNER_DOCUMENTS_FAILURE = "GET_YOUTUBE_PARTNER_DOCUMENTS_FAILURE";

export const GET_PLAYBOOK_PARTNER_PROCESS_STAGES_REQUEST = "GET_PLAYBOOK_PARTNER_PROCESS_STAGES_REQUEST";
export const GET_PLAYBOOK_PARTNER_PROCESS_STAGES_SUCCESS = "GET_PLAYBOOK_PARTNER_PROCESS_STAGES_SUCCESS";
export const GET_PLAYBOOK_PARTNER_PROCESS_STAGES_FAILURE = "GET_PLAYBOOK_PARTNER_PROCESS_STAGES_FAILURE";

export const GET_PARTNER_GENERAL_DATA_REQUEST = "GET_PARTNER_GENERAL_DATA_REQUEST";
export const GET_PARTNER_GENERAL_DATA_SUCCESS = "GET_PARTNER_GENERAL_DATA_SUCCESS";
export const GET_PARTNER_GENERAL_DATA_FAILURE = "GET_PARTNER_GENERAL_DATA_FAILURE";