import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import { Button } from "antd";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import { base_url } from "../../../Config/Auth";
import { FlexContainer } from "../../UI/Layout";
import { Spacer } from "../../UI/Elements";
import { InputComponent } from "../Formik/InputComponent";
import FormikPlacesAutoComplete from "../Formik/FormikPlacesAutoComplete";
import { addAccountAddress } from "../../../Containers/Account/AccountAction";
import { addPartnerAddress } from "../../../Containers/Partner/PartnerAction";
import { updateContactAddress } from "../../../Containers/Contact/ContactAction";
import { updateUserAddress } from "../../../Containers/Auth/AuthAction";
class AddAddressField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      isSubmittingError: false,
    };
  }

  addAddress = (address) => {
    const {
      account: { accountId },

      addAccountAddress,

      toggleAdd,
    } = this.props;
    this.setState({ isSubmitting: true });

    axios
      .post(`${base_url}/address/account/${accountId}`, address, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      })
      .then((res) => {
        addAccountAddress(res.data);
        this.setState({ isSubmitting: false });
        toggleAdd();
        console.log(res);
      })
      .catch((err) => {
        toggleAdd();
        this.setState({ isSubmitting: false, isSubmittingError: true });
        console.log(err);
      });
  };

  partnerAddAddress = (address) => {
    const {
      partner: { partnerId },

      addPartnerAddress,

      toggleAdd,
    } = this.props;
    this.setState({ isSubmitting: true });

    axios
      .post(`${base_url}/address/partner/${partnerId}`, address, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      })
      .then((res) => {
        addPartnerAddress(res.data);
        this.setState({ isSubmitting: false });
        toggleAdd();
        console.log(res);
      })
      .catch((err) => {
        toggleAdd();
        this.setState({ isSubmitting: false, isSubmittingError: true });
        console.log(err);
      });
  };

  render() {
    const {
      components: {},
      partnerAdd,
    } = this.props;

    return (
      <>
        <Formik
          initialValues={{
            address: {
              address1: "",
              address2: "",
              street: "",
              town: "",
              city: "",
              state: "",
              country: "",
              postalCode: "",
              latitude: "",
              longitude: "",
            },
          }}
          onSubmit={(values) => {
            partnerAdd
              ? this.partnerAddAddress(values.address)
              : this.addAddress(values.address);
          }}
        >
          {({
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            values,
            ...rest
          }) => (
            <Form>
              <Field
                name={`address`}
                noLabel
                component={FormikPlacesAutoComplete}
                options={{}}
              />
              <Spacer />
              {/* <Field
                name={`address.addressType`}
                noLabel
                placeholder='Address type'
                component={SelectComponent}
                options={['Office', 'Communication', 'Headquarters', 'Registered']}
              /> */}
              <Field
                noLabel
                placeholder="Address 1"
                name="address.address1"
                component={InputComponent}
                // defaultValue='low'
              />
              <Field
                noLabel
                placeholder="Address 2"
                name="address.address2"
                component={InputComponent}
                // defaultValue='low'
              />
              <Field
                noLabel
                placeholder="Street"
                name="address.street"
                component={InputComponent}
                // defaultValue='low'
              />
              <Field
                noLabel
                placeholder="Town"
                name="address.town"
                component={InputComponent}
                // defaultValue='low'
              />
              <Field
                noLabel
                placeholder="City"
                name="address.city"
                component={InputComponent}
                // defaultValue='low'
              />
              <Field
                noLabel
                placeholder="State"
                name="address.state"
                component={InputComponent}
                // defaultValue='low'
              />
              <Field
                noLabel
                placeholder="Country"
                name="address.country"
                component={InputComponent}
                // defaultValue='low'
              />
              <Field
                noLabel
                placeholder="Zip code"
                name="address.postalCode"
                component={InputComponent}
                // defaultValue='low'
              />
              <Spacer />
              <FlexContainer justifyContent="flex-end">
                <Button type="primary" htmlType="submit" loading={isSubmitting}>
                <FormattedMessage
                 id="app.save"
                 defaultMessage="Save"
                />
                </Button>
                &nbsp;
                <Button type="default" onClick={this.props.toggleAdd}>
                  {/* Cancel */}
                  <FormattedMessage
                   id="app.cancel"
                   defaultMessage="Cancel"
                   />
                </Button>
              </FlexContainer>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}
const mapStateToProps = ({ account, partner }) => ({
  account: account.account,
  partner: partner.partner,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateContactAddress,
      addAccountAddress,
      updateUserAddress,
      addPartnerAddress,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddAddressField);
