export const ADD_SLA_REQUEST = "ADD_SLA_REQUEST";
export const ADD_SLA_SUCCESS = "ADD_SLA_SUCCESS";
export const ADD_SLA_FAILURE = "ADD_SLA_FAILURE";

export const GET_SLA_REQUEST = "GET_SLA_REQUEST";
export const GET_SLA_SUCCESS = "GET_SLA_SUCCESS";
export const GET_SLA_FAILURE = "GET_SLA_FAILURE";

export const ADD_GENERATE_OPPORTUNITY_REQUEST =
  "ADD_GENERATE_OPPORTUNITY_REQUEST";
export const ADD_GENERATE_OPPORTUNITY_SUCCESS =
  "ADD_GENERATE_OPPORTUNITY_SUCCESS";
export const ADD_GENERATE_OPPORTUNITY_FAILURE =
  "ADD_GENERATE_OPPORTUNITY_FAILURE";

export const GET_GENERATE_OPPORTUNITY_REQUEST =
  "GET_GENERATE_OPPORTUNITY_REQUEST";
export const GET_GENERATE_OPPORTUNITY_SUCCESS =
  "GET_GENERATE_OPPORTUNITY_SUCCESS";
export const GET_GENERATE_OPPORTUNITY_FAILURE =
  "GET_GENERATE_OPPORTUNITY_FAILURE";

export const ADD_MATRIX_REQUEST = "ADD_MATRIX_REQUEST";
export const ADD_MATRIX_SUCCESS = "ADD_MATRIX_SUCCESS";
export const ADD_MATRIX_FAILURE = "ADD_MATRIX_FAILURE";

export const GET_MATRIX_REQUEST = "GET_MATRIX_REQUEST";
export const GET_MATRIX_SUCCESS = "GET_MATRIX_SUCCESS";
export const GET_MATRIX_FAILURE = "GET_MATRIX_FAILURE";

export const HANDLE_TEMPLATE_MODAL = "HANDLE_TEMPLATE_MODAL";

export const ADD_TEMPLATE_REQUEST = "ADD_TEMPLATE_REQUEST";
export const ADD_TEMPLATE_SUCCESS = "ADD_TEMPLATE_SUCCESS";
export const ADD_TEMPLATE_FAILURE = "ADD_TEMPLATE_FAILURE";

export const GET_TEMPLATE_REQUEST = "GET_TEMPLATE_REQUEST";
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";
export const GET_TEMPLATE_FAILURE = "GET_TEMPLATE_FAILURE";

export const UPDATE_TEMPLATE_REQUEST = "UPDATE_TEMPLATE_REQUEST";
export const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS";
export const UPDATE_TEMPLATE_FAILURE = "UPDATE_TEMPLATE_FAILURE";

export const ADD_NOTIFICATION_TEMPLATE_REQUEST =
  "ADD_NOTIFICATION_TEMPLATE_REQUEST";
export const ADD_NOTIFICATION_TEMPLATE_SUCCESS =
  "ADD_NOTIFICATION_TEMPLATE_SUCCESS";
export const ADD_NOTIFICATION_TEMPLATE_FAILURE =
  "ADD_NOTIFICATION_TEMPLATE_FAILURE";

export const GET_NOTIFICATION_TEMPLATE_REQUEST =
  "GET_NOTIFICATION_TEMPLATE_REQUEST";
export const GET_NOTIFICATION_TEMPLATE_SUCCESS =
  "GET_NOTIFICATION_TEMPLATE_SUCCESS";
export const GET_NOTIFICATION_TEMPLATE_FAILURE =
  "GET_NOTIFICATION_TEMPLATE_FAILURE";

export const UPDATE_NOTIFICATION_TEMPLATE_REQUEST =
  "UPDATE_NOTIFICATION_TEMPLATE_REQUEST";
export const UPDATE_NOTIFICATION_TEMPLATE_SUCCESS =
  "UPDATE_NOTIFICATION_TEMPLATE_SUCCESS";
export const UPDATE_NOTIFICATION_TEMPLATE_FAILURE =
  "UPDATE_NOTIFICATION_TEMPLATE_FAILURE";

export const SET_CURRENT_EMAIL = "SET_CURRENT_EMAIL";
export const SET_CURRENT_NOTIFICATION = "SET_CURRENT_NOTIFICATION";

export const ADD_LEAD_AGING_REQUEST = "ADD_LEAD_AGING_REQUEST";
export const ADD_LEAD_AGING_SUCCESS = "ADD_LEAD_AGING_SUCCESS";
export const ADD_LEAD_AGING_FAILURE = "ADD_LEAD_AGING_FAILURE";

export const GET_LEAD_AGING_REQUEST = "GET_LEAD_AGING_REQUEST";
export const GET_LEAD_AGING_SUCCESS = "GET_LEAD_AGING_SUCCESS";
export const GET_LEAD_AGING_FAILURE = "GET_LEAD_AGING_FAILURE";

export const ADD_SENDPULSE_REQUEST = "ADD_SENDPULSE_REQUEST";
export const ADD_SENDPULSE_SUCCESS = "ADD_SENDPULSE_SUCCESS";
export const ADD_SENDPULSE_FAILURE = "ADD_SENDPULSE_FAILURE";

export const GET_SENDPULSE_REQUEST = "GET_SENDPULSE_REQUEST";
export const GET_SENDPULSE_SUCCESS = "GET_SENDPULSE_SUCCESS";
export const GET_SENDPULSE_FAILURE = "GET_SENDPULSE_FAILURE";

export const ADD_AUTO_APPROVAL_REQUEST = "ADD_AUTO_APPROVAL_REQUEST";
export const ADD_AUTO_APPROVAL_SUCCESS = "ADD_AUTO_APPROVAL_SUCCESS";
export const ADD_AUTO_APPROVAL_FAILURE = "ADD_AUTO_APPROVAL_FAILURE";

export const GET_AUTO_APPROVAL_REQUEST = "GET_AUTO_APPROVAL_REQUEST";
export const GET_AUTO_APPROVAL_SUCCESS = "GET_AUTO_APPROVAL_SUCCESS";
export const GET_AUTO_APPROVAL_FAILURE = "GET_AUTO_APPROVAL_FAILURE";

//Qutotation For Email
export const ADD_QUOTATION_FOR_EMAIL_REQUEST =
  "ADD_QUOTATION_FOR_EMAIL_REQUEST";
export const ADD_QUOTATION_FOR_EMAIL_SUCCESS =
  "ADD_QUOTATION_FOR_EMAIL_SUCCESS";
export const ADD_QUOTATION_FOR_EMAIL_FAILURE =
  "ADD_QUOTATION_FOR_EMAIL_FAILURE";

export const GET_QUOTATION_FOR_EMAIL_REQUEST =
  "GET_QUOTATION_FOR_EMAIL_REQUEST";
export const GET_QUOTATION_FOR_EMAIL_SUCCESS =
  "GET_QUOTATION_FOR_EMAIL_SUCCESS";
export const GET_QUOTATION_FOR_EMAIL_FAILURE =
  "GET_QUOTATION_FOR_EMAIL_FAILURE";

//Qutotation For Notification
export const ADD_QUOTATION_FOR_NOTIFICATION_REQUEST =
  "ADD_QUOTATION_FOR_NOTIFICATION_REQUEST";
export const ADD_QUOTATION_FOR_NOTIFICATION_SUCCESS =
  "ADD_QUOTATION_FOR_NOTIFICATION_SUCCESS";
export const ADD_QUOTATION_FOR_NOTIFICATION_FAILURE =
  "ADD_QUOTATION_FOR_NOTIFICATION_FAILURE";

export const GET_QUOTATION_FOR_NOTIFICATION_REQUEST =
  "GET_QUOTATION_FOR_NOTIFICATION_REQUEST";
export const GET_QUOTATION_FOR_NOTIFICATION_SUCCESS =
  "GET_QUOTATION_FOR_NOTIFICATION_SUCCESS";
export const GET_QUOTATION_FOR_NOTIFICATION_FAILURE =
  "GET_QUOTATION_FOR_NOTIFICATION_FAILURE";

//INVOICE For Email
export const ADD_INVOICE_FOR_EMAIL_REQUEST = "ADD_INVOICE_FOR_EMAIL_REQUEST";
export const ADD_INVOICE_FOR_EMAIL_SUCCESS = "ADD_INVOICE_FOR_EMAIL_SUCCESS";
export const ADD_INVOICE_FOR_EMAIL_FAILURE = "ADD_INVOICE_FOR_EMAIL_FAILURE";

export const GET_INVOICE_FOR_EMAIL_REQUEST = "GET_INVOICE_FOR_EMAIL_REQUEST";
export const GET_INVOICE_FOR_EMAIL_SUCCESS = "GET_INVOICE_FOR_EMAIL_SUCCESS";
export const GET_INVOICE_FOR_EMAIL_FAILURE = "GET_INVOICE_FOR_EMAIL_FAILURE";

//INVOICE For Notification
export const ADD_INVOICE_FOR_NOTIFICATION_REQUEST =
  "ADD_INVOICE_FOR_NOTIFICATION_REQUEST";
export const ADD_INVOICE_FOR_NOTIFICATION_SUCCESS =
  "ADD_INVOICE_FOR_NOTIFICATION_SUCCESS";
export const ADD_INVOICE_FOR_NOTIFICATION_FAILURE =
  "ADD_INVOICE_FOR_NOTIFICATION_FAILURE";

export const GET_INVOICE_FOR_NOTIFICATION_REQUEST =
  "GET_INVOICE_FOR_NOTIFICATION_REQUEST";
export const GET_INVOICE_FOR_NOTIFICATION_SUCCESS =
  "GET_INVOICE_FOR_NOTIFICATION_SUCCESS";
export const GET_INVOICE_FOR_NOTIFICATION_FAILURE =
  "GET_INVOICE_FOR_NOTIFICATION_FAILURE";

//RecruitPro For Email
export const ADD_RECRUITPRO_FOR_EMAIL_REQUEST =
  "ADD_RECRUITPRO_FOR_EMAIL_REQUEST";
export const ADD_RECRUITPRO_FOR_EMAIL_SUCCESS =
  "ADD_RECRUITPRO_FOR_EMAIL_SUCCESS";
export const ADD_RECRUITPRO_FOR_EMAIL_FAILURE =
  "ADD_RECRUITPRO_FOR_EMAIL_FAILURE";

export const GET_RECRUITPRO_FOR_EMAIL_REQUEST =
  "GET_RECRUITPRO_FOR_EMAIL_REQUEST";
export const GET_RECRUITPRO_FOR_EMAIL_SUCCESS =
  "GET_RECRUITPRO_FOR_EMAIL_SUCCESS";
export const GET_RECRUITPRO_FOR_EMAIL_FAILURE =
  "GET_RECRUITPRO_FOR_EMAIL_FAILURE";

export const ADD_SEARCH_TAB_REQUEST = "ADD_SEARCH_TAB_REQUEST";
export const ADD_SEARCH_TAB_SUCCESS = "ADD_SEARCH_TAB_SUCCESS";
export const ADD_SEARCH_TAB_FAILURE = "ADD_SEARCH_TAB_FAILURE";

export const GET_SEARCH_TAB_REQUEST = "GET_SEARCH_TAB_REQUEST";
export const GET_SEARCH_TAB_SUCCESS = "GET_SEARCH_TAB_SUCCESS";
export const GET_SEARCH_TAB_FAILURE = "GET_SEARCH_TAB_FAILURE";

//Leads For Email
export const ADD_LEADS_FOR_EMAIL_REQUEST = "ADD_LEADS_FOR_EMAIL_REQUEST";
export const ADD_LEADS_FOR_EMAIL_SUCCESS = "ADD_LEADS_FOR_EMAIL_SUCCESS";
export const ADD_LEADS_FOR_EMAIL_FAILURE = "ADD_LEADS_FOR_EMAIL_FAILURE";

export const GET_LEADS_FOR_EMAIL_REQUEST = "GET_LEADS_FOR_EMAIL_REQUEST";
export const GET_LEADS_FOR_EMAIL_SUCCESS = "GET_LEADS_FOR_EMAIL_SUCCESS";
export const GET_LEADS_FOR_EMAIL_FAILURE = "GET_LEADS_FOR_EMAIL_FAILURE";

//Leads For Notification
export const ADD_LEADS_FOR_NOTIFICATION_REQUEST =
  "ADD_LEADS_FOR_NOTIFICATION_REQUEST";
export const ADD_LEADS_FOR_NOTIFICATION_SUCCESS =
  "ADD_LEADS_FOR_NOTIFICATION_SUCCESS";
export const ADD_LEADS_FOR_NOTIFICATION_FAILURE =
  "ADD_LEADS_FOR_NOTIFICATION_FAILURE";

export const GET_LEADS_FOR_NOTIFICATION_REQUEST =
  "GET_LEADS_FOR_NOTIFICATION_REQUEST";
export const GET_LEADS_FOR_NOTIFICATION_SUCCESS =
  "GET_LEADS_FOR_NOTIFICATION_SUCCESS";
export const GET_LEADS_FOR_NOTIFICATION_FAILURE =
  "GET_LEADS_FOR_NOTIFICATION_FAILURE";

export const HANDLE_SEQUENCE_MODAL = "HANDLE_SEQUENCE_MODAL";
export const HANDLE_WEBSITE_MODAL = "HANDLE_WEBSITE_MODAL";

export const ADD_SEQUENCE_LEADS_REQUEST = "ADD_SEQUENCE_LEADS_REQUEST";
export const ADD_SEQUENCE_LEADS_SUCCESS = "ADD_SEQUENCE_LEADS_SUCCESS";
export const ADD_SEQUENCE_LEADS_FAILURE = "ADD_SEQUENCE_LEADS_FAILURE";

export const GET_SEQUENCE_LEADS_REQUEST = "GET_SEQUENCE_LEADS_REQUEST";
export const GET_SEQUENCE_LEADS_SUCCESS = "GET_SEQUENCE_LEADS_SUCCESS";
export const GET_SEQUENCE_LEADS_FAILURE = "GET_SEQUENCE_LEADS_FAILURE";
//Quote approval under opportunity
export const ADD_QUOTE_APPROVAL_OPPORTUNITY_REQUEST =
  "ADD_QUOTE_APPROVAL_OPPORTUNITY_REQUEST";
export const ADD_QUOTE_APPROVAL_OPPORTUNITY_SUCCESS =
  "ADD_QUOTE_APPROVAL_OPPORTUNITY_SUCCESS";
export const ADD_QUOTE_APPROVAL_OPPORTUNITY_FAILURE =
  "ADD_QUOTE_APPROVAL_OPPORTUNITY_FAILURE";

export const GET_QUOTE_APPROVAL_OPPORTUNITY_REQUEST =
  "GET_QUOTE_APPROVAL_OPPORTUNITY_REQUEST";
export const GET_QUOTE_APPROVAL_OPPORTUNITY_SUCCESS =
  "GET_QUOTE_APPROVAL_OPPORTUNITY_SUCCESS";
export const GET_QUOTE_APPROVAL_OPPORTUNITY_FAILURE =
  "GET_QUOTE_APPROVAL_OPPORTUNITY_FAILURE";
