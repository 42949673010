import * as types from "./AuthTypes";
import { SET_FISCAL_TIME_INTERVAL } from "../Dashboard/DashBoardActionTypes";
import { base_url, login_url } from "../../Config/Auth";
import axios from "axios";
import { message, notification } from "antd";
import { createBrowserHistory } from "history";
import moment from "moment";
import { SET_FISCAL_TIME_INTERVAL_REPORT } from "../Report/ReportActionType";
import { SET_FISCAL_TIME_INTERVAL_TEAM } from "../Teams/TeamsActionTypes";
import { SET_FISCAL_TIME_INTERVAL_VIEWPORT } from "../Viewport/ViewportActionTypes";
const history = createBrowserHistory();

/**
 * toggle url to local or server
 */
export const toggleServer = (server) => (dispatch) => {
  dispatch({
    type: types.TOGGLE_SERVER,
    payload: server,
  });
};
/**
 * user registration goes here, any person can register .
 * after registration success the person will get an email to activate his/her account
 */
export const register = (user) => (dispatch) => {
  dispatch({
    type: types.REGISTER_REQUEST,
  });

  axios
    .post(`${base_url}/registration`, { ...user })
    .then((res) => {
      console.log(res);
      if (res.data.emailInd === true) {
        message.error("Account cannot be created using this user name");
        dispatch({
          type: types.REGISTER_FAILURE,
        });
      } else {
        message.success(
          "Thank you for registering on Korero, Success in closing Opportunities."
        );
        dispatch({
          type: types.REGISTER_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      message.error("Something went wrong!");
      dispatch({
        type: types.REGISTER_FAILURE,
      });
    });
};
/**
 * Email validation goes here
 * this method is a called when user click on the email activation link
 * this method verify the email and if user is verified it send them to set Password page
 */
export const validateEmail = (
  userId,
  token,
  emailId,
  organizationId,
  history
) => (dispatch) => {
  console.log(userId, token, emailId, organizationId);
  dispatch({
    type: types.VALIDATE_EMAIL_REQUEST,
  });

  axios
    .post(`${base_url}/emailValidation`, {
      userId: userId,
      emailId: emailId,
      token: token,
      organizationId: organizationId,
      ipAddress: "103.72.61.3",
    })
    .then((res) => {
      console.log(res);
      if (res.data === true) {
        console.log("email is valid");
        message.success("Your email has been validated successfully.");
        history.push({
          pathname: "/setPassword",
          state: {
            userId: userId,
            emailId: emailId,
            organizationId: organizationId,
            token: token,
          },
        });
        // dispatch({
        //     type: types.VALIDATE_EMAIL_SUCCESS,
        // })
      }
    })
    .catch((err) => {
      console.log(err);
      // dispatch({
      //     type: types.VALIDATE_EMAIL_FAILURE
      // })
    });
};
/**
 * reset password
 * this method is a called when user click on the forgot password link
 * this method verify the email and if user is verified it send them to set Password page
 */
export const resetPassword = (
  userId,
  token,
  emailId,
  organizationId,
  history
) => (dispatch) => {
  console.log(userId, token, emailId, organizationId);

  axios
    .post(`${base_url}/emailValidation`, {
      userId: userId,
      emailId: emailId,
      token: token,
      organizationId: organizationId,
    })
    .then((res) => {
      console.log(res);
      if (res.data === true) {
        history.push({
          pathname: "/setPassword",
          state: {
            userId: userId,
            emailId: emailId,
            organizationId: organizationId,
            token: token,
          },
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const ApproveQuote = (quoteId, history) => (dispatch) => {
  axios
    .get(`${base_url}/approveQuote/${quoteId}`)
    .then((res) => {
      console.log(res);
      if (res.data === true) {
        history.push({
          pathname: "/Approved",
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
export const ApproveQuoteByAdmin = (orgId, quoteId, history) => (dispatch) => {
  axios
    .get(`${base_url}/approveQuote/admin/${orgId}/${quoteId}`)
    .then((res) => {
      console.log(res);
      if (res.data === true) {
        history.push({
          pathname: "/ApprovedByAdmin",
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const OfferValidate = (profileId, history) => (dispatch) => {
  axios
    .get(`${base_url}/accept/offer/candidate/${profileId}`)
    .then((res) => {
      console.log(res);
      if (res.data === true) {
        history.push({
          pathname: "/Offer",
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const SponsorOfferValidate = (profileId, history) => (dispatch) => {
  axios
    .get(`${base_url}/sponser/offer/${profileId}`)
    .then((res) => {
      console.log(res);
      if (res.data === true) {
        history.push({
          pathname: "/SponsorOffer",
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
/**
 * Set Password goes here
 * password is set here and after password set it redirect to login page
 */
export const setPassword = (
  userId,
  organizationId,
  emailId,
  password,
  history
) => (dispatch) => {
  console.log(userId, organizationId, emailId, password);
  axios
    .post(`${base_url}/setPassword`, {
      userId: userId,
      organizationId: organizationId,
      emailId: emailId,
      password: password,
      ipAddress: "103.72.61.3",
    })
    .then((res) => {
      console.log(res);
      if (res.data === true) {
        message.success("Your password has been saved successfully.");
        history.push("/login");
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

/**
 * change Password goes here
 *change your existing password with a new password
 */
export const changePassword = (data, cb) => (dispatch) => {
  console.log(data);
  dispatch({ type: types.CHANGE_PASSWORD_REQUEST });
  axios
    .post(`${base_url}/changePassword`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({ type: types.CHANGE_PASSWORD_SUCCESS });
      cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: types.CHANGE_PASSWORD_FAILURE });
      cb();
    });
};

/**
 * forgot Password goes here
 * password is set here and after password set it redirect to login page
 */
export const forgotPassword = (email) => (dispatch) => {
  axios
    .get(`${base_url}/forgotPassword?email=${email}`, email)
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};
/**
 * login request with username(email) and password
 * after successfull login it store the recieved token to local storage sends to dashboard
 */
export const login = ({ userName, password }, history, cb) => (dispatch) => {
  dispatch({
    type: types.LOGIN_REQUEST,
  });
  axios
    .post(`${login_url}/token/generate-token`, {
      username: userName,
      password: password,
    })
    .then((res) => {
      // message.success('Welcome to FokusWork, great to have you here.')
      console.log(res);
      sessionStorage.setItem("token", res.data.token);

      dispatch(getUserDetails(res.data.token));

      history.push("/");
      dispatch({
        type: types.LOGIN_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err && err.response && err.response.data);
      cb && cb("failure");

      if (
        err &&
        err.response &&
        err.response.data ===
          "You have entered an invalid username or password "
      ) {
        message.error("You have entered an invalid username or password ");
      } else {
        message.error("Oops! something went wrong. Please retry.");
        console.log(err);
        history.push({
          pathname: "/",
        });
      }
      dispatch({
        type: types.LOGIN_FAILURE,
        payload: err,
      });
    });
};
/**

 * get user details after login
 */
export const getUserDetails = (token) => (dispatch) => {
  dispatch({
    type: types.GET_USER_DETAILS_REQUEST,
  });
  axios
    .get(`${base_url}/user/profile`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => {
      console.log(res);
      sessionStorage.setItem("userDetails", JSON.stringify(res.data));
      dispatch({
        type: types.GET_USER_DETAILS_SUCCESS,
        payload: res.data,
      });

      dispatch(setFiscalTimeIntervalReport(res.data));
      dispatch(setFiscalTimeIntervalTeam(res.data));
      dispatch(setFiscalTimeIntervalViewport(res.data));

      dispatch(setFiscalTimeInterval(res.data));

      dispatch(getLoginDetails(res.data.userId));
    })
    .catch((err) => {
      // message.error("Oops, something went wrong during getting user details.");
      console.log(err);
      history.push({
        pathname: "/",
      });
      dispatch({
        type: types.GET_USER_DETAILS_FAILURE,
        payload: err,
      });
    });
};
export const setFiscalTimeInterval = (data) => (dispatch) => {
  dispatch({
    type: SET_FISCAL_TIME_INTERVAL,
    payload: data,
  });
};
export const setFiscalTimeIntervalReport = (data) => (dispatch) => {
  dispatch({
    type: SET_FISCAL_TIME_INTERVAL_REPORT,
    payload: data,
  });
};
export const setFiscalTimeIntervalViewport = (data) => (dispatch) => {
  //debugger;
  dispatch({
    type: SET_FISCAL_TIME_INTERVAL_VIEWPORT,
    payload: data,
  });
};
export const setFiscalTimeIntervalTeam = (data) => (dispatch) => {
  //debugger;
  dispatch({
    type: SET_FISCAL_TIME_INTERVAL_TEAM,
    payload: data,
  });
};

/**
 * update contact address
 * @param {string} userId
 * @param {address object} address
 */
export const updateUserAddress = (userId, address) => (dispatch) => {
  console.log(userId, address);
  // dispatch(getUserDetails(token));
  dispatch({
    type: types.UPDATE_USER_ADDRESS,
    payload: {
      userId,
      address,
    },
  });
};
/**
 * get user details after login
 */
export const getOrganizationDetails = (token) => (dispatch) => {
  dispatch({
    type: types.GET_ORGANIZATION_DETAILS_REQUEST,
  });
  axios
    .get(`${base_url}/organization`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ORGANIZATION_DETAILS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ORGANIZATION_DETAILS_FAILURE,
        payload: err,
      });
    });
};
/**
 * update user details after login
 */
export const updateOrganizationDetails = (organizationId, data, cb) => (
  dispatch
) => {
  console.log(data);
  console.log(organizationId);
  dispatch({
    type: types.UPDATE_ORGANIZATION_DETAILS_REQUEST,
  });
  axios
    .put(
      `${base_url}/organization/${organizationId}`,
      { ...data },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      let userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
      // //////////debugger,
      console.log(userDetails);
      let newUserDetails = {
        ...userDetails,
        metaData: { ...userDetails.metaData, organization: res.data },
      };

      sessionStorage.setItem("userDetails", JSON.stringify(newUserDetails));
      dispatch({
        type: types.UPDATE_ORGANIZATION_DETAILS_SUCCESS,
        payload: res.data,
      });
      cb && cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_ORGANIZATION_DETAILS_FAILURE,
        payload: err,
      });
      cb && cb();
    });
};

/**
 * update oraganization subscription type STARTER/PROFESSIONAL
 */
export const updateSubscriptionType = (subscriptionType) => (dispatch) =>
  dispatch({ type: types.UPDATE_SUBSCRIPTION_TYPE, payload: subscriptionType });

/**
 * get last login detail
 */
export const getLoginDetails = (userId) => (dispatch) => {
  axios
    .get(`${base_url}/loginDetails/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      notification.open({
        placement: "bottomRight",
        message: "Welcome to Korero, great to have you here.",
        description:
          "Last logged on " +
          moment(res.data && res.data[1] && res.data[1].loginDate).format(
            "LLL"
          ),
        style: {
          // backgroundColor: '#1890ff',
          // color: '#fff',
          width: 600,
          marginLeft: 335 - 600,
        },
      });
    })
    .catch((err) => console.log(err));
};
/**
 * logout the user
 * clear token from sessionStorage
 * redirect to login
 */
// export const logout = (history) => (dispatch) => {
//   dispatch({
//     type: types.LOGOUT_REQUEST,
//   });
//   axios
//     .get(`${base_url}/logout`, {
//       headers: {
//         Authorization: "Bearer " + sessionStorage.getItem("token") || "",
//       },
//     })
//     .then((res) => {
//       window.sessionStorage.clear();
//       history.push("/login");
//       message.success("You have successfully logged out. See you soon.");
//       dispatch({
//         type: types.LOGOUT_SUCCESS,
//         payload: res.data,
//       });
//     })
//     .catch((err) => {
//       console.log(err);
//       dispatch({
//         type: types.LOGOUT_FAILURE,
//         payload: err,
//       });
//     });
// };

export const logout = (history) => (dispatch) => {
  window.sessionStorage.clear();
  history.push("/login");
  dispatch({ type: types.LOGOUT });
  message.success("You have successfully logged out. See you soon.");
};
