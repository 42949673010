import * as types from "./ContactActionTypes";
import moment from "moment";
import { handleContactModal } from "./ContactAction";

const initialState = {
  addAbsoluteModal: false,
  addWinModal: false,
  addMeetingsModal: false,
  addCallsModal: false,
  addPieModal: false,
  linkProductModal: false,

  fetchingNoOfEmailsSent: false,
  fetchingNoOfEmailsSentError: false,
  noOfEmails: [],

  addRenewalButtonModal: false,
  addPauseButtonModal: false,

  fetchingRecordsByUserId: false,
  fetchingRecordsByUserIdError: false,
  recordData: {},

  linkingRenewalByContactId: false,
  linkingRenewalByContactIdError: false,

  linkingPauseByContactId: false,
  linkingPauseByContactIdError: false,

  feedbackModal: false,



  dateRangeList: [
    {
      id: 1,
      type: "week",
      value: "1W",
      isSelected: true,
      startDate: moment()
        .startOf("week")
        .toISOString(),
      endDate: moment()
        .endOf("week")
        .toISOString(),
    },
    // { id: 2, type: 'week', value: '4W', isSelected: false, startDate: moment().startOf('week').subtract(4, 'week').toISOString(), endDate: moment().endOf('week').toISOString() },
    {
      id: 2,
      type: "month",
      value: "MTD",
      isSelected: false,
      startDate: moment()
        .startOf("month")
        .toISOString(),
      endDate: moment()
        .endOf("month")
        .toISOString(),
    },
    {
      id: 3,
      type: "quarter",
      value: "QTD",
      isSelected: false,
      startDate: moment()
        .startOf("quarter")
        .toISOString(),
      endDate: moment()
        .endOf("quarter")
        .toISOString(),
    },
    {
      id: 4,
      type: "year",
      value: "FY",
      isSelected: false,
      startDate: moment()
        .startOf("month")
        .toISOString(),
      endDate: moment()
        .endOf("month")
        .toISOString(),
    },
  ],
  isCustomSelected: false,
  startDate: moment()
    .startOf("year")
    .toISOString(),
  endDate: moment()
    .endOf("year")
    .toISOString(),
  contactFilterText: "",
  viewType: "grid",
  contactFilterUser: "all_user",
  addContactModal: false,
  linkContactModal: false,

  addingContact: false,
  addingContactError: false,

  fetchingContactById: false,
  fetchingContactByIdError: false,

  deletingContactById: false,
  deletingContactByIdError: false,

  addLinkContactOrderConfigureModal: false,

  contact: {},

  linkingAccountToContact: false,

  updateContactById: false,
  updateContactByIdError: false,



  fetchingContactListRangeByUserId: false,
  fetchingContactListRangeByUserIdError: false,
  contactListRangeByUserId: [],

  fetchingContactListRangeByUserIdForDashboard: false,
  fetchingContactListRangeByUserIdForDashboardError: false,
  contactListRangeByUserIdForDashboard: [],

  fetchingContactListRangeOfAllUsers: false,
  fetchingContactListRangeOfAllUsersError: false,
  contactListRangeOfAllUsers: [],

  fetchingAccountListByContactId: false,
  fetchingAccountListByContactIdError: false,
  accountListByContactId: [],

  fetchingOpportunityListByContactId: false,
  fetchingOpportunityListByContactIdError: false,
  opportunityListByContactId: [],

  fetchingFunnelValueByContactId: false,
  fetchingFunnelValueByContactIdError: false,
  funnelValue: {},

  fetchingServiceOrder: false,
  fetchingServiceOrderError: false,
  serviceOrderForGenerating: [],

  linkingProductToContact: false,
  linkingProductToContactError: false,

  fetchingNotesListByContactId: false,
  fetchingNotesListByContactIdError: false,
  notesListByContactId: [],

  fetchingCallsListByContactId: false,
  fetchingCallsListByContactIdError: false,
  callListByContactId: [],

  fetchingEventsListByContactId: false,
  fetchingEventsListByContactIdError: false,
  eventsListByContactId: [],

  linkingServiceToContact: false,
  linkingServiceToContactError: false,

  fetchingProductOrderDetailsById: false,
  fetchingProductOrderDetailsByIdError: false,
  productOrderByOrderId: [],

  fetchingTasksListByContactId: false,
  fetchingTasksListByContactIdError: false,
  tasksListByContactId: [],

  fetchingTopicsByContactId: false,
  fetchingTopicsByContactIdError: false,
  addingTopicsByContactId: false,
  addingTopicsByContactIdError: false,
  deletingTopicsByContactId: false,
  deletingTopicsByContactIdError: false,
  topicsByContactId: [],

  fetchingCatagoryByContactId: false,
  fetchingCatagoryByContactIdError: false,
  deletingCatagoryByContactId: false,
  deletingCatagoryByContactIdError: false,
  addingCatagoryByContactId: false,
  addingCatagoryByContactIdError: false,
  catagoryByContactId: [],

  fetchingContacts: false,
  fetchingContactsError: false,
  contacts: [],

  fetchingContactActivityData: false,
  fetchingContactActivityDataError: false,
  contactActivity: [],

  fetchingContactsLazyLoading: false,
  fetchingContactsLazyLoadingError: false,
  contactsLazyLoading: [],

  fetchingLatestContacts: false,
  fetchingLatestContactsError: false,
  latestContacts: [],

  fetchingProductsByContactId: false,
  fetchingProductsByContactIdError: false,
  productsByContactId: [],

  generatingOrderByContactId: false,
  generatingOrderByContactIdError: false,

  fetchingServicesByContactId: false,
  fetchingServicesByContactIdError: false,
  servicesByContactId: [],

  fetchingAllLatestContacts: false,
  fetchingAllLatestContactsError: false,

  fetchingLatestContactsByOrganizationId: false,
  fetchingLatestContactsByOrganizationIdError: false,
  latestContactsByOrganizationId: [],

  fetchingContactsByFunnelValue: false,
  fetchingContactsByFunnelValueError: false,
  contactsByFunnelValue: [],

  fetchingAllContactsByFunnelValue: false,
  fetchingAllContactsByFunnelValueError: false,

  fetchingContactsByTimeToConnect: false,
  fetchingContactsByTimeToConnectError: false,
  contactsByTimeToConnect: [],

  fetchingInputSearchData: false,
  fetchingInputSearchDataError: false,

  inputData: [],

  fetchingAllContactsByTimeToConnect: false,
  fetchingAllContactsByTimeToConnectError: false,

  contactDrawerVisible: false,
  contactDrawerProps: {},

  fetchingMapContacts: false,
  fetchingMapContactsError: false,
  mapContacts: [],

  fetchingCategory: false,
  fetchingCategoryError: false,
  category: [],

  fetchingDesignation: false,
  fetchingDesignationError: false,
  designation: [],

  fetchingCategoryWiseContact: false,
  fetchingCategoryWiseContactError: false,

  fetchingRoleChartDataByContactId: false,
  fetchingRoleChartDataByContactIdError: false,
  roleChartDataByContactId: {},

  addingContactLinkByOpportunityId: false,

  adddingDocumentByContactId: false,
  adddingDocumentByContactIdError: false,

  fetchingDocumentsByContactId: false,
  fetchingDocumentsByContactIdError: false,
  documentsByContactId: [],

  fetchingAllLatestContactsForLazyLoading: false,
  fetchingAllLatestContactsForLazyLoadingError: false,
  latestContactsForLazyLoading: [],

  linkingSatgeToOpportunityByContactId: false,
  linkingSatgeToOpportunityByContactIdError: false,

  fetchingContactQuote: false,
  fetchingContactQuoteError: false,
  contactQuote: [],

  fetchingContactOrder: false,
  fetchingContactOrderError: false,
  contactOrder: [],

  setEditingNoteByContactId: {},
  addContactCustomeFieldModal: false,
  addContactActivityModal: false,

  //adding customeField form
  addingCustomeFieldForm: false,
  addingCustomeFieldFormError: false,

  fetchingCustomeFieldData: false,
  fetchingCustomeFieldDataError: false,
  customeFieldTableData: [],

  fetchingTimeLineByContactId: false,
  fetchingTimeLineByContactIdError: false,
  timeLineDataByContactId: [],

  fetchingOrder: false,
  fetchingOrderError: false,
  orderForGenerating: [],

  addContactSubscriptionConfigureModal: false,

  fetchingContactListRangeByUserIdForReport: false,
  fetchingContactListRangeByUserIdForReportError: false,
  contactListRangeByUserIdForReport: [],

  fetchingContactListRangeByOrgIdForReport: false,
  fetchingContactListRangeByOrgIdForReportError: false,
  contactListRangeByOrgIdForReport: [],

  suspendContact: false,
  suspendContactError: false,

  addContactOrderModal: false,

  fetchingFeedbackByOrderId: false,
  fetchingFeedbackByOrderIdError: true,
  orderFeedbacks: [],

  fetchingFeedbackByContactId: false,
  fetchingFeedbackByContactIdError: true,
  contactFeedbacks: [],

  fetchingContactHistory: false,
  fetchingContactHistoryError: false,
  ContactHistory: [],

  //SHARE Account Permission of customer
  addSharingContact: false,
  addSharingContactError: false,

  fetchingPermissionsListContact: false,
  fetchingPermissionsListContactError: false,
  permissionsDataListContact: [],

};
function generateContactData(state, payload) {
  if (payload.newEntry) {
    return [...payload.data];
  }
  return [...state.contactsLazyLoading, ...payload.data];
}
export const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CATAGORY_WISE_CONTACT_FOR_AND_OPERATION_SUCCESS:
      return { ...state, contacts: action.payload };

    case types.SET_NOTE_EDIT_BY_CONTACT_ID:
      return { ...state, setEditingNoteByContactId: action.payload };

    case types.GET_CONTACT_QUOTE_REQUEST:
      return { ...state, fetchingContactQuote: true };
    case types.GET_CONTACT_QUOTE_SUCCESS:
      return {
        ...state,
        fetchingContactQuote: false,
        contactQuote: action.payload,
      };
    case types.GET_CONTACT_QUOTE_FAILURE:
      return {
        ...state,
        fetchingContactQuote: false,
        fetchingContactQuoteError: true,
      };

    // order

    case types.GET_CONTACT_ORDER_REQUEST:
      return { ...state, fetchingContactOrder: true };
    case types.GET_CONTACT_ORDER_SUCCESS:
      return {
        ...state,
        fetchingContactOrder: false,
        contactOrder: action.payload,
      };
    case types.GET_CONTACT_ORDER_FAILURE:
      return {
        ...state,
        fetchingContactOrder: false,
        fetchingContactOrderError: true,
      };


    case types.GET_ROLE_CHART_DATA_BY_CONTACT_ID_REQUEST:
      return { ...state, fetchingRoleChartDataByContactId: true };
    case types.GET_ROLE_CHART_DATA_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        fetchingRoleChartDataByContactId: false,
        roleChartDataByContactId: action.payload,
      };
    case types.GET_ROLE_CHART_DATA_BY_CONTACT_ID_FAILURE:
      return {
        ...state,
        fetchingRoleChartDataByContactId: false,
        fetchingRoleChartDataByContactIdError: true,
      };

    case types.GET_CATAGORY_REQUEST:
      return { ...state, fetchingCategory: true };
    case types.GET_CATAGORY_SUCCESS:
      return { ...state, fetchingCategory: false, category: action.payload };
    case types.GET_CATAGORY_FAILURE:
      return { ...state, fetchingCategory: false, fetchingCategoryError: true };

    case types.GET_DESIGNATION_REQUEST:
      return { ...state, fetchingDesignation: true };
    case types.GET_DESIGNATION_SUCCESS:
      return {
        ...state,
        fetchingDesignation: false,
        designation: action.payload,
      };
    case types.GET_DESIGNATION_FAILURE:
      return {
        ...state,
        fetchingDesignation: false,
        fetchingDesignationError: true,
      };

    case types.GET_CATAGORY_WISE_CONTACT_REQUEST:
      return { ...state, fetchingCategoryWiseContact: true };
    case types.GET_CATAGORY_WISE_CONTACT_SUCCESS:
      return {
        ...state,
        fetchingCategoryWiseContact: false,
        contactsLazyLoading: generateContactData(state, action.payload),
      };

    case types.GET_CATAGORY_WISE_CONTACT_FAILURE:
      return {
        ...state,
        fetchingCategoryWiseContact: false,
        fetchingCategoryWiseContactError: true,
      };

    case types.GET_CONTACTS_REQUEST:
      return { ...state, fetchingContacts: true };
    case types.GET_CONTACTS_SUCCESS:
      return { ...state, fetchingContacts: false, contacts: action.payload };
    case types.GET_CONTACTS_FAILURE:
      return { ...state, fetchingContacts: false, fetchingContactsError: true };

    case types.GET_CONTACTS_LAZY_LOADING_REQUEST:
      return { ...state, fetchingContactsLazyLoading: true };
    case types.GET_CONTACTS_LAZY_LOADING_SUCCESS:
      return {
        ...state,
        fetchingContactsLazyLoading: false,
        contactsLazyLoading: [...state.contactsLazyLoading, ...action.payload],
      };
    case types.GET_CONTACTS_LAZY_LOADING_FAILURE:
      return {
        ...state,
        fetchingContactsLazyLoading: false,
        fetchingContactsLazyLoadingError: true,
      };

    case types.GET_MAP_CONTACTS_REQUEST:
      return { ...state, fetchingMapContacts: true };
    case types.GET_MAP_CONTACTS_SUCCESS:
      return {
        ...state,
        fetchingMapContacts: false,
        mapContacts: action.payload,
      };
    case types.GET_MAP_CONTACTS_FAILURE:
      return {
        ...state,
        fetchingMapContacts: false,
        fetchingMapContactsError: true,
      };
    /**
     * get the list of all latest contacts
     */
    case types.GET_LATEST_CONTACTS_REQUEST:
      return { ...state, fetchingLatestContacts: true };
    case types.GET_LATEST_CONTACTS_SUCCESS:
      return {
        ...state,
        fetchingLatestContacts: false,
        latestContacts: action.payload,
      };
    case types.GET_LATEST_CONTACTS_FAILURE:
      return {
        ...state,
        fetchingLatestContacts: false,
        fetchingLatestContactsError: true,
      };

    case types.GET_ALL_LATEST_CONTACTS_REQUEST:
      return { ...state, fetchingAllLatestContacts: true };
    case types.GET_ALL_LATEST_CONTACTS_SUCCESS:
      return {
        ...state,
        fetchingAllLatestContacts: false,
        latestContacts: [...action.payload],
      };
    case types.GET_ALL_LATEST_CONTACTS_FAILURE:
      return {
        ...state,
        fetchingAllLatestContacts: false,
        fetchingLatestContactsError: true,
      };
    /**
     * get the list of all latest contacts
     */
    case types.GET_LATEST_CONTACTS_BY_ORGANIZATION_ID_REQUEST:
      return { ...state, fetchingLatestContactsByOrganizationId: true };
    case types.GET_LATEST_CONTACTS_BY_ORGANIZATION_ID_SUCCESS:
      return {
        ...state,
        fetchingLatestContactsByOrganizationId: false,
        latestContactsByOrganizationId: action.payload,
        // latestContactsByOrganizationId: [
        //   ...state.latestContactsByOrganizationId,
        //   ...action.payload,
        // ],
      };
    case types.GET_LATEST_CONTACTS_BY_ORGANIZATION_ID_FAILURE:
      return {
        ...state,
        fetchingLatestContactsByOrganizationId: false,
        fetchingLatestContactsByOrganizationIdError: true,
      };
    /**
     * get the list of all contacts sort by funnel value
     */
    case types.GET_CONTACTS_BY_FUNNEL_VALUE_REQUEST:
      return { ...state, fetchingContactsByFunnelValue: true };
    case types.GET_CONTACTS_BY_FUNNEL_VALUE_SUCCESS:
      return {
        ...state,
        fetchingContactsByFunnelValue: false,
        contactsByFunnelValue: action.payload,
      };
    case types.GET_CONTACTS_BY_FUNNEL_VALUE_FAILURE:
      return {
        ...state,
        fetchingContactsByFunnelValue: false,
        fetchingContactsByFunnelValueError: true,
      };

    case types.GET_ALL_CONTACTS_BY_FUNNEL_VALUE_REQUEST:
      return { ...state, fetchingAllContactsByFunnelValue: true };
    case types.GET_ALL_CONTACTS_BY_FUNNEL_VALUE_SUCCESS:
      return {
        ...state,
        fetchingAllContactsByFunnelValue: false,
        contactsByFunnelValue: [...action.payload],
      };
    case types.GET_ALL_CONTACTS_BY_FUNNEL_VALUE_FAILURE:
      return {
        ...state,
        fetchingAllContactsByFunnelValue: false,
        fetchingContactsByFunnelValueError: true,
      };
    /**
     * get the list of all contacts by time to connect
     */
    case types.GET_CONTACTS_BY_TIME_TO_CONNECT_REQUEST:
      return { ...state, fetchingContactsByTimeToConnect: true };
    case types.GET_CONTACTS_BY_TIME_TO_CONNECT_SUCCESS:
      return {
        ...state,
        fetchingContactsByTimeToConnect: false,
        contactsByTimeToConnect: action.payload,
      };
    case types.GET_CONTACTS_BY_TIME_TO_CONNECT_FAILURE:
      return {
        ...state,
        fetchingContactsByTimeToConnect: false,
        fetchingContactsByTimeToConnectError: true,
      };

    case types.GET_ALL_CONTACTS_BY_TIME_TO_CONNECT_REQUEST:
      return { ...state, fetchingALLContactsByTimeToConnect: true };
    case types.GET_ALL_CONTACTS_BY_TIME_TO_CONNECT_SUCCESS:
      return {
        ...state,
        fetchingALLContactsByTimeToConnect: false,
        contactsByTimeToConnect: [...action.payload],
      };
    case types.GET_ALL_CONTACTS_BY_TIME_TO_CONNECT_FAILURE:
      return {
        ...state,
        fetchingALLContactsByTimeToConnect: false,
        fetchingAllContactsByTimeToConnectError: true,
      };

    case types.GET_CONTACT_BY_ID_REQUEST:
      return { ...state, fetchingContactById: true };
    case types.GET_CONTACT_BY_ID_SUCCESS:
      return { ...state, fetchingContactById: false, contact: action.payload };
    case types.GET_CONTACT_BY_ID_FAILURE:
      return {
        ...state,
        fetchingContactById: false,
        fetchingContactByIdError: true,
      };
    /**
     * update a single contact by its ID
     */
    case types.UPDATE_CONTACT_BY_ID_REQUEST:
      return { ...state, updateContactById: true };
    case types.UPDATE_CONTACT_BY_ID_SUCCESS:
      return { ...state, updateContactById: false, contact: action.payload };
    case types.UPDATE_CONTACT_BY_ID_FAILURE:
      return {
        ...state,
        updateContactById: false,
        updateContactByIdError: true,
      };

    case types.UPDATE_CONTACT_ADDRESS:
      return {
        ...state,
        contact: {
          ...state.contact,
          address: state.contact.address.map((item) => {
            if (item.addressId === action.payload.address.addressId) {
              return action.payload.address;
            } else {
              return item;
            }
          }),
        },
      };

    /**
     * delete a single contact by its ID
     */
    case types.DELETE_CONTACT_BY_ID_REQUEST:
      return { ...state, deletingContactById: true };
    case types.DELETE_CONTACT_BY_ID_SUCCESS:
      return {
        ...state,
        deletingContactById: false,
        contacts: state.contacts.filter(
          (contact) => contact.contactId !== action.payload
        ),
        latestContacts: state.latestContacts.filter(
          (contact) => contact.contactId !== action.payload
        ),
        contactsByFunnelValue: state.contactsByFunnelValue.filter(
          (contact) => contact.contactId !== action.payload
        ),
      };
    case types.DELETE_CONTACT_BY_ID_FAILURE:
      return {
        ...state,
        deletingContactById: false,
        deletingContactByIdError: true,
      };
    /**
     * link account to a contact
     */
    case types.LINK_ACCOUNT_TO_CONTACT_REQUEST:
      return { ...state };
    case types.LINK_ACCOUNT_TO_CONTACT_SUCCESS:
      return { ...state };
    case types.LINK_ACCOUNT_TO_CONTACT_FAILURE:
      return { ...state };
    /**
     * unlink account from a contact
     */
    case types.UNLINK_ACCOUNT_FROM_CONTACT_REQUEST:
      return { ...state };
    case types.UNLINK_ACCOUNT_FROM_CONTACT_SUCCESS:
      return { ...state };
    case types.UNLINK_ACCOUNT_FROM_CONTACT_FAILURE:
      return { ...state };

    case types.GET_CONTACT_LIST_RANGE_BY_USER_ID_REQUEST:
      return { ...state, fetchingContactListRangeByUserId: true };
    case types.GET_CONTACT_LIST_RANGE_BY_USER_ID_SUCCESS:
      return {
        ...state,
        fetchingContactListRangeByUserId: false,
        contactListRangeByUserId: action.payload,
      };
    case types.GET_CONTACT_LIST_RANGE_BY_USER_ID_FAILURE:
      return {
        ...state,
        fetchingContactListRangeByUserId: false,
        fetchingContactListRangeByUserIdError: true,
      };

    case types.GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_DASHBOARD_REQUEST:
      return { ...state, fetchingContactListRangeByUserIdForDashboard: true };
    case types.GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_DASHBOARD_SUCCESS:
      return {
        ...state,
        fetchingContactListRangeByUserIdForDashboard: false,
        contactListRangeByUserIdForDashboard: action.payload,
      };
    case types.GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_DASHBOARD_FAILURE:
      return {
        ...state,
        fetchingContactListRangeByUserIdForDashboard: false,
        fetchingContactListRangeByUserIdForDashboardError: true,
      };

    /**
     * getcontact list of all users of an  organization
     */
    case types.GET_CONTACT_LIST_RANGE_OF_ALL_USERS_REQUEST:
      return { ...state, fetchingContactListRangeOfAllUsers: true };
    case types.GET_CONTACT_LIST_RANGE_OF_ALL_USERS_SUCCESS:
      return {
        ...state,
        fetchingContactListRangeOfAllUsers: false,
        contactListRangeOfAllUsers: action.payload,
      };
    case types.GET_CONTACT_LIST_RANGE_OF_ALL_USERS_FAILURE:
      return {
        ...state,
        fetchingContactListRangeOfAllUsers: false,
        fetchingContactListRangeOfAllUsersError: true,
      };

    /**
     * getcontact list by accountId
     */
    case types.GET_ACCOUNT_LIST_BY_CONTACT_ID_REQUEST:
      return { ...state, fetchingContactListByContactId: true };
    case types.GET_ACCOUNT_LIST_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        fetchingContactListByContactId: false,
        contactListByContactId: action.payload,
      };
    case types.GET_ACCOUNT_LIST_BY_CONTACT_ID_FAILURE:
      return {
        ...state,
        fetchingContactListByContactId: false,
        fetchingContactListByContactIdError: true,
      };
    /**
     * update accountlist list by contactId
     */
    case types.UPDATE_ACCOUNT_LIST_BY_CONTACT_ID_REQUEST:
      return { ...state };
    case types.UPDATE_ACCOUNT_LIST_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        accountListByContactId: state.accountListByContactId.filter(
          (item) => item.accountId !== action.payload
        ),
      };
    case types.UPDATE_ACCOUNT_LIST_BY_CONTACT_ID_FAILURE:
      return { ...state };
    /**
     * get opportunity list by accountId
     */
    case types.GET_OPPORTUNITY_LIST_BY_CONTACT_ID_REQUEST:
      return { ...state, fetchingOpportunityListByContactId: true };
    case types.GET_OPPORTUNITY_LIST_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        fetchingOpportunityListByContactId: false,
        opportunityListByContactId: action.payload,
      };
    case types.GET_OPPORTUNITY_LIST_BY_CONTACT_ID_FAILURE:
      return {
        ...state,
        fetchingOpportunityListByContactId: false,
        fetchingOpportunityListByContactIdError: true,
      };
    /**
     * get opportunity list by accountId
     */
    case types.UPDATE_OPPORTUNITY_LIST_BY_CONTACT_ID_REQUEST:
      return { ...state };
    case types.UPDATE_OPPORTUNITY_LIST_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        opportunityListByContactId: state.opportunityListByContactId.filter(
          (item) => item.opportunityId !== action.payload
        ),
      };
    case types.UPDATE_OPPORTUNITY_LIST_BY_CONTACT_ID_FAILURE:
      return { ...state };
    /**
     * get notes list by accountId
     */
    case types.GET_NOTES_LIST_BY_CONTACT_ID_REQUEST:
      return { ...state, fetchingNotesListByContactId: true };
    case types.GET_NOTES_LIST_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        fetchingNotesListByContactId: false,
        notesListByContactId: action.payload,
      };
    case types.GET_NOTES_LIST_BY_CONTACT_ID_FAILURE:
      return {
        ...state,
        fetchingNotesListByContactId: false,
        fetchingNotesListByContactIdError: true,
      };
    /**
     * get notes list by accountId
     */
    case types.UPDATE_NOTES_LIST_BY_CONTACT_ID_REQUEST:
      return { ...state };
    case types.UPDATE_NOTES_LIST_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        notesListByContactId: state.notesListByContactId.filter(
          (item) => item.noteId !== action.payload
        ),
      };
    case types.UPDATE_NOTES_LIST_BY_CONTACT_ID_FAILURE:
      return { ...state };
    /**
     * get calls list by accountId
     */
    case types.GET_CALLS_LIST_BY_CONTACT_ID_REQUEST:
      return { ...state, fetchingCallsListByContactId: true };
    case types.GET_CALLS_LIST_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        fetchingCallsListByContactId: false,
        callsListByContactId: action.payload,
      };
    case types.GET_CALLS_LIST_BY_CONTACT_ID_FAILURE:
      return {
        ...state,
        fetchingCallsListByContactId: false,
        fetchingCallsListByContactIdError: true,
      };
    /**
     * get calls list by accountId
     */
    case types.UPDATE_CALLS_LIST_BY_CONTACT_ID_REQUEST:
      return { ...state };
    case types.UPDATE_CALLS_LIST_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        callsListByContactId: state.callsListByContactId.filter(
          (item) => item.callId !== action.payload
        ),
      };
    case types.UPDATE_CALLS_LIST_BY_CONTACT_ID_FAILURE:
      return { ...state };
    /**
     * get events list by accountId
     */
    case types.GET_EVENTS_LIST_BY_CONTACT_ID_REQUEST:
      return { ...state, fetchingEventsListByContactId: true };
    case types.GET_EVENTS_LIST_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        fetchingEventsListByContactId: false,
        eventsListByContactId: action.payload,
      };
    case types.GET_EVENTS_LIST_BY_CONTACT_ID_FAILURE:
      return {
        ...state,
        fetchingEventsListByContactId: false,
        fetchingEventsListByContactIdError: true,
      };
    /**
     * get events list by accountId
     */
    case types.UPDATE_EVENTS_LIST_BY_CONTACT_ID_REQUEST:
      return { ...state };
    case types.UPDATE_EVENTS_LIST_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        eventsListByContactId: state.eventsListByContactId.filter(
          (item) => item.eventId !== action.payload
        ),
      };
    case types.UPDATE_EVENTS_LIST_BY_CONTACT_ID_FAILURE:
      return { ...state };
    /**
     * get tasks list by accountId
     */
    case types.GET_TASKS_LIST_BY_CONTACT_ID_REQUEST:
      return { ...state, fetchingTasksListByContactId: true };
    case types.GET_TASKS_LIST_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        fetchingTasksListByContactId: false,
        tasksListByContactId: action.payload,
      };
    case types.GET_TASKS_LIST_BY_CONTACT_ID_FAILURE:
      return {
        ...state,
        fetchingTasksListByContactId: false,
        fetchingTasksListByContactIdError: true,
      };
    /**
     * get tasks list by accountId
     */
    case types.UPDATE_TASKS_LIST_BY_CONTACT_ID_REQUEST:
      return { ...state };
    case types.UPDATE_TASKS_LIST_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        tasksListByContactId: state.tasksListByContactId.filter(
          (item) => item.taskId !== action.payload
        ),
      };
    case types.UPDATE_TASKS_LIST_BY_CONTACT_ID_FAILURE:
      return { ...state };

    case types.ADD_CONTACT_REQUEST:
      return { ...state, addingContact: true };
    case types.ADD_CONTACT_SUCCESS:
      return { ...state, addingContact: false, addContactModal: false };
    case types.ADD_CONTACT_FAILURE:
      return { ...state, addingContact: false, addContactModal: false };

    case types.ADD_LINK_CONTACT_BY_OPPORTUNITY_ID_REQUEST:
      return { ...state, addingContactLinkByOpportunityId: true };
    case types.ADD_LINK_CONTACT_BY_OPPORTUNITY_ID_SUCCESS:
      return {
        ...state,
        addingContactLinkByOpportunityId: false,
        addContactModal: false,
      };
    case types.ADD_LINK_CONTACT_BY_OPPORTUNITY_ID_FAILURE:
      return {
        ...state,
        addingContactLinkByOpportunityId: false,
        addContactModal: false,
      };

    case types.GET_TOPICS_BY_CONTACT_ID_REQUEST:
      return { ...state, fetchingTopicsByContactId: true };
    case types.GET_TOPICS_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        fetchingTopicsByContactId: false,
        topicsByContactId: action.payload,
      };
    case types.GET_TOPICS_BY_CONTACT_ID_FAILURE:
      return {
        ...state,
        fetchingTopicsByContactId: false,
        fetchingTopicsByContactIdError: true,
      };

    case types.ADD_TOPIC_BY_CONTACT_ID_REQUEST:
      return { ...state, addingTopicByContactId: true };
    case types.ADD_TOPIC_BY_CONTACT_ID_SUCCESS:
      // console.clear()
      // console.log(action.payload)
      return {
        ...state,
        addingTopicByContactId: false,
        topicsByContactId: [...state.topicsByContactId, action.payload],
      };
    case types.ADD_TOPIC_BY_CONTACT_ID_FAILURE:
      return {
        ...state,
        addingTopicByContactId: false,
        addingTopicByContactIdError: true,
      };

    case types.DELETE_TOPIC_BY_CONTACT_ID_REQUEST:
      return { ...state, deletingTopicByContactId: true };
    case types.DELETE_TOPIC_BY_CONTACT_ID_SUCCESS:
      return { ...state, deletingTopicByContactId: false };
    case types.DELETE_TOPIC_BY_CONTACT_ID_FAILURE:
      return {
        ...state,
        deletingTopicByContactId: false,
        deletingTopicByContactIdError: true,
      };

    //Add catagory
    case types.ADD_CATAGORY_BY_CONTACT_ID_REQUEST:
      return { ...state, addingCatagoryByContactId: true };
    case types.ADD_CATAGORY_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        addingCatagoryByContactId: false,
        catagoryByContactId: [...state.catagoryByContactId, action.payload],
      };
    case types.ADD_CATAGORY_BY_CONTACT_ID_FAILURE:
      return {
        ...state,
        addingCatagoryByContactId: false,
        addingCatagoryByContactIdError: true,
      };
    //get catagory
    case types.GET_CATAGORY_BY_CONTACT_ID_REQUEST:
      return { ...state, fetchingCatagoryByContactId: true };
    case types.GET_CATAGORY_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        fetchingCatagoryByContactId: false,
        catagoryByContactId: action.payload,
      };
    case types.GET_CATAGORY_BY_CONTACT_ID_FAILURE:
      return {
        ...state,
        fetchingCatagoryByContactId: false,
        fetchingCatagoryByContactIdError: true,
      };
    //delete category
    case types.DELETE_CATAGORY_BY_CONTACT_ID_REQUEST:
      return { ...state, deletingCategoryByContactId: true };
    case types.DELETE_CATAGORY_BY_CONTACT_ID_SUCCESS:
      return { ...state, deletingCategoryByContactId: false };
    case types.DELETE_CATAGORY_BY_CONTACT_ID_FAILURE:
      return {
        ...state,
        deletingCategoryByContactId: false,
        deletingCategoryByContactIdError: true,
      };

    case types.FUNNEL_VALUE_BY_CONTACT_ID_REQUEST:
      return { ...state, fetchingFunnelValueByContactId: true };
    case types.FUNNEL_VALUE_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        fetchingFunnelValueByContactId: false,
        funnelValue: action.payload,
      };
    case types.FUNNEL_VALUE_BY_CONTACT_ID_FAILURE:
      return {
        ...state,
        fetchingFunnelValueByContactId: false,
        fetchingFunnelValueByContactIdError: true,
      };

    case types.HANDLE_CONTACT_MODAL:
      return { ...state, addContactModal: action.payload };
    case types.HANDLE_LINK_CONTACT_MODAL:
      return { ...state, linkContactModal: action.payload };
    case types.HANDLE_CONTACT_DRAWER:
      return {
        ...state,
        contactDrawerVisible: action.payload.isVisible,
        contactDrawerProps: action.payload.props,
      };

    case types.SET_CONTACT_VIEW_TYPE:
      return { ...state, viewType: action.payload };
    case types.SET_CONTACT_FILTER_TEXT:
      return { ...state, contactFilterText: action.payload };
    case types.SET_CONTACT_FILTER_USER:
      return { ...state, contactFilterUser: action.payload };
    case types.CHANGE_SELECTED_TIME_INTERVAL_CONTACT:
      return {
        ...state,
        dateRangeList: newDateRange(state.dateRangeList, action.payload),
        isCustomSelected: false,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    case types.SET_TIME_INTERVAL_CONTACT:
      return {
        ...state,
        isCustomSelected: true,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    case types.ADD_CONTACT_DOCUMENT_REQUEST:
      return {
        ...state,
        adddingDocumentByContactId: true,
        adddingDocumentByContactIdError: false,
      };
    case types.ADD_CONTACT_DOCUMENT_SUCCESS:
      return {
        ...state,
        adddingDocumentByContactId: false,
        adddingDocumentByContactIdError: false,
      };
    case types.ADD_CONTACT_DOCUMENT_FAILURE:
      return {
        ...state,
        adddingDocumentByContactId: false,
        adddingDocumentByContactIdError: true,
      };

    case types.GET_CONTACT_DOCUMENTS_REQUEST:
      return {
        ...state,
        fetchingDocumentsByContactId: true,
        fetchingDocumentsByContactIdError: false,
      };
    case types.GET_CONTACT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        fetchingDocumentsByContactId: false,
        fetchingDocumentsByContactIdError: false,
        documentsByContactId: action.payload,
      };
    case types.GET_CONTACT_DOCUMENTS_FAILURE:
      return {
        ...state,
        fetchingDocumentsByContactId: false,
        fetchingDocumentsByContactIdError: true,
      };
    //EMAILS
    case types.GET_NO_OF_EMAILS_SENT_REQUEST:
      return {
        ...state,
        fetchingNoOfEmailsSent: true,
        fetchingDocumentsByContactIdError: false,
      };
    case types.GET_NO_OF_EMAILS_SENT_SUCCESS:
      return {
        ...state,
        fetchingNoOfEmailsSent: false,
        fetchingNoOfEmailsSentError: false,
        noOfEmails: action.payload,
      };
    case types.GET_NO_OF_EMAILS_SENT_FAILURE:
      return {
        ...state,
        fetchingNoOfEmailsSent: false,
        fetchingNoOfEmailsSentError: true,
      };

    case types.LINK_STAGE_TO_OPPORTUNITY_BY_CONTACT_ID_REQUEST:
      return {
        ...state,
        linkingSatgeToOpportunityByContactId: true,
      };
    case types.LINK_STAGE_TO_OPPORTUNITY_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        linkingSatgeToOpportunityByContactId: false,
        opportunityListByContactId: state.opportunityListByContactId.map(
          (item, i) => {
            if (item.opportunityId === action.payload.opportunityId) {
              return action.payload;
            } else {
              return item;
            }
          }
        ),
      };
    case types.LINK_STAGE_TO_OPPORTUNITY_BY_CONTACT_ID_FAILURE:
      return {
        ...state,
        linkingSatgeToOpportunityByContactId: false,
        linkingSatgeToOpportunityByContactIdError: true,
      };

    case types.HANDLE_ABSOLUTE_MODAL:
      return { ...state, addAbsoluteModal: action.payload };

    case types.HANDLE_WIN_MODAL:
      return { ...state, addWinModal: action.payload };

    case types.HANDLE_MEETINGS_MODAL:
      return { ...state, addMeetingsModal: action.payload };

    case types.HANDLE_CALLS_MODAL:
      return { ...state, addCallsModal: action.payload };

    case types.HANDLE_PIE_MODAL:
      return { ...state, addPieModal: action.payload };

    case types.EMPTY_CONTACT_DATA:
      return { ...state, contactsLazyLoading: [] };

    case types.HANDLE_CONTACT_CUSTOME_FIELD_MODAL:
      return { ...state, addContactCustomeFieldModal: action.payload };

    case types.CLEANUP_CONTACT_REQUEST:
      return { ...state, cleanupContactTable: true };
    case types.CLEANUP_CONTACT_SUCCESS:
      return { ...state, cleanupContactTable: false };
    case types.CLEANUP_CONTACT_FAILURE:
      return {
        ...state,
        cleanupContactTable: false,
        cleanupContactTableError: true,
      };

    //GET RECORDS
    case types.GET_RECORDS_REQUEST:
      return { ...state, fetchingRecordsByUserId: true };
    case types.GET_RECORDS_SUCCESS:
      return {
        ...state,
        fetchingRecordsByUserId: false,
        recordData: action.payload,
      };
    case types.GET_RECORDS_FAILURE:
      return {
        ...state,
        fetchingRecordsByUserId: false,
        fetchingRecordsByUserIdError: true,
      };
    //LAZY LOADING FOR CONTACTS REPORTS
    case types.GET_ALL_LATEST_CONTACTS_FOR_LAZY_LOADING_REQUEST:
      return { ...state, fetchingAllLatestContactsForLazyLoading: true };
    case types.GET_ALL_LATEST_CONTACTS_FOR_LAZY_LOADING_SUCCESS:
      return {
        ...state,
        fetchingAllLatestContactsForLazyLoading: false,
        latestContactsForLazyLoading: [
          ...state.latestContactsForLazyLoading,
          ...action.payload,
        ],
        //  latestContactsForLazyLoading: [
        //   ...state.latestContactsForLazyLoading,
        //   ...action.payload,
        // ],
      };
    case types.GET_ALL_LATEST_CONTACTS_FOR_LAZY_LOADING_FAILURE:
      return {
        ...state,
        fetchingAllLatestContactsForLazyLoading: false,
        fetchingAllLatestContactsForLazyLoadingError: true,
      };

    case types.ADD_CUSTOME_FIELD_FORM_REQUEST:
      return { ...state, addingCustomeFieldForm: true };
    case types.ADD_CUSTOME_FIELD_FORM_SUCCESS:
      return {
        ...state,
        addingCustomeFieldForm: false,
        addContactCustomeFieldModal: false,
      };
    case types.ADD_CUSTOME_FIELD_FORM_FAILURE:
      return { ...state, addingCustomeFieldFormError: true };

    case types.GET_CUSTOME_FIELD_REQUEST:
      return { ...state, fetchingCustomeFieldData: true };
    case types.GET_CUSTOME_FIELD_SUCCESS:
      return {
        ...state,
        fetchingCustomeFieldData: false,
        customeFieldTableData: action.payload,
      };
    case types.GET_CUSTOME_FIELD_FAILURE:
      return {
        ...state,
        fetchingCustomeFieldData: false,
        fetchingCustomeFieldDataError: true,
      };

    //Time line data
    case types.GET_TIMELINE_DATA_BY_CONTACT_ID_REQUEST:
      return { ...state, fetchingTimeLineByContactId: true };
    case types.GET_TIMELINE_DATA_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        fetchingTimeLineByContactId: false,
        timeLineDataByContactId: action.payload,
      };

    case types.GET_TIMELINE_DATA_BY_CONTACT_ID_FAILURE:
      return {
        ...state,
        fetchingTimeLineByContactId: false,
        fetchingTimeLineByContactIdError: true,
      };

    case types.HANDLE_CONTACT_ACTIVITY_MODAL:
      return { ...state, addContactActivityModal: action.payload };

    case types.GET_CONTACT_ACTIVITY_DATA_REQUEST:
      return { ...state, fetchingContactActivityData: true };
    case types.GET_CONTACT_ACTIVITY_DATA_SUCCESS:
      return {
        ...state,
        fetchingContactActivityData: false,
        contactActivity: action.payload,
      };
    case types.GET_CONTACT_ACTIVITY_DATA_FAILURE:
      return {
        ...state,
        fetchingContactActivityData: false,
        fetchingContactActivityDataError: true,
      };

    case types.GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST:
      return { ...state, fetchingContactListRangeByUserIdForReport: true };
    case types.GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS:
      return {
        ...state,
        fetchingContactListRangeByUserIdForReport: false,
        contactListRangeByUserIdForReport: action.payload,
      };
    case types.GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE:
      return {
        ...state,
        fetchingContactListRangeByUserIdForReport: false,
        fetchingContactListRangeByUserIdForReportError: true,
      };

    case types.GET_CONTACT_LIST_BY__RANGE_BY_ORGANISATION_ID_FOR_REPORT_REQUEST:
      return { ...state, fetchingContactListRangeByOrgIdForReport: true };
    case types.GET_CONTACT_LIST_BY__RANGE_BY_ORGANISATION_ID_FOR_REPORT_SUCCESS:
      return {
        ...state,
        fetchingContactListRangeByOrgIdForReport: false,
        contactListRangeByOrgIdForReport: action.payload,
      };
    case types.GET_CONTACT_LIST_BY__RANGE_BY_ORGANISATION_ID_FOR_REPORT_FAILURE:
      return {
        ...state,
        fetchingContactListRangeByOrgIdForReport: false,
        fetchingContactListRangeByOrgIdForReportError: true,
      };

    case types.INPUT_SEARCH_DATA_REQUSET:
      return { ...state, fetchingInputSearchData: true };
    case types.INPUT_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        fetchingInputSearchData: false,
        contactsLazyLoading: action.payload,
        // serachedData: action.payload,
      };
    case types.INPUT_SEARCH_DATA_FAILURE:
      return { ...state, fetchinngInputSearchDataError: true };

    case types.SUSPEND_CONTACT_REQUSET:
      return { ...state, suspendContact: true };
    case types.SUSPEND_CONTACT_SUCCESS:
      return {
        ...state,
        suspendContact: false,
        suspend: action.payload,
      };
    case types.SUSPEND_CONTACT_FAILURE:
      return {
        ...state,
        suspendContact: false,
        suspendContactError: true,
      };

    case types.HANDLE_CONTACT_ORDER_MODAL:
      return { ...state, addContactOrderModal: action.payload };

    case types.HANDLE_LINK_CONTACT_ORDER_CONFIGURE_MODAL:
      return { ...state, addLinkContactOrderConfigureModal: action.payload };

    // order product

    case types.LINK_PRODUCT_TO_CONTACT_REQUEST:
      return {
        ...state,
        linkingProductToContact: true,

      };
    case types.LINK_PRODUCT_TO_CONTACT_SUCCESS:
      return {
        ...state,
        linkingProductToContact: false,
        addLinkContactOrderConfigureModal: false,
      };
    case types.LINK_PRODUCT_TO_CONTACT_FAILURE:
      return {
        ...state,
        linkingProductToContact: false,
        linkingProductToContactError: true,
        addLinkContactOrderConfigureModal: false,
      };

    case types.FETCHING_PROFESSIONALDUCTS_BY_CONTACT_ID_REQUEST:
      return {
        ...state,
        fetchingProductsByContactId: true,
        fetchingProductsByContactIdError: false,
      };
    case types.FETCHING_PROFESSIONALDUCTS_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        fetchingProductsByContactId: false,
        fetchingProductsByContactIdError: false,
        productsByContactId: action.payload,
      };
    case types.FETCHING_PROFESSIONALDUCTS_BY_CONTACT_ID_FAILURE:
      return {
        ...state,
        fetchingProductsByContactId: false,
        fetchingProductsByContactIdError: true,
      };

    case types.FETCHING_PROFESSIONAL_SERVICE_BY_CONTACT_ID_REQUEST:
      return {
        ...state,
        fetchingServicesByContactId: true,
        fetchingServicesByContactIdError: false,
      };
    case types.FETCHING_PROFESSIONAL_SERVICE_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        fetchingServicesByContactId: false,
        fetchingServicesByContactIdError: false,
        servicesByContactId: action.payload,
      };
    case types.FETCHING_PROFESSIONAL_SERVICE_BY_CONTACT_ID_FAILURE:
      return {
        ...state,
        fetchingServicesByContactId: false,
        fetchingServicesByContactIdError: true,
      };

    // service

    case types.LINK_SERVICE_TO_CONTACT_REQUEST:
      return {
        ...state,
        linkingServiceToContact: true,
      };
    case types.LINK_SERVICE_TO_CONTACT_SUCCESS:
      return {
        ...state,
        linkingServiceToContact: false,
        addLinkContactOrderConfigureModal: false,
      };
    case types.LINK_SERVICE_TO_CONTACT_FAILURE:
      return {
        ...state,
        linkingServiceToContact: false,
        linkingServiceToContactError: true,
        addLinkContactOrderConfigureModal: false,
      };

    case types.FETCHING_NEW_ORDER_LIST_REQUEST:
      return {
        ...state,
        fetchingOrder: true,
      };
    case types.FETCHING_NEW_ORDER_LIST_SUCCESS:
      return {
        ...state,
        fetchingOrder: false,
        orderForGenerating: action.payload,
      };
    case types.FETCHING_NEW_ORDER_LIST_FAILURE:
      return {
        ...state,
        fetchingOrder: false,
        fetchingOrderError: true,
      };

    case types.FETCHING_NEW_ORDER_SERVICE_LIST_REQUEST:
      return {
        ...state,
        fetchingServiceOrder: true,
      };
    case types.FETCHING_NEW_ORDER_SERVICE_LIST_SUCCESS:
      return {
        ...state,
        fetchingServiceOrder: false,
        serviceOrderForGenerating: action.payload,
      };
    case types.FETCHING_NEW_ORDER_SERVICE_LIST_FAILURE:
      return {
        ...state,
        fetchingServiceOrder: false,
        fetchingServiceOrderError: true,
      };

    case types.HANDLE_CONTACT_SUBSCRIPTION_CONFIGURE_MODAL:
      return { ...state, addContactSubscriptionConfigureModal: action.payload };

    /**
 * generate order with subscription
 */

    case types.GENERATE_ORDER_BY_CONTACT_ID_REQUEST:
      return {
        ...state,
        generatingOrderByContactId: true,
      };
    case types.GENERATE_ORDER_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        generatingOrderByContactId: false,
        addContactSubscriptionConfigureModal: false,
      };
    case types.GENERATE_ORDER_BY_CONTACT_ID_FAILURE:
      return {
        ...state,
        generatingOrderByContactId: false,
        generatingOrderByContactIdError: true,
        addContactSubscriptionConfigureModal: false,
      };
    /**
    * renewal modal
    */
    case types.HANDLE_RENEWAL_BUTTON_MODAL:
      return { ...state, addRenewalButtonModal: action.payload };

    /**
     * Pause modal
     */
    case types.HANDLE_PAUSE_BUTTON_MODAL:
      return { ...state, addPauseButtonModal: action.payload }

    case types.GET_PRODUCT_ORDER_DETAILS_BY_ID_REQUEST:
      return { ...state, fetchingProductOrderDetailsById: true };
    case types.GET_PRODUCT_ORDER_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        fetchingProductOrderDetailsById: false,
        productOrderByOrderId: action.payload,
      };
    case types.GET_PRODUCT_ORDER_DETAILS_BY_ID_FAILURE:
      return {
        ...state,
        fetchingProductOrderDetailsById: false,
        fetchingProductOrderDetailsByIdError: true,
      };

    /**
    * post pause form
    */

    case types.LINK_PAUSE_BY_CONTACT_ID_REQUEST:
      return {
        ...state,
        linkingPauseByContactId: true,
      };
    case types.LINK_PAUSE_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        linkingPauseByContactId: false,
        addPauseButtonModal: false,
        contactOrder: state.contactOrder.map((item) => {
          if (
            item.contactId ==
            action.payload.contactId
          ) {
            return action.payload;
          } else {
            return item;
          }
        }),
      };
    case types.LINK_PAUSE_BY_CONTACT_ID_FAILURE:
      return {
        ...state,
        linkingPauseByContactId: false,
        linkingPauseByContactIdError: true,
        addPauseButtonModal: false,
      };

    /**
    * post renwal form
    */

    case types.LINK_RENEWAL_BY_CONTACT_ID_REQUEST:
      return {
        ...state,
        linkingRenewalByContactId: true,
      };
    case types.LINK_RENEWAL_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        linkingRenewalByContactId: false,
        addRenewalButtonModal: false,
        contactOrder: state.contactOrder.map((item) => {
          if (
            item.contactId ==
            action.payload.contactId
          ) {
            return action.payload;
          } else {
            return item;
          }
        }),
      };
    case types.LINK_RENEWAL_BY_CONTACT_ID_FAILURE:
      return {
        ...state,
        linkingRenewalByContactId: false,
        linkingRenewalByContactIdError: true,
        addRenewalButtonModal: false,
      };

    case types.GET_FEEDBACK_BY_ORDER_ID_REQUEST:
      return { ...state, fetchingFeedbackByOrderId: true };
    case types.GET_FEEDBACK_BY_ORDER_ID_SUCCESS:
      return {
        ...state,
        fetchingFeedbackByOrderId: false,
        orderFeedbacks: action.payload,
      };
    case types.GET_FEEDBACK_BY_ORDER_ID_FAILURE:
      return {
        ...state,
        fetchingFeedbackByOrderId: false,
        fetchingFeedbackByOrderIdError: true,
      };

    case types.GET_FEEDBACK_BY_CONTACT_ID_REQUEST:
      return { ...state, fetchingFeedbackByContactId: true };
    case types.GET_FEEDBACK_BY_CONTACT_ID_SUCCESS:
      return {
        ...state,
        fetchingFeedbackByContactId: false,
        contactFeedbacks: action.payload,
      };
    case types.GET_FEEDBACK_BY_CONTACT_ID_FAILURE:
      return {
        ...state,
        fetchingFeedbackByContactId: false,
        fetchingFeedbackByContactIdError: true,
      };

    // * feedback-card modal
    // */
    case types.HANDLE_FEEDBACK_MODAL:
      return { ...state, feedbackModal: action.payload };

    // * get history of account
    // */
    case types.GET_CONTACT_HISTORY_REQUEST:
      return { ...state, fetchingAccountHistory: true };
    case types.GET_CONTACT_HISTORY_SUCCESS:
      return {
        ...state,
        fetchingContactHistory: false,
        contactHistory: action.payload,
      };
    case types.GET_CONTACT_HISTORY_FAILURE:
      return {
        ...state,
        fetchingContactHistory: false,
        fetchingContactHistoryError: true,
      };

    //SHARE Contact Customer Permissiom
    case types.ADD_SHARE_CONTACT_PERMISSION_REQUEST:
      return { ...state, addSharingContact: true };

    case types.ADD_SHARE_CONTACT_PERMISSION_SUCCESS:
      return { ...state, addSharingContact: false, contactByUserId: action.payload };

    case types.ADD_SHARE_CONTACT_PERMISSION_FAILURE:
      return {
        ...state,
        addSharingContact: false,
        addSharingContactError: true,
      };

    case types.GET_PERMISSIONS_LIST_CONTACT_REQUEST:
      return { ...state, fetchingPermissionsListContact: true };
    case types.GET_PERMISSIONS_LIST_CONTACT_SUCCESS:
      return {
        ...state,
        fetchingPermissionsListContact: false,
        permissionsDataListContact: action.payload,
      };
    case types.GET_PERMISSIONS_LIST_CONTACT_FAILURE:
      return {
        ...state,
        fetchingPermissionsListContact: false,
        fetchingPermissionsListContactError: false,
      };

    default:
      return state;
  }
  return state;
};

const newDateRange = (dateRange, newDate) =>
  dateRange.map((range) => {
    if (range.id === newDate.id) {
      return { ...range, isSelected: true };
    } else {
      return { ...range, isSelected: false };
    }
  });




