import Select from "antd/lib/select";
import styled from "styled-components";

const StyledSelect = styled(Select)`
  .ant-input {
    border-radius: 2px;
    border: 0.06em solid ${(props) => props.theme.inputBorderColor};
    background-color: ${(props) => props.theme.backgroundColor};
    color: ${(props) => props.theme.color};
    display: block;
    margin: 0.2rem 0;
    border-radius: 0.2rem;
    height: 2.25em;
    outline: none;
    // box-shadow: 0px 0.25em 0.62em -0.25em ${(props) => props.theme.boxShadowColor};
    box-shadow:${(props) => (props.isShadow ? "" : "0px 0.25em 0.62em -0.25em #aaa")} ; 
    padding: 0.3rem 1rem;
  }
  .ant-select-selection {
    // box-shadow: 0px 0.25em 0.62em -0.25em ${(props) => props.theme.boxShadowColor};
    box-shadow:${(props) => (props.isShadow ? "" : "0px 0.25em 0.62em -0.25em #aaa")} ; 
    border-right: ${(props) =>
    props.isRequired ? "0.18em solid #ed260b" : ""} !important;
    
  }
   .ant-select-selector {
    
    position: relative;
    background-color: #fff;
    border: 0.06em solid #d9d9d9;
    // border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-right: ${(props) =>
    props.isRequired ? "0.18em solid #ed260b" : ""} !important;
      box-shadow: rgb(170 170 170) 0px 0.25em 0.62em -0.25em;
      border-radius: 0.2rem;

}


    

  
  

  



`;
export default StyledSelect;
