import React from "react";
import { Table, Empty } from "antd";
import styled from "styled-components";

const MyTable = () => (
  <Table
    locale={{
      emptyText: <Empty description={"We couldn't find relevant data"} />,
    }}
  />
);

const StyledTable = styled(Table)`
  color: ${(props) => props.theme.color};
  background-color: ${(props) => props.theme.backgroundColor};
  margin: ${(props) => props.theme.margin || "0.2rem"};

  .ant-table {
    color: ${(props) => props.theme.color};
  }

  .ant-table-thead > tr > th {
    color: ${(props) => props.theme.color};
    background-color: ${(props) => props.theme.backgroundColor};
    font-weight: bold;
    font-size: 13px;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 2px 2px;
    font-size: 13px;;
  }

  .ant-table-thead > tr,
  .ant-table-tbody > tr {
    background-color: ${(props) => props.backgroundColor || ""};
  }
  .ant-table-tbody > tr:hover {
    background-color: ${(props) => props.theme.tableHoverColor};
  }
  .ant-table-tbody > tr:active {
    background-color: ${(props) => props.theme.tableHoverColor};
  }
  .ant-table-tbody > tr:focus {
    background-color: ${(props) => props.theme.tableHoverColor};
  }
  .dwXsDd .ant-table-thead > tr > th,
  .dwXsDd .ant-table-tbody > tr > td {
    padding: 2px 2px;
  }
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background-color: ${(props) => props.theme.tableHoverColor};
    color: ${(props) => props.theme.tableHoverFontColor};
  }
`;
export default StyledTable;
