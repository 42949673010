import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ApproveQuoteByAdmin } from "./AuthAction";
import Button from "antd/lib/button";
import { FlexContainer } from "../../Components/UI/Layout";
import { AuthContainer, FormContainer } from "./styled";
import { Title } from "../../Components/UI/Elements";
import { ClockLoader } from "../../Components/Placeholder";
import background5 from "../../Assets/Images/background5.png";

class ApproveQuoteByAdminValidation extends Component {
  constructor(props) {
    super(props);
  }
  handleApproveQuoteByAdminValidation = () => {
    const {
      history,
      match: {
        params: { orgId, quoteId },
      },
    } = this.props;
    this.props.ApproveQuoteByAdmin(orgId, quoteId, history);
  };
  componentDidMount() {
    this.handleApproveQuoteByAdminValidation();
  }
  render() {
    return (
      <AuthContainer backgroundImage={background5}>
        <FormContainer style={{ alignSelf: "center" }}>
          <FlexContainer
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Title color="#f4f4f4"
              // fontFamily="Abel" 
              fontFamily="Roboto"
              fontSize={"1.25em"}>
              Please wait while we complete Quote Approval...
            </Title>
            <ClockLoader />
          </FlexContainer>
        </FormContainer>
      </AuthContainer>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ApproveQuoteByAdmin }, dispatch);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApproveQuoteByAdminValidation)
);
