import * as types from "./OpportunityActionTypes";
import axios from "axios";
import moment from "moment";
import { base_url } from "../../Config/Auth";
import { message } from "antd";
import { getDeliveryUser } from "../Team/TeamAction";
import { UPDATE_TASK_SUCCESS } from "../Task/TaskActionTypes";

export const setCurrentRecruitMentData = (data) => (dispatch) => {
  dispatch({ type: types.SET_CURRENT_RECRUITMENT_DATA, payload: data });
};

export const updateRecruitment = (data, opportunityId, cb) => (dispatch) => {
  dispatch({ type: types.UPDATE_RECRUITMENT_REQUEST });

  axios
    .put(`${base_url}/update/recriutment`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      //debugger;
      dispatch(getRecruitByOpportunityId(opportunityId));
      console.log("data...............", res);
      cb && cb("success", res.data.sponserId);

      // message.success("Update successfully!");
      dispatch({
        type: types.UPDATE_RECRUITMENT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      //debugger;
      console.log(err);
      dispatch({
        type: types.UPDATE_RECRUITMENT_FAILURE,
      });
      cb && cb("failure");
    });
};

export const tagContact = (taskId, data) => (dispatch) => {
  dispatch({ type: types.ADD_TAG_CONTACT_REQUEST });

  axios
    .put(`${base_url}/link/opportunity/task-contact`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch({
        type: types.ADD_TAG_CONTACT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_TAG_CONTACT_FAILURE,
      });
    });
};

export const approve = (taskId, data) => (dispatch) => {
  dispatch({ type: types.ADD_APPROVE_REQUEST });

  axios
    .put(`${base_url}/link/opportunity/task-level`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch({
        type: types.ADD_APPROVE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_APPROVE_FAILURE,
      });
    });
};

export const afterApprove = (taskId, data, cb) => (dispatch) => {
  dispatch({ type: types.AFTER_APPROVE_REQUEST });

  axios
    .put(`${base_url}/link/opportunity/task-approval`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch({
        type: types.AFTER_APPROVE_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: UPDATE_TASK_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })

    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.AFTER_APPROVE_FAILURE,
      });
    });
};

// export const contactTask = (taskId, data) => dispatch => {
//   dispatch({ type: types.TASK_CONTACT_REQUEST });

//   axios
//     .put(`${base_url}/afterApproval/${taskId}`, data, {
//       headers: {
//         Authorization: "Bearer " + sessionStorage.getItem("token") || ""
//       }
//     })

//     .then(res => {
//       console.log(res);
//       dispatch({
//         type: types.TASK_CONTACT_SUCCESS,
//         payload: res.data
//       });
//     })
//     .catch(err => {
//       console.log(err);
//       dispatch({
//         type: types.TASK_CONTACT_FAILURE
//       });
//     });
// };

export const addDelivery = (data) => (dispatch) => {
  dispatch({ type: types.ADD_DELIVERY_REQUEST });

  axios
    .post(`${base_url}/delivery/opportunty`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      dispatch(getDeliveryUser());
      console.log(res);
      dispatch({
        type: types.ADD_DELIVERY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_DELIVERY_FAILURE,
      });
    });
};

export const getDelivery = (opportunityId) => (dispatch) => {
  dispatch({ type: types.GET_DELIVERY_REQUEST });
  axios
    .get(`${base_url}/opportunitydelivery/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_DELIVERY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_DELIVERY_FAILURE,
        payload: err,
      });
    });
};
export const handleLinkConfigureModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_LINK_CONFIGURE_MODAL,
    payload: modalProps,
  });
};

export const handleCostingModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_COSTING_MODAL,
    payload: modalProps,
  });
};
/**
 * get all the opportunity of the user
 */
export const getOpportunities = (userId) => (dispatch) => {
  let api_url = "";
  if (userId) {
    api_url = `/sort/all/opportunities/user/${userId}`;
  } else {
    api_url = `/opportunities`;
  }
  dispatch({
    type: types.GET_OPPORTUNITIES_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_OPPORTUNITIES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_OPPORTUNITIES_FAILURE,
        payload: err,
      });
    });
};

/**
 * get all the latest opportunity of the user
 */
export const getLatestOpportunities = (userId, startDate, endDate) => (
  dispatch
) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/sort/opportunities/user/${userId}?sort=latest`;
  } else {
    api_url = `/sort/opportunities/user/${userId}?sort=latest&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_LATEST_OPPORTUNITIES_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_LATEST_OPPORTUNITIES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_LATEST_OPPORTUNITIES_FAILURE,
        payload: err,
      });
    });
};
export const getAllLatestOpportunities = (userId, startDate, endDate) => (
  dispatch
) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/sort/all/opportunities/user/${userId}?sort=latest`;
  } else {
    api_url = `/sort/all/opportunities/user/${userId}?sort=latest&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_ALL_LATEST_OPPORTUNITIES_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ALL_LATEST_OPPORTUNITIES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ALL_LATEST_OPPORTUNITIES_FAILURE,
        payload: err,
      });
    });
};

// /OPPORTUNITY CONTACTS LAZY LOADING

export const getAllLatestOpportunitiesForLazyLoading = (
  pageNo,
  userId,
  startDate,
  endDate
) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/sort/all/opportunities/user/${userId}?sort=latest`;
  } else {
    api_url = `/myView/report/oppertunity/${pageNo}?userId=${userId}&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_ALL_LATEST_OPPORTUNITIES_FOR_LAZY_LOADING_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ALL_LATEST_OPPORTUNITIES_FOR_LAZY_LOADING_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ALL_LATEST_OPPORTUNITIES_FOR_LAZY_LOADING_FAILURE,
        payload: err,
      });
    });
};
/**
 * get all the latest opportunity of the user
 */
export const getLatestOpportunitiesByOrganizationId = (
  organizationId,
  startDate,
  endDate
) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/sort/opportunities/organization/${organizationId}?`;
  } else {
    api_url = `/sort/opportunities/organization/${organizationId}?&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_FAILURE,
        payload: err,
      });
    });
};

//opportunity report lazy loading by organisation id
export const getLatestOpportunitiesByOrganizationIdForLazyLoading = (
  pageNo,
  organizationId,
  startDate,
  endDate
) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/sort/opportunities/organization/${organizationId}?`;
  } else {
    api_url = `/orgView/report/opportunity/${pageNo}?orgId=${organizationId}&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type:
      types.GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_FOR_LAZY_LOADING_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type:
          types.GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_FOR_LAZY_LOADING_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type:
          types.GET_LATEST_OPPORTUNITIES_BY_ORGANIZATION_ID_FOR_LAZY_LOADING_FAILURE,
        payload: err,
      });
    });
};
/**
 * get all the opportunity of the user
 */
export const getOpportunitiesByPrice = (userId, startDate, endDate) => (
  dispatch
) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/sort/opportunities/user/${userId}?sort=price`;
  } else {
    api_url = `/sort/opportunities/user/${userId}?sort=price&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_OPPORTUNITIES_BY_PRICE_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_OPPORTUNITIES_BY_PRICE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_OPPORTUNITIES_BY_PRICE_FAILURE,
        payload: err,
      });
    });
};
export const getAllOpportunitiesByPrice = (userId, startDate, endDate) => (
  dispatch
) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/sort/all/opportunities/user/${userId}?sort=price`;
  } else {
    api_url = `/sort/all/opportunities/user/${userId}?sort=price&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_ALL_OPPORTUNITIES_BY_PRICE_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ALL_OPPORTUNITIES_BY_PRICE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ALL_OPPORTUNITIES_BY_PRICE_FAILURE,
        payload: err,
      });
    });
};

export const getAllOpportunitiesByWinloss = (userId, startDate, endDate) => (
  dispatch
) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/wonOpportunity?sort=price`;
  } else {
    api_url = `/wonOpportunity?sort=price&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_OPPORTUNITIES_BY_WINLOSS_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_OPPORTUNITIES_BY_WINLOSS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_OPPORTUNITIES_BY_WINLOSS_FAILURE,
        payload: err,
      });
    });
};

/**
 * get all the opportunity of the user
 */
export const updateOpportunityStage = (
  sourceStageId,
  destinationStageId,
  opportunityId,
  cb
) => (dispatch) => {
  console.log(sourceStageId, destinationStageId, opportunityId);
  if (destinationStageId === "won") {
    message.success("stage is won");
  }
  if (destinationStageId === "loss") {
    message.error("stage is loss");
  }
  dispatch({
    type: types.UPDATE_OPPORTUNITY_STAGE_REQUEST,
    payload: {
      sourceStageId,
      destinationStageId,
      opportunityId,
    },
  });
  axios
    .put(
      `${base_url}/opportunity/${opportunityId}`,
      { stageId: destinationStageId },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      // if (res.data.stageName === "Won") {
      //   message.error("Won");
      // } else {
      //   message.error("Loss");
      // }

      dispatch({
        type: types.UPDATE_OPPORTUNITY_STAGE_SUCCESS,
        payload: res.data,
      });
      cb && cb(res.data);
    })
    .catch((err) => {
      console.log(err);

      dispatch({
        type: types.UPDATE_OPPORTUNITY_STAGE_FAILURE,
        payload: err,
      });
      cb && cb("failure");
    });
};

//update opportunitySatge under Particular Opportunity

export const updateOpportunityStageByParticularOpportunity = (
  StageId,
  opportunityId,
  cb
) => (dispatch) => {
  dispatch({
    type: types.UPDATE_OPPORTUNITY_BY_PARTICULAR_OPPORTUNIY_STAGE_REQUEST,
  });
  axios
    .put(
      `${base_url}/opportunity/${opportunityId}`,
      { stageId: StageId },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);

      dispatch(getOpportunityById(opportunityId));
      dispatch({
        type: types.UPDATE_OPPORTUNITY_BY_PARTICULAR_OPPORTUNIY_STAGE_SUCCESS,
        payload: res.data,
      });
      cb && cb(res.data);
    })
    .catch((err) => {
      console.log(err);

      dispatch({
        type: types.UPDATE_OPPORTUNITY_BY_PARTICULAR_OPPORTUNIY_STAGE_FAILURE,
        payload: err,
      });
      cb && cb("failure");
    });
};
/**
 * get all the opportunity stages
 */
export const getStages = () => (dispatch) => {
  dispatch({
    type: types.GET_STAGES_REQUEST,
  });
  axios
    .get(`${base_url}/stages`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_STAGES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_STAGES_FAILURE,
        payload: err,
      });
    });
};

/**
 * add a new stage of opportunity
 */
// export const addStage = (stage, cb) => dispatch => {
//   dispatch({ type: types.ADD_STAGE_REQUEST });

//   axios
//     .post(`${base_url}/stage`, stage, {
//       headers: {
//         Authorization: "Bearer " + sessionStorage.getItem("token") || ""
//       }
//     })
//     .then(res => {
//       console.log(res);
//       dispatch({
//         type: types.ADD_STAGE_SUCCESS,
//         payload: { ...stage, stageId: res.data }
//       });
//     })
//     .catch(err => {
//       console.log(err);
//       dispatch({
//         type: types.ADD_STAGE_FAILURE
//       });
//     });
// };

/**
 * remove a new stage of opportunity
 */
export const removeStage = (stageId, cb) => (dispatch) => {
  console.log(stageId);
  dispatch({
    type: types.REMOVE_STAGE_REQUEST,
  });
  axios
    .delete(`${base_url}/stage/${stageId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.REMOVE_STAGE_SUCCESS,
        payload: stageId,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.REMOVE_STAGE_FAILURE,
      });
    });
};

/**
 *update label of stage of opportunity
 */
export const updateStage = (stageId, stageName, cb) => (dispatch) => {
  console.log(stageId, stageName);
  dispatch({
    type: types.UPDATE_STAGE_REQUEST,
  });
  axios
    .put(
      `${base_url}/stage/${stageId}`,
      { stageName },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_STAGE_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_STAGE_FAILURE,
      });
      cb && cb("error");
    });
};

/**
 * get all the opportunity sources
 */
export const getSources = () => (dispatch) => {
  dispatch({
    type: types.GET_SOURCES_REQUEST,
  });
  axios
    .get(`${base_url}/sources`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_SOURCES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_SOURCES_FAILURE,
        payload: err,
      });
    });
};

/**
 * add a new source of opportunity
 */
export const addSource = (source, cb) => (dispatch) => {
  console.log(source);
  dispatch({
    type: types.ADD_SOURCE_REQUEST,
  });
  axios
    .post(`${base_url}/source`, source, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.ADD_SOURCE_SUCCESS,
        payload: { ...source, leadSourceId: res.data },
      });
      cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_SOURCE_FAILURE,
      });
      cb();
    });
};

/**
 * remove a new source of opportunity
 */
export const removeSource = (leadSourceId, cb) => (dispatch) => {
  console.log(leadSourceId);
  dispatch({
    type: types.REMOVE_SOURCE_REQUEST,
  });
  axios
    .delete(`${base_url}/source/${leadSourceId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.REMOVE_SOURCE_SUCCESS,
        payload: leadSourceId,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.REMOVE_SOURCE_FAILURE,
      });
    });
};

/**
 *update label of source of opportunity
 */
export const updateSource = (leadSourceId, sourceName, cb) => (dispatch) => {
  console.log(leadSourceId, sourceName);
  dispatch({
    type: types.UPDATE_SOURCE_REQUEST,
  });
  axios
    .put(
      `${base_url}/source/${leadSourceId}`,
      { sourceName },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_SOURCE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_SOURCE_FAILURE,
      });
    });
};

/**
 * get all the COUNTRIES
 */
export const getCountries = () => (dispatch) => {
  dispatch({
    type: types.GET_COUNTRIES_REQUEST,
  });
  axios
    .get(`${base_url}/countries`)
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_COUNTRIES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_COUNTRIES_FAILURE,
        payload: err,
      });
    });
};

export const getCurrency = () => (dispatch) => {
  dispatch({
    type: types.GET_CURRENCY_REQUEST,
  });
  axios
    .get(`${base_url}/currency`)
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CURRENCY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CURRENCY_FAILURE,
        payload: err,
      });
    });
};

/**
 * get a specific opportunity of the user with the opportunityId
 */
export const getOpportunityById = (opportunityId) => (dispatch) => {
  console.log("inside getOpportunityById");
  dispatch({
    type: types.GET_OPPORTUNITY_BY_ID_REQUEST,
  });
  axios
    .get(`${base_url}/opportunity/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_OPPORTUNITY_BY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_OPPORTUNITY_BY_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get account list by opportunityId
 */
export const getAccountListByOpportunityId = (opportunityId) => (dispatch) => {
  console.log(opportunityId);
  dispatch({
    type: types.GET_ACCOUNT_LIST_BY_OPPORTUNITY_ID_REQUEST,
  });
  axios
    .get(`${base_url}/account/opportunity/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ACCOUNT_LIST_BY_OPPORTUNITY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ACCOUNT_LIST_BY_OPPORTUNITY_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get opportunity list by opportunityId
 */
export const getContactListByOpportunityId = (opportunityId) => (dispatch) => {
  console.log(opportunityId);
  dispatch({
    type: types.GET_CONTACT_LIST_BY_OPPORTUNITY_ID_REQUEST,
  });
  axios
    .get(`${base_url}/contact/opportunity/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CONTACT_LIST_BY_OPPORTUNITY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CONTACT_LIST_BY_OPPORTUNITY_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get notes list by opportunityId
 */
export const getNotesListByOpportunityId = (opportunityId) => (dispatch) => {
  console.log(opportunityId);
  dispatch({
    type: types.GET_NOTES_LIST_BY_OPPORTUNITY_ID_REQUEST,
  });
  axios
    .get(`${base_url}/note/opportunity/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_NOTES_LIST_BY_OPPORTUNITY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_NOTES_LIST_BY_OPPORTUNITY_ID_FAILURE,
        payload: err,
      });
    });
};

export const getNotesListByOpportunityLinkTask = (taskId, opportunityId) => (
  dispatch
) => {
  console.log(opportunityId);
  dispatch({
    type: types.GET_NOTES_LIST_BY_OPPORTUNITY_LINK_TASK_ID_REQUEST,
  });
  axios
    .get(`${base_url}/note/task/${taskId}/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_NOTES_LIST_BY_OPPORTUNITY_LINK_TASK_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_NOTES_LIST_BY_OPPORTUNITYLINK_TASK_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get call list by opportunityId
 */
export const getCallsListByOpportunityId = (opportunityId) => (dispatch) => {
  console.log(opportunityId);
  dispatch({
    type: types.GET_CALLS_LIST_BY_OPPORTUNITY_ID_REQUEST,
  });
  axios
    .get(`${base_url}/call/opportunity/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CALLS_LIST_BY_OPPORTUNITY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CALLS_LIST_BY_OPPORTUNITY_ID_FAILURE,
        payload: err,
      });
    });
};
export const patchTask = (id, data, cb) => (dispatch) => {
  console.log(data);
  dispatch({ type: types.PATCH_TASK_BY_ID_REQUEST });
  axios
    .patch(
      `${base_url}/task/${id}`,
      { ...data },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.PATCH_TASK_BY_ID_SUCCESS,
        payload: res.data,
      });
      cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.PATCH_TASK_BY_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * get task list by opportunityId
 */
export const getTasksListByOpportunityId = (opportunityId) => (dispatch) => {
  console.log(opportunityId);
  dispatch({
    type: types.GET_TASKS_LIST_BY_OPPORTUNITY_ID_REQUEST,
  });
  axios
    .get(`${base_url}/task/opportunity/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_TASKS_LIST_BY_OPPORTUNITY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_TASKS_LIST_BY_OPPORTUNITY_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get event list by opportunityId
 */
export const getEventsListByOpportunityId = (opportunityId) => (dispatch) => {
  console.log(opportunityId);
  dispatch({
    type: types.GET_EVENTS_LIST_BY_OPPORTUNITY_ID_REQUEST,
  });
  axios
    .get(`${base_url}/event/opportunity/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_EVENTS_LIST_BY_OPPORTUNITY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_EVENTS_LIST_BY_OPPORTUNITY_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * request for adding a opportunity
 */
export const addOpportunity = (opportunity, cb) => (dispatch, getState) => {
  const userId = getState().auth.userDetails.userId;
  dispatch({
    type: types.ADD_OPPORTUNITY_REQUEST,
  });
  axios
    .post(`${base_url}/opportunity`, opportunity, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      const startDate = moment()
        .startOf("month")
        .toISOString();
      const endDate = moment()
        .endOf("month")
        .toISOString();
      dispatch(getOpportunities(userId));
      dispatch(getLatestOpportunities(userId, startDate, endDate));
      dispatch(getOpportunitiesByPrice(userId));
      dispatch({
        type: types.ADD_OPPORTUNITY_SUCCESS,
        payload: res.data,
      });
      cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_OPPORTUNITY_FAILURE,
        payload: err,
      });
    });
};

/**
 * update aspecific field using put request
 */
export const updateOpportunity = (id, data, cb) => (dispatch) => {
  console.log(data);
  dispatch({ type: types.UPDATE_OPPORTUNITY_BY_ID_REQUEST });
  axios
    .put(
      `${base_url}/opportunity/${id}`,
      { ...data },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      dispatch(getOpportunityDeviation(id));
      console.log(res);
      dispatch({
        type: types.UPDATE_OPPORTUNITY_BY_ID_SUCCESS,
        payload: res.data,
      });
      cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_OPPORTUNITY_BY_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get documents of an opportunity
 */
export const getOpportunityDocument = (opportunityId) => (dispatch) => {
  dispatch({ type: types.GET_OPPORTUNITY_DOCUMENTS_REQUEST });
  axios
    .get(`${base_url}/document/opportunity/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_OPPORTUNITY_DOCUMENTS_SUCCESS,
        payload: res.data,
      });
      // cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_OPPORTUNITY_DOCUMENTS_FAILURE,
        payload: err,
      });
    });
};

//opportunity playbook
export const getPlaybookOpportunity = (opportunityId) => (dispatch) => {
  dispatch({ type: types.GET_PLAYBOOK_OPPORTUNITY_DOCUMENTS_REQUEST });
  axios
    .get(`${base_url}/playbooks/opportunity/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PLAYBOOK_OPPORTUNITY_DOCUMENTS_SUCCESS,
        payload: res.data,
      });
      // cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_PLAYBOOK_OPPORTUNITY_DOCUMENTS_FAILURE,
        payload: err,
      });
    });
};

//get playBooks by stage id
export const getPlaybookByStageId = (stageId) => (dispatch) => {
  dispatch({ type: types.GET_PLAYBOOK_BY_STAGEID_REQUEST });
  axios
    .get(`${base_url}/playbooks/${stageId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PLAYBOOK_BY_STAGEID_SUCCESS,
        payload: res.data,
      });
      // cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_PLAYBOOK_BY_STAGEID_FAILURE,
        payload: err,
      });
    });
};
//Playbook Youtube
export const getPlaybookOpportunityYoutube = (opportunityId) => (dispatch) => {
  dispatch({ type: types.GET_PLAYBOOK_OPPORTUNITY_YOUTUBE_REQUEST });
  axios
    .get(`${base_url}/playbook/youtubes/opportunity/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PLAYBOOK_OPPORTUNITY_YOUTUBE_SUCCESS,
        payload: res.data,
      });
      // cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_PLAYBOOK_OPPORTUNITY_YOUTUBE_FAILURE,
        payload: err,
      });
    });
};

export const deleteDocument = (documentId) => (dispatch, getState) => {
  console.log("inside deleteDocument", documentId);
  const { opportunityId } = getState("opportunity").opportunity.opportunity;
  dispatch({
    type: types.DELETE_DOCUMENT_REQUEST,
  });

  axios
    .delete(`${base_url}/opportunity/${opportunityId}/document/${documentId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      dispatch({
        type: types.DELETE_DOCUMENT_SUCCESS,
        payload: documentId,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.DELETE_DOCUMENT_FAILURE,
        payload: err,
      });
    });
};

/**
 * link document to a opportunity
 */
export const addOpportunityDocument = (documentId, data, cb) => (dispatch) => {
  console.log(data);
  dispatch({ type: types.ADD_OPPORTUNITY_DOCUMENT_REQUEST });
  axios
    .put(`${base_url}/opportunity/document/${documentId}`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.ADD_OPPORTUNITY_DOCUMENT_SUCCESS,
        payload: res.data,
      });
      cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_OPPORTUNITY_DOCUMENT_FAILURE,
        payload: err,
      });
    });
};

/**
 * opportunity modal action
 */
export const handleOpportunityModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_OPPORTUNITY_MODAL,
    payload: modalProps,
  });
};

/**
 * link opportunity modal action
 */
export const handleLinkOpportunityModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_LINK_OPPORTUNITY_MODAL,
    payload: modalProps,
  });
};
/**
 * link product modal action
 */
export const handleLinkProductModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_LINK_PROFESSIONALDUCT_MODAL,
    payload: modalProps,
  });
};
/**
 * link delivery modal action
 */
export const handleLinkDeliveryModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_LINK_DELIVERY_MODAL,
    payload: modalProps,
  });
};
/**
 * link service action
 */
export const handleLinkServiceModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_LINK_SERVICE_MODAL,
    payload: modalProps,
  });
};

/**
 * document upload modal in opportunity
 */
export const handleDocumentUploadModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_DOCUMENT_UPLOAD_MODAL,
    payload: modalProps,
  });
};

/**
 * opportunity modal drawer
 */
export const handleOpportunityDrawer = (drawerProps, isVisible) => (
  dispatch
) => {
  dispatch({
    type: types.HANDLE_OPPORTUNITY_DRAWER,
    payload: { props: drawerProps, isVisible: isVisible },
  });
};

/**
 * set company name, domain and logo from clearbit
 */
export const setClearbitData = (data) => (dispatch) => {
  dispatch({
    type: types.SET_CLEARBIT_DATA,
    payload: data,
  });
};

/**
 * SET ACCCOUNT VIEW TYPE
 * TABLE VIEW/CARD VIEW
 */
export const setOpportunityViewType = (viewType) => (dispatch) =>
  dispatch({ type: types.SET_OPPORTUNITY_VIEW_TYPE, payload: viewType });

/**
 * SET ACCCOUNT FILTER ETXT
 */
export const setOpportunityFilterText = (opportunityFilterText) => (dispatch) =>
  dispatch({
    type: types.SET_OPPORTUNITY_FILTER_TEXT,
    payload: opportunityFilterText,
  });

/**
 * set opportunity filter by User
 */
export const setOpportunityFilterUser = (user) => (dispatch) =>
  dispatch({ type: types.SET_OPPORTUNITY_FILTER_USER, payload: user });
//Before Link check

export const linkContactsCheckToOpportunity = (
  opportunityId,
  associations,
  cb
) => (dispatch) => {
  console.log(opportunityId, associations);
  dispatch({
    type: types.LINK_CONTACTS_TO_CHECK_OPPORTUNITY_ID_REQUEST,
  });
  axios
    .post(
      `${base_url}/check/opportunity/contact/${opportunityId}`,
      associations,
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      if (res.data === true) {
        message.error("This contact is already taggged with this Opportunity");
      } else {
        dispatch(linkContactsToOpportunity(opportunityId, associations, cb));
      }
      console.log(res);
      dispatch({
        type: types.LINK_CONTACTS_TO_CHECK_OPPORTUNITY_ID_SUCCESS,
        payload: res.data,
      });
      cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.LINK_CONTACTS_TO_CHECK_OPPORTUNITY_ID_FAILURE,
      });
      cb();
    });
};

/**
 * link contacts to an opportunity
 */
export const linkContactsToOpportunity = (opportunityId, associations, cb) => (
  dispatch
) => {
  console.log(opportunityId, associations);
  dispatch({
    type: types.LINK_CONTACTS_TO_OPPORTUNITY_ID_REQUEST,
  });
  axios
    .post(
      `${base_url}/linkContacts/opportunity/${opportunityId}`,
      associations,
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.LINK_CONTACTS_TO_OPPORTUNITY_ID_SUCCESS,
        payload: res.data,
      });
      cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.LINK_CONTACTS_TO_OPPORTUNITY_ID_FAILURE,
      });
      cb();
    });
};

/**
 * unlink a opportunity from opportunity
 */
export const linkAccountToOpportunity = (opportunityId, accountId, cb) => (
  dispatch
) => {
  console.log(opportunityId, accountId);
  axios
    .post(`${base_url}/opportunity/${opportunityId}/account/${accountId}`, [], {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_ACCOUNT_LIST_BY_OPPORTUNITY_ID_SUCCESS,
        // payload: accountId
      });
      cb(accountId);
    })
    .catch((err) => {
      console.log(err);
      cb();
    });
};

/**
 * unlink a opportunity from opportunity
 */
export const unlinkAccountFromOpportunity = (opportunityId, accountId, cb) => (
  dispatch
) => {
  console.log(opportunityId, accountId);
  axios
    .delete(`${base_url}/opportunity/${opportunityId}/account/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_ACCOUNT_LIST_BY_OPPORTUNITY_ID_SUCCESS,
        // payload: contactId
      });
      cb();
    })
    .catch((err) => {
      console.log(err);
      cb();
    });
};

/**
 * unlink a opportunity from opportunity
 */
export const unlinkContactFromOpportunity = (opportunityId, contactId) => (
  dispatch
) => {
  console.log(opportunityId, contactId);
  axios
    .delete(`${base_url}/opportunity/${opportunityId}/contact/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_CONTACT_LIST_BY_OPPORTUNITY_ID_SUCCESS,
        payload: contactId,
      });
    })
    .catch((err) => console.log(err));
};

/**
 * unlink a call from opportunity
 */
export const unlinkCallFromOpportunity = (opportunityId, callId) => (
  dispatch
) => {
  console.log(opportunityId, callId);
  axios
    .delete(`${base_url}/opportunity/${opportunityId}/call/${callId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_CALLS_LIST_BY_OPPORTUNITY_ID_SUCCESS,
        payload: callId,
      });
    })
    .catch((err) => console.log(err));
};

/**
 * unlink a task from opportunity
 */
export const unlinkTaskFromOpportunity = (opportunityId, taskId) => (
  dispatch
) => {
  console.log(opportunityId, taskId);
  axios
    .delete(`${base_url}/opportunity/${opportunityId}/task/${taskId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_TASKS_LIST_BY_OPPORTUNITY_ID_SUCCESS,
        payload: taskId,
      });
    })
    .catch((err) => console.log(err));
};

/**
 * unlink a event from opportunity
 */
export const unlinkEventFromOpportunity = (opportunityId, eventId) => (
  dispatch
) => {
  console.log(opportunityId, eventId);
  axios
    .delete(`${base_url}/opportunity/${opportunityId}/event/${eventId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_EVENTS_LIST_BY_OPPORTUNITY_ID_SUCCESS,
        payload: eventId,
      });
    })
    .catch((err) => console.log(err));
};

/**
 *get data for trend chart opportunity
 */
export const getHistoricalProposalAmount = (opportunityId) => (dispatch) => {
  console.log(sessionStorage.getItem("token"));
  dispatch({ type: types.GET_HISTORICAL_PROFESSIONALPOSAL_AMOUNT_REQUEST });
  axios
    .get(`${base_url}/opportunity/amount/history/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_HISTORICAL_PROFESSIONALPOSAL_AMOUNT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_HISTORICAL_PROFESSIONALPOSAL_AMOUNT_FAILURE,
        payload: err,
      });
    });
};
/**
 *get data for opportunity velocty
 */
export const getOpportunityVelocity = (userId) => (dispatch) => {
  dispatch({ type: types.GET_OPPORTUNITY_VELOCITY_REQUEST });
  axios
    .get(`${base_url}/user/opportunityVelocity/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_OPPORTUNITY_VELOCITY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_OPPORTUNITY_VELOCITY_FAILURE,
        payload: err,
      });
    });
};
/**
 *get data for opportunity velocty by organization Id
 */
export const getOpportunityVelocityByOrganizationId = (organizatonId) => (
  dispatch
) => {
  dispatch({ type: types.GET_OPPORTUNITY_VELOCITY_BY_ORGANIZATION_ID_REQUEST });
  axios
    .get(`${base_url}/organization/opportunityVelocity/${organizatonId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_OPPORTUNITY_VELOCITY_BY_ORGANIZATION_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_OPPORTUNITY_VELOCITY_BY_ORGANIZATION_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 *get data for opportunity velocty by organization Id
 */
export const getOpportunityDeviation = (opportunityId) => (dispatch) => {
  dispatch({ type: types.GET_OPPORTUNITY_DEVIATION_REQUEST });
  axios
    .get(`${base_url}/deviationAmount/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_OPPORTUNITY_DEVIATION_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_OPPORTUNITY_DEVIATION_FAILURE,
        payload: err,
      });
    });
};

/**
 * set role of a contact for an opportunity
 */
export const setContactRoleForOpportunity = (
  opportunityId,
  contactId,
  role
) => (dispatch) => {
  console.log(opportunityId, contactId, role);
  console.log(sessionStorage.getItem("token"));
  axios
    .post(
      `${base_url}/opportunity/${opportunityId}/contact/${contactId}?contactRole=${role}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_CONTACT_ROLE_BY_OPPORTUNITY_ID_SUCCESS,
        payload: { contactId, role },
      });
    })
    .catch((err) => console.log(err));
};
/**
 *get all linked products of an organization
 */
export const getProductsByOpportunityId = (opportunityId) => (dispatch) => {
  dispatch({
    type: types.FETCHING_PROFESSIONALDUCTS_BY_OPPORTUNITY_ID_REQUEST,
  });
  axios
    .get(`${base_url}/opportunity/productDetails/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.FETCHING_PROFESSIONALDUCTS_BY_OPPORTUNITY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.FETCHING_PROFESSIONALDUCTS_BY_OPPORTUNITY_ID_FAILURE,
        payload: err,
      });
    });
};

export const getServiceByOpportunityId = (opportunityId) => (dispatch) => {
  dispatch({
    type: types.FETCHING_SERVICE_BY_OPPORTUNITY_ID_REQUEST,
  });
  axios
    .get(`${base_url}/opportunity/serviceDetails/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.FETCHING_SERVICE_BY_OPPORTUNITY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.FETCHING_SERVICE_BY_OPPORTUNITY_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * linke product to a opportunity
 */
export const linkProductToOpportunity = (opportunityId, data, cb) => (
  dispatch
) => {
  console.log(opportunityId);
  dispatch({ type: types.LINK_PROFESSIONALDUCT_TO_OPPORTUNITY_REQUEST });
  axios
    .post(`${base_url}/link/opportunity/product/${opportunityId}`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.LINK_PROFESSIONALDUCT_TO_OPPORTUNITY_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.LINK_PROFESSIONALDUCT_TO_OPPORTUNITY_FAILURE,
      });
      cb && cb("error");
    });
};

export const linkServiceToOpportunity = (opportunityId, data, cb) => (
  dispatch
) => {
  console.log(opportunityId);
  dispatch({ type: types.LINK_SERVICE_TO_OPPORTUNITY_REQUEST });
  axios
    .post(`${base_url}/link/opportunity/service/${opportunityId}`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(getServiceByOpportunityId(opportunityId));
      dispatch({
        type: types.LINK_SERVICE_TO_OPPORTUNITY_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.LINK_SERVICE_TO_OPPORTUNITY_FAILURE,
      });
      cb && cb("error");
    });
};

export const addReson = (data, cb) => (dispatch) => {
  //debugger;
  dispatch({ type: types.ADD_REASON_REQUEST });

  axios
    .post(`${base_url}/reason/stageChange`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch({
        type: types.ADD_REASON_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_REASON_FAILURE,
      });
      cb && cb("failure");
    });
};

export const getQuotation = (opportunityId) => (dispatch) => {
  dispatch({
    type: types.FETCH_QUOTATION_REQUEST,
  });
  axios
    .get(`${base_url}/opportunity/quote/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.FETCH_QUOTATION_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.FETCH_QUOTATION_FAILURE,
        payload: err,
      });
    });
};

export const generateQuotation = (finalData, opportunityId, cb) => (
  dispatch
) => {
  console.log(finalData);
  dispatch({
    type: types.GENERATE_QUOTATION_REQUEST,
  });
  axios
    .post(`${base_url}/opportunity/quote`, finalData, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      dispatch(getQuotation(opportunityId));

      console.log(res.data);
      cb && cb("Success", res.data);
      dispatch({
        type: types.GENERATE_QUOTATION_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      cb && cb("Failure", err);
      dispatch({
        type: types.GENERATE_QUOTATION_FAILURE,
        payload: err,
      });
    });
};

export const getCurrentQuotation = (quoteId) => (dispatch) => {
  dispatch({
    type: types.GET_CURRENT_QUOTATION_REQUEST,
  });
  axios
    .get(`${base_url}/quote/${quoteId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CURRENT_QUOTATION_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CURRENT_QUOTATION_FAILURE,
        payload: err,
      });
    });
};

export const approvedQuotation = (quoteId, opportunityId, data, cb) => (
  dispatch
) => {
  dispatch({
    type: types.APPROVED_QUOTATION_REQUEST,
  });
  axios
    .put(`${base_url}/update/quote/${quoteId}`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(getQuotation(opportunityId));
      dispatch({
        type: types.APPROVED_QUOTATION_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.APPROVED_QUOTATION_FAILURE,
        payload: err,
      });
      cb && cb("failure");
    });
};

//Recruit
export const handleRecruitModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_RECRUIT_MODAL,
    payload: modalProps,
  });
};

export const handleTagProfileModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_TAGPROFILE_MODAL,
    payload: modalProps,
  });
};

export const addRecruit = (data, opportunityId, cb) => (dispatch) => {
  dispatch({ type: types.LINK_RECRUIT_TO_OPPORTUNITY_REQUEST });

  axios
    .post(`${base_url}/link/recruitment/opportunity`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      message.success("Requirement added successfully!");
      // dispatch(getRecruitByOpportunityId(opportunityId));
      console.log(res);
      dispatch({
        type: types.LINK_RECRUIT_TO_OPPORTUNITY_SUCCESS,
        payload: res.data,
      });
      cb && cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.LINK_RECRUIT_TO_OPPORTUNITY_FAILURE,
      });
      cb && cb();
    });
};

export const getRecruitByOpportunityId = (opportunityId) => (dispatch) => {
  dispatch({ type: types.GET_RECRUIT_TO_OPPORTUNITY_REQUEST });

  axios
    .get(`${base_url}/recruitment/opportunity/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      // dispatch(getDeliveryUser());
      console.log(res);
      dispatch({
        type: types.GET_RECRUIT_TO_OPPORTUNITY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_RECRUIT_TO_OPPORTUNITY_FAILURE,
      });
    });
};

export const LinkCategoryRecruit = (data) => (dispatch) => {
  dispatch({ type: types.LINK_RECRUIT_CATEGORY_TO_OPPORTUNITY_REQUEST });

  axios
    .put(`${base_url}/link/recriutment/category `, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch({
        type: types.LINK_RECRUIT_CATEGORY_TO_OPPORTUNITY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.LINK_RECRUIT_CATEGORY_TO_OPPORTUNITY_FAILURE,
      });
    });
};

export const LinkContactRecruit = (data) => (dispatch) => {
  dispatch({ type: types.LINK_RECRUIT_CONTACT_TO_OPPORTUNITY_REQUEST });

  axios
    .put(`${base_url}/link/recriutment/contcat `, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch({
        type: types.LINK_RECRUIT_CONTACT_TO_OPPORTUNITY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.LINK_RECRUIT_CONTACT_TO_OPPORTUNITY_FAILURE,
      });
    });
};

export const LinkStageRecruit = (data, cb) => (dispatch) => {
  dispatch({ type: types.LINK_RECRUIT_STAGE_TO_OPPORTUNITY_REQUEST });

  axios
    .put(`${base_url}/update/recriutment/stage `, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);

      dispatch({
        type: types.LINK_RECRUIT_STAGE_TO_OPPORTUNITY_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.LINK_RECRUIT_STAGE_TO_OPPORTUNITY_FAILURE,
      });
      cb && cb("failure");
    });
};

export const addRecruitProProfile = (data, cb) => (dispatch) => {
  dispatch({ type: types.ADD_RECRUITMENT_PROFILE_REQUEST });

  axios
    .post(`${base_url}/create/profile `, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);

      dispatch({
        type: types.ADD_RECRUITMENT_PROFILE_SUCCESS,
        payload: res.data,
      });
      cb && cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_RECRUITMENT_PROFILE_FAILURE,
      });
      cb && cb();
    });
};

export const LinkStatusRecruit = (data, cb) => (dispatch) => {
  dispatch({ type: types.LINK_RECRUIT_STATUS_TO_OPPORTUNITY_REQUEST });

  axios
    .put(`${base_url}/update/recriutment/status `, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch({
        type: types.LINK_RECRUIT_STATUS_TO_OPPORTUNITY_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.LINK_RECRUIT_STATUS_TO_OPPORTUNITY_FAILURE,
      });
      cb && cb("failure");
    });
};

export const getQuoteListRangeByOrgIdForReport = (
  pageNo,
  organizationId,
  startDate,
  endDate
) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/orgView/report/quotation?orgId=${organizationId}`;
  } else {
    api_url = `/orgView/report/quotation/${pageNo}?orgId=${organizationId}&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_QUOTE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_QUOTE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_QUOTE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_FAILURE,
        payload: err,
      });
    });
};

export const getQuoteListRangeByUserIdForReport = (
  pageNo,
  userId,
  startDate,
  endDate
) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/myView/report/quotation?userId=${userId}`;
  } else {
    api_url = `/myView/report/quotation/${pageNo}?userId=${userId}&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_QUOTE_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_QUOTE_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_QUOTE_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE,
        payload: err,
      });
    });
};

export const getInvoiceListRangeByOrgIdForReport = (
  pageNo,
  organizationId,
  startDate,
  endDate
) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/orgView/report/invoice?orgId=${organizationId}`;
  } else {
    api_url = `/orgView/report/invoice/${pageNo}?orgId=${organizationId}&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_INVOICE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_INVOICE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_INVOICE_LIST_RANGE_BY_ORG_ID_FOR_REPORT_FAILURE,
        payload: err,
      });
    });
};

export const getInvoiceListRangeByUserIdForReport = (
  pageNo,
  userId,
  startDate,
  endDate
) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/myView/report/invoice?userId=${userId}`;
  } else {
    api_url = `/myView/report/invoice/${pageNo}?userId=${userId}&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_INVOICE_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_INVOICE_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_INVOICE_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE,
        payload: err,
      });
    });
};

export const emailSendQuote = (data) => (dispatch) => {
  dispatch({ type: types.EMAIL_SEND_FOR_QUOTE_REQUEST });

  axios
    .post(`${base_url}/emailSend/quote`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch({
        type: types.EMAIL_SEND_FOR_QUOTE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.EMAIL_SEND_FOR_QUOTE_FAILURE,
      });
    });
};

export const emailSendRecruitment = (data) => (dispatch) => {
  dispatch({ type: types.EMAIL_SEND_FOR_RECRUITMENT_REQUEST });

  axios
    .post(`${base_url}/sendMail/recruitment`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch({
        type: types.EMAIL_SEND_FOR_RECRUITMENT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.EMAIL_SEND_FOR_RECRUITMENT_FAILURE,
      });
    });
};

export const emailSendInvoice = (data) => (dispatch) => {
  dispatch({ type: types.EMAIL_SEND_FOR_INVOICE_REQUEST });

  axios
    .post(`${base_url}/emailSend/invoice`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch({
        type: types.EMAIL_SEND_FOR_INVOICE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.EMAIL_SEND_FOR_INVOICE_FAILURE,
      });
    });
};

export const handleAgeingModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_AGEING_MODAL,
    payload: modalProps,
  });
};

export const handleStageModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_STAGE_MODAL,
    payload: modalProps,
  });
};

export const handleMeetingsModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_MEETINGS_MODAL,
    payload: modalProps,
  });
};

export const handleCallsModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_CALLS_MODAL,
    payload: modalProps,
  });
};

export const handleRemarksModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_REMARKS_MODAL,
    payload: modalProps,
  });
};

export const getProfile = (oppId) => (dispatch) => {
  dispatch({ type: types.LINK_PROFILE_TO_OPPORTUNITY_REQUEST });

  axios
    .get(`${base_url}/linked/recruitments/${oppId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      // dispatch(getProfileByOpportunityId(opportunityId));
      console.log(res);
      dispatch({
        type: types.LINK_PROFILE_TO_OPPORTUNITY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.LINK_PROFILE_TO_OPPORTUNITY_FAILURE,
      });
    });
};

//Recruitment Remark
export const addRemark = (data, profileId) => (dispatch) => {
  dispatch({ type: types.ADD_REMARK_REQUEST });

  axios
    .post(`${base_url}/note/stage`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      dispatch(getRemark(profileId));
      console.log(res);
      dispatch({
        type: types.ADD_REMARK_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_REMARK_FAILURE,
      });
    });
};

export const getRemark = (profileId) => (dispatch) => {
  dispatch({ type: types.GET_REMARK_REQUEST });

  axios
    .get(`${base_url}/note/${profileId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_REMARK_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_REMARK_FAILURE,
      });
    });
};

//RecruimentDashBoard (For Jumpstart)

export const getAllRecruitmentByOppId = (oppId) => (dispatch) => {
  dispatch({ type: types.GET_ALL_RECRUITMENT_BY_OPP_ID_REQUEST });

  axios
    .get(`${base_url}/all/recruitment/${oppId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ALL_RECRUITMENT_BY_OPP_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ALL_RECRUITMENT_BY_OPP_ID_FAILURE,
      });
    });
};

export const getAllRecruitmentPositionByOppId = (oppId) => (dispatch) => {
  dispatch({ type: types.GET_RECRUITMENT_POSITION_BY_OPP_ID_REQUEST });

  axios
    .get(`${base_url}/positions/recruit/${oppId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_RECRUITMENT_POSITION_BY_OPP_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_RECRUITMENT_POSITION_BY_OPP_ID_FAILURE,
      });
    });
};

export const getAllRecruitmentAvgTimeByOppId = (oppId) => (dispatch) => {
  dispatch({ type: types.GET_RECRUITMENT_AVG_TIME_BY_OPP_ID_REQUEST });

  axios
    .get(`${base_url}/AvgTime/${oppId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_RECRUITMENT_AVG_TIME_BY_OPP_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_RECRUITMENT_AVG_TIME_BY_OPP_ID_FAILURE,
      });
    });
};

export const getAllRecruitmentDetailsByOppId = (oppId) => (dispatch) => {
  dispatch({ type: types.GET_ALL_RECRUITMENT_DETAILS_BY_OPP_ID_REQUEST });

  axios
    .get(`${base_url}/details/recruitment/${oppId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ALL_RECRUITMENT_DETAILS_BY_OPP_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ALL_RECRUITMENT_DETAILS_BY_OPP_ID_FAILURE,
      });
    });
};

export const getAllRecruitmentPositionFilledByOppId = (oppId) => (dispatch) => {
  dispatch({ type: types.GET_RECRUITMENT_POSITION_FILLED_BY_OPP_ID_REQUEST });

  axios
    .get(`${base_url}/position/closed/${oppId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_RECRUITMENT_POSITION_FILLED_BY_OPP_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_RECRUITMENT_POSITION_FILLED_BY_OPP_ID_FAILURE,
      });
    });
};

//candidate switch
export const candidateSwitch = (
  profileId,
  opportunityId,
  recruitmentId,
  contactId,
  cb
) => (dispatch) => {
  dispatch({ type: types.CANDIDATE_SWITCH_REQUEST });

  axios
    .post(
      `${base_url}/Offer/candidate`,
      {
        profileId: profileId,
        opportunityId: opportunityId,
        recruitmentId: recruitmentId,
        contactId: contactId,
      },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )

    .then((res) => {
      console.log(res);

      dispatch({
        type: types.CANDIDATE_SWITCH_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.CANDIDATE_SWITCH_FAILURE,
      });
      cb && cb("failure");
    });
};

//sponsore switch
export const sponsorSwitch = (
  profileId,
  opportunityId,
  recruitmentId,
  sponsorId,
  candidateId,
  cb
) => (dispatch) => {
  dispatch({ type: types.SPONSOR_SWITCH_REQUEST });

  axios
    .post(
      `${base_url}/Offer/sponser`,
      {
        profileId: profileId,
        opportunityId: opportunityId,
        recruitmentId: recruitmentId,
        sponserId: sponsorId,
        candidateId: candidateId,
      },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )

    .then((res) => {
      console.log(res);

      dispatch({
        type: types.SPONSOR_SWITCH_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.SPONSOR_SWITCH_FAILURE,
      });
      cb && cb("failure");
    });
};

//handle select sponsor
export const handleSponsorModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_SELECT_SPONSOR_MODAL,
    payload: modalProps,
  });
};

export const deleteOppProduct = (opportunityId, productId) => (
  dispatch,
  getState
) => {
  dispatch({
    type: types.DELETE_OPP_PRODUCT_REQUEST,
  });
  axios
    .delete(`${base_url}/opportunity/${opportunityId}/product/${productId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(getProductsByOpportunityId(opportunityId));
      dispatch({
        type: types.DELETE_OPP_PRODUCT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.DELETE_OPP_PRODUCT_FAILURE,
        payload: err,
      });
    });
};

export const deleteOppService = (opportunityId, serviceId) => (
  dispatch,
  getState
) => {
  dispatch({
    type: types.DELETE_OPP_SERVICE_REQUEST,
  });
  axios
    .delete(`${base_url}/opportunity/${opportunityId}/service/${serviceId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(getServiceByOpportunityId(opportunityId));
      dispatch({
        type: types.DELETE_OPP_SERVICE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.DELETE_OPP_SERVICE_FAILURE,
        payload: err,
      });
    });
};

export const handlePurchaseModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_PURCHASE_MODAL,
    payload: modalProps,
  });
};

export const addPurchase = (data, quoteId) => (dispatch) => {
  dispatch({
    type: types.ADD_PURCHASE_REQUEST,
  });
  axios
    .put(`${base_url}/purchase/product`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      dispatch(emailSendInvoice({ quoteId }));
      dispatch({
        type: types.ADD_PURCHASE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.ADD_PURCHASE_FAILURE,
        payload: err,
      });
    });
};

export const getPurchase = (quoteId) => (dispatch) => {
  dispatch({
    type: types.GET_PURCHASE_REQUEST,
  });
  axios
    .get(
      `${base_url}/purchase/${quoteId}`,

      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      dispatch({
        type: types.GET_PURCHASE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_PURCHASE_FAILURE,
        payload: err,
      });
    });
};

export const handlePlaybookModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_PLAYBOOK_MODAL,
    payload: modalProps,
  });
};

//card paybook
export const getPlaybook = (quoteId) => (dispatch) => {
  dispatch({
    type: types.GET_PLAYBOOK_REQUEST,
  });
  axios
    .get(
      `${base_url}/purchase/${quoteId}`,

      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      dispatch({
        type: types.GET_PLAYBOOK_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_PLAYBOOK_FAILURE,
        payload: err,
      });
    });
};

export const cleanUpQuotationTable = () => (dispatch) => {
  //debugger;
  dispatch({ type: types.CLEANUP_QUOTATION_TABLE });
};

//send email stage
export const emailSendStage = (data) => (dispatch) => {
  dispatch({ type: types.SEND_EMAIL_STAGE_UPDATE_REQUEST });

  axios
    .post(`${base_url}/sendMail/stage`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch({
        type: types.SEND_EMAIL_STAGE_UPDATE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.SEND_EMAIL_STAGE_UPDATE_FAILURE,
      });
    });
};

//Check Quote approve by quote id
export const checkQuoteApproveByQuoteId = (quoteId, cb) => (dispatch) => {
  dispatch({ type: types.CHECK_QUOTE_APPROVED_BY_QUOTE_ID_REQUEST });

  axios
    .get(`${base_url}/check/quote/approve/${quoteId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);

      dispatch({
        type: types.CHECK_QUOTE_APPROVED_BY_QUOTE_ID_SUCCESS,
        payload: res.data,
      });
      cb && cb("success", res.data);
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.CHECK_QUOTE_APPROVED_BY_QUOTE_ID_FAILURE,
      });
      cb && cb("failure");
    });
};

//Check Quote approve by opportunity id
export const checkQuoteApproveByOpportunityId = (oppId, data, cb) => (
  dispatch
) => {
  dispatch({ type: types.CHECK_QUOTE_APPROVED_BY_OPPORTUNITY_ID_REQUEST });

  axios
    .post(`${base_url}/check/quote/approve/${oppId}`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      if (!res.data) {
        message.info(
          "Quotation approval data has changed. Refresh for updates"
        );
      }

      dispatch({
        type: types.CHECK_QUOTE_APPROVED_BY_OPPORTUNITY_ID_SUCCESS,
        payload: res.data,
      });
      cb && cb("success", res.data);
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.CHECK_QUOTE_APPROVED_BY_OPPORTUNITY_ID_FAILURE,
      });
      cb && cb("failure");
    });
};

export const handleFashionModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_FASHION_MODAL,
    payload: modalProps,
  });
};

//add Fashion Item
export const addFashionItem = (data, oppId, cb) => (dispatch) => {
  dispatch({ type: types.ADD_FASHION_ITEM_REQUEST });
  axios
    .post(`${base_url}/opportunity/item-details`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch(getFashionItem(oppId));

      dispatch({
        type: types.ADD_FASHION_ITEM_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_FASHION_ITEM_FAILURE,
      });
      cb && cb("failure");
    });
};

//update fashion item

export const updateFashionItem = (data, itemId, cb) => (dispatch) => {
  dispatch({ type: types.UPDATE_FASHION_ITEM_REQUEST });
  axios
    .put(`${base_url}/opportunity/item-specification-details`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      // dispatch(getFashionItem(opportunityId));
      dispatch(getUploadItem(itemId));
      dispatch(handleFashionEditModal(false));

      dispatch({
        type: types.UPDATE_FASHION_ITEM_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_FASHION_ITEM_FAILURE,
      });
      cb && cb("failure");
    });
};

//GET Fashion Item
export const getFashionItem = (opportunityId) => (dispatch) => {
  dispatch({ type: types.GET_FASHION_ITEM_REQUEST });
  axios
    .get(`${base_url}/opportunity/item-details/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);

      dispatch({
        type: types.GET_FASHION_ITEM_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_FASHION_ITEM_FAILURE,
      });
    });
};
//add fashion item
export const addUploadItem = (data, cb, itemId) => (dispatch) => {
  dispatch({ type: types.ADD_UPLOAD_ITEM_REQUEST });
  axios
    .post(`${base_url}/opportunity/item-specification-details`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch(getUploadItem(itemId));
      cb && cb("success");
      dispatch({
        type: types.ADD_UPLOAD_ITEM_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_UPLOAD_ITEM_FAILURE,
      });
      cb && cb("failure");
    });
};

//GET Fashion Item
export const getUploadItem = (itemId) => (dispatch) => {
  dispatch({ type: types.GET_UPLOAD_ITEM_REQUEST });
  axios
    .get(`${base_url}/opportunity/item-specification-details/${itemId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);

      dispatch({
        type: types.GET_UPLOAD_ITEM_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_UPLOAD_ITEM_FAILURE,
      });
    });
};

//timeline dATA BY CONTACT ID

export const getTimeLineDataByOpportunityId = (opportunityId) => (dispatch) => {
  dispatch({
    type: types.GET_TIMELINE_DATA_BY_OPPORTUNITY_ID_REQUEST,
  });
  axios
    .get(`${base_url}/timeline/opportunity/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_TIMELINE_DATA_BY_OPPORTUNITY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_TIMELINE_DATA_BY_OPPORTUNITY_ID_FAILURE,
        payload: err,
      });
    });
};
export const handleFashionEditModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_FASHION_EDIT_MODAL,
    payload: modalProps,
  });
};

export const getStageCheckByStageId = (stageId, oppId) => (dispatch) => {
  dispatch({ type: types.GET_STAGE_CHECK_BY_STAGEID_REQUEST });
  axios
    .get(
      `${base_url}/opportunity/complitionTask/${stageId}/${oppId}`,

      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      if (res.data.taskInd) {
        message.success(res.data.message);
      }
      dispatch({
        type: types.GET_STAGE_CHECK_BY_STAGEID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_STAGE_CHECK_BY_STAGEID_FAILURE,
        payload: err,
      });
    });
};

// suspend

export const getStageDeleteByStageId = (stageId, oppId) => (dispatch) => {
  dispatch({ type: types.GET_STAGE_DELETE_BY_STAGEID_REQUEST });
  axios
    .delete(
      `${base_url}/opportunity/complitionTask/${stageId}/${oppId}`,

      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      if (res.data.taskInd) {
        message.success(res.data.message);
      }
      dispatch({
        type: types.GET_STAGE_DELETE_BY_STAGEID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_STAGE_DELETE_BY_STAGEID_FAILURE,
        payload: err,
      });
    });
};

export const handleOpportunityActivityModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_OPPORTUNITY_ACTIVITY_MODAL,
    payload: modalProps,
  });
};

export const getOpportunityActivityData = () => (dispatch) => {
  dispatch({
    type: types.GET_OPPORTUNITY_ACTIVITY_DATA_REQUEST,
  });
  axios
    .get(`${base_url}/Opportunity/categoryList`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_OPPORTUNITY_ACTIVITY_DATA_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_OPPORTUNITY_ACTIVITY_DATA_FAILURE,
        payload: err,
      });
    });
};

export const getOpportunityListRangeByUserIdForReport = (
  userId,
  type,
  startDate,
  endDate
) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/report/call/${userId}`;
  } else {
    api_url = `/myView/report/opportunity?userId=${userId}&type=${type}&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_OPPORTUNITY_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_OPPORTUNITY_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_OPPORTUNITY_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE,
        payload: err,
      });
    });
};

export const getQuotationListRangeByUserIdForReport = (
  userId,
  startDate,
  endDate
) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/report/call/${userId}`;
  } else {
    api_url = `/myView/report/quotation?userId=${userId}&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_QUOTATION_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_QUOTATION_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_QUOTATION_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE,
        payload: err,
      });
    });
};

export const getBillingListRangeByUserIdForReport = (
  userId,
  startDate,
  endDate
) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/report/call/${userId}`;
  } else {
    api_url = `/myView/report/invoice?userId=${userId}&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_BILLING_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_BILLING_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_BILLING_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE,
        payload: err,
      });
    });
};

export const getOpportunityStageData = (opportunityId, stageId) => (
  dispatch
) => {
  dispatch({
    type: types.GET_OPPORTUNITY_STAGE_DATA_REQUEST,
  });
  axios
    .get(
      `${base_url}/opportunity/opportunity-stage-disable/${opportunityId}/${stageId}`,
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_OPPORTUNITY_STAGE_DATA_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_OPPORTUNITY_STAGE_DATA_FAILURE,
        payload: err,
      });
    });
};

export const addOpportunityStageDisable = (data, cb) => (dispatch) => {
  dispatch({ type: types.ADD_OPPORTUNITY_STAGE_DISABLE_REQUEST });

  axios
    .post(
      `${base_url}/opportunity/opportunity-stage-disable`,

      data,
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )

    .then((res) => {
      console.log(res);
      dispatch({
        type: types.ADD_OPPORTUNITY_STAGE_DISABLE_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_OPPORTUNITY_STAGE_DISABLE_FAILURE,
      });
      cb && cb("failure");
    });
};

export const setClearbitCostingData = (data) => (dispatch) => {
  dispatch({
    type: types.SET_CLEARBIT_COSTING_DATA,
    payload: data,
  });
};

export const handleGalleryModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_GALLERY_MODAL,
    payload: modalProps,
  });
};

export const addGalleryItem = (data, itemId, cb) => (dispatch) => {
  dispatch({ type: types.ADD_GALLERY_ITEM_REQUEST });
  axios
    .post(`${base_url}/opportunity/item-image-details`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch(getGalleryItem(itemId));
      cb && cb("success");
      dispatch({
        type: types.ADD_GALLERY_ITEM_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_GALLERY_ITEM_FAILURE,
      });
      cb && cb("failure");
    });
};

export const getGalleryItem = (itemId) => (dispatch) => {
  dispatch({
    type: types.FETCH_GALLERY_REQUEST,
  });
  axios
    .get(`${base_url}/opportunity/item-image-details/${itemId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.FETCH_GALLERY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.FETCH_GALLERY_FAILURE,
        payload: err,
      });
    });
};

//COSTING SERVICE
export const getCostingServiceByOpportunityId = (opportunityId) => (
  dispatch
) => {
  dispatch({
    type: types.FETCHING_COSTING_SERVICE_BY_OPPORTUNITY_ID_REQUEST,
  });
  axios
    .get(`${base_url}/opportunity/serviceDetails/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.FETCHING_COSTING_SERVICE_BY_OPPORTUNITY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.FETCHING_COSTING_SERVICE_BY_OPPORTUNITY_ID_FAILURE,
        payload: err,
      });
    });
};

//COSTING PRODUCT
export const getCostingProductByOpportunityId = (opportunityId) => (
  dispatch
) => {
  dispatch({
    type: types.FETCHING_COSTING_PRODUCT_BY_OPPORTUNITY_ID_REQUEST,
  });
  axios
    .get(`${base_url}/opportunity/productDetails/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.FETCHING_COSTING_PRODUCT_BY_OPPORTUNITY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.FETCHING_COSTING_PRODUCT_BY_OPPORTUNITY_ID_FAILURE,
        payload: err,
      });
    });
};

export const linkCostingProductByTaskId = (taskId, data) => (dispatch) => {
  dispatch({ type: types.ADD_COSTING_PRODUCT_REQUEST });

  axios
    .post(`${base_url}/task/costsheet/product/${taskId}`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      dispatch(getProductsByTaskId(taskId));
      dispatch(handleCostingModal(false));
      console.log(res);
      dispatch({
        type: types.ADD_COSTING_PRODUCT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_COSTING_PRODUCT_FAILURE,
      });
    });
};

export const linkCostingServiceByTaskId = (taskId, data) => (dispatch) => {
  dispatch({ type: types.ADD_COSTING_SERVICE_REQUEST });

  axios
    .post(`${base_url}/task/costsheet/service/${taskId}`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      dispatch(getServiceByTaskId(taskId));
      dispatch(handleCostingModal(false));
      console.log(res);
      dispatch({
        type: types.ADD_COSTING_SERVICE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_COSTING_SERVICE_FAILURE,
      });
    });
};

export const getCostingProductAndServiceValue = (opportunityId) => (
  dispatch
) => {
  dispatch({
    type: types.FETCH_COSTING_PRODUCT_AND_SERVICE_REQUEST,
  });
  axios
    .get(`${base_url}/opportunity/quote/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.FETCH_COSTING_PRODUCT_AND_SERVICE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.FETCH_COSTING_PRODUCT_AND_SERVICE_FAILURE,
        payload: err,
      });
    });
};

//QUOTE DETAILS
export const getCurrentQuotationForCosting = (taskId) => (dispatch) => {
  dispatch({
    type: types.GET_CURRENT_QUOTATION_FOR_COSTING_REQUEST,
  });
  axios
    .get(`${base_url}/task/costsheet/${taskId} `, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CURRENT_QUOTATION_FOR_COSTING_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CURRENT_QUOTATION_FOR_COSTING_FAILURE,
        payload: err,
      });
    });
};

//get products by task id
export const getProductsByTaskId = (taskId) => (dispatch) => {
  dispatch({
    type: types.FETCHING_PRODUCTS_BY_TASK_ID_REQUEST,
  });
  axios
    .get(`${base_url}/task/costsheet/product/${taskId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.FETCHING_PRODUCTS_BY_TASK_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.FETCHING_PRODUCTS_BY_TASK_ID_FAILURE,
        payload: err,
      });
    });
};

//get service by task id
export const getServiceByTaskId = (taskId) => (dispatch) => {
  dispatch({
    type: types.FETCHING_SERVICE_BY_TASK_ID_REQUEST,
  });
  axios
    .get(`${base_url}/task/costsheet/service/${taskId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.FETCHING_SERVICE_BY_TASK_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.FETCHING_SERVICE_BY_TASK_ID_FAILURE,
        payload: err,
      });
    });
};

//GENERATE QUOTE
export const generateCostingQuotation = (finalData, taskId) => (dispatch) => {
  console.log(finalData);
  dispatch({
    type: types.GENERATE_COSTING_QUOTATION_REQUEST,
  });
  axios
    .post(`${base_url}/task/costsheet`, finalData, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      dispatch(getCurrentQuotationForCosting(taskId));

      console.log(res.data);

      dispatch({
        type: types.GENERATE_COSTING_QUOTATION_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);

      dispatch({
        type: types.GENERATE_COSTING_QUOTATION_FAILURE,
        payload: err,
      });
    });
};

//COST SHEET BY COST ID
export const getCostSheetByCostId = (costId) => (dispatch) => {
  dispatch({
    type: types.FETCHING_COST_SHEET_BY_COST_ID_REQUEST,
  });
  axios
    .get(`${base_url}/costsheet/${costId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.FETCHING_COST_SHEET_BY_COST_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.FETCHING_COST_SHEET_BY_COST_ID_FAILURE,
        payload: err,
      });
    });
};

export const getCostSheetByOpportunityId = (opportunityId) => (dispatch) => {
  dispatch({
    type: types.FETCHING_COST_SHEET_BY_OPPORTUNITY_ID_REQUEST,
  });
  axios
    .get(`${base_url}/opportunity/costsheet/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.FETCHING_COST_SHEET_BY_OPPORTUNITY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.FETCHING_COST_SHEET_BY_OPPORTUNITY_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get all the opportunity customer
 */
export const getCustomers = (orgId) => (dispatch) => {
  dispatch({
    type: types.GET_CUSTOMERS_REQUEST,
  });
  axios
    .get(`${base_url}/accountType/org/${orgId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CUSTOMERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CUSTOMERS_FAILURE,
        payload: err,
      });
    });
};

// /**
//  * add a new customer of opportunity
//  */
export const addCustomer = (customer, orgId) => (dispatch) => {
  console.log(customer);
  dispatch({
    type: types.ADD_CUSTOMER_REQUEST,
  });
  axios
    .post(`${base_url}/accounts/type`, customer, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      dispatch(getCustomers(orgId));
      console.log(res);
      dispatch({
        type: types.ADD_CUSTOMER_SUCCESS,
        payload: { ...customer, typeId: res.data },
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_CUSTOMER_FAILURE,
      });
    });
};

/**
 * remove a new customer of opportunity
 */
export const removeCustomer = (typeId, cb) => (dispatch) => {
  console.log(typeId);
  dispatch({
    type: types.REMOVE_CUSTOMER_REQUEST,
  });
  axios
    .delete(`${base_url}/delete/type/customer/${typeId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.REMOVE_CUSTOMER_SUCCESS,
        payload: typeId,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.REMOVE_CUSTOMER_FAILURE,
      });
    });
};

/**
 *update label of customer of opportunity
 */
export const updateCustomer = (typeId, type, cb) => (dispatch) => {
  console.log(typeId, type);
  dispatch({
    type: types.UPDATE_CUSTOMER_REQUEST,
  });
  axios
    .put(
      `${base_url}/update/type/customer`,
      { type, typeId },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_CUSTOMER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_CUSTOMER_FAILURE,
      });
    });
};

export const dropOpportunity = (opportunityId) => (dispatch, getState) => {
  // console.log(stageId);
  const userId = getState().auth.userDetails.userId;
  const fiscalMapper = getState().auth.userDetails.metaData.fiscalMapper;
  dispatch({
    type: types.DROP_OPPORTUNITY_BY_OPPORTUNITY_ID_REQUEST,
  });
  axios
    .delete(`${base_url}/drop/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      const startDate = moment()
        .startOf("month")
        .toISOString();
      const endDate = moment()
        .endOf("month")
        .toISOString();
      const startDate1 = fiscalMapper.fiscalStartDate;
      const endDate1 = moment().toISOString();
      dispatch(getLatestOpportunities(userId, startDate, endDate));
      dispatch(getOpportunitiesByPrice(userId));
      dispatch(getAllOpportunitiesByWinloss(userId, startDate1, endDate1));
      dispatch({
        type: types.DROP_OPPORTUNITY_BY_OPPORTUNITY_ID_SUCCESS,
        // payload: stageId,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.DROP_OPPORTUNITY_BY_OPPORTUNITY_ID_FAILURE,
      });
    });
};

/**
 * get all the opportunity contact
 */
export const getContacts = (orgId) => (dispatch) => {
  dispatch({
    type: types.GET_CONTACTS_REQUEST,
  });
  axios
    .get(`${base_url}/contact/designation/${orgId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CONTACTS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CONTACTS_FAILURE,
        payload: err,
      });
    });
};

// /**
//  * add a new contact of opportunity
//  */
export const addContact = (contact, orgId) => (dispatch) => {
  console.log(contact);
  dispatch({
    type: types.ADD_CONTACT_REQUEST,
  });
  axios
    .post(`${base_url}/save/contact/designation`, contact, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      dispatch(getContacts(orgId));
      console.log(res);
      dispatch({
        type: types.ADD_CONTACT_SUCCESS,
        payload: { ...contact, typeId: res.data },
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_CONTACT_FAILURE,
      });
    });
};

/**
 * remove a new contact of opportunity
 */
export const removeContact = (leadContactId, cb) => (dispatch) => {
  console.log(leadContactId);
  dispatch({
    type: types.REMOVE_CONTACT_REQUEST,
  });
  axios
    // .delete(`${base_url}/source/${leadCustomerId}`, {
    //   headers: {
    //     Authorization: "Bearer " + sessionStorage.getItem("token") || "",
    //   },
    // })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.REMOVE_CONTACT_SUCCESS,
        payload: leadContactId,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.REMOVE_CONTACT_FAILURE,
      });
    });
};

/**
 *update label of contact of opportunity
 */
export const updateContact = (leadContactId, contactName, cb) => (dispatch) => {
  console.log(leadContactId, contactName);
  dispatch({
    type: types.UPDATE_CONTACT_REQUEST,
  });
  axios
    // .put(
    //   `${base_url}/source/${leadCustomerId}`,
    //   { customerName },
    //   {
    //     headers: {
    //       Authorization: "Bearer " + sessionStorage.getItem("token") || "",
    //     },
    //   }
    // )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_CONTACT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_CONTACT_FAILURE,
      });
    });
};

// get document

export const getDocuments = () => (dispatch) => {
  dispatch({
    type: types.GET_DOCUMENT_REQUEST,
  });
  axios
    .get(`${base_url}/document`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_DOCUMENT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_DOCUMENT_FAILURE,
        payload: err,
      });
    });
};

// add document

export const addDocument = (document, orgId) => (dispatch) => {
  console.log(document);
  dispatch({
    type: types.ADD_DOCUMENT_REQUEST,
  });
  axios
    .post(`${base_url}/document`, document, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      // dispatch(getCustomers(orgId))
      console.log(res);
      dispatch({
        type: types.ADD_DOCUMENT_SUCCESS,
        payload: { ...document, typeId: res.data },
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_DOCUMENT_FAILURE,
      });
    });
};
/**
 * update event modal
 */
export const handleUpdateEventModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_UPDATE_EVENT_MODAL,
    payload: modalProps,
  });
};
/**
 * update call modal
 */
export const handleUpdateCallModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_UPDATE_CALL_MODAL,
    payload: modalProps,
  });
};

/**
 * update task modal
 */
export const handleUpdateTaskModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_UPDATE_TASK_MODAL,
    payload: modalProps,
  });
};

/**
 * Input data search
 */

export const inputDataSearch = (name) => (dispatch) => {
  dispatch({
    type: types.INPUT_SEARCH_OPPORTUNITY_DATA_REQUEST,
  });
  axios
    .get(`${base_url}/opportunity/Name/${name}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      if (res.data.opportunityId) {
        console.log(res.data);
        dispatch();
      }

      dispatch({
        type: types.INPUT_SEARCH_OPPORTUNITY_DATA_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.INPUT_SEARCH_OPPORTUNITY_DATA_FAILURE,
        payload: err,
      });
    });
};

/**
 * remove a new document of opportunity
 */
export const removeDocument = (leadDocumentId, cb) => (dispatch) => {
  console.log(leadDocumentId);
  dispatch({
    type: types.REMOVE_DOCUMENT_REQUEST,
  });
  axios
    .delete(`${base_url}/document/${leadDocumentId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.REMOVE_DOCUMENT_SUCCESS,
        payload: leadDocumentId,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.REMOVE_DOCUMENT_FAILURE,
      });
    });
};

/**
 *update label of document of opportunity
 */
export const updateDocument = (leadDocumentId, documentName, cb) => (dispatch) => {
  console.log(leadDocumentId, documentName);
  dispatch({
    type: types.UPDATE_DOCUMENT_REQUEST,
  });
  axios
    .put(
      `${base_url}/document/${leadDocumentId}`,
      { documentName },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_DOCUMENT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_DOCUMENT_FAILURE,
      });
    });
};


//CONTACT PERMISSION SHARE Of Partner
export const shareOpportunityPermission = (data) => (dispatch, getState) => {
  // const { userId } = getState("auth").auth.userDetails;
  dispatch({
    type: types.ADD_SHARE_OPPORTUNITY_PERMISSION_REQUEST,
  });

  axios
    // .post(`${base_url}/permission/details`, data, {
    //   headers: {
    //     Authorization: "Bearer " + sessionStorage.getItem("token") || "",
    //   },
    // })
    .then((res) => {
      console.log(res);
      // dispatch(getCustomerListByUserId(userId));
      dispatch({
        type: types.ADD_SHARE_OPPORTUNITY_PERMISSION_SUCCESS,
        payload: res.data,
      });
      // cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_SHARE_OPPORTUNITY_PERMISSION_FAILURE,
        payload: err,
      });
      // cb && cb("failure");
    });
};

export const getPermissionsListOpportunity = () => (dispath) => {
  dispath({ type: types.GET_PERMISSIONS_LIST_OPPORTUNITY_REQUEST });
  axios
    // .get(`${base_url}/permission/type?type=${"customer"}`, {
    //   headers: {
    //     Authorization: "Bearer " + sessionStorage.getItem("token") || "",
    //   },
    // })
    .then((res) => {
      dispath({
        type: types.GET_PERMISSIONS_LIST_OPPORTUNITY_SUCCESS,
        payload: res.data,
      });

    })
    .catch((err) => {
      dispath({
        type: types.GET_PERMISSIONS_LIST_OPPORTUNITY_FAILURE,
        payload: err,
      });
    });
};


