import * as types from "./RulesActionType";

const initialState = {
  addTemplateModal: false,

  addingSla: false,
  addingSlaError: false,

  addingSendPulse: false,
  addingSendPulseError: false,

  addWebsiteModal: false,

  fetchingSla: false,
  fetchingSlaError: false,
  sla: [],

  addingGenerateOpportunity: false,
  addingGenerateOpportunityError: false,

  fetchingGenerateOpportunity: false,
  fetchingGenerateOpportunityError: false,
  generateOpportunity: {},

  fetchingSendPulse: false,
  fetchingSendPulserror: false,
  sendPulse: {},

  fetchingMatrix: false,
  fetchingMatrixError: false,
  matrix: {},

  addingMatrix: false,
  addingMatrixError: false,

  addingTemplate: false,
  addingTemplateError: false,

  fetchingTemplate: false,
  fetchingTemplateError: false,
  template: [],

  addingNotificationTemplate: false,
  addingNotificationTemplateError: false,

  fetchingNotificationTemplate: false,
  fetchingNotificationTemplateError: false,
  notificationTemplate: [],

  addingLeadAging: false,
  addingLeadAgingError: false,

  fetchingLeadAging: false,
  fetchingLeadAgingError: false,
  leadAging: [],
  currentEmail: {},
  currentNotification: {},

  udatingEmail: false,
  udatingEmailError: false,

  udatingNotification: false,
  udatingNotificationError: false,

  addingAutoApproval: false,
  addingAutoApprovalError: false,

  fetchingAutoApproval: false,
  fetchingAutoApprovalError: false,
  autoApproval: [],

  //adding quotation

  addingQuotationsData: false,
  addingQuotationsDataError: false,

  fetchingaddingQuotation: false,
  fetchingaddingQuotationError: false,
  addingQuotation: [],

  //adding quotation ForNotification

  addingQuotationNotification: false,
  addingQuotatioNotificationError: false,

  fetchingaddingQuotationNotification: false,
  fetchingaddingQuotationNotificationError: false,
  quotationNotification: [],

  //Invoice
  addingInvoiceForEmail: false,
  addingInvoiceForEmailError: false,

  fetchingaddingInvoice: false,
  fetchingaddingInvoiceError: false,
  addingInvoice: [],

  addingInvoiceNotification: false,
  addingInvoiceNotificationError: false,

  fetchingaddingInvoiceNotification: false,
  fetchingaddingInvoiceNotificationError: false,
  invoiceNotification: [],

  //RecruitPro
  addingRecruitPro: false,
  addingRecruitProError: false,

  fetchingRecruitPro: false,
  fetchingRecruitProError: false,
  recruitProForEmail: [],

  addingQuoteSearchTab: false,
  addingQuoteSearchTabError: false,

  fetchingQuoteSearchTab: false,
  fetchingQuoteSearchTabError: false,
  searchTabData: {},

  //adding leads

  addingLeadsData: false,
  addingLeadsDataError: false,

  fetchingLeadsQuotation: false,
  fetchingLeadsQuotationError: false,
  leadsEmail: [],

  //adding leads ForNotification

  leadsQuotationNotification: false,
  leadsQuotationNotificationError: false,

  fetchingleadsQuotationNotification: false,
  fetchingleadsQuotationNotificationError: false,
  leadsNotification: [],
  addSequenceModal: false,

  addingSequenceLeads: false,
  addingSequenceLeadsError: false,

  fetchingSequenceLeads: false,
  fetchingSequenceLeadsError: false,
  sequenceData: [],

  //quote approval opp
  addingQuoteApprovalOpportunity: false,
  addingQuoteApprovalOpportunityError: false,

  fetchingQuoteApprovalOpportunity: false,
  fetchingQuoteApprovalOpportunityError: false,
  quoteApprovalOpportunity: {},
};
export const ruleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CURRENT_EMAIL:
      return { ...state, currentEmail: action.payload };

    case types.SET_CURRENT_NOTIFICATION:
      return { ...state, currentNotification: action.payload };
    case types.HANDLE_TEMPLATE_MODAL:
      return { ...state, addTemplateModal: action.payload };

    case types.ADD_SLA_REQUEST:
      return { ...state, addingSla: true };
    case types.ADD_SLA_SUCCESS:
      return {
        ...state,
        addingSla: false,
      };
    case types.ADD_SLA_FAILURE:
      return {
        ...state,
        addingSla: false,
        addingSlaError: true,
      };

    case types.ADD_GENERATE_OPPORTUNITY_REQUEST:
      return { ...state, addingGenerateOpportunity: true };
    case types.ADD_GENERATE_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        addingGenerateOpportunity: false,
      };
    case types.ADD_GENERATE_OPPORTUNITY_FAILURE:
      return {
        ...state,
        addingGenerateOpportunity: false,
        addingGenerateOpportunityError: true,
      };

    case types.ADD_SENDPULSE_REQUEST:
      return { ...state, addingSendPulse: true };
    case types.ADD_SENDPULSE_SUCCESS:
      return {
        ...state,
        addingSendPulse: false,
        sendPulse: action.payload,
      };
    case types.ADD_SENDPULSE_FAILURE:
      return {
        ...state,
        addingSendPulse: false,
        addingSendPulseError: true,
      };

    case types.ADD_MATRIX_REQUEST:
      return { ...state, addingMatrix: true };
    case types.ADD_MATRIX_SUCCESS:
      return {
        ...state,
        addingMatrix: false,
      };
    case types.ADD_MATRIX_FAILURE:
      return {
        ...state,
        addingMatrix: false,
        addingMatrixError: true,
      };

    case types.GET_SLA_REQUEST:
      return { ...state, fetchingSla: true };
    case types.GET_SLA_SUCCESS:
      return {
        ...state,
        fetchingSla: false,
        sla: action.payload,
      };
    case types.GET_SLA_FAILURE:
      return {
        ...state,
        fetchingSla: false,
        fetchingSlaError: true,
      };

    case types.GET_GENERATE_OPPORTUNITY_REQUEST:
      return { ...state, fetchingGenerateOpportunity: true };
    case types.GET_GENERATE_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        fetchingGenerateOpportunity: false,
        generateOpportunity: action.payload,
      };
    case types.GET_GENERATE_OPPORTUNITY_FAILURE:
      return {
        ...state,
        fetchingGenerateOpportunity: false,
        fetchingGenerateOpportunityError: true,
      };

    case types.GET_SENDPULSE_REQUEST:
      return { ...state, fetchingSendPulse: true };
    case types.GET_SENDPULSE_SUCCESS:
      return {
        ...state,
        fetchingSendPulse: false,
        sendPulse: action.payload,
      };
    case types.GET_SENDPULSE_FAILURE:
      return {
        ...state,
        fetchingSendPulse: false,
        fetchingSendPulseError: true,
      };

    case types.GET_MATRIX_REQUEST:
      return { ...state, fetchingMatrix: true };
    case types.GET_MATRIX_SUCCESS:
      return {
        ...state,
        fetchingMatrix: false,
        matrix: action.payload,
      };
    case types.GET_MATRIX_FAILURE:
      return {
        ...state,
        fetchingMatrix: false,
        fetchingMatrixError: true,
      };

    case types.ADD_TEMPLATE_REQUEST:
      return { ...state, addingTemplate: true };
    case types.ADD_TEMPLATE_SUCCESS:
      return {
        ...state,
        addingTemplate: false,
        addTemplateModal: false,
      };
    case types.ADD_TEMPLATE_FAILURE:
      return {
        ...state,
        addingTemplate: false,
        addingTemplateaError: true,
        addTemplateModal: false,
      };

    case types.GET_TEMPLATE_REQUEST:
      return { ...state, fetchingTemplate: true };
    case types.GET_TEMPLATE_SUCCESS:
      return {
        ...state,
        fetchingTemplate: false,
        template: action.payload,
      };
    case types.GET_TEMPLATE_FAILURE:
      return {
        ...state,
        fetchingTemplate: false,
        fetchingTemplateError: true,
      };

    case types.ADD_NOTIFICATION_TEMPLATE_REQUEST:
      return { ...state, addingNotificationTemplate: true };
    case types.ADD_NOTIFICATION_TEMPLATE_SUCCESS:
      return {
        ...state,
        addingNotificationTemplate: false,
        addTemplateModal: false,
      };
    case types.ADD_NOTIFICATION_TEMPLATE_FAILURE:
      return {
        ...state,
        addingNotificationTemplate: false,
        addingNotificationTemplateError: true,
        addTemplateModal: false,
      };

    case types.GET_NOTIFICATION_TEMPLATE_REQUEST:
      return { ...state, fetchingNotificationTemplate: true };
    case types.GET_NOTIFICATION_TEMPLATE_SUCCESS:
      return {
        ...state,
        fetchingNotificationTemplate: false,
        notificationTemplate: action.payload,
      };
    case types.GET_NOTIFICATION_TEMPLATE_FAILURE:
      return {
        ...state,
        fetchingNotificationTemplate: false,
        fetchingNotificationTemplateError: true,
      };

    case types.UPDATE_TEMPLATE_REQUEST:
      return { ...state, udatingEmail: true };
    case types.UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        udatingEmail: false,
      };
    case types.UPDATE_TEMPLATE_FAILURE:
      return { ...state, udatingEmail: false, udatingEmailError: true };

    case types.UPDATE_NOTIFICATION_TEMPLATE_REQUEST:
      return { ...state, udatingNotification: true };
    case types.UPDATE_NOTIFICATION_TEMPLATE_SUCCESS:
      return {
        ...state,
        udatingNotification: false,
      };
    case types.UPDATE_NOTIFICATION_TEMPLATE_FAILURE:
      return {
        ...state,
        udatingNotification: false,
        udatingNotificationError: true,
      };

    case types.ADD_LEAD_AGING_REQUEST:
      return { ...state, addingLeadAging: true };
    case types.ADD_LEAD_AGING_SUCCESS:
      return {
        ...state,
        addingLeadAging: false,
      };
    case types.ADD_LEAD_AGING_FAILURE:
      return {
        ...state,
        addingLeadAging: false,
        addingLeadAgingError: true,
      };

    case types.GET_LEAD_AGING_REQUEST:
      return { ...state, fetchingLeadAging: true };
    case types.GET_LEAD_AGING_SUCCESS:
      return {
        ...state,
        fetchingLeadAging: false,
        leadAging: action.payload,
      };
    case types.GET_LEAD_AGING_FAILURE:
      return {
        ...state,
        fetchingLeadAging: false,
        fetchingLeadAgingError: true,
      };

    case types.ADD_AUTO_APPROVAL_REQUEST:
      return { ...state, addingAutoApproval: true };
    case types.ADD_AUTO_APPROVAL_SUCCESS:
      return {
        ...state,
        addingAutoApproval: false,
      };
    case types.ADD_AUTO_APPROVAL_FAILURE:
      return {
        ...state,
        addingAutoApproval: false,
        addingAutoApprovalError: true,
      };

    case types.GET_AUTO_APPROVAL_REQUEST:
      return { ...state, fetchingAutoApproval: true };
    case types.GET_AUTO_APPROVAL_SUCCESS:
      return {
        ...state,
        fetchingAutoApproval: false,
        autoApproval: action.payload,
      };
    case types.GET_AUTO_APPROVAL_FAILURE:
      return {
        ...state,
        fetchingAutoApproval: false,
        fetchingAutoApprovalError: true,
      };

    //Quotation Add Get
    case types.ADD_QUOTATION_FOR_EMAIL_REQUEST:
      return { ...state, addingQuotationsData: true };
    case types.ADD_QUOTATION_FOR_EMAIL_SUCCESS:
      return {
        ...state,
        addingQuotationsData: false,
        addingQuotation: action.payload,
      };
    case types.ADD_QUOTATION_FOR_EMAIL_FAILURE:
      return {
        ...state,
        addingQuotationsData: false,
        addingQuotationsDataError: true,
      };

    case types.GET_QUOTATION_FOR_EMAIL_REQUEST:
      return { ...state, fetchingaddingQuotation: true };
    case types.GET_QUOTATION_FOR_EMAIL_SUCCESS:
      return {
        ...state,
        fetchingaddingQuotation: false,
        addingQuotation: action.payload,
      };
    case types.GET_QUOTATION_FOR_EMAIL_FAILURE:
      return {
        ...state,
        fetchingaddingQuotation: false,
        fetchingaddingQuotationError: true,
      };

    //Quotation Add Get For Notification
    case types.ADD_QUOTATION_FOR_NOTIFICATION_REQUEST:
      return { ...state, addingQuotationNotification: true };
    case types.ADD_QUOTATION_FOR_NOTIFICATION_SUCCESS:
      return {
        ...state,
        addingQuotationNotification: false,
        quotationNotification: action.payload,
      };
    case types.ADD_QUOTATION_FOR_NOTIFICATION_FAILURE:
      return {
        ...state,
        addingQuotationNotification: false,
        addingQuotatioNotificationError: true,
      };

    case types.GET_QUOTATION_FOR_NOTIFICATION_REQUEST:
      return { ...state, fetchingaddingQuotationNotification: true };
    case types.GET_QUOTATION_FOR_NOTIFICATION_SUCCESS:
      return {
        ...state,
        fetchingaddingQuotationNotification: false,
        quotationNotification: action.payload,
      };
    case types.GET_QUOTATION_FOR_NOTIFICATION_FAILURE:
      return {
        ...state,
        fetchingaddingQuotationNotification: false,
        fetchingaddingQuotationNotificationError: true,
      };

    //Invoice For Mail Add Get
    case types.ADD_INVOICE_FOR_EMAIL_REQUEST:
      return { ...state, addingInvoiceForEmail: true };
    case types.ADD_INVOICE_FOR_EMAIL_SUCCESS:
      return {
        ...state,
        addingInvoiceForEmail: false,
        addingInvoice: action.payload,
      };
    case types.ADD_INVOICE_FOR_EMAIL_FAILURE:
      return {
        ...state,
        addingInvoiceForEmail: false,
        addingInvoiceForEmailError: true,
      };

    case types.GET_INVOICE_FOR_EMAIL_REQUEST:
      return { ...state, fetchingaddingInvoice: true };
    case types.GET_INVOICE_FOR_EMAIL_SUCCESS:
      return {
        ...state,
        fetchingaddingInvoice: false,
        addingInvoice: action.payload,
      };
    case types.GET_INVOICE_FOR_EMAIL_FAILURE:
      return {
        ...state,
        fetchingaddingInvoice: false,
        fetchingaddingInvoiceError: true,
      };

    //Invoice Add Get For Notification
    case types.ADD_INVOICE_FOR_NOTIFICATION_REQUEST:
      return { ...state, addingInvoiceNotification: true };
    case types.ADD_INVOICE_FOR_NOTIFICATION_SUCCESS:
      return {
        ...state,
        addingInvoiceNotification: false,
        invoiceNotification: action.payload,
      };
    case types.ADD_INVOICE_FOR_NOTIFICATION_FAILURE:
      return {
        ...state,
        addingInvoiceNotification: false,
        addingInvoiceNotificationError: true,
      };

    case types.GET_INVOICE_FOR_NOTIFICATION_REQUEST:
      return { ...state, fetchingaddingInvoiceNotification: true };
    case types.GET_INVOICE_FOR_NOTIFICATION_SUCCESS:
      return {
        ...state,
        fetchingaddingInvoiceNotification: false,
        invoiceNotification: action.payload,
      };
    case types.GET_INVOICE_FOR_NOTIFICATION_FAILURE:
      return {
        ...state,
        fetchingaddingInvoiceNotification: false,
        fetchingaddingInvoiceNotificationError: true,
      };

    //RecruitPro For Mail Add Get
    case types.ADD_RECRUITPRO_FOR_EMAIL_REQUEST:
      return { ...state, addingRecruitPro: true };
    case types.ADD_RECRUITPRO_FOR_EMAIL_SUCCESS:
      return {
        ...state,
        addingRecruitPro: false,
        recruitProForEmail: action.payload,
      };
    case types.ADD_RECRUITPRO_FOR_EMAIL_FAILURE:
      return {
        ...state,
        addingRecruitPro: false,
        addingRecruitProError: true,
      };

    case types.GET_RECRUITPRO_FOR_EMAIL_REQUEST:
      return { ...state, fetchingRecruitPro: true };
    case types.GET_RECRUITPRO_FOR_EMAIL_SUCCESS:
      return {
        ...state,
        fetchingRecruitPro: false,
        recruitProForEmail: action.payload,
      };
    case types.GET_RECRUITPRO_FOR_EMAIL_FAILURE:
      return {
        ...state,
        fetchingRecruitPro: false,
        fetchingRecruitProError: true,
      };

    //SearchTab
    case types.ADD_SEARCH_TAB_REQUEST:
      return { ...state, addingQuoteSearchTab: true };
    case types.ADD_SEARCH_TAB_SUCCESS:
      return {
        ...state,
        addingQuoteSearchTab: false,
        searchTabData: action.payload,
      };
    case types.ADD_SEARCH_TAB_FAILURE:
      return {
        ...state,
        addingQuoteSearchTab: false,
        addingQuoteSearchTabError: true,
      };

    case types.GET_SEARCH_TAB_REQUEST:
      return { ...state, fetchingQuoteSearchTab: true };
    case types.GET_SEARCH_TAB_SUCCESS:
      return {
        ...state,
        fetchingQuoteSearchTab: false,
        searchTabData: action.payload,
      };
    case types.GET_SEARCH_TAB_FAILURE:
      return {
        ...state,
        fetchingQuoteSearchTab: false,
        fetchingQuoteSearchTabError: true,
      };

    //Leads Add Get
    case types.ADD_LEADS_FOR_EMAIL_REQUEST:
      return { ...state, addingLeadsData: true };
    case types.ADD_LEADS_FOR_EMAIL_SUCCESS:
      return {
        ...state,
        addingLeadsData: false,
        leadsQuotation: action.payload,
      };
    case types.ADD_LEADS_FOR_EMAIL_FAILURE:
      return {
        ...state,
        addingLeadsData: false,
        addingLeadsDataError: true,
      };

    case types.GET_LEADS_FOR_EMAIL_REQUEST:
      return { ...state, fetchingLeadsQuotation: true };
    case types.GET_LEADS_FOR_EMAIL_SUCCESS:
      return {
        ...state,
        fetchingLeadsQuotation: false,
        leadsEmail: action.payload,
      };
    case types.GET_LEADS_FOR_EMAIL_FAILURE:
      return {
        ...state,
        fetchingLeadsQuotation: false,
        fetchingLeadsQuotationError: true,
      };

    //Leads Add Get For Notification
    case types.ADD_LEADS_FOR_NOTIFICATION_REQUEST:
      return { ...state, leadsQuotationNotification: true };
    case types.ADD_LEADS_FOR_NOTIFICATION_SUCCESS:
      return {
        ...state,
        leadsQuotationNotification: false,
        leadsNotification: action.payload,
      };
    case types.ADD_LEADS_FOR_NOTIFICATION_FAILURE:
      return {
        ...state,
        leadsQuotationNotification: false,
        leadsQuotationNotificationError: true,
      };

    case types.GET_LEADS_FOR_NOTIFICATION_REQUEST:
      return { ...state, fetchingleadsQuotationNotification: true };
    case types.GET_LEADS_FOR_NOTIFICATION_SUCCESS:
      return {
        ...state,
        fetchingleadsQuotationNotification: false,
        leadsNotification: action.payload,
      };
    case types.GET_LEADS_FOR_NOTIFICATION_FAILURE:
      return {
        ...state,
        fetchingleadsQuotationNotification: false,
        fetchingleadsQuotationNotificationError: true,
      };
    case types.HANDLE_SEQUENCE_MODAL:
      return { ...state, addSequenceModal: action.payload };

    case types.HANDLE_WEBSITE_MODAL:
      return { ...state, addWebsiteModal: action.payload };

    case types.ADD_SEQUENCE_LEADS_REQUEST:
      return {
        ...state,
        addingSequenceLeads: true,
      };
    case types.ADD_SEQUENCE_LEADS_SUCCESS:
      return {
        ...state,
        addingSequenceLeads: false,
        addSequenceModal: false,
      };
    case types.ADD_SEQUENCE_LEADS_FAILURE:
      return {
        ...state,
        addingSequenceLeads: false,
        addingSequenceLeadsError: true,
      };

    case types.GET_SEQUENCE_LEADS_REQUEST:
      return {
        ...state,
        fetchingSequenceLeads: true,
      };
    case types.GET_SEQUENCE_LEADS_SUCCESS:
      return {
        ...state,
        fetchingSequenceLeads: false,
        sequenceData: action.payload,
      };
    case types.GET_SEQUENCE_LEADS_FAILURE:
      return {
        ...state,
        fetchingSequenceLeads: false,
        fetchingSequenceLeadsError: true,
      };

    //quote approval opportunity
    case types.ADD_QUOTE_APPROVAL_OPPORTUNITY_REQUEST:
      return { ...state, addingQuoteApprovalOpportunity: true };
    case types.ADD_QUOTE_APPROVAL_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        addingQuoteApprovalOpportunity: false,
      };
    case types.ADD_QUOTE_APPROVAL_OPPORTUNITY_FAILURE:
      return {
        ...state,
        addingQuoteApprovalOpportunity: false,
        addingQuoteApprovalOpportunityError: true,
      };

    case types.GET_QUOTE_APPROVAL_OPPORTUNITY_REQUEST:
      return { ...state, fetchingQuoteApprovalOpportunity: true };
    case types.GET_QUOTE_APPROVAL_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        fetchingQuoteApprovalOpportunity: false,
        quoteApprovalOpportunity: action.payload,
      };
    case types.GET_QUOTE_APPROVAL_OPPORTUNITY_FAILURE:
      return {
        ...state,
        fetchingQuoteApprovalOpportunity: false,
        fetchingQuoteApprovalOpportunityError: true,
      };

    default:
      return state;
  }
};
