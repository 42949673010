import * as types from "./GoalActionTypes";

const initialState = {
  addGoalModal: false,
  editGoalModal: false,

  addingGoal: false,
  addingGoalError: false,

  // isEditing: false,
  editingGoal: false,
  editGoalState: {},
  updatingGoal: false,
  updatingGoalError: false,

  fetchingGoalsByOrganizationId: false,
  fetchingGoalsByOrganizationIdError: false,
  goalsByOrganizationId: [],

  fetchingGoalsByUserId: false,
  fetchingGoalsByUserIdError: false,
  goalsByUserId: [],
};
export const goalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.HANDLE_GOAL_MODAL:
      ////debugger;
      return {
        ...state,
        addGoalModal: action.payload,
      };

    case types.HANDLE_EDIT_GOAL_MODAL:
      ////debugger;
      return {
        ...state,
        editGoalModal: action.payload,
      };

    case types.ADD_GOAL_REQUEST:
      return { ...state, addingGoal: true, addingGoalError: false };
    case types.ADD_GOAL_SUCCESS:
      return { ...state, addingGoal: false, addingGoalError: false };
    case types.ADD_GOAL_FAILURE:
      return { ...state, addingGoal: false, addingGoalError: true };

    case types.GET_GOALS_BY_ORGANIZATION_ID_REQUEST:
      return {
        ...state,
        fetchingGoalsByOrganizationId: true,
        fetchingGoalsByOrganizationIdError: false,
      };
    case types.GET_GOALS_BY_ORGANIZATION_ID_SUCCESS:
      return {
        ...state,
        fetchingGoalsByOrganizationId: false,
        fetchingGoalsByOrganizationIdError: false,
        // isEditing: true,
        goalsByOrganizationId: action.payload,
      };
    case types.GET_GOALS_BY_ORGANIZATION_ID_FAILURE:
      return {
        ...state,
        fetchingGoalsByOrganizationId: false,
        fetchingGoalsByOrganizationIdError: true,
      };

    case types.GET_GOALS_BY_USER_ID_REQUEST:
      return {
        ...state,
        fetchingGoalsByUserId: true,
        fetchingGoalsByUserIdError: false,
      };
    case types.GET_GOALS_BY_USER_ID_SUCCESS:
      return {
        ...state,
        fetchingGoalsByUserId: false,
        fetchingGoalsByUserIdError: false,
        goalsByUserId: action.payload,
      };
    case types.GET_GOALS_BY_USER_ID_FAILURE:
      return {
        ...state,
        fetchingGoalsByUserId: false,
        fetchingGoalsByUserIdError: true,
      };

    case types.SET_EDIT_GOAL_STATE:
      return { ...state, editGoalState: action.payload };

    case types.UPDATE_GOAL_BY_ID_REQUEST:
      return { ...state, editingGoal: true, editingGoalError: false };
    case types.UPDATE_GOAL_BY_ID_SUCCESS:
      return {
        ...state,
        editingGoal: false,
        editingGoalError: false,
        goalsByUserId: state.goalsByUserId.map((goal) => {
          if (goal.id === action.payload.id) {
            return action.payload;
          }
          return goal;
        }),
      };
    case types.UPDATE_GOAL_BY_ID_FAILURE:
      return { ...state, editingGoal: false, editingGoalError: true };

    default:
      return state;
  }
};
