export const ADD_SURVEY_TEMPLATE_REQUEST = "ADD_SURVEY_TEMPLATE_REQUEST";

export const GET_SURVEY_TEMPLATE_REQUEST = "GET_SURVEY_TEMPLATE_REQUEST";

export const GET_SURVEY_REQUEST = "GET_SURVEY_REQUEST";
export const GET_SURVEY_SUCCESS = "GET_SURVEY_SUCCESS";
export const GET_SURVEY_FAILURE = "GET_SURVEY_FAILURE";

export const ADD_SURVEY_REQUEST = "ADD_SURVEY_REQUEST";
export const ADD_SURVEY_SUCCESS = "ADD_SURVEY_SUCCESS";
export const ADD_SURVEY_FAILURE = "ADD_SURVEY_FAILURE";

export const UPDATE_SURVEY_REQUEST = "UPDATE_SURVEY_REQUEST";
export const UPDATE_SURVEY_SUCCESS = "UPDATE_SURVEY_SUCCESS";
export const UPDATE_SURVEY_FAILURE = "UPDATE_SURVEY_FAILURE";

export const HANDLE_SURVEY_MODAL = "HANDLE_SURVEY_MODAL";

export const GET_SURVEY_STAGES_REQUEST = "GET_SURVEY_STAGES_REQUEST";
export const GET_SURVEY_STAGES_SUCCESS = "GET_SURVEY_STAGES_SUCCESS";
export const GET_SURVEY_STAGES_FAILURE = "GET_SURVEY_STAGES_FAILURE";

export const ADD_SURVEY_STAGE_REQUEST = "ADD_SURVEY_STAGE_REQUEST";
export const ADD_SURVEY_STAGE_SUCCESS = "ADD_SURVEY_STAGE_SUCCESS";
export const ADD_SURVEY_STAGE_FAILURE = "ADD_SURVEY_STAGE_FAILURE";

export const UPDATE_SURVEY_STAGE_REQUEST = "UPDATE_SURVEY_STAGE_REQUEST";
export const UPDATE_SURVEY_STAGE_SUCCESS = "UPDATE_SURVEY_STAGE_SUCCESS";
export const UPDATE_SURVEY_STAGE_FAILURE = "UPDATE_SURVEY_STAGE_FAILURE";

export const SET_SURVEY_VIEW_TYPE = "SET_SURVEY_VIEW_TYPE";



//Question part
export const GET_QUESTION_REQUEST = "GET_QUESTION_REQUEST";
export const GET_QUESTION_SUCCESS = "GET_QUESTION_SUCCESS";
export const GET_QUESTION_FAILURE = "GET_QUESTION_FAILURE";

export const ADD_QUESTION_REQUEST = "ADD_QUESTION_REQUEST";
export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS";
export const ADD_QUESTION_FAILURE = "ADD_QUESTION_FAILURE";

export const SET_CURRENT_QUESTION = "SET_CURRENT_QUESTION";

export const HANDLE_UPDATE_QUESTION_MODAL = "HANDLE_UPDATE_QUESTION_MODAL";


export const UPDATE_QUESTION_REQUEST="UPDATE_QUESTION_REQUEST";
export const UPDATE_QUESTION_SUCCESS="UPDATE_QUESTION_SUCCESS";
export const UPDATE_QUESTION_FAILURE="UPDATE_QUESTION_FAILURE";
