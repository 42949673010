import * as types from "./StoryLineActionType";

const initialState = {
    fetchingStoryLineProcess: false,
    fetchingStoryLineProcessError: false,
    storyLineProcess: [],

    fetchingStorylineProcessStages: false,
    fetchingStorylineProcessStagesError: false,
    storyLineStages: [],

    addStorylineModal: false,

    addingStorylineProcess: false,
    addingStorylineProcessError: false,

    addingStorylineProcessStages: false,
    addingStorylineProcessStagesError: false,

    updatingStorylineStages: false,
    updatingStorylineStagesError: false,

    updateStorylineProcess: false,
    updateStorylineProcessError: false,

};
export const storyLineReducer = (state = initialState, action) => {
    switch (action.type) {

        //Get process of storyline

        case types.GET_STORYlINE_PROCESS_REQUEST:
            return { ...state, fetchingStoryLineProcess: true, fetchingStoryLineProcessError: false };
        case types.GET_STORYlINE_PROCESS_SUCCESS:
            return {
                ...state,
                fetchingStoryLineProcess: false,
                fetchingStoryLineProcessError: false,
                storyLineProcess: action.payload,
            };
        case types.GET_STORYlINE_PROCESS_FAILURE:
            return { ...state, fetchingStoryLineProcess: false, fetchingStoryLineProcessError: true };

        // add storyline modal

        case types.HANDLE_STORYLINE_MODAL:
            return { ...state, addStorylineModal: action.payload };

        // get storyline stages

        case types.GET_STORYLINE_PROCESS_STAGES_REQUEST:
            return {
                ...state,
                fetchingStorylineProcessStages: true,
                fetchingStorylineProcessStagesError: false,
            };
        case types.GET_STORYLINE_PROCESS_STAGES_SUCCESS:
            return {
                ...state,
                fetchingStorylineProcessStages: false,
                fetchingStorylineProcessStagesError: false,
                storyLineStages: action.payload,
            };
        case types.GET_STORYLINE_PROCESS_STAGES_FAILURE:
            return {
                ...state,
                fetchingStorylineProcessStages: false,
                fetchingStorylineProcessStagesError: true,
            };

        //Add storyline Process

        case types.ADD_STORYLINE_PROCESS_REQUEST:
            return {
                ...state,
                addingStorylineProcess: true,
                addingStorylineProcessError: false
            };
        case types.ADD_STORYLINE_PROCESS_SUCCESS:
            return {
                ...state,
                addingStorylineProcess: false,
                addingStorylineProcessError: false,
                addStorylineModal: false,
            };
        case types.ADD_STORYLINE_PROCESS_FAILURE:
            return {
                ...state,
                addingStorylineProcess: false,
                addingStorylineProcessError: true,
                addStorylineModal: false,
            };

        case types.ADD_STORYLINE_PROCESS_STAGE_REQUEST:
            return { ...state, addingStorylineProcessStages: true };
        case types.ADD_STORYLINE_PROCESS_STAGE_SUCCESS:
            return {
                ...state,
                addingStorylineProcessStages: false,
                storyLineStages: [...state.storyLineStages, action.payload],
            };
        case types.ADD_STORYLINE_PROCESS_STAGE_FAILURE:
            return {
                ...state,
                addingStorylineProcessStages: false,
                addingStorylineProcessStagesError: true,
            };

        // Update storyline Stages

        case types.UPDATE_STORYLINE_STAGE_REQUEST:
            return { ...state, updatingStorylineStages: true };
        case types.UPDATE_STORYLINE_STAGE_SUCCESS:
            // return { ...state, updatingStages: false, states: [...state.states, action.payload] };
            return {
                ...state,
                updatingStorylineStages: false,
                //     ProcessStages: state.ProcessStages.map((state) =>
                //    state.stageId === action.payload.stageId ? action.payload : state
                //    ),
            };
        case types.UPDATE_STORYLINE_STAGE_FAILURE:
            return { ...state, updatingStorylineStages: false, updatingStorylineStagesError: true };

        //Update storyline process


        case types.UPDATE_STORYLINE_PROCESS_REQUEST:
            return { ...state, updateStorylineProcess: true };
        case types.UPDATE_STORYLINE_PROCESS_SUCCESS:
            // return { ...state, updatingStages: false, states: [...state.states, action.payload] };
            return {
                ...state,
                updateStorylineProcess: false,
                //   Process: state.Process.map((state) =>
                //     state.processId === action.payload.processId ? action.payload : state
                //   ),
            };
        case types.UPDATE_STORYLINE_PROCESS_FAILURE:
            return {
                ...state,
                updateStorylineProcess: false,
                updateStorylineProcessError: true,
            };

        default:
            return state;
    }
};
