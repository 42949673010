export const HANDLE_VIEWPORT_DRAWER = "HANDLE_VIEWPORT_DRAWER";

export const CHANGE_SELECTED_TIME_INTERVAL_VIEWPORT =
  "CHANGE_SELECTED_TIME_INTERVAL_VIEWPORT";
export const SET_TIME_INTERVAL_VIEWPORT = "SET_TIME_INTERVAL_VIEWPORT";
export const SET_FISCAL_TIME_INTERVAL_VIEWPORT =
  "SET_FISCAL_TIME_INTERVAL_VIEWPORT";

export const ADD_VIEWPORT_USER_REQUEST = "ADD_VIEWPORT_USER_REQUEST";
export const ADD_VIEWPORT_USER_SUCCESS = "ADD_VIEWPORT_USER_SUCCESS";
export const ADD_VIEWPORT_USER_FAILURE = "ADD_VIEWPORT_USER_FAILURE";

export const SEND_VIEWPORT_NOTIFICATION_REQUEST =
  "SEND_VIEWPORT_NOTIFICATION_REQUEST";
export const SEND_VIEWPORT_NOTIFICATION_SUCCESS =
  "SEND_VIEWPORT_NOTIFICATION_SUCCESS";
export const SEND_VIEWPORT_NOTIFICATION_FAILURE =
  "SEND_VIEWPORT_NOTIFICATION_FAILURE";

export const GET_ADDED_VIEWPORT_USERS_REQUEST =
  "GET_ADDED_VIEWPORT_USERS_REQUEST";
export const GET_ADDED_VIEWPORT_USERS_SUCCESS =
  "GET_ADDED_VIEWPORT_USERS_SUCCESS";
export const GET_ADDED_VIEWPORT_USERS_FAILURE =
  "GET_ADDED_VIEWPORT_USERS_FAILURE";

export const DELETE_ADDED_VIEWPORT_USER_REQUEST =
  "DELETE_ADDED_VIEWPORT_USER_REQUEST";
export const DELETE_ADDED_VIEWPORT_USER_SUCCESS =
  "DELETE_ADDED_VIEWPORT_USER_SUCCESS";
export const DELETE_ADDED_VIEWPORT_USER_FAILURE =
  "DELETE_ADDED_VIEWPORT_USER_FAILURE";

export const GET_VIEWPORT_USERS_REQUEST = "GET_VIEWPORT_USERS_REQUEST";
export const GET_VIEWPORT_USERS_SUCCESS = "GET_VIEWPORT_USERS_SUCCESS";
export const GET_VIEWPORT_USERS_FAILURE = "GET_VIEWPORT_USERS_FAILURE";

export const GET_VIEWPORT_USERS_DETAILS_REQUEST =
  "GET_VIEWPORT_USERS_DETAILS_REQUEST";
export const GET_VIEWPORT_USERS_DETAILS_SUCCESS =
  "GET_VIEWPORT_USERS_DETAILS_SUCCESS";
export const GET_VIEWPORT_USERS_DETAILS_FAILURE =
  "GET_VIEWPORT_USERS_DETAILS_FAILURE";

export const SET_SELECTED_VIEWPORT_USER = "SET_SELECTED_VIEWPORT_USER";

export const HANDLE_VIEWPORT_MODAL = "HANDLE_VIEWPORT_MODAL";

//FUNNEL

export const GET_VIEWPORT_FUNNEL_REQUEST = "GET_VIEWPORT_FUNNEL_REQUEST";
export const GET_VIEWPORT_FUNNEL_SUCCESS = "GET_VIEWPORT_FUNNEL_SUCCESS";
export const GET_VIEWPORT_FUNNEL_FAILURE = "GET_VIEWPORT_FUNNEL_FAILURE";
//FUNNEL FOR QUARTOR

export const GET_VIEWPORT_FUNNEL_FOR_QUARTER_REQUEST =
  "GET_VIEWPORT_FUNNEL_FOR_QUARTER_REQUEST";
export const GET_VIEWPORT_FUNNEL_FOR_QUARTER_SUCCESS =
  "GET_VIEWPORT_FUNNEL_FOR_QUARTER_SUCCESS";
export const GET_VIEWPORT_FUNNEL_FOR_QUARTER_FAILURE =
  "GET_VIEWPORT_FUNNEL_FOR_QUARTER_FAILURE";

//Sales velocity
export const GET_VIEWPORT_VELOCITY_REQUEST = "GET_VIEWPORT_VELOCITY_REQUEST";
export const GET_VIEWPORT_VELOCITY_SUCCESS = "GET_VIEWPORT_VELOCITY_SUCCESS";
export const GET_VIEWPORT_VELOCITY_FAILURE = "GET_VIEWPORT_VELOCITY_FAILURE";

//winRate/won count
export const GET_VIEWPORT_WON_REQUEST = "GET_VIEWPORT_WON_REQUEST";
export const GET_VIEWPORT_WON_SUCCESS = "GET_VIEWPORT_WON_SUCCESS";
export const GET_VIEWPORT_WON_FAILURE = "GET_VIEWPORT_WON_FAILURE";

//customerAdded
export const GET_VIEWPORT_ACCOUNT_REQUEST = "GET_VIEWPORT_ACCOUNT_REQUEST";
export const GET_VIEWPORT_ACCOUNT_SUCCESS = "GET_VIEWPORT_ACCOUNT_SUCCESS";
export const GET_VIEWPORT_ACCOUNT_FAILURE = "GET_VIEWPORT_ACCOUNT_FAILURE";

//Ratio
export const GET_VIEWPORT_ACCOUNT_RATIO_CHART_REQUEST =
  "GET_VIEWPORT_ACCOUNT_RATIO_CHART_REQUEST";
export const GET_VIEWPORT_ACCOUNT_RATIO_CHART_SUCCESS =
  "GET_VIEWPORT_ACCOUNT_RATIO_CHART_SUCCESS";
export const GET_VIEWPORT_ACCOUNT_RATIO_CHART_FAILURE =
  "GET_VIEWPORT_ACCOUNT_RATIO_CHART_FAILURE";

//stage chart
export const GET_VIEWPORT_STAGE_CHART_REQUEST =
  "GET_VIEWPORT_STAGE_CHART_REQUEST";
export const GET_VIEWPORT_STAGE_CHART_SUCCESS =
  "GET_VIEWPORT_STAGE_CHART_SUCCESS";
export const GET_VIEWPORT_STAGE_CHART_FAILURE =
  "GET_VIEWPORT_STAGE_CHART_FAILURE";

//win loss chart

export const GET_VIEWPORT_WIN_LOSS_CHART_REQUEST =
  "GET_VIEWPORT_WIN_LOSS_CHART_REQUEST";
export const GET_VIEWPORT_WIN_LOSS_CHART_SUCCESS =
  "GET_VIEWPORT_WIN_LOSS_CHART_SUCCESS";
export const GET_VIEWPORT_WIN_LOSS_CHART_FAILURE =
  "GET_VIEWPORT_WIN_LOSS_CHART_FAILURE";
