export const SET_CAMPAIGN_VIEW_TYPE = "SET_CAMPAIGN_VIEW_TYPE";

export const ADD_CAMPAIGN_REQUEST = "ADD_CAMPAIGN_REQUEST";
export const ADD_CAMPAIGN_SUCCESS = "ADD_CAMPAIGN_SUCCESS";
export const ADD_CAMPAIGN_FAILURE = "ADD_CAMPAIGN_FAILURE";

export const GET_CAMPAIGN_REQUEST = "GET_CAMPAIGN_REQUEST";
export const GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS";
export const GET_CAMPAIGN_FAILURE = "GET_CAMPAIGN_FAILURE";

export const HANDLE_CAMPAIGN_DRAWER = "HANDLE_CAMPAIGN_DRAWER";
export const HANDLE_CAMPAIGN_CONTACT_MODAL = "HANDLE_CAMPAIGN_CONTACT_MODAL";

export const GET_CAMPAIGN_CONTACT_LIST_REQUEST =
  "GET_CAMPAIGN_CONTACT_LIST_REQUEST";
export const GET_CAMPAIGN_CONTACT_LIST_SUCCESS =
  "GET_CAMPAIGN_CONTACT_LIST_SUCCESS";
export const GET_CAMPAIGN_CONTACT_LIST_FAILURE =
  "GET_CAMPAIGN_CONTACT_LIST_FAILURE";

  export const SET_CAMPAIGNPLANNER_DATE = 'SET_PLANNER_DATE';
  export const HANDLE_EVENT_MODAL = 'HANDLE_EVENT_MODAL';
  export const SET_DATE_AND_TIME = 'SET_DATE_AND_TIME';
  export const HANDLE_CHOOSER_MODAL = 'HANDLE_CHOOSER_MODAL'
  export const HANDLE_VIEW_EDIT_MODAL = 'HANDLE_VIEW_EDIT_MODAL'
  export const SET_FORM_MODAL_TYPE = 'SET_FORM_MODAL_TYPE'
