export const GET_TASKS_COMPLETED_REQUEST = "GET_TASKS_COMPLETED_REQUEST ";
export const GET_TASKS_COMPLETED_SUCCESS = "GET_TASKS_COMPLETED_SUCCESS";
export const GET_TASKS_COMPLETED_FAILURE = "GET_TASKS_COMPLETED_FAILURE";

export const GET_TASKS_TOSTART_REQUEST = "GET_TASKS_TOSTART_REQUEST";
export const GET_TASKS_TOSTART_SUCCESS = "GET_TASKS_TOSTART_SUCCESS";
export const GET_TASKS_TOSTART_FAILURE = "GET_TASKS_TOSTART_FAILURE";

export const GET_TASKS_ASSIGNED_REQUEST = "GET_TASKS_ASSIGNED_REQUEST";
export const GET_TASKS_ASSIGNED_SUCCESS = "GET_TASKS_ASSIGNED_SUCCESS";
export const GET_TASKS_ASSIGNED_FAILURE = "GET_TASKS_ASSIGNED_FAILURE";

export const GET_TASKS_INPROGRESS_REQUEST = "GET_TASKS_INPROGRESS_REQUEST";
export const GET_TASKS_INPROGRESS_SUCCESS = "GET_TASKS_INPROGRESS_SUCCESS";
export const GET_TASKS_INPROGRESS_FAILURE = "GET_TASKS_INPROGRESS_FAILURE";

export const GET_HIGH_PRIORITY_TASKS_COMPLETE_REQUEST =
  "GET_HIGH_PRIORITY_TASKS_COMPLETE_REQUEST";
export const GET_HIGH_PRIORITY_TASKS_COMPLETE_SUCCESS =
  "GET_HIGH_PRIORITY_TASKS_COMPLETE_SUCCESS";
export const GET_HIGH_PRIORITY_TASKS_COMPLETE_FAILURE =
  "GET_HIGH_PRIORITY_TASKS_COMPLETE_FAILURE";

export const GET_TASK_VELOCITY_REQUEST = "GET_TASK_VELOCITY_REQUEST";
export const GET_TASK_VELOCITY_SUCCESS = "GET_TASK_VELOCITY_SUCCESS";
export const GET_TASK_VELOCITY_FAILURE = "GET_TASK_VELOCITY_FAILURE";

export const GET_APPROVALS_CLOSED_REQUEST = "GET_APPROVALS_CLOSED_REQUEST";
export const GET_APPROVALS_CLOSED_SUCCESS = "GET_APPROVALS_CLOSED_SUCCESS";
export const GET_APPROVALS_CLOSED_FAILURE = "GET_APPROVALS_CLOSED_FAILURE";

export const GET_APPROVALS_PENDING_REQUEST = "GET_APPROVALS_PENDING_REQUEST";
export const GET_APPROVALS_PENDING_SUCCESS = "GET_APPROVALS_PENDING_SUCCESS";
export const GET_APPROVALS_PENDING_FAILURE = "GET_APPROVALS_PENDING_FAILURE";

export const GET_TIMEZONE_REQUEST = "GET_TIMEZONE_REQUEST ";
export const GET_TIMEZONE_SUCCESS = "GET_TIMEZONE_SUCCESS";
export const GET_TIMEZONE_FAILURE = "GET_TIMEZONE_FAILURE";

export const ADD_TASK_REQUEST = "ADD_TASK_REQUEST";
export const ADD_TASK_SUCCESS = "ADD_TASK_SUCCESS";
export const ADD_TASK_FAILURE = "ADD_TASK_FAILURE";

export const DELETE_TASK_REQUEST = "DELETE_TASK_REQUEST";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_FAILURE = "DELETE_TASK_FAILURE";

export const UPDATE_TASK_BY_ID_REQUEST = "UPDATE_TASK_BY_ID_REQUEST";
export const UPDATE_TASK_BY_ID_SUCCESS = "UPDATE_TASK_BY_ID_SUCCESS";
export const UPDATE_TASK_BY_ID_FAILURE = "UPDATE_TASK_BY_ID_FAILURE";

export const PATCH_TASK_BY_ID_REQUEST = "PATCH_TASK_BY_ID_REQUEST";
export const PATCH_TASK_BY_ID_SUCCESS = "PATCH_TASK_BY_ID_SUCCESS";
export const PATCH_TASK_BY_ID_FAILURE = "PATCH_TASK_BY_ID_FAILURE";

export const GET_TASK_LIST_RANGE_BY_USER_ID_REQUEST =
  "GET_TASK_LIST_RANGE_BY_USER_ID_REQUEST";
export const GET_TASK_LIST_RANGE_BY_USER_ID_SUCCESS =
  "GET_TASK_LIST_RANGE_BY_USER_ID_SUCCESS";
export const GET_TASK_LIST_RANGE_BY_USER_ID_FAILURE =
  "GET_TASK_LIST_RANGE_BY_USER_ID_FAILURE";

export const GET_TASK_LIST_RANGE_OF_ALL_USERS_REQUEST =
  "GET_TASK_LIST_RANGE_OF_ALL_USERS_REQUEST";
export const GET_TASK_LIST_RANGE_OF_ALL_USERS_SUCCESS =
  "GET_TASK_LIST_RANGE_OF_ALL_USERS_SUCCESS";
export const GET_TASK_LIST_RANGE_OF_ALL_USERS_FAILURE =
  "GET_TASK_LIST_RANGE_OF_ALL_USERS_FAILURE";

export const REJECT_APPROVE_REQUEST = "REJECT_APPROVE_REQUEST";
export const REJECT_APPROVE_SUCCESS = "REJECT_APPROVE_SUCCESS";
export const REJECT_APPROVE_FAILURE = "REJECT_APPROVE_FAILURE";

export const TASK_APPROVED_REQUEST = "TASK_APPROVED_REQUEST";
export const TASK_APPROVED_SUCCESS = "TASK_APPROVED_SUCCESS";
export const TASK_APPROVED_FAILURE = "TASK_APPROVED_FAILURE";

export const TASK_CHECK_REQUEST = "TASK_CHECK_REQUEST";
export const TASK_CHECK_SUCCESS = "TASK_CHECK_SUCCESS";
export const TASK_CHECK_FAILURE = "TASK_CHECK_FAILURE";

export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";

// export const ADD_APPROVE_REQUEST = "ADD_APPROVE_REQUEST";
// export const ADD_APPROVE_SUCCESS = "ADD_APPROVE_SUCCESS";
// export const ADD_APPROVE_FAILURE = "ADD_APPROVE_FAILURE";

// export const ADD_APPROVE_REQUEST = "ADD_APPROVE_REQUEST";
// export const ADD_APPROVE_SUCCESS = "ADD_APPROVE_SUCCESS";
// export const ADD_APPROVE_FAILURE = "ADD_APPROVE_FAILURE";

export const HANDLE_TASK_MODAL = "HANDLE_TASK_MODAL";

export const GET_TASK_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST =
  "GET_TASK_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST";
export const GET_TASK_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS =
  "GET_TASK_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS";
export const GET_TASK_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE =
  "GET_TASK_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE";

export const PARTNER_APPROVED_REQUEST = "PARTNER_APPROVED_REQUEST";
export const PARTNER_APPROVED_SUCCESS = "PARTNER_APPROVED_SUCCESS";
export const PARTNER_APPROVED_FAILURE = "PARTNER_APPROVED_FAILURE";

export const PARTNER_REJECT_REQUEST = "PARTNER_REJECT_REQUEST";
export const PARTNER_REJECT_SUCCESS = "PARTNER_REJECT_SUCCESS";
export const PARTNER_REJECT_FAILURE = "PARTNER_REJECT_FAILURE";

export const EMAIL_SEND_COMPLETED_REQUEST = "EMAIL_SEND_COMPLETED_REQUEST";
export const EMAIL_SEND_COMPLETED_SUCCESS = "EMAIL_SEND_COMPLETED_SUCCESS";
export const EMAIL_SEND_COMPLETED_FAILURE = "EMAIL_SEND_COMPLETED_FAILURE";

export const LOCAL_TASK_CONTACT_REQUEST = "LOCAL_TASK_CONTACT_REQUEST";
export const LOCAL_TASK_CONTACT_SUCCESS = "LOCAL_TASK_CONTACT_SUCCESS";
export const LOCAL_TASK_CONTACT_FAILURE = "LOCAL_TASK_CONTACT_FAILURE";

export const DOCUMENT_APPROVED_REQUEST = "DOCUMENT_APPROVED_REQUEST";
export const DOCUMENT_APPROVED_SUCCESS = "DOCUMENT_APPROVED_SUCCESS";
export const DOCUMENT_APPROVED_FAILURE = "DOCUMENT_APPROVED_FAILURE";

export const DOCUMENT_REJECT_REQUEST = "DOCUMENT_REJECT_REQUEST";
export const DOCUMENT_REJECT_SUCCESS = "DOCUMENT_REJECT_SUCCESS";
export const DOCUMENT_REJECT_FAILURE = "DOCUMENT_REJECT_FAILURE";

export const APPROVE_LEADS_PATNER_REQUEST = "APPROVE_LEADS_PATNER_REQUEST";
export const APPROVE_LEADS_PATNER_SUCCESS = "APPROVE_LEADS_PATNER_SUCCESS";
export const APPROVE_LEADS_PATNER_FAILURE = "APPROVE_LEADS_PATNER_FAILURE";

export const HANDLE_LEADS_ASSIGN_MODAL = "HANDLE_LEADS_ASSIGN_MODAL";

export const REJECT_PARTNER_LEADS_REQUEST = "REJECT_PARTNER_LEADS_REQUEST";
export const REJECT_PARTNER_LEADS_SUCCESS = "REJECT_PARTNER_LEADS_SUCCESS";
export const REJECT_PARTNER_LEADS_FAILURE = "REJECT_PARTNER_LEADS_FAILURE";
