export const ADD_CUSTOME_FIELD_REQUEST = "ADD_CUSTOME_FIELD_REQUEST";
export const ADD_CUSTOME_FIELD_SUCCESS = "ADD_CUSTOME_FIELD_SUCCESS";
export const ADD_CUSTOME_FIELD_FAILURE = "ADD_CUSTOME_FIELD_FAILURE";

export const GET_CUSTOME_FILED_WITH_TABNAME_REQUEST =
  "GET_CUSTOME_FILED_WITH_TABNAME_REQUEST";
export const GET_CUSTOME_FILED_WITH_TABNAME_SUCCESS =
  "GET_CUSTOME_FILED_WITH_TABNAME_SUCCESS";
export const GET_CUSTOME_FILED_WITH_TABNAME_FAILURE =
  "GET_CUSTOME_FILED_WITH_TABNAME_FAILURE";
