import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import axios from "axios";
import "./App.css";
import { BundleLoader } from "./Components/Placeholder";
import PrivateRoute from "./Helpers/Auth/PrivateRoute";
import AppErrorBoundary from "./Helpers/ErrorBoundary/AppErrorBoundary";
// import { Offline, Online } from "react-detect-offline";
import AppLoginMessage from "./Containers/Auth/AppLoginMessage";
import ApprovedMessage from "./Containers/Auth/ApprovedMessage";
import ApprovedValidation from "./Containers/Auth/ApprovedValidation";
import ConnectionLoss from "./Containers/NetworkLoss/ConnectionLoss";
import CandidateOfferValidation from "./Containers/Auth/CandidateOfferValidation";
import OfferMessage from "./Containers/Auth/OfferMessage";
import SponsorOfferValidation from "./Containers/Auth/SponsorOfferValidation";
import SponsorOfferMessage from "./Containers/Auth/SponsorOfferMessage";
import ApprovedQuoteByAdminMessage from "./Containers/Auth/ApprovedQuoteByAdminMessage";
import ApproveQuoteByAdminValidation from "./Containers/Auth/ApproveQuoteByAdminValidation";

/**
 * lazy loaded compenents
 */
const Register = lazy(() => import("./Containers/Auth/Register"));
const ProRegister = lazy(() => import("./Containers/Auth/ProRegister"));
const Login = lazy(() => import("./Containers/Auth/Login"));
const EmailValidation = lazy(() => import("./Containers/Auth/EmailValidation"));
const ForgotPasswordVerification = lazy(() =>
  import("./Containers/Auth/ForgotPasswordVerification")
);
const SetPassword = lazy(() => import("./Containers/Auth/SetPassword"));
const ForgotPassword = lazy(() => import("./Containers/Auth/ForgotPassword"));
const MainApp = lazy(() => import("./Containers/Main/MainApp"));

class App extends Component {
  render() {
    const { fetchingUserDetails } = this.props;
    return (
      <div>
        {/* <Offline>
          <div className="wrapper">
            <ConnectionLoss />
          </div>
        </Offline> */}
        {/* <Online> */}
        <AppErrorBoundary>
          <Suspense fallback={<BundleLoader />}>
            <Switch>
              <Route exact path="/register/:type?" component={Register} />

              {/* <Route exact path="/login/:username?/:password?" component={Login} /> */}
              <Route exact path="/login" component={Login} />
              <Route exact path="/mobilelogin" component={AppLoginMessage} />
              <Route
                exact
                path="/activationEmail/:userId/:token/:emailId/:organizationId"
                component={EmailValidation}
              />
              <Route
                exact
                path="/forgotPasswordVerification/:userId/:token/:emailId/:organizationId"
                component={ForgotPasswordVerification}
              />
              <Route
                exact
                path="/approve/quote/:quoteId"
                component={ApprovedValidation}
              />
              <Route
                exact
                path="/acceptOffer/candidate/:profileId"
                component={CandidateOfferValidation}
              />
              <Route
                exact
                path="/sponseroffer/:profileId"
                component={SponsorOfferValidation}
              />
              <Route
                exact
                path="/approveQuote/admin/:orgId/:quoteId"
                component={ApproveQuoteByAdminValidation}
              />
              <Route exact path="/setPassword" component={SetPassword} />
              <Route exact path="/forgotPassword" component={ForgotPassword} />
              <Route exact path="/Approved" component={ApprovedMessage} />
              <Route
                exact
                path="/ApprovedByAdmin"
                component={ApprovedQuoteByAdminMessage}
              />
              <Route exact path="/Offer" component={OfferMessage} />
              <Route
                exact
                path="/SponsorOffer"
                component={SponsorOfferMessage}
              />
              {fetchingUserDetails ? (
                <BundleLoader />
              ) : (
                <PrivateRoute path="/" component={MainApp} />
              )}
            </Switch>
          </Suspense>
        </AppErrorBoundary>
        {/* </Online> */}
       
      

      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  fetchingUserDetails: auth.fetchingUserDetails,
});
export default connect(mapStateToProps)(App);
