import React, { Component } from "react";
import { Avatar, Divider, Button, Menu, Dropdown, Tooltip } from "antd";
import { PlusOutlined, SolutionOutlined } from '@ant-design/icons';
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { FlexContainer, MainWrapper, ResponsiveCard } from "../Layout";
import { CurrencySymbol } from "../../Common";
import { ActionIcon } from "../../Utils";
import { MultiAvatar } from "./";
import { Title, SubTitle } from "./";

const ContactBussinessCard = (props) => {
  const {
    handleClick,
    handleSecondaryTitleClick,
    handlePreview,
    imageId,
    imageURL,
    primaryTitle,
    secondaryTitle,
    secondaryImageId,
    secondaryImageURL,
    subtitle1,
    subtitle2,
    hideSecondaryAvatar,
    subtitle1Icon,
    mainAvatarTitle,
    bottomBarComponent,
    currencyType,
    dropdownMenu,
    partnerContactInd,
    handleOpportunityModal,
  } = props;
  return (
    <ResponsiveCard flexDirection="column" style={{ borderRadius: 3 }}>
      <MainWrapper>
        <FlexContainer
          alignItems="center"
          flexWrap="no-wrap"
          style={{ height: "3.43em" }}
        >
          <FlexContainer style={{ flexBasis: "25%", marginRight: "0.2rem" }}>
            <MultiAvatar
              primaryTitle={primaryTitle}
              imageId={imageId}
              // imageURL={imageURL}
              imgHeight={40}
              imgWidth={40}
              imgRadius={50}
            />
          </FlexContainer>
          &nbsp;
          <FlexContainer
            flexDirection="column"
            style={{ flexBasis: "65%", overflow: "hidden" }}
          >
            <Title
              fontSize="1em"
              overflow="hidden"
              textOverflow="ellipsis"
              style={{ color: "#337df4", cursor: "pointer" }}
              onClick={handleClick || null}
            >
              {primaryTitle || ""}
            </Title>
            {secondaryTitle && (
              <SubTitle
                overflow="hidden"
                textOverflow="ellipsis"
                style={{
                  cursor: "pointer",
                  fontSize: '0.9375em',
                  display: "flex",
                }}
                onClick={handleSecondaryTitleClick || null}
              >
                {!hideSecondaryAvatar && (
                  <MultiAvatar
                    primaryTitle={secondaryTitle || "F"}
                    imageId={secondaryImageId}
                    imageURL={secondaryImageURL}
                    imgHeight={30}
                    imgWidth={30}
                    smallAvatar
                    minAvatarWidth="1.56em"
                  />
                )}
                &nbsp;&nbsp;&nbsp;
                <p style={{ marginTop: "0.125em" }}>{secondaryTitle || ""}</p>
              </SubTitle>
            )}
          </FlexContainer>
          <FlexContainer style={{ flexBasis: "10%", alignSelf: "flex-start" }}>
            {dropdownMenu || <></>}
          </FlexContainer>
        </FlexContainer>
        <FlexContainer flexDirection="column" style={{ padding: "0.2rem" }}>
          {/* <SubTitle
            style={{ fontWeight: 500, fontSize: 15, marginTop: "-0.37em", marginBottom: "-0.18em" }}
          >
            <Icon type={subtitle1Icon || "filter"} theme="filled" />
            &nbsp;&nbsp;
            {currencyType && <CurrencySymbol currencyType={currencyType} />}
            &nbsp;{subtitle1 || ""}
          </SubTitle> */}
          {/* <SubTitle style={{ fontWeight: 500, fontSize: 15 }}>
                        <Icon type="phone" theme="filled" /> &nbsp;
                        {subtitle2 || ''}
                    </SubTitle> */}
        </FlexContainer>
        <FlexContainer style={{ width: "100%", paddingLeft: "0.5rem" }}>
          {/* <FlexContainer style={{}}>
                        <Button
                            type="primary"
                            shape="circle"
                            icon="phone"
                            style={{ backgroundColor: '#466070', border: 'none', marginRight: '0.4rem', fontSize: 18 }} />
                        <Button
                            type="primary"
                            shape="circle"
                            icon="file-text"
                            style={{ backgroundColor: '#466070', border: 'none', marginRight: '0.4rem', fontSize: 18 }} />
                    </FlexContainer> */}
          <FlexContainer
            style={{ display: "flex", justifyContent: "space-evenly" }}
            justifyContent={bottomBarComponent ? "space-between" : "flex-end"}
            alignSelf="flex-end"
            alignItems="center"
          >
            {/* <Tooltip placement="right" title="Pulse"> */}
            <Tooltip placement="right" title={<FormattedMessage
              id="app.pulse"
              defaultMessage="Pulse"
            />}>
              <div>
                <Button
                  size={"small"}
                  type="ghost"
                  style={{
                    // color: "#888",
                    borderColor: "transparent",
                    alignSelf: "flex-end",
                  }}
                  onClick={handlePreview}
                >
                  <PulseIcon></PulseIcon>
                </Button>
              </div>
            </Tooltip>
            <div
              style={{
                borderLeft: "0.06em solid rgb(239 229 229)",
                height: "1.25em",
              }}
            ></div>
            <div onClick={handleOpportunityModal}>
              {/* <Tooltip placement="right" title="Opportunity"> */}
              <Tooltip placement="right" title={<FormattedMessage
                id="app.opportunities"
                defaultMessage="Opportunity"
              />}>
                {/* <Icon
                  style={{
                    fontSize: "113%",
                    marginTop: "-6%",
                    color: "rgb(188 182 182)",
                    cursor: "pointer",
                  }}
                  type="bulb"
                  key="opportunity"
                /> */} <i class="far fa-lightbulb"></i>
                <PlusOutlined
                  style={{ fontSize: "0.56em", cursor: "pointer" }}
                // type="plus"

                // handleIconClick={handleOpportunityModal}
                />
              </Tooltip>
            </div>
            <div
              style={{
                borderLeft: "0.06em solid rgb(239 229 229)",
                height: "1.25em",
              }}
            ></div>
            <Tooltip placement="right" title="Partner">
              {bottomBarComponent && bottomBarComponent}
              {partnerContactInd === "yes" && (
                <Button
                  size={"small"}
                  type="ghost"
                  style={{
                    color: "#24b524",
                    borderColor: "transparent",
                    alignSelf: "flex-end",
                    fontSize: "1em",
                  }}
                >
                  <SolutionOutlined />
                </Button>
              )}
            </Tooltip>
          </FlexContainer>
        </FlexContainer>
      </MainWrapper>
    </ResponsiveCard>
  );
};
export default ContactBussinessCard;

const AppIcon = (props) => (
  <i
    className={`fas fa-heartbeat ${props.className}`}
    style={{ fontSize: "123%" }}
  ></i>
);

const PulseIcon = styled(AppIcon)`
  color: #df9697;
  &:hover {
    /* background: yellow; */
    color: blue;
  }
`;
