import React, { lazy, Suspense } from "react";

import { BundleLoader } from "../../../Components/Placeholder";
import { FormattedMessage } from "react-intl";
import { StyledModal } from "../../../Components/UI/Antd";
const AccountForm = lazy(() => import("./AccountForm"));

const AddAccountModal = (props) => {
  return (
    <>
      <StyledModal
        // title="Customer"
        title={<FormattedMessage
          id="app.customers"
          defaultMessage="Customers"
        />}
        width="60%"
        visible={props.addAccountModal}
        maskClosable={false}
        destroyOnClose
        // maskStyle={{transition: '0.5s filter linear', filter: 'blur(1.25em)', width: '100%', height: '100%', padding: '3.12em', backgroundColor: 'rgba(49, 56, 66,0.7)'}}
        maskStyle={{ backgroundColor: "rgba(1, 30, 71,0.7)" }}
        style={{ top: 40 }}
        onCancel={() => props.handleAccountModal(false)}
        footer={null}
      >
        <Suspense fallback={<BundleLoader />}>
          <AccountForm />{" "}
        </Suspense>
      </StyledModal>
    </>
  );
};

export default AddAccountModal;
