import React from "react";
import { Select, message, Tooltip } from "antd";
import {
  InfoCircleOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  EditOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import ContentLoader from "react-content-loader";
import { CurrencySymbol, cu } from "../../Common";
import CurrencyCompressor from "../../Common/CurrencyCompressor";
import { renderTooltip } from "../../../Helpers/Function/UIFunctions";
import { ActionIcon } from "../../Utils";
import { TextInput } from ".";
const { Option } = Select;
const JumpStartWrapper = styled.div`
  margin: 0.2rem 0.4rem;
  padding: 0.5em;
  border: 0.06em solid #ddd;
  border-radius: 0.2rem;
  display: flex;
  justify-content: "space-between";
  align-items: center;
  background: ${(props) => props.bgColor || "#fff"};
  color: #333 !important;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
  -webkit-box-shadow: 0 0.5em 0.37em -0.37em rgb(46, 44, 44);
  -moz-box-shadow: 0 0.5em 0.37em -0.37em rgb(46, 44, 44);
  box-shadow: 0 0.5em 0.37em -0.37em rgb(46, 44, 44);
  flex: 1 1 0;
  height: 3.75em;
  transition: 0.3s all;
  &:hover {
    -webkit-box-shadow: 0 0.75em 0.37em -0.37em rgb(46, 44, 44);
    -moz-box-shadow: 0 0.75em 0.37em -0.37em rgb(46, 44, 44);
    box-shadow: 0 0.75em 0.37em -0.37em rgb(46, 44, 44);
  }
`;
const Progress = styled.span`
  color: ${(props) => (props.amount >= 0 ? "#0d9412" : "rgb(248, 15, 15)")};
  font-size: 0.8em;
  font-weight: bold;
  margin-left: 0.18em;

  @media only screen and (max-width: 62em) {
    font-size: 0.5rem;
  }
`;
const InfoIcon = styled.span`
  font-size: 0.75em;
  cursor: pointer;
  @media only screen and (max-width: 62em) {
    font-size: 0.46em;
  }
`;

const Value = styled.span`
  font-size: 1em;

  @media only screen and (max-width: 62em) {
    font-size: 0.53em;
  }
`;
const JumpStartDetail = ({
  title,
  value,
  stringValue,
  textValue,
  currencyType,
  currencyType1,
  bgColor,
  clickable,
  noProgress,
  handleEdit,
  isLevelTextInputOpen,
  progress,
  edit,
  text,
  levels,
  isLoading,
  handleUpdate,
  handleLevelNotEdit,
  userId,
  taskInd,
  tooltipData,
  jumpstartClick,
  cursorData,
  icon,
}) => {
  console.log(levels);
  function onChange(value) {
    if (taskInd) {
      handleLevelNotEdit();
    } else {
      handleUpdate(userId, { level: value });
    }
    ////debugger;
    console.log(value);
  }
  return (
    <JumpStartWrapper
      bgColor={bgColor}
      clickable={clickable}
      onClick={jumpstartClick}
      style={{ cursor: cursorData }}
    >
      <div>
        {icon ? (
          <h3>
            {title || "N/A"}{" "}
            <Tooltip title={tooltipData}>
              <InfoIcon>
                <InfoCircleOutlined
                // type="info-circle"
                // style={{ fontSize: "1em", cursor: "pointer" }}
                />
              </InfoIcon>
            </Tooltip>
          </h3>
        ) : (
          <h3>{title || "N/A"}</h3>
        )}

        {isLoading ? (
          <span
            style={{
              width: "7.5em",
              height: "0.18em",
              display: "inline-block",
              opacity: 0.5,
            }}
          >
            <MyLoader />{" "}
          </span>
        ) : (
          <h2>
            {isLevelTextInputOpen ? (
              <>
                <Select
                  // dropdownStyle={{ backgroundColor: "green", width: "9.37em" }}
                  // value={value || ""}
                  defaultValue={value}
                  showSearch
                  // disabled={!this.props.disabled}
                  style={{ width: 150, marginBottom: "0.18em" }}
                  placeholder="Select"
                  // onChange={onChange}
                  onSelect={onChange}
                >
                  {levels.map((item) => {
                    return <Option value={item.level}>{item.level} </Option>;
                  })}
                </Select>
                {/* <TextInput
                  placeholder="Level"
                  name="processName"
                  // defaultValue={this.state.currentProcess.processName}
                  // onChange={this.handleChange}
                  width={"100%"}
                  height={"1.25em"}
                  // style={{ marginLeft: "2.81em" }}
                /> */}
              </>
            ) : (
              <>
                {currencyType && <CurrencySymbol currencyType={currencyType} />}
                {currencyType && <span>&nbsp;</span>}
                {stringValue ? value : CurrencyCompressor(value) || 0}
                &nbsp;
                {currencyType1 && (
                  <CurrencySymbol currencyType={currencyType1} />
                )}
                {textValue && (
                  <>
                    <Value>{text}</Value>
                  </>
                )}
                {edit && (
                  <Tooltip title="Edit">
                    <EditOutlined
                      // tooltipTitle="Edit"
                      // iconType="edit"
                      onClick={handleEdit}
                      size="0.875em"
                    />
                  </Tooltip>
                )}
              </>
            )}

            {!noProgress && (
              // renderTooltip(
              //   "this is what progress means",
              <Progress amount={progress || 0}>
                {progress > 0 ? (
                  <>
                    <CaretUpOutlined />
                    {`${(progress && progress.toFixed(0)) || 0}%`}
                  </>
                ) : (
                  <>
                    <CaretDownOutlined />
                    {`${(progress && progress.toFixed(0)) || 0}%`}
                  </>
                )}
                {/* &nbsp;{`${(progress && progress.toFixed(2)) || 0}%`} */}
              </Progress>
            )
            // )
            }
          </h2>
        )}
      </div>
    </JumpStartWrapper>
  );
};

export default JumpStartDetail;

const MyLoader = (props) => (
  <ContentLoader
    height={30}
    width={150}
    speed={2}
    primaryColor="#395E9D"
    secondaryColor="#223393"
    {...props}
  >
    <rect x="25" y="15" width="9.37em" height="1.87em" />
  </ContentLoader>
);
