import * as types from "./TeamsActionTypes";
import moment from "moment";

const initialState = {
  addWeightedModal: false,
  addAbsoluteModal: false,
  addClosureModal: false,
  addWinModal: false,
  addWonModal: false,
  addCustomerModal: false,
  addWeightedChartModal: false,
  addActualChartModal: false,
  addProgressChartModal: false,
  addWinChartModal: false,
  addWonChartModal: false,
  addCustomerChartModal: false,
  addingLocation: false,
  addCardLocationIconModal: false,
  addCardLocationIconModalForTable: false,
  updateLocationIconModalForTable: false,

  fetchingLocationDetails: false,
  fetchingLocationDetailsError: false,
  locationDetails: [],

  fetchingLocationWiseUser: false,
  fetchingLocationWiseUserError: false,
  locationWiseUser: [],

  dateRangeList: [
    {
      id: 1,
      type: "year",
      value: "FY",
      starter: true,
      isSelected: true,
      startDate: moment()
        .startOf("year")
        .toISOString(),
      endDate: moment()
        .endOf("year")
        .toISOString(),
    },
    {
      id: 2,
      type: "quarter",
      value: "QTD",
      starter: false,
      isSelected: false,
      startDate: moment()
        .startOf("quarter")
        .toISOString(),
      endDate: moment()
        .endOf("quarter")
        .toISOString(),
    },
    {
      id: 3,
      type: "month",
      value: "MTD",
      starter: false,
      isSelected: false,
      startDate: moment()
        .startOf("month")
        .toISOString(),
      endDate: moment()
        .endOf("month")
        .toISOString(),
    },
    {
      id: 4,
      type: "week",
      value: "1W",
      starter: false,
      isSelected: false,
      startDate: moment()
        .startOf("week")
        .toISOString(),
      endDate: moment()
        .endOf("week")
        .toISOString(),
    },
  ],
  isCustomSelected: false,
  timeRangeType: "year",
  startDate: moment()
    .startOf("year")
    .toISOString(),
  endDate: moment()
    .endOf("year")
    .toISOString(),
  viewType: "grid",
  teamDashboardType: "ALL",
  selectedTeamForDashboard: null,
  addTeamsModal: false,
  addChartModal: false,

  addingTeam: false,
  addingTeamError: false,
  fetchingTeamById: false,
  fetchingTeamByIdError: false,
  updateTeamById: false,
  updateTeamByIdError: false,
  addingInventoryLocation: false,
  team: {},

  fetchingTeams: false,
  fetchingTeamsError: false,
  teams: [],

  fetchingBestTeams: false,
  fetchingBestTeamsError: false,
  bestTeams: [],

  fetchingYourTeams: false,
  fetchingYourTeamsError: false,
  yourTeams: [],

  fetchingPromisingTeams: false,
  fetchingPromisingTeamsError: false,
  promisingTeams: [],

  fetchingChartDataForAllTeams: false,
  fetchingChartDataForAllTeamsError: false,
  chartDataForAllTeams: [],

  fetchingFunnelOfAllTeams: false,
  fetchingFunnelOfAllTeamsError: false,
  funnelOfAllTeams: [],

  fetchingOpportunitiesOfAllTeams: false,
  fetchingOpportunitiesOfAllTeamsError: false,
  opportunitiesOfAllTeams: [],

  fetchingAccountsOfAllTeams: false,
  fetchingAccountsOfAllTeamsError: false,
  accountsOfAllTeams: [],

  fetchingContactsOfAllTeams: false,
  fetchingContactsOfAllTeamsError: false,
  contactsOfAllTeams: [],

  fetchingFunnelByTeamId: false,
  fetchingFunnelByTeamIdError: false,
  funnelByTeamId: [],

  fetchingOpportunitiesByTeamId: false,
  fetchingOpportunitiesByTeamIdError: false,
  opportunitiesByTeamId: [],

  fetchingAccountsByTeamId: false,
  fetchingAccountsByTeamIdError: false,
  accountsByTeamId: [],

  fetchingContactsByTeamId: false,
  fetchingContactsByTeamIdError: false,
  contactsByTeamId: [],

  fetchingOpportunityChartByTeamId: false,
  fetchingOpportunityChartByTeamIdError: false,
  opportunityChartByTeamId: {},

  fetchingContactListByTeamId: false,
  fetchingContactListByTeamIdError: false,
  contactListByTeamId: [],

  fetchingAccountListByTeamId: false,
  fetchingAccountListByTeamIdError: false,
  accountListByTeamId: [],

  fetchingOpportunityListByTeamId: false,
  fetchingOpportunityListByTeamIdError: false,
  opportunityListByTeamId: [],

  fetchingUsersListByTeamId: false,
  fetchingUsersListByTeamIdError: false,
  usersListByTeamId: [],

  fetchingNotesListByTeamId: false,
  fetchingNotesListByTeamIdError: false,
  notesListByTeamId: [],

  fetchingEventsListByTeamId: false,
  fetchingEventsListByTeamIdError: false,
  eventsListByTeamId: [],

  fetchingListOfLocationDetails: false,
  fetchingListOfLocationDetailsError: false,
  listOfLocation: [],

  fetchingInputTeamsData: false,
  fetchingInputTeamsDataError: false,

  setEditingLocation: {},

  updateLocation: false,
  updateLocationError: false,

  addTeamMemberModal: false,
};
const newDateRange = (dateRange, newDate) =>
  dateRange.map((range) => {
    if (range.id === newDate.id) {
      return { ...range, isSelected: true };
    } else {
      return { ...range, isSelected: false };
    }
  });
const mergeFiscalAndQuarter = (dateRange, newDate) =>
  dateRange.map((date) => {
    // let q1s = newDate.metaData.fiscalMapper.q1StartDate;
    // let q1e = newDate.metaData.fiscalMapper.q1EndDate;
    // let q2s = newDate.metaData.fiscalMapper.q2StartDate;
    // let q2e = newDate.metaData.fiscalMapper.q2EndDate;
    // let q3s = newDate.metaData.fiscalMapper.q3StartDate;
    // let q3e = newDate.metaData.fiscalMapper.q3EndDate;
    // let q4s = newDate.metaData.fiscalMapper.q4StartDate;
    // let q4e = newDate.metaData.fiscalMapper.q4EndDate;

    if (date.value === "QTD") {
      return {
        ...date,
        startDate:
          newDate.metaData.fiscalMapper.metaData.currentQuarterStartDate +
          "T00:00:00Z",
        endDate:
          newDate.metaData.fiscalMapper.metaData.currentQuarterEndDate +
          "T00:00:00Z",
      };
      // if (moment().isBetween(moment(q1s), moment(q1e))) {
      //   return { ...date, startDate: q1s, endDate: q1e };
      // }
      // if (moment().isBetween(moment(q2s), moment(q2e))) {
      //   return { ...date, startDate: q2s, endDate: q2e };
      // }
      // if (moment().isBetween(moment(q3s), moment(q3e))) {
      //   return { ...date, startDate: q3s, endDate: q3e };
      // }
      // if (moment().isBetween(moment(q4s), moment(q4e))) {
      //   return { ...date, startDate: q4s, endDate: q4e };
      // }
    } else if (date.value === "FY") {
      return {
        ...date,
        startDate: newDate.metaData.fiscalMapper.fiscalStartDate,
        endDate: newDate.metaData.fiscalMapper.fiscalEndDate,
      };
    } else {
      return date;
    }
  });
export const teamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_SELECTED_TIME_INTERVAL_TEAM:
      return {
        ...state,
        dateRangeList: newDateRange(state.dateRangeList, action.payload),
        isCustomSelected: false,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        timeRangeType: action.payload.type,
      };
    case types.SET_TIME_INTERVAL_TEAM:
      return {
        ...state,
        isCustomSelected: true,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    case types.SET_FISCAL_TIME_INTERVAL_TEAM:
      return {
        ...state,
        dateRangeList: mergeFiscalAndQuarter(
          state.dateRangeList,
          action.payload
        ),
        startDate: action.payload.metaData.fiscalMapper.fiscalStartDate,
        endDate: action.payload.metaData.fiscalMapper.fiscalEndDate,
      };
    case types.GET_TEAMS_REQUEST:
      return { ...state, fetchingTeams: true, fetchingTeamsError: false };
    case types.GET_TEAMS_SUCCESS:
      return {
        ...state,
        fetchingTeams: false,
        fetchingTeamsError: false,
        teams: action.payload,
      };
    case types.GET_TEAMS_FAILURE:
      return { ...state, fetchingTeams: false, fetchingTeamsError: true };

    case types.GET_TEAM_BY_ID_REQUEST:
      return { ...state, fetchingTeamById: true, fetchingTeamByIdError: false };
    case types.GET_TEAM_BY_ID_SUCCESS:
      return {
        ...state,
        fetchingTeamById: false,
        fetchingTeamByIdError: false,
        team: action.payload,
      };
    case types.GET_TEAM_BY_ID_FAILURE:
      return { ...state, fetchingTeamById: false, fetchingTeamByIdError: true };

    case types.UPDATE_TEAM_BY_ID_REQUEST:
      return { ...state, updatingTeamById: true, updatingTeamByIdError: false };
    case types.UPDATE_TEAM_BY_ID_SUCCESS:
      return {
        ...state,
        updatingTeamById: false,
        updatingTeamByIdError: false,
        team: action.payload,
      };
    case types.UPDATE_TEAM_BY_ID_FAILURE:
      return { ...state, updatingTeamById: false, updatingTeamByIdError: true };

    case types.GET_BEST_TEAMS_REQUEST:
      return {
        ...state,
        fetchingBestTeams: true,
        fetchingBestTeamsError: false,
      };
    case types.GET_BEST_TEAMS_SUCCESS:
      return {
        ...state,
        fetchingBestTeams: false,
        fetchingBestTeamsError: false,
        bestTeams: action.payload,
      };
    case types.GET_BEST_TEAMS_FAILURE:
      return {
        ...state,
        fetchingBestTeams: false,
        fetchingBestTeamsError: true,
      };

    case types.GET_YOUR_TEAMS_REQUEST:
      return {
        ...state,
        fetchingYourTeams: true,
        fetchingYourTeamsError: false,
      };
    case types.GET_YOUR_TEAMS_SUCCESS:
      return {
        ...state,
        fetchingYourTeams: false,
        fetchingYourTeamsError: false,
        yourTeams: action.payload,
      };
    case types.GET_YOUR_TEAMS_FAILURE:
      return {
        ...state,
        fetchingYourTeams: false,
        fetchingYourTeamsError: true,
      };

    case types.GET_MOST_PROFESSIONALMISING_TEAMS_REQUEST:
      return {
        ...state,
        fetchingPromisingTeams: false,
        fetchingPromisingTeamsError: false,
      };
    case types.GET_MOST_PROFESSIONALMISING_TEAMS_SUCCESS:
      return {
        ...state,
        fetchingPromisingTeams: false,
        fetchingPromisingTeamsError: false,
        promisingTeams: action.payload,
      };
    case types.GET_MOST_PROFESSIONALMISING_TEAMS_FAILURE:
      return {
        ...state,
        fetchingPromisingTeams: false,
        fetchingPromisingTeamsError: false,
      };

    case types.ADD_TEAM_REQUEST:
      return { ...state, addingTeam: true };
    case types.ADD_TEAM_SUCCESS:
      return { ...state, addingTeam: false, addTeamsModal: false };
    case types.ADD_TEAM_FAILURE:
      return { ...state, addingTeam: false, addingTeamError: true };

    case types.GET_CHART_DATA_FOR_ALL_TEAMS_REQUEST:
      return {
        ...state,
        fetchingChartDataForAllTeams: true,
        fetchingChartDataForAllTeamsError: false,
      };
    case types.GET_CHART_DATA_FOR_ALL_TEAMS_SUCCESS:
      return {
        ...state,
        fetchingChartDataForAllTeams: false,
        fetchingChartDataForAllTeamsError: false,
        chartDataForAllTeams: action.payload,
      };
    case types.GET_CHART_DATA_FOR_ALL_TEAMS_FAILURE:
      return {
        ...state,
        fetchingChartDataForAllTeams: false,
        fetchingChartDataForAllTeamsError: true,
      };

    case types.GET_FUNNEL_OF_ALL_TEAMS_REQUEST:
      return {
        ...state,
        fetchingFunnelOfAllTeams: true,
        fetchingFunnelOfAllTeamsError: false,
      };
    case types.GET_FUNNEL_OF_ALL_TEAMS_SUCCESS:
      return {
        ...state,
        fetchingFunnelOfAllTeams: false,
        fetchingFunnelOfAllTeamsError: false,
        funnelOfAllTeams: action.payload,
      };
    case types.GET_FUNNEL_OF_ALL_TEAMS_FAILURE:
      return {
        ...state,
        fetchingFunnelOfAllTeams: false,
        fetchingFunnelOfAllTeamsError: true,
      };

    case types.GET_OPPORTUNITIES_OF_ALL_TEAMS_REQUEST:
      return {
        ...state,
        fetchingOpportunitiesOfAllTeams: true,
        fetchingOpportunitiesOfAllTeamsError: false,
      };
    case types.GET_OPPORTUNITIES_OF_ALL_TEAMS_SUCCESS:
      return {
        ...state,
        fetchingOpportunitiesOfAllTeams: false,
        fetchingOpportunitiesOfAllTeamsError: false,
        opportunitiesOfAllTeams: action.payload,
      };
    case types.GET_OPPORTUNITIES_OF_ALL_TEAMS_FAILURE:
      return {
        ...state,
        fetchingOpportunitiesOfAllTeams: false,
        fetchingOpportunitiesOfAllTeamsError: true,
      };

    case types.GET_ACCOUNTS_OF_ALL_TEAMS_REQUEST:
      return {
        ...state,
        fetchingAccountsOfAllTeams: true,
        fetchingAccountsOfAllTeamsError: false,
      };
    case types.GET_ACCOUNTS_OF_ALL_TEAMS_SUCCESS:
      return {
        ...state,
        fetchingAccountsOfAllTeams: false,
        fetchingAccountsOfAllTeamsError: false,
        accountsOfAllTeams: action.payload,
      };
    case types.GET_ACCOUNTS_OF_ALL_TEAMS_FAILURE:
      return {
        ...state,
        fetchingAccountsOfAllTeams: false,
        fetchingAccountsOfAllTeamsError: true,
      };

    case types.GET_CONTACTS_OF_ALL_TEAMS_REQUEST:
      return {
        ...state,
        fetchingContactsOfAllTeams: true,
        fetchingContactsOfAllTeamsError: false,
      };
    case types.GET_CONTACTS_OF_ALL_TEAMS_SUCCESS:
      return {
        ...state,
        fetchingContactsOfAllTeams: false,
        fetchingContactsOfAllTeamsError: false,
        contactsOfAllTeams: action.payload,
      };
    case types.GET_CONTACTS_OF_ALL_TEAMS_FAILURE:
      return {
        ...state,
        fetchingContactsOfAllTeams: false,
        fetchingContactsOfAllTeamsError: true,
      };

    case types.GET_FUNNEL_BY_TEAM_ID_REQUEST:
      return {
        ...state,
        fetchingFunnelByTeamId: true,
        fetchingFunnelByTeamIdError: false,
      };
    case types.GET_FUNNEL_BY_TEAM_ID_SUCCESS:
      return {
        ...state,
        fetchingFunnelByTeamId: false,
        fetchingFunnelByTeamIdError: false,
        funnelByTeamId: action.payload,
      };
    case types.GET_FUNNEL_BY_TEAM_ID_FAILURE:
      return {
        ...state,
        fetchingFunnelByTeamId: false,
        fetchingFunnelByTeamIdError: true,
      };

    case types.GET_OPPORTUNITIES_BY_TEAM_ID_REQUEST:
      return {
        ...state,
        fetchingOpportunitiesByTeamId: true,
        fetchingOpportunitiesByTeamIdError: false,
      };
    case types.GET_OPPORTUNITIES_BY_TEAM_ID_SUCCESS:
      return {
        ...state,
        fetchingOpportunitiesByTeamId: false,
        fetchingOpportunitiesByTeamIdError: false,
        opportunitiesByTeamId: action.payload,
      };
    case types.GET_OPPORTUNITIES_BY_TEAM_ID_FAILURE:
      return {
        ...state,
        fetchingOpportunitiesByTeamId: false,
        fetchingOpportunitiesByTeamIdError: true,
      };

    case types.GET_ACCOUNTS_BY_TEAM_ID_REQUEST:
      return {
        ...state,
        fetchingAccountsByTeamId: true,
        fetchingAccountsByTeamIdError: false,
      };
    case types.GET_ACCOUNTS_BY_TEAM_ID_SUCCESS:
      return {
        ...state,
        fetchingAccountsByTeamId: false,
        fetchingAccountsByTeamIdError: false,
        accountsByTeamId: action.payload,
      };
    case types.GET_ACCOUNTS_BY_TEAM_ID_FAILURE:
      return {
        ...state,
        fetchingAccountsByTeamId: false,
        fetchingAccountsByTeamIdError: true,
      };

    case types.GET_CONTACTS_BY_TEAM_ID_REQUEST:
      return {
        ...state,
        fetchingContactsByTeamId: true,
        fetchingContactsByTeamIdError: false,
      };
    case types.GET_CONTACTS_BY_TEAM_ID_SUCCESS:
      return {
        ...state,
        fetchingContactsByTeamId: false,
        fetchingContactsByTeamIdError: false,
        contactsByTeamId: action.payload,
      };
    case types.GET_CONTACTS_BY_TEAM_ID_FAILURE:
      return {
        ...state,
        fetchingContactsByTeamId: false,
        fetchingContactsByTeamIdError: true,
      };

    case types.GET_OPPORTUNITY_CHART_BY_TEAM_ID_REQUEST:
      return {
        ...state,
        fetchingOpportunityChartByTeamId: true,
        fetchingOpportunityChartByTeamIdError: false,
      };
    case types.GET_OPPORTUNITY_CHART_BY_TEAM_ID_SUCCESS:
      return {
        ...state,
        fetchingOpportunityChartByTeamId: false,
        fetchingOpportunityChartByTeamIdError: false,
        opportunityChartByTeamId: action.payload,
      };
    case types.GET_OPPORTUNITY_CHART_BY_TEAM_ID_FAILURE:
      return {
        ...state,
        fetchingOpportunityChartByTeamId: false,
        fetchingOpportunityChartByTeamIdError: true,
      };

    case types.GET_CONTACT_LIST_BY_TEAM_ID_REQUEST:
      return {
        ...state,
        fetchingContactListByTeamId: true,
        fetchingContactListByTeamIdError: false,
      };
    case types.GET_CONTACT_LIST_BY_TEAM_ID_SUCCESS:
      return {
        ...state,
        fetchingContactListByTeamId: false,
        fetchingContactListByTeamIdError: false,
        contactListByTeamId: action.payload,
      };
    case types.GET_CONTACT_LIST_BY_TEAM_ID_FAILURE:
      return {
        ...state,
        fetchingContactListByTeamId: false,
        fetchingContactListByTeamIdError: true,
      };

    case types.GET_ACCOUNT_LIST_BY_TEAM_ID_REQUEST:
      return {
        ...state,
        fetchingAccountListByTeamId: true,
        fetchingAccountListByTeamIdError: false,
      };
    case types.GET_ACCOUNT_LIST_BY_TEAM_ID_SUCCESS:
      return {
        ...state,
        fetchingAccountListByTeamId: false,
        fetchingAccountListByTeamIdError: false,
        accountListByTeamId: action.payload,
      };
    case types.GET_ACCOUNT_LIST_BY_TEAM_ID_FAILURE:
      return {
        ...state,
        fetchingAccountListByTeamId: false,
        fetchingAccountListByTeamIdError: true,
      };

    case types.GET_OPPORTUNITY_LIST_BY_TEAM_ID_REQUEST:
      return {
        ...state,
        fetchingOpportunityListByTeamId: true,
        fetchingOpportunityListByTeamIdError: false,
      };
    case types.GET_OPPORTUNITY_LIST_BY_TEAM_ID_SUCCESS:
      return {
        ...state,
        fetchingOpportunityListByTeamId: false,
        fetchingOpportunityListByTeamIdError: false,
        opportunityListByTeamId: action.payload,
      };
    case types.GET_OPPORTUNITY_LIST_BY_TEAM_ID_FAILURE:
      return {
        ...state,
        fetchingOpportunityListByTeamId: false,
        fetchingOpportunityListByTeamIdError: true,
      };

    case types.GET_USERS_LIST_BY_TEAM_ID_REQUEST:
      return {
        ...state,
        fetchingUsersListByTeamId: true,
        fetchingUsersListByTeamIdError: false,
      };
    case types.GET_USERS_LIST_BY_TEAM_ID_SUCCESS:
      return {
        ...state,
        fetchingUsersListByTeamId: false,
        fetchingUsersListByTeamIdError: false,
        usersListByTeamId: action.payload,
      };
    case types.GET_USERS_LIST_BY_TEAM_ID_FAILURE:
      return {
        ...state,
        fetchingUsersListByTeamId: false,
        fetchingUsersListByTeamIdError: true,
      };

    case types.GET_NOTES_LIST_BY_TEAM_ID_REQUEST:
      return {
        ...state,
        fetchingNotesListByTeamId: true,
        fetchingNotesListByTeamIdError: false,
      };
    case types.GET_NOTES_LIST_BY_TEAM_ID_SUCCESS:
      return {
        ...state,
        fetchingNotesListByTeamId: false,
        fetchingNotesListByTeamIdError: false,
        notesListByTeamId: action.payload,
      };
    case types.GET_NOTES_LIST_BY_TEAM_ID_FAILURE:
      return {
        ...state,
        fetchingNotesListByTeamId: false,
        fetchingNotesListByTeamIdError: true,
      };

    case types.GET_EVENTS_LIST_BY_TEAM_ID_REQUEST:
      return {
        ...state,
        fetchingEventsListByTeamId: true,
        fetchingEventsListByTeamIdError: false,
      };
    case types.GET_EVENTS_LIST_BY_TEAM_ID_SUCCESS:
      return {
        ...state,
        fetchingEventsListByTeamId: false,
        fetchingEventsListByTeamIdError: false,
        eventsListByTeamId: action.payload,
      };
    case types.GET_EVENTS_LIST_BY_TEAM_ID_FAILURE:
      return {
        ...state,
        fetchingEventsListByTeamId: false,
        fetchingEventsListByTeamIdError: true,
      };

    /**
     * handle Teams form modal
     */
    case types.HANDLE_TEAMS_MODAL:
      return { ...state, addTeamsModal: action.payload };

    case types.HANDLE_CHART_MODAL:
      return { ...state, addChartModal: action.payload };

    case types.HANDLE_ADD_TEAMS_MEMBER_MODAL:
      return { ...state, addTeamMemberModal: action.payload };

    /**
     * set team dashboard to ALl or individual team
     */
    case types.SET_TEAMS_DASHBOARD_TYPE:
      console.log(action.payload);
      return { ...state, teamDashboardType: action.payload };

    /**
     * set selected team
     */
    case types.SET_SELECTED_TEAM:
      return { ...state, selectedTeamForDashboard: action.payload };

    /**
     * set viewtype to grid or table
     */
    case types.SET_TEAMS_VIEW_TYPE:
      return { ...state, viewType: action.payload, teamDashboardType: "ALL" };

    case types.HANDLE_WEIGHTED_MODAL:
      return { ...state, addWeightedModal: action.payload };

    case types.HANDLE_ABSOLUTE_MODAL:
      return { ...state, addAbsoluteModal: action.payload };

    case types.HANDLE_CLOSURE_MODAL:
      return { ...state, addClosureModal: action.payload };

    case types.HANDLE_WIN_MODAL:
      return { ...state, addWinModal: action.payload };

    case types.HANDLE_WON_MODAL:
      return { ...state, addWonModal: action.payload };

    case types.HANDLE_CUSTOMER_MODAL:
      return { ...state, addCustomerModal: action.payload };

    case types.HANDLE_WEIGHTEDCHART_MODAL:
      return { ...state, addWeightedChartModal: action.payload };

    case types.HANDLE_ACTUALCHART_MODAL:
      return { ...state, addActualChartModal: action.payload };

    case types.HANDLE_PROGRESSCHART_MODAL:
      return { ...state, addProgressChartModal: action.payload };

    case types.HANDLE_WINCHART_MODAL:
      return { ...state, addWinChartModal: action.payload };

    case types.HANDLE_WONCHART_MODAL:
      return { ...state, addWonChartModal: action.payload };

    case types.HANDLE_CUSTOMERCHART_MODAL:
      return { ...state, addCustomerChartModal: action.payload };
    //LOCATION
    case types.ADD_LOCATION_REQUEST:
      return { ...state, addingLocation: true };
    case types.ADD_LOCATION_SUCCESS:
      return {
        ...state,
        addingLocation: false,
        addLocationModal: false,
      };
    case types.ADD_LOCATION_FAILURE:
      return {
        ...state,
        addingLocation: false,
        addingLocationError: true,
        addLocationModal: false,
      };
    case types.GET_LOCATION_DETAILS_REQUEST:
      return { ...state, fetchingLocationDetails: true };
    case types.GET_LOCATION_DETAILS_SUCCESS:
      return {
        ...state,
        fetchingLocationDetails: false,
        locationDetails: action.payload,
      };
    case types.GET_LOCATION_DETAILS_FAILURE:
      return {
        ...state,
        fetchingLocationDetails: false,
        fetchingLocationDetailsError: true,
      };
    case types.HANDLE_CARD_LOCATION_ICON_MODAL:
      return { ...state, addCardLocationIconModal: action.payload };

    case types.GET_LIST_OF_LOCATION_DETAILS_REQUEST:
      return { ...state, fetchingListOfLocationDetails: true };
    case types.GET_LIST_OF_LOCATION_DETAILS_SUCCESS:
      return {
        ...state,
        fetchingListOfLocationDetails: false,
        listOfLocation: action.payload,
      };
    case types.GET_LIST_OF_LOCATION_DETAILS_FAILURE:
      return {
        ...state,
        fetchingListOfLocationDetails: false,
        fetchingListOfLocationDetailsError: true,
      };
    case types.HANDLE_LOCATION_USER_MODAL:
      return { ...state, addCardLocationIconModalForTable: action.payload };

    case types.HANDLE_UPDATE_LOCATION_USER_MODAL:
      return { ...state, updateLocationIconModalForTable: action.payload };

    case types.ADD_INVENTORY_LOCATION_REQUEST:
      return { ...state, addingInventoryLocation: true };
    case types.ADD_INVENTORY_LOCATION_SUCCESS:
      return {
        ...state,
        addingInventoryLocation: false,
        addCardLocationIconModal: false,
      };
    case types.ADD_INVENTORY_LOCATION_FAILURE:
      return {
        ...state,
        addingInventoryLocation: false,
        addingInventoryLocationError: true,
        addCardLocationIconModal: false,
      };

    case types.GET_LOCATION_WISE_USER_REQUEST:
      return { ...state, fetchingLocationWiseUser: true };
    case types.GET_LOCATION_WISE_USER_SUCCESS:
      return {
        ...state,
        fetchingLocationWiseUser: false,
        locationWiseUser: action.payload,
      };
    case types.GET_LOCATION_WISE_USER_FAILURE:
      return {
        ...state,
        fetchingLocationWiseUser: false,
        fetchingLocationWiseUserError: true,
      };
    case types.SET_LOCATION_EDIT:
      return { ...state, setEditingLocation: action.payload };

    case types.UPDATE_LOCATION_REQUEST:
      return { ...state, updateLocation: true };
    case types.UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        updateLocation: false,
        updateLocationIconModalForTable: false,
      };
    case types.UPDATE_LOCATION_FAILURE:
      return {
        ...state,
        updateLocation: false,
        updateLocationError: true,
        updateLocationIconModalForTable: false,
      };

    case types.INPUT_SEARCH_TEAMS_DATA_REQUEST:
      return { ...state, fetchingInputTeamsData: true };
    case types.INPUT_SEARCH_TEAMS_DATA_SUCCESS:
      return {
        ...state,
        fetchingInputTeamsData: false,
        teams: action.payload,
      };
    case types.INPUT_SEARCH_TEAMS_DATA_FAILURE:
      return { ...state, fetchingInputTeamsDataError: true };

    default:
      return state;
  }
};
