import * as types from "./LeadsActionTypes";
const initialState = {
  addLeadsAccountModal: false,
  addLeadsContactModal: false,
  editLeadsContactModal: false,
  editLeadsAccountModal: false,
  addQualifiedModal: false,
  addLeadsContactQualifierModal: false,

  addingLeadsAccount: false,
  addingLeadsAccountError: false,

  addLeadsAccountSchedulerModal: false,

  fetchingLeadsAccounts: false,
  fetchingLeadsAccountsError: false,
  leadsAccounts: [],

  fetchingLeadsAccountsWithOutPageNo: false,
  fetchingLeadsAccountsWithOutPageNoError: false,
  leadsAccountsWithOutPageNo: [],

  convertingLeadsAccountToActualAccount: false,
  convertingLeadsAccountToActualAccountError: false,
  convertingLeadsContactToActualContact: false,
  convertingLeadsContactToActualContactError: false,

  addingLeadsContact: false,
  addingLeadsContactError: false,

  fetchingLeadsContacts: false,
  fetchingLeadsContactsError: false,
  leadsContacts: [],

  fetchingLeadsContactsWithoutPage: false,
  fetchingLeadsContactsWithoutPageError: false,
  leadsContactsWithoutPage: [],

  fetchingFilterdLeadsContactsByCategory: false,
  fetchingFilterdLeadsContactsByCategoryError: false,

  setEditingLeadsContact: {},
  setEditingLeadsAccount: {},

  updatingLeadsContact: false,
  updatingLeadsContactError: false,

  updatingLeadsAccount: false,
  updatingLeadsAccountError: false,

  fetchingLeadsDesignationContacts: false,
  fetchingLeadsDesignationContactsError: false,

  //approcahing Leads
  fetchingApproachingLeads: false,
  fetchingApproachingLeadsError: false,
  approachingLeads: [],

  //junk Leads
  fetchingJunkLeads: false,
  fetchingJunkLeadsError: false,
  junkLeads: [],

  reassigningJunkContacts: false,
  reassigningJunkContactsError: false,

  leadType: "me",

  fetchingLeadsFilterJunkContacts: false,
  fetchingLeadsFilterJunkContactsError: false,
  leadsContactsJunk: [],

  fetchingFilterdLeadsJunkContactsByCategory: false,
  fetchingFilterdLeadsJunkContactsByCategoryError: false,

  fetchingLeadsCountryContacts: false,
  fetchingLeadsCountryContactsError: false,

  //cleanup contct,account,junk leads
  deletingLeadsAccount: false,
  deletingLeadsAccountError: false,

  deletingLeadsContact: false,
  deletingLeadsContactError: false,

  deletingJunkLeadsContact: false,
  deletingJunkLeadsContactError: false,

  addingLeadsAccountSchedulerCall: false,
  addingLeadsAccountSchedulerCallError: false,

  addEventTaskModal: false,

  addLeadsOpportunityModal: false,

  addingLeadsSchedulerEvent: false,
  addingLeadsSchedulerEventError: false,

  addingLeadsAccountSchedulerEvent: false,
  addingLeadsAccountSchedulerEventError: false,

  fetchingLeadsSchedulerEvent: false,
  fetchingLeadsSchedulerEventError: false,
  leadsSchedulerEvent: [],

  fetchingTimelineListByUserId: false,
  fetchingTimelineListByUserIdError: false,
  timelineListByUserId: [],

  addingLeadsSchedulerCall: false,
  addingLeadsSchedulerCallError: false,

  fetchingLeadsSchedulerCall: false,
  fetchingLeadsSchedulerCallError: false,
  leadsSchedulerCall: [],

  addContactTimelineModal: false,
  addAccountTimelineModal: false,

  fetchingNotesListByLeadsId: false,
  fetchingNotesListByLeadsIdError: false,
  notesListByLeadsId: [],

  fetchingContactNotesListByLeadsId: false,
  fetchingContactNotesListByLeadsIdError: false,
  contactNotesListByLeadsId: [],

  fetchingAccountTimelineListByUserId: false,
  fetchingAccountTimelineListByUserIdError: false,
  accountTimelineListByUserId: [],

  setEditingNote: {},
  addLeadsNotesModal: false,
  addLeadsContactNotesModal: false,

  fetchingLeadsAccountListRangeByUserIdForReport: false,
  fetchingLeadsAccountListRangeByUserIdForReportError: false,
  leadsAccountListRangeByUserIdForReport: [],

  fetchingLeadsContactListRangeByUserIdForReport: false,
  fetchingLeadsContactListRangeByUserIdForReportError: false,
  leadsContactListRangeByUserIdForReport: [],

  //SHARE Account Permission of customer
  addSharingLead: false,
  addSharingLeadError: false,

  fetchingPermissionsListLead: false,
  fetchingPermissionsListLeadError: false,
  permissionsDataListLead: [],
};
export const leadReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LEADS_TYPE:
      return { ...state, leadType: action.payload };

    case types.SET_LEADS_CONTACT_EDIT:
      return { ...state, setEditingLeadsContact: action.payload };

    case types.SET_LEADS_ACCOUNT_EDIT:
      return { ...state, setEditingLeadsAccount: action.payload };

    case types.SET_NOTE_EDIT:
      return { ...state, setEditingNote: action.payload };

    case types.HANDLE_LEADS_ACCOUNT_MODAL:
      return { ...state, addLeadsAccountModal: action.payload };

    case types.HANDLE_LEADS_CONTACT_MODAL:
      return { ...state, addLeadsContactModal: action.payload };

    case types.HANDLE_LEADS_CONTACT_NOTES_MODAL:
      return { ...state, addLeadsContactNotesModal: action.payload };

    case types.HANDLE_EDIT_LEADS_CONTACT_MODAL:
      return { ...state, editLeadsContactModal: action.payload };

    case types.HANDLE_EDIT_LEADS_ACCOUNT_MODAL:
      return { ...state, editLeadsAccountModal: action.payload };

    case types.HANDLE_LEADS_ACCOUNT_SCHEDULER_MODAL:
      return { ...state, addLeadsAccountSchedulerModal: action.payload };

    case types.HANDLE_CONTACT_TIMELINE_MODAL:
      return { ...state, addContactTimelineModal: action.payload };

    case types.HANDLE_ACCOUNT_TIMELINE_MODAL:
      return { ...state, addAccountTimelineModal: action.payload };

    case types.ADD_LEADS_ACCOUNT_REQUEST:
      return { ...state, addingLeadsAccount: true };
    case types.ADD_LEADS_ACCOUNT_SUCCESS:
      return {
        ...state,
        addingLeadsAccount: false,
        addLeadsAccountModal: false,
      };
    case types.ADD_LEADS_ACCOUNT_FAILURE:
      return {
        ...state,
        addingLeadsAccount: false,
        addingLeadsAccountError: true,
      };

    case types.CONVERT_LEADS_ACCOUNT_TO_ACTUAL_ACCOUNT_REQUEST:
      return { ...state, convertingLeadsAccountToActualAccount: true };
    case types.CONVERT_LEADS_ACCOUNT_TO_ACTUAL_ACCOUNT_SUCCESS:
      return {
        ...state,
        convertingLeadsAccountToActualAccount: false,
      };
    case types.CONVERT_LEADS_ACCOUNT_TO_ACTUAL_ACCOUNT_FAILURE:
      return {
        ...state,
        convertingLeadsAccountToActualAccount: false,
        convertingLeadsAccountToActualAccountError: true,
      };

    case types.GET_LEADS_ACCOUNT_REQUEST:
      return { ...state, fetchingLeadsAccounts: true };
    case types.GET_LEADS_ACCOUNT_SUCCESS:
      return {
        ...state,
        fetchingLeadsAccounts: false,
        leadsAccounts: [...state.leadsAccounts, ...action.payload],
      };
    case types.GET_LEADS_ACCOUNT_FAILURE:
      return {
        ...state,
        fetchingLeadsAccounts: false,
        fetchingLeadsAccountsError: true,
      };

    case types.GET_LEADS_ACCOUNT_WITHOUT_PAGE_NO_REQUEST:
      return { ...state, fetchingLeadsAccountsWithOutPageNo: true };
    case types.GET_LEADS_ACCOUNT_WITHOUT_PAGE_NO_SUCCESS:
      return {
        ...state,
        fetchingLeadsAccountsWithOutPageNo: false,
        leadsAccountsWithOutPageNo: action.payload,
      };
    case types.GET_LEADS_ACCOUNT_WITHOUT_PAGE_NO_FAILURE:
      return {
        ...state,
        fetchingLeadsAccountsWithOutPageNo: false,
        fetchingLeadsAccountsWithOutPageNoError: true,
      };

    case types.UPDATE_ACCOUNT_TYPE_FOR_LEAD_REQUEST:
      return { ...state };
    case types.UPDATE_ACCOUNT_TYPE_FOR_LEAD_SUCCESS:
      return {
        ...state,
        leadsAccounts: state.leadsAccounts.map((item) =>
          item.leadsId === action.payload.leadsId
            ? { ...item, leadCategory: action.payload.leadCategory }
            : item
        ),
      };
    case types.UPDATE_ACCOUNT_TYPE_FOR_LEAD_FAILURE:
      return { ...state };

    case types.ADD_LEADS_CONTACT_REQUEST:
      return { ...state, addingLeadsContact: true };
    case types.ADD_LEADS_CONTACT_SUCCESS:
      return {
        ...state,
        addingLeadsContact: false,
        addLeadsContactModal: false,
      };
    case types.ADD_LEADS_CONTACT_FAILURE:
      return {
        ...state,
        addingLeadsContact: false,
        addingLeadsContactError: true,
      };

    case types.GET_LEADS_CONTACT_REQUEST:
      return { ...state, fetchingLeadsContacts: true };
    case types.GET_LEADS_CONTACT_SUCCESS:
      return {
        ...state,
        fetchingLeadsContacts: false,
        leadsContacts: [...state.leadsContacts, ...action.payload],
      };
    case types.GET_LEADS_CONTACT_FAILURE:
      return {
        ...state,
        fetchingLeadsContacts: false,
        fetchingLeadsContactsError: true,
      };

    case types.GET_LEADS_CONTACT_WITHOUT_PAGE_REQUEST:
      return { ...state, fetchingLeadsContactsWithoutPage: true };
    case types.GET_LEADS_CONTACT_WITHOUT_PAGE_SUCCESS:
      return {
        ...state,
        fetchingLeadsContactsWithoutPage: false,
        leadsContactsWithoutPage: action.payload,
      };
    case types.GET_LEADS_CONTACT_WITHOUT_PAGE_FAILURE:
      return {
        ...state,
        fetchingLeadsContactsWithoutPage: false,
        fetchingLeadsContactsWithoutPageError: true,
      };

    case types.UPDATE_CONTACT_TYPE_FOR_LEAD_REQUEST:
      return { ...state };
    case types.UPDATE_CONTACT_TYPE_FOR_LEAD_SUCCESS:
      return {
        ...state,

        leadsContacts: state.leadsContacts.map((item) => {
          if (item.leadsId === action.payload.leadsId)
            return {
              ...item,
              dncInd: action.payload.dncInd,
              leadCategory: action.payload.leadCategory,
              junkInd: action.payload.junkInd,
            };
          else {
            return item;
          }
        }),

        leadsContactsJunk: state.leadsContactsJunk.map((item) => {
          if (item.leadsId === action.payload.leadsId)
            return {
              ...item,
              dncInd: action.payload.dncInd,
              leadCategory: action.payload.leadCategory,
            };
          else {
            return item;
          }
        }),
      };
    case types.UPDATE_CONTACT_TYPE_FOR_LEAD_FAILURE:
      return { ...state };

    case types.CONVERT_LEADS_CONTACT_TO_ACTUAL_CONTACT_REQUEST:
      return { ...state, convertingLeadsContactToActualContact: true };
    case types.CONVERT_LEADS_CONTACT_TO_ACTUAL_CONTACT_SUCCESS:
      return {
        ...state,
        convertingLeadsContactToActualContact: false,
      };
    case types.CONVERT_LEADS_CONTACT_TO_ACTUAL_CONTACT_FAILURE:
      return {
        ...state,
        convertingLeadsContactToActualContact: false,
        convertingLeadsContactToActualContactError: true,
      };

    case types.GET_FILTERS_LEADS_CONTACT_BY_CATEGORY_REQUEST:
      return { ...state, fetchingFilterdLeadsContactsByCategory: true };
    case types.GET_FILTERS_LEADS_CONTACT_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        fetchingFilterdLeadsContactsByCategory: false,
        leadsContacts: action.payload,
      };
    case types.GET_FILTERS_LEADS_CONTACT_BY_CATAOGRY_FAILURE:
      return {
        ...state,
        fetchingFilterdLeadsContactsByCategory: false,
        fetchingFilterdLeadsContactsByCategoryError: true,
      };

    case types.GET_FILTERS_LEADS_JUNK_CONTACT_BY_CATEGORY_REQUEST:
      return { ...state, fetchingFilterdLeadsJunkContactsByCategory: true };
    case types.GET_FILTERS_LEADS_JUNK_CONTACT_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        fetchingFilterdLeadsJunkContactsByCategory: false,
        leadsContactsJunk: action.payload,
      };
    case types.GET_FILTERS_LEADS_JUNK_CONTACT_BY_CATAOGRY_FAILURE:
      return {
        ...state,
        fetchingFilterdLeadsJunkContactsByCategory: false,
        fetchingFilterdLeadsJunkContactsByCategoryError: true,
      };

    case types.GET_FILTERS_LEADS_ACCOUNT_BY_CATEGORY_REQUEST:
      return { ...state, fetchingFilterdLeadsAccountsByCategory: true };
    case types.GET_FILTERS_LEADS_ACCOUNT_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        fetchingFilterdLeadsAccountsByCategory: false,
        leadsAccounts: action.payload,
      };
    case types.GET_FILTERS_LEADS_ACCOUNT_BY_CATAOGRY_FAILURE:
      return {
        ...state,
        fetchingFilterdLeadsAccountsByCategory: false,
        fetchingFilterdLeadsAccountsByCategoryError: true,
      };

    //update Contact /Account

    case types.UPDATE_CONTACT_LEAD_REQUEST:
      return { ...state, updatingLeadsContact: true };
    case types.UPDATE_CONTACT_LEAD_SUCCESS:
      return {
        ...state,
        updatingLeadsContact: false,
        // leadsContacts: state.leadsContacts.map((item) =>
        //   item.leadsId === action.payload.leadsId ? action.payload : item
        // ),
        leadsContacts: state.leadsContacts.map((item) => {
          if (item.leadsId === action.payload.leadsId) {
            return action.payload;
          } else {
            return item;
          }
        }),

        leadsContactsJunk: state.leadsContactsJunk.map((item) => {
          if (item.leadsId === action.payload.leadsId) {
            return action.payload;
          } else {
            return item;
          }
        }),
        editLeadsContactModal: false,
      };
    case types.UPDATE_CONTACT_LEAD_FAILURE:
      return {
        ...state,
        updatingLeadsContact: false,
        updatingLeadsContactError: true,
      };

    case types.UPDATE_ACCOUNT_LEAD_REQUEST:
      return { ...state, updatingLeadsAccount: true };
    case types.UPDATE_ACCOUNT_LEAD_SUCCESS:
      return {
        ...state,
        updatingLeadsAccount: false,
        leadsAccounts: state.leadsAccounts.map((item) =>
          item.leadsId === action.payload.leadsId ? action.payload : item
        ),
        editLeadsAccountModal: false,
      };
    case types.UPDATE_ACCOUNT_LEAD_FAILURE:
      return {
        ...state,
        updatingLeadsAccount: false,
        updatingLeadsAccountError: true,
      };

    case types.GET_DESIGNATION_WISE_CONTACT_REQUEST:
      return { ...state, fetchingLeadsDesignationContacts: true };
    case types.GET_DESIGNATION_WISE_CONTACT_SUCCESS:
      return {
        ...state,
        fetchingLeadsDesignationContacts: false,
        leadsContacts: action.payload,
      };
    case types.GET_DESIGNATION_WISE_CONTACT_FAILURE:
      return {
        ...state,
        fetchingLeadsDesignationContacts: false,
        fetchingLeadsDesignationContactsError: true,
      };

    case types.GET_COUNTRY_WISE_CONTACT_REQUEST:
      return { ...state, fetchingLeadsCountryContacts: true };
    case types.GET_COUNTRY_WISE_CONTACT_SUCCESS:
      return {
        ...state,
        fetchingLeadsCountryContacts: false,
        leadsContacts: action.payload,
      };
    case types.GET_COUNTRY_WISE_CONTACT_FAILURE:
      return {
        ...state,
        fetchingLeadsCountryContacts: false,
        fetchingLeadsCountryContactsError: true,
      };

    case types.GET_APPRAOCHING_LEADS_REQUEST:
      return { ...state, fetchingApproachingLeads: true };
    case types.GET_APPRAOCHING_LEADS_SUCCESS:
      return {
        ...state,
        fetchingApproachingLeads: false,
        approachingLeads: action.payload,
      };
    case types.GET_APPRAOCHING_LEADS_FAILURE:
      return {
        ...state,
        fetchingApproachingLeadsError: true,
        fetchingApproachingLeads: false,
      };

    case types.GET_JUNKED_LEADS_REQUEST:
      return { ...state, fetchingJunkLeads: true };
    case types.GET_JUNKED_LEADS_SUCCESS:
      return {
        ...state,
        fetchingJunkLeads: false,
        junkLeads: [...state.junkLeads, ...action.payload],
      };
    case types.GET_JUNKED_LEADS_FAILURE:
      return {
        ...state,
        fetchingJunkLeadsError: true,
        fetchingJunkLeads: false,
      };

    case types.GET_FILTER_JUNK_CONTACT_REQUEST:
      return { ...state, fetchingLeadsFilterJunkContacts: true };
    case types.GET_FILTER_JUNK_CONTACT_SUCCESS:
      return {
        ...state,
        fetchingLeadsFilterJunkContacts: false,
        leadsContactsJunk: action.payload,
      };
    case types.GET_FILTER_JUNK_CONTACT_FAILURE:
      return {
        ...state,
        fetchingLeadsFilterJunkContacts: false,
        fetchingLeadsFilterJunkContactsError: true,
      };

    case types.REASSIGN_JUNK_CONTACT_REQUEST:
      return { ...state, reassigningJunkContacts: true };
    case types.REASSIGN_JUNK_CONTACT_SUCCESS:
      return {
        ...state,
        reassigningJunkContacts: false,
      };
    case types.REASSIGN_JUNK_CONTACT_FAILURE:
      return {
        ...state,
        reassigningJunkContacts: false,
        reassigningJunkContactsError: true,
      };
    case types.CLEAN_LEADS_CONTACT:
      return { ...state, leadsContacts: [] };
    case types.CLEAN_LEADS_ACCOUNT:
      return { ...state, leadsAccounts: [] };
    case types.CLEAN_JUNK_LEADS_CONTACT:
      return { ...state, junkLeads: [] };

    //cleanup leads contact,account,junk leads
    case types.CLEANUP_LEADS_ACCOUNT_REQUEST:
      return { ...state, deletingLeadsAccount: true };
    case types.CLEANUP_LEADS_ACCOUNT_SUCCESS:
      return { ...state, deletingLeadsAccount: false };
    case types.CLEANUP_LEADS_ACCOUNT_FAILURE:
      return {
        ...state,
        deletingLeadsAccount: false,
        deletingLeadsAccountError: true,
      };

    case types.CLEANUP_LEADS_CONTACT_REQUEST:
      return { ...state, deletingLeadsContact: true };
    case types.CLEANUP_LEADS_CONTACT_SUCCESS:
      return { ...state, deletingLeadsContact: false };
    case types.CLEANUP_LEADS_CONTACT_FAILURE:
      return {
        ...state,
        deletingLeadsContact: false,
        deletingLeadsContactError: true,
      };

    case types.CLEANUP_JUNK_LEADS_CONTACT_REQUEST:
      return { ...state, deletingJunkLeadsContact: true };
    case types.CLEANUP_JUNK_LEADS_CONTACT_SUCCESS:
      return { ...state, deletingJunkLeadsContact: false };
    case types.CLEANUP_JUNK_LEADS_CONTACT_FAILURE:
      return {
        ...state,
        deletingJunkLeadsContact: false,
        deletingJunkLeadsContactError: true,
      };
    case types.HANDLE_EVENT_TASK_MODAL:
      return { ...state, addEventTaskModal: action.payload };

    case types.HANDLE_LEADS_NOTES_MODAL:
      return { ...state, addLeadsNotesModal: action.payload };

    case types.ADD_LEADS_SCHEDULER_EVENT_REQUEST:
      return { ...state, addingLeadsSchedulerEvent: true };
    case types.ADD_LEADS_SCHEDULER_EVENT_SUCCESS:
      return {
        ...state,
        addingLeadsSchedulerEvent: false,
        addEventTaskModal: false,
      };
    case types.ADD_LEADS_SCHEDULER_EVENT_FAILURE:
      return {
        ...state,
        addingLeadsSchedulerEvent: false,
        addingLeadsSchedulerEventError: true,
      };
    case types.GET_LEADS_SCHEDULER_EVENT_REQUEST:
      return { ...state, fetchingLeadsSchedulerEvent: true };
    case types.GET_LEADS_SCHEDULER_EVENT_SUCCESS:
      return {
        ...state,
        fetchingLeadsSchedulerEvent: false,
        leadsSchedulerEvent: action.payload,
      };
    case types.GET_LEADS_SCHEDULER_EVENT_FAILURE:
      return {
        ...state,
        fetchingLeadsSchedulerEvent: false,
        fetchingLeadsSchedulerEventError: true,
      };
    //CALL
    case types.ADD_LEADS_SCHEDULER_CALL_REQUEST:
      return { ...state, addingLeadsSchedulerCall: true };
    case types.ADD_LEADS_SCHEDULER_CALL_SUCCESS:
      return {
        ...state,
        addingLeadsSchedulerCall: false,
        addEventTaskModal: false,
      };
    case types.ADD_LEADS_SCHEDULER_CALL_FAILURE:
      return {
        ...state,
        addingLeadsSchedulerCall: false,
        addingLeadsSchedulerCallError: true,
      };
    //ACCOUNT CALL
    case types.ADD_LEADS_ACCOUNT_SCHEDULER_CALL_REQUEST:
      return { ...state, addingLeadsAccountSchedulerCall: true };
    case types.ADD_LEADS_ACCOUNT_SCHEDULER_CALL_SUCCESS:
      return {
        ...state,
        addingLeadsAccountSchedulerCall: false,
        addLeadsAccountSchedulerModal: false,
      };
    case types.ADD_LEADS_ACCOUNT_SCHEDULER_CALL_FAILURE:
      return {
        ...state,
        addingLeadsAccountSchedulerCall: false,
        addingLeadsAccountSchedulerCallError: true,
      };
    //ACCOUNT EVENT
    case types.ADD_LEADS_ACCOUNT_SCHEDULER_EVENT_REQUEST:
      return { ...state, addingLeadsAccountSchedulerEvent: true };
    case types.ADD_LEADS_ACCOUNT_SCHEDULER_EVENT_SUCCESS:
      return {
        ...state,
        addingLeadsAccountSchedulerEvent: false,
        addLeadsAccountSchedulerModal: false,
      };
    case types.ADD_LEADS_ACCOUNT_SCHEDULER_EVENT_FAILURE:
      return {
        ...state,
        addingLeadsAccountSchedulerEvent: false,
        addingLeadsAccountSchedulerEventError: true,
      };

    case types.GET_LEADS_SCHEDULER_CALL_REQUEST:
      return { ...state, fetchingLeadsSchedulerCall: true };
    case types.GET_LEADS_SCHEDULER_CALL_SUCCESS:
      return {
        ...state,
        fetchingLeadsSchedulerCall: false,
        leadsSchedulerCall: action.payload,
      };
    case types.GET_LEADS_SCHEDULER_CALL_FAILURE:
      return {
        ...state,
        fetchingLeadsSchedulerCall: false,
        fetchingLeadsSchedulerCallError: true,
      };

    case types.GET_NOTES_LIST_BY_LEADS_ID_REQUEST:
      return { ...state, fetchingNotesListByLeadsId: true };
    case types.GET_NOTES_LIST_BY_LEADS_ID_SUCCESS:
      return {
        ...state,
        fetchingNotesListByLeadsId: false,
        notesListByLeadsId: action.payload,
      };
    case types.GET_NOTES_LIST_BY_LEADS_ID_FAILURE:
      return {
        ...state,
        fetchingNotesListByLeadsId: false,
        fetchingNotesListByLeadsIdError: true,
      };

    case types.GET_CONTACT_NOTES_LIST_BY_LEADS_ID_REQUEST:
      return { ...state, fetchingContactNotesListByLeadsId: true };
    case types.GET_CONTACT_NOTES_LIST_BY_LEADS_ID_SUCCESS:
      return {
        ...state,
        fetchingContactNotesListByLeadsId: false,
        contactNotesListByLeadsId: action.payload,
      };
    case types.GET_CONTACT_NOTES_LIST_BY_LEADS_ID_FAILURE:
      return {
        ...state,
        fetchingContactNotesListByLeadsId: false,
        fetchingContactNotesListByLeadsIdError: true,
      };

    case types.GET_TIMELINE_LIST_BY_USER_ID_REQUEST:
      return { ...state, fetchingTimelineListByUserId: true };
    case types.GET_TIMELINE_LIST_BY_USER_ID_SUCCESS:
      return {
        ...state,
        fetchingTimelineListByUserId: false,
        timelineListByUserId: action.payload,
      };
    case types.GET_TIMELINE_LIST_BY_USER_ID_FAILURE:
      return {
        ...state,
        fetchingTimelineListByUserId: false,
        fetchingTimelineListByUserIdError: true,
      };

    case types.GET_ACCOUNT_TIMELINE_LIST_BY_USER_ID_REQUEST:
      return { ...state, fetchingAccountTimelineListByUserId: true };
    case types.GET_ACCOUNT_TIMELINE_LIST_BY_USER_ID_SUCCESS:
      return {
        ...state,
        fetchingAccountTimelineListByUserId: false,
        accountTimelineListByUserId: action.payload,
      };
    case types.GET_ACCOUNT_TIMELINE_LIST_BY_USER_ID_FAILURE:
      return {
        ...state,
        fetchingAccountTimelineListByUserId: false,
        fetchingAccountTimelineListByUserIdError: true,
      };

    case types.GET_LEADS_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST:
      return { ...state, fetchingLeadsAccountListRangeByUserIdForReport: true };
    case types.GET_LEADS_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS:
      return {
        ...state,
        fetchingLeadsAccountListRangeByUserIdForReport: false,
        leadsAccountListRangeByUserIdForReport: action.payload,
      };
    case types.GET_LEADS_ACCOUNT_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE:
      return {
        ...state,
        fetchingLeadsAccountListRangeByUserIdForReport: false,
        fetchingLeadsAccountListRangeByUserIdForReportError: true,
      };

    case types.GET_LEADS_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST:
      return { ...state, fetchingLeadsContactListRangeByUserIdForReport: true };
    case types.GET_LEADS_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS:
      return {
        ...state,
        fetchingLeadsContactListRangeByUserIdForReport: false,
        leadsContactListRangeByUserIdForReport: action.payload,
      };
    case types.GET_LEADS_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE:
      return {
        ...state,
        fetchingLeadsContactListRangeByUserIdForReport: false,
        fetchingLeadsContactListRangeByUserIdForReportError: true,
      };

    case types.ADD_LEADS_QUALIFIER_SALES_ACCOUNT_REQUEST:
      return { ...state, addingLeadsQualifier: true };
    case types.ADD_LEADS_QUALIFIER_SALES_ACCOUNT_SUCCESS:
      return {
        ...state,
        addingLeadsQualifier: false,
        addQualifiedModal: false,
      };
    case types.ADD_LEADS_QUALIFIER_SALES_ACCOUNT_FAILURE:
      return {
        ...state,
        addingLeadsQualifier: false,
        addingLeadsQualifierError: true,
      };

    case types.ADD_CONTACT_LEADS_QUALIFIER_SALES_ACCOUNT_REQUEST:
      return { ...state, addingContactLeadsQualifier: true };
    case types.ADD_CONTACT_LEADS_QUALIFIER_SALES_ACCOUNT_SUCCESS:
      return {
        ...state,
        addingContactLeadsQualifier: false,
        addLeadsContactQualifierModal: false,
      };
    case types.ADD_CONTACT_LEADS_QUALIFIER_SALES_ACCOUNT_FAILURE:
      return {
        ...state,
        addingContactLeadsQualifier: false,
        addingContactLeadsQualifierError: true,
      };

    case types.HANDLE_ADD_QUALIFIED_MODAL:
      return { ...state, addQualifiedModal: action.payload };

    case types.HANDLE_ADD_LEADS_QUALIFIER_MODAL:
      return { ...state, addLeadsContactQualifierModal: action.payload };

    case types.HANDLE_LEADS_OPPORTUNITY_MODAL:
      return { ...state, addLeadsOpportunityModal: action.payload };



    //SHARE Contact Customer Permissiom
    case types.ADD_SHARE_LEAD_PERMISSION_REQUEST:
      return { ...state, addSharingLead: true };

    case types.ADD_SHARE_LEAD_PERMISSION_SUCCESS:
      return { ...state, addSharingLead: false, leadByUserId: action.payload };

    case types.ADD_SHARE_LEAD_PERMISSION_FAILURE:
      return {
        ...state,
        addSharingLead: false,
        addSharingLeadError: true,
      };

    case types.GET_PERMISSIONS_LIST_LEAD_REQUEST:
      return { ...state, fetchingPermissionsListLead: true };
    case types.GET_PERMISSIONS_LIST_LEAD_SUCCESS:
      return {
        ...state,
        fetchingPermissionsListLead: false,
        permissionsDataListLead: action.payload,
      };
    case types.GET_PERMISSIONS_LIST_LEAD_FAILURE:
      return {
        ...state,
        fetchingPermissionsListLead: false,
        fetchingPermissionsListLeadError: false,
      };
    default:
      return state;
  }
};
