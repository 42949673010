import * as types from "./SettingsActionTypes";
import { base_url } from "../../Config/Auth";
import axios from "axios";
import { ActionIcon } from "../../Components/Utils";
import { UPDATE_RECRUITMENT_ADVANCE_SUCCESS } from "../Auth/AuthTypes";

export const handleOrgEmailModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_ORG_EMAIL_MODAL,
    payload: modalProps,
  });
};

export const handleOrgFacebookModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_ORG_FACEBOOK_MODAL,
    payload: modalProps,
  });
};
export const handleQuickbookModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_ORG_QUICKBOOK_MODAL,
    payload: modalProps,
  });
};
/**
 * goal modal action
 */

export const handleEditProcessModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_TASK_EDIT_PROCESS_MODAL,
    payload: modalProps,
  });
};

export const handleProcessModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_PROCESS_MODAL,
    payload: modalProps,
  });
};
export const handleRulesModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_RULES_MODAL,
    payload: modalProps,
  });
};
export const handleProcessTaskModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_PROCESS_TASK_MODAL,
    payload: modalProps,
  });
};

export const addProcess = (data) => (dispatch) => {
  dispatch({
    type: types.ADD_PROCESS_REQUEST,
  });

  axios
    .post(`${base_url}/process`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(getProcess());
      dispatch({
        type: types.ADD_PROCESS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.ADD_PROCESS_FAILURE,
        payload: err,
      });
    });
};
// get default process
export const getDefaultProcess = () => (dispatch) => {
  dispatch({
    type: types.GET_DEFAULT_PROCESS_REQUEST,
  });
  axios
    .get(`${base_url}/defaultprocess`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_DEFAULT_PROCESS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_DEFAULT_PROCESS_FAILURE,
        payload: err,
      });
    });
};

export const getProcess = () => (dispatch) => {
  dispatch({
    type: types.GET_PROCESS_REQUEST,
  });
  axios
    .get(`${base_url}/processes`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PROCESS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_PROCESS_FAILURE,
        payload: err,
      });
    });
};

export const updateStage = (stageId, stageName, probability, days, cb) => (
  dispatch
) => {
  console.log(stageId, stageName, probability);
  dispatch({
    type: types.UPDATE_STAGE_REQUEST,
  });
  axios
    .put(
      `${base_url}/stage/${stageId}`,
      { stageName, probability, days },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_STAGE_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_STAGE_FAILURE,
      });
      cb && cb("error");
    });
};

export const getAllProcessStages = () => (dispatch) => {
  dispatch({
    type: types.GET_ALL_PROCESS_STAGES_REQUEST,
  });
  axios
    .get(`${base_url}/allProcessStages`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ALL_PROCESS_STAGES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ALL_PROCESS_STAGES_FAILURE,
        payload: err,
      });
    });
};

export const getOppoStages = () => (dispatch) => {
  dispatch({
    type: types.GET_OPPO_STAGES_REQUEST,
  });
  axios
    .get(`${base_url}/oppoStages`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_OPPO_STAGES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_OPPO_STAGES_FAILURE,
        payload: err,
      });
    });
};
export const getProcessStages = (processId) => (dispatch) => {
  dispatch({
    type: types.GET_PROCESS_STAGES_REQUEST,
  });
  axios
    .get(`${base_url}/process/${processId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PROCESS_STAGES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_PROCESS_STAGES_FAILURE,
        payload: err,
      });
    });
};
export const removeStage = (stageId, cb) => (dispatch) => {
  console.log(stageId);
  dispatch({
    type: types.REMOVE_STAGE_REQUEST,
  });
  axios
    .delete(`${base_url}/stage/${stageId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.REMOVE_STAGE_SUCCESS,
        payload: stageId,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.REMOVE_STAGE_FAILURE,
      });
    });
};

export const addProcessStage = (stage, cb) => (dispatch) => {
  dispatch({ type: types.ADD_PROCESS_STAGE_REQUEST });

  axios
    .post(`${base_url}/process/stage`, stage, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.ADD_PROCESS_STAGE_SUCCESS,
        payload: { ...stage, stageId: res.data },
      });
      cb && cb("Success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_PROCESS_STAGE_FAILURE,
      });
      cb && cb("Failure");
    });
};

export const updateProcessName = (process, cb) => (dispatch) => {
  dispatch({ type: types.UPDATE_PROCESS_NAME_REQUEST });

  axios
    .put(`${base_url}/updateProcess`, process, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_PROCESS_NAME_SUCCESS,
        payload: res.data,
      });
      cb && cb("Success", res.data);
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_PROCESS_NAME_FAILURE,
      });
      cb && cb("Failure");
    });
};

export const addProcessTask = (data, cb) => (dispatch) => {
  dispatch({
    type: types.ADD_PROCESS_TASK_REQUEST,
  });

  axios
    .post(`${base_url}/stage/task`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.ADD_PROCESS_TASK_SUCCESS,
        payload: res.data,
      });
      cb && cb("Success");
    })
    .catch((err) => {
      dispatch({
        type: types.ADD_PROCESS_TASK_FAILURE,
        payload: err,
      });
      cb && cb("Failure");
    });
};

export const getProcessTask = (stageId) => (dispatch) => {
  dispatch({
    type: types.GET_PROCESS_TASK_REQUEST,
  });
  axios
    .get(`${base_url}/stage/task/${stageId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PROCESS_TASK_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_PROCESS_TASK_FAILURE,
        payload: err,
      });
    });
};

/**
 * get department
 */
export const getDepartments = () => (dispatch) => {
  dispatch({
    type: types.GET_DEPARTMENTS_REQUEST,
  });
  axios
    .get(`${base_url}/departments`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_DEPARTMENTS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_DEPARTMENTS_FAILURE,
        payload: err,
      });
    });
};

/**
 * get levels
 */
export const getLevels = () => (dispatch) => {
  //debugger;
  dispatch({
    type: types.GET_LEVELS_REQUEST,
  });
  axios
    .get(`${base_url}/level`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_LEVELS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_LEVELS_FAILURE,
        payload: err,
      });
    });
};
export const updateTask = (id, data, cb) => (dispatch) => {
  console.log(data);
  dispatch({ type: types.UPDATE_TASK_BY_ID_REQUEST });
  axios
    .put(
      `${base_url}/task/${id}`,
      { ...data },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_TASK_BY_ID_SUCCESS,
        payload: res.data,
      });
      cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_TASK_BY_ID_FAILURE,
        payload: err,
      });
    });
};
export const deleteTask = (taskId) => (dispatch, getState) => {
  console.log("inside deleteTask", taskId);
  dispatch({
    type: types.DELETE_TASK_REQUEST,
  });

  axios
    .delete(`${base_url}/globalTask/${taskId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.DELETE_TASK_SUCCESS,
        payload: taskId,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.DELETE_TASK_FAILURE,
        payload: err,
      });
    });
};
export const updateTaskResuffel = (task) => (dispatch) => {
  console.log(task);

  const final = task.map((task, i) => {
    return { ...task, sequence: (i += 1) };
  });
  console.log(final);

  // const object1 = task[0];
  // const object2 = task[1];
  // const finalData = [
  //   { ...object1, sequence: object2.sequence },
  //   { ...object2, sequence: object1.sequence },
  // ];

  dispatch({
    type: types.UPDATE_TASK_RESUFFEL_BY_ID_REQUEST,
  });
  axios
    .post(`${base_url}/tasksuffle`, final, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_TASK_RESUFFEL_BY_ID_SUCCESS,
        payload: final,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_TASK_RESUFFEL_BY_ID_FAILURE,
        payload: err,
      });
    });
};

export const updateTaskData = (taskId, data, cb) => (dispatch) => {
  ////debugger
  console.log("inside updateTaskData");
  dispatch({
    type: types.UPDATE_PROCESS_TASK_REQUEST,
  });

  axios
    .put(`${base_url}/update/globalTask/${taskId}`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_PROCESS_TASK_SUCCESS,
        payload: taskId,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.DELETE_TASK_FAILURE,
        payload: err,
      });
      cb && cb("failure");
    });
};
export const handleTaskModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_TASK_MODAL,
    payload: modalProps,
  });
};
export const setEditTask = (name) => (dispatch) => {
  dispatch({
    type: types.SET_TASK_EDIT,
    payload: name,
  });
};

//recruiter
export const addProcessForRecruiter = (data, organizationId, cb) => (
  dispatch
) => {
  dispatch({
    type: types.ADD_PROCESS_FOR_RECRUIT_REQUEST,
  });

  axios
    .post(`${base_url}/recruitment`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(getProcessForRecruit(organizationId));
      dispatch({
        type: types.ADD_PROCESS_FOR_RECRUIT_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      dispatch({
        type: types.ADD_PROCESS_FOR_RECRUIT_FAILURE,
        payload: err,
      });
      cb && cb("failure");
    });
};

export const getProcessForRecruit = (organizationId) => (dispatch) => {
  //debugger;
  dispatch({
    type: types.GET_PROCESS_FOR_RECRUIT_REQUEST,
  });
  axios
    .get(`${base_url}/recruitment/${organizationId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log("print when new process added................", res);
      dispatch({
        type: types.GET_PROCESS_FOR_RECRUIT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_PROCESS_FOR_RECRUIT_FAILURE,
        payload: err,
      });
    });
};
export const dataClear = () => (dispatch) => {
  dispatch({ type: types.DATA_CLEAR });
};
export const getProcessStagesForRecruit = (recruitmentProcessId) => (
  dispatch
) => {
  dispatch({
    type: types.GET_PROCESS_STAGES_FOR_RECRUIT_REQUEST,
  });
  axios
    .get(`${base_url}/recruitmentdetails/${recruitmentProcessId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PROCESS_STAGES_FOR_RECRUIT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_PROCESS_STAGES_FOR_RECRUIT_FAILURE,
        payload: err,
      });
    });
};

export const addProcessStageForRecruit = (stage, cb) => (dispatch) => {
  dispatch({ type: types.ADD_PROCESS_STAGE_FOR_RECRUIT_REQUEST });

  axios
    .post(`${base_url}/recruitment/stage`, stage, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.ADD_PROCESS_STAGE_FOR_RECRUIT_SUCCESS,
        payload: { ...stage, stageId: res.data },
      });
      cb && cb("Success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_PROCESS_STAGE_FOR_RECRUIT_FAILURE,
      });
      cb && cb("Failure");
    });
};

export const updateProcessNameForRecruit = (process, cb) => (dispatch) => {
  //debugger;
  dispatch({ type: types.UPDATE_PROCESS_NAME_FOR_RECRUIT_REQUEST });

  axios
    .put(`${base_url}/editRecruitmentProcess`, process, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_PROCESS_NAME_FOR_RECRUIT_SUCCESS,
        payload: res.data,
      });
      cb && cb("Success", res.data);
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_PROCESS_NAME_FOR_RECRUIT_FAILURE,
      });
      cb && cb("Failure");
    });
};

export const updateStageForRecruit = (
  stageId,
  stageName,
  probability,
  days,
  cb
) => (dispatch) => {
  console.log(stageName, probability);
  dispatch({
    type: types.UPDATE_STAGE_FOR_RECRUIT_REQUEST,
  });
  axios
    .put(
      `${base_url}/edit/recriutmentStage`,
      { stageId, stageName, probability, days },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_STAGE_FOR_RECRUIT_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_STAGE_FOR_RECRUIT_FAILURE,
      });
      cb && cb("error");
    });
};

export const getAllProcessStagesForRecruit = () => (dispatch) => {
  dispatch({
    type: types.GET_ALL_PROCESS_STAGES_FOR_RECRUIT_REQUEST,
  });
  axios
    .get(`${base_url}/recruitment/processStages`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ALL_PROCESS_STAGES_FOR_RECRUIT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ALL_PROCESS_STAGES_FOR_RECRUIT_FAILURE,
        payload: err,
      });
    });
};

export const addOrganizationLeadsUser = (data) => (dispatch) => {
  dispatch({ type: types.ADD_ORGANIZATION_LEADS_USER_REQUEST });

  axios
    .put(`${base_url}/organization-leads-user`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);

      dispatch({
        type: types.ADD_ORGANIZATION_LEADS_USER_SUCCESS,
        payload: res.data,
      });
      // cb && cb("Success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_ORGANIZATION_LEADS_USER_FAILURE,
      });
      // cb && cb("Failure");
    });
};

export const getOrganizationLeadsUser = () => (dispatch) => {
  dispatch({ type: types.GET_ORGANIZATION_LEADS_USER_REQUEST });

  axios
    .get(`${base_url}/organization-leads-user?pageName=Contact Us`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ORGANIZATION_LEADS_USER_SUCCESS,
        payload: res.data,
      });
      // cb && cb("Success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ORGANIZATION_LEADS_USER_FAILURE,
      });
      // cb && cb("Failure");
    });
};

export const getSignatureInd = () => (dispatch) => {
  dispatch({ type: types.GET_SIGNATURE_REQUEST });

  axios
    .get(`${base_url}/check/signature`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_SIGNATURE_SUCCESS,
        payload: res.data,
      });
      // cb && cb("Success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_SIGNATURE_FAILURE,
      });
      // cb && cb("Failure");
    });
};

export const enableRecruitmentAdvance = (organizationId) => (dispatch) => {
  console.log("print organization Id ...........", organizationId);
  dispatch({
    type: types.ENABLE_RECRUITMENT_ADVANCE_REQUEST,
  });
  axios
    .post(
      `${base_url}/advance/recruitment/enable/${organizationId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log("inside then");
      dispatch({
        type: types.ENABLE_RECRUITMENT_ADVANCE_SUCCESS,
        payload: { organizationId },
      });
      dispatch({
        type: UPDATE_RECRUITMENT_ADVANCE_SUCCESS,
        payload: res.data.advanceRecruitmentInd,
      });
    })
    .catch((err) => {
      console.log("inside catch");
      console.log(err);
      dispatch({
        type: types.ENABLE_RECRUITMENT_ADVANCE_FAILURE,
        payload: err,
      });
    });
};

//Apparel
export const addApparel = (data) => (dispatch) => {
  dispatch({
    type: types.ADD_APPAREL_REQUEST,
  });

  axios
    .post(`${base_url}/organization/apparel-category`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(getApparel());
      dispatch({
        type: types.ADD_APPAREL_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.ADD_APPAREL_FAILURE,
        payload: err,
      });
    });
};

export const getApparel = () => (dispatch) => {
  dispatch({
    type: types.GET_APPAREL_REQUEST,
  });

  axios
    .get(`${base_url}/organization/apparel-category`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_APPAREL_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_APPAREL_FAILURE,
        payload: err,
      });
    });
};

export const updateApparel = (data, cb) => (dispatch) => {
  dispatch({
    type: types.UPDATE_APPAREL_REQUEST,
  });
  axios
    .put(`${base_url}/organization/apparel-category`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_APPAREL_SUCCESS,
        payload: res.data,
      });
      cb && cb("success", res.data);
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_APPAREL_FAILURE,
      });
      cb && cb("error");
    });
};

export const addApparelById = (data, apparelId) => (dispatch) => {
  dispatch({
    type: types.ADD_APPAREL_DATA_BY_APPAREL_ID_REQUEST,
  });

  axios
    .post(`${base_url}/organization/apparel-subcategory`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(getApparelById(apparelId));
      dispatch({
        type: types.ADD_APPAREL_DATA_BY_APPAREL_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.ADD_APPAREL_DATA_BY_APPAREL_ID_FAILURE,
        payload: err,
      });
    });
};

export const getApparelById = (apparelId) => (dispatch) => {
  dispatch({
    type: types.GET_APPAREL_DATA_BY_APPAREL_ID_REQUEST,
  });

  axios
    .get(`${base_url}/organization/apparel-subcategory/${apparelId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);

      dispatch({
        type: types.GET_APPAREL_DATA_BY_APPAREL_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_APPAREL_DATA_BY_APPAREL_ID_FAILURE,
        payload: err,
      });
    });
};

export const updateApparelById = (subcategoryId, subcategoryName, cb) => (
  dispatch
) => {
  dispatch({
    type: types.UPDATE_APPAREL_BY_APPAREL_ID_REQUEST,
  });
  axios
    .put(
      `${base_url}/organization/apparel-subcategory`,
      { subcategoryId, subcategoryName },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_APPAREL_BY_APPAREL_ID_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_APPAREL_BY_APPAREL_ID_FAILURE,
      });
      cb && cb("error");
    });
};

export const handleApparelModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_APPAREL_MODAL,
    payload: modalProps,
  });
};

export const getAllSubCategory = () => (dispatch) => {
  dispatch({
    type: types.GET_ALL_SUBCATEGORY_REQUEST,
  });

  axios
    .get(`${base_url}/organization/apparel-subcategory`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ALL_SUBCATEGORY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ALL_SUBCATEGORY_FAILURE,
        payload: err,
      });
    });
};


