import React, { Component } from "react";
import { Avatar, Divider, Button, Menu, Dropdown, Tooltip } from "antd";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { FlexContainer, MainWrapper, ResponsiveCard } from "../Layout";
import { CurrencySymbol } from "../../Common";
import { StyledPopconfirm } from "../../../Components/UI/Antd";
import { ActionIcon } from "../../Utils";
import { MultiAvatar } from "./";
import { Title, SubTitle } from "./";
import { SubnodeOutlined, UsergroupAddOutlined, EnvironmentOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

const BussinessCard = (props) => {
  const {
    handleClick,
    handleSecondaryTitleClick,
    handlePreview,
    handlePreviewAdmin,
    imageId,
    imageURL,
    primaryTitle,
    secondaryTitle,
    secondaryImageId,
    secondaryImageURL,
    userType,
    subtitle1,
    subtitle2,
    hideSecondaryAvatar,
    subtitle1Icon,
    mainAvatarTitle,
    bottomBarComponent,
    department,
    currencyType,
    dropdownMenu,
    inventoryInd,
    handleOpportunityModal,
    handleContactModal,
    AccountIcon,
    handleUserModal,
    handleCardLocationIconModal,
  } = props;
  console.log("AccountIcon", AccountIcon);
  return (
    <ResponsiveCard flexDirection="column" style={{ borderRadius: 1 }}>
      <MainWrapper>
        <FlexContainer
          alignItems="center"
          flexWrap="no-wrap"
          style={{ height: "2.81em", marginBottom: "0.25em" }}
        >
          <FlexContainer style={{ flexBasis: "25%", marginRight: "0.2rem" }}>
            <MultiAvatar
              primaryTitle={primaryTitle}
              imageId={imageId}
              imageURL={imageURL}
              imgHeight={40}
              imgWidth={40}
            />
          </FlexContainer>
          &nbsp;
          <FlexContainer
            flexDirection="column"
            style={{ flexBasis: "65%", overflow: "hidden" }}
          >
            <Title
              fontSize="1em"
              overflow="hidden"
              textOverflow="ellipsis"
              style={{ color: "#337df4", cursor: "pointer", fontSize: "1em" }}
              onClick={handleClick || null}
            >
              {primaryTitle || ""}
            </Title>

            {secondaryTitle && (
              <SubTitle
                overflow="hidden"
                textOverflow="ellipsis"
                style={{
                  cursor: "pointer",
                  fontSize: "0.99em",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={handleSecondaryTitleClick || null}
              >
                {/* <Icon type="mail" theme="filled" />  */}
                {!hideSecondaryAvatar && (
                  <MultiAvatar
                    primaryTitle={secondaryTitle || "F"}
                    imageId={secondaryImageId}
                    imageURL={secondaryImageURL}
                    imgHeight={25}
                    imgWidth={25}
                    smallAvatar
                    minAvatarWidth="1.56em"
                  />
                )}
                &nbsp;
                {secondaryTitle || ""}
              </SubTitle>
            )}
          </FlexContainer>
          <FlexContainer style={{ flexBasis: "10%", alignSelf: "flex-start" }}>
            {dropdownMenu || <></>}
          </FlexContainer>
        </FlexContainer>
        <FlexContainer flexDirection="column" style={{ padding: "0.2rem" }}>
          <SubTitle
            style={{
              fontWeight: 500,
              fontSize: "0.9375em",
              marginTop: "-0.37em",
              marginBottom: "-0.18em",
            }}
          >
            {/* <SubnodeOutlined
              // type={subtitle1Icon || "filter"}
              theme="filled"
              style={{
                color: subtitle1Icon === "crown" ? "rgb(148 11 157)" : null,
              }}
            /> */}
            &nbsp;&nbsp;
            {currencyType && <CurrencySymbol currencyType={currencyType} />}
            &nbsp;{subtitle1 || ""}
            {/* <PulseIcon></PulseIcon> */}
          </SubTitle>
          {/* <SubTitle style={{ fontWeight: 500, fontSize: 15 }}>
                        <Icon type="phone" theme="filled" /> &nbsp;
                        {subtitle2 || ''}
                    </SubTitle> */}
        </FlexContainer>
        <FlexContainer style={{ width: "100%", paddingLeft: "0.5rem" }}>
          {department === "Sales" && userType === "ADMIN" ? (
            <FlexContainer
              style={{}}
              justifyContent={bottomBarComponent ? "space-between" : "flex-end"}
              alignSelf="flex-end"
              alignItems="center"
            >
              {bottomBarComponent && bottomBarComponent}
              <Tooltip placement="right" title="Control Tower">
                <Button
                  size={"small"}
                  type="ghost"
                  style={{
                    color: "#888",
                    borderColor: "transparent",
                    alignSelf: "flex-end",
                  }}
                  onClick={handlePreviewAdmin}
                >
                  <PulseIcon1
                  // onMouseOver={({ target }) => (target.style.color = "white")}
                  ></PulseIcon1>
                </Button>
              </Tooltip>
              <div
                style={{
                  borderLeft: "0.06em solid rgb(239 229 229)",
                  height: "1.25em",
                }}
              ></div>

              <div onClick={handleUserModal}>
                <Tooltip placement="right" title="Users">
                  <UsergroupAddOutlined
                    style={{
                      fontSize: "113%",
                      marginTop: "-6%",
                      color: "rgb(188 182 182)",
                      cursor: "pointer",
                    }}
                    // type="usergroup-add"
                    key="user"
                  />
                  <PlusOutlined
                    style={{ fontSize: "0.56em", cursor: "pointer" }}
                  // type="plus"
                  />
                </Tooltip>
              </div>
              {inventoryInd ? (
                <div onClick={handleCardLocationIconModal}>
                  <EnvironmentOutlined
                    style={{
                      fontSize: "113%",
                      marginTop: "-6%",
                      color: "rgb(188 182 182)",
                      cursor: "pointer",
                    }}
                  // type="environment"
                  />
                </div>
              ) : null}
            </FlexContainer>
          ) : (
            <FlexContainer
              style={{ display: "flex", justifyContent: "space-evenly" }}
              justifyContent={bottomBarComponent ? "space-between" : "flex-end"}
              alignSelf="flex-end"
              alignItems="center"
            >
              {bottomBarComponent && bottomBarComponent}
              {/* <Tooltip placement="right" title="Pulse"> */}
              <Tooltip placement="right" title={<FormattedMessage
                id="app.pulse"
                defaultMessage="Pulse"
              />}>
                <div>
                  <Button
                    className="hover_button"
                    size={"small"}
                    type="ghost"
                    style={{
                      color: "#888",
                      borderColor: "transparent",
                      alignSelf: "flex-end",
                    }}
                    onClick={handlePreview}
                  >
                    <PulseIcon></PulseIcon>
                  </Button>
                </div>
              </Tooltip>
              {inventoryInd ? (
                <div onClick={handleCardLocationIconModal}>
                  <EnvironmentOutlined
                    style={{
                      fontSize: "113%",
                      marginTop: "-6%",
                      color: "rgb(188 182 182)",
                      cursor: "pointer",
                    }}
                  // type="environment"
                  />
                </div>

              ) : null}
              {inventoryInd ? (
                <div>
                  <StyledPopconfirm
                    // title="Do you wish to suspend ?"
                    title={
                      <FormattedMessage
                        id="app.doyouwishtosuspend?"
                        defaultMessage="Do you wish to suspend ?"
                      />
                    }
                  // onConfirm={() => props.DeleteSuspendAccount(props.account.accountId)}
                  >
                    <DeleteOutlined
                      style={{
                        fontSize: "113%",
                        marginTop: "-6%",
                        color: "rgb(188 182 182)",
                        cursor: "pointer",
                      }}
                    // type="environment"
                    />
                  </StyledPopconfirm>
                </div>

              ) : null}
              <div
                style={{
                  borderLeft: "0.06em solid rgb(239 229 229)",
                  height: "1.25em",
                }}
              ></div>

              {props.productCard ? null : (
                <>
                  <div onClick={handleOpportunityModal}>
                    {/* <Tooltip placement="right" title="Opportunity"> */}
                    <Tooltip placement="right" title={<FormattedMessage
                      id="app.opportunities"
                      defaultMessage="Opportunity"
                    />}>
                      <i class="far fa-lightbulb"></i>
                      <PlusOutlined
                        style={{ fontSize: "0.56em", cursor: "pointer" }}
                      // type="plus"
                      />
                    </Tooltip>
                  </div>
                  {/* )} */}

                  <div
                    style={{
                      borderLeft: "0.06em solid rgb(239 229 229)",
                      height: "1.25em",
                    }}
                  ></div>
                  <div onClick={handleContactModal}>
                    {/* <Tooltip placement="right" title="Contact"> */}
                    <Tooltip placement="right" title={<FormattedMessage
                      id="app.contacts"
                      defaultMessage="Contacts"
                    />}>
                      <i class="far fa-address-book"></i>
                      <PlusOutlined
                        style={{ fontSize: "0.56em", cursor: "pointer" }}
                      // type="plus"
                      />
                    </Tooltip>
                  </div>
                </>
              )}
            </FlexContainer>
          )}
        </FlexContainer>
      </MainWrapper>
    </ResponsiveCard>
  );
};
export default BussinessCard;

const AppIcon = (props) => (
  <i
    className={`fas fa-heartbeat ${props.className}`}
    style={{ fontSize: "123%" }}
  ></i>
);

const AppIcon1 = (props) => (
  <i
    className={`fas fa-broadcast-tower ${props.className}`}
    style={{ fontSize: "123%" }}
  ></i>
);

const PulseIcon = styled(AppIcon)`
  color: #df9697;
  :hover {
    color: blue;
  }
`;
const PulseIcon1 = styled(AppIcon1)`
  color: green;
  &:hover {
    background: yellow;
    color: blue;
  }
`;
