
export const GET_RELEASENOTE_REQUEST = "GET_RELEASENOTE_REQUEST";
export const GET_RELEASENOTE_SUCCESS = "GET_RELEASENOTE_SUCCESS";
export const GET_RELEASENOTE_FAILURE = "GET_RELEASENOTE_FAILURE";

export const ADD_RELEASENOTE_REQUEST = "ADD_RELEASENOTE_REQUEST";
export const ADD_RELEASENOTE_SUCCESS = "ADD_RELEASENOTE_SUCCESS";
export const ADD_RELEASENOTE_FAILURE = "ADD_RELEASENOTE_FAILURE";

export const GET_NEW_RELEASENOTE_REQUEST = "GET_NEW_RELEASENOTE_REQUEST";
export const GET_NEW_RELEASENOTE_SUCCESS = "GET_NEW_RELEASENOTE_SUCCESS";
export const GET_NEW_RELEASENOTE_FAILURE = "GET_NEW_RELEASENOTE_FAILURE";