import React, { Component } from "react";
import AsyncSelect from "react-select/lib/Async";
import axios from "axios";
import { get } from "lodash";
import { base_url, login_url } from "../../../Config/Auth";
import { ValidationError, StyledLabel, StyledAsync } from "../../UI/Elements";
import { FlexContainer } from "../../UI/Layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { message } from "antd";
import { setClearbitProductData } from "../../../Containers/Account/AccountAction";

class ProductClearbit extends Component {
  loadOptions = (value) => {
    if (!value) {
      return Promise.resolve([]);
    }
    const url = `${base_url}/product/${value}`;
    return axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      })
      .then((res) => {
        return res.data.map((opt) => ({
          label: opt.name,
          value: opt.name,
          productId: opt.productId,
          productName: opt.productName,
          categoryName: opt.categoryName,
          imageId: opt.imageId,
          subCategoryName: opt.subCategoryName,
          attributeName: opt.attributeName,
          subAttributeName: opt.subAttributeName,
          maxDiscount: opt.maxDiscount,
          baseCost: opt.baseCost,
          currency: opt.currency,
          stockUnit: opt.stockUnit,
        }));
      })
      .catch((err) => console.log(err));
  };
  // renderOptions = ({ data }) => (<div><p>----{data.website}</p></div>);
  handleInputChange = (e) => e;

  handleOnChange = (option) => {
    let productExist = false;

    const { product } = this.props;

    if (product) {
      if (!product) return;

      product.forEach((product) => {
        //debugger;
        if (product.productName === option.value) {
          //debugger;
          productExist = true;
        }
      });
      this.handleBlur();
    }
    if (product) {
      if (productExist) {
        message.error("Product already exists");
        this.props.form.setFieldValue("label", "");
        this.props.form.setFieldValue("categoryName", "");
        this.props.form.setFieldValue("subCategoryName", "");
        this.props.form.setFieldValue("attributeName", "");
        this.props.form.setFieldValue("subAttributeName", "");
        this.props.form.setFieldValue("maxDiscount", "");
        this.props.form.setFieldValue("baseCost", "");
        this.props.form.setFieldValue("productCurrency", "");
        this.props.form.setFieldValue("stockUnit", "");
        this.props.setClearbitProductData(null);
      } else {
        this.props.form.setFieldValue("label", option.value);
        this.props.form.setFieldValue("categoryName", option.categoryName);
        this.props.form.setFieldValue(
          "subCategoryName",
          option.subCategoryName
        );
        this.props.form.setFieldValue("attributeName", option.attributeName);
        this.props.form.setFieldValue(
          "subAttributeName",
          option.subAttributeName
        );
        this.props.form.setFieldValue("maxDiscount", option.maxDiscount);
        this.props.form.setFieldValue("baseCost", option.baseCost);
        this.props.form.setFieldValue("productCurrency", option.currency);
        this.props.form.setFieldValue("stockUnit", option.stockUnit);
        this.props.form.setFieldValue("productName", option.productName);

        this.props.setClearbitProductData(option);
      }
    }
  };

  handleBlur = (option) => {
    this.props.form.setFieldValue("label", true);
    // this.props.form.setFieldValue("category", true);
    // this.props.form.setFieldValue("subcategory", true);
    // this.props.form.setFieldValue("attributeName", true);
    // this.props.form.setFieldValue("subAttributeName", true);
    // this.props.form.setFieldValue("maxDiscount", true);
    // this.props.form.setFieldValue("baseCost", true);
    // this.props.form.setFieldValue("productCurrency", true);
    // this.props.form.setFieldValue("stockUnit", true);
    // this.props.form.setFieldValue("productName", true);
  };

  render() {
    const {
      label,
      placeholder,
      isRequired,
      inlineLabel,
      isColumn,
      isColumnwithnoCreate,
      form: { touched, errors, setFieldValue, setFieldTouched },
      field,
      ...rest
    } = this.props;
    if (isColumnwithnoCreate) {
      return (
        <>
          <StyledLabel style={{ flexBasis: "20%" }}>{label}</StyledLabel>
          <StyledAsync
            formatCreateLabel={() => undefined}
            isRequired={isRequired}
            classNamePrefix="sales"
            placeholder={placeholder}
            cacheOptions
            loadOptions={this.loadOptions}
            defaultOptions
            onInputChange={this.handleInputChange}
            onBlur={this.handleBlur}
            onChange={(option) => this.handleOnChange(option)}
            styles={{ width: 600 }}

            // components={this.renderOptions}
          />

          {get(touched, field.name) && get(errors, field.name) && (
            <ValidationError>{get(errors, field.name)}</ValidationError>
          )}
        </>
      );
    }
    if (isColumn) {
      return (
        <>
          <StyledLabel style={{ flexBasis: "20%" }}>{label}</StyledLabel>
          <StyledAsync
            isRequired={isRequired}
            classNamePrefix="sales"
            placeholder={placeholder}
            cacheOptions
            loadOptions={this.loadOptions}
            defaultOptions
            onInputChange={this.handleInputChange}
            onBlur={this.handleBlur}
            onChange={(option) => this.handleOnChange(option)}
            styles={{ width: 600 }}

            // components={this.renderOptions}
          />

          {get(touched, field.name) && get(errors, field.name) && (
            <ValidationError>{get(errors, field.name)}</ValidationError>
          )}
        </>
      );
    }
    return (
      <>
        <FlexContainer>
          <FlexContainer alignItems="center" flexWrap={inlineLabel && "nowrap"}>
            <StyledLabel style={{ flexBasis: "20%" }}>{label}</StyledLabel>
            <StyledAsync
              isRequired={isRequired}
              classNamePrefix="sales"
              placeholder={placeholder}
              cacheOptions
              loadOptions={this.loadOptions}
              defaultOptions
              onInputChange={this.handleInputChange}
              onBlur={this.handleBlur}
              onChange={(option) => this.handleOnChange(option)}
              styles={{ width: 600 }}

              // components={this.renderOptions}
            />
          </FlexContainer>
        </FlexContainer>
        {get(touched, field.name) && get(errors, field.name) && (
          <ValidationError>{get(errors, field.name)}</ValidationError>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth, product, opportunity }) => ({
  user: auth.userDetails,
  opportunity: opportunity.opportunity,
  productsByOpportunityId: opportunity.productsByOpportunityId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setClearbitProductData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductClearbit);
