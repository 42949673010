export const ADD_QUICKBOOK_REQUEST = "ADD_QUICKBOOK_REQUEST";
export const ADD_QUICKBOOK_SUCCESS = "ADD_QUICKBOOK_SUCCESS";
export const ADD_QUICKBOOK_FAILURE = "ADD_QUICKBOOK_FAILURE";

export const POST_FACEBOOK_REQUEST = "POST_FACEBOOK_REQUEST";
export const POST_FACEBOOK_SUCCESS = "POST_FACEBOOK_SUCCESS";
export const POST_FACEBOOK_FAILURE = "POST_FACEBOOK_FAILURE";

export const GET_FACEBOOK_WEBHOOKS_REQUEST = "GET_FACEBOOK_WEBHOOKS_REQUEST";
export const GET_FACEBOOK_WEBHOOKS_SUCCESS = "GET_FACEBOOK_WEBHOOKS_SUCCESS";
export const GET_FACEBOOK_WEBHOOKS_FAILURE = "GET_FACEBOOK_WEBHOOKS_FAILURE";

export const GET_QUICKBOOK_PROCESS_REQUEST = "GET_QUICKBOOK_PROCESS_REQUEST";
export const GET_QUICKBOOK_PROCESS_SUCCESS = "GET_QUICKBOOK_PROCESS_SUCCESS";
export const GET_QUICKBOOK_PROCESS_FAILURE = "GET_QUICKBOOK_PROCESS_FAILURE";

export const FACEBOOK_LOGIN_REQUEST = "FACEBOOK_LOGIN_REQUEST";
export const FACEBOOK_LOGIN_SUCCESS = "FACEBOOK_LOGIN_SUCCESS";
export const FACEBOOK_LOGIN_FAILURE = "FACEBOOK_LOGIN_FAILURE";