export const CHANGE_SELECTED_TIME_INTERVAL_TEAM =
  "CHANGE_SELECTED_TIME_INTERVAL_TEAM";
export const SET_TIME_INTERVAL_TEAM = "SET_TIME_INTERVAL_TEAM";
export const SET_FISCAL_TIME_INTERVAL_TEAM = "SET_FISCAL_TIME_INTERVAL_TEAM";
export const SET_SELECTED_TEAM_TYPE = "SET_SELECTED_TEAM_TYPE";

export const ADD_TEAM_REQUEST = "ADD_TEAM_REQUEST";
export const ADD_TEAM_SUCCESS = "ADD_TEAM_SUCCESS";
export const ADD_TEAM_FAILURE = "ADD_TEAM_FAILURE";

export const DELETE_TEAM_MEMBER_REQUEST = "DELETE_TEAM_MEMBER_REQUEST";
export const DELETE_TEAM_MEMBER_SUCCESS = "DELETE_TEAM_MEMBER_SUCCESS";
export const DELETE_TEAM_MEMBER_FAILURE = "DELETE_TEAM_MEMBER_FAILURE";

export const GET_TEAMS_REQUEST = "GET_TEAMS_REQUEST";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
export const GET_TEAMS_FAILURE = "GET_TEAMS_FAILURE";

export const GET_TEAM_BY_ID_REQUEST = "GET_TEAM_BY_ID_REQUEST";
export const GET_TEAM_BY_ID_SUCCESS = "GET_TEAM_BY_ID_SUCCESS";
export const GET_TEAM_BY_ID_FAILURE = "GET_TEAMS_FAILURE";

export const UPDATE_TEAM_BY_ID_REQUEST = "UPDATE_TEAM_BY_ID_REQUEST";
export const UPDATE_TEAM_BY_ID_SUCCESS = "UPDATE_TEAM_BY_ID_SUCCESS";
export const UPDATE_TEAM_BY_ID_FAILURE = "UPDATE_TEAMS_FAILURE";

export const GET_BEST_TEAMS_REQUEST = "GET_BEST_TEAMS_REQUEST";
export const GET_BEST_TEAMS_SUCCESS = "GET_BEST_TEAMS_SUCCESS";
export const GET_BEST_TEAMS_FAILURE = "GET_BEST_TEAMS_FAILURE";

export const GET_YOUR_TEAMS_REQUEST = "GET_YOUR_TEAMS_REQUEST";
export const GET_YOUR_TEAMS_SUCCESS = "GET_YOUR_TEAMS_SUCCESS";
export const GET_YOUR_TEAMS_FAILURE = "GET_YOUR_TEAMS_FAILURE";

export const GET_MOST_PROFESSIONALMISING_TEAMS_REQUEST =
  "GET_MOST_PROFESSIONALMISING_TEAMS_REQUEST";
export const GET_MOST_PROFESSIONALMISING_TEAMS_SUCCESS =
  "GET_MOST_PROFESSIONALMISING_TEAMS_SUCCESS";
export const GET_MOST_PROFESSIONALMISING_TEAMS_FAILURE =
  "GET_MOST_PROFESSIONALMISING_TEAMS_FAILURE";
/**
 * jumpstart and chart request for all teams
 */
export const GET_CHART_DATA_FOR_ALL_TEAMS_REQUEST =
  "GET_CHART_DATA_FOR_ALL_TEAMS_REQUEST";
export const GET_CHART_DATA_FOR_ALL_TEAMS_SUCCESS =
  "GET_CHART_DATA_FOR_ALL_TEAMS_SUCCESS";
export const GET_CHART_DATA_FOR_ALL_TEAMS_FAILURE =
  "GET_CHART_DATA_FOR_ALL_TEAMS_FAILURE";

export const GET_FUNNEL_OF_ALL_TEAMS_REQUEST =
  "GET_FUNNEL_OF_ALL_TEAMS_REQUEST";
export const GET_FUNNEL_OF_ALL_TEAMS_SUCCESS =
  "GET_FUNNEL_OF_ALL_TEAMS_SUCCESS";
export const GET_FUNNEL_OF_ALL_TEAMS_FAILURE =
  "GET_FUNNEL_OF_ALL_TEAMS_FAILURE";

export const GET_OPPORTUNITIES_OF_ALL_TEAMS_REQUEST =
  "GET_OPPORTUNITIES_OF_ALL_TEAMS_REQUEST";
export const GET_OPPORTUNITIES_OF_ALL_TEAMS_SUCCESS =
  "GET_OPPORTUNITIES_OF_ALL_TEAMS_SUCCESS";
export const GET_OPPORTUNITIES_OF_ALL_TEAMS_FAILURE =
  "GET_OPPORTUNITIES_OF_ALL_TEAMS_FAILURE";

export const GET_ACCOUNTS_OF_ALL_TEAMS_REQUEST =
  "GET_ACCOUNTS_OF_ALL_TEAMS_REQUEST";
export const GET_ACCOUNTS_OF_ALL_TEAMS_SUCCESS =
  "GET_ACCOUNTS_OF_ALL_TEAMS_SUCCESS";
export const GET_ACCOUNTS_OF_ALL_TEAMS_FAILURE =
  "GET_ACCOUNTS_OF_ALL_TEAMS_FAILURE";

export const GET_CONTACTS_OF_ALL_TEAMS_REQUEST =
  "GET_CONTACTS_OF_ALL_TEAMS_REQUEST";
export const GET_CONTACTS_OF_ALL_TEAMS_SUCCESS =
  "GET_CONTACTS_OF_ALL_TEAMS_SUCCESS";
export const GET_CONTACTS_OF_ALL_TEAMS_FAILURE =
  "GET_CONTACTS_OF_ALL_TEAMS_FAILURE";

/**
 * jumpstart and chart request for single team
 */
export const GET_CHART_DATA_BY_TEAM_ID_REQUEST =
  "GET_CHART_DATA_BY_TEAM_ID_REQUEST";
export const GET_CHART_DATA_BY_TEAM_ID_SUCCESS =
  "GET_CHART_DATA_BY_TEAM_ID_SUCCESS";
export const GET_CHART_DATA_BY_TEAM_ID_FAILURE =
  "GET_CHART_DATA_BY_TEAM_ID_FAILURE";

export const GET_FUNNEL_BY_TEAM_ID_REQUEST = "GET_FUNNEL_BY_TEAM_ID_REQUEST";
export const GET_FUNNEL_BY_TEAM_ID_SUCCESS = "GET_FUNNEL_BY_TEAM_ID_SUCCESS";
export const GET_FUNNEL_BY_TEAM_ID_FAILURE = "GET_FUNNEL_BY_TEAM_ID_FAILURE";

export const GET_OPPORTUNITIES_BY_TEAM_ID_REQUEST =
  "GET_OPPORTUNITIES_BY_TEAM_ID_REQUEST";
export const GET_OPPORTUNITIES_BY_TEAM_ID_SUCCESS =
  "GET_OPPORTUNITIES_BY_TEAM_ID_SUCCESS";
export const GET_OPPORTUNITIES_BY_TEAM_ID_FAILURE =
  "GET_OPPORTUNITIES_BY_TEAM_ID_FAILURE";

export const GET_ACCOUNTS_BY_TEAM_ID_REQUEST =
  "GET_ACCOUNTS_BY_TEAM_ID_REQUEST";
export const GET_ACCOUNTS_BY_TEAM_ID_SUCCESS =
  "GET_ACCOUNTS_BY_TEAM_ID_SUCCESS";
export const GET_ACCOUNTS_BY_TEAM_ID_FAILURE =
  "GET_ACCOUNTS_BY_TEAM_ID_FAILURE";

export const GET_CONTACTS_BY_TEAM_ID_REQUEST =
  "GET_CONTACTS_BY_TEAM_ID_REQUEST";
export const GET_CONTACTS_BY_TEAM_ID_SUCCESS =
  "GET_CONTACTS_BY_TEAM_ID_SUCCESS";
export const GET_CONTACTS_BY_TEAM_ID_FAILURE =
  "GET_CONTACTS_BY_TEAM_ID_FAILURE";

export const GET_OPPORTUNITY_CHART_BY_TEAM_ID_REQUEST =
  "GET_OPPORTUNITY_CHART_BY_TEAM_ID_REQUEST";
export const GET_OPPORTUNITY_CHART_BY_TEAM_ID_SUCCESS =
  "GET_OPPORTUNITY_CHART_BY_TEAM_ID_SUCCESS";
export const GET_OPPORTUNITY_CHART_BY_TEAM_ID_FAILURE =
  "GET_OPPORTUNITY_CHART_BY_TEAM_ID_FAILURE";

export const GET_CONTACT_LIST_BY_TEAM_ID_REQUEST =
  "GET_CONTACT_LIST_BY_TEAM_ID_REQUEST";
export const GET_CONTACT_LIST_BY_TEAM_ID_SUCCESS =
  "GET_CONTACT_LIST_BY_TEAM_ID_SUCCESS";
export const GET_CONTACT_LIST_BY_TEAM_ID_FAILURE =
  "GET_CONTACT_LIST_BY_TEAM_ID_FAILURE";

export const UPDATE_CONTACT_LIST_BY_TEAM_ID_REQUEST =
  "UPDATE_CONTACT_LIST_BY_TEAM_ID_REQUEST";
export const UPDATE_CONTACT_LIST_BY_TEAM_ID_SUCCESS =
  "UPDATE_CONTACT_LIST_BY_TEAM_ID_SUCCESS";
export const UPDATE_CONTACT_LIST_BY_TEAM_ID_FAILURE =
  "UPDATE_CONTACT_LIST_BY_TEAM_ID_FAILURE";

export const GET_ACCOUNT_LIST_BY_TEAM_ID_REQUEST =
  "GET_ACCOUNT_LIST_BY_TEAM_ID_REQUEST";
export const GET_ACCOUNT_LIST_BY_TEAM_ID_SUCCESS =
  "GET_ACCOUNT_LIST_BY_TEAM_ID_SUCCESS";
export const GET_ACCOUNT_LIST_BY_TEAM_ID_FAILURE =
  "GET_ACCOUNT_LIST_BY_TEAM_ID_FAILURE";

export const UPDATE_ACCOUNT_LIST_BY_TEAM_ID_REQUEST =
  "UPDATE_ACCOUNT_LIST_BY_TEAM_ID_REQUEST";
export const UPDATE_ACCOUNT_LIST_BY_TEAM_ID_SUCCESS =
  "UPDATE_ACCOUNT_LIST_BY_TEAM_ID_SUCCESS";
export const UPDATE_ACCOUNT_LIST_BY_TEAM_ID_FAILURE =
  "UPDATE_ACCOUNT_LIST_BY_TEAM_ID_FAILURE";

export const GET_OPPORTUNITY_LIST_BY_TEAM_ID_REQUEST =
  "GET_OPPORTUNITY_LIST_BY_TEAM_ID_REQUEST";
export const GET_OPPORTUNITY_LIST_BY_TEAM_ID_SUCCESS =
  "GET_OPPORTUNITY_LIST_BY_TEAM_ID_SUCCESS";
export const GET_OPPORTUNITY_LIST_BY_TEAM_ID_FAILURE =
  "GET_OPPORTUNITY_LIST_BY_TEAM_ID_FAILURE";

export const UPDATE_OPPORTUNITY_LIST_BY_TEAM_ID_REQUEST =
  "UPDATE_OPPORTUNITY_LIST_BY_TEAM_ID_REQUEST";
export const UPDATE_OPPORTUNITY_LIST_BY_TEAM_ID_SUCCESS =
  "UPDATE_OPPORTUNITY_LIST_BY_TEAM_ID_SUCCESS";
export const UPDATE_OPPORTUNITY_LIST_BY_TEAM_ID_FAILURE =
  "UPDATE_OPPORTUNITY_LIST_BY_TEAM_ID_FAILURE";

export const GET_USERS_LIST_BY_TEAM_ID_REQUEST =
  "GET_USERS_LIST_BY_TEAM_ID_REQUEST";
export const GET_USERS_LIST_BY_TEAM_ID_SUCCESS =
  "GET_USERS_LIST_BY_TEAM_ID_SUCCESS";
export const GET_USERS_LIST_BY_TEAM_ID_FAILURE =
  "GET_USERS_LIST_BY_TEAM_ID_FAILURE";

export const UPDATE_USERS_LIST_BY_TEAM_ID_REQUEST =
  "UPDATE_USERS_LIST_BY_TEAM_ID_REQUEST";
export const UPDATE_USERS_LIST_BY_TEAM_ID_SUCCESS =
  "UPDATE_USERS_LIST_BY_TEAM_ID_SUCCESS";
export const UPDATE_USERS_LIST_BY_TEAM_ID_FAILURE =
  "UPDATE_USERS_LIST_BY_TEAM_ID_FAILURE";

export const GET_NOTES_LIST_BY_TEAM_ID_REQUEST =
  "GET_NOTES_LIST_BY_TEAM_ID_REQUEST";
export const GET_NOTES_LIST_BY_TEAM_ID_SUCCESS =
  "GET_NOTES_LIST_BY_TEAM_ID_SUCCESS";
export const GET_NOTES_LIST_BY_TEAM_ID_FAILURE =
  "GET_NOTES_LIST_BY_TEAM_ID_FAILURE";

export const UPDATE_NOTES_LIST_BY_TEAM_ID_REQUEST =
  "UPDATE_NOTES_LIST_BY_TEAM_ID_REQUEST";
export const UPDATE_NOTES_LIST_BY_TEAM_ID_SUCCESS =
  "UPDATE_NOTES_LIST_BY_TEAM_ID_SUCCESS";
export const UPDATE_NOTES_LIST_BY_TEAM_ID_FAILURE =
  "UPDATE_NOTES_LIST_BY_TEAM_ID_FAILURE";

export const GET_CALLS_LIST_BY_TEAM_ID_REQUEST =
  "GET_CALLS_LIST_BY_TEAM_ID_REQUEST";
export const GET_CALLS_LIST_BY_TEAM_ID_SUCCESS =
  "GET_CALLS_LIST_BY_TEAM_ID_SUCCESS";
export const GET_CALLS_LIST_BY_TEAM_ID_FAILURE =
  "GET_CALLS_LIST_BY_TEAM_ID_FAILURE";

export const UPDATE_CALLS_LIST_BY_TEAM_ID_REQUEST =
  "UPDATE_CALLS_LIST_BY_TEAM_ID_REQUEST";
export const UPDATE_CALLS_LIST_BY_TEAM_ID_SUCCESS =
  "UPDATE_CALLS_LIST_BY_TEAM_ID_SUCCESS";
export const UPDATE_CALLS_LIST_BY_TEAM_ID_FAILURE =
  "UPDATE_CALLS_LIST_BY_TEAM_ID_FAILURE";

export const GET_EVENTS_LIST_BY_TEAM_ID_REQUEST =
  "GET_EVENTS_LIST_BY_TEAM_ID_REQUEST";
export const GET_EVENTS_LIST_BY_TEAM_ID_SUCCESS =
  "GET_EVENTS_LIST_BY_TEAM_ID_SUCCESS";
export const GET_EVENTS_LIST_BY_TEAM_ID_FAILURE =
  "GET_EVENTS_LIST_BY_TEAM_ID_FAILURE";

export const UPDATE_EVENTS_LIST_BY_TEAM_ID_REQUEST =
  "UPDATE_EVENTS_LIST_BY_TEAM_ID_REQUEST";
export const UPDATE_EVENTS_LIST_BY_TEAM_ID_SUCCESS =
  "UPDATE_EVENTS_LIST_BY_TEAM_ID_SUCCESS";
export const UPDATE_EVENTS_LIST_BY_TEAM_ID_FAILURE =
  "UPDATE_EVENTS_LIST_BY_TEAM_ID_FAILURE";

export const GET_TASKS_LIST_BY_TEAM_ID_REQUEST =
  "GET_TASKS_LIST_BY_TEAM_ID_REQUEST";
export const GET_TASKS_LIST_BY_TEAM_ID_SUCCESS =
  "GET_TASKS_LIST_BY_TEAM_ID_SUCCESS";
export const GET_TASKS_LIST_BY_TEAM_ID_FAILURE =
  "GET_TASKS_LIST_BY_TEAM_ID_FAILURE";

export const UPDATE_TASKS_LIST_BY_TEAM_ID_REQUEST =
  "UPDATE_TASKS_LIST_BY_TEAM_ID_REQUEST";
export const UPDATE_TASKS_LIST_BY_TEAM_ID_SUCCESS =
  "UPDATE_TASKS_LIST_BY_TEAM_ID_SUCCESS";
export const UPDATE_TASKS_LIST_BY_TEAM_ID_FAILURE =
  "UPDATE_TASKS_LIST_BY_TEAM_ID_FAILURE";

export const SET_TEAMS_DASHBOARD_TYPE = "SET_TEAMS_DASHBOARD_TYPE";
export const SET_SELECTED_TEAM = "SET_SELECTED_TEAM";
export const HANDLE_TEAMS_MODAL = "HANDLE_TEAMS_MODAL";
export const HANDLE_CHART_MODAL = "HANDLE_CHART_MODAL";
export const SET_TEAMS_VIEW_TYPE = "SET_TEAMS_VIEW_TYPE";
export const HANDLE_ADD_TEAMS_MEMBER_MODAL = "HANDLE_ADD_TEAMS_MEMBER_MODAL";

export const HANDLE_WEIGHTED_MODAL = "HANDLE_WEIGHTED_MODAL";
export const HANDLE_ABSOLUTE_MODAL = "HANDLE_ABSOLUTE_MODAL";
export const HANDLE_CLOSURE_MODAL = "HANDLE_CLOSURE_MODAL";
export const HANDLE_WIN_MODAL = "HANDLE_WIN_MODAL";
export const HANDLE_WON_MODAL = "HANDLE_WON_MODAL";
export const HANDLE_CUSTOMER_MODAL = "HANDLE_CUSTOMER_MODAL";

export const HANDLE_WEIGHTEDCHART_MODAL = "HANDLE_WEIGHTEDCHART_MODAL";
export const HANDLE_ACTUALCHART_MODAL = "HANDLE_ACTUALCHART_MODAL";
export const HANDLE_PROGRESSCHART_MODAL = "HANDLE_PROGRESSCHART_MODAL";
export const HANDLE_WINCHART_MODAL = "HANDLE_WINCHART_MODAL";
export const HANDLE_WONCHART_MODAL = "HANDLE_WONCHART_MODAL";
export const HANDLE_CUSTOMERCHART_MODAL = "HANDLE_CUSTOMERCHART_MODAL";

export const ADD_LOCATION_REQUEST = "ADD_LOCATION_REQUEST";
export const ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCCESS";
export const ADD_LOCATION_FAILURE = "ADD_LOCATION_FAILURE";

export const GET_LOCATION_DETAILS_REQUEST = "GET_LOCATION_DETAILS_REQUEST";
export const GET_LOCATION_DETAILS_SUCCESS = "GET_LOCATION_DETAILS_SUCCESS";
export const GET_LOCATION_DETAILS_FAILURE = "GET_LOCATION_DETAILS_FAILURE";

export const HANDLE_CARD_LOCATION_ICON_MODAL =
  "HANDLE_CARD_LOCATION_ICON_MODAL";
export const GET_LIST_OF_LOCATION_DETAILS_REQUEST =
  "GET_LIST_OF_LOCATION_DETAILS_REQUEST";
export const GET_LIST_OF_LOCATION_DETAILS_SUCCESS =
  "GET_LIST_OF_LOCATION_DETAILS_SUCCESS";
export const GET_LIST_OF_LOCATION_DETAILS_FAILURE =
  "GET_LIST_OF_LOCATION_DETAILS_FAILURE";

export const HANDLE_LOCATION_USER_MODAL = "HANDLE_LOCATION_USER_MODAL";

export const ADD_INVENTORY_LOCATION_REQUEST = "ADD_INVENTORY_LOCATION_REQUEST";
export const ADD_INVENTORY_LOCATION_SUCCESS = "ADD_INVENTORY_LOCATION_SUCCESS";
export const ADD_INVENTORY_LOCATION_FAILURE = "ADD_INVENTORY_LOCATION_FAILURE";

export const GET_LOCATION_WISE_USER_REQUEST = "GET_LOCATION_WISE_USER_REQUEST";
export const GET_LOCATION_WISE_USER_SUCCESS = "GET_LOCATION_WISE_USER_SUCCESS";
export const GET_LOCATION_WISE_USER_FAILURE = "GET_LOCATION_WISE_USER_FAILURE";

export const HANDLE_UPDATE_LOCATION_USER_MODAL =
  "HANDLE_UPDATE_LOCATION_USER_MODAL";

export const SET_LOCATION_EDIT = "SET_LOCATION_EDIT";

export const UPDATE_LOCATION_REQUEST = "UPDATE_LOCATION_REQUEST";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const UPDATE_LOCATION_FAILURE = "UPDATE_LOCATION_FAILURE";

export const INPUT_SEARCH_TEAMS_DATA_REQUEST = "INPUT_SEARCH_TEAMS_DATA_REQUEST";
export const INPUT_SEARCH_TEAMS_DATA_SUCCESS = "INPUT_SEARCH_TEAMS_DATA_SUCCESS";
export const INPUT_SEARCH_TEAMS_DATA_FAILURE = "INPUT_SEARCH_TEAMS_DATA_FAILURE";
