import * as types from "./ContactActionTypes";
import axios from "axios";
import moment from "moment";
import { base_url } from "../../Config/Auth";
import { message } from "antd";
import { linkContactsToOpportunity } from "../Opportunity/OpportunityAction";
const mockData = [
  {
    contactId: "COIF30729872716262020",
    contactType: "Strategic",
    contactSubtype: "",
    salutation: "Mr.",
    designation: "CXO",
    description: "",
    department: "IT",
    language: "",
    contactOwner: "USIF36567893899172020",
    owner: {
      userId: "USIF36567893899172020",
      creatorId: "USIF39644679864102020",
      organizationId: "OIF56911991357102020",
      creationDate: "2020-04-17T04:40:18Z",
      salutation: "",
      firstName: "Jit",
      middleName: "",
      lastName: "",
      status: "",
      emailValidationInd: true,
      userType: "USER",
      gender: "",
      phoneNo: "",
      mobileNo: "",
      emailId: "jitmishra05@yahoo.com",
      tradeCurrency: "EUR",
      imageId: "IINF63582486689222020",
      department: "Sales",
      countryDialCode: "",
      countryDialCode1: "",
      teamLeadInd: false,
      firstTimeLoginInd: false,
      designation: "",
      level: "L2",
    },
    creatorId: "USIF36567893899172020",
    creatorName: "Jit  ",
    creationDate: "2020-04-26T17:16:38Z",
    accountId: "ACINF57806120359262020",
    firstName: "Ravi ",
    middleName: "",
    lastName: "Krishnan",
    gender: "",
    phoneNo: "",
    alternateNo: "",
    mobileNo: "",
    emailId: "",
    secondaryEmailId: "",
    linkedinPublicUrl: "",
    skypeId: "",
    fax: "",
    imageId: "IINF22458683415262020",
    address: [
      {
        addressId: "ADIF26452119627262020",
        addressType: "",
        address1: "1600",
        address2: "",
        town: "",
        street: "Holloway Avenue",
        city: "San Francisco",
        postalCode: "94132",
        state: "California",
        country: "United States",
        longitude: "-122.4799405",
        latitude: "37.7241492",
      },
    ],
    metaData: {
      funnel: {
        weightedFunnel: 0,
        actualFunnel: 0,
        currency: "EUR",
        for: "Contact",
        id: "COIF30729872716262020",
        totalActualFunnel: 0,
        totalWeightedFunnel: 0,
      },
      followupInd: false,
      account: {
        accountId: "ACINF57806120359262020",
        accountName: "McKesson",
        accountOwner: "USIF36567893899172020",
        website: "mckesson.com",
        tickerSymbol: "",
        tradeCurrency: "",
        companySize: "",
        industryType: "Drug distribution",
        baseCountry: "",
        phone1: "",
        phone2: "",
        fax: "",
        email: "",
        rating: 0,
        sicCode: "",
        annualRevenue: "",
        description: "",
        creator: "USIF36567893899172020",
        creationDate: "2020-04-26T17:13:41Z",
        imageId: "",
        address: [
          {
            addressId: "ADIF14275951023262020",
            addressType: "",
            address1: "1600",
            address2: "",
            town: "",
            street: "Holloway Avenue",
            postalCode: "94132",
            state: "California",
            country: "United States",
            longitude: "-122.4799405",
            latitude: "37.7241492",
          },
        ],
        creatorName: "Jit  ",
        owner: {
          userId: "USIF36567893899172020",
          creatorId: "USIF39644679864102020",
          organizationId: "OIF56911991357102020",
          creationDate: "2020-04-17T04:40:18Z",
          salutation: "",
          firstName: "Jit",
          middleName: "",
          lastName: "",
          status: "",
          emailValidationInd: true,
          userType: "USER",
          gender: "",
          phoneNo: "",
          mobileNo: "",
          emailId: "jitmishra05@yahoo.com",
          tradeCurrency: "EUR",
          imageId: "IINF63582486689222020",
          department: "Sales",
          countryDialCode: "",
          countryDialCode1: "",
          teamLeadInd: false,
          firstTimeLoginInd: false,
          designation: "",
          level: "L2",
        },
        imageURL: "https://logo.clearbit.com/mckesson.com",
        foundedIn: 0,
        enrichInd: false,
      },
    },
    countryDialCode: "",
    countryDialCode1: "",
    departmentDetails: "Supply chain",
    accountMapper: {
      accountId: "ACINF57806120359262020",
      accountName: "McKesson",
      accountOwner: "USIF36567893899172020",
      website: "mckesson.com",
      tickerSymbol: "",
      tradeCurrency: "",
      companySize: "",
      industryType: "Drug distribution",
      baseCountry: "",
      phone1: "",
      phone2: "",
      fax: "",
      email: "",
      rating: 0,
      sicCode: "",
      annualRevenue: "",
      description: "",
      creator: "USIF36567893899172020",
      creationDate: "2020-04-26T17:13:41Z",
      imageId: "",
      creatorName: "Jit  ",
      owner: {
        userId: "USIF36567893899172020",
        creatorId: "USIF39644679864102020",
        organizationId: "OIF56911991357102020",
        creationDate: "2020-04-17T04:40:18Z",
        salutation: "",
        firstName: "Jit",
        middleName: "",
        lastName: "",
        status: "",
        emailValidationInd: true,
        userType: "USER",
        gender: "",
        phoneNo: "",
        mobileNo: "",
        emailId: "jitmishra05@yahoo.com",
        tradeCurrency: "EUR",
        imageId: "IINF63582486689222020",
        department: "Sales",
        countryDialCode: "",
        countryDialCode1: "",
        teamLeadInd: false,
        firstTimeLoginInd: false,
        designation: "",
        level: "L2",
      },
      imageURL: "https://logo.clearbit.com/mckesson.com",
      foundedIn: 0,
      enrichInd: false,
    },
  },
  {
    contactId: "COIF4662539221262020",
    contactType: "Strategic",
    contactSubtype: "",
    salutation: "Mr.",
    designation: "CXO",
    description: "",
    department: "Business",
    language: "",
    contactOwner: "USIF36567893899172020",
    owner: {
      userId: "USIF36567893899172020",
      creatorId: "USIF39644679864102020",
      organizationId: "OIF56911991357102020",
      creationDate: "2020-04-17T04:40:18Z",
      salutation: "",
      firstName: "Jit",
      middleName: "",
      lastName: "",
      status: "",
      emailValidationInd: true,
      userType: "USER",
      gender: "",
      phoneNo: "",
      mobileNo: "",
      emailId: "jitmishra05@yahoo.com",
      tradeCurrency: "EUR",
      imageId: "IINF63582486689222020",
      department: "Sales",
      countryDialCode: "",
      countryDialCode1: "",
      teamLeadInd: false,
      firstTimeLoginInd: false,
      designation: "",
      level: "L2",
    },
    creatorId: "USIF36567893899172020",
    creatorName: "Jit  ",
    creationDate: "2020-04-26T12:11:40Z",
    accountId: "ACINF25150576758252020",
    firstName: "Rob ",
    middleName: "",
    lastName: "Wayne",
    gender: "",
    phoneNo: "898461644",
    alternateNo: "",
    mobileNo: "664662445",
    emailId: "rob@nhs.com",
    secondaryEmailId: "",
    linkedinPublicUrl: "",
    skypeId: "RobW",
    fax: "",
    imageId: "",
    address: [
      {
        addressId: "ADIF15917105384262020",
        addressType: "",
        address1: "136",
        address2: "",
        town: "",
        street: "Penarth Road",
        city: "",
        postalCode: "CF10 5RY",
        state: "Wales",
        country: "United Kingdom",
        longitude: "-3.1789539",
        latitude: "51.4751516",
      },
    ],
    metaData: {
      funnel: {
        weightedFunnel: 224638.8,
        actualFunnel: 240072,
        currency: "EUR",
        for: "Contact",
        id: "COIF4662539221262020",
        totalActualFunnel: 0,
        totalWeightedFunnel: 0,
      },
      followupInd: false,
      account: {
        accountId: "ACINF25150576758252020",
        accountName: "NHS",
        accountOwner: "USIF36567893899172020",
        website: "www.nhs.uk",
        tickerSymbol: "",
        tradeCurrency: "",
        companySize: "",
        industryType: "Healthcare",
        baseCountry: "",
        phone1: "",
        phone2: "",
        fax: "",
        email: "",
        rating: 0,
        sicCode: "",
        annualRevenue: "",
        description: "",
        creator: "USIF36567893899172020",
        creationDate: "2020-04-25T21:40:43Z",
        imageId: "IINF96646304024252020",
        address: [
          {
            addressId: "ADIF72998840123252020",
            addressType: "",
            address1: "",
            address2: "",
            town: "",
            street: "",
            postalCode: "M90 1QX",
            state: "England",
            country: "United Kingdom",
            longitude: "-2.2727303",
            latitude: "53.3588026",
          },
        ],
        creatorName: "Jit  ",
        owner: {
          userId: "USIF36567893899172020",
          creatorId: "USIF39644679864102020",
          organizationId: "OIF56911991357102020",
          creationDate: "2020-04-17T04:40:18Z",
          salutation: "",
          firstName: "Jit",
          middleName: "",
          lastName: "",
          status: "",
          emailValidationInd: true,
          userType: "USER",
          gender: "",
          phoneNo: "",
          mobileNo: "",
          emailId: "jitmishra05@yahoo.com",
          tradeCurrency: "EUR",
          imageId: "IINF63582486689222020",
          department: "Sales",
          countryDialCode: "",
          countryDialCode1: "",
          teamLeadInd: false,
          firstTimeLoginInd: false,
          designation: "",
          level: "L2",
        },
        imageURL: "https://logo.clearbit.com/www.nhs.uk",
        foundedIn: 0,
        enrichInd: false,
      },
    },
    countryDialCode: "+44",
    countryDialCode1: "+44",
    departmentDetails: "Front office",
    accountMapper: {
      accountId: "ACINF25150576758252020",
      accountName: "NHS",
      accountOwner: "USIF36567893899172020",
      website: "www.nhs.uk",
      tickerSymbol: "",
      tradeCurrency: "",
      companySize: "",
      industryType: "Healthcare",
      baseCountry: "",
      phone1: "",
      phone2: "",
      fax: "",
      email: "",
      rating: 0,
      sicCode: "",
      annualRevenue: "",
      description: "",
      creator: "USIF36567893899172020",
      creationDate: "2020-04-25T21:40:43Z",
      imageId: "IINF96646304024252020",
      creatorName: "Jit  ",
      owner: {
        userId: "USIF36567893899172020",
        creatorId: "USIF39644679864102020",
        organizationId: "OIF56911991357102020",
        creationDate: "2020-04-17T04:40:18Z",
        salutation: "",
        firstName: "Jit",
        middleName: "",
        lastName: "",
        status: "",
        emailValidationInd: true,
        userType: "USER",
        gender: "",
        phoneNo: "",
        mobileNo: "",
        emailId: "jitmishra05@yahoo.com",
        tradeCurrency: "EUR",
        imageId: "IINF63582486689222020",
        department: "Sales",
        countryDialCode: "",
        countryDialCode1: "",
        teamLeadInd: false,
        firstTimeLoginInd: false,
        designation: "",
        level: "L2",
      },
      imageURL: "https://logo.clearbit.com/www.nhs.uk",
      foundedIn: 0,
      enrichInd: false,
    },
  },
  {
    contactId: "COIF9751196760252020",
    contactType: "Strategic",
    contactSubtype: "",
    salutation: "Mr.",
    designation: "CXO",
    description: "",
    department: "IT",
    language: "",
    contactOwner: "USIF36567893899172020",
    owner: {
      userId: "USIF36567893899172020",
      creatorId: "USIF39644679864102020",
      organizationId: "OIF56911991357102020",
      creationDate: "2020-04-17T04:40:18Z",
      salutation: "",
      firstName: "Jit",
      middleName: "",
      lastName: "",
      status: "",
      emailValidationInd: true,
      userType: "USER",
      gender: "",
      phoneNo: "",
      mobileNo: "",
      emailId: "jitmishra05@yahoo.com",
      tradeCurrency: "EUR",
      imageId: "IINF63582486689222020",
      department: "Sales",
      countryDialCode: "",
      countryDialCode1: "",
      teamLeadInd: false,
      firstTimeLoginInd: false,
      designation: "",
      level: "L2",
    },
    creatorId: "USIF36567893899172020",
    creatorName: "Jit  ",
    creationDate: "2020-04-25T21:42:59Z",
    accountId: "ACINF25150576758252020",
    firstName: "John",
    middleName: "",
    lastName: "Weber",
    gender: "",
    phoneNo: "2345234234",
    alternateNo: "",
    mobileNo: "1212335345",
    emailId: "john@nhs.com",
    secondaryEmailId: "",
    linkedinPublicUrl: "",
    skypeId: "john1",
    fax: "",
    imageId: "IINF22087392158282020",
    address: [
      {
        addressId: "ADIF98486888365252020",
        addressType: "",
        address1: "",
        address2: "",
        town: "",
        street: "",
        city: "",
        postalCode: "M90 1QX",
        state: "England",
        country: "United Kingdom",
        longitude: "-2.2727303",
        latitude: "53.3588026",
      },
    ],
    metaData: {
      funnel: {
        weightedFunnel: 284649.3,
        actualFunnel: 411502,
        currency: "EUR",
        for: "Contact",
        id: "COIF9751196760252020",
        totalActualFunnel: 0,
        totalWeightedFunnel: 0,
      },
      followupInd: false,
      account: {
        accountId: "ACINF25150576758252020",
        accountName: "NHS",
        accountOwner: "USIF36567893899172020",
        website: "www.nhs.uk",
        tickerSymbol: "",
        tradeCurrency: "",
        companySize: "",
        industryType: "Healthcare",
        baseCountry: "",
        phone1: "",
        phone2: "",
        fax: "",
        email: "",
        rating: 0,
        sicCode: "",
        annualRevenue: "",
        description: "",
        creator: "USIF36567893899172020",
        creationDate: "2020-04-25T21:40:43Z",
        imageId: "IINF96646304024252020",
        address: [
          {
            addressId: "ADIF72998840123252020",
            addressType: "",
            address1: "",
            address2: "",
            town: "",
            street: "",
            postalCode: "M90 1QX",
            state: "England",
            country: "United Kingdom",
            longitude: "-2.2727303",
            latitude: "53.3588026",
          },
        ],
        creatorName: "Jit  ",
        owner: {
          userId: "USIF36567893899172020",
          creatorId: "USIF39644679864102020",
          organizationId: "OIF56911991357102020",
          creationDate: "2020-04-17T04:40:18Z",
          salutation: "",
          firstName: "Jit",
          middleName: "",
          lastName: "",
          status: "",
          emailValidationInd: true,
          userType: "USER",
          gender: "",
          phoneNo: "",
          mobileNo: "",
          emailId: "jitmishra05@yahoo.com",
          tradeCurrency: "EUR",
          imageId: "IINF63582486689222020",
          department: "Sales",
          countryDialCode: "",
          countryDialCode1: "",
          teamLeadInd: false,
          firstTimeLoginInd: false,
          designation: "",
          level: "L2",
        },
        imageURL: "https://logo.clearbit.com/www.nhs.uk",
        foundedIn: 0,
        enrichInd: false,
      },
    },
    countryDialCode: "+44",
    countryDialCode1: "+44",
    departmentDetails: "CIO",
    accountMapper: {
      accountId: "ACINF25150576758252020",
      accountName: "NHS",
      accountOwner: "USIF36567893899172020",
      website: "www.nhs.uk",
      tickerSymbol: "",
      tradeCurrency: "",
      companySize: "",
      industryType: "Healthcare",
      baseCountry: "",
      phone1: "",
      phone2: "",
      fax: "",
      email: "",
      rating: 0,
      sicCode: "",
      annualRevenue: "",
      description: "",
      creator: "USIF36567893899172020",
      creationDate: "2020-04-25T21:40:43Z",
      imageId: "IINF96646304024252020",
      creatorName: "Jit  ",
      owner: {
        userId: "USIF36567893899172020",
        creatorId: "USIF39644679864102020",
        organizationId: "OIF56911991357102020",
        creationDate: "2020-04-17T04:40:18Z",
        salutation: "",
        firstName: "Jit",
        middleName: "",
        lastName: "",
        status: "",
        emailValidationInd: true,
        userType: "USER",
        gender: "",
        phoneNo: "",
        mobileNo: "",
        emailId: "jitmishra05@yahoo.com",
        tradeCurrency: "EUR",
        imageId: "IINF63582486689222020",
        department: "Sales",
        countryDialCode: "",
        countryDialCode1: "",
        teamLeadInd: false,
        firstTimeLoginInd: false,
        designation: "",
        level: "L2",
      },
      imageURL: "https://logo.clearbit.com/www.nhs.uk",
      foundedIn: 0,
      enrichInd: false,
    },
  },
  {
    contactId: "COIF48821866162252020",
    contactType: "Strategic",
    contactSubtype: "",
    salutation: "Mr.",
    designation: "CXO",
    description: "",
    department: "HR",
    language: "",
    contactOwner: "USIF36567893899172020",
    owner: {
      userId: "USIF36567893899172020",
      creatorId: "USIF39644679864102020",
      organizationId: "OIF56911991357102020",
      creationDate: "2020-04-17T04:40:18Z",
      salutation: "",
      firstName: "Jit",
      middleName: "",
      lastName: "",
      status: "",
      emailValidationInd: true,
      userType: "USER",
      gender: "",
      phoneNo: "",
      mobileNo: "",
      emailId: "jitmishra05@yahoo.com",
      tradeCurrency: "EUR",
      imageId: "IINF63582486689222020",
      department: "Sales",
      countryDialCode: "",
      countryDialCode1: "",
      teamLeadInd: false,
      firstTimeLoginInd: false,
      designation: "",
      level: "L2",
    },
    creatorId: "USIF36567893899172020",
    creatorName: "Jit  ",
    creationDate: "2020-04-25T20:39:57Z",
    accountId: "ACINF97554628338252020",
    firstName: "John",
    middleName: "",
    lastName: "de Niro",
    gender: "",
    phoneNo: "",
    alternateNo: "",
    mobileNo: "6386646465",
    emailId: "",
    secondaryEmailId: "",
    linkedinPublicUrl: "",
    skypeId: "",
    fax: "",
    imageId: "",
    address: [
      {
        addressId: "ADIF15379138710252020",
        addressType: "",
        address1: "4315",
        address2: "",
        town: "",
        street: "Diplomacy Drive",
        city: "Anchorage",
        postalCode: "99508",
        state: "Alaska",
        country: "United States",
        longitude: "-149.8002427",
        latitude: "61.182452",
      },
    ],
    metaData: {
      funnel: {
        weightedFunnel: 0,
        actualFunnel: 0,
        currency: "EUR",
        for: "Contact",
        id: "COIF48821866162252020",
        totalActualFunnel: 0,
        totalWeightedFunnel: 0,
      },
      followupInd: false,
      account: {
        accountId: "ACINF97554628338252020",
        accountName: "American Airlines",
        accountOwner: "USIF36567893899172020",
        website: "aa.com",
        tickerSymbol: "",
        tradeCurrency: "EUR",
        companySize: "100000",
        industryType: "Transportation By Air",
        baseCountry: "",
        phone1: "",
        phone2: "",
        fax: "",
        email: "",
        rating: 0,
        sicCode: "",
        annualRevenue: "10 bn",
        description:
          "AMR Corporation is a commercial aviation business and airline holding company based in Fort Worth, Texas.",
        creator: "USIF36567893899172020",
        creationDate: "2020-04-25T10:50:36Z",
        imageId: "",
        address: [
          {
            addressId: "ADIF87313360492262020",
            addressType: "work",
            address1: "4333 Amon Carter Blvd.",
            address2: " ",
            postalCode: "76155",
            state: "Texas",
            country: "United States",
          },
          {
            addressId: "ADIF10064832517252020",
            addressType: "",
            address1: "937",
            address2: "",
            town: "",
            street: "Phillips Lane",
            postalCode: "40209",
            state: "Kentucky",
            country: "United States",
            longitude: "-85.74147359999999",
            latitude: "38.19911",
          },
          {
            addressId: "ADIF98968271394262020",
            addressType: " ",
            address1: " ",
            address2: " ",
            postalCode: " ",
            state: "TX",
            country: "USA",
          },
        ],
        creatorName: "Jit  ",
        owner: {
          userId: "USIF36567893899172020",
          creatorId: "USIF39644679864102020",
          organizationId: "OIF56911991357102020",
          creationDate: "2020-04-17T04:40:18Z",
          salutation: "",
          firstName: "Jit",
          middleName: "",
          lastName: "",
          status: "",
          emailValidationInd: true,
          userType: "USER",
          gender: "",
          phoneNo: "",
          mobileNo: "",
          emailId: "jitmishra05@yahoo.com",
          tradeCurrency: "EUR",
          imageId: "IINF63582486689222020",
          department: "Sales",
          countryDialCode: "",
          countryDialCode1: "",
          teamLeadInd: false,
          firstTimeLoginInd: false,
          designation: "",
          level: "L2",
        },
        imageURL: "https://logo.clearbit.com/aa.com",
        foundedIn: 0,
        enrichInd: false,
      },
    },
    countryDialCode: "+1",
    countryDialCode1: "",
    departmentDetails: "",
    accountMapper: {
      accountId: "ACINF97554628338252020",
      accountName: "American Airlines",
      accountOwner: "USIF36567893899172020",
      website: "aa.com",
      tickerSymbol: "",
      tradeCurrency: "EUR",
      companySize: "100000",
      industryType: "Transportation By Air",
      baseCountry: "",
      phone1: "",
      phone2: "",
      fax: "",
      email: "",
      rating: 0,
      sicCode: "",
      annualRevenue: "10 bn",
      description:
        "AMR Corporation is a commercial aviation business and airline holding company based in Fort Worth, Texas.",
      creator: "USIF36567893899172020",
      creationDate: "2020-04-25T10:50:36Z",
      imageId: "",
      creatorName: "Jit  ",
      owner: {
        userId: "USIF36567893899172020",
        creatorId: "USIF39644679864102020",
        organizationId: "OIF56911991357102020",
        creationDate: "2020-04-17T04:40:18Z",
        salutation: "",
        firstName: "Jit",
        middleName: "",
        lastName: "",
        status: "",
        emailValidationInd: true,
        userType: "USER",
        gender: "",
        phoneNo: "",
        mobileNo: "",
        emailId: "jitmishra05@yahoo.com",
        tradeCurrency: "EUR",
        imageId: "IINF63582486689222020",
        department: "Sales",
        countryDialCode: "",
        countryDialCode1: "",
        teamLeadInd: false,
        firstTimeLoginInd: false,
        designation: "",
        level: "L2",
      },
      imageURL: "https://logo.clearbit.com/aa.com",
      foundedIn: 0,
      enrichInd: false,
    },
  },
  {
    contactId: "COIF91802974096252020",
    contactType: "Operational",
    contactSubtype: "",
    salutation: "Ms.",
    designation: "Director",
    description: "",
    department: "Finance",
    language: "",
    contactOwner: "USIF36567893899172020",
    owner: {
      userId: "USIF36567893899172020",
      creatorId: "USIF39644679864102020",
      organizationId: "OIF56911991357102020",
      creationDate: "2020-04-17T04:40:18Z",
      salutation: "",
      firstName: "Jit",
      middleName: "",
      lastName: "",
      status: "",
      emailValidationInd: true,
      userType: "USER",
      gender: "",
      phoneNo: "",
      mobileNo: "",
      emailId: "jitmishra05@yahoo.com",
      tradeCurrency: "EUR",
      imageId: "IINF63582486689222020",
      department: "Sales",
      countryDialCode: "",
      countryDialCode1: "",
      teamLeadInd: false,
      firstTimeLoginInd: false,
      designation: "",
      level: "L2",
    },
    creatorId: "USIF36567893899172020",
    creatorName: "Jit  ",
    creationDate: "2020-04-25T10:45:31Z",
    accountId: "ACINF57805276417252020",
    firstName: "Elsa",
    middleName: "",
    lastName: "McCormick",
    gender: "",
    phoneNo: "",
    alternateNo: "",
    mobileNo: "",
    emailId: "",
    secondaryEmailId: "",
    linkedinPublicUrl: "",
    skypeId: "",
    fax: "",
    imageId: "",
    address: [
      {
        addressId: "ADIF61627495637252020",
        addressType: "",
        address1: "2",
        address2: "",
        town: "",
        street: "Madelieftuin",
        city: "Bergschenhoek",
        postalCode: "2662 CT",
        state: "Zuid-Holland",
        country: "Netherlands",
        longitude: "4.4884493",
        latitude: "51.9875171",
      },
    ],
    metaData: {
      funnel: {
        weightedFunnel: 128000,
        actualFunnel: 140000,
        currency: "EUR",
        for: "Contact",
        id: "COIF91802974096252020",
        totalActualFunnel: 0,
        totalWeightedFunnel: 0,
      },
      followupInd: false,
      account: {
        accountId: "ACINF57805276417252020",
        accountName: "Air France-KLM",
        accountOwner: "USIF36567893899172020",
        website: "airfranceklm.com",
        tickerSymbol: "",
        tradeCurrency: "",
        companySize: "20000",
        industryType:
          "Local and Suburban Transit and Interurban Highway Passenger Transportation",
        baseCountry: "",
        phone1: "",
        phone2: "",
        fax: "",
        email: "",
        rating: 0,
        sicCode: "",
        annualRevenue: "",
        description:
          "Air France and KLM are involved in conservation projects: The forest conservation project in Madagascar (PHCF), supported by Air France since 2008 and co-funded by the French Development Agency (AFD) and the French Global Environment Facility (FFEM) contributes to the development of local communities while protecting the biodiversity of the island. As part of its partnership with WWF-NL, KLM financially supports three conservation programs: the Coral Triangle in Indonesia, the Bonaire Marine Park and the APRA program in the Amazon.",
        creator: "USIF36567893899172020",
        creationDate: "2020-04-25T08:43:54Z",
        imageId: "",
        address: [
          {
            addressId: "ADIF12046125704282020",
            addressType: "work",
            address1: " ",
            address2: " ",
            postalCode: " ",
            state: "Ile-de-france",
            country: "France",
          },
          {
            addressId: "ADIF55269156629252020",
            addressType: "",
            address1: "202",
            address2: "",
            town: "",
            street: "Evert van de Beekstraat",
            postalCode: "1118 CP",
            state: "Noord-Holland",
            country: "Netherlands",
            longitude: "4.7682744",
            latitude: "52.3105386",
          },
          {
            addressId: "ADIF49115749670282020",
            addressType: "work",
            address1: " ",
            address2: " ",
            postalCode: " ",
            state: " ",
            country: "United States",
          },
        ],
        twitter: "We can not find relevant data.",
        linkedin: "https://www.linkedin.com/company/air-france--klm",
        facebook: "We can not find relevant data.",
        creatorName: "Jit  ",
        owner: {
          userId: "USIF36567893899172020",
          creatorId: "USIF39644679864102020",
          organizationId: "OIF56911991357102020",
          creationDate: "2020-04-17T04:40:18Z",
          salutation: "",
          firstName: "Jit",
          middleName: "",
          lastName: "",
          status: "",
          emailValidationInd: true,
          userType: "USER",
          gender: "",
          phoneNo: "",
          mobileNo: "",
          emailId: "jitmishra05@yahoo.com",
          tradeCurrency: "EUR",
          imageId: "IINF63582486689222020",
          department: "Sales",
          countryDialCode: "",
          countryDialCode1: "",
          teamLeadInd: false,
          firstTimeLoginInd: false,
          designation: "",
          level: "L2",
        },
        imageURL: "https://logo.clearbit.com/airfranceklm.com",
        foundedIn: 0,
        enrichInd: true,
      },
    },
    countryDialCode: "",
    countryDialCode1: "",
    departmentDetails: "",
    accountMapper: {
      accountId: "ACINF57805276417252020",
      accountName: "Air France-KLM",
      accountOwner: "USIF36567893899172020",
      website: "airfranceklm.com",
      tickerSymbol: "",
      tradeCurrency: "",
      companySize: "20000",
      industryType:
        "Local and Suburban Transit and Interurban Highway Passenger Transportation",
      baseCountry: "",
      phone1: "",
      phone2: "",
      fax: "",
      email: "",
      rating: 0,
      sicCode: "",
      annualRevenue: "",
      description:
        "Air France and KLM are involved in conservation projects: The forest conservation project in Madagascar (PHCF), supported by Air France since 2008 and co-funded by the French Development Agency (AFD) and the French Global Environment Facility (FFEM) contributes to the development of local communities while protecting the biodiversity of the island. As part of its partnership with WWF-NL, KLM financially supports three conservation programs: the Coral Triangle in Indonesia, the Bonaire Marine Park and the APRA program in the Amazon.",
      creator: "USIF36567893899172020",
      creationDate: "2020-04-25T08:43:54Z",
      imageId: "",
      twitter: "We can not find relevant data.",
      linkedin: "https://www.linkedin.com/company/air-france--klm",
      facebook: "We can not find relevant data.",
      creatorName: "Jit  ",
      owner: {
        userId: "USIF36567893899172020",
        creatorId: "USIF39644679864102020",
        organizationId: "OIF56911991357102020",
        creationDate: "2020-04-17T04:40:18Z",
        salutation: "",
        firstName: "Jit",
        middleName: "",
        lastName: "",
        status: "",
        emailValidationInd: true,
        userType: "USER",
        gender: "",
        phoneNo: "",
        mobileNo: "",
        emailId: "jitmishra05@yahoo.com",
        tradeCurrency: "EUR",
        imageId: "IINF63582486689222020",
        department: "Sales",
        countryDialCode: "",
        countryDialCode1: "",
        teamLeadInd: false,
        firstTimeLoginInd: false,
        designation: "",
        level: "L2",
      },
      imageURL: "https://logo.clearbit.com/airfranceklm.com",
      foundedIn: 0,
      enrichInd: true,
    },
  },
  {
    contactId: "COIF72479801533252020",
    contactType: "Operational",
    contactSubtype: "",
    salutation: "Mr.",
    designation: "Director",
    description: "",
    department: "Operations",
    language: "",
    contactOwner: "USIF36567893899172020",
    owner: {
      userId: "USIF36567893899172020",
      creatorId: "USIF39644679864102020",
      organizationId: "OIF56911991357102020",
      creationDate: "2020-04-17T04:40:18Z",
      salutation: "",
      firstName: "Jit",
      middleName: "",
      lastName: "",
      status: "",
      emailValidationInd: true,
      userType: "USER",
      gender: "",
      phoneNo: "",
      mobileNo: "",
      emailId: "jitmishra05@yahoo.com",
      tradeCurrency: "EUR",
      imageId: "IINF63582486689222020",
      department: "Sales",
      countryDialCode: "",
      countryDialCode1: "",
      teamLeadInd: false,
      firstTimeLoginInd: false,
      designation: "",
      level: "L2",
    },
    creatorId: "USIF36567893899172020",
    creatorName: "Jit  ",
    creationDate: "2020-04-25T10:42:45Z",
    accountId: "ACINF57805276417252020",
    firstName: "Philip",
    middleName: "",
    lastName: "S",
    gender: "",
    phoneNo: "",
    alternateNo: "",
    mobileNo: "",
    emailId: "",
    secondaryEmailId: "",
    linkedinPublicUrl: "",
    skypeId: "",
    fax: "",
    imageId: "",
    address: [
      {
        addressId: "ADIF15848738794252020",
        addressType: "",
        address1: "",
        address2: "",
        town: "",
        street: "Stationsplein",
        city: "Amsterdam",
        postalCode: "1012 AB",
        state: "Noord-Holland",
        country: "Netherlands",
        longitude: "4.900272",
        latitude: "52.3791283",
      },
    ],
    metaData: {
      funnel: {
        weightedFunnel: 8000,
        actualFunnel: 20000,
        currency: "EUR",
        for: "Contact",
        id: "COIF72479801533252020",
        totalActualFunnel: 0,
        totalWeightedFunnel: 0,
      },
      followupInd: false,
      account: {
        accountId: "ACINF57805276417252020",
        accountName: "Air France-KLM",
        accountOwner: "USIF36567893899172020",
        website: "airfranceklm.com",
        tickerSymbol: "",
        tradeCurrency: "",
        companySize: "20000",
        industryType:
          "Local and Suburban Transit and Interurban Highway Passenger Transportation",
        baseCountry: "",
        phone1: "",
        phone2: "",
        fax: "",
        email: "",
        rating: 0,
        sicCode: "",
        annualRevenue: "",
        description:
          "Air France and KLM are involved in conservation projects: The forest conservation project in Madagascar (PHCF), supported by Air France since 2008 and co-funded by the French Development Agency (AFD) and the French Global Environment Facility (FFEM) contributes to the development of local communities while protecting the biodiversity of the island. As part of its partnership with WWF-NL, KLM financially supports three conservation programs: the Coral Triangle in Indonesia, the Bonaire Marine Park and the APRA program in the Amazon.",
        creator: "USIF36567893899172020",
        creationDate: "2020-04-25T08:43:54Z",
        imageId: "",
        address: [
          {
            addressId: "ADIF12046125704282020",
            addressType: "work",
            address1: " ",
            address2: " ",
            postalCode: " ",
            state: "Ile-de-france",
            country: "France",
          },
          {
            addressId: "ADIF55269156629252020",
            addressType: "",
            address1: "202",
            address2: "",
            town: "",
            street: "Evert van de Beekstraat",
            postalCode: "1118 CP",
            state: "Noord-Holland",
            country: "Netherlands",
            longitude: "4.7682744",
            latitude: "52.3105386",
          },
          {
            addressId: "ADIF49115749670282020",
            addressType: "work",
            address1: " ",
            address2: " ",
            postalCode: " ",
            state: " ",
            country: "United States",
          },
        ],
        twitter: "We can not find relevant data.",
        linkedin: "https://www.linkedin.com/company/air-france--klm",
        facebook: "We can not find relevant data.",
        creatorName: "Jit  ",
        owner: {
          userId: "USIF36567893899172020",
          creatorId: "USIF39644679864102020",
          organizationId: "OIF56911991357102020",
          creationDate: "2020-04-17T04:40:18Z",
          salutation: "",
          firstName: "Jit",
          middleName: "",
          lastName: "",
          status: "",
          emailValidationInd: true,
          userType: "USER",
          gender: "",
          phoneNo: "",
          mobileNo: "",
          emailId: "jitmishra05@yahoo.com",
          tradeCurrency: "EUR",
          imageId: "IINF63582486689222020",
          department: "Sales",
          countryDialCode: "",
          countryDialCode1: "",
          teamLeadInd: false,
          firstTimeLoginInd: false,
          designation: "",
          level: "L2",
        },
        imageURL: "https://logo.clearbit.com/airfranceklm.com",
        foundedIn: 0,
        enrichInd: true,
      },
    },
    countryDialCode: "",
    countryDialCode1: "",
    departmentDetails: "Rostering",
    accountMapper: {
      accountId: "ACINF57805276417252020",
      accountName: "Air France-KLM",
      accountOwner: "USIF36567893899172020",
      website: "airfranceklm.com",
      tickerSymbol: "",
      tradeCurrency: "",
      companySize: "20000",
      industryType:
        "Local and Suburban Transit and Interurban Highway Passenger Transportation",
      baseCountry: "",
      phone1: "",
      phone2: "",
      fax: "",
      email: "",
      rating: 0,
      sicCode: "",
      annualRevenue: "",
      description:
        "Air France and KLM are involved in conservation projects: The forest conservation project in Madagascar (PHCF), supported by Air France since 2008 and co-funded by the French Development Agency (AFD) and the French Global Environment Facility (FFEM) contributes to the development of local communities while protecting the biodiversity of the island. As part of its partnership with WWF-NL, KLM financially supports three conservation programs: the Coral Triangle in Indonesia, the Bonaire Marine Park and the APRA program in the Amazon.",
      creator: "USIF36567893899172020",
      creationDate: "2020-04-25T08:43:54Z",
      imageId: "",
      twitter: "We can not find relevant data.",
      linkedin: "https://www.linkedin.com/company/air-france--klm",
      facebook: "We can not find relevant data.",
      creatorName: "Jit  ",
      owner: {
        userId: "USIF36567893899172020",
        creatorId: "USIF39644679864102020",
        organizationId: "OIF56911991357102020",
        creationDate: "2020-04-17T04:40:18Z",
        salutation: "",
        firstName: "Jit",
        middleName: "",
        lastName: "",
        status: "",
        emailValidationInd: true,
        userType: "USER",
        gender: "",
        phoneNo: "",
        mobileNo: "",
        emailId: "jitmishra05@yahoo.com",
        tradeCurrency: "EUR",
        imageId: "IINF63582486689222020",
        department: "Sales",
        countryDialCode: "",
        countryDialCode1: "",
        teamLeadInd: false,
        firstTimeLoginInd: false,
        designation: "",
        level: "L2",
      },
      imageURL: "https://logo.clearbit.com/airfranceklm.com",
      foundedIn: 0,
      enrichInd: true,
    },
  },
];

/**
 * set selected time range from time interval contact
 */
export const setSelectedTimeIntervalContact = (selectedTime) => (dispatch) => {
  console.log(selectedTime);
  dispatch({
    type: types.CHANGE_SELECTED_TIME_INTERVAL_CONTACT,
    payload: selectedTime,
  });
};

/**
 * set current Time  contact
 */
export const setTimeRangeContact = (startDate, endDate) => (dispatch) => {
  dispatch({
    type: types.SET_TIME_INTERVAL_CONTACT,
    payload: {
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
    },
  });
};

/**
 * get all the contact of the user
 */
export const getContacts = (userId) => (dispatch) => {
  let api_url = "";
  if (userId) {
    api_url = `/sort/all/contacts/user/${userId}`;
  } else {
    api_url = `/contacts`;
  }
  dispatch({
    type: types.GET_CONTACTS_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CONTACTS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_CONTACTS_FAILURE,
        payload: err,
      });
    });
};

/**
 * get all the contact of the user
 */
export const getContactsForLazyLoading = (userId, page) => (dispatch) => {
  let api_url = "";
  if (userId) {
    api_url = `/all/contacts/user/${userId}/${page}`;
  } else {
    api_url = `/contacts`;
  }
  dispatch({
    type: types.GET_CONTACTS_LAZY_LOADING_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CONTACTS_LAZY_LOADING_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_CONTACTS_LAZY_LOADING_FAILURE,
        payload: err,
      });
    });
};

export const getCatagory = () => (dispatch) => {
  dispatch({
    type: types.GET_CATAGORY_REQUEST,
  });
  axios
    .get(`${base_url}/contact/categoryList`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CATAGORY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_CATAGORY_FAILURE,
        payload: err,
      });
    });
};
const data = [
  {
    contactId: "COIF78081877568162021",
    category: [],
    designation: "",
    department: "",
    creationDate: "2021-01-16T15:48:53Z",
    firstName: "Dominic",
    middleName: "",
    lastName: "P",
    phoneNo: "",
    mobileNo: "",
    emailId: "",
    imageId: "",
    metaData: {
      funnel: {
        weightedFunnel: 0.0,
        actualFunnel: 0.0,
        currency: "EUR",
        for: "Contact",
        id: "COIF78081877568162021",
        totalActualFunnel: 0.0,
        totalWeightedFunnel: 0.0,
        lifeTimeValue: 0.0,
      },
      account: {
        rating: 0.0,
        wonCount: 0,
        lostCount: 0,
        foundedIn: 0,
        enrichInd: false,
      },
    },
    countryDialCode: "+31",
    countryDialCode1: "+31",
    departmentDetails: "",
    wonCount: 0,
    lostCount: 0,
    billing: 0.0,
    availableInd: "No",
  },
];
export const getCatagoryWiseContact = (userId, category, page, newEntry) => (
  dispatch
) => {
  dispatch({
    type: types.GET_CATAGORY_WISE_CONTACT_REQUEST,
  });
  axios
    .get(`${base_url}/contacts/category/${userId}/${category}/${page}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CATAGORY_WISE_CONTACT_SUCCESS,
        payload: { data: res.data, newEntry },
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_CATAGORY_WISE_CONTACT_FAILURE,
        payload: err,
      });
    });
};

export const getCatagoryWiseContactForAndOperation = (data) => (dispatch) => {
  dispatch({
    type: types.GET_CATAGORY_WISE_CONTACT_FOR_AND_OPERATION_SUCCESS,
    payload: data,
  });
};

export const getMapContacts = (userId) => (dispatch) => {
  dispatch({
    type: types.GET_MAP_CONTACTS_REQUEST,
  });
  axios
    .get(`${base_url}/map/contacts/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_MAP_CONTACTS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_MAP_CONTACTS_FAILURE,
        payload: err,
      });
    });
};

/**
 * get all the latest opportunity of the user
 */
export const getLatestContacts = (userId, startDate, endDate) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/sort/contacts/user/${userId}sort=latest`;
  } else {
    api_url = `/sort/contacts/user/${userId}?sort=latest&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_LATEST_CONTACTS_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_LATEST_CONTACTS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_LATEST_CONTACTS_FAILURE,
        payload: err,
      });
    });
};
export const getAllLatestContacts = (userId, startDate, endDate) => (
  dispatch
) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/sort/all/contacts/user/${userId}sort=latest`;
  } else {
    api_url = `/sort/all/contacts/user/${userId}?sort=latest&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_ALL_LATEST_CONTACTS_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ALL_LATEST_CONTACTS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ALL_LATEST_CONTACTS_FAILURE,
        payload: err,
      });
    });
};

//contacts
export const getAllLatestContactsForLazyLoading = (
  userId,
  pageNo,
  startDate,
  endDate
) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/sort/all/contacts/user/${userId}sort=latest`;
  } else {
    api_url = `/myView/report/contact/${pageNo}?userId=${userId}&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_ALL_LATEST_CONTACTS_FOR_LAZY_LOADING_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ALL_LATEST_CONTACTS_FOR_LAZY_LOADING_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ALL_LATEST_CONTACTS_FOR_LAZY_LOADING_FAILURE,
        payload: err,
      });
    });
};
/**
 * get all the latest opportunity of the user
 */
export const getLatestContactsByOrganizationId = (
  pageNo,
  organizationId,
  startDate,
  endDate
) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/sort/contacts/organization/${organizationId}`;
  } else {
    api_url = `/orgView/report/contact/${pageNo}?orgId=${organizationId}&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_LATEST_CONTACTS_BY_ORGANIZATION_ID_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_LATEST_CONTACTS_BY_ORGANIZATION_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_LATEST_CONTACTS_BY_ORGANIZATION_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get all the contact of the user
 */
export const getContactsByFunnelValue = (userId, startDate, endDate) => (
  dispatch
) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/sort/contacts/user/${userId}?sort=funnel`;
  } else {
    api_url = `/sort/contacts/user/${userId}?sort=funnel&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_CONTACTS_BY_FUNNEL_VALUE_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CONTACTS_BY_FUNNEL_VALUE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CONTACTS_BY_FUNNEL_VALUE_FAILURE,
        payload: err,
      });
    });
};
export const getAllContactsByFunnelValue = (userId, startDate, endDate) => (
  dispatch
) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/sort/all/contacts/user/${userId}?sort=funnel`;
  } else {
    api_url = `/sort/all/contacts/user/${userId}?sort=funnel&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_ALL_CONTACTS_BY_FUNNEL_VALUE_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ALL_CONTACTS_BY_FUNNEL_VALUE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ALL_CONTACTS_BY_FUNNEL_VALUE_FAILURE,
        payload: err,
      });
    });
};
/**
 * get all the contacts by time to connect
 */
export const getContactsByTimeToConnect = (userId, startDate, endDate) => (
  dispatch
) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/contact/followup/user/${userId}`;
  } else {
    api_url = `/sort/contact/user/${userId}?sort=funnel&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_CONTACTS_BY_TIME_TO_CONNECT_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CONTACTS_BY_TIME_TO_CONNECT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CONTACTS_BY_TIME_TO_CONNECT_FAILURE,
        payload: err,
      });
    });
};

export const getAllContactsByTimeToConnect = (userId, startDate, endDate) => (
  dispatch
) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/contact/all/followup/user/${userId}`;
  } else {
    api_url = `/sort/all/contact/user/${userId}?sort=funnel&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_ALL_CONTACTS_BY_TIME_TO_CONNECT_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ALL_CONTACTS_BY_TIME_TO_CONNECT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ALL_CONTACTS_BY_TIME_TO_CONNECT_FAILURE,
        payload: err,
      });
    });
};

/**
 * get a specific contact of the user with the contactId
 */
export const getContactById = (contactId) => (dispatch) => {
  console.log("inside add contact");
  dispatch({
    type: types.GET_CONTACT_BY_ID_REQUEST,
  });
  axios
    .get(`${base_url}/contact/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CONTACT_BY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CONTACT_BY_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get list of contacts added by an user
 */
export const getContactListRangeByUserId = (userId, startDate, endDate) => (
  dispatch
) => {
  dispatch({
    type: types.GET_CONTACT_LIST_RANGE_BY_USER_ID_REQUEST,
  });
  axios
    .get(
      `${base_url}/contact/user/${userId}?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CONTACT_LIST_RANGE_BY_USER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CONTACT_LIST_RANGE_BY_USER_ID_FAILURE,
        payload: err,
      });
    });
};

export const getContactListRangeByUserIdForDashboard = (
  userId,
  startDate,
  endDate
) => (dispatch) => {
  dispatch({
    type: types.GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_DASHBOARD_REQUEST,
  });
  axios
    .get(
      `${base_url}/contactsAdded/myView/${userId}?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_DASHBOARD_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_DASHBOARD_FAILURE,
        payload: err,
      });
    });
};

/**
 * get list of contacts of all users of an organization
 */
export const getContactListRangeOfAllUsers = (startDate, endDate) => (
  dispatch
) => {
  dispatch({
    type: types.GET_CONTACT_LIST_RANGE_OF_ALL_USERS_REQUEST,
  });
  axios
    .get(
      `${base_url}/contactsAdded/orgView?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CONTACT_LIST_RANGE_OF_ALL_USERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CONTACT_LIST_RANGE_OF_ALL_USERS_FAILURE,
        payload: err,
      });
    });
};
/**
 * get account list by contactId
 */
export const getAccountListByContactId = (contactId) => (dispatch) => {
  console.log(contactId);
  dispatch({
    type: types.GET_ACCOUNT_LIST_BY_CONTACT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/account/contact/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ACCOUNT_LIST_BY_CONTACT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ACCOUNT_LIST_BY_CONTACT_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get opportunity list by contactId
 */
export const getOpportunityListByContactId = (contactId) => (dispatch) => {
  console.log(contactId);
  dispatch({
    type: types.GET_OPPORTUNITY_LIST_BY_CONTACT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/opportunity/contact/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_OPPORTUNITY_LIST_BY_CONTACT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_OPPORTUNITY_LIST_BY_CONTACT_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get opportunity list by contactId
 */
export const getFunnelValueByContactId = (contactId) => (dispatch) => {
  console.log(contactId);
  dispatch({
    type: types.FUNNEL_VALUE_BY_CONTACT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/funnel/contact/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.FUNNEL_VALUE_BY_CONTACT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.FUNNEL_VALUE_BY_CONTACT_ID_FAILURE,
        payload: err,
      });
    });
};
export const setEditNoteByContactId = (name) => (dispatch) => {
  dispatch({
    type: types.SET_NOTE_EDIT_BY_CONTACT_ID,
    payload: name,
  });
};

/**
 * get notes list by contactId
 */
export const getNotesListByContactId = (contactId) => (dispatch) => {
  console.log(contactId);
  dispatch({
    type: types.GET_NOTES_LIST_BY_CONTACT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/note/contact/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_NOTES_LIST_BY_CONTACT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_NOTES_LIST_BY_CONTACT_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get call list by contactId
 */
export const getCallsListByContactId = (contactId) => (dispatch) => {
  console.log(contactId);
  dispatch({
    type: types.GET_CALLS_LIST_BY_CONTACT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/call/contact/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CALLS_LIST_BY_CONTACT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CALLS_LIST_BY_CONTACT_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get topics of interest by contactId
 */
export const getTopicsByContactId = (contactId) => (dispatch) => {
  dispatch({
    type: types.GET_TOPICS_BY_CONTACT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/topic/contact/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_TOPICS_BY_CONTACT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_TOPICS_BY_CONTACT_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * add topic of a contactId
 */
export const addTopicByContactId = (topic) => (dispatch) => {
  console.log(topic);
  dispatch({
    type: types.ADD_TOPIC_BY_CONTACT_ID_REQUEST,
  });
  axios
    .post(`${base_url}/topic`, topic, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.ADD_TOPIC_BY_CONTACT_ID_SUCCESS,
        payload: { ...topic, topicId: res.data },
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_TOPIC_BY_CONTACT_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * delete topics of interest by contactId
 */
export const deleteTopicByContactId = (topicId, contactId) => (dispatch) => {
  dispatch({
    type: types.DELETE_TOPIC_BY_CONTACT_ID_REQUEST,
  });
  axios
    .delete(`${base_url}/topic/${topicId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.DELETE_TOPIC_BY_CONTACT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.DELETE_TOPIC_BY_CONTACT_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * add catagory of a contactId
 */
export const addCatagoryByContactId = (category) => (dispatch) => {
  console.log(category);
  dispatch({
    type: types.ADD_CATAGORY_BY_CONTACT_ID_REQUEST,
  });
  axios
    .post(`${base_url}/contact/category`, category, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.ADD_CATAGORY_BY_CONTACT_ID_SUCCESS,
        payload: { ...category, categoryId: res.data },
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_CATAGORY_BY_CONTACT_ID_FAILURE,
        payload: err,
      });
    });
};

//get catagory
export const getCatagoryByContactId = (contactId) => (dispatch) => {
  dispatch({
    type: types.GET_CATAGORY_BY_CONTACT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/category/contact/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CATAGORY_BY_CONTACT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CATAGORY_BY_CONTACT_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * delete category of interest by contactId
 */
export const deleteCategoryByContactId = (categoryId, contactId) => (
  dispatch,
  getState
) => {
  const contactId = getState().contact.contact.contactId;
  dispatch({
    type: types.DELETE_CATAGORY_BY_CONTACT_ID_REQUEST,
  });
  axios
    .delete(`${base_url}/category/${categoryId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(getCatagoryByContactId(contactId));
      dispatch({
        type: types.DELETE_CATAGORY_BY_CONTACT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.DELETE_CATAGORY_BY_CONTACT_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get task list by contactId
 */
export const getTasksListByContactId = (contactId) => (dispatch) => {
  console.log(contactId);
  dispatch({
    type: types.GET_TASKS_LIST_BY_CONTACT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/task/contact/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_TASKS_LIST_BY_CONTACT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_TASKS_LIST_BY_CONTACT_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * get event list by contactId
 */
export const getEventsListByContactId = (contactId) => (dispatch) => {
  console.log(contactId);
  dispatch({
    type: types.GET_EVENTS_LIST_BY_CONTACT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/event/contact/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_EVENTS_LIST_BY_CONTACT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_EVENTS_LIST_BY_CONTACT_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * request for adding a contact
 */
export const addContact = (contact, cb) => (dispatch, getState) => {
  const userId = getState().auth.userDetails.userId;

  const opportunityId = getState().opportunity.opportunity.opportunityId;
  console.log("inside add contact");
  dispatch({
    type: types.ADD_CONTACT_REQUEST,
  });

  axios
    .post(`${base_url}/contact`, contact, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      // dispatch(
      //   linkContactsToOpportunity(opportunityId, { contactIds: [res.data] }, cb)
      // );
      const startDate = moment()
        .startOf("month")
        .toISOString();
      const endDate = moment()
        .endOf("month")
        .toISOString();
      dispatch(getContacts(userId));
      dispatch(getLatestContacts(userId, startDate, endDate));
      dispatch(getContactsByFunnelValue(userId));
      dispatch({
        type: types.ADD_CONTACT_SUCCESS,
        payload: res.data,
      });
      cb && cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_CONTACT_FAILURE,
        payload: err,
      });
      cb && cb();
    });
};

export const addLinkContactByOpportunityId = (contact, opportunityId, cb) => (
  dispatch,
  getState
) => {
  // const userId = getState().auth.userDetails.userId;
  console.log("inside addLinkContactByOpportunityId contact");
  dispatch({
    type: types.ADD_LINK_CONTACT_BY_OPPORTUNITY_ID_REQUEST,
  });

  axios
    .post(`${base_url}/link/cotact/${opportunityId}`, contact, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      // // dispatch(
      // //   linkContactsToOpportunity(opportunityId, { contactIds: [res.data] }, cb)
      // // );
      // const startDate = moment()
      //   .startOf("month")
      //   .toISOString();
      // const endDate = moment()
      //   .endOf("month")
      //   .toISOString();
      // dispatch(getContacts(userId));
      // dispatch(getLatestContacts(userId, startDate, endDate));
      // dispatch(getContactsByFunnelValue(userId));
      dispatch({
        type: types.ADD_LINK_CONTACT_BY_OPPORTUNITY_ID_SUCCESS,
        payload: res.data,
      });
      cb && cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_LINK_CONTACT_BY_OPPORTUNITY_ID_FAILURE,
        payload: err,
      });
      cb && cb();
    });
};

/**
 * update aspecific field using put request
 */
export const updateContact = (id, data, cb) => (dispatch) => {
  console.log(data);
  dispatch({ type: types.UPDATE_CONTACT_BY_ID_REQUEST });
  axios
    .put(
      `${base_url}/contact/${id}`,
      { ...data },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_CONTACT_BY_ID_SUCCESS,
        payload: res.data,
      });
      cb && cb("Success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.UPDATE_CONTACT_BY_ID_FAILURE,
        payload: err,
      });
      cb && cb("Failure");
    });
};

/**
 * delete a contact by Id
 */
export const deleteContact = (id, cb) => (dispatch) => {
  dispatch({ type: types.DELETE_CONTACT_BY_ID_REQUEST });
  axios
    .delete(`${base_url}/contact/${id}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.DELETE_CONTACT_BY_ID_SUCCESS,
        payload: id,
      });
      cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.DELETE_CONTACT_BY_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 * contact modal action
 */
export const handleContactModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_CONTACT_MODAL,
    payload: modalProps,
  });
};

/**
 * account link modal action
 */
export const handleLinkContactModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_LINK_CONTACT_MODAL,
    payload: modalProps,
  });
};

/**
 * contact modal drawer
 */
export const handleContactDrawer = (drawerProps, isVisible) => (dispatch) => {
  dispatch({
    type: types.HANDLE_CONTACT_DRAWER,
    payload: { props: drawerProps, isVisible: isVisible },
  });
};

/**
 * SET CONTACT VIEW TYPE
 * TABLE VIEW/CARD VIEW
 */
export const setContactViewType = (viewType) => (dispatch) =>
  dispatch({ type: types.SET_CONTACT_VIEW_TYPE, payload: viewType });

/**
 * SET CONTACT VIEW TYPE
 * TABLE VIEW/CARD VIEW
 */
export const setContactFilterText = (contactFilterText) => (dispatch) =>
  dispatch({ type: types.SET_CONTACT_FILTER_TEXT, payload: contactFilterText });

/**
 * set contact filter by User
 */
export const setContactFilterUser = (user) => (dispatch) =>
  dispatch({ type: types.SET_CONTACT_FILTER_USER, payload: user });

/**
 * unlink a opportunity from opportunity
 */
export const linkAccountToContact = (contactId, accountId, cb) => (
  dispatch
) => {
  console.log(contactId, accountId);
  dispatch({ type: types.LINK_ACCOUNT_TO_CONTACT_REQUEST });
  axios
    .post(`${base_url}/contact/${contactId}/account/${accountId}`, [], {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.LINK_ACCOUNT_TO_CONTACT_SUCCESS,
        payload: res.data,
      });
      cb(accountId);
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: types.LINK_ACCOUNT_TO_CONTACT_FAILURE });
      cb();
    });
};

/**
 * unlink a account from a contact
 */
export const unlinkAccountFromContact = (contactId, accountId, cb) => (
  dispatch
) => {
  console.log(contactId, accountId);
  dispatch({ type: types.UNLINK_ACCOUNT_FROM_CONTACT_REQUEST });

  axios
    .delete(`${base_url}/contact/${contactId}/account/${accountId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UNLINK_ACCOUNT_FROM_CONTACT_SUCCESS,
        payload: res.data,
      });
      cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: types.UNLINK_ACCOUNT_FROM_CONTACT_FAILURE });
      cb();
    });
};

/**
 * unlink a opportunity from contact
 */
export const linkOpportunitiesToContact = (contactId, associations, cb) => (
  dispatch
) => {
  console.log(contactId, associations, cb);
  // axios.delete(`${base_url}/contact/${contactId}/opportunity/${opportunityId}`, {
  //     headers: {
  //         'Authorization': 'Bearer ' + sessionStorage.getItem('token') || ''
  //     }
  // })
  //     .then(res => {
  //         console.log(res)
  //         dispatch({
  //             type: types.UPDATE_OPPORTUNITY_LIST_BY_CONTACT_ID_SUCCESS,
  //             payload: opportunityId
  //         })
  //     })
  //     .catch(err => console.log(err))
};
/**
 * unlink a opportunity from contact
 */
export const unlinkOpportunityFromContact = (contactId, opportunityId) => (
  dispatch
) => {
  console.log(contactId, opportunityId);
  axios
    .delete(`${base_url}/contact/${contactId}/opportunity/${opportunityId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_OPPORTUNITY_LIST_BY_CONTACT_ID_SUCCESS,
        payload: opportunityId,
      });
    })
    .catch((err) => console.log(err));
};

/**
 * unlink a call from contact
 */
export const unlinkCallFromContact = (contactId, callId) => (dispatch) => {
  console.log(contactId, callId);
  axios
    .delete(`${base_url}/contact/${contactId}/call/${callId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_CALLS_LIST_BY_CONTACT_ID_SUCCESS,
        payload: callId,
      });
    })
    .catch((err) => console.log(err));
};

/**
 * unlink a task from contact
 */
export const unlinkTaskFromContact = (contactId, taskId) => (dispatch) => {
  console.log(contactId, taskId);
  axios
    .delete(`${base_url}/contact/${contactId}/task/${taskId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_TASKS_LIST_BY_CONTACT_ID_SUCCESS,
        payload: taskId,
      });
    })
    .catch((err) => console.log(err));
};

/**
 * unlink a event from contact
 */
export const unlinkEventFromContact = (contactId, eventId) => (dispatch) => {
  console.log(contactId, eventId);
  axios
    .delete(`${base_url}/contact/${contactId}/event/${eventId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.UPDATE_EVENTS_LIST_BY_CONTACT_ID_SUCCESS,
        payload: eventId,
      });
    })
    .catch((err) => console.log(err));
};

/**
 * update contact address
 * @param {string} contactId
 * @param {address object} address
 */
export const updateContactAddress = (contactId, address) => (dispatch) => {
  console.log(contactId, address);
  dispatch({
    type: types.UPDATE_CONTACT_ADDRESS,
    payload: {
      contactId,
      address,
    },
  });
};

export const getRoleChartDataByContactId = (contactId) => (dispatch) => {
  console.log(contactId);
  dispatch({
    type: types.GET_ROLE_CHART_DATA_BY_CONTACT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/chart/contact/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ROLE_CHART_DATA_BY_CONTACT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ROLE_CHART_DATA_BY_CONTACT_ID_FAILURE,
        payload: err,
      });
    });
};

export const addContactDocument = (documentId, data, cb) => (
  dispatch,
  getState
) => {
  const contactId = getState().contact.contact.contactId;

  console.log(data);
  dispatch({ type: types.ADD_CONTACT_DOCUMENT_REQUEST });
  axios
    .put(`${base_url}/opportunity/document/${documentId}`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(getContactDocument(contactId));
      dispatch({
        type: types.ADD_CONTACT_DOCUMENT_SUCCESS,
        payload: res.data,
      });
      cb && cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_CONTACT_DOCUMENT_FAILURE,
        payload: err,
      });
      cb && cb();
    });
};

export const getContactDocument = (contactId) => (dispatch) => {
  dispatch({ type: types.GET_CONTACT_DOCUMENTS_REQUEST });
  axios
    .get(`${base_url}/document/contact/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CONTACT_DOCUMENTS_SUCCESS,
        payload: res.data,
      });
      // cb();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CONTACT_DOCUMENTS_FAILURE,
        payload: err,
      });
    });
};

export const LinkStageOpportunityByContactId = (contactId, data) => (
  dispatch
) => {
  dispatch({ type: types.LINK_STAGE_TO_OPPORTUNITY_BY_CONTACT_ID_REQUEST });

  axios
    .patch(`${base_url}/link/stages/conatct/opportunity/${contactId} `, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })

    .then((res) => {
      console.log(res);
      dispatch({
        type: types.LINK_STAGE_TO_OPPORTUNITY_BY_CONTACT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.LINK_STAGE_TO_OPPORTUNITY_BY_CONTACT_ID_FAILURE,
      });
    });
};

export const getDesignation = () => (dispatch) => {
  dispatch({
    type: types.GET_DESIGNATION_REQUEST,
  });
  axios
    .get(`${base_url}/filter/designation`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_DESIGNATION_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_DESIGNATION_FAILURE,
        payload: err,
      });
    });
};

export const getContactQuote = (contactId) => (dispatch, getState) => {
  dispatch({
    type: types.GET_CONTACT_QUOTE_REQUEST,
  });

  axios
    .get(`${base_url}/contact/quotes/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      dispatch({
        type: types.GET_CONTACT_QUOTE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CONTACT_QUOTE_FAILURE,
        payload: err,
      });
    });
};

// get contact order

export const getContactOrder = (contactId) => (dispatch, getState) => {
  dispatch({
    type: types.GET_CONTACT_ORDER_REQUEST,
  });

  axios
    .get(`${base_url}/contact/order/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      dispatch({
        type: types.GET_CONTACT_ORDER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CONTACT_ORDER_FAILURE,
        payload: err,
      });
    });
};




export const handleAbsoluteModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_ABSOLUTE_MODAL,
    payload: modalProps,
  });
};

export const handleWinModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_WIN_MODAL,
    payload: modalProps,
  });
};

export const handleMeetingsModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_MEETINGS_MODAL,
    payload: modalProps,
  });
};

export const handleCallsModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_CALLS_MODAL,
    payload: modalProps,
  });
};

export const handlePieModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_PIE_MODAL,
    payload: modalProps,
  });
};

export const emptyContactData = () => (dispatch) => {
  dispatch({
    type: types.EMPTY_CONTACT_DATA,
  });
};

export const handleContactCustomeFieldModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_CONTACT_CUSTOME_FIELD_MODAL,
    payload: modalProps,
  });
};

export const getNoOfEmailsSent = () => (dispatch, getState) => {
  dispatch({
    type: types.GET_NO_OF_EMAILS_SENT_REQUEST,
  });

  axios
    .get(`${base_url}/contact/quotes`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      dispatch({
        type: types.GET_NO_OF_EMAILS_SENT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_NO_OF_EMAILS_SENT_FAILURE,
        payload: err,
      });
    });
};

export const cleanUpContacts = (data, userId) => (dispatch) => {
  dispatch({
    type: types.CLEANUP_CONTACT_REQUEST,
  });
  axios
    .post(
      `${base_url}/delete/contacts`,
      { ids: data },
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token") || "",
        },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch(emptyContactData());
      dispatch(getContactsForLazyLoading(userId, 0));
      message.success(`Successfully Deleted!`);

      dispatch({
        type: types.CLEANUP_CONTACT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.CLEANUP_CONTACT_FAILURE,
        payload: err,
      });
    });
};

//total no of records

export const getRecords = (userId) => (dispatch) => {
  dispatch({
    type: types.GET_RECORDS_REQUEST,
  });
  axios
    .get(`${base_url}/user/total-resource-list/${userId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_RECORDS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_RECORDS_FAILURE,
        payload: err,
      });
    });
};

//ADD CUSTOME FIELD FORM
export const addCustomeFieldForm = (data, resourceName, resourceId, tabId) => (
  dispatch
) => {
  dispatch({
    type: types.ADD_CUSTOME_FIELD_FORM_REQUEST,
  });
  axios
    .post(`${base_url}/custom-fields-value`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(getCustomeFieldData(resourceName, resourceId, tabId));
      dispatch({
        type: types.ADD_CUSTOME_FIELD_FORM_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.ADD_CUSTOME_FIELD_FORM_FAILURE,
        payload: err,
      });
    });
};

//get customefield
export const getCustomeFieldData = (resourceName, resourceId, tabId) => (
  dispatch
) => {
  dispatch({
    type: types.GET_CUSTOME_FIELD_REQUEST,
  });
  axios
    .get(`${base_url}/custom-fields/${resourceName}/${resourceId}/${tabId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CUSTOME_FIELD_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_CUSTOME_FIELD_FAILURE,
        payload: err,
      });
    });
};
//timeline dATA BY CONTACT ID

export const getTimeLineDataByContactId = (contactId) => (dispatch) => {
  dispatch({
    type: types.GET_TIMELINE_DATA_BY_CONTACT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/timeline/contact/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_TIMELINE_DATA_BY_CONTACT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_TIMELINE_DATA_BY_CONTACT_ID_FAILURE,
        payload: err,
      });
    });
};

export const handleContactActivityModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_CONTACT_ACTIVITY_MODAL,
    payload: modalProps,
  });
};

export const getContactActivityData = () => (dispatch) => {
  dispatch({
    type: types.GET_CONTACT_ACTIVITY_DATA_REQUEST,
  });
  axios
    .get(`${base_url}/contact/categoryList`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CONTACT_ACTIVITY_DATA_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: types.GET_CONTACT_ACTIVITY_DATA_FAILURE,
        payload: err,
      });
    });
};

//
export const getContactListOpportunityRangeByUserIdForReport = (
  userId,
  type,
  startDate,
  endDate
) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/report/call/${userId}`;
  } else {
    api_url = `/myView/report/contact?userId=${userId}&type=${type}&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type: types.GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CONTACT_LIST_RANGE_BY_USER_ID_FOR_REPORT_FAILURE,
        payload: err,
      });
    });
};

export const getContactListByOrganisationOpportunityRangeByOrgIdForReport = (
  orgId,
  type,
  startDate,
  endDate
) => (dispatch) => {
  let api_url = "";
  if (startDate === undefined || endDate === undefined) {
    api_url = `/report/call/${orgId}`;
  } else {
    api_url = `/orgView/report/contact?orgId=${orgId}&type=${type}&startDate=${startDate}&endDate=${endDate}`;
  }
  dispatch({
    type:
      types.GET_CONTACT_LIST_BY__RANGE_BY_ORGANISATION_ID_FOR_REPORT_REQUEST,
  });
  axios
    .get(`${base_url}${api_url}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type:
          types.GET_CONTACT_LIST_BY__RANGE_BY_ORGANISATION_ID_FOR_REPORT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type:
          types.GET_CONTACT_LIST_BY__RANGE_BY_ORGANISATION_ID_FOR_REPORT_FAILURE,
        payload: err,
      });
    });
};

export const inputDataSearch = (name) => (dispatch) => {
  dispatch({
    type: types.INPUT_SEARCH_DATA_REQUSET,
  });
  axios
    .get(`${base_url}/contact/Name/${name}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      if (res.data.contactId) {
        console.log(res.data);
        // dispatch(getAllLatestContactsForLazyLoading(res.data));
      }

      dispatch({
        type: types.INPUT_SEARCH_DATA_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.INPUT_SEARCH_DATA_FAILURE,
        payload: err,
      });
    });
};

export const DeletesuspendContact = (contactId, cb) => (dispatch, getState) => {
  const userId = getState().auth.userDetails.userId;
  dispatch({
    type: types.SUSPEND_CONTACT_REQUSET,
  });
  axios
    .delete(`${base_url}/suspend/contact/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      const startDate = moment()
        .startOf("month")
        .toISOString();
      const endDate = moment()
        .endOf("month")
        .toISOString();

      console.log(res.data);
      dispatch(getLatestContacts(userId, startDate, endDate));
      dispatch(getContactsByFunnelValue(userId));
      dispatch(getContactsByTimeToConnect(userId));

      dispatch({
        type: types.SUSPEND_CONTACT_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      dispatch({
        type: types.SUSPEND_CONTACT_FAILURE,
        payload: err,
      });
      cb && cb("failure");
    });
};

export const handleContactOrderModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_CONTACT_ORDER_MODAL,
    payload: modalProps,
  });
};

export const handleLinkContactOrderConfigureModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_LINK_CONTACT_ORDER_CONFIGURE_MODAL,
    payload: modalProps,
  });
};

// order product
// get type

export const getProductsByContactId = (contactId) => (dispatch) => {
  dispatch({
    type: types.FETCHING_PROFESSIONALDUCTS_BY_CONTACT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/account/productDetails/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.FETCHING_PROFESSIONALDUCTS_BY_CONTACT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.FETCHING_PROFESSIONALDUCTS_BY_CONTACT_ID_FAILURE,
        payload: err,
      });
    });
};

//post

export const linkProductToContact = (data, contactId) => (
  dispatch
) => {
  console.log(contactId);
  dispatch({ type: types.LINK_PRODUCT_TO_CONTACT_REQUEST });
  axios
    .post(`${base_url}/contact/product`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(fetchingNewOrder(contactId));
      dispatch({
        type: types.LINK_PRODUCT_TO_CONTACT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.LINK_PRODUCT_TO_CONTACT_FAILURE,
      });
    });
};

// get contact service

export const getServicesByContactId = (contactId) => (dispatch) => {
  dispatch({
    type: types.FETCHING_PROFESSIONAL_SERVICE_BY_CONTACT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/contact/productDetails/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.FETCHING_PROFESSIONAL_SERVICE_BY_CONTACT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.FETCHING_PROFESSIONAL_SERVICE_BY_CONTACT_ID_FAILURE,
        payload: err,
      });
    });
};

// link service page

export const linkServiceToContact = (data, contactId) => (
  dispatch
) => {
  console.log(contactId);
  dispatch({ type: types.LINK_SERVICE_TO_CONTACT_REQUEST });
  axios
    .post(`${base_url}/contact/service`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch(fetchingNewServiceOrder(contactId));
      dispatch({
        type: types.LINK_SERVICE_TO_CONTACT_SUCCESS,
        payload: res.data,
      });

    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.LINK_SERVICE_TO_CONTACT_FAILURE,
      });

    });
};

// get product

export const fetchingNewOrder = (contactId) => (dispatch) => {
  dispatch({
    type: types.FETCHING_NEW_ORDER_LIST_REQUEST,
  });
  axios
    .get(`${base_url}/contact/product/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.FETCHING_NEW_ORDER_LIST_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.FETCHING_NEW_ORDER_LIST_FAILURE,
        payload: err,
      });
    });
};
// get service

export const fetchingNewServiceOrder = (contactId) => (dispatch) => {
  dispatch({
    type: types.FETCHING_NEW_ORDER_SERVICE_LIST_REQUEST,
  });
  axios
    .get(`${base_url}/contact/service/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.FETCHING_NEW_ORDER_SERVICE_LIST_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.FETCHING_NEW_ORDER_SERVICE_LIST_FAILURE,
        payload: err,
      });
    });
};


export const handleContactSubscriptionConfigureModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_CONTACT_SUBSCRIPTION_CONFIGURE_MODAL,
    payload: modalProps,
  });
};

/**
 *  generate order with subscription
 */

export const generateOrderByContactId = (data, cb) => (dispatch) => {
  // //debugger;
  dispatch({ type: types.GENERATE_ORDER_BY_CONTACT_ID_REQUEST });
  axios
    .post(`${base_url}/contact/order`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GENERATE_ORDER_BY_CONTACT_ID_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GENERATE_ORDER_BY_CONTACT_ID_FAILURE,
      });
      cb && cb("failure");
    });
};
/**
 * renewal button
 */
export const handleRenewalButtonModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_RENEWAL_BUTTON_MODAL,
    payload: modalProps,
  });
};

/**
* pause button
*/
export const handlePauseButtonModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_PAUSE_BUTTON_MODAL,
    payload: modalProps,
  });
};

/**
 * Get Product Order Details
 */
export const getProductOrderDetailsById = (orderId) => (dispatch) => {
  dispatch({
    type: types.GET_PRODUCT_ORDER_DETAILS_BY_ID_REQUEST,
  });
  axios
    .get(`${base_url}/contact-order/${orderId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PRODUCT_ORDER_DETAILS_BY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_PRODUCT_ORDER_DETAILS_BY_ID_FAILURE,
        payload: err,
      });
    });
};
/**
 * Link pause in contact
 */
export const linkPauseOrder = (data, cb) => (dispatch) => {
  //debugger;
  dispatch({ type: types.LINK_PAUSE_BY_CONTACT_ID_REQUEST });
  axios
    .post(`${base_url}/order/pause-order`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);

      dispatch({
        type: types.LINK_PAUSE_BY_CONTACT_ID_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.LINK_PAUSE_BY_CONTACT_ID_FAILURE,
      });
      cb && cb("failure");
    });
};

/**
 * Link Renewal in contact
 */
export const linkRenewalOrder = (data, cb) => (dispatch) => {
  dispatch({ type: types.LINK_RENEWAL_BY_CONTACT_ID_REQUEST });
  axios
    .post(`${base_url}/order/renew-order`, data, {})
    .then((res) => {
      console.log(res);

      dispatch({
        type: types.LINK_RENEWAL_BY_CONTACT_ID_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.LINK_RENEWAL_BY_CONTACT_ID_FAILURE,
      });
      cb && cb("failure");
    });
};

export const getFeedbackByOrderId = (orderId) => (dispatch) => {
  dispatch({
    type: types.GET_FEEDBACK_BY_ORDER_ID_REQUEST,
  });
  axios
    .get(`${base_url}/order/feedback/${orderId}`)
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_FEEDBACK_BY_ORDER_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_FEEDBACK_BY_ORDER_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 *  Contact Table feedback
 */
export const getFeedbackByContactId = (contactId) => (dispatch) => {
  dispatch({
    type: types.GET_FEEDBACK_BY_CONTACT_ID_REQUEST,
  });
  axios
    .get(`${base_url}/feedback/contact/${contactId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_FEEDBACK_BY_CONTACT_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_FEEDBACK_BY_CONTACT_ID_FAILURE,
        payload: err,
      });
    });
};

/**
 *  feedback-card modal
 */
export const handleFeedbackModal = (modalProps) => (dispatch) => {
  dispatch({
    type: types.HANDLE_FEEDBACK_MODAL,
    payload: modalProps,
  });
}

/**
 *  getContactHistory
 */


export const getContactHistory = (contactId) => (dispatch) => {
  dispatch({
    type: types.GET_CONTACT_HISTORY_REQUEST,
  });
  axios
    .get(`${base_url}/contactHistory/${contactId}`)
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_CONTACT_HISTORY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_CONTACT_HISTORY_FAILURE,
        payload: err,
      });
    });
};

//CONTACT PERMISSION SHARE Of Partner
export const shareContactPermission = (data) => (dispatch, getState) => {
  // const { userId } = getState("auth").auth.userDetails;
  dispatch({
    type: types.ADD_SHARE_CONTACT_PERMISSION_REQUEST,
  });

  axios
    // .post(`${base_url}/permission/details`, data, {
    //   headers: {
    //     Authorization: "Bearer " + sessionStorage.getItem("token") || "",
    //   },
    // })
    .then((res) => {
      console.log(res);
      // dispatch(getCustomerListByUserId(userId));
      dispatch({
        type: types.ADD_SHARE_CONTACT_PERMISSION_SUCCESS,
        payload: res.data,
      });
      // cb && cb("success");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_SHARE_CONTACT_PERMISSION_FAILURE,
        payload: err,
      });
      // cb && cb("failure");
    });
};

export const getPermissionsListContact = () => (dispath) => {
  dispath({ type: types.GET_PERMISSIONS_LIST_CONTACT_REQUEST });
  axios
    // .get(`${base_url}/permission/type?type=${"customer"}`, {
    //   headers: {
    //     Authorization: "Bearer " + sessionStorage.getItem("token") || "",
    //   },
    // })
    .then((res) => {
      dispath({
        type: types.GET_PERMISSIONS_LIST_CONTACT_SUCCESS,
        payload: res.data,
      });

    })
    .catch((err) => {
      dispath({
        type: types.GET_PERMISSIONS_LIST_CONTACT_FAILURE,
        payload: err,
      });
    });
};

