export const GET_STORYlINE_PROCESS_REQUEST = "GET_STORYlINE_PROCESS_REQUEST";
export const GET_STORYlINE_PROCESS_SUCCESS = "GET_STORYlINE_PROCESS_SUCCESS";
export const GET_STORYlINE_PROCESS_FAILURE = "GET_STORYlINE_PROCESS_FAILURE";

export const GET_STORYLINE_PROCESS_STAGES_REQUEST = "GET_STORYLINE_PROCESS_STAGES_REQUEST";
export const GET_STORYLINE_PROCESS_STAGES_SUCCESS = "GET_STORYLINE_PROCESS_STAGES_SUCCESS";
export const GET_STORYLINE_PROCESS_STAGES_FAILURE = "GET_STORYLINE_PROCESS_STAGES_FAILURE";

export const ADD_STORYLINE_PROCESS_REQUEST = "ADD_STORYLINE_PROCESS_REQUEST";
export const ADD_STORYLINE_PROCESS_SUCCESS = "ADD_STORYLINE_PROCESS_SUCCESS";
export const ADD_STORYLINE_PROCESS_FAILURE = "ADD_STORYLINE_PROCESS_FAILURE";

export const ADD_STORYLINE_PROCESS_STAGE_REQUEST = "ADD_STORYLINE_PROCESS_STAGE_REQUEST";
export const ADD_STORYLINE_PROCESS_STAGE_SUCCESS = "ADD_STORYLINE_PROCESS_STAGE_SUCCESS";
export const ADD_STORYLINE_PROCESS_STAGE_FAILURE = "ADD_STORYLINE_PROCESS_STAGE_FAILURE";

export const UPDATE_STORYLINE_PROCESS_REQUEST = "UPDATE_STORYLINE_PROCESS_REQUEST";
export const UPDATE_STORYLINE_PROCESS_SUCCESS = "UPDATE_STORYLINE_PROCESS_SUCCESS";
export const UPDATE_STORYLINE_PROCESS_FAILURE = "UPDATE_STORYLINE_PROCESS_FAILURE";

export const UPDATE_STORYLINE_STAGE_REQUEST = "UPDATE_STORYLINE_STAGE_REQUEST";
export const UPDATE_STORYLINE_STAGE_SUCCESS = "UPDATE_STORYLINE_STAGE_SUCCESS";
export const UPDATE_STORYLINE_STAGE_FAILURE = "UPDATE_STORYLINE_STAGE_FAILURE";

export const HANDLE_STORYLINE_MODAL = "HANDLE_STORYLINE_MODAL";




// get url--- getStoryStagesbystoryLineprocessId
// post url--- storyLineProcessAdd
//post url --- storyLineStageAdd
// put url--- storyLineProcessNameUpdate
//put url --- storyLineStageUpdate