export const GET_CUSTOM_TABLE_NAMES_REQUEST = "GET_CUSTOM_TABLE_NAMES_REQUEST";
export const GET_CUSTOM_TABLE_NAMES_SUCCESS = "GET_CUSTOM_TABLE_NAMES_SUCCESS";
export const GET_CUSTOM_TABLE_NAMES_FAILURE = "GET_CUSTOM_TABLE_NAMES_FAILURE";

export const GET_CUSTOM_COLUMN_NAMES_REQUEST =
  "GET_CUSTOM_COLUMN_NAMES_REQUEST";
export const GET_CUSTOM_COLUMN_NAMES_SUCCESS =
  "GET_CUSTOM_COLUMN_NAMES_SUCCESS";
export const GET_CUSTOM_COLUMN_NAMES_FAILURE =
  "GET_CUSTOM_COLUMN_NAMES_FAILURE";
