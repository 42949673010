import * as types from "./QuoteActionTypes";
const initialState = {
  viewType: "First",

  fetchingMobileSearchData: false,
  fetchinngMobileSearchDataError: false,
  mobileData: {},
  serachedData: null,

  addingContactForQuote: false,
  addingContactForQuoteError: false,
  QuoteContact: [],

  fetchingExistingQuote: false,
  fetchingExistingQuoteError: false,
  existingQuote: [],

  cancelingQuote: false,
  cancelingQuoteError: false,

  fetchingQuotesOpen: false,
  fetchingQuotesOpenError: false,
  quotesOpen: null,

  fetchingQuotesClose: false,
  fetchingQuotesCloseError: false,
  quotesClose: null,

  fetchingQuotesAverageTime: false,
  fetchingQuotesAverageTimeError: false,
  quotesAverageTime: null,
};
export const quoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_QUOTE_VIEW_TYPE:
      return { ...state, viewType: action.payload };
    case types.GET_DATA_OR_NOT:
      return {
        ...state,
        serachedData: action.payload.contactId ? action.payload : null,
      };

    case types.MOBILE_SEARCH_DATA_REQUSET:
      return { ...state, fetchingMobileSearchData: true };
    case types.MOBILE_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        fetchingMobileSearchData: false,
        mobileData: action.payload,
        serachedData: action.payload,
      };
    case types.MOBILE_SEARCH_DATA_FAILURE:
      return { ...state, fetchinngMobileSearchDataError: true };

    case types.ADD_CONTACT_FOR_QUOTE_REQUEST:
      return { ...state, addingContactForQuote: true };
    case types.ADD_CONTACT_FOR_QUOTE_SUCCESS:
      return {
        ...state,
        addingContactForQuote: false,
        QuoteContact: action.payload,
      };
    case types.ADD_CONTACT_FOR_QUOTE_FAILURE:
      return {
        ...state,
        addingContactForQuote: false,
        addingContactForQuoteError: true,
      };

    case types.GET_EXISTING_QUOTE_REQUEST:
      return { ...state, fetchingExistingQuote: true };
    case types.GET_EXISTING_QUOTE_SUCCESS:
      return {
        ...state,
        fetchingExistingQuote: false,
        existingQuote: [...state.existingQuote, ...action.payload],
      };
    case types.GET_EXISTING_QUOTE_FAILURE:
      return {
        ...state,
        fetchingExistingQuote: false,
        fetchingExistingQuoteError: true,
      };

    case types.CANCEL_QUOTE_REQUEST:
      return { ...state, cancelingQuote: true };
    case types.CANCEL_QUOTE_SUCCESS:
      return {
        ...state,
        cancelingQuote: false,
      };
    case types.CANCEL_QUOTE_FAILURE:
      return {
        ...state,
        cancelingQuote: false,
        cancelingQuoteError: true,
      };

    case types.DATA_CLEAR:
      return { ...state, mobileData: {}, serachedData: null };
    case types.GET_QUOTES_OPEN_REQUEST:
      return { ...state, fetchingQuotesOpen: true };
    case types.GET_QUOTES_OPEN_SUCCESS:
      return {
        ...state,
        fetchingQuotesOpen: false,
        quotesOpen: action.payload,
      };
    case types.GET_QUOTES_OPEN_FAILURE:
      return {
        ...state,
        fetchingQuotesOpen: false,
        fetchingQuotesOpenError: true,
      };

    case types.GET_QUOTES_CLOSE_REQUEST:
      return { ...state, fetchingQuotesClose: true };
    case types.GET_QUOTES_CLOSE_SUCCESS:
      return {
        ...state,
        fetchingQuotesClose: false,
        quotesClose: action.payload,
      };
    case types.GET_QUOTES_CLOSE_FAILURE:
      return {
        ...state,
        fetchingQuotesClose: false,
        fetchingQuotesCloseError: true,
      };

    case types.GET_QUOTES_AVERAGE_TIME_REQUEST:
      return { ...state, fetchingQuotesAverageTime: true };
    case types.GET_QUOTES_AVERAGE_TIME_SUCCESS:
      return {
        ...state,
        fetchingQuotesAverageTime: false,
        quotesAverageTime: action.payload,
      };
    case types.GET_QUOTES_AVERAGE_TIME_FAILURE:
      return {
        ...state,
        fetchingQuotesAverageTime: false,
        fetchingQuotesAverageTimeError: true,
      };

    default:
      return state;
  }
};
