import styled from "styled-components";
import { Calendar } from 'react-big-calendar'
import moment from 'moment';
// import 'react-big-calendar/lib/css/react-big-calendar.css';

const StyledCalendar = styled(Calendar)`
    /* border-radius: 0.18em;
    border: 0.06em solid ${props => props.theme.borderColor};
    background-color: ${props => props.theme.backgroundColor};
    color: ${props => props.theme.color};
    display: block;
    margin: 0.3rem;
    border-radius: 0.2rem;
    outline: none;
    box-shadow: 0px 0.25em 0.62em -0.25em  ${props => props.theme.boxShadowColor};
    padding: 0.3rem 1rem; */
    .rbc-calendar{
            border: 2px solid red !important;
            }

    .rbc-timeslot-group {
        border-bottom: 0.06em solid tomato;
    }
   `
export default StyledCalendar;