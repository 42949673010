import * as types from "./AuthTypes";
const initialState = {
  env: "server",
  registering: false,
  registeringError: false,
  registeringSuccess: false,
  user: {},
  fetchingUserDetails: false,
  fetchingUserDetailsError: null,
  updatingUserDetails: false,
  updatingUserDetailsError: null,
  userDetails: JSON.parse(sessionStorage.getItem("userDetails")) || {},
  fetchingOrganizationDetails: false,
  fetchingOrganizationDetailsError: null,
  updatingOrganizationDetails: false,
  updatingOrganizationDetailsError: null,
  organizationDetails: {},
  changingPassword: false,
  changingPasswordError: false,
  logging: false,
  loginError: false,
  token: sessionStorage.getItem("token"),
};
export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_SERVER:
      return { ...state, env: action.payload };
    case types.REGISTER_REQUEST:
      return { ...state, registering: true, registeringError: false };
    case types.REGISTER_SUCCESS:
      return {
        ...state,
        registering: false,
        registeringError: false,
        registeringSuccess: true,
        user: action.payload,
      };
    case types.REGISTER_FAILURE:
      return {
        ...state,
        registering: false,
        registeringError: true,
        registeringSuccess: false,
      };

    case types.VALIDATE_EMAIL_REQUEST:
      return { ...state };
    case types.VALIDATE_EMAIL_SUCCESS:
      return { ...state };
    case types.VALIDATE_EMAIL_FAILURE:
      return { ...state };

    case types.SET_PASSWORD_REQUEST:
      return { ...state };
    case types.SET_PASSWORD_SUCCESS:
      return { ...state };
    case types.SET_PASSWORD_FAILURE:
      return { ...state };

    case types.CHANGE_PASSWORD_REQUEST:
      return { ...state, changingPassword: true, changingPasswordError: false };
    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changingPassword: false,
        changingPasswordError: false,
      };
    case types.CHANGE_PASSWORD_FAILURE:
      return { ...state, changingPassword: false, changingPasswordError: true };

    case types.LOGIN_REQUEST:
      return { ...state, logging: true };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        logging: false,
        token: action.payload.token || sessionStorage.getItem("token"),
      };
    case types.LOGIN_FAILURE:
      return { ...state, logging: false, loginError: true };

    case types.GET_USER_DETAILS_REQUEST:
      return { ...state, fetchingUserDetails: true };
    case types.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        fetchingUserDetails: false,
        userDetails:
          action.payload || JSON.parse(sessionStorage.getItem("userDetails")),
      };
    case types.GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        fetchingUserDetails: false,
        fetchingUserDetailsError: true,
      };

    case types.UPDATE_USER_DETAILS_REQUEST:
      return { ...state, updatingUserDetails: true };
    case types.UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        updatingUserDetails: false,
        userDetails:
          action.payload || JSON.parse(sessionStorage.getItem("userDetails")),
      };
    case types.UPDATE_USER_DETAILS_FAILURE:
      return {
        ...state,
        updatingUserDetails: false,
        updatingUserDetailsError: true,
      };

    case types.GET_ORGANIZATION_DETAILS_REQUEST:
      return { ...state, fetchingOrganizationDetails: true };
    case types.GET_ORGANIZATION_DETAILS_SUCCESS:
      return {
        ...state,
        fetchingOrganizationDetails: false,
        organizationDetails: action.payload,
      };
    case types.GET_ORGANIZATION_DETAILS_FAILURE:
      return {
        ...state,
        fetchingOrganizationDetails: false,
        fetchingOrganizationDetailsError: true,
      };

    case types.UPDATE_ORGANIZATION_DETAILS_REQUEST:
      return { ...state, updatingOrganizationDetails: true };
    case types.UPDATE_ORGANIZATION_DETAILS_SUCCESS:
      return {
        ...state,
        updatingOrganizationDetails: false,
        organizationDetails: action.payload,
        userDetails: {
          ...state.userDetails,
          metaData: {
            ...state.userDetails.metaData,
            organization: action.payload,
          },
        },
      };
    case types.UPDATE_ORGANIZATION_DETAILS_FAILURE:
      return {
        ...state,
        updatingOrganizationDetails: false,
        updatingOrganizationDetailsError: true,
      };

    /**
     * update subscriptiontype inn userDetails to
     */
    case types.UPDATE_SUBSCRIPTION_TYPE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          metaData: {
            ...state.userDetails.metaData,
            organization: {
              ...state.userDetails.metaData.organization,
              subscriptionType: action.payload,
            },
          },
        },
      };
    /**
     * this is for realtime updation of smartboost of loggedin user
     * is smartboost is updated from user drawer then, the user smartboost also get updated
     */
    case types.UPDATE_SMARTBOOST_SUCCESS:
      console.log(action.payload);
      function updateSmartBoost(userDetails, param) {
        if (userDetails.userId === param.userId) {
          return {
            ...userDetails,
            metaData: { ...userDetails.metaData, smartBoost: param.boostInd },
          };
        } else {
          return userDetails;
        }
      }
      return {
        ...state,
        userDetails: updateSmartBoost(state.userDetails, action.payload),
      };
    /**
     * this is for realtime updation of product of loggedin user
     * is product is updated from user drawer then, the user product also get updated
     */
    case types.UPDATE_PROFESSIONALDUCT_SUCCESS:
      console.log(action.payload);
      function updateProduct(userDetails, param) {
        if (userDetails.userId === param.userId) {
          return {
            ...userDetails,
            metaData: {
              ...userDetails.metaData,
              productStatus: param.productStatus,
            },
          };
        } else {
          return userDetails;
        }
      }
      return {
        ...state,
        userDetails: updateProduct(state.userDetails, action.payload),
      };
    /**
     * this is for realtime updation of viewport of loggedin user
     * is viewport is updated from user drawer then, the user viewport status also get updated
     */
    case types.UPDATE_VIEWPORT_SUCCESS:
      console.log(action.payload);
      function updateViewport(userDetails, status) {
        return {
          ...userDetails,
          metaData: { ...userDetails.metaData, viewportStatus: status },
        };
      }
      return {
        ...state,
        userDetails: updateViewport(state.userDetails, action.payload),
      };

    case types.UPDATE_USER_ADDRESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          address: state.userDetails.address.map((item) => {
            if (item.addressId === action.payload.address.addressId) {
              return action.payload.address;
            } else {
              return item;
            }
          }),
        },
      };

    case types.UPDATE_LEGAL_SUCCESS:
      console.log(action.payload);
      function updateLegal(userDetails, param) {
        //////debugger;
        if (userDetails.organizationId === param.organizationId) {
          return {
            ...userDetails,
            metaData: { ...userDetails.metaData, legalInd: param.legalInd },
          };
        } else {
          return userDetails;
        }
      }
      return {
        ...state,
        userDetails: updateLegal(state.userDetails, action.payload),
      };

    case types.UPDATE_FINANCE_SUCCESS:
      console.log(action.payload);
      function updateFinance(userDetails, param) {
        if (userDetails.organizationId === param.organizationId) {
          return {
            ...userDetails,
            metaData: { ...userDetails.metaData, financeInd: param.financeInd },
          };
        } else {
          return userDetails;
        }
      }
      return {
        ...state,
        userDetails: updateFinance(state.userDetails, action.payload),
      };
    case types.UPDATE_RISKMANAGEMENT_SUCCESS:
      console.log(action.payload);
      function updateRisk(userDetails, param) {
        if (userDetails.organizationId === param.organizationId) {
          return {
            ...userDetails,
            metaData: {
              ...userDetails.metaData,
              riskManagementInd: param.riskManagementInd,
            },
          };
        } else {
          return userDetails;
        }
      }
      return {
        ...state,
        userDetails: updateRisk(state.userDetails, action.payload),
      };

    case types.UPDATE_CUSTOME_MODULE_SUCCESS:
      console.log(action.payload);
      function updateCustomeModule(userDetails, param) {
        //////debugger;
        if (userDetails.organizationId === param.organizationId) {
          return {
            ...userDetails,
            metaData: { ...userDetails.metaData, data: param.Ind },
          };
        } else {
          return userDetails;
        }
      }
      return {
        ...state,
        userDetails: updateCustomeModule(state.userDetails, action.payload),
      };

    /**
     * this is for realtime updation of Recruitment of loggedin user
     * is Recruitment is updated from user drawer then, the user Recruitment status also get updated
     */
    case types.UPDATE_RECRUITMENT_SUCCESS:
      console.log(action.payload);
      function updateRecruitment(userDetails, status) {
        return {
          ...userDetails,
          metaData: { ...userDetails.metaData, recriutmentInd: status },
        };
      }
      return {
        ...state,
        userDetails: updateRecruitment(state.userDetails, action.payload),
      };

    /**
     * this is for realtime updation of Marketing of loggedin user
     * is Marketing is updated from user drawer then, the user Marketing status also get updated
     */
    case types.UPDATE_MARKETING_SUCCESS:
      console.log(action.payload);
      function Marketing(userDetails, status) {
        return {
          ...userDetails,
          metaData: { ...userDetails.metaData, campaignInd: status },
        };
      }
      return {
        ...state,
        userDetails: Marketing(state.userDetails, action.payload),
      };

    case types.UPDATE_RECRUITMENT_ADVANCE_SUCCESS:
      console.log(action.payload);
      function recruitAdvance(userDetails, status) {
        return {
          ...userDetails,
          metaData: { ...userDetails.metaData, advanceRecruitInd: status },
        };
      }
      return {
        ...state,
        userDetails: recruitAdvance(state.userDetails, action.payload),
      };

    case types.UPDATE_INVENTORY_SUCCESS:
      console.log(action.payload);
      function inventory(userDetails, status) {
        return {
          ...userDetails,
          metaData: { ...userDetails.metaData, inventoryInd: status },
        };
      }
      return {
        ...state,
        userDetails: inventory(state.userDetails, action.payload),
      };

    case types.UPDATE_FASHION_SUCCESS:
      console.log(action.payload);
      function fashion(userDetails, status) {
        return {
          ...userDetails,
          metaData: { ...userDetails.metaData, garmentProInd: status },
        };
      }
      return {
        ...state,
        userDetails: fashion(state.userDetails, action.payload),
      };

    default:
      return state;
  }
  return state;
};
