import * as types from "./CustomeActionType";
const initialState = {
  addingCustomeField: false,
  addingCustomeFieldError: false,

  fetchingCustomeFieldByTabName: false,
  fetchingCustomeFieldByTabNameError: false,
  customeFieldDataByTabName: [],
};
export const customeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_CUSTOME_FIELD_REQUEST:
      return { ...state, addingCustomeField: true };
    case types.ADD_CUSTOME_FIELD_SUCCESS:
      return { ...state, addingCustomeField: false };
    case types.ADD_CUSTOME_FIELD_FAILURE:
      return {
        ...state,
        addingCustomeField: false,
        addingCustomeFieldError: false,
      };

    case types.GET_CUSTOME_FILED_WITH_TABNAME_REQUEST:
      return { ...state, fetchingCustomeFieldByTabName: true };
    case types.GET_CUSTOME_FILED_WITH_TABNAME_SUCCESS:
      return {
        ...state,
        fetchingCustomeFieldByTabName: false,
        customeFieldDataByTabName: action.payload,
      };
    case types.GET_CUSTOME_FILED_WITH_TABNAME_FAILURE:
      return {
        ...state,
        fetchingCustomeFieldByTabName: false,
        fetchingCustomeFieldByTabNameError: false,
      };
    default:
      return state;
  }
};
