import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { bindActionCreators } from "redux";
import { Button } from "antd";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Spacer, StyledLabel } from "../../../../../Components/UI/Elements";
import SearchSelect from "../../../../../Components/Forms/Formik/SearchSelect";
import { InputComponent } from "../../../../../Components/Forms/Formik/InputComponent";
import { FlexContainer } from "../../../../../Components/UI/Layout";
import {
  linkProductToOpportunity,
  getProductsByOpportunityId,
} from "../../../OpportunityAction";
import { TextareaComponent } from "../../../../../Components/Forms/Formik/TextareaComponent";
import ProductClearbit from "../../../../../Components/Forms/Autocomplete/ProductClearbit";
import { setClearbitProductData } from "../../../../Account/AccountAction";
import { accountReducer } from "../../../../Account/AccountReducer";

/**
 * yup validation scheme for creating a product
 */
const LinkProductSchema = Yup.object().shape({
  // productId: Yup.string().required("Input needed!"),
  quantity: Yup.string().required("Input needed!"),
  // allowedDiscount: Yup.number()
  //   .min(0, " ")
  //   .max(3, "Discount cannot exceed Maximum !"),
});

class LinkProductform extends Component {
  // componentDidMount() {
  //   this.props.setClearbitProductData({});
  // }
  handleCallback = () => {
    const {
      getProductsByOpportunityId,
      opportunity: { opportunityId },
    } = this.props;
    getProductsByOpportunityId(opportunityId);
    console.log();
  };
  getLinkedSchema = (max) => {
    console.log(max);
  };
  render() {
    const {
      linkProductToOpportunity,
      opportunity: { opportunityId, currency },
    } = this.props;
    const {
      productsByOpportunityId,
      productId,
      linkingProductToOpportunity,
    } = this.props;
    console.log(productId);
    return (
      <>
        <Formik
          isDisabled={true}
          // enableReinitialize
          initialValues={{
            category: "",
            subcategory: "",
            productName: "",
            attributeName: "",
            subAttributeName: "",
            allowedDiscount: 0,
            extendedNetPrice: 0,
            baseCost: "",
            maxDiscount: "",
            productCurrency: undefined,
            opportunityCurrency: this.props.opportunity.currency,
            stockUnit: "",
            productId: this.props.productId,
            quantity: "",
          }}
          validationSchema={LinkProductSchema}
          onSubmit={(values, { resetForm }) => {
            console.log({ ...values, productId: this.props.productId });
            linkProductToOpportunity(
              opportunityId,
              { ...values, productId: this.props.productId },
              this.handleCallback
            );
          }}
        >
          {({
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            values,
            ...rest
          }) => (
            <Form>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    height: "100%",
                    width: "45%",
                  }}
                >
                  <Field
                    isRequired
                    name="label"
                    type="text"
                    // label="Search"
                    label={<FormattedMessage
                      id="app.search"
                      defaultMessage="Search"
                    />}
                    placeholder="Search or Createto search..."
                    isColumnwithnoCreate
                    setClearbitProductData={setClearbitProductData}
                    component={ProductClearbit}
                    product={productsByOpportunityId}
                    inlineLabel
                    style={{ flexBasis: "80%" }}
                  />
                  <Spacer />
                  <Field
                    disabled="true"
                    name="categoryName"
                    type="text"
                    // label="Category"
                    label={<FormattedMessage
                      id="app.category"
                      defaultMessage="Category"
                    />}
                    inlineLabel
                    isColumn
                    width={"100%"}
                    component={InputComponent}
                    style={{
                      flexBasis: "80%",
                      height: "2em",
                      marginTop: "0em",
                    }}
                  />
                  <Spacer />
                  <Field
                    disabled="true"
                    name="subCategoryName"
                    // label="Sub Category"
                    label={<FormattedMessage
                      id="app.subcategory"
                      defaultMessage="Sub Category"
                    />}
                    inlineLabel
                    isColumn
                    width={"100%"}
                    component={InputComponent}
                    style={{
                      flexBasis: "80%",
                      height: "2em",
                      marginTop: "0em",
                    }}
                  />
                  <Spacer />
                  <Field
                    disabled="true"
                    name="productName"
                    // label="Name"
                    label={<FormattedMessage
                      id="app.name"
                      defaultMessage="Name"
                    />}
                    inlineLabel
                    isColumn
                    width={"100%"}
                    component={InputComponent}
                    style={{
                      flexBasis: "80%",
                      height: "2em",
                      marginTop: "0em",
                    }}
                  />
                  <Spacer />
                  <FlexContainer justifyContent="space-between">
                    <div style={{ width: "47%" }}>
                      <Field
                        name="attributeName"
                        disabled="true"
                        // label="Attribute"
                        label={<FormattedMessage
                          id="app.attribute"
                          defaultMessage="Attribute"
                        />}
                        isColumn
                        width={"100%"}
                        inlineLabel
                        component={InputComponent}
                        style={{
                          flexBasis: "80%",
                          height: "2em",
                          marginTop: "0em",
                        }}
                      />
                    </div>
                    <div style={{ width: "47%" }}>
                      <Field
                        disabled="true"
                        name="subAttributeName"
                        // label="Sub Attribute"
                        label={<FormattedMessage
                          id="app.subattribute"
                          defaultMessage="Sub Attribute"
                        />}
                        inlineLabel
                        isColumn
                        width={"100%"}
                        component={InputComponent}
                        style={{
                          flexBasis: "80%",
                          height: "2em",
                          marginTop: "0em",
                        }}
                      />
                    </div>
                  </FlexContainer>
                  {/* <Field
                    isRequired
                    selectType="product"
                    name="category.name"
                    isColumn
                    margintop={"0.25em"}
                    label="category"
                    inlineLabel
                    // fillAnother={["subcategory"]}
                    changeCallback={(option) => {
                      //debugger;
                      console.log(option);
                      setTimeout(() => {
                        setFieldValue("category", option.subCategory);
                        // setFieldValue("baseCost", option.baseCost);
                        // setFieldValue("productCurrency", option.currency);
                        // setFieldValue("subCategory", option.subCategory);
                        // setFieldValue("description", option.description);
                        // setFieldValue("maxDiscount", option.maxDiscount);
                        // setFieldValue("allowedDiscount", 0);
                        // setFieldValue(
                        //   "extendedNetPrice",
                        //   option.baseCost *
                        //     (1 - values.allowedDiscount / 100) *
                        //     values.quantity
                        // );
                      }, 1);
                    }}
                    component={SearchSelect}
                    style={{ flexBasis: "80%" }}
                  /> */}
                  {/* <Field
                    name="category.name"
                    // disabled="true"
                    label="Category"
                    isColumn
                    width={"14.37em"}
                    inlineLabel
                    component={InputComponent}
                    style={{
                      flexBasis: "80%",
                      height: "2em",
                       marginTop: "0em",
                    }}
                  />
                  <Spacer />
                  <Field
                    // disabled="true"
                    name="subcategory"
                    label="Sub Category"
                    inlineLabel
                    isColumn
                    width={"14.37em"}
                    component={InputComponent}
                    style={{
                      flexBasis: "80%",
                      height: "2em",
                       marginTop: "0em",
                    }}
                  />

                  <Spacer />
                  <Field
                    isRequired
                    selectType="product"
                    name="productId"
                    isColumn
                    margintop={"0.25em"}
                    label="Name"
                    inlineLabel
                    fillAnother={["category", "baseCost", "productCurrency"]}
                    changeCallback={(option) => {
                      setTimeout(() => {
                        setFieldValue("category", option.category);
                        setFieldValue("baseCost", option.baseCost);
                        setFieldValue("productCurrency", option.currency);
                        setFieldValue("subCategory", option.subCategory);
                        setFieldValue("description", option.description);
                        setFieldValue("maxDiscount", option.maxDiscount);
                        setFieldValue("allowedDiscount", 0);
                        setFieldValue(
                          "extendedNetPrice",
                          option.baseCost *
                            (1 - values.allowedDiscount / 100) *
                            values.quantity
                        );
                      }, 1);
                    }}
                    component={SearchSelect}
                    style={{ flexBasis: "80%" }}
                  />
                  <Spacer />
                  <FlexContainer justifyContent="space-between">
                    <div style={{ width: "47%" }}>
                      <Field
                        name="variant"
                        // disabled="true"
                        label="Attribute"
                        isColumn
                        width={"160.31em"}
                        inlineLabel
                        component={InputComponent}
                        style={{
                          flexBasis: "80%",
                          height: "2em",
                           marginTop: "0em",
                        }}
                      />
                    </div>
                    <div style={{ width: "47%" }}>
                      <Field
                        // disabled="true"
                        name="volume"
                        label="Sub Attribute"
                        inlineLabel
                        isColumn
                        width={"160.31em"}
                        component={InputComponent}
                        style={{
                          flexBasis: "80%",
                          height: "2em",
                           marginTop: "0em",
                        }}
                      />
                    </div>
                  </FlexContainer>
                  <Spacer style={{ marginBottom: "2.81em" }} />
                  <Field
                    name="description"
                    disabled="true"
                    label="Description"
                    isColumn
                    width={"14.37em"}
                    component={TextareaComponent}
                    inlineLabel
                    style={{ flexBasis: "80%", height: "2em" }}
                  /> */}
                </div>
                <div
                  style={{
                    height: "100%",
                    width: "45%",
                  }}
                >
                  <FlexContainer justifyContent="space-between">
                    <div style={{ width: "47%" }}>
                      <Field
                        isRequired
                        name="quantity"
                        // label="Units"
                        label={<FormattedMessage
                          id="app.units"
                          defaultMessage="Units"
                        />}
                        type="number"
                        isColumn
                        width={"100%"}
                        component={InputComponent}
                        onChange={({ target: { value } }) => {
                          //debugger;
                          setFieldValue("quantity", value);
                          const bc = values.baseCost;
                          const ad = values.allowedDiscount;

                          setFieldValue(
                            "extendedNetPrice",
                            (bc * (1 - ad / 100) * value).toFixed(2)
                          );
                        }}
                        inlineLabel
                        style={{
                          flexBasis: "80%",
                          height: "2em",
                          marginTop: "0em",

                        }}
                      />
                    </div>
                    <div style={{ width: "47%" }}>
                      <Field
                        disabled="true"
                        name="stockUnit"
                        // label="Unit In Stock"
                        label={<FormattedMessage
                          id="app.unitinstock"
                          defaultMessage="Unit In Stock"
                        />}
                        isColumn
                        width={"100%"}
                        component={InputComponent}
                        getLinkedSchema={values.maxDiscount}
                        inlineLabel
                        style={{
                          flexBasis: "80%",
                          height: "2em",
                          marginTop: "0em",
                        }}
                      />
                    </div>
                  </FlexContainer>
                  <Spacer />
                  <FlexContainer justifyContent="space-between">
                    <div style={{ width: "47%" }}>
                      <Field
                        isRequired
                        type="number"
                        name="allowedDiscount"
                        // label="Discount(%)"
                        label={<FormattedMessage
                          id="app.discount(%)"
                          defaultMessage="Discount(%)"
                        />}
                        component={InputComponent}
                        isColumn
                        width={"100%"}
                        inlineLabel
                        // max={values.maxDiscount}
                        onChange={({ target: { value } }) => {
                          setFieldValue("allowedDiscount", value);
                          const bc = values.baseCost;
                          const qty = values.quantity;
                          setFieldValue(
                            "extendedNetPrice",
                            (bc * (1 - value / 100) * qty).toFixed(2)
                          );
                        }}
                        style={{
                          flexBasis: "80%",
                          height: "2em",
                          marginTop: "0em",
                        }}
                      />
                    </div>

                    <div style={{ width: "47%" }}>
                      <Field
                        disabled="true"
                        name="maxDiscount"
                        // label="Max Discount(%)"
                        label={<FormattedMessage
                          id="app.maxdiscount(%)"
                          defaultMessage="Max Discount(%)"
                        />}
                        isColumn
                        width={"100%"}
                        component={InputComponent}
                        getLinkedSchema={values.maxDiscount}
                        inlineLabel
                        style={{
                          flexBasis: "80%",
                          height: "2em",
                          marginTop: "0em",
                        }}
                      />
                    </div>
                  </FlexContainer>
                  <Spacer />

                  <FlexContainer justifyContent="space-between">
                    <div style={{ width: "47%" }}>
                      <Field
                        disabled="true"
                        name="baseCost"
                        // label="Price"
                        label={<FormattedMessage
                          id="app.price"
                          defaultMessage="Price"
                        />}
                        isColumn
                        width={"100%"}
                        component={InputComponent}
                        inlineLabel
                        style={{
                          flexBasis: "50%",
                          height: "2em",
                          marginTop: "0em",
                        }}
                      />
                    </div>
                    <div style={{ width: "47%" }}>
                      <Field
                        disabled="true"
                        name="extendedNetPrice"
                        // label="Net"
                        label={<FormattedMessage
                          id="app.net"
                          defaultMessage="Net"
                        />}
                        isColumn
                        width={"100%"}
                        component={InputComponent}
                        inlineLabel
                        style={{
                          flexBasis: "80%",
                          height: "2em",
                          marginTop: "0em",
                        }}
                      />
                    </div>
                  </FlexContainer>
                  <Spacer />
                  <FlexContainer justifyContent="space-between">
                    <div style={{ width: "47%" }}>
                      <Field
                        disabled="true"
                        selectType="currency"
                        name="opportunityCurrency"
                        // label="Opportunity Curr."
                        label={<FormattedMessage
                          id="app.opportunitycurr."
                          defaultMessage="Opportunity Curr"
                        />}
                        isColumn
                        width={"100%"}
                        inlineLabel
                        value={this.props.opportunity.currency}
                        component={InputComponent}
                        style={{
                          flexBasis: "80%",
                          height: "2em",
                          marginTop: "0em",
                        }}
                      />
                    </div>
                    <div style={{ width: "47%" }}>
                      <Field
                        disabled="true"
                        name="productCurrency"
                        // label=" Curr."
                        label={<FormattedMessage
                          id="app.curr."
                          defaultMessage="Curr ."
                        />}
                        isColumn
                        width={"100%"}
                        inlineLabel
                        component={InputComponent}
                        style={{
                          flexBasis: "80%",
                          height: "2em",
                          marginTop: "0em",
                        }}
                      />
                    </div>
                  </FlexContainer>
                </div>
              </div>
              {/* <div style={{ width: 500 }}>
                <Field
                  isRequired
                  selectType="product"
                  name="productId"
                  label="Name"
                  inlineLabel
                  fillAnother={["category", "baseCost", "productCurrency"]}
                  changeCallback={option => {
                    setTimeout(() => {
                      setFieldValue("category", option.category);
                      setFieldValue("productCurrency", option.currency);
                      setFieldValue("subCategory", option.subCategory);
                      setFieldValue("description", option.description);
                      setFieldValue("maxDiscount", option.maxDiscount);
                      setFieldValue("allowedDiscount", 0);
                      setFieldValue(
                        "extendedNetPrice",
                        option.baseCost *
                          (1 - values.allowedDiscount / 100) *
                          values.quantity
                      );
                    }, 1);
                  }}
                  component={SearchSelect}
                  style={{ flexBasis: "80%" }}
                />
                <Spacer style={{ marginTop: "0.75em" }} />
                <FlexContainer>
                  <Field
                    name="category"
                    disabled="true"
                    label="Category"
                    inlineLabel
                    component={InputComponent}
                    style={{
                      flexBasis: "80%",
                      height: "2.37em",
                      marginLeft: "40.18em",
                      width: "140.5em"
                    }}
                  />

                  <Field
                    disabled="true"
                    name="subCategory"
                    label=" Sub"
                    inlineLabel
                    component={InputComponent}
                    style={{
                      flexBasis: "80%",
                      height: "2.37em",
                      marginLeft: "-0.94em"
                    }}
                  />
                </FlexContainer>
                <Spacer />
                <Field
                  name="description"
                  disabled="true"
                  label="Description"
                  component={InputComponent}
                  inlineLabel
                  style={{ flexBasis: "80%", height: "2.37em" }}
                />
                <Spacer />
                <Field
                  isRequired
                  name="quantity"
                  label="Units"
                  type="number"
                  component={InputComponent}
                  onChange={({ target: { value } }) => {
                    setFieldValue("quantity", value);
                    const bc = values.baseCost;
                    const ad = values.allowedDiscount;

                    setFieldValue(
                      "extendedNetPrice",
                      bc * (1 - ad / 100) * value
                    );
                  }}
                  inlineLabel
                  style={{ flexBasis: "80%", height: "2.37em" }}
                />
                <Spacer />
                <FlexContainer>
                  <div style={{ width: "50%" }}>
                    <Field
                      isRequired
                      type="number"
                      name="allowedDiscount"
                      label="Allowed Discount(%)"
                      component={InputComponent}
                      inlineLabel
                      max={values.maxDiscount}
                      onChange={({ target: { value } }) => {
                        setFieldValue("allowedDiscount", value);
                        const bc = values.baseCost;
                        const qty = values.quantity;
                        setFieldValue(
                          "extendedNetPrice",
                          bc * (1 - value / 100) * qty
                        );
                      }}
                      style={{
                        flexBasis: "80%",
                        height: "2.37em",
                        marginLeft: "1.25em",
                        width: "150.56em"
                      }}
                    />
                  </div>
                  &nbsp;&nbsp;
                  <div style={{ width: "48%" }}>
                    <Field
                      disabled="true"
                      name="maxDiscount"
                      label="  Max"
                      component={InputComponent}
                      getLinkedSchema={values.maxDiscount}
                      inlineLabel
                      style={{
                        flexBasis: "80%",
                        height: "2.37em"
                      }}
                    />
                  </div>
                </FlexContainer>
                <Spacer />
                <FlexContainer>
                  <Field
                    disabled
                    selectType="currency"
                    name="opportunityCurrency"
                    label="OppCurr."
                    inlineLabel
                    component={InputComponent}
                    style={{
                      flexBasis: "80%",
                      height: "2.37em",
                      marginLeft: "30.56em",
                      width: "12.5em"
                    }}
                  />

                  <Field
                    disabled
                    selectType="currency"
                    name="productCurrency"
                    label=" Curr."
                    inlineLabel
                    component={InputComponent}
                    style={{
                      flexBasis: "80%",
                      height: "2.37em",
                      marginLeft: "-0.18em"
                    }}
                  />
                </FlexContainer>
                <Spacer />
           

                <FlexContainer>
                  <Field
                    disabled="true"
                    name="baseCost"
                    label="Price"
                    component={InputComponent}
                    inlineLabel
                    style={{
                      flexBasis: "50%",
                      height: "2.37em",
                      marginLeft: "2.81em",
                      width: "10em"
                    }}
                  />

                  <Field
                    disabled="true"
                    name="extendedNetPrice"
                    label="Net"
                    component={InputComponent}
                    inlineLabel
                    style={{
                      flexBasis: "80%",
                      height: "2.37em",
                      marginLeft: "-0.18em"
                    }}
                  />
                </FlexContainer>
                <Spacer />

                <Button
                  type="primary"
                  style={{ marginLeft: "41.56em" }}
                  htmlType="submit"
                  loading={false}
                >
                  Create
                </Button>
              </div> */}
              <Spacer />
              <FlexContainer justifyContent="flex-end">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={linkingProductToOpportunity}
                >
                  {/* Create */}
                  <FormattedMessage
                    id="app.create"
                    defaultMessage="Create"
                  />
                </Button>
              </FlexContainer>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

const mapStateToProps = ({ auth, account, opportunity }) => ({
  user: auth.userDetails,
  opportunity: opportunity.opportunity,
  productId: account.clearbitProduct.productId,
  productsByOpportunityId: opportunity.productsByOpportunityId,
  linkingProductToOpportunity: opportunity.linkingProductToOpportunity,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      linkProductToOpportunity,
      getProductsByOpportunityId,
      setClearbitProductData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LinkProductform);
