import React, { Component } from "react";
import { ImageGradient } from "./styled";
import { Title, Spacer } from "../../Components/UI/Elements";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import LoginBackground from "../../Assets/Images/login-background1.jpg";
import TimeToConnectImage from "../../Assets/Images/BusinessLeaders.jpg";
import DashboardImage from "../../Assets/Images/BusinessLeaders.jpg";
// import TimeToConnectImage from "../../Assets/Images/HappyNewYear.jpg";
// import DashboardImage from "../../Assets/Images/HappyNewYear.jpg";
import MultiFunction from "../../Assets/Images/MultiFunction.jpg";
import TaskGovernance from "../../Assets/Images/TaskGovernance.jpg";

import Slider from "react-slick";
import { Carousel } from "antd";
class RandomImageScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenshot: DashboardImage,
    };
  }
  componentDidMount() {
    function getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    }
    console.log(getRandomInt(3));
    let randomNumber = getRandomInt(3);
    if (randomNumber <= 1) {
      this.setState({
        screenshot: DashboardImage,
      });
    } else if (randomNumber === 2) {
      this.setState({
        screenshot: TimeToConnectImage,
      });
    }
  }
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div style={{ width: "50%", overflow: "hidden" }}>
        <ImageGradient
        // backgroundImage={LoginBackground}
        // style={{ padding: "0 auto" }}
        >
          <Slider {...settings}>
            <Title
              color="#fff"
              fontSize="1.5rem"
              // fontFamily="Segoe UI"
              fontFamily="Roboto"
              lineHeight="1.5rem"
              textAlign="center"
              margin="1.25em 0px 0px 0px"
              style={{
                lineHeight: " 1.1",
                marginTop: "1.25em",
              }}
            >
              <div> Real time Business performance reports for</div>
              <div
                style={{
                  marginTop: "0.94em",
                }}
              ></div>
              <div> CEOs & Sales leaders</div>
              <br />
              <div
                className="image"
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  minHeight: "10em",
                  display: "flex",
                  justifyContent: "center",
                  // boxShadow: "0 0.31em 1.25em 0 #444",
                  border: "0.06em solid #ddd",
                  borderRadius: " 0.43em",
                  margin: "2.5em",
                  padding: "0.5em 0.62em",
                  backgroundColor: "white",
                }}
              >
                <img src={TimeToConnectImage} className="login_image"></img>
              </div>
            </Title>

            <Title
              color="#fff"
              fontSize="1.5rem"
              // fontFamily="Segoe UI"
              fontFamily="Roboto"
              textAlign="center"
              lineHeight="1.5rem"
              margin="1.25em 0px 0px 0px"
              style={{
                lineHeight: " 1.1",
                marginTop: "1.25em",
              }}
            >
              <div>Sales process with Workflows allows the enterprise</div>
              <div
                style={{
                  marginTop: "0.94em",
                }}
              ></div>
              <div>to define rules for selecting the task owner.</div>
              <br />
              <div
                className="image"
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  minHeight: "10em",
                  display: "flex",
                  justifyContent: "center",
                  // boxShadow: "0 0.31em 1.25em 0 #444",
                  border: "0.06em solid #ddd",
                  borderRadius: " 0.43em",
                  margin: "2.5em",
                  padding: "0.5em 0.62em",
                  backgroundColor: "white",
                }}
              >
                <img src={TaskGovernance} className="login_image"></img>
              </div>
            </Title>

            <Title
              color="#fff"
              fontSize="1.5rem"
              // fontFamily="Segoe UI"
              fontFamily="Roboto"
              textAlign="center"
              lineHeight="1.5rem"
              margin="1.25em 0px 0px 0px"
              style={{
                lineHeight: " 1.1",
                marginTop: "1.25em",
              }}
            >
              <div>Collaborate with users from different functions</div>
              <div
                style={{
                  marginTop: "0.94em",
                }}
              ></div>
              <div>& external parties securely to accelerate closures.</div>
              <br />
              <div
                className="image"
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  minHeight: "10em",
                  display: "flex",
                  justifyContent: "center",
                  // boxShadow: "0 0.31em 1.25em 0 #444",
                  border: "0.06em solid #ddd",
                  borderRadius: " 0.43em",
                  margin: "2.5em",
                  padding: "0.5em 0.62em",
                  backgroundColor: "white",
                }}
              >
                <img src={MultiFunction} className="login_image"></img>
              </div>
            </Title>
          </Slider>
          <div>
            <div className="randome_text2"
             style={{
              //  fontSize:"10px",
              //  marginTop:"-4px",
             }}
             >
              <div>
                Watch out this space as we keep rolling out interesting features
                so that
              </div>
              <div>
                {" "}
                you focus on your opportunity funnel and customer engagement.
              </div>
              {/* <a href="https://www.freepik.com/vectors/background">Background vector created by pikisuperstar - www.freepik.com */}
              {/* </a> */}
              <div></div>
            </div>
          </div>

          {/* <div
            style={{
              alignItems: "center",
              textAlign: "center",
              minHeight: "7.5em",
              display: "flex",
              justifyContent: "center",
              boxShadow: "0 0.31em 1.25em 0 #444",
              border: "0.06em solid #ddd",
              borderRadius: " 0.43em",
              padding: "0.5em 0.62em",
              margin: "2.5em",
              backgroundColor: "#ebe9e9",
              marginTop: "1.56em",
            }}
          > */}
          {/* <div className="randome_text3">
              <div>
                Korero team extends its support to the Small and Medium
                enterprises sector.
                <div>
                  You will not be billed for usage untill 30th Sep 2020.
                </div>
              </div>

              <Spacer />
              <div>
                Do send your feedback on improving the experience, email us on
                support@tekorero.com
              </div>
            </div> */}
          {/* </div> */}
        </ImageGradient>
      </div>
    );
  }
}

export default RandomImageScreen;
