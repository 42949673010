import * as types from './PlannerActionTypes'

const initialState = {
    plannerStartDate: '',
    plannerEndDate: '',
    plannerStartTime: '',
    plannerEndTime: '',
    chooserModal: false,
    viewEditModal: false,
    selectedEvent: {},
    openedFormModal: 'event',
}
export const plannerReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_PLANNER_DATE:
            const { startDate, endDate, startTime, endTime } = action.payload;
            return { ...state, plannerStartDate: startDate, plannerEndDate: endDate, plannerStartTime: startTime, plannerEndTime: endTime };
        case types.HANDLE_EVENT_MODAL:
            return { ...state, addEventModal: action.payload };
        case types.HANDLE_VIEW_EDIT_MODAL:
            return { ...state, viewEditModal: action.payload.visible, selectedEvent: action.payload.event };
        case types.SET_DATE_AND_TIME:
            return { ...state };
        case types.SET_FORM_MODAL_TYPE:
            return { ...state, chooserModal: false };
        case types.HANDLE_CHOOSER_MODAL:
            return { ...state, chooserModal: action.payload };

    }
    return state;
}