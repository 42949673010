export const GET_EMAIL_CREDENTIAL_REQUEST = "GET_EMAIL_CREDENTIAL_REQUEST";
export const GET_EMAIL_CREDENTIAL_SUCCESS = "GET_EMAIL_CREDENTIAL_SUCCESS";
export const GET_EMAIL_CREDENTIAL_FAILURE = "GET_EMAIL_CREDENTIAL_FAILURE";

export const ADD_EMAIL_CREDENTIAL_REQUEST = "ADD_EMAIL_CREDENTIAL_REQUEST";
export const ADD_EMAIL_CREDENTIAL_SUCCESS = "ADD_EMAIL_CREDENTIAL_SUCCESS";
export const ADD_EMAIL_CREDENTIAL_FAILURE = "ADD_EMAIL_CREDENTIAL_FAILURE";

export const UPDATE_EMAIL_CREDENTIAL_REQUEST =
  "UPDATE_EMAIL_CREDENTIAL_REQUEST";
export const UPDATE_EMAIL_CREDENTIAL_SUCCESS =
  "UPDATE_EMAIL_CREDENTIAL_SUCCESS";
export const UPDATE_EMAIL_CREDENTIAL_FAILURE =
  "UPDATE_EMAIL_CREDENTIAL_FAILURE";

export const SEND_EMAIL_REQUEST = "SEND_EMAIL_REQUEST";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILURE = "SEND_EMAIL_FAILURE";

export const GET_EMAIL_CREDENTIAL_FOR_ADMIN_REQUEST =
  "GET_EMAIL_CREDENTIAL_FOR_ADMIN_REQUEST";
export const GET_EMAIL_CREDENTIAL_FOR_ADMIN_SUCCESS =
  "GET_EMAIL_CREDENTIAL_FOR_ADMIN_SUCCESS";
export const GET_EMAIL_CREDENTIAL_FOR_ADMIN_FAILURE =
  "GET_EMAIL_CREDENTIAL_FOR_ADMIN_FAILURE";

export const ADD_EMAIL_CREDENTIAL_FOR_ADMIN_REQUEST =
  "ADD_EMAIL_CREDENTIAL_FOR_ADMIN_REQUEST";
export const ADD_EMAIL_CREDENTIAL_FOR_ADMIN_SUCCESS =
  "ADD_EMAIL_CREDENTIAL_FOR_ADMIN_SUCCESS";
export const ADD_EMAIL_CREDENTIAL_FOR_ADMIN_FAILURE =
  "ADD_EMAIL_CREDENTIAL_FOR_ADMIN_FAILURE";

export const UPDATE_EMAIL_CREDENTIAL_FOR_ADMIN_REQUEST =
  "UPDATE_EMAIL_CREDENTIAL_FOR_ADMIN_REQUEST";
export const UPDATE_EMAIL_CREDENTIAL_FOR_ADMIN_SUCCESS =
  "UPDATE_EMAIL_CREDENTIAL_FOR_ADMIN_SUCCESS";
export const UPDATE_EMAIL_CREDENTIAL_FOR_ADMIN_FAILURE =
  "UPDATE_EMAIL_CREDENTIAL_FOR_ADMIN_FAILURE";
