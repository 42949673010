import * as types from "./UsersBillingActionTypes";

const initialState = {
  addPaymentModeModal: false,

};

export const usersbillingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.HANDLE_PAYMENTMODE_MODAL:
      return { ...state, addPaymentModeModal: action.payload };


      case types.GET_BILLING_ID_REQUEST:
      return { ...state, fetchingBillingId: true };
    case types.GET_BILLING_ID_SUCCESS:
      return { ...state, fetchingBillingId: false, users: action.payload };
    case types.GET_BILLING_ID_FAILURE:
      return { ...state, fetchingBillingId: false, fetchingBillingIdError: true };

      default:
        return state;
    }
    return state;
  };