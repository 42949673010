import styled from "styled-components";
import { StyledSelect } from "../Antd";
const SelectInput = styled(StyledSelect)`
  .ant-input{
      border-radius: 2px;
      // border: 0.06em solid ${(props) => props.theme.borderColor};
      border: 0.06em solid gainsboro;
      background-color: ${(props) => props.theme.backgroundColor};
      color: ${(props) => props.theme.color};
      display: block;
      margin: 0 0 0.2rem 0;
      width:21.87em;
      border-radius: 0.2rem;
      outline: none;
      box-shadow:${(props) =>
    props.isShadow ? "" : "0px 0.25em 0.62em -0.25em #aaa"} ; 
  // height:2em;
      border-right: ${(props) =>
    props.isRequired ? "0.18em solid #ed260b" : ""} !important;
      padding: 0.3rem 1rem;
    &:hover{
      // box-shadow: 0px 0.25em 0.62em -0.125em  #777;
      }
      ::placeholder {
        color: #888;
      }
      ::placeholder {
        color: #bfbebb;
      }
  }
  .ant-select-selection {
    // height: 2em;
     
  }
   `;
export default SelectInput;
